import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";

const useBanners = contents => {
  const [banners, setBanners] = useState([]);
  const context = useContext<{ auth: any; user: any; account: any }>(
    MainContext
  );
  const {
    auth: { isLoggedIn },
    account: { accountDetails }
  } = context;

  useEffect(() => {
    validateBanners();
  }, [isLoggedIn]);

  const validateBanners = () => {
    const result = contents
      .filter(
        content =>
          content &&
          ((accountDetails.isEligibleForLoyalty && isLoggedIn) ||
            !content.hideForNonLoyaltyUser)
      )
      .filter(
        content =>
          content &&
          ((!content.loggedInUser && !content.guestUser) ||
            (!isLoggedIn && content.guestUser) ||
            (isLoggedIn && content.loggedInUser))
      );

    setBanners(result);
  };

  return banners;
};

export default useBanners;
