import React, { FC } from "react";
import { BreadCrumbsComponent } from "@zilker/store-components";
import { withRouter } from "react-router-dom";

import "./ReleaseNotes.less";

const ReleaseNotes: FC = () => {
  const breadCrumbsMapped = () => {
    const breadCrumbsMap = [
      { param: "home", name: "Home" },

      {
        param: "nocrumb",
        name: "Release Notes"
      }
    ];

    return breadCrumbsMap.filter(urlParam => !!urlParam.param);
  };

  const breadCrumbsMap = breadCrumbsMapped();

  return (
    <div className="release-notes">
      <BreadCrumbsComponent breadCrumbsMap={breadCrumbsMap} />
    </div>
  );
};

export default ReleaseNotes;
