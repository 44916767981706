import React, { useContext, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import uuidv4 from "uuid/v4";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { cortexFetch } from "../utils/Cortex";

import "./JobDropdownComponent.less";

const JobDropdownComponent: React.FC<RouteComponentProps> = ({
  history,
  match,
  location
}) => {
  const mainContext = useContext<{
    auth: any;
    user: any;
    cart: any;
    job: any;
    account: any;
  }>(MainContext);

  const {
    account: {
      accountDetails: { jobsArray, jobNumberRequired }
    },
    cart: {
      cartDetails: { defaultCart },
      getCartDetails
    },
    auth: { logout },
    job: {
      setPersistedJobNumber,
      setPersistedJobName,
      persistedJobName,
      persistedJobNumber
    }
  } = mainContext;

  const { url } = match;
  const { pathname } = location;

  const selectedJob =
    defaultCart && defaultCart.jobNumber ? defaultCart.jobNumber : "default";
  const isCartAssociatedToContract =
    defaultCart &&
    defaultCart.items &&
    defaultCart.items.length &&
    defaultCart.cartOrderDetailsForm["contract-number"] &&
    defaultCart.cartOrderDetailsForm.pricing;

  const updateJob = jobSelected => {
    const link = defaultCart.cartOrderDetailsForm.links[0].uri;

    const body = {
      "update-mode": "JOB",
      "job-number": jobSelected.jobNumber,
      "job-name": jobSelected.jobName
    };

    cortexFetch(link, {
      method: "post",
      body: JSON.stringify(body)
    })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        getCartDetails();

        /**
         * After the order is completed, user's session is still active
         * and we can assume the user wants to keep using the same job.
         * That's why we persist the job name and number.
         */
        persistJobInContext(jobSelected);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => updateJob => JobDropdownComponent.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "updateJob => JobDropdownComponent.tsx"
          });
        }
      });
  };

  const persistJobInContext = ({ jobNumber, jobName }) => {
    setPersistedJobNumber(jobNumber);
    setPersistedJobName(jobName);
  };

  const onJobSelection = e => {
    const { value: jobNumber } = e.target;

    const jobSelected = jobsArray.find(job => job.jobNumber === jobNumber);

    updateJob(jobSelected);
  };

  const defaultCartJobNumber = defaultCart ? defaultCart.jobNumber : null;

  useEffect(() => {
    if (
      jobsArray &&
      jobsArray.length > 1 &&
      defaultCart &&
      !defaultCart.jobName &&
      !defaultCart.jobNumber
    ) {
      if (persistedJobNumber) {
        updateJob({
          jobNumber: persistedJobNumber,
          jobName: persistedJobName || ""
        });
      }
    }
  }, [defaultCartJobNumber, persistedJobNumber, persistedJobName]);

  useEffect(() => {
    if (defaultCart && jobsArray && jobsArray.length === 1) {
      updateJob({
        jobNumber: jobsArray[0].jobNumber,
        jobName: jobsArray[0].jobName
      });
    }
  }, []);

  if (jobNumberRequired && jobsArray && jobsArray.length) {
    return (
      <div className="job-dropdown">
        <label htmlFor="job-dropdown">{intl.get("job")}: </label>
        <select
          name="job-dropdown"
          id="job-dropdown"
          onChange={onJobSelection}
          value={selectedJob}
          disabled={
            url.includes("purchaseReceipt") ||
            pathname === "/order" ||
            isCartAssociatedToContract
          }
        >
          {defaultCart && !defaultCart.jobNumber ? (
            <option value="default">
              {persistedJobName && persistedJobNumber
                ? `${persistedJobName || ""} ${persistedJobNumber}`
                : `--- ${intl.get("select-job")} ---`}
            </option>
          ) : null}
          {jobsArray.map(job => (
            <option
              className="job-dropdown-option"
              key={`${uuidv4()}-${job.jobNumber}`}
              value={job.jobNumber}
            >
              {`${job.jobName || ""} ${job.jobNumber}`}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return null;
};

export default withRouter(JobDropdownComponent);
