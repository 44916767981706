/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import {
  checkTokensExpired,
  checkResponse,
  isInvalidEmail,
  pushToMaintenace,
  checkMemberType
} from "@elasticpath/ref-store/src/utils/helpers";
import base32 from "@elasticpath/ref-store/src/utils/base32/base32";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { useMainContext } from "../../../app/src/contexts/MainContext";
import Input from "../common/InputComponent/Input";
import UserRoles from "../../../app/src/utils/mappings/userRoles";

import "./b2b.editassociate.less";

let Config: IEpConfig | any = {};
let intl = { get: (str, ...args: any[]) => str };

interface SubUserInputs {
  email: string;
  firstName: string;
  lastName: string;
  rolesSelected: string[];
  existingUserMessage: string;
  uri: string;
}

interface B2bEditAssociateProps {
  isEditOpen: boolean;
  handleClose: () => void;
  handleUpdate: () => void;
  associateEmail: string;
  addAssociateUri: string;
  rolesSelector: any;
  isSelf: boolean;
  isAddAssociateOpen: boolean;
  history: any;
  auth: any;
  user: any;
  associate?: any;
}

interface Role {
  roleName: string;
  selectRoleURI: string;
  selected: boolean;
  message?: string;
}

enum RoleTypes {
  BUYER = "BUYER",
  BUYER_ADMIN = "BUYER_ADMIN",
  CATALOG_BROWSER = "CATALOG_BROWSER",
  ECOMM_VIEW_CREDIT = "ECOMM_VIEW_CREDIT",
  ECOMM_VIEW_STATEMENTS = "ECOMM_VIEW_STATEMENTS",
  ECOMMERCE_BUYER = "ECOMMERCE_BUYER",
  LEAD_DASHBOARD_ADMIN = "LEADGEN_ADMIN",
  LEADGEN_DISTRIBUTOR_ADMIN = "LEADGEN_DISTRIBUTOR_ADMIN",
  LEAD_DASHBOARD_VIEWONLY = "LEADGEN_VIEWONLY",
  VIEW_DISTRIBUTOR_REPORTS = "VIEW_DISTRIBUTOR_REPORTS",
  VIEW_DEALER_REPORTS = "VIEW_DEALER_REPORTS"
}

interface ErrorObj {
  errors: SubUserInputs;
  isValid: boolean;
}

const B2bEditAssociate: React.FC<B2bEditAssociateProps> = props => {
  const { user, associate, isEditOpen } = props;

  const {
    userProfile: {
      roles: rolesContext,
      hasLeadGenRoles,
      hasLeadGenDistributorRoles,
      ecommerceUser,
      leadGenDealer,
      ecommerceBuyer
    }
  } = user;
  const epConfig = getConfig();
  Config = epConfig.config;
  ({ intl } = epConfig);
  const context = useMainContext();
  const {
    account: {
      accountDetails: { isBuyerAdmin, membership, customerNumber, company }
    }
  } = context;

  const isLeadGenAdmin = rolesContext.includes(UserRoles().leadDashboardAdmin);

  const isLeadGenDistributorAdmin = rolesContext.includes(
    UserRoles().leadDistributerAdmin
  );

  const isValidDaikinManagerLeadGen = rolesContext.includes("VIEW_ALL_REPORTS");

  const userHasLeads =
    leadGenDealer !== null &&
    leadGenDealer !== "null" &&
    leadGenDealer !== undefined;

  const daikinComfortPro = intl.get("dcp");
  const hasDcpMembership = checkMemberType(
    daikinComfortPro.toLowerCase(),
    membership
  );

  const isValidCODLeadGen = hasLeadGenRoles && userHasLeads && hasDcpMembership;
  const isValidINDLeadGen =
    !ecommerceUser && !ecommerceBuyer && hasLeadGenRoles && userHasLeads;
  const isValidDistributorLeadGen = hasLeadGenDistributorRoles;

  const shouldDisplayLeads =
    isValidCODLeadGen ||
    isValidINDLeadGen ||
    isValidDistributorLeadGen ||
    isValidDaikinManagerLeadGen;

  let initialInputs: SubUserInputs = {
    email: "",
    firstName: "",
    lastName: "",
    rolesSelected: [],
    existingUserMessage: "",
    uri: ""
  };

  let initialRoles: Array<Role> = [];

  const [subUser, setSubUser] = useState<SubUserInputs>({ ...initialInputs });
  const [errors, setErrors] = useState<SubUserInputs>({ ...initialInputs });
  const [changedRoles, setChangedRoles] = useState<Array<Role>>(initialRoles);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [adminWarning, setAdminWarning] = useState<boolean>(false);
  const [rolesUpdated, setRolesUpdated] = useState<boolean>(false);
  const [rolesRendered, setRolesRendered] = useState<boolean>(false);

  const shouldBeSelected = role => {
    const selected = changedRoles.find(
      roleSelected => roleSelected.roleName === role
    );
    return Boolean(selected);
  };

  const renderRoleSelection = () => {
    const { rolesSelector } = props;

    let allAssociateRoles: Array<Role> = [];
    if (rolesSelector) {
      if (rolesSelector._choice) {
        rolesSelector._choice.forEach(choiceElement => {
          allAssociateRoles.push({
            roleName: choiceElement._description[0].name,
            selectRoleURI: choiceElement._selectaction[0].self.uri,
            selected: false
          });
        });
      }
      if (rolesSelector._chosen) {
        rolesSelector._chosen.forEach(chosenElement => {
          allAssociateRoles.push({
            roleName: chosenElement._description[0].name,
            selectRoleURI: chosenElement._selectaction[0].self.uri,
            selected: true
          });
        });
      }
      allAssociateRoles.sort((a, b) => {
        if (a.roleName > b.roleName) {
          return 1;
        }
        if (a.roleName < b.roleName) {
          return -1;
        }
        return 0;
      });
    } else {
      allAssociateRoles = [
        {
          roleName: RoleTypes.CATALOG_BROWSER,
          selectRoleURI: "",
          selected: false
        },
        {
          roleName: RoleTypes.BUYER_ADMIN,
          selectRoleURI: "",
          selected: false
        },
        {
          roleName: RoleTypes.ECOMM_VIEW_CREDIT,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.ECOMM_VIEW_CREDIT)
        },
        {
          roleName: RoleTypes.ECOMM_VIEW_STATEMENTS,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.ECOMM_VIEW_STATEMENTS)
        },
        {
          roleName: RoleTypes.ECOMMERCE_BUYER,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.ECOMMERCE_BUYER)
        },
        {
          roleName: RoleTypes.LEAD_DASHBOARD_ADMIN,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.LEAD_DASHBOARD_ADMIN)
        },
        {
          roleName: RoleTypes.LEAD_DASHBOARD_VIEWONLY,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.LEAD_DASHBOARD_VIEWONLY)
        },
        {
          roleName: RoleTypes.VIEW_DISTRIBUTOR_REPORTS,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.VIEW_DISTRIBUTOR_REPORTS)
        },
        {
          roleName: RoleTypes.VIEW_DEALER_REPORTS,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.VIEW_DEALER_REPORTS)
        },
        {
          roleName: RoleTypes.LEADGEN_DISTRIBUTOR_ADMIN,
          selectRoleURI: "",
          selected: shouldBeSelected(RoleTypes.LEADGEN_DISTRIBUTOR_ADMIN)
        }
      ];
    }
    const shouldRenderRoles =
      changedRoles.length ||
      rolesUpdated ||
      (!changedRoles.length && rolesRendered) ||
      (!isEditOpen && rolesRendered);

    const userIsDistributor =
      rolesContext &&
      rolesContext.find(role => role === RoleTypes.LEADGEN_DISTRIBUTOR_ADMIN);
    const userIsDealer =
      rolesContext &&
      rolesContext.find(role => role === RoleTypes.LEAD_DASHBOARD_ADMIN);

    return (
      shouldRenderRoles && (
        <div className="roles-container">
          {ecommerceUser && (
            <>
              <p className="role-message">*{intl.get("admin-user-message")}</p>
              <div className="role-checkbox-container">
                {allAssociateRoles
                  .filter(
                    role =>
                      role &&
                      role.roleName &&
                      (role.roleName.toUpperCase() ===
                        RoleTypes.ECOMM_VIEW_CREDIT ||
                        role.roleName.toUpperCase() ===
                          RoleTypes.ECOMM_VIEW_STATEMENTS ||
                        role.roleName.toUpperCase() ===
                          RoleTypes.ECOMMERCE_BUYER)
                  )
                  .map(role => (
                    <div key={role.roleName} className="role-checkbox-item">
                      <div className="role-checkbox">
                        <input
                          id={role.roleName}
                          type="checkbox"
                          defaultChecked={role.selected}
                          disabled={!isBuyerAdmin}
                          onChange={e => handleRoleChange(role, e)}
                          className="style-checkbox"
                        />
                        <label htmlFor={role.roleName} />
                        <label
                          htmlFor={role.roleName}
                          className={`role-title ${
                            !isBuyerAdmin ? "disabled" : ""
                          }`}
                        >
                          {intl.get(role.roleName.toLowerCase()) ||
                            role.roleName}
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {shouldDisplayLeads && (
            <>
              <p className="role-message">
                *{intl.get("leadgen-user-description")}
              </p>
              <div className="role-checkbox-container">
                {allAssociateRoles
                  .filter(
                    role =>
                      (role &&
                        role.roleName &&
                        (role.roleName.toUpperCase() ===
                          RoleTypes.LEAD_DASHBOARD_ADMIN ||
                          role.roleName.toUpperCase() ===
                            RoleTypes.LEADGEN_DISTRIBUTOR_ADMIN ||
                          role.roleName.toUpperCase() ===
                            RoleTypes.LEAD_DASHBOARD_VIEWONLY)) ||
                      role.roleName.toUpperCase() ===
                        RoleTypes.VIEW_DEALER_REPORTS ||
                      role.roleName.toUpperCase() ===
                        RoleTypes.VIEW_DISTRIBUTOR_REPORTS
                  )
                  .map(
                    role =>
                      (((role.roleName ===
                        RoleTypes.LEADGEN_DISTRIBUTOR_ADMIN ||
                        role.roleName === RoleTypes.VIEW_DISTRIBUTOR_REPORTS) &&
                        userIsDistributor) ||
                        ((role.roleName === RoleTypes.VIEW_DEALER_REPORTS ||
                          role.roleName === RoleTypes.LEAD_DASHBOARD_VIEWONLY ||
                          role.roleName === RoleTypes.LEAD_DASHBOARD_ADMIN) &&
                          userIsDealer)) && (
                        <div key={role.roleName} className="role-checkbox-item">
                          <div className="role-checkbox">
                            <input
                              id={role.roleName}
                              type="checkbox"
                              disabled={
                                !isLeadGenAdmin && !isLeadGenDistributorAdmin
                              }
                              defaultChecked={role.selected}
                              onChange={e => handleRoleChange(role, e)}
                              className="style-checkbox"
                            />
                            <label htmlFor={role.roleName} />
                            <label
                              htmlFor={role.roleName}
                              className={`role-title ${
                                !isLeadGenAdmin && !isLeadGenDistributorAdmin
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              {intl.get(role.roleName.toLowerCase()) ||
                                role.roleName}
                            </label>
                          </div>
                        </div>
                      )
                  )}
              </div>
            </>
          )}
          {isAddAssociateOpen && ecommerceUser && (
            <p className="role-message admin-role">
              *{intl.get("admin-user-description")}
            </p>
          )}
          {isAddAssociateOpen &&
            ecommerceUser &&
            allAssociateRoles
              .filter(
                role =>
                  role &&
                  role.roleName &&
                  role.roleName.toUpperCase() === RoleTypes.BUYER_ADMIN
              )
              .map(role => (
                <div key={role.roleName} className="role-checkbox-item">
                  <div className="role-checkbox">
                    <input
                      id={role.roleName}
                      type="checkbox"
                      defaultChecked={role.selected}
                      onChange={e => handleRoleChange(role, e)}
                      className="style-checkbox"
                    />
                    <label htmlFor={role.roleName} />
                    <label htmlFor={role.roleName} className="role-title">
                      {intl.get(role.roleName.toLowerCase()) || role.roleName}
                    </label>
                  </div>
                </div>
              ))}
        </div>
      )
    );
  };

  const renderAdminWarningMessage = () => {
    return (
      <Modal
        open={adminWarning}
        onClose={() => setAdminWarning(false)}
        classNames={{
          modal: "admin-warning-modal",
          overlay: "admin-warning-overlay",
          closeButton: "b2b-dialog-close-btn"
        }}
      >
        <div className="admin-warning-body">
          <p>{intl.get("admin-warning")}</p>
          <button
            type="button"
            className="dast-btn dast-btn-primary"
            onClick={() => setAdminWarning(false)}
          >
            {intl.get("ok")}
          </button>
        </div>
      </Modal>
    );
  };

  const handleRoleChange = (role: Role, e: any): void => {
    const changes: Array<Role> = [...changedRoles];

    const roleIndex = changes.findIndex(r => r.roleName === role.roleName);
    if (roleIndex !== -1) {
      changes.splice(roleIndex, 1);
    } else {
      changes.push(role);
    }

    const isAdminWarning: boolean =
      Boolean(
        changes.find((ch: Role) => ch.roleName === RoleTypes.BUYER_ADMIN)
      ) && role.roleName === RoleTypes.BUYER_ADMIN;

    setAdminWarning(isAdminWarning);
    setChangedRoles(changes);

    setErrors({ ...initialInputs });
  };

  const handleErrorResponse = (err): void => {
    const errorObj: SubUserInputs = { ...initialInputs };
    errorObj.existingUserMessage = err.messages[0]["debug-message"];
    setErrors(errorObj);
    setIsLoading(false);
  };

  const handleSubAccountAdded = (
    email: string,
    firstname: string,
    lastname: string,
    roles: Array<string>,
    uri: string
  ): void => {
    const { scope } = getConfig().config.cortexApi;
    const { addAssociateUri, handleUpdate } = props;

    const urlPost: string = `/subusermessage/${scope}/form`;
    const url = isEditOpen ? uri : urlPost;
    const accountguidencoded: string = addAssociateUri.slice(
      addAssociateUri.lastIndexOf(scope) + scope.length + 1
    );
    const accountguid = base32.decode(accountguidencoded);
    setIsLoading(true);

    let methodType;
    if (isEditOpen) {
      methodType = "put";
    } else {
      methodType = "post";
    }
    let body;
    if (isEditOpen) {
      body = JSON.stringify({
        email,
        roles,
        "first-name": firstname,
        "last-name": lastname,
        status: "ACTIVE"
      });
    } else {
      body = JSON.stringify({
        accountguid,
        email,
        roles,
        firstname,
        lastname
      });
    }
    cortexFetch(url, {
      method: methodType,
      body
    })
      .then(res => {
        if (res.status === 400) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        const onSuccess = data => data;

        return checkResponse(res, onSuccess);
      })
      .then(() => {
        setIsLoading(false);

        handleModalClose();
        handleUpdate();
      })
      .catch(err => {
        if (err.messages && err.messages[0]) {
          handleErrorResponse(err);
          return;
        }
        handleExpiredToken(err);
      });
  };

  const validateFormInputs = (
    email: string,
    firstName: string,
    lastName: string
  ): ErrorObj => {
    const errorObj: SubUserInputs = { ...initialInputs };

    errorObj.email = isInvalidEmail(email);

    if (!firstName.trim()) {
      errorObj.firstName = intl.get("field-cannot-be-empty");
    }

    if (!lastName.trim()) {
      errorObj.lastName = intl.get("field-cannot-be-empty");
    }

    if (errorObj.rolesSelected.length) {
      errorObj.rolesSelected[0] = "You must select a role";
    } else {
      errorObj.rolesSelected = null;
    }

    const isValid: boolean = !Object.values(errorObj).filter(value => value)
      .length;

    return {
      errors: errorObj,
      isValid
    };
  };

  const handleAddAssociateClicked = (): any => {
    const { email, firstName, lastName, uri } = subUser;

    const { errors: errorMessages, isValid }: ErrorObj = validateFormInputs(
      email,
      firstName,
      lastName
    );

    let editedRoles;
    const roles: Array<string> = changedRoles.map(role =>
      role.roleName.replace(" ", "_").toUpperCase()
    );

    if (!isEditOpen) {
      roles.push(RoleTypes.BUYER);
    } else {
      editedRoles = [...roles].join();
    }

    if (!isValid || (!isEditOpen && !roles.length)) {
      setErrors(errorMessages);

      return;
    }

    const selectedRoles = isEditOpen ? editedRoles : roles;
    handleSubAccountAdded(email, firstName, lastName, selectedRoles, uri);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;

    setSubUser(prevState => {
      const updatedState = { ...subUser };
      updatedState[name] = value;

      return { ...prevState, ...updatedState };
    });

    setErrors({ ...initialInputs });
  };

  const handleModalClose = (): void => {
    const { handleClose } = props;
    setSubUser({ ...initialInputs });
    setErrors({ ...initialInputs });
    setRolesUpdated(false);
    setChangedRoles([]);
    handleClose();
    setAdminWarning(false);
    setRolesRendered(false);
  };

  const handleExpiredToken = err => {
    const {
      history,
      auth: { logout }
    } = props;

    if (checkTokensExpired(err)) {
      logout().catch(e =>
        pushToMaintenace(history, {
          e,
          errIn: "Logout => handleExpiredToken => B2BEditAssociate.tsx"
        })
      );
    } else {
      pushToMaintenace(history, {
        e: err,
        errIn: "handleExpiredToken => B2BEditAssociate.tsx"
      });
    }
  };

  const { isAddAssociateOpen, associateEmail } = props;
  const { email, firstName, lastName } = subUser;

  const filterRoles = () => {
    if (isEditOpen) {
      const filteredRoles = associate.roles.filter(
        role =>
          role === RoleTypes.ECOMM_VIEW_CREDIT ||
          role === RoleTypes.ECOMM_VIEW_STATEMENTS ||
          role === RoleTypes.ECOMMERCE_BUYER ||
          role === RoleTypes.LEAD_DASHBOARD_ADMIN ||
          role === RoleTypes.LEADGEN_DISTRIBUTOR_ADMIN ||
          role === RoleTypes.LEAD_DASHBOARD_VIEWONLY ||
          role === RoleTypes.VIEW_DISTRIBUTOR_REPORTS ||
          role === RoleTypes.VIEW_DEALER_REPORTS
      );

      if (!filteredRoles.length) {
        setRolesUpdated(true);
      }
      initialRoles = filteredRoles.map(role => {
        const roleChanged = {
          roleName: role,
          selectRoleURI: "",
          selected: true
        };
        return roleChanged;
      });
      initialInputs = {
        email: associateEmail,
        firstName: associate.firstName,
        lastName: associate.lastName,
        rolesSelected: associate.roles,
        existingUserMessage: "",
        uri: associate.uri
      };
      setRolesRendered(true);
    } else if (isAddAssociateOpen) {
      initialInputs = {
        email: "",
        firstName: "",
        lastName: "",
        rolesSelected: [],
        existingUserMessage: "",
        uri: ""
      };

      if (isAddAssociateOpen && ecommerceUser) {
        initialRoles = [
          {
            roleName: RoleTypes.ECOMMERCE_BUYER,
            selectRoleURI: "",
            selected: true
          }
        ];
        setRolesRendered(true);
      } else {
        initialRoles = [];
        setRolesRendered(true);
      }
    } else {
      initialInputs = {
        email: "",
        firstName: "",
        lastName: "",
        rolesSelected: [],
        existingUserMessage: "",
        uri: ""
      };
      initialRoles = [];
    }
    setSubUser({ ...initialInputs });
    setChangedRoles(initialRoles);
  };

  useEffect(() => {
    filterRoles();
  }, [isEditOpen, isAddAssociateOpen]);

  return (
    <Modal
      open={isEditOpen || isAddAssociateOpen}
      onClose={handleModalClose}
      classNames={{
        modal: "b2b-edit-associate-dialog",
        closeButton: "b2b-dialog-close-btn"
      }}
    >
      {renderAdminWarningMessage()}
      <h4 className="dialog-header">
        {isEditOpen ? intl.get("edit-user") : intl.get("add-user")}
      </h4>
      <div className="dialog-content">
        {errors && errors.existingUserMessage ? (
          <p className="validation-error"> {errors.existingUserMessage}</p>
        ) : null}
        <div className="am-columns title-section">
          <div className="am-field-editor">
            <div className="associate-input-title">{intl.get("email")}</div>
            <Input
              label=""
              type="email"
              inputName="email"
              ariaLabel={intl.get("email")}
              inputHandler={handleInputChange}
              disabled={isEditOpen}
              value={isEditOpen ? associateEmail : email}
              errors={errors}
              required
            />
          </div>

          <div>
            <div className="account-title">{intl.get("account")}</div>
            <p>{company}</p>
            <p>{customerNumber}</p>
          </div>
        </div>
        <div className="am-columns">
          <div className="am-field-editor">
            <div className="associate-input-title">
              {intl.get("first-name")}
            </div>
            <Input
              label=""
              type="text"
              inputName="firstName"
              ariaLabel={intl.get("first-name")}
              inputHandler={handleInputChange}
              value={firstName}
              errors={errors}
              required
            />
          </div>
        </div>

        <div className="am-columns">
          <div className="am-field-editor">
            <div className="associate-input-title">{intl.get("last-name")}</div>
            <Input
              label=""
              type="text"
              inputName="lastName"
              ariaLabel={intl.get("last-name")}
              inputHandler={handleInputChange}
              value={lastName}
              errors={errors}
              required
            />
          </div>
        </div>

        <div className="am-columns role-column">
          <div className="checkbox-role-title">{intl.get("role")}</div>
          {renderRoleSelection()}
        </div>

        {errors && errors.rolesSelected && errors.rolesSelected.length ? (
          <p className="validation-error"> {errors.rolesSelected}</p>
        ) : null}
      </div>
      <div className="dialog-footer add-associate-buttons">
        <button
          className="dast-btn dast-btn-secondary"
          type="button"
          onClick={handleModalClose}
        >
          {intl.get("cancel").toUpperCase()}
        </button>
        <button
          className="dast-btn dast-btn-primary"
          type="button"
          onClick={handleAddAssociateClicked}
        >
          {intl.get("save").toUpperCase()}
        </button>
      </div>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="miniLoader" />
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default B2bEditAssociate;
