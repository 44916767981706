import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Menu as BrMenu,
  Reference,
  isMenu,
  MenuItem,
  TYPE_LINK_EXTERNAL
} from "@bloomreach/spa-sdk";
import {
  BrManageMenuButton,
  BrComponentContext,
  BrPageContext
} from "@bloomreach/react-sdk";
import { getConfig, IEpConfig } from "../../utils/ConfigProvider";
import CreateLink from "../../Bloomreach/components/BRlink/brlink";
import "../appfooter.main.less";

interface MenuModel {
  menu: Reference;
}

const AppFooterMenu: React.FC = () => {
  const component = useContext(BrComponentContext);
  const page = useContext(BrPageContext);

  const menuRef = component.getModels<MenuModel>().menu;
  const menu = menuRef && page.getContent<BrMenu>(menuRef);

  if (!isMenu(menu)) {
    return null;
  }

  const editButton = <BrManageMenuButton menu={menu} />;

  const createLinks = () => {
    const scrollTop = true;
    return menu.getItems().map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={index}>
        <CreateLink menuItem={item} />
      </li>
    ));
  };

  return (
    <>
      <ul className={`menulink ${page.isPreview() ? "has-edit-button" : ""}`}>
        <BrManageMenuButton menu={menu} />
        {createLinks()}
      </ul>
    </>
  );
};

export default AppFooterMenu;
