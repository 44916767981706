import { useState, useEffect } from "react";
import { getConfig } from "@zilker/store-components";
import { fetchProfile } from "../services/EpServices";
import * as LoginService from "../services/LoginService";
import {
  checkTokensExpired,
  generateAddressString,
  createNotificationsPhoneList
} from "../utils/helpers";

const initialUserProfile = {
  familyName: "",
  givenName: "",
  phone: "",
  fax: "",
  preferedCurrency: "",
  preferredLocale: "",
  email: "",
  optInSmsAlert: "",
  optInOrderStatusEmail: "",
  optInDeliveryStatusEmail: "",
  businessNumber: "",
  htmlEmail: "",
  taxЕxemptionId: "",
  custAttribute1: "",
  custAttribute2: "",
  addresses: "",
  profileUri: "",
  defaultShippingAddress: "",
  extendedBillingAddress: "",
  defaultShippingAddressString: "",
  onlineBillPayLink: "",
  optOutMarketing: "",
  accountUsers: "",
  roles: "",
  isTSMDealer: "",
  hasError: false,
  ecommerceUser: false,
  ecommerceBuyer: false,
  isInitialProfile: true
};

const TSM_ROLE = "TSM_DEMO";
const ECOMMERCE_BUYER = "ECOMMERCE_BUYER";
const LEADGEN_ADMIN = "LEADGEN_ADMIN";
const LEADGEN_DISTRIBUTOR_ADMIN = "LEADGEN_DISTRIBUTOR_ADMIN";
const VIEW_DISTRIBUTOR_REPORTS = "VIEW_DISTRIBUTOR_REPORTS";
const ECOMMERCE_USER = "ECOMMERCE";
const LEADGEN_VIEWONLY = "LEADGEN_VIEWONLY";
const VIEW_ALL_DEALER_REPORTS = "VIEW_DEALER_REPORTS";

// eslint-disable-next-line import/prefer-default-export
export const ProfileContext = () => {
  const [userProfile, setProfile] = useState(initialUserProfile);
  const checkLogin = LoginService.isLoggedIn();
  const { config } = getConfig();
  const authToken = localStorage.getItem(
    `${config.cortexApi.scope}_oAuthToken`
  );
  const accountSharedId = localStorage.getItem(
    `${config.cortexApi.scope}_AccountSharedId`
  );

  useEffect(() => {
    if (checkLogin && authToken && accountSharedId) {
      getUserProfile();
    }
  }, [checkLogin, authToken, accountSharedId]);

  function getUserProfile() {
    fetchUserProfile(setProfile);
  }

  function removeUserProfile() {
    setProfile(initialUserProfile);
  }

  return {
    userProfile,
    getUserProfile,
    removeUserProfile,
    setUserProfile,
    setProfile
  };
};

function setUserProfile(res, setProfile) {
  let profile;
  const { config } = getConfig();
  {
    let addressInfo = {
      locality: "",
      "extended-address": "",
      "postal-code": "",
      region: "",
      "street-address": "",
      "country-name": ""
    };

    let extendedBillingAddress = "";

    let defaultShippingAddress = {
      "country-name": "",
      "extended-address": "",
      locality: "",
      organization: "",
      "phone-number": "",
      "postal-code": "",
      region: "",
      "street-address": "",
      uri: ""
    };

    let defaultShippingAddressString = "";

    const defaultProfile = res._defaultprofile[0];

    const createSmsPhoneList = () => {
      return createNotificationsPhoneList(
        defaultProfile.phone,
        defaultProfile["sms-phone-numbers"]
      );
    };

    const smsPhoneNumbersList = createSmsPhoneList();

    if (defaultProfile._addresses[0]._shippingaddresses) {
      // eslint-disable-next-line prefer-destructuring
      defaultShippingAddress =
        defaultProfile._addresses[0]._shippingaddresses[0]._default[0].address;
      defaultShippingAddress.uri =
        defaultProfile._addresses[0]._shippingaddresses[0]._default[0].self.uri;

      defaultShippingAddressString = generateAddressString(
        defaultShippingAddress
      );
    }

    if (defaultProfile._addresses[0]._billingaddresses) {
      addressInfo =
        defaultProfile._addresses[0]._billingaddresses[0]._default[0].address;

      extendedBillingAddress = generateAddressString(addressInfo);
    }

    const email = defaultProfile._emails[0]._element
      ? defaultProfile._emails[0]._element[0].email
      : "";

    if (defaultProfile["client-list"]) {
      defaultProfile.clientList = JSON.parse(defaultProfile["client-list"]);
    }

    const tempRoles =
      defaultProfile.roles &&
      JSON.parse(defaultProfile.roles).map(role => role.toUpperCase());

    const leadGenDistributorRole = tempRoles.find(
      role =>
        role === LEADGEN_DISTRIBUTOR_ADMIN || role === VIEW_DISTRIBUTOR_REPORTS
    );

    const leadGenRoles = tempRoles.find(
      role =>
        role === LEADGEN_ADMIN ||
        role === LEADGEN_VIEWONLY ||
        role === VIEW_ALL_DEALER_REPORTS
    );

    if (
      defaultProfile._accountusers &&
      defaultProfile._accountusers[0] &&
      defaultProfile._accountusers[0]._element
    ) {
      defaultProfile.accountUsers = defaultProfile._accountusers[0]._element.map(
        accountuser => ({
          email: accountuser.email,
          firstName: accountuser["first-name"],
          lastName: accountuser["last-name"],
          uri: accountuser.self.uri,
          roles: accountuser.roles
            ? JSON.parse(accountuser.roles).map(role => role.toUpperCase())
            : []
        })
      );
    }

    profile = {
      familyName: defaultProfile["family-name"],
      givenName: defaultProfile["given-name"],
      phone: defaultProfile.phone,
      fax: defaultProfile.fax,
      preferedCurrency: defaultProfile["preferred-currency"],
      preferredLocale: defaultProfile["preferred-locale"],
      email,
      city: addressInfo.locality,
      postalCode: addressInfo["postal-code"],
      region: addressInfo.region,
      street: addressInfo["street-address"],
      country: addressInfo["country-name"],
      optInSmsAlert: defaultProfile["opt-in-sms-alert"],
      optInOrderStatusEmail: defaultProfile["opt-in-order-status-email"],
      optInDeliveryStatusEmail: defaultProfile["opt-in-delivery-status-email"],
      smsPhoneNumbers: smsPhoneNumbersList,
      orderStatusEmails: defaultProfile["order-status-emails"],
      deliveryStatusEmails: defaultProfile["delivery-status-emails"],
      businessNumber: defaultProfile["business-number"],
      htmlEmail: defaultProfile["html-email"],
      taxЕxemptionId: defaultProfile["tax-exemption-id"],
      custAttribute1: defaultProfile.cust_Attribute1,
      custAttribute2: defaultProfile.cust_Attribute2,
      addresses: defaultProfile._addresses,
      profileUri: defaultProfile.self.uri,
      defaultShippingAddress,
      extendedBillingAddress,
      defaultShippingAddressString,
      isCanadianUser: addressInfo["country-name"] === "CA",
      clientList: defaultProfile.clientList,
      onlineBillPayLink:
        addressInfo["country-name"] === "CA"
          ? config.onlineBillingWebsiteCA
          : config.onlineBillingWebsiteUS,
      optOutMarketing: defaultProfile["opt-out-marketing"],
      accountUsers: defaultProfile.accountUsers,
      roles: defaultProfile.roles
        ? JSON.parse(defaultProfile.roles).map(role => role.toUpperCase())
        : "",
      leadGenDealer: defaultProfile["lead-gen-dealer"],
      leadGenDistributor: leadGenDistributorRole,
      leadGenDealerEmail: email,
      hasLeadGenRoles: leadGenRoles,
      hasLeadGenDistributorRoles: leadGenDistributorRole
    };

    profile.isTSMDealer = profile.roles && profile.roles.includes(TSM_ROLE);

    const userRoles = profile.roles;
    profile.ecommerceUser = !!(
      userRoles && userRoles.find(role => role === ECOMMERCE_USER)
    );
    profile.ecommerceBuyer = !!(
      userRoles && userRoles.find(role => role === ECOMMERCE_BUYER)
    );

    setProfile(profile);
  }
}

function fetchUserProfile(setProfile) {
  let profile;
  fetchProfile()
    .then(res => setUserProfile(res, setProfile))
    .catch(error => {
      console.error("error: ", error);
      // If tokens are expired delegate session expired handling to
      // appheader.main fetchNavigationData
      if (!checkTokensExpired(error)) {
        profile = initialUserProfile;
        profile.hasError = error.message;
        setProfile(profile);
      }
    });
}
