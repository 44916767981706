/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { BrComponent } from "@bloomreach/react-sdk";
import { CookieAcceptancePopup } from "@zilker/store-components";
import AppFooterMenu from "./AppFooterColumns/appfootermenu";
import AppFooterLogo from "./AppFooterColumns/appfooterlogo";
import { getConfig } from "../utils/ConfigProvider";
import "./appfooter.main.less";

interface AppFooterMainProps {
  appFooterLinks?: {
    [key: string]: any;
  };
}

function AppFooterMain(props: AppFooterMainProps) {
  const { config } = getConfig();
  return (
    <>
      <BrComponent path="cookie-acceptance-popup">
        <CookieAcceptancePopup />
      </BrComponent>
      <footer className="app-footer">
        <div className="container">
          <div className="main-row">
            <div className="logo-footer-container">
              <BrComponent path="footer-logo">
                <AppFooterLogo />
              </BrComponent>
            </div>
            <div className="menu-container">
              <div className="column-menu">
                <BrComponent path="footer-menu">
                  <AppFooterMenu />
                </BrComponent>
              </div>
              <div className="column-menu">
                <BrComponent path="footer-menu-2">
                  <AppFooterMenu />
                </BrComponent>
              </div>
              <div className="column-menu">
                <BrComponent path="footer-menu-3">
                  <AppFooterMenu />
                </BrComponent>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default AppFooterMain;
