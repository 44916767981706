import React, { useContext } from "react";
import intl from "react-intl-universal";
import {
  CheckoutSummaryList,
  getConfig,
  AddPromotionContainer,
  PaymentMethods,
  Messagecontainer
} from "@zilker/store-components";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { noOp } from "../../../app/src/utils/helpers";

interface OrderSummarySectionProps {
  orderData: {
    [key: string]: any;
  };
  missingFields: string;
  disableCompleteOrder: boolean;
  onCompleteOrder: () => any;
  resetMissingFields: () => any;
  fetchOrderData: (...args: any[]) => any;
  history: any;
  auth: any;
}

const OrderSummarySection: React.FC<OrderSummarySectionProps> = props => {
  const {
    orderData,
    missingFields,
    disableCompleteOrder,
    onCompleteOrder,
    resetMissingFields,
    fetchOrderData,
    history,
    auth
  } = props;

  const { config } = getConfig();
  const { enablePromotion, calculatePrice } = config;

  const context = useContext<{ cart: any }>(MainContext);
  const {
    cart: { selectedPayment, shippingOptions, shippingAddresses }
  } = context;

  const renderPromotions = (): any => {
    if (
      orderData &&
      orderData._order &&
      orderData._order[0] &&
      orderData._order[0]._cartorderdetails &&
      orderData._order[0]._cartorderdetails[0] &&
      orderData._order[0]._cartorderdetails[0]._cartorderdetailsform &&
      orderData._order[0]._cartorderdetails[0]._cartorderdetailsform[0]
    ) {
      const data = {
        cartOrderDetailsForm:
          orderData._order[0]._cartorderdetails[0]._cartorderdetailsform[0]
      };

      return (
        <AddPromotionContainer
          isDeletingPromoCode={false}
          hideAddPromotionButton
          hideDeletePromotionButton
          data={data}
          history={history}
          auth={auth}
          /** When hideAddPromotionButton and hideDeletePromotionButton are true, onSubmittedPromotion is never called.
           * Thus, passing noOp function as a placeholder for required prop.
           */
          onSubmittedPromotion={noOp}
        />
      );
    }

    return null;
  };

  return (
    <div className="checkout-sidebar-sticky">
      <h2 className="section-title">{intl.get("order-summary")}</h2>
      {enablePromotion && renderPromotions()}
      <CheckoutSummaryList
        data={orderData}
        onChange={() => {
          fetchOrderData();
        }}
        history={history}
        auth={auth}
      />
      <button
        className="dast-btn-primary dast-btn wide submit-order"
        aria-label={intl.get("complete-order")}
        type="button"
        disabled={
          (selectedPayment === PaymentMethods.kinpay && disableCompleteOrder) ||
          (shippingOptions && !shippingOptions.length) ||
          (shippingAddresses && !shippingAddresses.length)
        }
        onClick={onCompleteOrder}
      >
        {intl.get("complete-order")}
      </button>
      {calculatePrice ? (
        <p className="price-disclaimer">
          *{intl.get("commodity-prices-may-vary-at-final-checkout")}
        </p>
      ) : null}
      {missingFields && (
        <div className="required-fields-fail">
          <Messagecontainer
            message={{
              type: "",
              debugMessages: missingFields
            }}
            closeContainerHandler={resetMissingFields}
          />
        </div>
      )}
    </div>
  );
};

export default OrderSummarySection;
