import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ImageSet, Page } from "@bloomreach/spa-sdk";
import { isLinkExternal } from "../../../app/src/utils/helpers";
import { getConfig } from "../utils/ConfigProvider";

import "./articles-card.less";

interface ArticlesCardProps {
  image: string;
  header: string;
  subHeader: string;
  buttonText: string;
  urlLink: string;
  page: Page;
  imageAltText: string;
}

const ArticlesCard: FC<ArticlesCardProps> = ({
  image,
  header,
  subHeader,
  buttonText,
  urlLink,
  page,
  imageAltText
}) => {
  const [isExternal, url] = isLinkExternal(urlLink);
  const { config } = getConfig();
  const imgMissingHorizontal = config.missingImagePlaceholderUrl;
  const imageLink = image && page.getContent<ImageSet>(image);

  return (
    <div className="articles-card">
      {image && (
        <div className="articles-card-image-wrapper">
          <img
            className="articles-card-image"
            src={imageLink.getOriginal().getUrl()}
            alt={imageAltText}
            onError={e => {
              const element: any = e.target;
              element.src = imgMissingHorizontal;
            }}
          />
        </div>
      )}
      <div className="articles-card-header">
        <h3>{header}</h3>
      </div>
      <p className="articles-card-subheader">{subHeader}</p>
      {buttonText && (
        <Link
          to={isExternal ? { pathname: url } : url}
          target={!isExternal ? "_self" : "_blank"}
          rel={isExternal ? "noopener noreferrer" : ""}
          className="articles-link dast-btn dast-btn-primary"
        >
          {buttonText}
        </Link>
      )}
    </div>
  );
};

export default ArticlesCard;
