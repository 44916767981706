import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import QuickHitsHorizontalCard from "../../../QuickHitsHorizontalCard/quick-hits-horizontal-card.main";
import QuickHitsHorizontalCardInterface from "../../../QuickHitsHorizontalCard/types/QuickHitsHorizontalCard.interfaces";

import "./quick-hits-horizontal.less";

const QuickHitsHorizontal = ({ component, page }: BrProps) => {
  const { document: documentRef, title } = component.getModels();
  const content = documentRef && page.getContent(documentRef);
  const quickHitsHorizontalCardList = content
    ? content.getData().quickHitsHorizontalCardList
    : [];

  const setCardsStyle = () => {
    switch (quickHitsHorizontalCardList.length) {
      case 0:
        return "";
      case 1:
        return "one-card-container";
      case 2:
        return "two-card-container";
      case 3:
        return "three-card-container";
      case 4:
        return "four-card-container";
      default:
        return "";
    }
  };
  const cardsStyle = setCardsStyle();

  const renderCards = () => {
    return (
      <div className={`quick-hits-horizontal-card-list ${cardsStyle}`}>
        {quickHitsHorizontalCardList.map(
          (card: QuickHitsHorizontalCardInterface, index) => {
            const id = `quick-hits-horizontal-card-${index}`;
            return (
              <QuickHitsHorizontalCard
                header={card.header}
                image={card.image}
                redirectLink={card.redirectLink}
                subtext={card.subtext}
                key={id}
                buttonText={card.buttonText}
                page={page}
              />
            );
          }
        )}
      </div>
    );
  };

  return (
    <div className="quick-hits-horizontal-container container">
      {title && (
        <div className="quick-hits-horizontal-title">
          <h2>{title}</h2>
        </div>
      )}
      {renderCards()}
    </div>
  );
};

export default QuickHitsHorizontal;
