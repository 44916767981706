/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useContext, useState, useEffect } from "react";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage
} from "@elasticpath/ref-store/src/utils/helpers";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { cortexFetch } from "../utils/Cortex";
import { getConfig } from "../utils/ConfigProvider";

import "./MatchUpBundle.less";

interface MatchUpItem {
  _id: string;
  bold: boolean;
  description: string;
  name: string;
  sequence: number;
  sku: string;
}

interface MatchUpLine {
  _id: string;
  addToCart: boolean;
  isHeader: boolean;
  items: MatchUpItem[];
  sequence: number;
}

interface MatchUpGroup {
  _id: string;
  lines: MatchUpLine[];
  name: string;
}

export interface MatchUpBundleInterface {
  _id: string;
  branchNumber: string;
  createdAt: string;
  groups: MatchUpGroup[];
  name: string;
  sequence: number;
  updatedAt: string;
}

interface MatchUpBundleProps {
  bundle: MatchUpBundleInterface;
  bundleRefs: any;
}

const isDesktopScreen = (): boolean => window.innerWidth > 768;

const MatchUpBundle: React.FC<MatchUpBundleProps> = ({
  bundle,
  bundleRefs
}) => {
  const { groups } = bundle;
  const [currentLine, setCurrentLine] = useState<string>("");
  const [isDesktop, setIsDesktop] = useState<boolean>(isDesktopScreen());
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const context = useContext<{ cart: any; auth: any }>(MainContext);
  const history = useHistory();

  const { config } = getConfig();
  const { useAlternativeMobileMatchUps } = config;

  const toggleScrollVisibility = (): void => {
    setScrollToTop(Boolean(window.pageYOffset > 300));
  };

  useEffect(() => {
    // Detect screen width change
    window.addEventListener("resize", () => setIsDesktop(isDesktopScreen()));
    // Detect if the user has scrolled the page
    window.addEventListener("scroll", toggleScrollVisibility);

    // Clean up event listeners
    return () => {
      window.removeEventListener("resize", () =>
        setIsDesktop(isDesktopScreen())
      );
      window.removeEventListener("scroll", toggleScrollVisibility);
    };
  }, []);

  const handleScrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const addProductToCart = (event, line: MatchUpLine): void => {
    setCurrentLine(event.target.name);
    const { items } = line;
    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        },
        setErrorCartPopupMessage
      },
      auth: { logout }
    } = context;

    const body = {
      items: items.map(item => ({ code: item.sku, quantity: 1 }))
    };

    addToCart(addItemsToCart.self.uri, body)
      .then(res => {
        updateCartContex(body.items);
        setCurrentLine("");
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => addProductToCart => MatchUpBundle.tsx"
            })
          );
        } else {
          setCurrentLine("");
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        }
      });
  };

  const updateCartContex = (addedItems): void => {
    const {
      cart: { getCartDetails, setSuccesCartPopupMessage }
    } = context;
    getCartDetails()
      .then(res => {
        setSuccesCartPopupMessage(addedItems.length);
      })
      .catch(e =>
        pushToMaintenace(history, {
          e,
          errIn: "getCartDetails => MatchUpBundle.tsx"
        })
      );
  };

  const getLabels = (lines: MatchUpLine[]): string[] => {
    const labelLine = lines && lines.find((line: MatchUpLine) => line.isHeader);
    const labelItems =
      labelLine &&
      labelLine.items &&
      labelLine.items.map((item: MatchUpItem) => item.name);

    return labelItems;
  };

  function getGroups(): any {
    return groups.map((group: MatchUpGroup) => {
      // For Mobile Screens - get labels beforehand
      const headerLabels: string[] = getLabels(group.lines);

      return (
        <div
          key={group._id}
          className="mainBundle content-box content-table"
          ref={el => (bundleRefs[group._id] = el)}
        >
          <h4>{group.name}</h4>
          <div className="bundle-table">
            {getLines(group.lines, headerLabels)}
          </div>
          {!isDesktop && scrollToTop && (
            <button
              type="button"
              className="scroll-to-top-match-up dast-btn"
              onClick={handleScrollToTop}
            >
              <i className="icon-chevron-up" />
            </button>
          )}
        </div>
      );
    });
  }

  function getLines(lines: MatchUpLine[], headerLabels: string[]): any {
    return lines.map((line: MatchUpLine, i: number) => {
      const { items } = line;
      if (!items) return null;
      return (
        <div
          key={line._id}
          className={`${
            useAlternativeMobileMatchUps ? "alternative-mobile" : ""
          } bundle`}
        >
          <div
            className={`bundleRow ${i === 0 ? `table-labels` : `table-item`}`}
          >
            {// For Mobile screen, if alternative flag is enabled - show items before add to cart button.
            !isDesktop &&
              useAlternativeMobileMatchUps &&
              getItems(items, headerLabels)}
            {i === 0 && <div className="match-up-line" />}
            {i !== 0 &&
              // eslint-disable-next-line no-nested-ternary
              (line.addToCart ? (
                currentLine === line._id ? (
                  <div className="miniLoader" />
                ) : (
                  <button
                    type="button"
                    aria-label={intl.get("add-to-cart")}
                    name={line._id}
                    className="dast-btn dast-btn-primary"
                    onClick={e => addProductToCart(e, line)}
                  >
                    {intl.get("add-to-cart")}
                  </button>
                )
              ) : (
                <button
                  aria-label={intl.get("call")}
                  type="button"
                  className="dast-btn dast-btn-secondary"
                >
                  <i className="icon-phone" />
                  {intl.get("call")}
                </button>
              ))}
            {// If alternative flag is enabled, for desktop - show items after the button.
            // If the flag is not enabled - show items after the button, for both screens.
            (useAlternativeMobileMatchUps && isDesktop) ||
            !useAlternativeMobileMatchUps
              ? getItems(items, headerLabels)
              : null}
          </div>
        </div>
      );
    });
  }

  function getItems(items: MatchUpItem[], headerLabels: string[]): any {
    return items.map((item: MatchUpItem, index: number): any => {
      const headerLabel: string = headerLabels[index];

      return headerLabel ? (
        <div className="match-up-line" key={item._id}>
          <span className="mobile-match-up-label">{`${headerLabel}: `}</span>
          <span>{item.name}</span>
        </div>
      ) : null;
    });
  }

  return getGroups();
};

export default MatchUpBundle;
