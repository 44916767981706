import React, { FC } from "react";
import { Link } from "react-router-dom";
import { isLinkExternal } from "../../../app/src/utils/helpers";
import HomePageCard from "../HomePageCard/home-page-card.main";

import "./quick-hits-horizontal-card.main.less";
import "./types/QuickHitsHorizontalCard.interfaces";

interface QuickHitsHorizontalCardProps {
  header: string;
  image: string;
  subtext?: string;
  redirectLink: string;
  buttonText?: string;
  page?: any;
}

const QuickHitsHorizontalCard: FC<QuickHitsHorizontalCardProps> = ({
  header,
  image,
  subtext,
  redirectLink,
  buttonText,
  page
}) => {
  const [isExternal, url] = isLinkExternal(redirectLink);
  const imgMissingHorizontal =
    "/site/binaries/content/gallery/placeholder/placeholder.jpg";
  const cardImage = page
    .getContent(image)
    .getOriginal()
    .getUrl();

  return (
    <HomePageCard justifyContent="center">
      <Link
        to={isExternal ? { pathname: url } : url}
        target={!isExternal ? "_self" : "_blank"}
        rel={isExternal ? "noopener noreferrer" : ""}
        className="quick-hits-horizontal-card"
      >
        {image && (
          <div className="quick-hits-image-wrapper">
            <img
              className="quick-hits-horizontal-card-image"
              src={cardImage}
              alt={header}
              onError={e => {
                const element: any = e.target;
                element.src = imgMissingHorizontal;
              }}
            />
          </div>
        )}
        <div className="quick-hits-horizontal-card-header">
          <h3>{header}</h3>
        </div>
        <p className="quick-hits-desc">{subtext}</p>
        {buttonText && (
          <button type="button" className="dast-btn dast-btn-primary">
            {buttonText}
          </button>
        )}
      </Link>
    </HomePageCard>
  );
};

export default QuickHitsHorizontalCard;
