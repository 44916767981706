import React, { useContext } from "react";
import { BrComponentContext, BrPageContext } from "@bloomreach/react-sdk";
import {
  Menu as BrMenu,
  Reference,
  isMenu,
  MenuItem,
  TYPE_LINK_EXTERNAL
} from "@bloomreach/spa-sdk";
import { Link } from "react-router-dom";
import { getConfig, IEpConfig } from "../../../utils/ConfigProvider";

interface BrLinkProps {
  menuItem?: MenuItem;
}

const BRlink = ({ menuItem }: BrLinkProps) => {
  const { config }: { config: IEpConfig } = getConfig();
  const { oneTrustDataDomainScript } = config;

  if (menuItem.getName() === "Cookie and Ad Settings") {
    return oneTrustDataDomainScript ? (
      <button type="button" id="ot-sdk-btn" className="ot-sdk-show-settings">
        {menuItem.getName()}
      </button>
    ) : null;
  }

  // Note that link type "external" just means that the link isn't configured in BR as "internal".
  // "Internal" links in BR point to some content that exists in bloomreach, however an "external"
  // link can still point to a page in the react app.
  // In short, "external" does not neccesarily mean "on a different domain".
  if (menuItem.getLink() && menuItem.getLink().type === TYPE_LINK_EXTERNAL) {
    if (
      menuItem.getUrl().startsWith("http") ||
      menuItem.getUrl().startsWith("www")
    ) {
      return (
        <a href={menuItem.getUrl()} target="_blank" rel="noopener noreferrer">
          {menuItem.getName()}
        </a>
      );
    }

    // Sometimes we have internal links to the site that don't start with a '/' and
    // thus can end up with a working link on the homepage, but child pages break the link.
    // ex. "somePage" ends up as "/category/somePage" when it's rendered on a nested page.
    if (!menuItem.getUrl().startsWith("/")) {
      return (
        <Link to={`/${menuItem.getUrl()}`} target="_blank">
          {menuItem.getName()}
        </Link>
      );
    }

    return (
      <Link to={`${menuItem.getUrl()}`} target="_blank">
        {menuItem.getName()}
      </Link>
    );
  }

  return (
    <div
      onClick={() => window.scrollTo(0, 0)}
      onKeyPress={() => {}}
      role="button"
      tabIndex={0}
    >
      <Link to={menuItem.getUrl()}>{menuItem.getName()}</Link>
    </div>
  );
};

export default BRlink;
