import React, { useState } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import { Link } from "react-router-dom";
import { isLinkExternal } from "@elasticpath/ref-store/src/utils/helpers";
import "./contentbanner.less";

const ContentBanner = ({ component, page }: BrProps) => {
  const { document: ref } = component.getModels();
  const content = ref && page.getContent(ref);
  if (!content) return null;

  const {
    imagelink,
    urllink,
    subHeader,
    header,
    imageAltText
  } = content.getData();
  const [isExternal, url] = isLinkExternal(urllink);
  const bannerImage = imagelink && page.getContent<ImageSet>(imagelink);

  const bannerContent = (
    <div className="content-banner-image-wrapper">
      <img alt={imageAltText} src={bannerImage.getOriginal().getUrl()} />
      <div className="text-container">
        <div className="text-wrapper">
          <h1>{header}</h1>
          <h4>{subHeader}</h4>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container content-banner-widget">
      {urllink ? (
        <Link
          to={isExternal ? { pathname: url } : url}
          target={!isExternal ? "_self" : "_blank"}
          rel={!isExternal ? "noopener noreferrer" : ""}
          className="content-banner-card"
        >
          {bannerContent}
        </Link>
      ) : (
        bannerContent
      )}
    </div>
  );
};
export default ContentBanner;
