import React, { FC, useState, useContext } from "react";
import intl from "react-intl-universal";
import PartsFinderTableItem from "../PartsFinderTableItem/PartsFinderTableItem";
import {
  PartDetails,
  PriceDetails,
  EntitlementDetails,
  PartsFinderSearchParams
} from "../../../app/src/containers/PartsFinderPage";
import {
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage,
  InventoryAvailabilityInterface,
  updateBranchNumber,
  isSpecialAirPurifier
} from "../../../app/src/utils/helpers";
import { addToCart } from "../../../app/src/services/EpServices";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { productAdded } from "../utils/Segment";
import AppHeaderLoginMain from "../AppHeaderLogin/appheaderlogin.main";

import "../../../app/src/theme/sharedClasses.less";
import "./PartsFinderTable.less";

interface PartsFinderTableProps {
  parts: Array<PartDetails>;
  prices: Array<PriceDetails>;
  availability: Array<InventoryAvailabilityInterface>;
  entitlements: Array<EntitlementDetails>;
  isMobile: boolean;
  history: any;
  searchParams: PartsFinderSearchParams;
}

const appHeaderLoginLinks = {
  profile: "/myAccount/profile",
  wishlists: "/wishlists"
};

const appModalLoginLinks = {
  registration: "/registration"
};

const PartsFinderTable: FC<PartsFinderTableProps> = ({
  parts,
  prices,
  availability,
  entitlements,
  history,
  isMobile,
  searchParams
}) => {
  const [checkedParts, setCheckedParts] = useState<any>({});
  const [loadingPickupOrDelivery, setLoadingPickupOrDelivery] = useState(null);
  const [isProductBeingAdded, setIsProductBeingAdded] = useState({
    sku: "",
    shippingMethod: ""
  });

  const context = useContext<{
    branches: any;
    cart: any;
    auth: any;
    user: any;
    account: any;
  }>(MainContext);
  const { cart, auth, user } = context;
  const { isLoggedIn, logout } = auth;
  const {
    userProfile: { ecommerceBuyer }
  } = user;

  const formatEntitlement = (part: PartDetails): boolean => {
    if (entitlements && entitlements.length) {
      const partEntitlement: EntitlementDetails = entitlements.find(
        item => item.sku === part.pid
      );
      if (partEntitlement) return partEntitlement.entitled;
    }
    return false;
  };

  const formatPrice = (part: PartDetails): string => {
    if (prices && prices.length) {
      const partPrice: PriceDetails = prices.find(
        item => item.sku === part.pid
      );
      if (partPrice) return `${partPrice.lineTotal}`;
    }
    return intl.get("pending");
  };
  const handlePartChecked = (
    part: PartDetails,
    quantity: string,
    price: string,
    add: boolean
  ) => {
    const checkedPart = {
      ...part,
      quantity,
      price
    };

    if (add) {
      const updatedCheckedParts = {
        ...checkedParts,
        [checkedPart.pid]: checkedPart
      };
      setCheckedParts(updatedCheckedParts);
    } else {
      const {
        [checkedPart.pid]: removedPart,
        ...updatedCheckedParts
      } = checkedParts;
      setCheckedParts(updatedCheckedParts);
    }
  };

  const updateSelectedQuantity = (part: PartDetails, quantity: string) => {
    const updatedPart = { ...checkedParts[part.pid], quantity };
    const updatedCheckedParts = { ...checkedParts, [part.pid]: updatedPart };
    setCheckedParts(updatedCheckedParts);
  };

  const handleAddSelectedToCart = (
    shippingMethod: "pickup" | "delivery",
    product?: PartDetails,
    productQuantity?: number
  ) => {
    const {
      getCartDetails,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      cartDetails: {
        defaultCart: { selectedBranch, addItemsToCart }
      }
    } = cart;

    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      },
      account: {
        accountDetails: { homeBranch }
      }
    } = context;
    let totalQuantity = 0;

    if (product) {
      setIsProductBeingAdded({
        sku: product.pid,
        shippingMethod
      });
    } else {
      setLoadingPickupOrDelivery(shippingMethod);
    }
    const items = product
      ? [
          {
            code: product.pid,
            quantity: Number(productQuantity),
            "branch-number": updateBranchNumber(
              shippingMethod === "delivery",
              product.pid,
              airPurifierBranchNumber,
              homeBranch,
              selectedBranch.code
            ),
            "shipping-method": shippingMethod
          }
        ]
      : Object.values(checkedParts).map((part: any) => {
          totalQuantity += Number(part.quantity);
          const partCodeAndQuantity = {
            code: part.pid,
            quantity: part.quantity
          };
          switch (shippingMethod) {
            case "delivery":
              return {
                ...partCodeAndQuantity,
                "branch-number": isSpecialAirPurifier(part.pid)
                  ? airPurifierBranchNumber
                  : selectedBranch.code,
                "shipping-method": shippingMethod
              };
            case "pickup":
              return {
                ...partCodeAndQuantity,
                "branch-number": selectedBranch.code,
                "shipping-method": shippingMethod
              };
            default:
              return null;
          }
        });

    addToCart(addItemsToCart.self.uri, { items })
      .then(res => getCartDetails())
      .then(() => {
        if (product) {
          productAdded(
            product.title,
            product.pid,
            !product.price || product.price === intl.get("pending")
              ? 0
              : Number(product.price),
            product.brand,
            product.class,
            Number(productQuantity)
          );
        } else {
          Object.values(checkedParts).forEach((part: any) => {
            productAdded(
              part.title,
              part.pid,
              !part.price || part.price === intl.get("pending")
                ? 0
                : Number(part.price),
              part.brand,
              part.class,
              Number(part.quantity)
            );
          });
        }
      })
      .then(() => {
        if (product) {
          setIsProductBeingAdded({ sku: "", shippingMethod: "" });
          setSuccesCartPopupMessage(productQuantity);
        } else {
          setLoadingPickupOrDelivery("");
          setSuccesCartPopupMessage(totalQuantity);
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddSelectedToCart => PartsFinderTable.tsx"
            })
          );
        } else {
          console.error(e);
          setLoadingPickupOrDelivery("");
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
          if (product) {
            setIsProductBeingAdded({ sku: "", shippingMethod: "" });
          }
        }
      });
  };

  const isLoadingItemsForPickup = loadingPickupOrDelivery === "pickup";

  const isLoadingItemsForDelivery = loadingPickupOrDelivery === "delivery";

  const isLoadingSelectedItemsToCart =
    isLoadingItemsForPickup || isLoadingItemsForDelivery;

  const isDisabledAddSelectedPartsForPickup =
    !Object.keys(checkedParts).length ||
    !ecommerceBuyer ||
    isLoadingItemsForDelivery ||
    (isProductBeingAdded && isProductBeingAdded.sku !== "");

  const isDisabledAddSelectedPartsForDelivery =
    !Object.keys(checkedParts).length ||
    !ecommerceBuyer ||
    isLoadingItemsForPickup ||
    (isProductBeingAdded && isProductBeingAdded.sku !== "");

  const renderParts = () => {
    return (
      <div>
        {parts.map(part => {
          const entitled: boolean = part.exists
            ? formatEntitlement(part)
            : false;
          const partPrice: string = part.exists ? formatPrice(part) : "";

          return (
            <PartsFinderTableItem
              key={part.pid}
              part={part}
              price={partPrice}
              entitled={entitled}
              handlePartChecked={handlePartChecked}
              updateSelectedQuantity={updateSelectedQuantity}
              isMobile={isMobile}
              history={history}
              searchParams={searchParams}
              availability={availability}
              handleAddProductToCart={handleAddSelectedToCart}
              isProductBeingAdded={isProductBeingAdded}
              isLoadingSelectedItemsToCart={isLoadingSelectedItemsToCart}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="parts-table">
      <div className="parts-table-header">
        <div className="buttons-wrapper">
          {// eslint-disable-next-line no-nested-ternary
          isLoggedIn ? (
            <>
              {!isLoadingItemsForPickup ? (
                <div className="pickup-delivery-wrapper">
                  <button
                    type="button"
                    className="dast-btn dast-btn-primary"
                    disabled={isDisabledAddSelectedPartsForPickup}
                    onClick={() => {
                      handleAddSelectedToCart("pickup");
                    }}
                  >
                    {ecommerceBuyer
                      ? intl.get("add-selected-as-pick-up")
                      : intl.get("contact-admin")}
                  </button>
                </div>
              ) : (
                <div className="miniLoader" />
              )}
              {!isLoadingItemsForDelivery ? (
                <div className="pickup-delivery-wrapper">
                  <button
                    type="button"
                    className="dast-btn dast-btn-primary"
                    disabled={isDisabledAddSelectedPartsForDelivery}
                    onClick={() => {
                      handleAddSelectedToCart("delivery");
                    }}
                  >
                    {ecommerceBuyer
                      ? intl.get("add-selected-as-delivery")
                      : intl.get("contact-admin")}
                  </button>
                </div>
              ) : (
                <div className="miniLoader" />
              )}
            </>
          ) : (
            <div className="login-button dast-btn dast-btn-primary">
              <AppHeaderLoginMain
                history={history}
                permission
                appHeaderLoginLinks={appHeaderLoginLinks}
                appModalLoginLinks={appModalLoginLinks}
                isLoggedIn={false}
                descriptiveButtonText={intl.get("login-message")}
              />
            </div>
          )}
        </div>
      </div>
      <div className="parts-table-body">{renderParts()}</div>
    </div>
  );
};

export default PartsFinderTable;
