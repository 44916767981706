import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import intl from "react-intl-universal";
import { History } from "history";

import { SavedOrderCard, page, getConfig } from "@zilker/store-components";
import SavedOrderDetails from "./SavedOrderDetails";
import { useMainContext } from "../contexts/MainContext";

import "./SavedOrders.less";
import {
  checkTokensExpired,
  pushToMaintenace,
  decodeURIComponentSafely
} from "../utils/helpers";

interface SavedOrdersProps {
  history: History;
  match: any;
  auth: any;
}

const SavedOrders: FC<SavedOrdersProps> = ({
  match: { params },
  history,
  auth: { logout }
}) => {
  const { detail, zoom } = params;
  const { config } = getConfig();
  const { defaultChannel } = config.brXM;
  const hideDots = defaultChannel === "motili";
  const {
    cart: { cartList, cartListError, getSavedOrderCartList }
  } = useMainContext();

  const [carts, setCarts] = useState<any>(cartList);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    page();
    setShowLoader(true);
    getSavedOrderCartList()
      .then(response => {
        if (!response) {
          setShowLoader(false);
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getSavedOrderCartList => SavedOrders.tsx"
            })
          );
        }
      });

    return () => setShowLoader(false);
  }, []);

  useEffect(() => {
    if (cartList) {
      const formatCartList = cartList._element.map(item => {
        return {
          name: item._descriptor[0].name,
          links: item.links,
          uri: item.self.uri,
          descriptorUri: item._descriptor[0].self.uri,
          totalQuantity: item["total-quantity"]
        };
      });
      setCarts(formatCartList);
      setShowLoader(false);
    } else setCarts(null);
  }, [cartList]);

  if (cartListError) {
    return (
      <Redirect
        to={{
          pathname: "/maintenance",
          state: {
            error: {
              e: { message: cartListError },
              errIn: "Cart list error in the cart context => SavedOrders.tsx"
            }
          }
        }}
      />
    );
  }

  const decodedOrderName = decodeURIComponentSafely(detail);

  return detail && zoom ? (
    <SavedOrderDetails
      orderName={decodedOrderName}
      link={zoom}
      history={history}
      logout={logout}
    />
  ) : (
    <div className="saved-orders content-box content-table">
      <h2>{intl.get("my-saved-orders")}</h2>

      {carts && carts.length ? (
        <>
          <div className="saved-orders-labels">
            <span>{`${intl.get("name")}${hideDots ? "" : ":"} `}</span>
            <span>{`${intl.get("actions")}${hideDots ? "" : ":"} `}</span>
            <span>{`${intl.get("add-to-cart")}${hideDots ? "" : ":"} `}</span>
          </div>
          <SavedOrderCard history={history} carts={carts} isSavedOrder />
        </>
      ) : (
        !showLoader && (
          <p className="no-saved-orders-message">
            {intl.get("there-are-no-saved-orders-to-display")}
          </p>
        )
      )}
      {showLoader && <div className="loader" />}
    </div>
  );
};

export default SavedOrders;
