import React, { useState } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";

import imgPlaceholder from "../../../app/src/images/img_missing_horizontal@2x.png";
import { generateImageUrl } from "../../../app/src/utils/mappings/productDetails";
import { useMainContext } from "../../../app/src/contexts/MainContext";

import "./productlistitem.main.less";

// Types
interface ProductListItemMainProps {
  productId?: string;
  offerData?: {
    [key: string]: any;
  };
  productElement?: {
    [key: string]: any;
  };
  featuredProductAttribute?: boolean;
  customClass?: string;
  itemDetailLink?: string;
  productPrice?: any;
}

// Component
const ProductListItemMain: React.FC<ProductListItemMainProps> = props => {
  const { auth } = useMainContext();
  const [imageStatus, setImageStatus] = useState("loading");

  // Props
  const {
    featuredProductAttribute,
    productPrice,
    customClass,
    itemDetailLink,
    offerData: productData
  } = props;

  /**
   * ## handleImageLoaded
   * @description Sets the image status "loaded" in the state.
   */
  const handleImageLoaded = () => {
    setImageStatus("loaded");
  };

  /**
   * ## renderPriceDetails
   *
   * @param productPriceInfo any
   * @param productSku string
   *
   * @description Display section for the product price.
   */
  const renderPriceDetails = () => {
    const productSku =
      productData && productData._code && productData._code.length
        ? productData._code[0].code
        : "";

    const displayLoader = auth.isLoggedIn && !productPrice;

    return (
      <div data-region="priceRegion">
        <div data-region="itemPriceRegion">
          <ul className="category-item-price-container">
            <li
              className="category-item-list-price category-item-purchase-price"
              data-region="itemListPriceRegion"
            >
              <span
                className="item-meta category-item-purchase-price-value"
                id={`category_item_price_${productSku}`}
              >
                {displayLoader && <div className="miniLoader" />}

                {auth.isLoggedIn && productPrice && `$${productPrice.total}`}
              </span>
            </li>
          </ul>
        </div>
        <div data-region="itemRateRegion" />
      </div>
    );
  };

  /**
   * ## renderProductDetails
   *
   * @description Renders product main details.
   */
  const renderProductDetails = () => {
    const productSku =
      productData && productData._code && productData._code.length
        ? productData._code[0].code
        : "";

    const imageUrl = productData
      ? generateImageUrl(productData._definition[0].details)
      : "";

    return (
      <div className={`category-item-inner content-box ${customClass}`}>
        <div
          className={`category-item-thumbnail-container ${
            imageStatus === "loaded" ? "loaded" : ""
          }`}
        >
          <Link to={`${itemDetailLink}/${encodeURIComponent(productSku)}`}>
            <img
              src={imageUrl}
              onError={(e: any) => {
                e.target.src = imgPlaceholder;
              }}
              alt="default"
              className="category-item-thumbnail img-responsive"
              onLoad={handleImageLoaded}
              title=""
            />
          </Link>
        </div>
        <div className="category-item-title-container">
          <div
            className="category-item-title"
            id={`category_item_title_link_${productSku}`}
          >
            <Link to={`${itemDetailLink}/${encodeURIComponent(productSku)}`}>
              {productData._definition[0]["display-name"]}
            </Link>
          </div>
          <p className="label">{intl.get("product")} #:</p>
          <h4
            className="category-item-title-sku"
            id={`category_item_title_sku_${productSku}`}
          >
            {productSku}
          </h4>
          {/* {Config.b2b.enable && (
            <h4
              className="category-item-title-sku"
              id={`category_item_title_sku_${productData._code[0].code}`}
            >
              {productData._code[0].code}
            </h4>
          )} */}
        </div>
        {featuredProductAttribute ? (
          <div className="featured">{intl.get("featured")}</div>
        ) : (
          ""
        )}
        {renderPriceDetails()}
      </div>
    );
  };

  // Render JSX if data exists.
  if (productData) {
    return renderProductDetails();
  }

  return null;
};

export default ProductListItemMain;
