/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import { cortexFetch } from "@zilker/store-components";
import * as LoginService from "@elasticpath/ref-store/src/services/LoginService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "../utils/helpers";
import { MainContext } from "../contexts/MainContext";

import "../theme/sharedClasses.less";
/* eslint-disable-next-line import/no-useless-path-segments */

interface AddPaymentMethodProps extends RouteComponentProps, BrProps {
  history: any;
}

interface AddPaymentMethodState {
  isLoading: boolean;
  errorMessage: string;
}

class AddPaymentMethod extends React.Component<
  AddPaymentMethodProps,
  AddPaymentMethodState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      errorMessage: ""
    };
    this.submitPayment = this.submitPayment.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.goToBack = this.goToBack.bind(this);
  }

  componentDidMount() {
    this.submitPayment();
  }

  submitPayment() {
    const {
      context: {
        auth: { logout }
      }
    } = this;
    const { history } = this.props;
    const data = window.location.search.replace("?", "");
    const paymentData = JSON.parse(
      `{"${decodeURI(data.replace(/&/g, '","').replace(/=/g, '":"'))}"}`
    );
    let codeCardType = "";
    let cardType = "";
    let paymentForm;

    if (
      paymentData.payment_token &&
      paymentData.req_card_type &&
      paymentData.req_bill_to_forename &&
      paymentData.req_bill_to_surname &&
      paymentData.req_card_number
    ) {
      codeCardType = paymentData.req_card_type ? paymentData.req_card_type : "";
      switch (codeCardType) {
        case "001":
          cardType = "Visa";
          break;
        case "002":
          cardType = "Mastercard";
          break;
        case "003":
          cardType = "American Express";
          break;
        default:
      }

      if (LoginService.isLoggedIn()) {
        cortexFetch(
          "/?zoom=defaultcart:order:paymentmethodinfo:paymenttokenform,defaultprofile:paymentmethods:paymenttokenform"
        )
          .then(res => checkResponse(res))
          .then(res => {
            const paymentFormLink = res._defaultprofile[0]._paymentmethods[0]._paymenttokenform[0].links.find(
              link => link.rel === "createpaymenttokenaction"
            );
            paymentForm = paymentFormLink.uri;
            return cortexFetch(paymentForm, {
              method: "post",
              body: JSON.stringify({
                "display-name": `${paymentData.req_bill_to_forename} ${paymentData.req_bill_to_surname}'s ${cardType} ending in: ${paymentData.req_card_number}`,
                token: paymentData.payment_token
              })
            })
              .then(resp => checkResponse(resp))
              .catch(e => {
                this.setState({
                  isLoading: false
                });
                if (checkTokensExpired(e)) {
                  logout().catch(err =>
                    pushToMaintenace(history, {
                      e: err,
                      errIn: "Logout => submitPayment => AddPaymentMethod.tsx"
                    })
                  );
                } else {
                  pushToMaintenace(history, {
                    e,
                    errIn: "submitPayment => AddPaymentMethod.tsx"
                  });
                }
              });
          })
          .then(() => {
            this.setState({
              isLoading: false
            });
            this.handleClose();
          })
          .catch(e => {
            this.setState({
              isLoading: false
            });
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => submitPayment => AddPaymentMethod.tsx"
                })
              );
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "submitPayment => AddPaymentMethod.tsx"
              });
            }
          });
      }
    } else {
      const errorMessage = paymentData.message
        ? paymentData.message.replace(/\+/g, " ")
        : "";
      this.setState({
        errorMessage,
        isLoading: false
      });
    }
  }

  handleClose() {
    const { history } = this.props;
    history.push("/profile");
  }

  goToBack() {
    const { history } = this.props;
    history.push("/profile");
  }

  render() {
    const { isLoading, errorMessage } = this.state;
    return (
      <div>
        {isLoading ? <div className="loader" /> : ""}
        {errorMessage.length ? (
          <div style={{ margin: "20px" }} className="error-message">
            <div style={{ color: "red", padding: "20px 0" }}>
              {errorMessage}
            </div>
            <div>
              <button
                className="dast-btn dast-btn-secondary payment-cancel-btn"
                aria-label={intl.get("go-back")}
                data-el-label="paymentForm.cancel"
                type="button"
                onClick={() => {
                  this.goToBack();
                }}
              >
                {intl.get("go-back")}
              </button>
              <button
                className="dast-btn dast-btn-primary new-payment-btn"
                type="button"
                aria-label={intl.get("add-new-payment-method")}
                onClick={() => {
                  this.goToBack();
                }}
              >
                {intl.get("add-new-payment-method")}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter<AddPaymentMethodProps, any>(AddPaymentMethod);
