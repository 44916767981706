import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";
import HomePageCard from "../../../HomePageCard/home-page-card.main";
import TrainingCard from "../../../TrainingCard/training-card";
import "./training-cards.less";

const TrainingCards = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return <>No CTA to display</>;
  }

  const { header, trainingCardList } = document.getData();

  const renderTrainingCards = () => {
    return (
      <div className="training-cards">
        {trainingCardList.map((trainingCard, index) => {
          return (
            <TrainingCard
              header={trainingCard.header}
              buttonLink={trainingCard.buttonLink}
              buttonText={trainingCard.buttonText}
              time={trainingCard.time}
              trainingType={trainingCard.trainingType}
              key={`training-card-${Math.random()}`}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="training-cards-wrapper container">
      <HomePageCard justifyContent="start" disableHover>
        <div className="training-cards-content">
          <h2 className="training-cards-header">{header}</h2>
          {renderTrainingCards()}
        </div>
      </HomePageCard>
    </div>
  );
};

export default TrainingCards;
