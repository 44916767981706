import React, { Component } from "react";
import intl from "react-intl-universal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ContractItemsTable } from "@zilker/store-components";
import { ContractOrder } from "./ContractOrdersPage";
import { MainContext } from "../contexts/MainContext";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";

import "./ContractDetailsPage.less";

interface ContractDetailsPageProps extends RouteComponentProps {
  history: any;
  match: any;
  location: any;
}

interface ContractDetailsPageState {
  contract: ContractOrder;
  release: boolean;
}

const contractInformation = {
  customerNumber: "customer-number",
  orderNumber: "contract-number",
  description: "description",
  purchaseOrderNumber: "p-o-number",
  customerName: "customer-name",
  jobNumber: "job-number",
  jobName: "job-name",
  enteredBy: "entered-by"
};

const contractDates = {
  orderPlaced: "date-ordered",
  expiration: "expiry-date",
  firstRelease: "first-release",
  lastRelease: "last-release",
  promisedShip: "date-promised",
  firstShipment: "first-shipment",
  lastShipment: "last-shipment"
};

const contractTotal = {
  subTotal: "sub-total",
  otherChargesAmt: "other-charges",
  tax: "tax",
  total: "total"
};

class ContractDetailsPage extends Component<
  ContractDetailsPageProps,
  ContractDetailsPageState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {
      contract: null,
      release: false
    };
  }

  componentDidMount() {
    const {
      location: { state }
    } = this.props;
    this.setState({
      release: state ? state.release : false
    });
    this.fetchContractDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      account: {
        accountDetails: { customerNumber }
      }
    } = this.context;
    const { contract } = this.state;
    const { contract: prevContract } = prevState;
    if (customerNumber && !contract && contract !== prevContract) {
      this.fetchContractDetails();
    }
  }

  async fetchContractDetails() {
    const {
      auth: { logout },
      contract: { updateContracts, fetchContract },
      account: {
        accountDetails: { customerNumber, company }
      }
    } = this.context;

    const {
      match: {
        params: { detail: contractNumber }
      },
      history
    } = this.props;

    try {
      const contract = await fetchContract(customerNumber, contractNumber);
      this.setState({
        contract: {
          ...contract,
          customerName: company
        }
      });
      updateContracts(contract);
    } catch (e) {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "logout => fetchContractDetails => ContractDetailsPage.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "fetchContractDetails => ContractDetailsPage.tsx"
        });
      }
    }
  }

  renderSection(title: string, data: { [name: string]: string }) {
    const { contract } = this.state;
    return (
      <div className={`${title} content-box`}>
        <h4>{intl.get(title)}</h4>
        <div className="section-body">
          {Object.keys(data).map((key: string) => (
            <div className="section-row" key={key}>
              <span className="label">{intl.get(data[key])}:</span>
              <span>{`${title === "total" ? `$` : ``}${contract[key]}`}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderAddressesSection() {
    const { contract } = this.state;
    return (
      <div className="contract-addresses content-box">
        <h4>{intl.get("addresses")}</h4>
        <div className="addresses-body">
          <div className="addresses-row shipping">
            <span className="label">{intl.get("ship-to")}:</span>
            <span>{`${contract.shipToAddress1}, ${contract.shipToCity}, ${contract.shipToState} ${contract.shipToZip}`}</span>
          </div>
          <div className="addresses-row branch">
            <span className="label">{intl.get("branch")}:</span>
            <span>{contract.branchShipAddress1}</span>
            <span>
              {`${contract.branchShipAddress2}, ${contract.branchShipCity}, ${contract.branchShipState}
            ${contract.branchShipZip}`}
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { contract, release } = this.state;
    const { history } = this.props;
    return contract ? (
      <div className="contract-details-container">
        <div className="container contract-details-main">
          <div className="row">
            {this.renderSection("contract-information", contractInformation)}
            {this.renderSection("date", contractDates)}
          </div>
          <div className="row">
            {this.renderAddressesSection()}
            {this.renderSection("total", contractTotal)}
          </div>
          <div className="row">
            <ContractItemsTable
              contract={contract}
              release={release}
              history={history}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="loader" />
    );
  }
}

export default withRouter(ContractDetailsPage);
