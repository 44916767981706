import React, { FC, useEffect, useState } from "react";
import intl from "react-intl-universal";
import WarrantyLookupPage from "../../../app/src/containers/WarrantyLookupPage";
import "./homewarrantylookup.less";

const HomeWarrantyLookUp = () => {
  const description = intl.get("warranty-lookup-description");
  return (
    <div className="home-page-warranty-widget container">
      <div className="home-page-warranty">
        <WarrantyLookupPage titleDescription={description} />
      </div>
    </div>
  );
};

export default HomeWarrantyLookUp;
