import React, { FC } from "react";
import { withRouter } from "react-router-dom";
import { useMainContext } from "../contexts/MainContext";
import { clearProfileCache } from "../services/EpServices";
import * as LoginService from "../services/LoginService";
import { authenticateUser } from "../utils/helpers";

import "./LeadDashboardInvitationPage.less";

interface LeadDashboardInvitationPageProps {
  history: any;
}

const LeadDashboardInvitationPage: FC<LeadDashboardInvitationPageProps> = ({
  history
}) => {
  const currentLocationParams = window.location.search;
  const urlParams = new URLSearchParams(currentLocationParams);
  const accountTypeParam = urlParams.get("accountType");
  const invEmailParam = urlParams.get("invEmail");
  const context = useMainContext();

  const authenticateUserSession = async () => {
    if (!LoginService.isLoggedIn()) {
      authenticateUser(accountTypeParam, invEmailParam, history);
    } else {
      if (invEmailParam) {
        const {
          user: { getUserProfile }
        } = context;

        clearProfileCache(invEmailParam)
          .then(() => {
            getUserProfile();
          })
          .catch(e =>
            console.error(
              "authenticateUser => LeadDashboardInvitationPage.tsx",
              e
            )
          );
      }
      history.push("/");
    }
  };
  authenticateUserSession();
  return <div className="loader" />;
};

export default withRouter(LeadDashboardInvitationPage);
