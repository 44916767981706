import React, { useContext, useState, useEffect } from "react";
import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";

import "./AddressItem.less";

// Types
export interface AddressDetails {
  "country-name": string;
  "extended-address": string;
  locality: string;
  organization: string;
  "phone-number": string;
  "postal-code": string;
  region: string;
  "street-address": string;
  uri?: string;
}

interface NameDetails {
  "family-name": string;
  "given-name": string;
}

interface TypeDetails {
  billing: string;
  shipping: string;
}
interface AddressItemDetails {
  address: AddressDetails;
  name: NameDetails;
  uri: string;
  type: TypeDetails;
  jobNumber: string;
  jobName: string;
}

interface AddressItemProps {
  addressItemDetails: AddressItemDetails;
  onDelete(...args): any;
  onEdit(any): any;
  displayWarningMessage?: boolean;
}

// Component
const AddressItem: React.FC<AddressItemProps> = props => {
  const {
    addressItemDetails: { name, address, uri, type, jobNumber, jobName },
    onEdit,
    onDelete,
    displayWarningMessage
  } = props;

  const context = useContext<{
    auth: any;
    user: any;
  }>(MainContext);

  const {
    user: {
      userProfile: { defaultShippingAddress }
    }
  } = context;

  const [defaultAddress, setDefaultAddress] = useState<AddressDetails>(null);

  useEffect(() => {
    setDefaultAddress(defaultShippingAddress);
  }, [defaultShippingAddress]);

  const isDefaultShippingAddress: boolean =
    defaultAddress && defaultAddress.uri === uri;

  return (
    <div className="address-item-container">
      <div className="address-item-content">
        <div className="address-item-details">
          <span>{`${name["given-name"]} ${name["family-name"]}, `}</span>
          <span>
            {`${address["street-address"]} ${address.locality} ${
              address.region ? address.region : ""
            }, ${address["country-name"]} ${address["postal-code"]}`}
          </span>
          {displayWarningMessage && (
            <div className="new-address-warning">
              <p>{intl.get("new-address-warning")}</p>
            </div>
          )}
        </div>

        <div className="address-item-job-info">
          {jobName ? (
            <>
              <span className="job-label">
                {`${intl.get("job-name").toUpperCase()}: `}
              </span>
              <span>{jobName}</span>
            </>
          ) : (
            ""
          )}
          {jobNumber ? (
            <>
              <span className="job-label">
                {`${intl.get("job-number").toUpperCase()}: `}
              </span>
              <span>{jobNumber}</span>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="address-item-buttons">
          <div>
            <button
              className="ico-button"
              type="button"
              onClick={() => {
                onEdit(uri);
              }}
            >
              <i className="icon-edit" />
              <span>{intl.get("edit")}</span>
            </button>
          </div>

          {isDefaultShippingAddress ||
          type.billing === "true" ||
          jobName ||
          jobNumber ? null : (
            <div>
              <button
                className="ico-button"
                type="button"
                onClick={() => {
                  onDelete(uri, address);
                }}
              >
                <i className="icon-delete" />
                <span>{intl.get("delete")}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressItem;
