import React, { FC, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import uuidv4 from "uuid/v4";
import intl from "react-intl-universal";
import { discoverOIDCParameters } from "@zilker/store-components/src/utils/AuthService";
import { getConfig, page } from "@zilker/store-components";
import { useMainContext } from "../contexts/MainContext";

import "./SessionExpiredPage.less";

import sessionExpiredImage from "../images/site-images/session-timeout.jpg";
import { pushToMaintenace } from "../utils/helpers";

interface OidcParameters {
  clientId: string;
  scopes: string;
  authorizationEndpoint: string;
  endSessionEndpoint: string;
}

const SessionExpiredPage: FC<{}> = () => {
  const mainContext = useMainContext();
  const [sessionNotExpired, setSessionNotExpired] = useState<boolean>(false);
  const [urlString, setUrlString] = useState<string>("");
  const history = useHistory();
  const {
    modal: { handleOpenLoginModal }
  } = mainContext;

  let loginUrlAddress = "";

  const { config } = getConfig();

  useEffect(() => {
    page();
    const epToken = localStorage.getItem(
      `${config.cortexApi.scope}_oAuthToken`
    );
    if (epToken) {
      setSessionNotExpired(true);
    }
  }, []);

  useEffect(() => {
    const oidcSecret = uuidv4();
    localStorage.setItem("OidcSecret", oidcSecret);

    discoverOIDCParameters()
      .then(oidcParameters => {
        const oidcStateObject = {
          secret: oidcSecret,
          pathname: window.location.pathname
        };

        const oidcStateEncoded = btoa(JSON.stringify(oidcStateObject));
        const redirectUrl = `${config.b2b.openId.callbackUrl}/loggedin`;
        const { brand } = config.b2b.openId;

        const queryArray = [
          `scope=${encodeURIComponent(oidcParameters.scopes)}`,
          "response_type=code",
          `client_id=${encodeURIComponent(oidcParameters.clientId)}`,
          `redirect_uri=${encodeURIComponent(redirectUrl)}`,
          `state=${oidcStateEncoded}`
        ];
        if (brand) {
          queryArray.push(`brand=${brand}`);
        }
        const query = queryArray.join("&");
        loginUrlAddress = `${oidcParameters.authorizationEndpoint}?${query}`;
        setUrlString(loginUrlAddress);
      })
      .catch(e => {
        pushToMaintenace(history, {
          e,
          errIn: "discoverOIDCParameters => SessionExpiredPage.tsx"
        });
      });
  }, [loginUrlAddress]);

  let keycloakLoginRedirectUrl = "";
  if (config.b2b.enable && config.b2b.openId && !config.b2b.openId.enable) {
    keycloakLoginRedirectUrl = `${
      config.b2b.keycloak.loginRedirectUrl
    }?client_id=${
      config.b2b.keycloak.client_id
    }&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
      config.b2b.keycloak.callbackUrl
    )}`;
  }

  if (sessionNotExpired) {
    return <Redirect to="/" />;
  }

  return (
    <div className="app-main page-container">
      <div className="container">
        <div className="session-expired-container">
          <div className="session-expired-image">
            <img
              src={sessionExpiredImage}
              alt={intl.get("session-have-expired")}
            />
          </div>
          <h1 className="session-expired-title">
            {intl.get("session-have-expired")}
          </h1>
          <h3 className="session-expired-subtitle">
            {intl.get("sign-in-to-your-account")}
          </h3>
          <div className="session-expired-button">
            <a
              aria-label={intl.get("sign-in")}
              href={`${
                config.b2b.openId && config.b2b.openId.enable
                  ? urlString
                  : keycloakLoginRedirectUrl
              }`}
              className="login-auth-service-btn"
            >
              <button
                type="button"
                className="dast-btn dast-btn-primary"
                onClick={() => handleOpenLoginModal(true)}
              >
                {intl.get("sign-in")}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionExpiredPage;
