import React, { FC, useState } from "react";
import { History } from "history";
import Modal from "react-responsive-modal";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { setLocalStorageFlag } from "@elasticpath/ref-store/src/utils/helpers";
import intl from "react-intl-universal";

import "./AppModalRedirectToDownload.less";
import "../../../app/src/theme/sharedClasses.less";

interface AppModalRedirectToDownloadProps extends RouteComponentProps {
  closeDownloadModal: () => void;
  history: History;
}

const AppModalRedirectToDownload: FC<AppModalRedirectToDownloadProps> = ({
  closeDownloadModal,
  history
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const ttl = 30 * 24 * 60 * 60 * 1000;

  const closeModal = () => {
    setIsModalOpen(false);
    closeDownloadModal();
  };

  const goToDownload = () => {
    setLocalStorageFlag("download_modal_rejected", "true", ttl);
    history.push("/download");
    closeModal();
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      classNames={{ modal: "download-modal-content" }}
    >
      <div className="modal-lg">
        <div className="modal-content" id="simplemodal-container">
          <div className="modal-body">
            <div id="downloadMobileAppModal">
              <div className="download-modal-content">
                {intl.get("download-popup-message")}
              </div>
              <div className="btn-container">
                <button
                  type="button"
                  className="dast-btn dast-btn-primary"
                  onClick={goToDownload}
                >
                  {intl.get("download-now")}
                </button>
                <button
                  type="button"
                  className="dast-btn dast-btn-secondary"
                  onClick={closeModal}
                >
                  {intl.get("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(AppModalRedirectToDownload);
