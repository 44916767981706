import React, { useContext } from "react";
import intl from "react-intl-universal";
import { isSpecialAirPurifier } from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";

interface OrderItemDetailsProps<T> {
  details: {
    [key: string]: any;
    serialNumbers?: any[];
  };
  noOp: () => any;
  isProductBeingAdded: { sku: string; shippingMethod: string };
  loadingPickupOrDelivery: string;
  onAddToCart: (...args: any) => void;
  showSerialNumber: boolean;
}

const OrderItemDetails: React.FC<OrderItemDetailsProps<Object>> = ({
  details,
  noOp,
  isProductBeingAdded,
  loadingPickupOrDelivery,
  onAddToCart,
  showSerialNumber
}) => {
  const { config }: { config: IEpConfig } = getConfig();
  const context = useContext<{ user: any }>(MainContext);

  const {
    user: {
      userProfile: { ecommerceBuyer }
    }
  } = context;

  const renderSerialNumbers = () => {
    return showSerialNumber ? (
      <div className="serial-numbers-container">
        <h5 className="serial-numbers-title">
          {`${intl.get("serial-numbers")}:`}
        </h5>
        <div className="serial-numbers-content">
          {details.serialNumbers && details.serialNumbers.length
            ? details.serialNumbers.map(
                (serialNumber: string, index: number, self: string[]) => (
                  <span key={serialNumber}>
                    {` ${serialNumber}${
                      index !== self.length - 1 ? ", " : ""
                    } `}
                  </span>
                )
              )
            : ""}
        </div>
      </div>
    ) : null;
  };

  const deliveryButtonLabelText = ecommerceBuyer
    ? intl.get("delivery-method")
    : intl.get("contact-admin");

  const pickupButtonLabelText = ecommerceBuyer
    ? intl.get("pick-up-method")
    : intl.get("contact-admin");

  const buttonsDisabled =
    isProductBeingAdded &&
    isProductBeingAdded.sku !== "" &&
    isProductBeingAdded.sku !== details.sku;

  const isLoadingItemForPickup =
    isProductBeingAdded &&
    isProductBeingAdded.sku === details.sku &&
    isProductBeingAdded.shippingMethod === "pickup";
  const isLoadingItemForDelivery =
    isProductBeingAdded &&
    isProductBeingAdded.sku === details.sku &&
    isProductBeingAdded.shippingMethod === "delivery";
  const isDisabledAddItemForPickup =
    !ecommerceBuyer ||
    isSpecialAirPurifier(details.sku) ||
    isLoadingItemForDelivery ||
    buttonsDisabled ||
    loadingPickupOrDelivery !== "";

  const isDisabledAddItemForDelivery =
    !ecommerceBuyer ||
    isLoadingItemForPickup ||
    buttonsDisabled ||
    loadingPickupOrDelivery !== "";

  return (
    <>
      <div className="items item-table">
        <div>
          <p>{intl.get("quantity-abbr")}</p>
          <p>{details.quantityOrdered}</p>
        </div>
        <div>
          <p>{intl.get("product")}#</p>
          <p className="product-sku">{details.sku}</p>
        </div>
        <div>
          <p>{intl.get("description")}</p>
          <p className="text-right text-md-left">{details.description}</p>
        </div>
        {config.calculatePrice && (
          <div>
            <p>{intl.get("price")}</p>
            <p>
              {ecommerceBuyer ? `$${details.netPrice}` : intl.get("pending")}
            </p>
          </div>
        )}
        {config.calculatePrice && (
          <div>
            <p>{intl.get("extended-price")}</p>
            <p>
              {ecommerceBuyer ? `$${details.linePrice}` : intl.get("pending")}
            </p>
          </div>
        )}
        {config.orderHistoryQTYShipped && (
          <div>
            <p>{intl.get("qty-shipped")}</p>
            <p>{details.quantityShipped}</p>
          </div>
        )}
        {config.showBackOrderedQuantity && (
          <div>
            <p>{intl.get("qty-b-o")}</p>
            <p>{details.quantityBackordered}</p>
          </div>
        )}
        <div>
          <p>{intl.get("action")}</p>
          <div className="pickup-delivery-buttons">
            {!isLoadingItemForPickup ? (
              <button
                className="dast-btn dast-btn-primary"
                aria-label={pickupButtonLabelText}
                onKeyDown={noOp}
                type="button"
                disabled={isDisabledAddItemForPickup}
                onClick={() => {
                  onAddToCart("pickup", details.sku);
                }}
              >
                {pickupButtonLabelText}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
            {!isLoadingItemForDelivery ? (
              <button
                className="dast-btn dast-btn-primary"
                aria-label={deliveryButtonLabelText}
                onKeyDown={noOp}
                type="button"
                disabled={isDisabledAddItemForDelivery}
                onClick={() => {
                  onAddToCart("delivery", details.sku);
                }}
              >
                {deliveryButtonLabelText}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
          </div>
        </div>
      </div>
      {renderSerialNumbers()}
    </>
  );
};

export default OrderItemDetails;
