import React, { useState } from "react";
import { getContractOrdersGQL } from "../services/connectGQLservices";

// eslint-disable-next-line import/prefer-default-export
export const ContractContext = () => {
  const [contractsList, setContractsList] = useState(null);

  const fetchContracts = async customerNumber => {
    try {
      const { data } = await getContractOrdersGQL(customerNumber);
      setContractsList(data.data.customer.contracts);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchContract = async (customerNumber, contractNumber) => {
    try {
      const { data } = await getContractOrdersGQL(customerNumber);
      return data.data.customer.contracts.find(
        contract => contract.orderNumber === contractNumber
      );
    } catch (err) {
      console.error(err);
    }
    return null;
  };

  const filterContracts = filters => {
    const filteredContracts = contractsList
      .filter(contract => {
        const appliedFilters = [];
        Object.keys(filters).forEach(key => {
          if (filters[key]) {
            appliedFilters.push(
              contract[key].toUpperCase() === filters[key].toUpperCase()
            );
          }
        });
        return appliedFilters.length
          ? appliedFilters.every(filter => !!filter)
          : true;
      })
      .filter(contract => !!contract);
    return filteredContracts;
  };

  const updateContracts = updatedContract => {
    const updatedContracts = contractsList.map(contract =>
      contract.orderNumber === updatedContract.orderNumber
        ? updatedContract
        : contract
    );
    setContractsList(updatedContracts);
  };

  return {
    contractsList,
    fetchContracts,
    filterContracts,
    updateContracts,
    fetchContract
  };
};
