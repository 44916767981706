/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { createRef } from "react";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { login } from "../utils/AuthService";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./cartclear.less";
import "../../../app/src/theme/sharedClasses.less";

let Config: IEpConfig | any = {};
let intl = { get: (str, ...args: any[]) => str };

interface CartClearProps {
  cartData: any;
  isCartEmpty: boolean;
  handleCartsUpdate?: (...args: any[]) => any;
  history: any;
  auth: any;
}

interface CartClearState {
  showConfirmationMsg: boolean;
  showLoader: boolean;
}

class CartClear extends React.Component<CartClearProps, CartClearState> {
  static contextType = MainContext;

  messageRef = createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);

    this.state = {
      showConfirmationMsg: false,
      showLoader: false
    };
    this.handleClearCart = this.handleClearCart.bind(this);
    this.handleOpenConfirmationMsg = this.handleOpenConfirmationMsg.bind(this);
    this.handleCloseConfirmationMsg = this.handleCloseConfirmationMsg.bind(
      this
    );
  }

  componentDidUpdate() {
    const {
      cart: { cartError }
    } = this.context;
    const { history } = this.props;
    if (cartError) {
      pushToMaintenace(history, {
        e: { message: cartError },
        errIn: "cart context error => CartClear.tsx"
      });
    }
  }

  handleClearCart() {
    const { cartData, history, auth } = this.props;
    const {
      cart: { getCartDetails }
    } = this.context;

    this.setState({ showLoader: true });
    login().then(() => {
      cortexFetch(`${cartData.selfUri}/lineitems`, {
        method: "delete"
      })
        .then(res => {
          const onSuccess = data => data;
          return checkResponse(res, onSuccess);
        })
        .then(() => {
          return getCartDetails();
        })
        .then(() => {
          this.setState({ showConfirmationMsg: false, showLoader: false });
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            auth.logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => handleClearCart => CartClear.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "handleClearCart => CartClear.tsx"
            });
          }
        });
    });
  }

  handleOpenConfirmationMsg() {
    this.setState({ showConfirmationMsg: true }, () => {
      // When the message appears, scroll to and focus the <div> element.
      window.scrollTo(0, this.messageRef.current.offsetTop);
      this.messageRef.current.focus();
    });
  }

  handleCloseConfirmationMsg() {
    this.setState({ showConfirmationMsg: false });
  }

  render() {
    const { showConfirmationMsg, showLoader } = this.state;
    const { isCartEmpty } = this.props;

    return (
      <div className="cart-clear-wrapper cart-clear-dropdown">
        <div className="dropdown cart-clear-dropdown">
          <button
            className="btn-ellipsis"
            type="submit"
            onClick={this.handleOpenConfirmationMsg}
            disabled={isCartEmpty}
          >
            {intl.get("clear-cart")}
          </button>
        </div>
        {showConfirmationMsg && (
          <div
            className="cart-clear-confirmation"
            ref={this.messageRef}
            tabIndex={-1}
          >
            <div className="cart-clear-confirmation-content">
              <p>
                {intl.get("cart-clear-msg")} <b>{intl.get("default-cart")}</b>
                {". "}
                {intl.get("cart-clear-confirmation")}
              </p>
              <div className="btn-wrap">
                <button
                  className="dast-btn dast-btn-secondary"
                  aria-label={intl.get("cancel")}
                  type="button"
                  onClick={this.handleCloseConfirmationMsg}
                >
                  {intl.get("cancel")}
                </button>
                <button
                  className="dast-btn dast-btn-primary ok-btn"
                  aria-label={intl.get("ok")}
                  type="button"
                  onClick={this.handleClearCart}
                >
                  {showLoader ? (
                    <span className="miniLoader" />
                  ) : (
                    intl.get("ok")
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CartClear;
