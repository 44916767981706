import React, { useContext } from "react";
import { useHistory } from "react-router";
import { BrComponent } from "@bloomreach/react-sdk";
import { MainContext } from "../../../app/src/contexts/MainContext";
import AppHeaderNavigationBarMain from "../AppHeaderNavigationBarMain/AppHeaderNavigationBarMain";
import AppHeaderCTA from "../AppHeaderCTA/appheaderCTA";
import AppSearchBar from "../AppSearchBar/appsearchbar";
import MenuEditButton from "../Bloomreach/components/MenuEditButton/menu-edit-button";
import { ReactComponent as CloseIcon } from "../../../app/src/images/icons/close-nav-icon.svg";
import detectIEBrowser from "../../../app/src/validation/detectIEBrowser";

import "./appheaderbottomnav.main.less";

interface AppHeaderBottomNavMainProps {
  isDesktop: boolean;
  isMobile?: boolean;
  cortexNavigations: any;
  handleIsOffline: (...args: any[]) => any;
  isOffline: boolean;
  appHeaderNavigationLinks: {
    [key: string]: any;
  };
  brSuggest: (...args: any[]) => any;
  isInStandaloneMode: boolean;
  isNavExpanded: boolean;
  toggleNavExpanded: (...args: any[]) => any;
}

const AppHeaderBottomNavMain: React.FC<AppHeaderBottomNavMainProps> = props => {
  const {
    isDesktop,
    isMobile,
    cortexNavigations,
    handleIsOffline,
    isOffline,
    appHeaderNavigationLinks,
    brSuggest,
    isInStandaloneMode,
    isNavExpanded,
    toggleNavExpanded
  } = props;
  const context = useContext<{ auth: any; user: any }>(MainContext);
  const history = useHistory();

  const {
    auth: { isLoggedIn },
    user: {
      userProfile: { ecommerceUser }
    }
  } = context;

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="header-container">
      <div className="icon-container search container">
        {!detectIEBrowser() && !isDesktop ? (
          <div className="toggle-btn-container">
            {isInStandaloneMode ? (
              <button
                className="back-btn"
                aria-label="Back button"
                type="button"
                onClick={goBack}
              >
                <span className="icon glyphicon glyphicon-chevron-left" />
              </button>
            ) : (
              ""
            )}
            <button
              className={`toggle-btn ${isNavExpanded ? "" : "collapsed"}`}
              type="button"
              data-toggle="collapse"
              data-target=".collapsable-container"
              aria-expanded={isNavExpanded}
              aria-label="Toggle navigation"
              onClick={toggleNavExpanded}
            >
              {isNavExpanded ? (
                <CloseIcon />
              ) : (
                <span className="icon glyphicon glyphicon-align-justify" />
              )}
            </button>
          </div>
        ) : null}
        <div className="search-container">
          {(!isLoggedIn || ecommerceUser) && (
            <AppSearchBar
              isMobileView={false}
              brSuggest={brSuggest}
              history={history}
            />
          )}
        </div>
        {!isMobile ? <AppHeaderCTA isDesktop={isDesktop} /> : null}
      </div>
      <div className="central-container container">
        {(!isLoggedIn || ecommerceUser) && (
          <div className="horizontal-menu">
            {!detectIEBrowser() ? (
              <BrComponent path="menu">
                <MenuEditButton />
              </BrComponent>
            ) : (
              <div>.</div>
            )}
            {isDesktop && cortexNavigations && !detectIEBrowser() ? (
              <AppHeaderNavigationBarMain
                isOfflineCheck={handleIsOffline}
                isOffline={isOffline}
                isMobileView={false}
                appHeaderNavigationLinks={appHeaderNavigationLinks}
                cortexNavigations={cortexNavigations}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeaderBottomNavMain;
