/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */
import {
  checkResponse,
  setTokenInHeaders
} from "@elasticpath/ref-store/src/utils/helpers";
import * as UserPrefs from "./UserPrefs";
import * as LoginService from "../../../app/src/services/LoginService";
import mockFetch from "./Mock";

import { getConfig } from "./ConfigProvider";

export function timeout(ms, promise) {
  let waitForResponse;
  const promiseTimeout = new Promise((resolve, reject) => {
    waitForResponse = setTimeout(() => {
      reject();
    }, ms);
  });

  return Promise.race([promise, promiseTimeout]).then(res => {
    clearTimeout(waitForResponse);
    return res;
  });
}

function removeLSkeys(keys: string[]): void {
  keys.map(key =>
    localStorage.removeItem(`${getConfig().config.cortexApi.scope}${key}`)
  );
}

export function cortexFetch(input, init = {}): any {
  const requestInit = {
    headers: {
      "Content-Type": "application/json"
    },
    ...init
  };

  if (requestInit && requestInit.headers) {
    requestInit.headers[
      "x-ep-user-traits"
    ] = `LOCALE=${UserPrefs.getSelectedLocaleValue()}, CURRENCY=${UserPrefs.getSelectedCurrencyValue()}`;

    // eslint-disable-next-line dot-notation
    requestInit.headers["Authorization"] = localStorage.getItem(
      `${getConfig().config.cortexApi.scope}_oAuthToken`
    );

    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_AccountSharedId`
      )
    ) {
      requestInit.headers["x-ep-account-shared-id"] = localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_AccountSharedId`
      );
    }

    if (
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthUserId`
      ) &&
      localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthImpersonationToken`
      )
    ) {
      requestInit.headers["x-ep-user-id"] = localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthUserId`
      );
      requestInit.headers["x-ep-impersonation-token"] = localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthImpersonationToken`
      );
      requestInit.headers["x-ep-user-roles"] = localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthRole`
      );
      requestInit.headers["x-ep-user-scopes"] = localStorage.getItem(
        `${getConfig().config.cortexApi.scope}_oAuthScope`
      );
    }
  }

  if (getConfig().config.enableOfflineMode) {
    return mockFetch(input);
  }

  let queryFormat = "";
  if (
    input &&
    (getConfig().config.formatQueryParameter.standardlinks ||
      getConfig().config.formatQueryParameter.noself ||
      getConfig().config.formatQueryParameter.nodatalinks)
  ) {
    const queryCharacter = input.includes("?") ? "&" : "?";
    const standardlinks = getConfig().config.formatQueryParameter.standardlinks
      ? "standardlinks,"
      : "";
    const noself = getConfig().config.formatQueryParameter.noself
      ? "zoom.noself,"
      : "";
    const nodatalinks = getConfig().config.formatQueryParameter.nodatalinks
      ? "zoom.nodatalinks"
      : "";
    queryFormat = `${queryCharacter}format=${standardlinks}${noself}${nodatalinks}`;
  }

  return timeout(
    (<any>getConfig().config).cortexApi.reqTimeout || 30000,
    fetch(
      `${getConfig().config.cortexApi.overrideHost || ""}${
        getConfig().config.cortexApi.path
      }${input}${queryFormat}`,
      requestInit
    )
      .then(res => {
        if (
          (res.status === 401 || res.status === 403) &&
          input !== "/oauth2/tokens"
        ) {
          return LoginService.doLogout()
            .then(response => {
              const onSuccess = data => data;
              checkResponse(response, onSuccess);
            })
            .then(() => {
              setTokenInHeaders();

              window.location.href = "/";
            })
            .catch(err => {
              console.error("err; ", err);
              throw err;
            });
        }
        if (res.status >= 500) {
          if (window.location.href.indexOf("/maintenance") === -1) {
            throw res;
            // if (history) history.push("/maintenance");
            // else throw res;
          }
        }
        return res;
      })
      .catch(error => {
        throw error;
      })
  ).catch(error => {
    throw error;
  });
}
