import React, { FC } from "react";
import { Link } from "react-router-dom";

import { isLinkExternal } from "../../../app/src/utils/helpers";
import useWindowSize from "../CustomHooks/useWindowSize";
import GridCardInterface from "./types/GridCardInterface";

interface BusinessOpsGridCardInterface {
  gridCard: GridCardInterface;
  isLoggedIn: boolean;
}

const BusinessOpsGridCard: FC<BusinessOpsGridCardInterface> = ({
  gridCard,
  isLoggedIn
}) => {
  const [width, height] = useWindowSize();
  const {
    id,
    image,
    topTitle,
    mainTitle,
    bottomTitle,
    buttonText,
    type,
    cardWidth,
    backgroundColor,
    textColor,
    borderColor,
    description,
    backgroundImage: desktopBackgroundImage,
    isLinkForLoggedInUserOnly,
    link,
    mobileBackgroundImage,
    buttonBackgroundColor,
    buttonBorderColor
  } = gridCard;
  const [isExternal, url] = isLinkExternal(link);

  const backgroundImage =
    width <= 768
      ? mobileBackgroundImage || desktopBackgroundImage
      : desktopBackgroundImage;

  const style = {
    flex: `${cardWidth} 0 0`,
    color: textColor,
    border: borderColor ? `1px solid ${borderColor}` : "",
    backgroundColor,
    backgroundImage: `url(${backgroundImage})`
  };
  return (
    <Link
      to={
        // eslint-disable-next-line no-nested-ternary
        !isLoggedIn && isLinkForLoggedInUserOnly
          ? "/login"
          : isExternal
          ? { pathname: url }
          : url
      }
      target={isExternal ? "_blank" : "_self"}
      rel={isExternal ? "noopener noreferrer" : ""}
      className={`grid-card ${type} ${backgroundImage ? "card-with-bkg" : ""}`}
      id={id}
      key={id}
      style={style}
    >
      {topTitle && <h2 className="grid-card-top-title">{topTitle}</h2>}
      {image && <img className="grid-card-image" src={image} alt={mainTitle} />}

      {mainTitle && <h2 className="grid-card-title">{mainTitle}</h2>}
      {bottomTitle && <h2 className="grid-card-bottom-title">{bottomTitle}</h2>}
      {description && <p className="grid-card-description">{description}</p>}
      {buttonText && (
        <button
          type="button"
          className="grid-card-button"
          style={{
            backgroundColor: buttonBackgroundColor || "transparent",
            border: buttonBorderColor
              ? `1px solid ${buttonBorderColor}`
              : "none"
          }}
        >
          {buttonText}
        </button>
      )}
    </Link>
  );
};

export default BusinessOpsGridCard;
