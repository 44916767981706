import React from "react";
import { BrProps, BrComponent } from "@bloomreach/react-sdk";
import detectIEBrowser from "@elasticpath/ref-store/src/validation/detectIEBrowser";
import CreateLink from "../../Bloomreach/components/BRlink/brlink";
import { getConfig } from "../../utils/ConfigProvider";

import AppFooterMenu from "../AppFooterColumns/appfootermenu";

import "./appfooter.horizontal.less";

const AppFooterHorizontal: React.FC<BrProps> = ({ component }) => {
  const year = new Date().getFullYear();
  const domain = window.location.hostname;
  if (!component) {
    return null;
  }
  const { menu } = component.getModels();

  const { config } = getConfig();

  function createFooterLinks() {
    const scrollTop = true;
    return menu.siteMenuItems.map(menuItem => (
      <li key={`${menuItem.id}_${Math.random()}`}>
        <div>
          <CreateLink />
        </div>
      </li>
    ));
  }

  return (
    <>
      <footer className="horizontal-footer-container d-none d-md-block">
        <div className="container">
          <div className="row">
            <div className="copy-right-container col-lg-3 col-md-3">
              &copy; {`${year} ${domain}`}
            </div>
            <ul className="footer-links-container col-lg-9 col-md-9">
              {createFooterLinks()}
            </ul>
          </div>
        </div>
      </footer>

      <footer className="app-footer d-block d-md-none">
        {!detectIEBrowser() ? (
          <BrComponent path="footer-menu">
            <AppFooterMenu />
          </BrComponent>
        ) : null}
        <div className="copyright-container container-fluid d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div>
                  &copy;
                  {`${year} ${domain}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default AppFooterHorizontal;
