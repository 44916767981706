import React from "react";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";
import { AddressContainer } from "@zilker/store-components";
import { pushToMaintenace } from "../../../app/src/utils/helpers";

import "./OrderDetailsComponent.less";

interface OrderDetailsComponentProps {
  job?: { jobNumber: string; jobName: string };
  contractNumber?: string;
  poNumber?: string;
  orderData?: any;
}

const OrderDetailsComponent: React.FC<OrderDetailsComponentProps> = ({
  job,
  contractNumber,
  poNumber,
  orderData
}) => {
  const history = useHistory();

  const jobNumber = job ? job.jobNumber : null;
  const jobName = job ? job.jobName : null;

  const renderBillingAddress = () => {
    const [
      billingAddress
    ] = orderData._order[0]._billingaddressinfo[0]._billingaddress;
    const { name, address } = billingAddress;
    return (
      <li className="flex-container">
        <span>{`${intl.get("billing-address")}: `}</span>
        <AddressContainer name={name} address={address} />
      </li>
    );
  };

  const renderPaymentMethod = () => {
    let displayName;
    try {
      const paymentMethodType =
        orderData._order[0]._paymentinstrumentselector[0]._chosen[0]
          ._description[0].name;
      displayName = intl.get(paymentMethodType);
    } catch (error) {
      pushToMaintenace(history, {
        e: error,
        errIn: "renderPaymentMethod => OrderDetailsComponent.tsx"
      });
    }
    return (
      <li className="flex-container">
        <span>{`${intl.get("payment-method")}: `}</span>
        <span>{displayName}</span>
      </li>
    );
  };

  return (
    <div>
      <ul className="job-branch-items">
        {jobNumber && jobName && (
          <li>
            <span>{`${intl.get("job")}:`}</span>
            {` ${jobNumber} ${jobName}`}
          </li>
        )}
        {contractNumber && (
          <li>
            <span>{`${intl.get("contract-number")}:`}</span>
            {` ${contractNumber}`}
          </li>
        )}
        {poNumber && (
          <li>
            <span>{`${intl.get("purchase-order")}:`}</span>
            {` ${poNumber}`}
          </li>
        )}
        {orderData &&
          orderData._order[0]._billingaddressinfo &&
          renderBillingAddress()}
        {orderData &&
          orderData._order[0]._paymentinstrumentselector &&
          renderPaymentMethod()}
      </ul>
    </div>
  );
};
export default OrderDetailsComponent;
