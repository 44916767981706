/* eslint-disable class-methods-use-this */
import { getConfig, cortexFetch } from "@zilker/store-components";
import * as TokenService from "./TokenService";
import { fetchAccounts, getMincronUserData } from "./EpServices";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  validateCompanyAffiliation
} from "../utils/helpers";

// import { checkTokensExpired } from "../utils/helpers";

/**
 * @member {function}
 * @desc Generate POST form body.
 */
export const _generateFormBody = userDetails => {
  const userFormBody = [];
  let userFormBodyString = "";
  Object.keys(userDetails).forEach(encodedKey => {
    const encodedValue = userDetails[encodedKey];
    userFormBody.push(`${encodedKey}=${encodedValue}`);
  });
  userFormBodyString = userFormBody.join("&");

  return userFormBodyString;
};

/**
 * @member {function}
 * @desc Indicates if a user is logged in.
 */
export const isLoggedIn = () => {
  return !!localStorage.getItem(
    `${getConfig().config.cortexApi.scope}_oAuthUserName`
  );
};

/**
 * @member {function}
 * @desc Indicates whether a user is in the process of logging in.
 * @param {Object} tempSsoTokens
 */
export const isLoginInProcess = tempSsoTokens => {
  const { _oAuthToken } = tempSsoTokens;
  return !!_oAuthToken;
};

/**
 * @member {function}
 * @desc Indicates whether a user is in the process of logging in.
 * @param {string} token
 * @param {string} cartName
 * @param {Object} tempSsoTokens
 */
export const setTokensToLocalStorage = (token, cartName, tempSsoTokens) => {
  const { config } = getConfig();
  const {
    _oAuthToken,
    _oAuthRole,
    _oAuthScope,
    _oAuthUserName
  } = tempSsoTokens;
  if (isLoginInProcess(tempSsoTokens)) {
    if (_oAuthRole) {
      localStorage.setItem(`${config.cortexApi.scope}_oAuthRole`, _oAuthRole);
    }
    if (_oAuthScope) {
      localStorage.setItem(`${config.cortexApi.scope}_oAuthScope`, _oAuthScope);
    }
    if (_oAuthToken) {
      localStorage.setItem(`${config.cortexApi.scope}_oAuthToken`, _oAuthToken);
    }
    if (_oAuthUserName) {
      localStorage.setItem(
        `${config.cortexApi.scope}_oAuthUserName`,
        _oAuthUserName
      );
    }
  }
  localStorage.setItem(`${config.cortexApi.scope}_b2bCart`, cartName);
  localStorage.setItem(`${config.cortexApi.scope}_oAuthToken`, token);
  localStorage.setItem(`DK_oAuthToken`, token);
};

export const isTokenSet = () => {
  return !!localStorage.getItem(
    `${getConfig().config.cortexApi.scope}_oAuthToken`
  );
};

/**
 * @member {function}
 * @desc Do public login (proxy to Token service).
 */
export const doPublicLogin = () => {
  return TokenService.getEPTokens().then(tokens =>
    TokenService.setEPTokens(tokens)
  );
};

/**
 * @member {function}
 * @desc Log in a registered user.
 * @param {string} username
 * @param {string} password
 */
export const doRegisteredLogin = ({ username, password }) => {
  return new Promise((resolve, reject) => {
    const registeredUserDetails = {
      username,
      password,
      grant_type: "password",
      role: "REGISTERED",
      scope: getConfig().config.cortexApi.scope
    };

    cortexFetch("/oauth2/tokens", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        Authorization: localStorage.getItem(
          `${getConfig().config.cortexApi.scope}_oAuthToken`
        )
      },
      body: _generateFormBody(registeredUserDetails)
    })
      .then(res => {
        const onSuccess = data => resolve(data.json());
        const onError = data => reject(data);
        return checkResponse(res, onSuccess, onError);
      })
      .catch(error => reject(error));
  });
};

/**
 * @member {function}
 * @desc Set cart information based on selected cart
 * @param {MainContext} mainContext
 * @param {selectedCart} number
 * @param {givenCartData} number
 * @param {handleModalClose} number
 */

export const handleSelectedCart = (
  mainContext,
  selectedCart,
  givenCartData?,
  handleModalClose?
) => {
  const { config } = getConfig();
  const {
    auth: { tempSsoTokens, setLogin },
    cart: { setIsCartFetchReady, cartDataFromAuth }
  } = mainContext;
  const cartData = givenCartData || cartDataFromAuth;
  const { _oAuthToken, _oAuthRole } = tempSsoTokens;
  const token =
    _oAuthToken || localStorage.getItem(`${config.cortexApi.scope}_oAuthToken`);
  const userRole =
    _oAuthRole || localStorage.getItem(`${config.cortexApi.scope}_oAuthRole`);

  if (userRole === "REGISTERED") {
    const selectedCartData = cartData[selectedCart].customerNumber;

    setTokensToLocalStorage(token, selectedCartData, tempSsoTokens);
    setIsCartFetchReady(true);
    setLogin(true);

    if (handleModalClose) {
      handleModalClose();
    }
  }
};

const handleRegisterUserModal = (user, logout, history) => {
  getMincronUserData()
    .then(data => {
      const ssoError = {
        errorMsgId: "CUSTOMER_NOT_FOUND",
        firstName: data["first-name"],
        lastName: data["last-name"],
        phoneNumber: data["phone-number"],
        email: data.email
      };

      localStorage.setItem("sso_error", JSON.stringify(ssoError));
    })
    .then(() => {
      user.removeUserProfile();
      logout().catch(err =>
        pushToMaintenace(history, {
          e: err,
          errIn: "Logout => handleRegisterUserModal => LoginService.tsx"
        })
      );
    });
};

/**
 * @member {function}
 * @desc Obtain and set user related data we get from cortexFetch responses
 * @param {History} history
 * @param {MainContext} mainContext
 */
export const processLoggedInUser = (history, mainContext) => {
  const { config } = getConfig();
  if (localStorage.getItem("hasLoggedIn")) {
    return;
  }
  const {
    auth: { logout },
    cart: { setCartDataFromAuth, selectedCart },
    user,
    modal: { setShowCartModal }
  } = mainContext;

  fetchAccounts()
    .then(res => {
      if (res && res._element) {
        const storefrontAccounts = res._element.filter(account => {
          const companyAffiliation = account["company-affiliation"];
          const activeAccount =
            account._status &&
            account._status[0] &&
            account._status[0].status === "ACTIVE";
          if (validateCompanyAffiliation(companyAffiliation) && activeAccount) {
            return account;
          }
          return null;
        });
        const shouldDisplayRegisterForm =
          storefrontAccounts && !storefrontAccounts.length;
        const activeStorefrontAccounts = storefrontAccounts.find(acc => {
          if (
            acc._associates &&
            acc._associates[0] &&
            acc._associates[0]._self &&
            acc._associates[0]._self[0] &&
            acc._associates[0]._self[0].status === "ACTIVE"
          ) {
            return true;
          }
          return false;
        });

        if (activeStorefrontAccounts) {
          if (storefrontAccounts.length === 1 && storefrontAccounts[0]) {
            const accountGuid = storefrontAccounts[0].self.uri.split("/").pop();
            let sharedId;
            if (storefrontAccounts[0]["dealer-number"]) {
              sharedId = `IND${storefrontAccounts[0]["dealer-number"]}`;
            } else if (storefrontAccounts[0]["distributor-id"]) {
              sharedId = `DIST${storefrontAccounts[0]["distributor-id"]}`;
            } else if (storefrontAccounts[0]["customer-number"]) {
              sharedId = storefrontAccounts[0]["customer-number"];
            }
            localStorage.setItem(
              `${config.cortexApi.scope}_AccountSharedId`,
              sharedId
            );
            localStorage.setItem(
              `${config.cortexApi.scope}_AccountGuid`,
              accountGuid
            );
            const orgAuthServiceData = [
              {
                customerNumber: sharedId,
                companyName: res._element[0]["account-business-name"],
                accountGuid
              }
            ];
            setCartDataFromAuth(orgAuthServiceData);
            localStorage.setItem(
              "orgAuthData",
              JSON.stringify(orgAuthServiceData)
            );
            const cartNumberUsed =
              selectedCart || localStorage.getItem("selectedCart") || 0;

            handleSelectedCart(mainContext, cartNumberUsed, orgAuthServiceData);

            localStorage.setItem("hasLoggedIn", "true");
          } else if (storefrontAccounts.length > 1) {
            const orgAuthServiceData = storefrontAccounts.map(element => {
              const accountStatus =
                element._associates &&
                element._associates[0] &&
                element._associates[0]._self &&
                element._associates[0]._self[0] &&
                element._associates[0]._self[0].status;
              const accountGuid = element.self.uri.split("/").pop();

              return {
                customerNumber: element["customer-number"],
                companyName: element["account-business-name"],
                status: accountStatus,
                accountGuid
              };
            });
            const orgAuthServiceDataSorted = orgAuthServiceData.sort((a, b) =>
              a.status === "ACTIVE" ? -1 : b.status === "ACTIVE" ? 1 : 0
            );
            setCartDataFromAuth(orgAuthServiceDataSorted);
            localStorage.setItem(
              "orgAuthData",
              JSON.stringify(orgAuthServiceDataSorted)
            );
            const accountSharedId = localStorage.getItem(
              `${config.cortexApi.scope}_AccountSharedId`
            );
            if (orgAuthServiceData && !accountSharedId) {
              setShowCartModal(true);
            }
          }
        } else if (
          !activeStorefrontAccounts ||
          !storefrontAccounts ||
          !storefrontAccounts.length
        ) {
          if (
            !activeStorefrontAccounts &&
            storefrontAccounts &&
            storefrontAccounts.length
          ) {
            const adminsToRender = storefrontAccounts.map(
              acc => acc._admins[0]._element
            );
            const adminEmails = [];
            adminsToRender.forEach(admin => {
              admin.forEach(item => {
                if (item._associatedetails && item._associatedetails[0]) {
                  adminEmails.push(item._associatedetails[0].email);
                }
              });
            });
            const ssoError = {
              errorMsgId: "SUBUSER_NOT_APPROVED",
              admins: adminEmails.join(",")
            };

            localStorage.setItem("sso_error", JSON.stringify(ssoError));
          } else if (shouldDisplayRegisterForm) {
            handleRegisterUserModal(user, logout, history);
          } else {
            // TODO: implement logic for no account users
            localStorage.setItem("user_rights", "false");
          }
          if (!shouldDisplayRegisterForm) {
            user.removeUserProfile();
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => processLoggedInUser => LoginService.tsx"
              })
            );
          }
        }
      } else if (!res._element) {
        handleRegisterUserModal(user, logout, history);
      }
    })
    .catch(e => {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => processLoggedInUser => LoginService.tsx"
          })
        );
      } else if (localStorage.getItem("hasLoggedIn") === null) {
        pushToMaintenace(history, {
          e,
          errIn: "processLoggedInUser => LoginService.tsx"
        });
      }
    });
};

/**
 * @member {function}
 * @desc Invalidate the current session and log out. Clears localStorage.
 */
export const doLogout = () => {
  return cortexFetch("/oauth2/tokens", {
    method: "delete"
  })
    .then(res => {
      const onSuccess = data => data;
      return checkResponse(res, onSuccess);
    })
    .then(res => {
      const userRights = localStorage.getItem("user_rights");
      const ssoError = localStorage.getItem("sso_error");
      localStorage.clear();
      localStorage.setItem("user_rights", userRights);
      if (ssoError) {
        localStorage.setItem("sso_error", ssoError);
      }

      sessionStorage.removeItem("notified-branch");
      sessionStorage.removeItem("jobChosen");
      sessionStorage.removeItem("clientChosen");
      document.cookie =
        "loyaltyprogram=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "leadgenusertype=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      localStorage.setItem("ajs_user_id", "");
      localStorage.setItem("ajs_user_traits", JSON.stringify({}));
      return res;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
};
