import React, { useContext } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { useHistory } from "react-router-dom";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import AppSearchBar from "../AppSearchBar/appsearchbar";
import { brSuggest } from "../../../app/src/services/SearchService";
import "./apphomesearch.less";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

const Config: IEpConfig | any = {};
interface SearchHomePagecontext extends BrProps {
  isLoggedIn: boolean;
}
const SearchHomePage: React.FC<SearchHomePagecontext> = props => {
  const Context = useContext(MainContext);
  // const { isLoggedIn } = Context.auth;
  const history = useHistory();
  const { config } = getConfig();
  const recommendationProductlist = () => {
    const list = [
      { id: 1, link: "/", product: "GSX140361" },
      { id: 2, link: "/", product: "GSX140361" },
      { id: 3, link: "/", product: "GSX140361" },
      { id: 4, link: "/", product: "GSX140361" },
      { id: 5, link: "/", product: "GSX140361" },
      { id: 6, link: "/", product: "GSX140361" },
      { id: 7, link: "/", product: "GSX140361" },
      { id: 8, link: "/", product: "GSX140361" },
      { id: 9, link: "/", product: "Product9" }
    ];
    const trimList = list.slice(0, 7);
    return (
      <>
        <span className="recommendations-title">Recommendations</span>
        <ul>
          {trimList.map(item => (
            <li key={item.id}>
              <a href={item.link}>{item.product}</a>
            </li>
          ))}
        </ul>
      </>
    );
  };
  return (
    <div className="main-container-home-searchbar">
      <div className="search-title">Search</div>
      <AppSearchBar
        isMobileView={false}
        brSuggest={brSuggest}
        history={history}
        // isLoggedIn={isLoggedIn}
      />
      {config.showRecommendationsForSearchBar ? (
        <div className="recommended-product">{recommendationProductlist()}</div>
      ) : (
        ""
      )}
    </div>
  );
};
export default SearchHomePage;
