import React, {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from "react";
import { NavLink } from "react-router-dom";

import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";

import BreadCrumbsComponent from "../BreadCrumbsComponent/bread.crumbs.component";
import useWindowSize from "../CustomHooks/useWindowSize";

import {
  cortexFetchNavigationLookupForm,
  navigationCategoryInfoLookup
} from "../utils/CortexLookup";

interface CategoryLandingBreadCrumbsAndCardsProps {
  categoryName: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
}

const CategoryLandingBreadCrumbsAndCards: FC<
  CategoryLandingBreadCrumbsAndCardsProps
> = ({ categoryName, setPageTitle }) => {
  const { config }: { config: IEpConfig } = getConfig();

  const desktopMin = 1024;
  const [breadCrumbsMap, setBreadCrumbsMap] = useState([]);
  const [childrenCategories, setChildrenCategories] = useState<any>();
  const [width] = useWindowSize();

  useEffect(() => {
    if (categoryName) {
      setBreadCrumbsMap([]);
      cortexFetchNavigationLookupForm().then(() => {
        // call new cortexApi endpoint to get info, save to breadcrumbs map
        navigationCategoryInfoLookup(categoryName, "categorylanding").then(
          (res: any) => {
            const displayName = res["display-name"];
            const parentName = res._parent[0]["display-name"];
            setPageTitle(displayName);
            setBreadCrumbsMap([
              { param: "home", name: "Home" },
              {
                param: `nocrumb`,
                name: parentName
              },
              {
                param: "nocrumb",
                name: displayName
              }
            ]);
            setChildrenCategories(res._child);
          }
        );
      });
    }
  }, [categoryName]);

  const renderCards = () => {
    let cards = null;

    if (childrenCategories) {
      cards = childrenCategories.map(childCategory => {
        const displayName = childCategory["display-name"];
        const catImageObject = childCategory.details.filter(
          detail => detail.name === "catImage"
        );
        const imageUrl =
          catImageObject[0] && catImageObject[0]["display-value"]
            ? catImageObject[0]["display-value"]
            : config.missingImagePlaceholderUrl;
        const categoryNameRegex = new RegExp(/[\s-/]/g);
        const navLinkRouteParams = {
          pathname: `/category/${childCategory.name.replace(
            categoryNameRegex,
            ""
          )}`
        };

        return (
          <NavLink
            to={navLinkRouteParams}
            tabIndex={-1}
            key={`${displayName}-key`}
            className="nav-link"
          >
            <div className="card">
              <div className="image-container">
                <img className="card-image" src={imageUrl} alt={displayName} />
              </div>
              <p>{displayName}</p>
            </div>
          </NavLink>
        );
      });
    }
    return cards;
  };

  return (
    <>
      <BreadCrumbsComponent breadCrumbsMap={breadCrumbsMap} />
      <div className="category-card-container">{renderCards()}</div>
    </>
  );
};

export default CategoryLandingBreadCrumbsAndCards;
