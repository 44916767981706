import React, { FC, useState } from "react";
import intl from "react-intl-universal";
import BranchDropdownComponent from "../BranchDropdownComponent/BranchDropdownComponent";

import "./search.results.menu.item.less";

interface SearchResultsMenuItemProps {
  data: any;
  itemChecked: boolean;
  onFacetClicked: (any) => any;
  selectedBranch: any;
  selected: string;
  handleCheckboxChange: (any) => any;
}

const SearchResultsMenuItem: FC<SearchResultsMenuItemProps> = ({
  data,
  itemChecked,
  onFacetClicked,
  selectedBranch,
  selected,
  handleCheckboxChange
}) => {
  const [isChecked, setChecked] = useState<boolean>(itemChecked);
  const [classNameSuffix, setClassNameSuffix] = useState<string>("");
  const showResultsBy = data.type === intl.get("show-results-by");

  const toggleChecked = (event: any): void => {
    const {
      target: { checked }
    } = event;

    onFacetClicked(event);
    setChecked(checked);
    setClassNameSuffix(checked ? "active" : "");
  };

  const toggleCheckedBranches = event => {
    onFacetClicked(event);
    handleCheckboxChange(data.name);
  };

  const id = `${data.name}-${data.type}`;
  const myCurrentBranch = intl.get("my-current-branch");
  if (showResultsBy) {
    return (
      <>
        <div
          className={`facet-item ${classNameSuffix} ${
            selected === data.name ? "active" : ""
          }`}
        >
          <input
            id={id}
            type="checkbox"
            name={data.type}
            onChange={toggleCheckedBranches}
            value={data.name}
            checked={selected === data.name}
          />
          <label htmlFor={id}>
            <span className="facet-name">{data.name}</span>
          </label>
        </div>
        <div className="branch-dropdown-container">
          {selectedBranch && data.name === myCurrentBranch && (
            <BranchDropdownComponent
              selectedBranch={selectedBranch.branchNumber}
              hideBranchLabel
              shouldRenderSpan={selected !== data.name}
            />
          )}
        </div>
      </>
    );
  }
  return (
    <div className={`facet-item ${classNameSuffix}`}>
      <input
        id={id}
        type="checkbox"
        name={data.type}
        onChange={toggleChecked}
        value={data.name}
        checked={isChecked}
      />
      <label htmlFor={id}>
        <span className="facet-name">{data.name}</span>
        <span className="facet-number">{`(${data.count})`}</span>
      </label>
    </div>
  );
};

export default SearchResultsMenuItem;
