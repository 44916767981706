import React, { useContext } from "react";
import intl from "react-intl-universal";
import { useMainContext } from "../../../app/src/contexts/MainContext.js";
import "./SingleBranch.less";

interface SingleBranchProps {
  branchName: string;
  branchNumber: string;
  address: string;
  phone: string;
  fax: string;
  email: string;
  userHomeBranch: {
    [key: string]: any;
  };
  openModal: (...args: any[]) => any;
}

const SingleBranch: React.FC<SingleBranchProps> = props => {
  const {
    cart: {
      cartDetails: {
        defaultCart: { selectedBranch, items, cartOrderDetailsForm }
      }
    }
  } = useMainContext();
  const {
    branchName,
    branchNumber,
    address,
    phone,
    fax,
    email,
    userHomeBranch,
    openModal
  } = props;
  const { branchNumber: userBranchNumber } = userHomeBranch;
  const isSelectedBranch =
    selectedBranch && branchNumber === selectedBranch.code;
  const isCartAssociatedToContract =
    items &&
    items.length &&
    cartOrderDetailsForm["contract-number"] &&
    cartOrderDetailsForm.pricing.toUpperCase() === "N";

  return (
    <div
      className={`${isSelectedBranch ? "content-box active" : "content-box"} ${
        isCartAssociatedToContract && !isSelectedBranch ? "disable-branch" : ""
      }`}
    >
      <div className="branch-select">
        <input
          type="radio"
          aria-label="branch number"
          name={branchNumber}
          onChange={openModal}
          value={selectedBranch.code}
          checked={isSelectedBranch}
        />
      </div>
      <div className="branch-info">
        <h4>{branchName}</h4>
        <p>{address}</p>
        <div className="branch-info-details">
          {phone && (
            <div>
              <p className="label">{intl.get("phone")}:</p>
              <p className="value">
                <a className="show-on-mobile" href={`tel:${phone}`}>
                  {phone}
                </a>
                <span className="hide-on-mobile">{phone}</span>
              </p>
            </div>
          )}
          {fax && (
            <div>
              <p className="label">{intl.get("fax")}:</p>
              <p className="value">{fax}</p>
            </div>
          )}
          {email && (
            <div>
              <p className="label">{intl.get("email")}:</p>
              <a className="value" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="branch-status">
        {userBranchNumber === branchNumber ? (
          <div className="home-branch">
            <i className="icon-home" />
            <p>{intl.get("home")}</p>
          </div>
        ) : null}
        {isSelectedBranch ? (
          <div className="selected-branch">
            <i className="icon-check" />
            <p>{intl.get("selected-branch")}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SingleBranch;
