import axios from "axios";
import config from "../ep.config.json";

const {
  DaikinApi: { baseUri: baseURL }
} = config;

axios.defaults.headers.common["Content-Type"] = "application/json";

/**
 * ## httpGet
 * @param url string - relative URL
 * @param options? any
 *
 * @description Function for sending GET requests to custom URL,
 * with optional params.
 */
function httpGet(url: string, options = {}) {
  return axios({ method: "get", url, baseURL, ...options });
}

/**
 * ## httpGetNoToken
 * @param url string - relative URL
 * @param options? any
 *
 * @description Function for sending GET requests to custom URL,
 * without common headers and with optional params.
 */
function httpGetNoToken(url: string, options = {}) {
  const axiosNoToken = axios.create();
  axiosNoToken.defaults.headers.common = { "Content-Type": "application/json" };

  return axiosNoToken({ method: "get", url, baseURL, ...options });
}

/**
 * ## httpPost
 * @param url string - relative URL
 * @param data any
 * @param options? any
 *
 * @description Function for sending POST requests to custom URL.
 */
function httpPost(url: string, data: any, options = {}) {
  return axios({ method: "post", url, baseURL, data, ...options });
}

/**
 * ## httpPut
 * @param url string - relative URL
 * @param data any
 * @param options any
 *
 * @description Function for sending PUT requests to update a
 * certain resource.
 */
function httpPut(url: string, data: any, options = {}) {
  return axios({ method: "put", url, baseURL, data, ...options });
}

function generateCancelToken() {
  return axios.CancelToken.source();
}

function isCanceled(err) {
  return axios.isCancel(err);
}

export {
  httpGet,
  httpGetNoToken,
  httpPost,
  httpPut,
  generateCancelToken,
  isCanceled
};
