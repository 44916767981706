import React, { FC, useEffect, useState, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  getConfig,
  MultipleItemsCarousel,
  SearchResultsItem,
  CategoryLandingBreadCrumbsAndCards,
  CarouselArrowButton
} from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import {
  EntitlementDetails,
  PriceDetails
} from "@elasticpath/ref-store/src/containers/PartsFinderPage";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import {
  generateSpecificErrorMessage,
  handleCustomException,
  updateBranchNumber,
  formatQuoteAvailability
} from "@elasticpath/ref-store/src/utils/helpers";
import intl from "react-intl-universal";
import { brSearch } from "../services/SearchService";
import "./CategoryLandingPage.less";
import { MainContext } from "../contexts/MainContext";
import { SingleProduct } from "../utils/searchUtils";

import { getProductPrice } from "../utils/mappings/productDetails";
import {
  getAvailability,
  checkEntitlementGQL
} from "../services/connectGQLservices";

interface CategoryLandingPageProps extends RouteComponentProps {
  history: any;
  match: any;
}

const CategoryLandingPage: FC<CategoryLandingPageProps> = ({
  history,
  match
}) => {
  const {
    params: { categoryName },
    url
  } = match;
  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    job: any;
    branches: any;
    navigation: any;
    account: any;
  }>(MainContext);
  const {
    cart: {
      cartDetails: { defaultCart },
      getCartDetails,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage
    },
    job: { persistedJobNumber },
    auth: { isLoggedIn, logout },
    branches: { branchesList },
    account: {
      accountDetails: { customerNumber }
    }
  } = context;
  const [productAvailability, setProductAvailability] = useState(null);
  const [inventoryError, setInventoryError] = useState<string>("");
  const [productPrices, setProductPrices] = useState<PriceDetails[]>(null);
  const clientId = defaultCart ? defaultCart.clientId : null;
  const [htmlData, setHtmlData] = useState("");
  const [carditems, setCardItems] = useState([]);
  const [products, setProducts] = useState<SingleProduct[]>([]);
  const [addCartButtonIds, setAddCartButtonsIds] = useState<Array<string>>([]);
  const [productEntitlements, setProductEntitlements] = useState<
    EntitlementDetails[]
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageTitle, setPageTitle] = useState<string>("");

  const { config } = getConfig();
  const { calculatePrice } = config;

  const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
  const jobNumber = defaultCart ? defaultCart.jobNumber : null;

  const fetchParams = {
    qParam: categoryName,
    searchType: "keyword",
    start: 0,
    rows: 10,
    filter: "",
    fqParam: "",
    urlParam: "",
    urlRef: "",
    branches: [],
    membership: [],
    disableSpellCheck: true
  };
  const fetchResult = async () => {
    const res = await brSearch(
      fetchParams.qParam,
      fetchParams.searchType,
      fetchParams.start,
      fetchParams.rows,
      fetchParams.filter,
      fetchParams.fqParam,
      fetchParams.urlParam,
      fetchParams.urlRef,
      fetchParams.branches,
      fetchParams.membership,
      fetchParams.disableSpellCheck
    );
    const {
      data: { campaign, response }
    } = res;
    if (campaign) {
      const {
        data: {
          campaign: { htmlText }
        }
      } = res;
      setHtmlData(htmlText);
    } else {
      setHtmlData("");
    }
    if (response) {
      const {
        data: {
          response: { docs }
        }
      } = res;
      setCardItems(docs);
      setProducts(docs);
      setIsLoading(false);
    } else {
      setCardItems([]);
    }
  };

  const fetchProductProductPrices = async () => {
    if (products.length) {
      if (isLoggedIn && selectedBranch && customerNumber) {
        const priceRequestBody = {
          customerNumber,
          shipments: [
            {
              branchNumber: selectedBranch.code,
              customerPickup: false,
              items: products.map(product => ({
                sku: product.pid,
                quantity: 1
              }))
            }
          ],
          jobNumber
        };

        if (defaultCart.jobNumber || persistedJobNumber) {
          priceRequestBody.jobNumber =
            defaultCart.jobNumber || persistedJobNumber;
        }
        await getProductPrice(
          priceRequestBody,
          res => {
            setProductPrices(res.data.quote.shipments[0].items);
          },
          e => {
            const errorPath = "fetchProductPrices => SearchResultsPage.tsx";
            handleCustomException(e, logout, history, errorPath);
            setProductPrices([]);
          }
        );
      }
    }
  };
  const fetchProductAvailability = async () => {
    if (isLoggedIn && selectedBranch && branchesList) {
      const skus = products.map((product: SingleProduct) => product.pid);
      try {
        const branchNumber = selectedBranch.code;
        const { data } = await getAvailability(
          skus,
          customerNumber,
          branchNumber
        );
        if (data && data.data && data.data.inventory) {
          if (!data.data.inventory.entitledInventory) {
            setInventoryError(intl.get("inventory-error"));
          } else {
            const availabilityData = data.data.inventory.entitledInventory;
            const availability = formatQuoteAvailability(
              availabilityData.branches,
              availabilityData.regionRollups,
              skus,
              availabilityData.hubInventory
            );
            setProductAvailability(availability);
            setInventoryError("");
          }
        }
      } catch (e) {
        const errorPath = "fetchProductAvailability => CategoryLandingPage.tsx";
        handleCustomException(e, logout, history, errorPath);
        setProductAvailability([]);
        setInventoryError(intl.get("inventory-error"));
      }
    }
  };

  const onAddToCart = (e, shippingMethod: "pickup" | "delivery") => {
    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      },
      account: {
        accountDetails: { homeBranch }
      }
    } = context;

    let activeBranch;
    if (defaultCart) {
      activeBranch = selectedBranch.code;
    }

    const code = e.target.id;
    const items = [
      {
        code,
        quantity: 1,
        "shipping-method": shippingMethod,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          code,
          airPurifierBranchNumber,
          homeBranch,
          activeBranch
        )
      }
    ];

    setAddCartButtonsIds(addCartButtonIds.concat(code));

    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        }
      }
    } = context;

    addToCart(addItemsToCart.self.uri, { items })
      .then(() => getCartDetails())
      .then(() => {
        setSuccesCartPopupMessage(1);
        setAddCartButtonsIds(prevState =>
          prevState.filter(buttonId => buttonId !== code)
        );
      })
      .catch(err => {
        console.error(err);
        setAddCartButtonsIds(prevState =>
          prevState.filter(buttonId => buttonId !== code)
        );
        setErrorCartPopupMessage(generateSpecificErrorMessage(err));
      });
  };

  const validateEntitlement = async () => {
    if (isLoggedIn && config.entitlementCheck && selectedBranch) {
      const skus = products.map((product: SingleProduct) => product.pid);
      try {
        const { data } = await checkEntitlementGQL(customerNumber, skus);
        if (data && data.data && data.data.customer) {
          setIsLoading(false);
          if (!data.data.customer.productEntitlements) {
            setProductEntitlements([]);
          } else {
            setProductEntitlements(data.data.customer.productEntitlements);
          }
        }
      } catch (e) {
        const errorPath =
          "validateEntitlement => checkEntitlementGQL => SearchResultsPage.tsx";
        handleCustomException(e, logout, history, errorPath);
        setProductEntitlements([]);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (url.includes("categorylanding")) {
      fetchResult();
    }
  }, [categoryName]);

  useEffect(() => {
    if (
      calculatePrice &&
      isLoggedIn &&
      products.length &&
      selectedBranch &&
      customerNumber
    ) {
      fetchProductProductPrices();
    }
  }, [products, selectedBranch, customerNumber]);

  // Fetch product availability
  useEffect(() => {
    if (
      isLoggedIn &&
      products.length &&
      selectedBranch &&
      customerNumber &&
      branchesList
    ) {
      fetchProductAvailability();
    }
  }, [
    isLoggedIn,
    products,
    selectedBranch,
    clientId,
    customerNumber,
    branchesList
  ]);

  useEffect(() => {
    if (isLoggedIn && products.length && customerNumber && selectedBranch) {
      validateEntitlement();
    }
  }, [isLoggedIn, products, customerNumber, selectedBranch]);

  const generateProductAvailability = (
    sku: string
  ): [number | string, number | string, any] => {
    const item =
      productAvailability &&
      productAvailability.find(
        p => p.sku === sku && p.branchNumber === selectedBranch.code
      );
    const branchAvailability = item ? item.branchAvailability : 0;
    const regionAvailability = item ? item.regionAvailability : 0;
    const dcAvailability = item ? item.dcQtyAvailable : 0;

    return [branchAvailability, regionAvailability, dcAvailability];
  };

  const productsToRender = products.map(item => {
    let priceDetails;
    let itemWithPrice;

    if (productPrices) {
      try {
        priceDetails = productPrices.find(product => product.sku === item.pid);
        const productPrice = priceDetails
          ? priceDetails.lineTotal
          : intl.get("pending");
        itemWithPrice = { ...item, productPrice };
      } catch (error) {
        itemWithPrice = { ...item, productPrice: intl.get("pending") };
      }
    } else {
      itemWithPrice = { ...item, productPrice: "" };
    }
    const entitlementDetails =
      productEntitlements && productEntitlements.find(p => p.sku === item.pid);

    const entitled = entitlementDetails
      ? entitlementDetails.entitled
      : !config.entitlementCheck;

    itemWithPrice.entitled = entitled;

    const [
      branchAvailability,
      regionAvailability,
      dcAvailability
    ] = generateProductAvailability(item.pid);

    itemWithPrice.branchAvailability = branchAvailability;
    itemWithPrice.regionAvailability = regionAvailability;
    itemWithPrice.dcAvailability = dcAvailability;
    return itemWithPrice;
  });

  const carouselConfig = {
    carouselId: "category-recommended-products-carousel",
    desktopItems: 4,
    mobile: 767,
    mobileItems: 1.5,
    tablet: 1024,
    tabletItems: 2.5
  };
  const customizedSetting = {
    arrows: carditems.length > 4,
    infinite: false,
    nextArrow: <CarouselArrowButton />,
    prevArrow: <CarouselArrowButton />,
    dots: false,
    scrollNumber: 1
  };

  return (
    <>
      {isLoading ? (
        <div className="category-landing-loader-wrapper">
          <div className="loader" />
        </div>
      ) : (
        <div>
          <h1 className="dast-category-banner">{pageTitle}</h1>
          <div className="container category-landing-page">
            {inventoryError && (
              <MessageContainer
                message={{
                  type: "basic",
                  debugMessages: inventoryError
                }}
                closeContainerHandler={null}
              />
            )}
            <CategoryLandingBreadCrumbsAndCards
              categoryName={categoryName}
              setPageTitle={setPageTitle}
            />
            {htmlData && (
              <div
                className="html-data-container "
                dangerouslySetInnerHTML={{
                  __html: htmlData
                }}
              />
            )}

            {carditems.length > 0 && (
              <div className="silder-container dast-product-carousel">
                <h2 className="dast-product-carousel-header">
                  {intl.get("related-product-title")}
                </h2>
                <MultipleItemsCarousel
                  items={productsToRender}
                  numOfItems={products.length}
                  carouselConfig={carouselConfig}
                  customizedSetting={customizedSetting}
                  displayComponent={item => (
                    <SearchResultsItem
                      item={item}
                      onAddToCart={onAddToCart}
                      key={item.pid}
                      clickedButtonLoading={addCartButtonIds.includes(item.pid)}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(CategoryLandingPage);
