import React, { useState } from "react";

// eslint-disable-next-line import/prefer-default-export
export const ClientContext = () => {
  const [persistedClient, setPersistedClient] = useState({ id: "", name: "" });

  return {
    persistedClient,
    setPersistedClient
  };
};
