import React from "react";
import Slider from "react-slick";
import "./MultipleItemsCarousel.less";

// This is CSS needed for carousel to work. Node module: slick-carousel.
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface CarouselConfig {
  mobile: number;
  tablet: number;
  carouselId: string;
  mobileItems: number;
  tabletItems: number;
  desktopItems: number;
}
interface CustomSetting {
  arrows: boolean;
  infinite: boolean;
  nextArrow: any;
  prevArrow: any;
  dots: boolean;
  scrollNumber: number;
}

interface MultipleItemsCarouselProps {
  items: Array<any>;
  numOfItems: number;
  /**
   * @description  Function that returns JSX component and passes props to it.
   * For example: item => <Component data={item} />
   */
  displayComponent: (...args: any) => any;
  carouselConfig: CarouselConfig;
  customizedSetting?: CustomSetting;
}

const MultipleItemsCarousel: React.FC<MultipleItemsCarouselProps> = props => {
  const { customizedSetting } = props;
  const generateItemsToShow = (
    itemsLength: number
  ): {
    tablet: number;
    mobile: number;
    desktop: number;
  } => {
    const { carouselConfig } = props;
    const { mobileItems, tabletItems, desktopItems } = carouselConfig;
    let mobileItemsNum: number = mobileItems;
    let tabletItemsNum: number = tabletItems;
    let desktopItemsNum: number = desktopItems;

    if (itemsLength <= desktopItemsNum) {
      desktopItemsNum = itemsLength;
    }
    if (itemsLength <= tabletItemsNum) {
      tabletItemsNum = itemsLength;
    }
    if (itemsLength <= mobileItemsNum) {
      mobileItemsNum = itemsLength;
    }
    return {
      desktop: desktopItemsNum,
      tablet: tabletItemsNum,
      mobile: mobileItemsNum
    };
  };

  const setAccessible = () => {
    setTimeout(() => {
      const elements = Array.from(
        document.getElementsByClassName("slick-slide slick-active")
      ).map(el => {
        if (
          el &&
          el.getElementsByClassName("item-sku") &&
          el.getElementsByClassName("item-sku")[0]
        ) {
          return el.getElementsByClassName("item-sku")[0].children[0];
        }
        return undefined;
      });
      elements.forEach(el => {
        if (el) {
          el.setAttribute("tabIndex", "0");
        }
      });
    }, 100);
  };

  const generateCarouselSettings = (): any => {
    const { numOfItems, carouselConfig } = props;
    const { mobile, tablet } = carouselConfig;
    const scrollNumber = customizedSetting
      ? customizedSetting.scrollNumber
      : numOfItems;
    // Required to set tabs on currently viewed items
    setAccessible();
    const customsArrows = customizedSetting ? customizedSetting.arrows : true;
    const customsInfinte = customizedSetting
      ? customizedSetting.infinite
      : true;
    const customeDots = customizedSetting ? customizedSetting.dots : true;
    const smallDeviceArrows = !customizedSetting;
    const customPreArrow = customizedSetting && customizedSetting.prevArrow;
    const customNextArrow = customizedSetting && customizedSetting.nextArrow;
    return {
      accessibility: true,
      arrows: customsArrows,
      infinite: customsInfinte,
      afterChange: setAccessible(),
      speed: 500,
      dots: customeDots,
      prevArrow: customPreArrow,
      nextArrow: customNextArrow,
      slidesToShow: generateItemsToShow(numOfItems).desktop,
      slidesToScroll: generateItemsToShow(scrollNumber).desktop,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: tablet,
          settings: {
            accessibility: true,
            arrows: smallDeviceArrows,
            infinite: customsInfinte,
            slidesToShow: generateItemsToShow(numOfItems).tablet,
            slidesToScroll: generateItemsToShow(scrollNumber).tablet
          }
        },
        {
          breakpoint: mobile,
          settings: {
            accessibility: true,
            infinite: customsInfinte,
            arrows: smallDeviceArrows,
            slidesToShow: generateItemsToShow(numOfItems).mobile,
            slidesToScroll: generateItemsToShow(numOfItems).mobile
          }
        }
      ]
    };
  };

  const { items, displayComponent } = props;

  const settings = generateCarouselSettings();

  return (
    <div className="multi-items-carousel">
      <Slider {...settings}>
        {items.map(item => (
          <div key={item.pid} className="single-carousel-item">
            {displayComponent(item)}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MultipleItemsCarousel;
