import intl from "react-intl-universal";

interface UrlToStringInterface {
  [key: string]: string;
}

const urlToStringMapp = () => {
  const map: UrlToStringInterface = {
    home: intl.get("home"),
    myAccount: intl.get("my-account"),
    accountSettings: intl.get("account-settings"),
    creditInformation: intl.get("credit-information"),
    statement: intl.get("statement"),
    addressBook: intl.get("address-book"),
    branchSettings: intl.get("branch-settings"),
    manageUsers: intl.get("manage-users"),
    savedOrders: intl.get("saved-orders"),
    standardOrders: intl.get("standard-orders"),
    orderHistory: intl.get("order-history"),
    orderDetails: intl.get("order-history"),
    invoiceQuery: intl.get("invoice-query"),
    invoiceDetails: intl.get("invoice-query"),
    contractOrders: intl.get("contract-orders"),
    contractDetails: intl.get("contract-orders"),
    leadTracking: intl.get("lead-tracking"),
    leadReporting: intl.get("lead-reporting")
  };
  return map;
};

export default urlToStringMapp;
