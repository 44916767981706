import React from "react";
import { BrProps, BrManageContentButton } from "@bloomreach/react-sdk";
import { ImageSet, Document, Pagination } from "@bloomreach/spa-sdk";
import useBanners from "../../../CustomHooks/useBanners";

import "./enhanced-banner.less";
import RichText from "../RichText/rich-text";

const EnhancedBanner = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  const [banner] = useBanners([document.getData()]);

  const { backgroundColor, displayMode, fontColor } = component.getProperties();

  const style = {
    backgroundColor: backgroundColor !== "" ? backgroundColor : "transparent",
    color: fontColor !== "" ? fontColor : null
  };

  const renderDisplayMode = () => {
    if (!displayMode || displayMode === "") {
      return (
        <div className="jumbotron">
          <p>Default Display Mode</p>
        </div>
      );
    }

    switch (displayMode) {
      case "text_only":
        return (
          banner && (
            <div className="jumbotron text d-flex flex-lg-row flex-column">
              <div className="title-container col-12 col-lg-4 p-0">
                <h1>{banner.title}</h1>
              </div>
              <div className="content-container col-12 col-lg-8 p-0">
                <RichText content={banner.content.value} />
              </div>
            </div>
          )
        );
      case "full":
        return (
          banner && (
            <div className="jumbotron full">
              <div className="title-container col-12">
                <h2>{banner.title}</h2>
              </div>
              <div className="content-container col-12">
                <RichText content={banner.content.value} />
              </div>
              <div className="link-container col-12">
                <a
                  aria-label={banner.linktitle}
                  className="dast-btn dast-btn-primary"
                  href={banner.url}
                >
                  {banner.linktitle}
                </a>
              </div>
            </div>
          )
        );
      default:
        return (
          <div className="jumbotron">
            <p>Unrecognized Display Mode</p>
          </div>
        );
    }
  };

  return (
    <div className="enhanced-banner-container container-fluid" style={style}>
      <div className="container">{renderDisplayMode()}</div>
    </div>
  );
};

export default EnhancedBanner;
