/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import uuidv4 from "uuid/v4";
import { Link, Redirect } from "react-router-dom";
import queryString from "query-string";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { zoomResetPassword } from "@zilker/store-components/src/static/zoom";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  checkMemberType,
  validateCompanyAffiliation
} from "@elasticpath/ref-store/src/utils/helpers";
import { changeBranchAndVendorOnCurrentOrder } from "@elasticpath/ref-store/src/services/EpServices";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { ReactComponent as ProfileIcon } from "../../../app/src/images/icons/profile-icon-mobile.svg";
import AppModalCartSelectMain from "../AppModalCartSelect/appmodalcartselect.main";
import AppHeaderJobSelect from "../AppHeaderJobSelect/appheaderjobselect.main";
import AppModalWarning from "../AppModalWarning/appmodalwarning.main";
import AppModalPhoneUpdate from "../AppModalPhoneUpdate/appmodalphoneupdate";
import AppHeaderPermissionDenied from "../AppHeaderPermissionDenied/appheaderpermissiondenied.main";
import { getAccessToken, discoverOIDCParameters } from "../utils/AuthService";
import { cortexFetch } from "../utils/Cortex";
import { ReactComponent as SigninIcon } from "../../../app/src/images/icons/signin-icon.svg";

import "./appheaderlogin.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AppHeaderLoginMainProps {
  history: any;
  isMobileView?: boolean;
  permission: boolean;
  onLogout?: (...args: any[]) => any;
  onLogin?: (...args: any[]) => any;
  onAuthRedirect?: (...args: any[]) => any;
  locationSearchData?: string;
  appHeaderLoginLinks: {
    [key: string]: any;
  };
  appModalLoginLinks: {
    [key: string]: any;
  };
  isLoggedIn: boolean;
  disableLogin?: boolean;
  descriptiveButtonText?: string;
  isAppHeaderMain?: boolean;
  isHeader?: boolean;
}
interface AppHeaderLoginMainState {
  openJobModal: boolean;
  loginUrlAddress: string;
  ssoLoader: boolean;
  error: string;
  isMobile: boolean;
  openPermissionDeniedModal: boolean;
}
interface OidcParameters {
  clientId: string;
  scopes: string;
  authorizationEndpoint: string;
  endSessionEndpoint: string;
}

class AppHeaderLoginMain extends React.Component<
  AppHeaderLoginMainProps,
  AppHeaderLoginMainState,
  OidcParameters
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);

    this.state = {
      openJobModal: false,
      loginUrlAddress: "",
      ssoLoader: false,
      error: "",
      isMobile: window.innerWidth < 1024,
      openPermissionDeniedModal: false
    };

    this.handleCartModalClose = this.handleCartModalClose.bind(this);
    this.logoutRegisteredUser = this.logoutRegisteredUser.bind(this);
    this.handleCartModalOpen = this.handleCartModalOpen.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    const { locationSearchData } = this.props;
    const url = locationSearchData;
    const params = queryString.parse(url);

    window.addEventListener("resize", this.updatePredicate);
    if (params.userId && params.role && params.token) {
      this.impersonate(params);
    } else if (params.role && params.token) {
      this.logoutRegisteredUser();
    }
    if (Config.b2b.openId && Config.b2b.openId.enable) {
      this.login();
    }
  }

  componentDidUpdate() // DGE-2944 - parameters used for checking if user has just logged in.
  // prevProps, prevState
  {
    const { history } = this.props;
    try {
      const {
        loginUrlAddress,
        ssoLoader,
        openPermissionDeniedModal
      } = this.state;
      const {
        context: {
          modal: { showCartModal },
          cart: { selectedCart }
        }
      } = this;

      this.shouldOpenJobModal();
      this.shouldOpenWarningModal();
      this.shouldOpenPermissionDeniedModal();
      const showSsoModalLS = localStorage.getItem("showSsoModal");
      if (showSsoModalLS === "true" && !ssoLoader) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ ssoLoader: true });
      }
      const currentSelectedCart =
        selectedCart || localStorage.getItem("selectedCart");
      const _oAuthTokenLS = localStorage.getItem(
        `${Config.cortexApi.scope}_oAuthToken`
      );
      if (
        Config.b2b.enable &&
        _oAuthTokenLS &&
        showSsoModalLS === "true" &&
        loginUrlAddress &&
        ssoLoader &&
        !showCartModal &&
        !openPermissionDeniedModal &&
        !currentSelectedCart
      ) {
        const {
          context: {
            modal: { setShowSsoModal }
          }
        } = this;

        this.handleCartModalOpen();
        setShowSsoModal(false);
        localStorage.setItem("showSsoModal", "false");
      }
    } catch (e) {
      pushToMaintenace(history, {
        e,
        errIn: "componentDidUpdate => AppHeaderLoginMain.tsx"
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  shouldOpenJobModal() {
    const { openJobModal, openPermissionDeniedModal } = this.state;
    const {
      context: {
        cart: { cartDetails, selectedCart },
        modal: { showCartModal },
        account: {
          accountDetails: { jobsArray, jobNumberRequired }
        }
      }
    } = this;
    const { defaultCart } = cartDetails;
    const currentSelectedCart =
      selectedCart || localStorage.getItem("selectedCart");
    const isJobAccount = jobNumberRequired && jobsArray && jobsArray.length > 1;
    if (
      isJobAccount &&
      !openPermissionDeniedModal &&
      !sessionStorage.getItem("jobChosen") &&
      !openJobModal &&
      defaultCart &&
      !!currentSelectedCart &&
      !showCartModal
    ) {
      this.setState({ openJobModal: true });
    }
  }

  shouldLogout() {
    const {
      context: {
        account: {
          accountDetails: { companyAffiliation }
        }
      }
    } = this;

    return !validateCompanyAffiliation(companyAffiliation);
  }

  shouldOpenPermissionDeniedModal() {
    const { openPermissionDeniedModal } = this.state;
    const {
      context: {
        account: {
          accountDetails: { companyAffiliation }
        },
        auth: { isLoggedIn }
      }
    } = this;
    const shouldLogoutUser = companyAffiliation && this.shouldLogout();

    if (isLoggedIn && shouldLogoutUser && !openPermissionDeniedModal) {
      this.setState({ openPermissionDeniedModal: true });
    }
  }

  updatePredicate() {
    this.setState({
      isMobile: window.innerWidth < 1024
    });
  }

  shouldOpenWarningModal() {
    let mismatch;
    const { error, openPermissionDeniedModal } = this.state;
    const { cart, modal, branches, auth, account } = this.context;
    const { accountDetails } = account;
    const {
      cartDetails: { defaultCart },
      getCartDetails
    } = cart;
    const { branchesList, findBranch } = branches;
    const { homeBranch } = accountDetails;
    const { logout } = auth;
    const { history } = this.props;

    const { warningModal, setWarningModal, showCartModal } = modal;
    if (defaultCart && homeBranch && branchesList) {
      const { selectedBranch, cartOrderDetailsForm } = defaultCart;
      const selectedBranchDetails = findBranch(selectedBranch.code);

      if (!selectedBranchDetails) {
        // Current branch does no longer exist in the list of entitled branches
        // We need to set current branch to the home branch
        const homeBranchDetails = findBranch(homeBranch);

        // Home branch does no longer exist in the list of entitled branches
        if (!homeBranchDetails) {
          if (!error) {
            this.setState({
              error: intl.get("unentitled-home-branch")
            });
          }
          return;
        }

        changeBranchAndVendorOnCurrentOrder(
          homeBranchDetails,
          cartOrderDetailsForm.links[0].uri
        )
          .then(() => getCartDetails())
          .catch((e: any) => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn:
                    "Logout => shouldOpenWarningModal => AppHeaderLoginMain.tsx"
                })
              );
            } else {
              pushToMaintenace(history, {
                e,
                errIn: "shouldOpenWarningModal => AppHeaderLoginMain.tsx"
              });
            }
          });
      } else {
        mismatch = homeBranch !== selectedBranch.code;

        if (
          Config.showHomeBranchWarning &&
          mismatch &&
          !openPermissionDeniedModal &&
          !warningModal.openModal &&
          !sessionStorage.getItem("notified-branch") &&
          !showCartModal
        ) {
          setWarningModal({
            openModal: true,
            modalMessage: intl.get("branch-mismatch-notice")
          });
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async login() {
    const { history } = this.props;
    const {
      context: {
        auth: { setAuthUrl }
      }
    } = this;
    try {
      const oidcSecret = uuidv4();
      localStorage.setItem("OidcSecret", oidcSecret);
      const oidcParameters: OidcParameters = await discoverOIDCParameters();
      const oidcStateObject = {
        secret: oidcSecret,
        pathname: window.location.pathname
      };

      const oidcStateEncoded = btoa(JSON.stringify(oidcStateObject));
      const redirectUrl = `${Config.b2b.openId.callbackUrl}/loggedin`;
      const { brand } = Config.b2b.openId;
      const queryArray = [
        `scope=${encodeURIComponent(oidcParameters.scopes)}`,
        "response_type=code",
        `client_id=${encodeURIComponent(oidcParameters.clientId)}`,
        `redirect_uri=${encodeURIComponent(redirectUrl)}`,
        `state=${oidcStateEncoded}`
      ];
      if (brand) {
        queryArray.push(`brand=${brand}`);
      }
      const query = queryArray.join("&");
      const loginUrl = `${oidcParameters.authorizationEndpoint}?${query}`;
      setAuthUrl(loginUrl);
      this.setState({
        loginUrlAddress: loginUrl
      });
    } catch (e) {
      pushToMaintenace(history, {
        e,
        errIn: "login => AppHeaderLoginMain.tsx"
      });
    }
  }

  logoutRegisteredUser() {
    const { history } = this.props;
    const {
      context: {
        auth: { logout },
        user
      }
    } = this;
    user.removeUserProfile();
    logout().catch(err =>
      pushToMaintenace(history, {
        e: err,
        errIn: "Logout => logoutRegisteredUser => AppHeaderLoginMain.tsx"
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleCartModalOpen() {
    const {
      context: {
        modal: { setShowCartModal }
      }
    } = this;
    setShowCartModal(true);
  }

  // eslint-disable-next-line class-methods-use-this
  handleCartModalClose() {
    const {
      context: {
        modal: { setShowCartModal }
      }
    } = this;
    setShowCartModal(false);
  }

  ssoCloseButton = () => {
    const {
      context: {
        auth: { logout }
      }
    } = this;
    const { history } = this.props;
    logout(true).catch(err =>
      pushToMaintenace(history, {
        e: err,
        errIn: "Logout => ssoCloseButton => AppHeaderLoginMain.tsx"
      })
    );
  };

  closeSsoLoader = () => {
    this.setState({
      ssoLoader: false
    });
  };

  impersonate(params) {
    const {
      context: {
        auth: { isLoggedIn }
      }
    } = this;
    const { onLogin } = this.props;
    this.logoutRegisteredUser();
    if (isLoggedIn) {
      getAccessToken(params.token).then(res => {
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthToken`,
          `Bearer ${res["access-token"]}`
        );
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthRole`,
          params.role
        );
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthUserId`,
          params.userId
        );
        localStorage.setItem(
          `${Config.cortexApi.scope}_oAuthImpersonationToken`,
          params.token
        );
        onLogin();
      });
    }
  }

  fetchPasswordResetData() {
    const {
      context: {
        auth: { logout }
      },
      props: { history }
    } = this;

    cortexFetch(`/?zoom=${zoomResetPassword.join()}`)
      .then(res => checkResponse(res))
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => fetchPasswordResetData => AppHeaderLoginMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchPasswordResetData => AppHeaderLoginMain.tsx"
          });
        }
      });
  }

  closeJobModal = () => {
    this.setState({ openJobModal: false });
  };

  render() {
    const {
      context: { auth, user, cart, contract, account, modal }
    } = this;

    const { descriptiveButtonText = "" } = this.props;

    const { isLoggedIn, logout } = auth;
    const { userProfile } = user;
    const {
      onlineBillPayLink,
      hasLeadGenRoles,
      hasLeadGenDistributorRoles,
      ecommerceUser,
      roles,
      leadGenDealer,
      ecommerceBuyer
    } = userProfile;
    const {
      accountDetails: { membership }
    } = account;
    const {
      cartDetails: { defaultCart }
    } = cart;
    const { showCartModal } = modal;
    const { contractsList } = contract;

    const {
      isMobileView,
      onAuthRedirect,
      appHeaderLoginLinks,
      history,
      isAppHeaderMain,
      isHeader
    } = this.props;
    const {
      openJobModal,
      loginUrlAddress,
      error,
      isMobile,
      openPermissionDeniedModal
    } = this.state;

    const daikinComfortPro = intl.get("dcp");
    const hasDcpMembership = checkMemberType(
      daikinComfortPro.toLowerCase(),
      membership
    );

    const userHasLeads =
      leadGenDealer !== null &&
      leadGenDealer !== "null" &&
      leadGenDealer !== undefined;

    const isValidDaikinManagerLeadGen = roles.includes("VIEW_ALL_REPORTS");
    const isValidCODLeadGen =
      hasLeadGenRoles && userHasLeads && hasDcpMembership;
    const isValidINDLeadGen =
      !ecommerceUser && !ecommerceBuyer && hasLeadGenRoles && userHasLeads;
    const isValidDistributorLeadGen = hasLeadGenDistributorRoles;

    const shouldDisplayLeadsMenuItem =
      isValidCODLeadGen ||
      isValidINDLeadGen ||
      isValidDistributorLeadGen ||
      isValidDaikinManagerLeadGen;

    const storePageDetails = () => {
      const { pathname } = window.location;
      localStorage.setItem("pageRoute", pathname);
    };

    let keycloakLoginRedirectUrl = "";
    if (Config.b2b.enable && Config.b2b.openId && !Config.b2b.openId.enable) {
      keycloakLoginRedirectUrl = `${
        Config.b2b.keycloak.loginRedirectUrl
      }?client_id=${
        Config.b2b.keycloak.client_id
      }&response_type=code&scope=openid&redirect_uri=${encodeURIComponent(
        Config.b2b.keycloak.callbackUrl
      )}`;
    }

    const makeAuthContainerDropdown = () => {
      return (
        <div className="auth-container dropdown">
          <button
            className="dropdown-toggle btn-auth-menu"
            type="button"
            id={`${isMobileView ? "mobile_" : ""}header_navbar_loggedIn_button`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            aria-label="Profile"
          >
            <ProfileIcon />
          </button>
          <div
            data-region="authMainRegion"
            className="auth-nav-container  dropdown-menu-right dropdown-menu dast-card"
            aria-label="header_navbar_login_button "
          >
            <ul
              data-el-container="global.profileMenu"
              className="auth-profile-menu-list"
            >
              <li className="dropdown-item">
                <Link to={appHeaderLoginLinks.profile} className="profile-link">
                  <div>
                    <i className="icon-user-cog" />
                    <span id="header_navbar_login_menu_my_account_link">
                      {intl.get("my-account")}
                    </span>
                  </div>
                </Link>
              </li>
              {ecommerceUser && (
                <li className="dropdown-item">
                  <Link
                    to={appHeaderLoginLinks.branchLocator}
                    className="branch-settings-link"
                  >
                    <div>
                      <i className="icon-home" />
                      <span id="header_navbar_login_menu_branch_locator_link">
                        {intl.get("branch-settings")}
                      </span>
                    </div>
                  </Link>
                </li>
              )}
              {ecommerceUser && (
                <li className="dropdown-item">
                  <Link
                    to={appHeaderLoginLinks.myOrders}
                    className="profile-link"
                  >
                    <div>
                      <i className="icon-my-orders" />
                      <span id="header_navbar_login_menu_my_orders_link">
                        {intl.get("my-orders")}
                      </span>
                    </div>
                  </Link>
                </li>
              )}
              {Config.contractOrderPageDisplay &&
              contractsList &&
              contractsList.length &&
              ecommerceUser ? (
                <li className="dropdown-item">
                  <Link
                    to={appHeaderLoginLinks.myContracts}
                    className="profile-link"
                  >
                    <div>
                      <i className="icon-my-orders" />
                      <span id="header_navbar_login_menu_my_contracts_link">
                        {intl.get("my-contracts")}
                      </span>
                    </div>
                  </Link>
                </li>
              ) : null}
              {Config.showOnlineBillPay && ecommerceUser && (
                <li className="dropdown-item">
                  <a
                    href={onlineBillPayLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="online-pay-link"
                  >
                    <div>
                      <i className="icon-online-payments" />
                      <span id="header_navbar_login_menu_online_bill_pay_link">
                        {intl.get("online-billing-website")}
                      </span>
                    </div>
                  </a>
                </li>
              )}
              {shouldDisplayLeadsMenuItem && (
                <li className="dropdown-item">
                  <Link
                    to={
                      isValidDistributorLeadGen || isValidDaikinManagerLeadGen
                        ? appHeaderLoginLinks.myReports
                        : appHeaderLoginLinks.myLeads
                    }
                    className="profile-link"
                  >
                    <div>
                      <i className="icon-user-shop" />
                      <span id="header_navbar_login_menu_my_leads_link">
                        {hasLeadGenDistributorRoles ||
                        isValidDaikinManagerLeadGen
                          ? intl.get("my-reports")
                          : intl.get("my-leads")}
                      </span>
                    </div>
                  </Link>
                </li>
              )}
              <li className="dropdown-item">
                {Config.b2b.enable ? (
                  <button
                    className="logout-link"
                    type="button"
                    data-el-label="auth.logout"
                    onClick={() => {
                      storePageDetails();
                      logout(true).catch(err => {
                        pushToMaintenace(history, {
                          e: err,
                          errIn: "Logout  => AppHeaderLoginMain.tsx"
                        });
                      });
                    }}
                  >
                    <i className="icon-sign-out" />
                    {intl.get("sign-out")}
                  </button>
                ) : (
                  <button
                    className="logout-link"
                    type="button"
                    data-el-label="auth.logout"
                    onClick={() => this.logoutRegisteredUser()}
                  >
                    <i className="icon-sign-out" />
                    {intl.get("sign-out")}
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      );
    };

    const makeLoginButton = () => {
      const shouldRenderSingInBtn =
        (isAppHeaderMain && isMobile) || !isAppHeaderMain;
      return shouldRenderSingInBtn ? (
        <>
          <a
            href={`${
              Config.b2b.openId && Config.b2b.openId.enable
                ? loginUrlAddress
                : keycloakLoginRedirectUrl
            }`}
            onClick={storePageDetails}
            className="login-auth-service-btn"
          >
            <button
              className={`login-btn ${
                descriptiveButtonText
                  ? "product-login"
                  : isHeader
                  ? "app-login"
                  : "other-login"
              }`}
              id={`${
                isMobileView ? "mobile_" : ""
              }header_navbar_loggedIn_button`}
              type="button"
            >
              {isMobileView ? (
                intl.get("account-login")
              ) : (
                <div className="login-container">
                  <p
                    className={`login ${
                      descriptiveButtonText
                        ? "product-login"
                        : isHeader
                        ? "app-login"
                        : "other-login"
                    }`}
                  >
                    {descriptiveButtonText || intl.get("sign-in")}
                  </p>
                </div>
              )}
              {isHeader && <SigninIcon className="signin-icon" />}
            </button>
          </a>
          <div data-region="authMainRegion" className="auth-nav-container" />
        </>
      ) : null;
    };

    const handleErrors = () => {
      return (
        error && (
          <Redirect
            to={{
              pathname: "/maintenance",
              state: {
                error: {
                  e: { message: error },
                  errIn: "AppHeaderLoginMain",
                  errorCode: intl.get(
                    "home-branch-not-in-branches-list-error-code"
                  )
                }
              }
            }}
          />
        )
      );
    };

    const renderJobModal = () => {
      const { items, cartOrderDetailsForm } = defaultCart;
      const isCartAssociatedToContract =
        items &&
        items.length &&
        cartOrderDetailsForm["contract-number"] &&
        cartOrderDetailsForm.pricing;

      return (
        <AppHeaderJobSelect
          closeJobModal={this.closeJobModal}
          initialJob={
            isCartAssociatedToContract
              ? defaultCart.cartOrderDetailsForm["job-number"]
              : ""
          }
          disableJobSelection={isCartAssociatedToContract}
        />
      );
    };

    return (
      <div
        className={`app-login-component ${isMobileView ? "mobile-view" : ""}`}
      >
        {handleErrors()}
        {isLoggedIn ? makeAuthContainerDropdown() : makeLoginButton()}
        {isHeader ? (
          <>
            {showCartModal && (
              <AppModalCartSelectMain
                key="app-modal-cart-selection-main"
                handleModalClose={this.handleCartModalClose}
                handleCartModalCloseButton={this.ssoCloseButton}
                openModal={showCartModal}
                onAuthenticationRedirect={onAuthRedirect}
                closeSsoLoader={this.closeSsoLoader}
                history={history}
                auth={auth}
              />
            )}
            {openJobModal && renderJobModal()}
            {openPermissionDeniedModal && (
              <AppHeaderPermissionDenied
                openModal={openPermissionDeniedModal}
              />
            )}
            <AppModalWarning />
            <AppModalPhoneUpdate history={history} />
          </>
        ) : null}
      </div>
    );
  }
}

export default AppHeaderLoginMain;
