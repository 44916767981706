import intl from "react-intl-universal";
import isEmpty from "./isEmpty";
import { isInvalidEmail } from "../utils/helpers";

function contactUsFormValidor(form) {
  const errors = {};

  if (isInvalidEmail(form.email)) {
    errors.email = isInvalidEmail(form.email);
  }

  if (!form.name.trim()) {
    errors.name = intl.get("field-cannot-be-empty");
  }

  if (!form.message.trim()) {
    errors.message = intl.get("field-cannot-be-empty");
  }

  if (!form.subject.trim()) {
    errors.subject = intl.get("subject-must-be-selected");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}

export default contactUsFormValidor;
