/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import {
  pushToMaintenace,
  checkTokensExpired,
  extractPhoneAndFax
} from "@elasticpath/ref-store/src/utils/helpers";
import { updateAccount } from "@elasticpath/ref-store/src/services/EpServices";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "./DefaultBranchInfoCard.less";

interface DefaultBranchProps {
  homeBranchNumber: string;
  history: any;
  auth: any;
  user: any;
  account: any;
}

export interface BranchDetails {
  address: string;
  address1: string;
  addressee: string;
  branchManager: string;
  branchName: string;
  branchNumber: string;
  city: string;
  country: string;
  createdAt: any;
  customBranchEmail?: string;
  distance?: string;
  distributor: string;
  devisionCode: string;
  devisionName: string;
  ehub: boolean;
  email: string;
  emergencyContact: string;
  fax: string;
  formattedAddress: string;
  geocoded: boolean;
  items?: any;
  latitude: string;
  location: any;
  locationType: string;
  longitude: string;
  otherContact: string;
  phone: string;
  regionCode: string;
  regionName: string;
  state: string;
  timezone: string;
  updatedAt: string;
  zip: string;
  _id: string;
}

const DefaultBranchInfoCard: React.FC<DefaultBranchProps> = ({
  homeBranchNumber,
  history,
  auth,
  user,
  account
}) => {
  const [homeBranchDetails, setHomeBranchDetails] = useState<BranchDetails>();
  const [selectedBranch, setSelectedBranch] = useState<BranchDetails>();
  const [loadingChangeBranch, setLoadingChangeBranch] = useState<boolean>(
    false
  );
  const context = useContext<{ branches: any }>(MainContext);
  const {
    branches: { branchesList, findBranch }
  } = context;
  const { accountDetails, getUserAccount } = account;
  const { customerRoles, isBuyerAdmin, company } = accountDetails;

  const { config }: { config: IEpConfig } = getConfig();
  const { defaultChannel } = config.brXM;
  const motiliStyle: boolean = defaultChannel === "motili";

  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const onBranchSelection = (e: any): void => {
    const { value: branch } = e.target;

    setSelectedBranch(JSON.parse(branch));
  };

  const changeHomeBranch = (): void => {
    const { logout } = auth;
    setLoadingChangeBranch(true);

    const updates = {
      "account-home-branch": selectedBranch.branchNumber,
      "account-home-branch-vendor": selectedBranch.distributor,
      "account-business-name": company
    };
    updateAccount(updates, accountDetails)
      .then(() => {
        return getUserAccount();
      })
      .then(() => {
        setLoadingChangeBranch(false);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err => {
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => changeDefaultBranch => DefaultBranchInfoCard.tsx"
            });
          });
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "changeDefaultBranch => DefaultBranchInfoCard.tsx"
          });
        }
      });
  };

  useEffect(() => {
    fetchAllBranches();
  }, [homeBranchNumber, branchesList]);

  const fetchAllBranches = (): void => {
    if (branchesList) {
      if (!homeBranchNumber) {
        // User missing home branch (No home branch defined in DCS profile)
        pushToMaintenace(history, {
          e: { message: intl.get("no-home-branch-error") },
          errIn: "fetchAllBranches => DefaultBranchInfoCard.tsx",
          errorCode: intl.get("missing-home-branch-error-code")
        });
      } else {
        const userHomeBranch: BranchDetails = findBranch(homeBranchNumber);
        if (userHomeBranch) {
          setHomeBranchDetails(userHomeBranch);
          setSelectedBranch(userHomeBranch);
        } else {
          // Home branch does no longer exist in the list of entitled branches
          pushToMaintenace(history, {
            e: { message: intl.get("unentitled-home-branch") },
            errIn: "fetchAllBranches => DefaultBranchInfoCard.tsx",
            errorCode: intl.get("home-branch-not-in-branches-list-error-code")
          });
        }
      }
    }
  };

  /* Instruction to point sub user to chat or contact us page to ask to update user level default home branch. */
  const subuserBranchInstructions = (
    <div className="subuser-branch-instructions-box">
      <span>{intl.get("subuser-branch-instructions-pt1")}</span>
      <Link to="/contactus" aria-label={intl.get("contact-us")}>
        {intl.get("contact-us").toLowerCase()}
      </Link>
      <span>{intl.get("subuser-branch-instructions-pt2")}</span>
    </div>
  );

  const { phone, fax } = extractPhoneAndFax(homeBranchDetails);

  return (
    <div>
      {homeBranchDetails ? (
        <>
          <div className="default-branch-row">
            <h4>{`${intl.get("default-cod")}: `}</h4>
            <span className="default-branch-name">
              {homeBranchDetails.branchName}
            </span>
          </div>
          <div className="default-branch-row">
            <p className="default-branch-address">
              {homeBranchDetails.formattedAddress}
            </p>
          </div>
          <div className="row default-branch-row">
            {phone && (
              <div className={`col-sm-${motiliStyle ? "12" : "6"}`}>
                <span className="default-branch-label label">
                  {`${intl.get("phone")}: `}
                </span>
                <a className="show-on-mobile" href={`tel:${phone}`}>
                  {phone}
                </a>
                <span className="default-branch-contact hide-on-mobile">
                  {phone}
                </span>
              </div>
            )}
            {fax && (
              <div className={`col-sm-${motiliStyle ? "12" : "6"}`}>
                <span className="default-branch-label label">
                  {`${intl.get("fax")}: `}
                </span>
                <span className="default-branch-contact">{fax}</span>
              </div>
            )}
          </div>
          {homeBranchDetails.email && (
            <div className="row default-branch-row">
              <div className="col-sm-12">
                <span className="default-branch-label label">
                  {`${intl.get("email")}: `}
                </span>
                <span className="default-branch-contact">
                  <a href={`mailto:${homeBranchDetails.email}`}>
                    {homeBranchDetails.email}
                  </a>
                </span>
              </div>
            </div>
          )}
          {!isVirtualBranchUser &&
            (config.canUpdateDefaultBranch && isBuyerAdmin ? (
              <>
                <h4>{`${intl.get("change-default-cod")}: `}</h4>
                <p>{intl.get("select-different-cod")}</p>
                <p className="additional-cod-info">
                  {`(${intl.get("additional-cod-info")})`}
                </p>
                <div className="branch-selection">
                  <div className="branch-dropdown home-branch-dropdown">
                    <select
                      name="branch-dropdown"
                      id="branch-dropdown"
                      onChange={onBranchSelection}
                      value={JSON.stringify(selectedBranch)}
                    >
                      {branchesList.map(branch => {
                        return (
                          <option
                            className="branch-dropdown-option"
                            key={branch.branchNumber}
                            value={JSON.stringify(branch)}
                          >
                            {branch.branchName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {loadingChangeBranch ? (
                    <div className="miniLoader" />
                  ) : (
                    <button
                      className="dast-btn dast-btn-primary text-nowrap"
                      type="button"
                      onClick={changeHomeBranch}
                    >
                      {intl.get("save")}
                    </button>
                  )}
                </div>
              </>
            ) : (
              subuserBranchInstructions
            ))}
        </>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
};

export default DefaultBranchInfoCard;
