import { processHttpResponse } from "../helpers";
import * as Config from "../../ep.config.json";
import { priceCatalogGQL } from "../../services/connectGQLservices";

interface ProductAttribute {
  "display-value": string;
  "display-name": string;
  name: string;
  value: string;
}

/**
  * ## generateImageUrl
  * 
  * @param details  Array<ProductAttribute>
  * @param imageType string - by default it is thumb_image, but user can also specify it to be full_image
    
  @remarks Extract the thumb image (or full image) URL from the item
  definitions attributes (details) array.
    
  @returns string 
*/

const generateImageUrl = (
  details: ProductAttribute[],
  imageType: string = "full_image"
): string => {
  const imageDetails = details.find((attribute: ProductAttribute) => {
    return attribute.name === imageType;
  });

  return imageDetails
    ? imageDetails["display-value"]
    : Config.missingImagePlaceholderUrl;
};

/**
 * ## getProductPrice
 *
 * @param payload any - request body for product price
 * @param onSuccess function - accepts response data and response status code
 * @param onError function - handles exceptions, accepts error message
 *
 * @description Call Daikin service to fetch pricing for the product(s).
 */

const getProductPrice = (
  payload: any,
  onSuccess: (data: any, status: number) => any,
  onError: (message: any) => any
): Promise<any> => {
  return priceCatalogGQL(payload)
    .then((pricingResponse: { data: any; status: number }) => {
      const { status, data } = pricingResponse;
      processHttpResponse({ data, status }, onSuccess, onError);
    })
    .catch(err => {
      onError(err);
    });
};

export { generateImageUrl, getProductPrice };
