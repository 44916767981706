import React, { FC, useEffect } from "react";
import intl from "react-intl-universal";
import { SearchResultsAvailabilityCheckbox } from "@zilker/store-components";
import { useMainContext } from "../../../app/src/contexts/MainContext.js";
import SearchResultsMenuSection from "./search.results.menu.section";
import useWindowSize from "../CustomHooks/useWindowSize";

import "./search.results.menu.less";

interface SearchResultsMenuProps {
  facets: any;
  filtersChecked: any;
  onFacetClicked: (filter: string) => any;
  checked: boolean;
  handler: (event: any) => void;
  selected: string;
  setSelected: any;
  openedFacetFields?: any[];
  toggleOpenedFacetFields?: (facetName: string) => any;
}

const SearchResultsMenu: FC<SearchResultsMenuProps> = ({
  facets,
  filtersChecked,
  onFacetClicked,
  checked,
  handler,
  selected,
  setSelected,
  openedFacetFields,
  toggleOpenedFacetFields
}) => {
  const {
    auth: { isLoggedIn }
  } = useMainContext();
  const [width, height] = useWindowSize();
  const showResultsBy = intl.get("show-results-by");
  // Values obtained from variables.less media query values
  const desktopMin = 1024;

  const inventorySortingArray = [
    {
      name: intl.get("in-my-region"),
      type: showResultsBy
    },
    {
      name: intl.get("my-current-branch"),
      type: showResultsBy
    },
    {
      name: intl.get("all-including-products-not-in-region"),
      type: showResultsBy
    }
  ];

  const handleCheckboxChange = option => {
    setSelected(option);
  };

  useEffect(() => {
    setSelected(intl.get("in-my-region"));
  }, []);

  return (
    <div className="search-results-menu">
      <div>
        <div className="search-results-menu-items">
          {isLoggedIn && width < desktopMin && (
            <SearchResultsAvailabilityCheckbox
              checked={checked}
              handler={handler}
            />
          )}
          {isLoggedIn && (
            <SearchResultsMenuSection
              key={showResultsBy}
              name={showResultsBy}
              data={inventorySortingArray}
              filtersChecked={filtersChecked}
              onFacetClicked={onFacetClicked}
              toggleOpenedFacetFields={toggleOpenedFacetFields}
              openedFacetFields={openedFacetFields}
              handleCheckboxChange={handleCheckboxChange}
              selected={selected}
            />
          )}
          {Object.keys(facets).map((facetName, i) => {
            const key = `${facetName}-${i}`;
            return (
              <SearchResultsMenuSection
                key={key}
                name={facetName}
                data={facets[facetName]}
                filtersChecked={filtersChecked}
                onFacetClicked={onFacetClicked}
                toggleOpenedFacetFields={toggleOpenedFacetFields}
                openedFacetFields={openedFacetFields}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SearchResultsMenu;
