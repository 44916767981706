import React, { FC, useContext, useState, useEffect, useRef } from "react";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";

import "./saved.order.card.less";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage,
  updateBranchNumber
} from "@elasticpath/ref-store/src/utils/helpers";
import { getStandardOrderDetailsGQL } from "@elasticpath/ref-store/src/services/connectGQLservices";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { cortexFetch } from "../utils/Cortex";
import Input from "../common/InputComponent/Input";
import { productAddedSavedCartList } from "../utils/Segment";

interface CartsInterface {
  name: string;
  links: any;
  uri: string;
  descriptorUri: string;
  totalQuantity: number;
  orderNumber?: string;
}

interface SavedOrderCardProps {
  history: any;
  carts: CartsInterface[];
  isSavedOrder?: boolean;
}

interface OrderDetails {
  name: string;
  links: any;
  uri: string;
  descriptorUri: string;
  totalQuantity: number;
}

const SavedOrderCard: FC<SavedOrderCardProps> = ({
  history,
  carts,
  isSavedOrder
}) => {
  const context = useContext<{
    cart: any;
    auth: any;
    user: any;
    account: any;
    branches: any;
  }>(MainContext);
  const {
    cart: {
      cartListError,
      getSavedOrderCartList,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      getCartDetails,
      cartDetails: { defaultCart }
    },
    auth: { logout },
    account: {
      accountDetails: { customerNumber, homeBranch }
    }
  } = context;
  const [selectedOrder, setSelectedOrder] = useState<OrderDetails>(null);
  const [renameModal, setRenameModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [orderName, setOrderName] = useState<string>("");
  const [modalLoader, setModalLoader] = useState<boolean>(false);
  const [isOrderBeingAdded, setIsOrderBeingAdded] = useState({
    orderName: "",
    shippingMethod: ""
  });
  const [renameError, setRenameError] = useState<string>("");

  const isCancelled = useRef(false);

  const { config }: { config: IEpConfig } = getConfig();
  const { defaultChannel } = config.brXM;
  const motiliStyle = defaultChannel === "motili";

  useEffect(() => {
    // eslint-disable-next-line no-return-assign
    return () => (isCancelled.current = true);
  }, []);

  if (cartListError) {
    pushToMaintenace(history, {
      e: { message: cartListError },
      errIn: "Cart context error => SavedOrderCard.tsx"
    });
  }

  const openRenameModal = (index: number): void => {
    setSelectedOrder(carts[index]);
    setOrderName(carts[index].name);
    setRenameModal(true);
  };

  const openDeleteModal = (index: number): void => {
    setSelectedOrder(carts[index]);
    setDeleteModal(true);
  };

  const handleInputChange = (e: any): void => {
    setOrderName(e.target.value);
    setRenameError("");
  };

  const validateOrderName = (): boolean => {
    const duplicate: OrderDetails = carts.find(
      order =>
        order.name
          .toUpperCase()
          .trim()
          .split(" ")
          .join("") ===
        orderName
          .toUpperCase()
          .trim()
          .split(" ")
          .join("")
    );
    if (duplicate) {
      setRenameError(
        intl.get("create-order-error-1") +
          orderName +
          intl.get("create-order-error-2")
      );
      return false;
    }
    return true;
  };

  const handleRenameOrder = (): void => {
    if (validateOrderName()) {
      setModalLoader(true);
      if (selectedOrder && orderName.length) {
        cortexFetch(selectedOrder.descriptorUri, {
          method: "put",
          body: JSON.stringify({ name: orderName.trim() })
        })
          .then(res => checkResponse(res, data => data))
          .then(() => getSavedOrderCartList())
          .then(() => {
            if (!isCancelled.current) {
              setOrderName("");
              setModalLoader(false);
              setRenameModal(false);
            }
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => handleRenameOrder => SavedOrderCard.tsx"
                })
              );
            } else if (!isCancelled.current) {
              pushToMaintenace(history, {
                e,
                errIn: "handleRenameOrder => SavedOrderCard.tsx"
              });
            }
          });
      }
    }
  };

  const handleDeleteOrder = (): void => {
    setModalLoader(true);
    if (selectedOrder) {
      cortexFetch(selectedOrder.uri, {
        method: "delete"
      })
        .then(res => checkResponse(res, data => data))
        .then(() => getSavedOrderCartList())
        .then(() => {
          if (!isCancelled.current) {
            setModalLoader(false);
            setDeleteModal(false);
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => handleDeleteOrder => SavedOrderCard.tsx"
              })
            );
          } else if (!isCancelled.current) {
            pushToMaintenace(history, {
              e,
              errIn: "handleDeleteOrder => SavedOrderCard.tsx"
            });
          }
        });
    }
  };

  const addCartToDefaultCart = (
    shippingMethod: "pickup" | "delivery",
    skus: string[]
  ) => {
    const { addItemsToCart } = defaultCart;
    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      }
    } = context;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    const selectedBranchNumber = selectedBranch && selectedBranch.code;
    const items = skus.map(item => {
      return {
        code: item,
        quantity: 1,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          item,
          airPurifierBranchNumber,
          homeBranch,
          selectedBranchNumber
        ),
        "shipping-method": shippingMethod
      };
    });
    return addToCart(addItemsToCart.self.uri, { items });
  };

  const handleAddStandardOrderToCart = (
    index: number,
    shippingMethod: "pickup" | "delivery",
    orderNumber: string
  ) => {
    let cartName = "";
    if (!isCancelled.current) {
      cartName = carts && carts.length && carts[index].name;
      setIsOrderBeingAdded({
        orderName: cartName,
        shippingMethod
      });
    }

    getStandardOrderDetailsGQL(customerNumber, orderNumber).then(
      standardOrderResponse => {
        let standardOrderRes = null;
        try {
          standardOrderRes =
            standardOrderResponse.data.data.customer.standardOrder;
        } catch (err) {
          if (checkTokensExpired(err)) {
            logout().catch(logoutErr =>
              pushToMaintenace(history, {
                e: logoutErr,
                errIn:
                  "Logout => getStandardOrderDetails => saved.order.card.tsx"
              })
            );
          } else {
            console.error(err);
            pushToMaintenace(history, {
              e: err,
              errIn: "getStandardOrderDetails => saved.order.card.tsx"
            });
          }
        }

        const { detail } = standardOrderRes;
        const orderSkus = detail.map(det => det.sku);
        addCartToDefaultCart(shippingMethod, orderSkus)
          .then(() => getCartDetails())
          .then(() => {
            productAddedSavedCartList(shippingMethod, cartName);
            setSuccesCartPopupMessage(orderSkus.length);
            if (!isCancelled.current) {
              setIsOrderBeingAdded({ orderName: "", shippingMethod: "" });
            }
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn:
                    "Logout => addCartToDefaultCart => saved.order.card.tsx"
                })
              );
            } else if (!isCancelled.current) {
              setIsOrderBeingAdded({ orderName: "", shippingMethod: "" });
              setErrorCartPopupMessage(
                generateSpecificErrorMessage(e, cartName)
              );
            }
          });
      }
    );
  };

  const handleAddToDefaultCart = (
    index: number,
    shippingMethod: "pickup" | "delivery"
  ): void => {
    let cartName = "";
    if (!isCancelled.current) {
      cartName = carts && carts.length && carts[index].name;
      setIsOrderBeingAdded({
        orderName: cartName,
        shippingMethod
      });
    }
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    const selectedBranchNumber = selectedBranch && selectedBranch.code;
    let addToDefaultCartLink;
    let itemsCount;
    try {
      addToDefaultCartLink = carts[index].links.find(
        link => link.rel === "addcarttodefaultcartform"
      ).uri;

      itemsCount = carts[index].totalQuantity;
    } catch (e) {
      pushToMaintenace(history, {
        e,
        errIn: "handleAddToDefaultCart => SavedOrderCard.tsx"
      });
    }

    cortexFetch(addToDefaultCartLink, {
      method: "post",
      body: JSON.stringify({
        "shipping-method": shippingMethod,
        "branch-number": selectedBranchNumber
      })
    })
      .then(res => {
        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      // Update only the default cart - after adding item(s).
      .then(() => getCartDetails())
      .then(() => {
        productAddedSavedCartList(shippingMethod, cartName);
        setSuccesCartPopupMessage(itemsCount);
        if (!isCancelled.current) {
          setIsOrderBeingAdded({ orderName: "", shippingMethod: "" });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddToDefaultCart => SavedOrderCard.tsx"
            })
          );
        } else if (!isCancelled.current) {
          setIsOrderBeingAdded({ orderName: "", shippingMethod: "" });
          setErrorCartPopupMessage(generateSpecificErrorMessage(e, cartName));
        }
      });
  };

  const handleCloseRenameModal = (): void => {
    setRenameError("");
    setRenameModal(false);
  };

  const renderRenameModal = (): any => {
    return (
      <Modal open={renameModal} onClose={handleCloseRenameModal}>
        <div className={`modal-${motiliStyle ? "lg" : "md"} rename-modal`}>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("rename-order")}</h2>
            </div>
            {selectedOrder ? (
              <div className="modal-body">
                <Input
                  type="text"
                  label={intl.get("name")}
                  ariaLabel={intl.get("name")}
                  inputHandler={handleInputChange}
                  inputName="orderName"
                  required={motiliStyle}
                  value={orderName}
                />
                {!modalLoader ? (
                  <div>
                    <div className="edit-order-buttons">
                      <button
                        type="button"
                        className="dast-btn dast-btn-secondary"
                        aria-label={intl.get("cancel")}
                        onClick={handleCloseRenameModal}
                      >
                        {intl.get("cancel")}
                      </button>
                      <button
                        type="button"
                        aria-label={intl.get("save")}
                        className="dast-btn dast-btn-primary"
                        onClick={handleRenameOrder}
                        disabled={!orderName.trim().length}
                      >
                        {intl.get("save")}
                      </button>
                    </div>
                    <p className="rename-error">{renameError}</p>
                  </div>
                ) : (
                  <div className="miniLoader" />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    );
  };

  const renderDeleteModal = (): any => {
    return (
      <Modal open={deleteModal} onClose={() => setDeleteModal(false)}>
        <div className={`modal-${motiliStyle ? "lg" : "md"} delete-modal`}>
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("delete-order")}</h2>
            </div>
            <div className="modal-body">
              {selectedOrder ? (
                <div>
                  <p>
                    {`${intl.get("you-are-about-delete")} ${
                      selectedOrder.name
                    }`}
                  </p>
                  <p>{intl.get("delete-order-confirmation")}</p>
                  {!modalLoader ? (
                    <div className="edit-order-buttons">
                      <button
                        type="button"
                        aria-label={intl.get("cancel")}
                        className="dast-btn dast-btn-secondary"
                        onClick={() => setDeleteModal(false)}
                      >
                        {intl.get("cancel")}
                      </button>
                      <button
                        type="button"
                        aria-label={intl.get("delete")}
                        className="dast-btn dast-btn-primary delete-order-btn"
                        onClick={handleDeleteOrder}
                      >
                        <i className="icon-delete" />
                        {intl.get("delete")}
                      </button>
                    </div>
                  ) : (
                    <div className="miniLoader" />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  if (!carts) return null;
  if (carts && carts.length) {
    return (
      <div>
        {renderRenameModal()}
        {renderDeleteModal()}
        {carts.map((element, index: number) => {
          const cartName = element.name;
          const doubleEncodedCartName = encodeURIComponent(
            encodeURIComponent(cartName)
          );
          const orderNumber = element.orderNumber;
          const selfReferenceLink = `/myAccount/${
            isSavedOrder ? "savedOrders" : "standardOrders"
          }/${doubleEncodedCartName}${isSavedOrder ? element.uri : ""}`;
          const key = cartName + index;
          const buttonsDisabled =
            isOrderBeingAdded &&
            isOrderBeingAdded.orderName !== "" &&
            isOrderBeingAdded.orderName !== cartName;
          const isPickupButtonLoading =
            isOrderBeingAdded &&
            isOrderBeingAdded.orderName === cartName &&
            isOrderBeingAdded.shippingMethod === "pickup";
          const isDeliveryButtonLoading =
            isOrderBeingAdded &&
            isOrderBeingAdded.orderName === cartName &&
            isOrderBeingAdded.shippingMethod === "delivery";

          const isDisabledAddOrderForPickup =
            isDeliveryButtonLoading || buttonsDisabled;
          const isDisabledAddOrderForDelivery =
            isPickupButtonLoading || buttonsDisabled;

          return (
            <div className="cart-card vertically-aligned" key={key}>
              <p>{cartName}</p>
              <div className="cart-card-options spread-row">
                <div className="order-action-button vertically-aligned">
                  <i title={intl.get("document")} className="icon-document" />
                  <button
                    className="details-button"
                    type="button"
                    onClick={() => history.push(selfReferenceLink)}
                  >
                    {intl.get("details")}
                  </button>
                </div>
                {isSavedOrder && (
                  <div className="order-action-button vertically-aligned">
                    <i className="icon-edit" />
                    <button
                      className="rename-button"
                      type="button"
                      onClick={() => openRenameModal(index)}
                    >
                      {intl.get("rename")}
                    </button>
                  </div>
                )}
                {isSavedOrder && (
                  <div className="order-action-button vertically-aligned">
                    <i className="icon-delete" />
                    <button
                      className="delete-button"
                      type="button"
                      onClick={() => openDeleteModal(index)}
                    >
                      {intl.get("delete")}
                    </button>
                  </div>
                )}
                <div className="break" />
              </div>
              <div className="order-action-button pickup-delivery-buttons vertically-aligned">
                {!isPickupButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("pick-up-method")}
                    type="button"
                    disabled={
                      isDisabledAddOrderForPickup ||
                      (isSavedOrder && !element.totalQuantity)
                    }
                    onClick={() =>
                      isSavedOrder
                        ? handleAddToDefaultCart(index, "pickup")
                        : handleAddStandardOrderToCart(
                            index,
                            "pickup",
                            orderNumber
                          )
                    }
                  >
                    {intl.get("pick-up-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
                {!isDeliveryButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("delivery-method")}
                    type="button"
                    disabled={
                      (isSavedOrder && !element.totalQuantity) ||
                      isDisabledAddOrderForDelivery
                    }
                    onClick={() =>
                      isSavedOrder
                        ? handleAddToDefaultCart(index, "delivery")
                        : handleAddStandardOrderToCart(
                            index,
                            "delivery",
                            orderNumber
                          )
                    }
                  >
                    {intl.get("delivery-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

export default SavedOrderCard;
