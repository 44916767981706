import React from "react";
import "./call-to-action.less";
import { Link } from "react-router-dom";
import { ImageSet } from "@bloomreach/spa-sdk";
import { BrProps } from "@bloomreach/react-sdk";
import { isLinkExternal } from "@elasticpath/ref-store/src/utils/helpers";

const CallToAction = ({ component, page }: BrProps) => {
  const { document: ref } = component.getModels();
  const content = ref && page.getContent(ref);
  if (!content) {
    return <>No CTA Content to display.</>;
  }
  const {
    actionCallCard,
    image: imageRef,
    staticdropdown: alignment
  } = content.getData();
  const ctaImage = imageRef && page.getContent<ImageSet>(imageRef);
  const ctaImageUrl = ctaImage ? ctaImage.getOriginal().getUrl() : null;
  const checkLink = item => {
    const [isExternal, url] = isLinkExternal(item.buttonlink);
    const isPhoneOrEmail =
      item.buttonlink.includes("mailto:") || item.buttonlink.includes("tel:");
    return (
      <>
        {isPhoneOrEmail ? (
          <a href={item.buttonlink} className="buttons">
            {item.buttonname}
          </a>
        ) : (
          <Link
            to={isExternal ? { pathname: url } : url}
            target={!isExternal ? "_self" : "_blank"}
            rel={!isExternal ? "noopener noreferrer" : ""}
            className="buttons"
          >
            {item.buttonname}
          </Link>
        )}
      </>
    );
  };
  return (
    <div className="call-action-widget container">
      <div className="call-action-container">
        <div
          className={`${ctaImageUrl ? "cta-with-image" : "cta-without-image"}`}
        >
          {actionCallCard.map(item => (
            <div
              className={`card ${
                alignment === "Left" || alignment === "Right"
                  ? `card-${alignment}`
                  : ""
              }`}
              key={`CTA-${Math.random()}`}
            >
              <div className="container-title-content">
                <div className="title">{item.title}</div>
                <div className="content">{item.text} </div>
              </div>
              <div className="button-container">{checkLink(item)}</div>
            </div>
          ))}
        </div>
        {ctaImageUrl ? (
          <div className="image-container">
            <img src={ctaImageUrl} alt="" className="image" />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default CallToAction;
