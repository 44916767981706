import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";
import RichText from "../RichText/rich-text";

const EnhancedListGrid = ({ component, page }: BrProps) => {
  const { pageable } = component.getModels();
  const params = component.getProperties();

  const titleFontColor = params.titleColor ? params.titleColor : null;
  const titleBGColor = params.titleBGColor ? params.titleBGColor : null;

  const titleStyle = {
    backgroundColor: titleBGColor,
    color: titleFontColor
  };

  const contents = pageable.items.map(reference => {
    const document = page.getContent<Document>(reference);
    if (!document) {
      return null;
    }
    return document.getData();
  });

  if (!contents) {
    return <span>No content items</span>;
  }

  let itemClass = "grid-item col-12 col-lg-6";
  if (contents.length > 2) {
    itemClass = "grid-item col-6 col-lg-4";
  }

  const renderText = item => {
    if (item.content && item.content !== "") {
      return <RichText content={item.content} />;
    }
    return <></>;
  };

  const renderItems = () => {
    return contents.map((content, index) => {
      const imageSrc = page.getContent<ImageSet>(content.image);
      return (
        <div className={itemClass} key={content.id}>
          <a href={content.url}>
            <img
              src={imageSrc ? imageSrc.getOriginal().getUrl() : ""}
              alt={content.title}
            />
            <div className="item-title" style={titleStyle}>
              <h3>{content.title}</h3>
            </div>
            <div className="item-text" style={titleStyle}>
              {renderText(content)}
            </div>
          </a>
        </div>
      );
    });
  };

  return (
    <div className="list-grid-container container-fluid">
      <div className="container">
        <div className="row">{renderItems()}</div>
      </div>
    </div>
  );
};

export default EnhancedListGrid;
