import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import "./header-banner-ticker.less";

const HeaderBannerTicker = ({ component, page }: BrProps) => {
  if (!component || !page) {
    return null;
  }
  const { document: documentRef } = component.getModels();
  const content = documentRef && page.getContent(documentRef);
  if (!content) {
    return null;
  }
  const { text, backgroundColor, textColor, size } = content.getData();
  const style = {
    backgroundColor,
    color: textColor,
    fontSize: size
  };
  return (
    content && (
      <div
        className="header-banner-ticker"
        style={style}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  );
};

export default HeaderBannerTicker;
