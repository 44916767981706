import React, { FC, useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";

import { orderSummaryGQL } from "@elasticpath/ref-store/src/services/connectGQLservices";
import {
  checkResponse,
  checkTokensExpired,
  generateSpecificErrorMessage,
  formatPickupOrDeliveryAvailabilityLabel,
  formatPickupOrDeliveryAvailabilityLabelAhri,
  pushToMaintenace,
  isSpecialAirPurifier,
  renderSpecialAirPurifierLabel,
  handleCustomException
} from "@elasticpath/ref-store/src/utils/helpers";
import AlternateBranchList from "../AlternateBranchList/AlternateBranchList";
import { getConfig } from "../utils/ConfigProvider";
import { cortexFetch } from "../utils/Cortex";
import { generateImageUrl } from "../../../app/src/utils/mappings/productDetails";
import { useMainContext } from "../../../app/src/contexts/MainContext.js";

import "./saved.order.detail.component.less";

interface SavedOrderProps {
  cartDetail: any;
  prosItems?: any;
  cartId?: string;
  qtyChangeDisabled: boolean;
  removeItemDisabled: boolean;
  setIsChangeDisabled: (value: boolean) => any;
  refreshCartDetails: (any?) => any;
  hasError: boolean;
  groupId?: string;
  first?: boolean;
  handleGroupItemsUpdate?: (...args: any[]) => any;
  activePros?: any;
  toggleActivePros?: (...args: any[]) => any;
  handleAddSingleItemTypeToCart: (
    productCode: string,
    shippingMethod: "pickup" | "delivery",
    groupId?: string,
    setSelectedQuantity?: any
  ) => any;
  inventoryError: string;
  isProductBeingAdded: { sku: string; shippingMethod: string };
  isLoadingOrderToCart: boolean;
  entitled: any;
  isStandardOrder?: boolean;
  setOrderPrices?: any;
  setOrderSummary?: any;
  cartData?: any;
  itemIsNotFromCatalog?: boolean;
  loadingPickupOrDelivery?: any;
}

enum CortexMethod {
  PUT = "put",
  DELETE = "delete"
}

const isDesktopScreen = (): boolean => window.innerWidth >= 1024;

const SavedOrderDetailComponent: FC<SavedOrderProps> = ({
  cartDetail,
  qtyChangeDisabled,
  removeItemDisabled,
  setIsChangeDisabled,
  refreshCartDetails,
  hasError,
  groupId,
  first,
  handleGroupItemsUpdate,
  activePros,
  toggleActivePros,
  handleAddSingleItemTypeToCart,
  inventoryError,
  isProductBeingAdded,
  isLoadingOrderToCart,
  entitled,
  prosItems,
  isStandardOrder,
  setOrderPrices,
  setOrderSummary,
  cartData,
  itemIsNotFromCatalog,
  loadingPickupOrDelivery
}) => {
  const [isDesktop, setIsDesktop] = useState<boolean>(isDesktopScreen());
  const { config } = getConfig();

  const productName = isStandardOrder
    ? cartDetail.title
    : cartDetail._item[0]._definition[0]["display-name"];
  const productCode = isStandardOrder
    ? cartDetail.sku
    : cartDetail._item[0]._code[0].code;
  let productPrice;
  let productTotal;
  try {
    productPrice = {
      display: isStandardOrder
        ? `$${cartDetail.price}`
        : cartDetail._price[0]["purchase-price"][0].display,
      amount: isStandardOrder
        ? cartDetail.price
        : cartDetail._price[0]["purchase-price"][0].amount
    };
    productTotal = isStandardOrder
      ? `$${cartDetail.lineTotal}`
      : cartDetail._total[0].cost[0].display;
  } catch (e) {
    console.error(e);
    productPrice = {
      display: intl.get("pending"),
      amount: intl.get("pending")
    };
    productTotal = intl.get("pending");
  }
  if (!productTotal) {
    if (
      cartDetail._price &&
      cartDetail._price[0] &&
      cartDetail._price[0]["purchase-price"]
    ) {
      productTotal = cartDetail._price[0]["purchase-price"][0].display;
    } else if (cartDetail.price) {
      productTotal = `$${cartDetail.price}`;
    }
  }
  const { quantity, self } = cartDetail;
  let productImage;
  if (Object.keys(cartDetail).length) {
    const standardOrderImage =
      cartDetail.full_image || config.missingImagePlaceholderUrl;
    productImage = isStandardOrder
      ? standardOrderImage
      : generateImageUrl(cartDetail._item[0]._definition[0].details);
  }

  const [selectedQuantity, setSelectedQuantity] = useState<number | string>(
    quantity
  );
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [openAlternateBranchModal, setOpenAlternateBranchModal] = useState<
    boolean
  >(false);

  const history = useHistory();
  const {
    auth: { logout },
    cart: {
      setErrorCartPopupMessage,
      cartDetails: { defaultCart }
    },
    branches: {
      findBranch,
      branchesList,
      airPurifierBranch: { branchNumber: airPurifierBranchNumber }
    },
    account: {
      accountDetails: { customerNumber, homeBranch }
    }
  } = useMainContext();
  const { scope } = config.cortexApi;

  const imgMissingHorizontal = config.missingImagePlaceholderUrl;

  const activeBranch =
    defaultCart && findBranch(defaultCart.selectedBranch.code)
      ? findBranch(defaultCart.selectedBranch.code).branchName
      : "";

  const editStandardOrder = async () => {
    const priceRequestBody = {
      customerNumber,
      shipments: [
        {
          branchNumber: defaultCart.selectedBranch.code,
          customerPickup: false,
          items: cartData.map(item => ({
            sku: item.sku,
            quantity:
              item.sku === productCode ? selectedQuantity : item.quantity
          }))
        }
      ],
      jobNumber: defaultCart.jobNumber
    };
    try {
      const { data } = await orderSummaryGQL(priceRequestBody);
      const { items }: { items } = data.data.quote.shipments[0];
      const { subTotal, total, tax } = data.data.quote;
      setOrderSummary({ subTotal, total, tax });

      setOrderPrices(items);
      setUpdateLoader(false);
      setDeleteLoader(false);
      setIsChangeDisabled(false);
    } catch (error) {
      const errorPath = "editStandardOrder => saved.order.detail.component.tsx";
      handleCustomException(error, logout, history, errorPath);
    }
  };

  const editSavedOrder = (
    payload: {
      method: CortexMethod;
      body?: string;
    },
    callbackName: string
  ): void => {
    cortexFetch(self.uri, payload)
      .then(res => {
        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      .then(() => refreshCartDetails())
      .then(() => {
        setUpdateLoader(false);
        setDeleteLoader(false);
        setIsChangeDisabled(false);
      })
      .catch(e => {
        setUpdateLoader(false);
        setDeleteLoader(false);
        setIsChangeDisabled(false);
        setErrorCartPopupMessage(generateSpecificErrorMessage(e));

        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: `Logout => ${callbackName} => saved.order.detail.component.tsx`
            })
          );
        }
      });
  };

  const handleQuantityChange = (): void => {
    setUpdateLoader(true);
    setIsChangeDisabled(true);
    const itemSku = isStandardOrder
      ? cartDetail.sku
      : cartDetail._item[0]._code[0].code;

    const branchNumber = isSpecialAirPurifier(itemSku)
      ? airPurifierBranchNumber
      : homeBranch;

    const shippingMethod = isSpecialAirPurifier(itemSku)
      ? "delivery"
      : "pickup";

    if (!selectedQuantity || selectedQuantity === quantity) {
      setSelectedQuantity(quantity);
      setUpdateLoader(false);
      setIsChangeDisabled(false);
    } else if (!isStandardOrder) {
      editSavedOrder(
        {
          method: CortexMethod.PUT,
          body: JSON.stringify({
            quantity: selectedQuantity,
            "branch-number": !cartDetail["branch-number"]
              ? branchNumber
              : cartDetail["branch-number"],
            "shipping-method": !cartDetail["shipping-method"]
              ? shippingMethod
              : cartDetail["shipping-method"]
          })
        },
        "handleQuantityChange"
      );
    } else {
      editStandardOrder();
    }
  };

  const handleInputQuantityChange = (e): void => {
    if (!e.target.value) {
      setSelectedQuantity("");
    } else {
      setSelectedQuantity(parseInt(e.target.value, 10));
    }
  };

  const handleRemoveBtnClicked = (): void => {
    setDeleteLoader(true);
    setIsChangeDisabled(true);
    editSavedOrder({ method: CortexMethod.DELETE }, "handleRemoveBtnClicked");
  };

  const updateProsItemsQuantity = (): void => {
    const shippingMethod = "pickup";

    setUpdateLoader(true);
    setIsChangeDisabled(true);
    handleGroupItemsUpdate(
      groupId,
      selectedQuantity,
      setUpdateLoader,
      shippingMethod,
      homeBranch
    );
  };

  const removeProsItems = (): void => {
    setDeleteLoader(true);
    setIsChangeDisabled(true);
    handleGroupItemsUpdate(
      groupId,
      0,
      setDeleteLoader,
      cartDetail["shipping-method"],
      cartDetail["branch-number"]
    );
  };

  const onAddToCart = (shippingMethod: "pickup" | "delivery") => {
    if (
      cartDetail.regionAvailability &&
      !cartDetail.branchAvailability &&
      shippingMethod === "pickup"
    ) {
      openAlternateBranchesModal();
    } else if (isStandardOrder) {
      handleAddSingleItemTypeToCart(
        productCode,
        shippingMethod,
        groupId,
        setSelectedQuantity
      );
    } else {
      handleAddSingleItemTypeToCart(productCode, shippingMethod, groupId);
    }
  };

  const buttonsDisabled =
    isProductBeingAdded &&
    isProductBeingAdded.sku !== "" &&
    isProductBeingAdded.sku !== productCode;
  const isPickupButtonLoading =
    isProductBeingAdded &&
    isProductBeingAdded.sku === productCode &&
    isProductBeingAdded.shippingMethod === "pickup";
  const isDeliveryButtonLoading =
    isProductBeingAdded &&
    isProductBeingAdded.sku === productCode &&
    isProductBeingAdded.shippingMethod === "delivery";

  const isDisabledAddItemForPickup =
    isSpecialAirPurifier(productCode) ||
    removeItemDisabled ||
    isDeliveryButtonLoading ||
    buttonsDisabled ||
    isLoadingOrderToCart ||
    !entitled ||
    itemIsNotFromCatalog;

  const isDisabledAddItemForDelivery =
    removeItemDisabled ||
    isPickupButtonLoading ||
    buttonsDisabled ||
    isLoadingOrderToCart ||
    !entitled ||
    itemIsNotFromCatalog;

  const isOrderOrItemAddedToCart =
    (isProductBeingAdded && isProductBeingAdded.sku !== "") ||
    isLoadingOrderToCart;

  const isDisabledRemoveButton = removeItemDisabled || isOrderOrItemAddedToCart;

  const isDisabledQtyChange = qtyChangeDisabled || isOrderOrItemAddedToCart;

  const openAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(true);
  };

  const closeAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(false);
  };
  const renderAlternateBranchesModal = () => {
    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };
    const isAHRI = groupId.includes("ahri");
    const ahriNumber = isAHRI
      ? groupId.split("ahri-group-")[1]
      : cartDetail["ahri-number"];

    const quantityToUse =
      groupId !== undefined &&
      (groupId !== "undefined" &&
        prosItems.reduce((acc, item) => {
          return acc + item.quantity;
        }, 0));

    const ahriArray =
      groupId !== undefined &&
      (groupId !== "undefined" &&
        prosItems.map(item => {
          let priceNumber;
          try {
            priceNumber = item._price[0]["purchase-price"][0].amount;
          } catch (e) {
            console.error(e);
            priceNumber = intl.get("pending");
          }

          return {
            code: item._item[0]._code[0].code,
            name: item._item[0]._definition[0]["display-name"],
            brand: item._item[0]._definition[0].details.find(
              itemDetail => itemDetail.name === "brand"
            ).value,
            priceNumber,
            ...item
          };
        }));

    const skusArray = isStandardOrder
      ? []
      : prosItems.map(item => {
          return item._item[0]._code[0].code;
        });

    const ahriQtyToUse =
      typeof selectedQuantity === "string"
        ? parseInt(selectedQuantity, 10)
        : selectedQuantity;

    const productBrand = isStandardOrder
      ? cartDetail.brand
      : cartDetail._item[0]._definition[0].details.find(
          detail => detail.name === "brand"
        ).value;

    return (
      <Modal
        open={openAlternateBranchModal}
        onClose={closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          {groupId === undefined || groupId === "undefined" ? (
            <AlternateBranchList
              product={{
                name: productName,
                sku: productCode,
                price: productPrice,
                brand: productBrand
              }}
              qtyColumnHeader={intl.get("stock-status")}
              history={history}
              branches={branchesList}
              itemQty={+quantity}
            />
          ) : (
            <AlternateBranchList
              orderInfo={{
                orderName: ahriNumber,
                orderItemsLength: prosItems.length
              }}
              products={ahriArray}
              qtyColumnHeader={intl.get("stock-status")}
              history={history}
              // we aren't performing an availability check for branches other than our current branch
              branches={branchesList}
              isAhri
              itemIndividualQty={ahriQtyToUse}
              itemQty={quantityToUse}
              availaibilityDisplayedWithoutQuantity
              skusArray={skusArray}
            />
          )}
        </div>
      </Modal>
    );
  };
  const savedOrderAhri = groupId !== "undefined" ? prosItems : null;
  let itemAvailability;
  let isAhriAvailable = null;
  if (savedOrderAhri) {
    isAhriAvailable = savedOrderAhri.every(
      ahriItem => ahriItem.branchAvailability && ahriItem.branchAvailability > 0
    );
  }
  // PGL-364: remove iventory icon
  if (isSpecialAirPurifier(productCode)) {
    itemAvailability = renderSpecialAirPurifierLabel(productCode);
  } else {
    itemAvailability = formatPickupOrDeliveryAvailabilityLabel({
      branchAvailability: cartDetail.branchAvailability,
      regionAvailability: cartDetail.regionAvailability,
      currentBranch: activeBranch,
      isSingleLinedLabel: false,
      onLabelClick: openAlternateBranchesModal,
      buttonIsLoading:
        removeItemDisabled ||
        isLoadingOrderToCart ||
        buttonsDisabled ||
        isPickupButtonLoading ||
        isDeliveryButtonLoading,
      dcAvailability: cartDetail.dcAvailability,
      isStockingOrEntitlementIssue: itemIsNotFromCatalog
    });
  }

  const ahriAvailability = formatPickupOrDeliveryAvailabilityLabelAhri({
    currentBranch: activeBranch,
    ahriAvailability: isAhriAvailable,
    onLabelClick: openAlternateBranchesModal,
    buttonIsLoading:
      removeItemDisabled ||
      isLoadingOrderToCart ||
      buttonsDisabled ||
      isPickupButtonLoading ||
      isDeliveryButtonLoading
  });

  const renderProsHeader = () => {
    const isAHRI = groupId.includes("ahri");
    const ahriNumber = isAHRI
      ? groupId.split("ahri-group-")[1]
      : cartDetail["ahri-number"];
    return (
      <div className="cart-detail-card">
        <div
          className={`pros-header ${!config.calculatePrice ? "no-prices" : ""}`}
        >
          <div className="description">
            <p>
              {ahriNumber
                ? intl.get("ahri-system", {
                    number: ahriNumber
                  })
                : intl.get("system-configuration")}
            </p>
            <button
              type="button"
              className="expand-pros-button"
              onClick={() => toggleActivePros(groupId)}
            >
              {activePros.includes(groupId) ? (
                <i className="icon-chevron-up" />
              ) : (
                <i className="icon-chevron-down" />
              )}
            </button>
          </div>
          <div className="quantity detail-column">
            {selectedQuantity !== undefined ? (
              <span className="input-group-btn">
                <div className="quantity-col form-content form-content-quantity">
                  <input
                    aria-label={`"quantity " + ${selectedQuantity}`}
                    className="saved-order-quantity-select form-control form-control-quantity"
                    type="number"
                    step="1"
                    min="1"
                    max="9999"
                    value={selectedQuantity}
                    onChange={handleInputQuantityChange}
                    onBlur={updateProsItemsQuantity}
                    disabled={isDisabledQtyChange || hasError}
                  />
                </div>
              </span>
            ) : (
              ""
            )}
            {updateLoader ? (
              <div className="quantity-loader-box">
                <div className="miniLoader" />
              </div>
            ) : null}
          </div>
          {!deleteLoader ? (
            <div className="remove-btn-box">
              <button
                className={`order-item-remove-btn d-block ${
                  !config.calculatePrice ? "wide-remove-btn" : ""
                }`}
                aria-label="remove item"
                type="button"
                onClick={removeProsItems}
                disabled={isDisabledRemoveButton}
              >
                <span className="btn-text">
                  <i className="icon-close-x" />
                </span>
              </button>
            </div>
          ) : (
            <div className="remove-btn-box">
              <div className="miniLoader" />
            </div>
          )}
          <div
            className={`availability detail-column label ${
              !isDesktop ? "hide-availability" : ""
            }`}
          >
            {inventoryError
              ? intl.get("available-for-back-order-label")
              : ahriAvailability}

            {updateLoader ? <div className="miniLoader" /> : null}
          </div>
          <div className="pickup-delivery-buttons-container detail column">
            <div className="pickup-delivery-buttons">
              {!isPickupButtonLoading ? (
                <button
                  className="dast-btn dast-btn-primary"
                  aria-label={intl.get("pick-up-method")}
                  type="button"
                  disabled={isDisabledAddItemForPickup}
                  onClick={() => {
                    onAddToCart("pickup");
                  }}
                >
                  {intl.get("pick-up-method")}
                </button>
              ) : (
                <div className="miniLoader" />
              )}
              {!isDeliveryButtonLoading ? (
                <button
                  className="dast-btn dast-btn-primary"
                  aria-label={intl.get("delivery-method")}
                  type="button"
                  disabled={isDisabledAddItemForDelivery}
                  onClick={() => {
                    onAddToCart("delivery");
                  }}
                >
                  {intl.get("delivery-method")}
                </button>
              ) : (
                <div className="miniLoader" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener("resize", () => setIsDesktop(isDesktopScreen()));

    return () =>
      window.removeEventListener("resize", () =>
        setIsDesktop(isDesktopScreen())
      );
  }, []);

  useEffect(() => {
    if (loadingPickupOrDelivery === "" && isStandardOrder) {
      setSelectedQuantity(1);
    }
  }, [loadingPickupOrDelivery]);

  return (
    <div className={itemIsNotFromCatalog ? "disabled" : ""}>
      {groupId !== "undefined" && first ? renderProsHeader() : null}
      <div
        className={`cart-detail-card ${hasError ? "with-error" : ""} ${
          groupId !== "undefined"
            ? `pros-lineitem ${activePros.includes(groupId) ? "active" : ""}`
            : ""
        } ${!isDesktop ? "mobile-position" : ""} ${
          groupId !== "undefined" && isDesktop ? "ahri-systems" : ""
        }`}
        style={groupId !== "undefined" ? { backgroundColor: "#f0f0f0" } : {}}
      >
        <div className="description detail-column">
          <div className="image">
            <img
              src={productImage}
              alt={productName || intl.get("none-available")}
              onError={e => {
                const element: any = e.target;
                element.src = imgMissingHorizontal;
              }}
            />
          </div>
          <div className="product-definition">
            <p className="product-name">{productName}</p>
            {itemIsNotFromCatalog ? (
              <div className="non-catalog-item">
                <p className="label">{intl.get("product")}:</p>
                <span className="product-code">{productCode}</span>
              </div>
            ) : (
              <NavLink
                to={`/itemdetail/${productCode}`}
                target="_blank"
                tabIndex={-1}
              >
                <p className="label">{intl.get("product")}:</p>
                <span className="product-code">{productCode}</span>
              </NavLink>
            )}
          </div>
        </div>
        {isDesktop ? (
          <>
            {config.calculatePrice && (
              <div className="price detail-column">
                {itemIsNotFromCatalog ? null : (
                  <>
                    <p>{intl.get("price")}: </p>
                    <p>{productPrice.display}</p>
                  </>
                )}
              </div>
            )}
            {renderAlternateBranchesModal()}
            {// eslint-disable-next-line no-nested-ternary
            groupId === "undefined"
              ? selectedQuantity !== undefined && (
                  <div className="quantity detail-column">
                    {itemIsNotFromCatalog ? null : (
                      <span className="input-group-btn">
                        <div className="quantity-col form-content form-content-quantity">
                          <input
                            aria-label={`"quantity " + ${selectedQuantity}`}
                            className="saved-order-quantity-select form-control form-control-quantity"
                            type="number"
                            step="1"
                            min="1"
                            max="9999"
                            value={selectedQuantity}
                            onChange={handleInputQuantityChange}
                            onBlur={handleQuantityChange}
                            disabled={isDisabledQtyChange || hasError}
                          />
                        </div>
                      </span>
                    )}
                    {updateLoader ? (
                      <div className="quantity-loader-box">
                        <div className="miniLoader" />
                      </div>
                    ) : null}
                  </div>
                )
              : null}

            {groupId === "undefined" ? (
              <div className="availability detail-column label">
                {inventoryError
                  ? intl.get("available-for-back-order-label")
                  : itemAvailability}

                {updateLoader && groupId === "undefined" ? (
                  <div className="miniLoader" />
                ) : null}
              </div>
            ) : null}

            {config.calculatePrice && (
              <div className="total-price detail-column">
                {itemIsNotFromCatalog ? null : (
                  <>
                    <p>{intl.get("ext-price")}: </p>
                    <p>{productTotal}</p>
                  </>
                )}
              </div>
            )}
            {groupId === "undefined" && (
              <div className="pickup-delivery-buttons detail-column">
                {!isPickupButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("pick-up-method")}
                    type="button"
                    disabled={isDisabledAddItemForPickup}
                    onClick={() => {
                      onAddToCart("pickup");
                    }}
                  >
                    {intl.get("pick-up-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
                {!isDeliveryButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("delivery-method")}
                    type="button"
                    disabled={isDisabledAddItemForDelivery}
                    onClick={() => {
                      onAddToCart("delivery");
                    }}
                  >
                    {intl.get("delivery-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
              </div>
            )}
            {// eslint-disable-next-line no-nested-ternary
            groupId === "undefined" && !isStandardOrder ? (
              !deleteLoader ? (
                <div className="remove-btn-box">
                  <button
                    className={`order-item-remove-btn d-block ${
                      !config.calculatePrice ? "wide-remove-btn" : ""
                    }`}
                    aria-label="remove item"
                    type="button"
                    onClick={handleRemoveBtnClicked}
                    disabled={isDisabledRemoveButton}
                  >
                    <span className="btn-text">
                      <i className="icon-close-x" />
                    </span>
                  </button>
                </div>
              ) : (
                <div className="remove-btn-box">
                  <div className="miniLoader" />
                </div>
              )
            ) : (
              <div />
            )}
          </>
        ) : (
          // Mobile & tablet view
          <div className="flex-container">
            {renderAlternateBranchesModal()}
            {groupId === "undefined" && (
              <div
                className={`quantity detail-column mobile-version${
                  scope === "motili" ? " motili-mobile" : ""
                }`}
              >
                {selectedQuantity !== undefined
                  ? !itemIsNotFromCatalog && (
                      <span className="input-group-btn">
                        <div className="quantity-col form-content form-content-quantity">
                          <input
                            aria-label={`"quantity " + ${selectedQuantity}`}
                            className="saved-order-quantity-select form-control form-control-quantity"
                            type="number"
                            step="1"
                            min="1"
                            max="9999"
                            value={selectedQuantity}
                            onChange={handleInputQuantityChange}
                            onBlur={handleQuantityChange}
                            disabled={isDisabledQtyChange || hasError}
                          />
                        </div>
                      </span>
                    )
                  : ""}
                {updateLoader ? (
                  <div className="quantity-loader-box">
                    <div className="miniLoader" />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
            {config.calculatePrice && (
              <div className="price detail-column mobile-version d-flex justify-content-end">
                {itemIsNotFromCatalog ? null : (
                  <>
                    <p className="label mr-2 mx-2">{intl.get("price")}: </p>
                    <p>{productPrice.display}</p>
                  </>
                )}
              </div>
            )}
            {config.calculatePrice && (
              <div className="total-price detail-column mobile-version d-flex justify-content-end">
                {itemIsNotFromCatalog ? null : (
                  <>
                    <p className="label mr-2 mx-2">{intl.get("ext-price")}: </p>
                    <p> {productTotal}</p>
                  </>
                )}
              </div>
            )}
            {groupId === "undefined" && (
              <div className="pickup-delivery-buttons detail-column mobile-version">
                {!isPickupButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("pick-up-method")}
                    type="button"
                    disabled={isDisabledAddItemForPickup}
                    onClick={() => {
                      onAddToCart("pickup");
                    }}
                  >
                    {intl.get("pick-up-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
                {!isDeliveryButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("delivery-method")}
                    type="button"
                    disabled={isDisabledAddItemForDelivery}
                    onClick={() => {
                      onAddToCart("delivery");
                    }}
                  >
                    {intl.get("delivery-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
              </div>
            )}
            {groupId === "undefined" && !isStandardOrder && (
              <div className="remove-btn-box">
                {!deleteLoader ? (
                  <button
                    className={`order-item-remove-btn order-idem-remove-btn-mobile d-block ${
                      !config.calculatePrice ? "wide-remove-btn" : ""
                    }`}
                    aria-label="remove item"
                    type="button"
                    onClick={handleRemoveBtnClicked}
                    disabled={isDisabledRemoveButton}
                  >
                    <span className="btn-text">
                      <i className="icon-close-x" />
                    </span>
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedOrderDetailComponent;
