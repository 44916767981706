import React, { FC, useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import {
  checkResponse,
  generateSpecificErrorMessage,
  checkTokensExpired,
  pushToMaintenace,
  isSpecialAirPurifier,
  updateBranchNumber
} from "../../../app/src/utils/helpers";
import { cortexFetch } from "../utils/Cortex";
import { getConfig } from "../utils/ConfigProvider";
import ContractItem, { ContractItemDetails } from "./ContractItem";
import { ContractOrder } from "../../../app/src/containers/ContractOrdersPage";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { checkEntitlementGQL } from "../../../app/src/services/connectGQLservices";
import { addToCart } from "../../../app/src/services/EpServices";
import MessageContainer from "../MessageContainer/messagecontainer";

import "../../../app/src/theme/sharedClasses.less";
import "./ContractItemsTable.less";
import { productAdded, productViewed } from "../utils/Segment";

interface ContractItemsTableProps {
  contract: ContractOrder;
  release: boolean;
  history: any;
}

interface ItemToRelease {
  code: string;
  quantity: number;
  "contract-control-line-number": string;
}

const ContractItemsTable: FC<ContractItemsTableProps> = ({
  contract,
  release,
  history
}) => {
  const [editable, setEditable] = useState<boolean>(release);
  const [itemsToRelease, setItemsToRelease] = useState<ItemToRelease[]>([]);
  const [loadingPickupOrDelivery, setLoadingPickupOrDelivery] = useState<
    string
  >("");
  const [itemsWithDetails, setItemsWithDetails] = useState<
    Array<ContractItemDetails>
  >(null);
  const [error, setError] = useState<string>("");
  const [sliceItemsStartIndex, setSliceItemsStartIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedAll, setIsLoadedAll] = useState(false);

  const context = useContext<{
    cart: any;
    auth: any;
    job: any;
    branches: any;
    user: any;
    account: any;
  }>(MainContext);

  const { config } = getConfig();

  const {
    detailLines: items
  }: { detailLines: Array<ContractItemDetails> } = contract;

  useEffect(() => {
    fetchItemsDetails();
  }, []);

  const onLoadMore = () => {
    if (!isLoading) {
      setIsLoading(true);
      fetchItemsDetails();
    }
  };

  const loadMoreButton = (
    <div className="button-container">
      <button
        type="button"
        className={`dast-btn dast-btn-primary ${isLoading ? "loading" : ""}`}
        onClick={onLoadMore}
        aria-label={intl.get("load-more")}
      >
        {isLoading ? (
          <div className="loader-container">
            <div className="miniLoader" />
          </div>
        ) : (
          intl.get("load-more")
        )}
      </button>
    </div>
  );

  const fetchItemsDetails = () => {
    const numberOfItems = 50;
    const slicedItems = items.slice(
      sliceItemsStartIndex,
      sliceItemsStartIndex + numberOfItems
    );

    const codes = slicedItems.map(item => item.sku);
    let details: Array<ContractItemDetails>;
    const {
      auth: { logout }
    } = context;
    /**
     * 1. Call EP to get item description
     * 2. Call enetitlement service
     */
    cortexFetch(
      `/items/${config.cortexApi.scope}/lookups/batches/form/?zoom=element,element:definition,element:code&followlocation=true`,
      {
        method: "post",
        body: JSON.stringify({ codes })
      }
    )
      .then(res => checkResponse(res))
      .then(res => {
        details = slicedItems.map(item => {
          const itemDetails = res._element.find(
            el => el._code[0].code === item.sku
          );
          return {
            ...item,
            description: itemDetails
              ? itemDetails._definition[0]["display-name"]
              : ""
          };
        });
        const skus = slicedItems.map(item => item.sku);
        return checkEntitlementGQL(contract.customerNumber, skus);
      })
      .then(res => {
        details = details.map(itemDetails => {
          const { data } = res;
          let entitledItem;
          if (
            data &&
            data.data &&
            data.data.customer &&
            data.data.customer.productEntitlements &&
            data.data.customer.productEntitlements.length
          ) {
            entitledItem = data.data.customer.productEntitlements.find(
              el => el.sku === itemDetails.sku
            );
          } else {
            entitledItem = null;
          }
          return {
            ...itemDetails,
            entitled: entitledItem ? entitledItem.entitled : false
          };
        });
        if (itemsWithDetails === null) {
          setItemsWithDetails(details);
        } else {
          setItemsWithDetails(prevArray => {
            const updatedItemsWithDetails = [...prevArray, ...details];
            if (updatedItemsWithDetails.length === items.length) {
              setIsLoadedAll(true);
            }
            return updatedItemsWithDetails;
          });
        }
        setSliceItemsStartIndex(prev => prev + numberOfItems);
        setIsLoading(false);
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchItemsDetails => ContractItemsTable.tsx"
            })
          );
        }
        if (itemsWithDetails === null) {
          setItemsWithDetails(slicedItems);
        } else {
          setItemsWithDetails(prevArray => {
            const updatedItemsWithDetails = [...prevArray, ...slicedItems];
            if (updatedItemsWithDetails.length === items.length) {
              setIsLoadedAll(true);
            }
            return updatedItemsWithDetails;
          });
        }
        setSliceItemsStartIndex(prev => prev + numberOfItems);
        setIsLoading(false);
      });
  };

  const handleQuantityEntered = (
    productNumber: string,
    quantity: string,
    lineCtlNo: string
  ) => {
    const item: ItemToRelease = {
      code: productNumber,
      quantity: Number(quantity),
      "contract-control-line-number": lineCtlNo
    };
    let updatedItems = [...itemsToRelease];
    const existingItemIndex = updatedItems.findIndex(
      ({ code }) => code === productNumber
    );
    setItemsWithDetails(
      itemsWithDetails.map(itemDetails => ({
        ...itemDetails,
        exeededQty: false
      }))
    );

    if (existingItemIndex >= 0) {
      if (!quantity) {
        updatedItems = updatedItems.filter(
          updatedItem => updatedItem.code !== item.code
        );
      } else {
        updatedItems[existingItemIndex] = item;
      }
    } else {
      updatedItems.push(item);
    }

    setError("");
    setItemsToRelease(updatedItems);
  };

  const resetTable = () => {
    setLoadingPickupOrDelivery("");
    setEditable(false);
    setItemsToRelease([]);
    setItemsWithDetails(
      itemsWithDetails.map(item => ({ ...item, exeededQty: false }))
    );
  };

  const validateItems = () => {
    const {
      cart: {
        cartDetails: {
          defaultCart: { items: cartItems }
        }
      }
    } = context;

    const itemsWithWarning = itemsWithDetails.map(itemDetails => {
      const itemToRelease = itemsToRelease.find(
        item => item.code === itemDetails.sku
      );
      const cartItem =
        cartItems &&
        cartItems.find(item => item._item[0]._code[0].code === itemDetails.sku);
      const cartQuantity = cartItem ? cartItem.quantity : 0;
      const quantityEntered = itemToRelease ? itemToRelease.quantity : 0;
      const hasError =
        Number(itemDetails.releasedQuantity) >= Number(itemDetails.quantity);

      if (
        !hasError &&
        quantityEntered + cartQuantity + Number(itemDetails.releasedQuantity) >
          Number(itemDetails.quantity)
      ) {
        return {
          ...itemDetails,
          exeededQty: true
        };
      }
      return { ...itemDetails, exeededQty: false };
    });

    setItemsWithDetails(itemsWithWarning);
    return itemsWithWarning.every(item => !item.exeededQty);
  };

  const validateBranch = (branchNumber, pricing) => {
    const {
      branches: { findBranch }
    } = context;
    const branchDetails = findBranch(branchNumber);

    if (branchDetails) {
      // Branch is in the list of entitled branches - pass branch to EP
      return {
        "branch-number": branchNumber,
        "branch-vendor": branchDetails.distirbutor
      };
    }

    // Branch is not in the list of entitled branches
    if (pricing === "Y") {
      // Contract is pricing only - it's not dependent on the branch so we don't have to pass branch to EP
      return {
        "branch-number": "",
        "branch-vendor": ""
      };
    }
    // Contract is not pricing only - it's dependent on the branch which is not entitled so we'll block add to cart
    return null;
  };

  const validateJob = (jobNumber, jobName) => {
    const {
      account: {
        accountDetails: { jobsArray, jobNumberRequired }
      }
    } = context;

    // Account does not have jobs - don't pass job params to EP
    if (!jobNumberRequired && jobsArray && !jobsArray.length) {
      return {};
    }

    const job = jobsArray.find(
      j =>
        j.jobNumber === jobNumber &&
        j.jobName.toUpperCase() === jobName.toUpperCase()
    );

    if (job) {
      // Job is in the list of user's jobs - pass job to EP
      return {
        "job-number": jobNumber,
        "job-name": jobName
      };
    }

    // Job is not in the list of user's jobs - block add to cart
    return null;
  };

  const releaseItemForPickupOrDelivery = (
    shippingMethod: "pickup" | "delivery"
  ) => {
    const {
      cart: {
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage,
        getCartDetails,
        cartDetails: {
          defaultCart: { addItemsToCart, selectedBranch }
        }
      },
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      }
    } = context;

    const {
      orderNumber,
      branchNo,
      jobNumber,
      jobName,
      pricing,
      purchaseOrderNumber
    } = contract;
    if (editable && itemsToRelease.length) {
      setLoadingPickupOrDelivery(shippingMethod);

      const branchParams = validateBranch(branchNo, pricing);
      if (!branchParams) {
        setError(intl.get("contract-error-missing-branch"));
        setLoadingPickupOrDelivery("");
        return;
      }

      const jobParams = validateJob(jobNumber, jobName);
      if (!jobParams) {
        setError(intl.get("contract-error-missing-job"));
        setLoadingPickupOrDelivery("");
        return;
      }

      if (pricing === "N" && !validateItems()) {
        setError(intl.get("exeeded-quantity-error"));
        setLoadingPickupOrDelivery("");
        return;
      }

      setError("");
      const totalQuantity = itemsToRelease.reduce(
        (acc, current) => acc + current.quantity,
        0
      );
      const itemsToBeReleased = itemsToRelease.map(item => {
        const {
          account: {
            accountDetails: { homeBranch }
          }
        } = context;
        const detailedItem = itemsWithDetails.find(
          itemWithDetail => itemWithDetail.sku === item.code
        );

        return {
          ...item,
          "branch-number": updateBranchNumber(
            shippingMethod === "delivery",
            item.code,
            airPurifierBranchNumber,
            homeBranch,
            selectedBranch.code
          ),
          "shipping-method": shippingMethod,
          name: detailedItem.description,
          price: detailedItem.unitPrice
        };
      });
      const body = {
        ...branchParams,
        "job-number": jobNumber,
        "job-name": jobName,
        "contract-number": orderNumber,
        pricing,
        "po-number": purchaseOrderNumber,
        items: itemsToBeReleased
      };

      addToCart(addItemsToCart.self.uri, body)
        .then(() => getCartDetails())
        .then(() => {
          itemsToBeReleased.forEach(item => {
            const numberPrice = Number(item.price);
            productViewed(item.name, item.code, numberPrice, null, null);
            productAdded(
              item.name,
              item.code,
              numberPrice,
              null,
              null,
              item.quantity
            );
          });
          setSuccesCartPopupMessage(totalQuantity);
        })
        .then(() => resetTable())
        .catch(err => {
          setErrorCartPopupMessage(generateSpecificErrorMessage(err));
          resetTable();
        });
    } else {
      setEditable(!editable);
      setItemsWithDetails(
        itemsWithDetails.map(item => ({ ...item, exeededQty: false }))
      );
    }
  };
  const hasSpecialAirPurifiers =
    itemsToRelease &&
    Boolean(itemsToRelease.find(item => isSpecialAirPurifier(item.code)));

  const isLoadingPickup = loadingPickupOrDelivery === "pickup";
  const isLoadingDelivery = loadingPickupOrDelivery === "delivery";

  const isPickupDisabled = hasSpecialAirPurifiers || isLoadingDelivery;

  const releaseButton = (hideButton, accessibleMessage, shippingMethod) => {
    return !hideButton ? (
      <button
        className="dast-btn dast-btn-primary"
        aria-label={!editable ? intl.get("release") : accessibleMessage}
        type="button"
        disabled={isPickupDisabled}
        onClick={() => {
          releaseItemForPickupOrDelivery(shippingMethod);
        }}
      >
        {!editable ? intl.get("release") : accessibleMessage}
      </button>
    ) : (
      <div className="miniLoader" />
    );
  };

  const renderReleaseButtons = () => {
    return (
      <div className="release-buttons-and-bubble-message-wrapper">
        <div className="release-buttons">
          <div
            className={`pickup-delivery-buttons ${
              editable ? "" : "singleButton"
            }`}
          >
            {editable &&
              releaseButton(
                isLoadingPickup,
                intl.get("release-as-pick-up"),
                "pickup"
              )}
            {releaseButton(
              isLoadingDelivery,
              intl.get("release-as-delivery"),
              "delivery"
            )}
          </div>
        </div>
        {error && (
          <MessageContainer
            message={{
              debugMessages: error,
              type: "error"
            }}
            closeContainerHandler={() => setError("")}
          />
        )}
      </div>
    );
  };

  const renderContractItemsTable = () => {
    return (
      <table>
        <thead className="table-labels">
          <tr>
            <th className="label">{intl.get("quantity-abbr")}</th>
            <th className="label">{intl.get("product")}#</th>
            <th className="label">{intl.get("description")}</th>
            <th className="label">{intl.get("price")}</th>
            <th className="label">{editable && intl.get("qty-ordered")}</th>
            <th className="label">{intl.get("qty-released")}</th>
          </tr>
        </thead>
        <tbody>
          {itemsWithDetails ? (
            itemsWithDetails.map(item => (
              <ContractItem
                key={item.sku}
                item={item}
                editable={editable}
                handleQuantityEntered={handleQuantityEntered}
                resetQty={!itemsToRelease.length}
                contract={contract}
              />
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <div className="miniLoader" />{" "}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="contract-items-table content-table content-box">
      <h4>{intl.get("items")}</h4>
      {renderReleaseButtons()}
      {renderContractItemsTable()}
      {itemsWithDetails && !loadedAll && loadMoreButton}
    </div>
  );
};

export default ContractItemsTable;
