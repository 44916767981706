import React, { FC, useEffect, Dispatch, SetStateAction } from "react";

// import "./category.plp.bread.crumbs.less";
import {
  BreadCrumbsComponent,
  navigationCategoryInfoLookup
} from "@zilker/store-components";
import { cortexFetchNavigationLookupForm } from "../utils/CortexLookup";

interface urlParamInterface {
  param: string;
  name: string;
  state?: boolean;
  start?: number;
  sku?: string;
  facets?: any;
  itemsLoaded?: number;
  sortByAvailability?: boolean;
}

interface Props {
  query: string;
  isCategoryPage: boolean;
  breadCrumbsMap: urlParamInterface[];
  setBreadCrumbsMap: Dispatch<SetStateAction<any[]>>;
  setPageTitle: Dispatch<SetStateAction<string>>;
}

const CategoryPLPBreadCrumbs: FC<Props> = ({
  query,
  isCategoryPage,
  breadCrumbsMap,
  setBreadCrumbsMap,
  setPageTitle
}) => {
  const pageType = "categoryPLP";
  useEffect(() => {
    const pageTitleExists = !!query;
    if (pageTitleExists && isCategoryPage) {
      cortexFetchNavigationLookupForm().then(() => {
        // call new cortexApi endpoint to get info, save to breadcrumbs map
        navigationCategoryInfoLookup(query, pageType).then((res: any) => {
          const displayName = res["display-name"];
          const parentName = res._parent[0]["display-name"];
          const parentInternalName = res._parent[0].name;
          const grandparentName = res._parent[0]._parent[0]["display-name"];
          setPageTitle(displayName);
          setBreadCrumbsMap([
            { param: "home", name: "Home" },
            { param: "nocrumb", name: grandparentName },
            {
              param: `categorylanding/${parentInternalName}`,
              name: parentName
            },
            {
              param: "nocrumb",
              name: displayName
            }
          ]);
        });
      });
    }
  }, [query]);

  return <BreadCrumbsComponent breadCrumbsMap={breadCrumbsMap} />;
};

export default CategoryPLPBreadCrumbs;
