import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";

import { getConfig } from "../utils/ConfigProvider";
import { ReactComponent as DropdownOpenIcon } from "../../../app/src/images/icons/dropdown-open-icon.svg";
import { ReactComponent as DropdownCloseIcon } from "../../../app/src/images/icons/dropdown-close-icon.svg";

import "./ProductDetailTabsComponent.less";

interface ProductDetailTabsComponentProps {
  productData: any;
  productDocuments: Array<{ assetType: string; url: string }>;
}

const Tabs = {
  SPECIFICATIONS: "specifications",
  WARRANTY: "warranty",
  GENERAL: "general",
  DOCUMENTS: "documents"
};

const ProductDetailTabsComponent = React.forwardRef<
  HTMLDivElement,
  ProductDetailTabsComponentProps
>((props, ref) => {
  const [specificationsTab, setSpecificationsTab] = useState<boolean>(false);
  const [warrantyTab, setWarrantyTab] = useState<boolean>(false);
  const [generalTab, setGeneralTab] = useState<boolean>(false);
  const [documentsTab, setDocumentsTab] = useState<boolean>(false);
  const { productData, productDocuments } = props;

  const { config } = getConfig();

  const attributesNoOutput = [
    "full_image",
    "long_description",
    "url",
    "warranty",
    "product_features",
    "short_description",
    "thumb_image",
    "branches_available",
    "vendor_number",
    "dimensional_drawing",
    "parts_manual",
    "service_manual",
    "specification_sheet",
    "warranty_certificate",
    "wire_diagram",
    "product_brochure",
    "accessories",
    "submittal_data_sheet",
    "erp_alias"
  ];

  const splitString = givenString => {
    const sentinels = ["\\|", "|", ";", "\\n", "\n"];
    const splitNumberedListRegex = /\d{1,}\.\s/;
    const findAllSemicolonsRegex = /;/g;
    const sentinelRes = sentinels.find(
      sentinel => givenString.split(sentinel).length > 1
    );
    let resultString = givenString;
    const numberedListLength =
      givenString.split(splitNumberedListRegex).length - 1;
    const delimitedListLength = givenString.split(sentinelRes).length;
    if (
      (sentinelRes === ";" || sentinelRes === "|") &&
      numberedListLength !== delimitedListLength
    ) {
      resultString = `* ${givenString}`;
      if (sentinelRes === ";") {
        resultString = resultString.replace(findAllSemicolonsRegex, ";* ");
      }
    }
    return resultString.split(sentinelRes);
  };

  /**
   * ## renderAttributes
   * @description Renders list of Product details under the "General" tab in the PDP.
   */

  const renderAttributes = () => {
    if (productData._definition[0].details) {
      return productData._definition[0].details
        .filter(attribute => {
          return (
            !attributesNoOutput.includes(attribute.name) &&
            attribute["display-value"] &&
            attribute["display-value"] !== ""
          );
        })
        .map(attribute => (
          <ul className="itemdetail-attribute" key={attribute.name}>
            <li className="itemdetail-attribute-label-col">
              {`${attribute["display-name"]}: `}
            </li>
            <li className="itemdetail-attribute-value-col">
              {attribute["display-value"].startsWith("http") ||
              attribute["display-value"].startsWith("https") ? (
                <a
                  href={attribute["display-value"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attribute["display-value"]}
                </a>
              ) : (
                <ul key={attribute.name}>
                  {splitString(attribute["display-value"]).map(feature => (
                    <li key={`attribute-${Math.random()}`}>{feature}</li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        ));
    }
    return <p>{intl.get("no-information-label")}</p>;
  };

  const renderDocuments = () => {
    if (productDocuments) {
      return (
        <div className="product-documents">
          {productDocuments.map(({ assetType, url }) => (
            <a
              className="product-document"
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              key={url}
            >
              <h3>{assetType}</h3>
            </a>
          ))}
        </div>
      );
    }
    return <p>{intl.get("no-information-label")}</p>;
  };

  /**
   * ## showTab
   *
   * @param e - event object
   *
   * @description Toggles selected Product details tab.
   */
  const showTab = e => {
    let name;
    if (e.target.tagName === "BUTTON") {
      // eslint-disable-next-line prefer-destructuring
      name = e.target.name;
    } else {
      // eslint-disable-next-line prefer-destructuring
      name = e.target.parentElement.name;
    }

    switch (name) {
      case Tabs.SPECIFICATIONS:
        setSpecificationsTab(!specificationsTab);
        break;
      case Tabs.WARRANTY:
        setWarrantyTab(!warrantyTab);
        break;
      case Tabs.GENERAL:
        setGeneralTab(!generalTab);
        break;
      case Tabs.DOCUMENTS:
        setDocumentsTab(!documentsTab);
        break;
      default:
    }
  };

  /**
   * ## renderAccordion
   *
   * @description Renders Product details accordion.
   */
  const renderAccordion = () => {
    if (!productData._definition[0].details) {
      return null;
    }
    const warranty = productData._definition[0].details.find(
      item => item.name === "warranty"
    );
    const features = productData._definition[0].details.find(
      item => item.name === "product_features"
    );
    const featureList = features ? splitString(features["display-value"]) : [];
    const warrantyList = warranty ? splitString(warranty["display-value"]) : [];

    return (
      <>
        <div className="accordion">
          <ul className="nav nav-accordion">
            <li className="nav-item">
              <button
                type="button"
                className="nav-link"
                name="specifications"
                onClick={showTab}
              >
                <h4>{intl.get("specifications")}</h4>
                {specificationsTab ? (
                  <DropdownCloseIcon />
                ) : (
                  <DropdownOpenIcon />
                )}
              </button>
              {specificationsTab ? (
                <div className="tab-content">
                  {featureList.length > 0 ? (
                    <ul className="product-features-list">
                      {featureList.map(item => {
                        if (item.startsWith("*")) {
                          return (
                            <li key={`_${Math.random()}`}>
                              <i className="icon-chevron-right" />
                              {item.replace("*", "")}
                            </li>
                          );
                        }
                        return <li key={`_${Math.random()}`}>{item}</li>;
                      })}
                    </ul>
                  ) : (
                    <p>{intl.get("no-information-label")}</p>
                  )}
                </div>
              ) : null}
            </li>
            <li className="nav-item">
              <button
                type="button"
                className="nav-link"
                name="warranty"
                onClick={showTab}
              >
                <h4>{intl.get("warranty")}</h4>
                {warrantyTab ? <DropdownCloseIcon /> : <DropdownOpenIcon />}
              </button>
              {warrantyTab ? (
                <div className="tab-content">
                  {warrantyList.length > 0 ? (
                    <ul className="product-features-list">
                      {warrantyList.map(item => {
                        if (item.startsWith("*")) {
                          return (
                            <li key={`_${Math.random()}`}>
                              <i className="icon-chevron-right" />
                              {item.replace("*", "")}
                            </li>
                          );
                        }
                        return <li key={`_${Math.random()}`}>{item}</li>;
                      })}
                    </ul>
                  ) : (
                    <p>{intl.get("no-information-label")}</p>
                  )}
                </div>
              ) : null}
            </li>
            <li className="nav-item">
              <button
                type="button"
                name="general"
                className="nav-link"
                onClick={showTab}
              >
                <h4>{intl.get("general")}</h4>
                {generalTab ? <DropdownCloseIcon /> : <DropdownOpenIcon />}
              </button>
              {generalTab ? (
                <div className="tab-content">
                  <div className="itemdetail-details">{renderAttributes()}</div>
                </div>
              ) : null}
            </li>
            {config.showDocumentsTab &&
            productDocuments &&
            productDocuments.length ? (
              <li className="nav-item">
                <button
                  type="button"
                  className="nav-link"
                  name="documents"
                  onClick={showTab}
                >
                  <h4>{intl.get("documents")}</h4>
                  {documentsTab ? <DropdownCloseIcon /> : <DropdownOpenIcon />}
                </button>
                {documentsTab ? (
                  <div className="tab-content">{renderDocuments()}</div>
                ) : null}
              </li>
            ) : null}
          </ul>
        </div>
      </>
    );
  };

  return <div ref={ref}>{renderAccordion()}</div>;
});

export default ProductDetailTabsComponent;
