import React, { useContext } from "react";
import {
  BrProps,
  BrManageMenuButton,
  BrComponentContext,
  BrPageContext
} from "@bloomreach/react-sdk";
import {
  Menu as BrMenu,
  Reference,
  isMenu,
  MenuItem,
  TYPE_LINK_EXTERNAL
} from "@bloomreach/spa-sdk";

const MenuEditButton = () => {
  const component = useContext(BrComponentContext);
  const page = useContext(BrPageContext);
  const menuRef = component.getModels().menu;
  const menu = menuRef && page.getContent<BrMenu>(menuRef);

  if (!isMenu(menu)) {
    return null;
  }

  return (
    <div className={`${page.isPreview() ? "has-edit-button" : ""}`}>
      <BrManageMenuButton menu={menu} />
    </div>
  );
};

export default MenuEditButton;
