/* eslint-disable no-param-reassign */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { FC, useEffect, useState, useRef } from "react";
import intl from "react-intl-universal";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import {
  PurchaseDetailsMain,
  OrderTableMain,
  orderCompleted,
  page,
  getConfig
} from "@zilker/store-components";
import { useMainContext } from "../contexts/MainContext";
import {
  getAvailability,
  getMincronOrdersIds
} from "../services/connectGQLservices";

import "./PurchaseReceiptPage.less";
import {
  checkTokensExpired,
  formatQuoteAvailability,
  pushToMaintenace
} from "../utils/helpers";

const PurchaseReceiptPage: FC<RouteComponentProps> = ({ history }) => {
  const context = useMainContext();
  const {
    auth,
    order: {
      orderData,
      purchaseData,
      branch,
      job,
      poNumber,
      promoCodes,
      setPromoCodes,
      contractNumber
    },
    user,
    account: {
      accountDetails: { customerNumber }
    }
  } = context;

  const { config } = getConfig();
  const { scope } = config.cortexApi;
  const { useEPOrderNumber } = config;

  const [mincronOrderResponse, setMincronOrderResponse] = useState([]);
  const [itemAvailability, setitemAvailability] = useState([]);

  const timer = useRef<any>(null);

  const handleReorderAllProducts = () => {
    history.push("/mycart");
  };

  const itemDetailLink = "/itemdetail";

  const checkItemAvailability = () => {
    const {
      auth: { logout },
      cart: {
        cartDetails: { defaultCart }
      }
    } = context;
    const skus =
      defaultCart && defaultCart.items
        ? defaultCart.items.map(item => item._item[0]._code[0].code)
        : [];
    if (defaultCart) {
      const { selectedBranch } = defaultCart;

      if (customerNumber && selectedBranch && skus) {
        const branchNumber = selectedBranch.code;
        getAvailability(skus, customerNumber, branchNumber)
          .then(res => {
            if (
              res &&
              res.data &&
              res.data.data &&
              res.data.data.inventory &&
              res.data.data.inventory.entitledInventory
            ) {
              const data = res.data.data.inventory.entitledInventory;
              const availability = formatQuoteAvailability(
                data.branches,
                data.regionRollups,
                skus
              );
              setitemAvailability(availability);
            }
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn:
                    "Logout => checkItemsAvailability => OrderReviewPage.tsx"
                })
              );
            }
          });
      }
    }
  };

  useEffect(() => {
    if (orderData && !itemAvailability) {
      checkItemAvailability();
    }
  }, [orderData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (orderData) {
      const orderUri = orderData._order[0].self.uri;
      const orderId = orderUri.slice(
        orderUri.lastIndexOf(`${scope}/`) + `${scope}/`.length
      );

      const total = orderData._order[0]._total[0].cost[0].amount;
      const tax = orderData._order[0]._tax[0].total.amount;
      const { currency } = orderData._order[0]._total[0].cost[0];

      const products = orderData._lineitems[0]._element.map(product => {
        let productPrice = 0;
        if (
          config.calculatePrice &&
          product &&
          product._price &&
          product._price[0] &&
          product._price[0]["purchase-price"] &&
          product._price[0]["purchase-price"][0] &&
          product._price[0]["purchase-price"][0].amount
        ) {
          productPrice = product._price[0]["purchase-price"][0].amount;
        }

        return {
          product_id: product._item[0]._code[0].code,
          sku: product._item[0]._code[0].code,
          name: product._item[0]._definition[0]["display-name"],
          price: productPrice,
          quantity: product.quantity
        };
      });
      // sends information to Segment when user completes the order
      orderCompleted(orderId, total, tax, currency, products);
    }
    checkItemAvailability();
    page();
    return () => {
      setPromoCodes(null);
    };
  }, []);

  useEffect(() => {
    if (purchaseData && !mincronOrderResponse.length && !useEPOrderNumber) {
      pullOrderNumFromMincron(purchaseData["purchase-number"], 0);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  function pullOrderNumFromMincron(epOrderId: string, limit: number): void {
    if (mincronOrderResponse.length || limit === 25) {
      return;
    }
    getMincronOrdersIds(epOrderId)
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.erp &&
          res.data.data.erp.orderSubmissionStatus
        ) {
          if (!res.data.data.erp.orderSubmissionStatus.length) {
            limit += 1;
            timer.current = setTimeout(() => {
              pullOrderNumFromMincron(epOrderId, limit);
            }, 4000);
          } else if (res.data.data.erp.orderSubmissionStatus.length) {
            setMincronOrderResponse(res.data.data.erp.orderSubmissionStatus);
          } else {
            console.error(
              intl.get("order-log-failure-message", {
                status: "ERROR"
              })
            );
          }
        } else {
          limit += 1;
          timer.current = setTimeout(() => {
            pullOrderNumFromMincron(epOrderId, limit);
          }, 4000);
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          auth.logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getMincronOrdersIds=> PurchaseReceiptPage.tsx"
            })
          );
        } else {
          console.error(e);
        }
      });
  }

  if (!auth.isLoggedIn || !orderData || !purchaseData)
    return <Redirect to={{ pathname: "/" }} />;

  return (
    <div className="app-main">
      <>
        <div
          className="purchase-receipt-container"
          id="purchaseReceiptContainer"
        >
          <PurchaseDetailsMain
            data={{
              ...purchaseData,
              branch,
              job,
              order: orderData._order,
              poNumber,
              promoCodes,
              contractNumber
            }}
            onReorderAllProducts={handleReorderAllProducts}
            itemDetailLink={itemDetailLink}
            history={history}
            auth={auth}
            user={user}
          />
        </div>
        <div className="order-items-container container">
          <div className="order-items-list">
            <OrderTableMain
              data={orderData}
              itemDetailLink={itemDetailLink}
              mincronOrderNums={mincronOrderResponse}
              itemAvailability={itemAvailability}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default withRouter(PurchaseReceiptPage);
