/* eslint-disable camelcase */
import React, { FC, useContext, useState } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { Messagecontainer } from "@zilker/store-components";
import {
  formatPickupOrDeliveryAvailabilityLabel,
  isSpecialAirPurifier,
  renderSpecialAirPurifierLabel
} from "@elasticpath/ref-store/src/utils/helpers";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { getConfig } from "../utils/ConfigProvider";
import AppHeaderLoginMain from "../AppHeaderLogin/appheaderlogin.main";
import AlternateBranchList from "../AlternateBranchList/AlternateBranchList";

import "./search.results.item.less";

const appHeaderLoginLinks = {
  profile: "/myAccount/profile",
  wishlists: "/wishlists"
};

const appModalLoginLinks = {
  registration: "/registration"
};

interface SearchResultsItemProps extends RouteComponentProps {
  history: any;
  item: any;
  onAddToCart: (any, shippingMethod: "pickup" | "delivery", item) => any;
  titleRef?: any;
  clickedButtonLoading?: boolean;
  start?: number;
  facets?: any;
  itemsLoaded?: number;
  sortByAvailability?: boolean;
  onClick?: (pid: any) => void;
}

/**
 * ## renderProductPrice
 *
 * @param price any
 * @param isLoggedIn boolean
 *
 * @description Renders product price or displays mini loader.
 * If user is not authenticated, the price won't be displayed.
 */
const renderProductPrice = (
  price: any,
  isLoggedIn: boolean,
  entitled: boolean,
  isEcommerceUser: boolean
) => {
  const { config } = getConfig();
  if (config.calculatePrice) {
    if (
      isLoggedIn &&
      (price === 0 ||
        ((price && price.toString() === intl.get("pending")) || !entitled) ||
        !isEcommerceUser)
    ) {
      return <div className="item-price">{intl.get("pending")}</div>;
    }

    if (isLoggedIn && price) {
      return <div className="item-price">${price}</div>;
    }

    if (isLoggedIn && !price) {
      return (
        <div className="pdp-loader-wrapper">
          <div className="miniLoader" />
        </div>
      );
    }
  } else {
    return "";
  }

  return null;
};

const SearchResultsItem: FC<SearchResultsItemProps> = ({
  item,
  onAddToCart,
  titleRef = undefined,
  clickedButtonLoading,
  start,
  facets,
  history,
  itemsLoaded,
  sortByAvailability,
  onClick
}) => {
  const context = useContext<{
    auth: any;
    account: any;
    cart: any;
    user: any;
    branches: any;
  }>(MainContext);

  const prevUrl = window.location.href;
  const { config } = getConfig();

  const imgMissingHorizontal = config.missingImagePlaceholderUrl;

  const {
    auth: { isLoggedIn },
    branches: { branchesList, findBranch },
    cart: {
      cartDetails: { defaultCart }
    },
    user: {
      userProfile: { ecommerceBuyer }
    },
    account: {
      accountDetails: { customerRoles }
    }
  } = context;

  const activeBranch =
    defaultCart && findBranch(defaultCart.selectedBranch.code)
      ? findBranch(defaultCart.selectedBranch.code).branchName
      : "";

  const [openAlternateBranchModal, setOpenAlternateBranchModal] = useState<
    boolean
  >(false);
  const [
    clickedPickupOrDeliveryButton,
    setClickedPickupOrDeliveryButton
  ] = useState<string>("");
  const onItemClick = () => {
    if (titleRef) {
      window.scrollTo(
        0,
        titleRef.current.scrollIntoView({ behavior: "smooth" })
      );
    }

    if (onClick) onClick(item);
  };

  let imageUrl;
  try {
    imageUrl = item.full_image || imgMissingHorizontal;
  } catch (error) {
    imageUrl = imgMissingHorizontal;
  }

  const isPriceZero = item && `${item.productPrice}` === "0";

  const isCallButtonActive =
    (config.calculatePrice && isPriceZero) ||
    !item.entitled ||
    (config.checkAvailability &&
      !item.branchAvailability &&
      !item.regionAvailability);

  const shouldBtnBeDisabled =
    !item.productPrice || isPriceZero || !item.entitled || !ecommerceBuyer;

  const openAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(true);
  };

  const closeAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(false);
  };

  const renderAlternateBranchesModal = () => {
    const productSkuCode = item.pid;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={openAlternateBranchModal}
        onClose={closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            product={{
              name: item.title,
              sku: productSkuCode,
              price: item.productPrice,
              brand: item.brand
            }}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branchesList}
            itemQty={1}
          />
        </div>
      </Modal>
    );
  };

  /** Various parameters from PLP are passed to PDP and Breadcrumbs components, using location state object.
   * The purpose is to persist those values between the pages - when the user navigates PLP => PDP => PLP,
   * those parameters are saved - start, facets, name, itemsLoaded (number of items loaded on PLP),
   * sortByAvailability (is the sort checkbox selected).
   */
  const navLinkRouteParams = {
    pathname: `/itemdetail/${item.pid}`,
    state: {
      searchResultParams: {
        start,
        facets,
        name: item.title,
        itemsLoaded,
        sortByAvailability,
        prevUrl
      }
    }
  };

  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const handleAddingProductToCart = event => {
    if (item.branchAvailability === 0 && item.regionAvailability > 0) {
      return openAlternateBranchesModal();
    }
    return onAddToCart(event, "pickup", item);
  };

  const pickupButtonIsLoading =
    clickedButtonLoading && clickedPickupOrDeliveryButton === "pickup";
  const deliveryButtonIsLoading =
    clickedButtonLoading && clickedPickupOrDeliveryButton === "delivery";
  const setTypeOfClickedButon = (buttonType: "pickup" | "delivery") =>
    setClickedPickupOrDeliveryButton(buttonType);

  const isRestrictedMaterial =
    item.restricted_materials && item.restricted_materials[0] === "Yes";

  return (
    <>
      {renderAlternateBranchesModal()}
      <div
        key={item.pid}
        className="search-result-item dast-product-card"
        id={item.pid}
      >
        <div className="search-result-item-container">
          <div className="item-image-container">
            <NavLink
              to={navLinkRouteParams}
              onClick={onItemClick}
              tabIndex={-1}
            >
              <img
                alt={item.title}
                src={imageUrl}
                onError={e => {
                  const element: any = e.target;
                  element.src = imgMissingHorizontal;
                }}
              />
            </NavLink>
          </div>
          <div className="item-info-container">
            <div className="item-title" title={item.title}>
              <NavLink
                to={navLinkRouteParams}
                onClick={onItemClick}
                tabIndex={-1}
              >
                {item.title}
              </NavLink>
            </div>
            <div className="item-alignment-wrapper">
              <div className="item-sku">
                <span>{intl.get("product")}: </span>
                <NavLink
                  to={navLinkRouteParams}
                  tabIndex={-1}
                  onClick={onItemClick}
                >
                  <span className="product-sku">{item.pid}</span>
                </NavLink>
              </div>
              {/* TODO: should we localize prices */}
              <div className="container-price-popup">
                {renderProductPrice(
                  item.productPrice,
                  isLoggedIn,
                  item.entitled,
                  ecommerceBuyer
                )}
              </div>
              {isRestrictedMaterial ? (
                <div className="item-warning-container">
                  <Messagecontainer
                    message={{
                      type: "danger-message",
                      debugMessages: intl.get("certificate-required")
                    }}
                    stayOpen
                    closeContainerHandler={null}
                  />
                </div>
              ) : null}
              {isLoggedIn ? (
                <>
                  <div className="item-availability-and-button">
                    <div className="item-availability">
                      {// eslint-disable-next-line no-nested-ternary
                      shouldBtnBeDisabled ? (
                        <div className="label-availability">
                          <span className="bold-text">
                            {!ecommerceBuyer
                              ? intl.get("contact-admin")
                              : intl.get("stocking-or-entitlement-issue")}
                          </span>
                          <span>
                            {!ecommerceBuyer
                              ? intl.get("to-place-online-orders")
                              : intl.get("call-branch-to-order")}
                          </span>
                        </div>
                      ) : isSpecialAirPurifier(item.pid) ? (
                        renderSpecialAirPurifierLabel(item.pid)
                      ) : (
                        formatPickupOrDeliveryAvailabilityLabel({
                          branchAvailability: item.branchAvailability,
                          regionAvailability: item.regionAvailability,
                          currentBranch: activeBranch,
                          isSingleLinedLabel: false,
                          isVirtualBranchesUser: isVirtualBranchUser,
                          callBranchToOrderButtonIsActive: isCallButtonActive,
                          onLabelClick: openAlternateBranchesModal,
                          buttonIsLoading: clickedButtonLoading,
                          dcAvailability: item.dcAvailability
                        })
                      )}
                    </div>
                    {pickupButtonIsLoading ? (
                      <div className="miniLoader" />
                    ) : (
                      <div className="item-button">
                        <button
                          tabIndex={-1}
                          type="button"
                          className="dast-btn dast-btn-tertiary"
                          onClick={event => {
                            handleAddingProductToCart(event);
                            setTypeOfClickedButon("pickup");
                          }}
                          id={item.pid}
                          disabled={
                            shouldBtnBeDisabled ||
                            deliveryButtonIsLoading ||
                            isSpecialAirPurifier(item.pid)
                          }
                        >
                          {intl.get("pick-up-method")}
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="item-availability-and-button">
                    <div className="item-availability">
                      <div className="label-availability">
                        {shouldBtnBeDisabled ? (
                          <>
                            <span className="bold-text">
                              {!ecommerceBuyer
                                ? intl.get("contact-admin")
                                : intl.get("stocking-or-entitlement-issue")}
                            </span>
                            <span>
                              {!ecommerceBuyer
                                ? intl.get("to-place-online-orders")
                                : intl.get("call-branch-to-order")}
                            </span>
                          </>
                        ) : (
                          formatPickupOrDeliveryAvailabilityLabel({
                            branchAvailability: item.branchAvailability,
                            regionAvailability: item.regionAvailability,
                            currentBranch: activeBranch,
                            isSingleLinedLabel: false,
                            isVirtualBranchesUser: isVirtualBranchUser,
                            callBranchToOrderButtonIsActive: isCallButtonActive,
                            buttonIsLoading: clickedButtonLoading,
                            isDelivery: true
                          })
                        )}
                      </div>
                    </div>
                    {deliveryButtonIsLoading ? (
                      <div className="miniLoader" />
                    ) : (
                      <div className="item-button">
                        <button
                          tabIndex={-1}
                          type="button"
                          className="dast-btn dast-btn-tertiary"
                          onClick={event => {
                            setTypeOfClickedButon("delivery");
                            onAddToCart(event, "delivery", item);
                          }}
                          id={item.pid}
                          disabled={
                            shouldBtnBeDisabled || pickupButtonIsLoading
                          }
                        >
                          {intl.get("ship-to-me")}
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="price-availability-placeholder-logged-out dast-btn dast-btn-tertiary">
                  <AppHeaderLoginMain
                    history={history}
                    permission
                    appHeaderLoginLinks={appHeaderLoginLinks}
                    appModalLoginLinks={appModalLoginLinks}
                    isLoggedIn={false}
                    descriptiveButtonText={intl.get("login-message")}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SearchResultsItem);
