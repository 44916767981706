import React, { FC } from "react";
import { Link } from "react-router-dom";
import { isLinkExternal } from "@elasticpath/ref-store/src/utils/helpers";
import HomePageCard from "../HomePageCard/home-page-card.main";

import "./training-card.less";

interface TrainingCardProps {
  header: string;
  buttonLink: string;
  buttonText: string;
  time?: string;
  trainingType?: string;
}

const TrainingCard: FC<TrainingCardProps> = ({
  header,
  buttonLink,
  buttonText,
  time,
  trainingType
}) => {
  const [isExternal, url] = isLinkExternal(buttonLink);
  let trainingClass = "training-type";
  switch (trainingType) {
    case "Business Training":
      trainingClass = "training-type business-training";
      break;
    case "Sales Training":
      trainingClass = "training-type sales-training";
      break;
    case "Technical Training":
      trainingClass = "training-type technical-training";
      break;
    default:
      break;
  }

  return (
    <div className="training-card">
      <h3 className="training-card-header">{header}</h3>
      <p className="training-card-time" aria-label={time ? "" : "skip"}>
        {time}
      </p>
      <Link
        to={isExternal ? { pathname: url } : url}
        target={!isExternal ? "_self" : "_blank"}
        rel={isExternal ? "noopener noreferrer" : ""}
      >
        <button type="button" className="training-card-button">
          {buttonText}
        </button>
      </Link>
      <div className={trainingClass}>
        <p>{trainingType}</p>
      </div>
    </div>
  );
};

export default TrainingCard;
