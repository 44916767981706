import React, { FC, useState } from "react";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import { SearchResultsMenu } from "@zilker/store-components";
import useWindowSize from "../CustomHooks/useWindowSize";
import { ReactComponent as FilterIcon } from "../../../app/src/images/icons/filter-icon.svg";

import "./searchFilters.less";

interface SearchFiltersProps {
  facets: any;
  filtersChecked: any;
  onFacetClicked: (filter: string) => any;
  checked: boolean;
  handler: (event: any) => void;
  selected?: string;
  setSelected?: any;
  openedFacetFields?: any[];
  toggleOpenedFacetFields?: (facetName: string) => any;
}

const SearchFilters: FC<SearchFiltersProps> = ({
  facets,
  filtersChecked,
  onFacetClicked,
  checked,
  handler,
  selected,
  setSelected,
  openedFacetFields,
  toggleOpenedFacetFields
}) => {
  const [isOpened, setIsOpen] = useState<boolean>(false);
  const [width, height] = useWindowSize();

  // Values obtained from variables.less media query values
  const desktopMin = 1024;

  const toggleMenu = (): void => {
    setIsOpen(!isOpened);
  };
  return (
    <>
      {width < desktopMin ? (
        <>
          <button
            type="button"
            className="search-filters"
            onClick={toggleMenu}
            onKeyDown={toggleMenu}
          >
            {intl.get("filter")}
            <FilterIcon className="filter-icon" />
          </button>
          {isOpened && (
            <Modal
              open={isOpened}
              onClose={() => setIsOpen(false)}
              classNames={{
                overlay: "search-results-modal-layout",
                modal: "search-results-modal"
              }}
            >
              <SearchResultsMenu
                facets={facets}
                filtersChecked={filtersChecked}
                onFacetClicked={onFacetClicked}
                checked={checked}
                handler={handler}
                selected={selected}
                setSelected={setSelected}
                openedFacetFields={openedFacetFields}
                toggleOpenedFacetFields={toggleOpenedFacetFields}
              />
            </Modal>
          )}
        </>
      ) : (
        <SearchResultsMenu
          facets={facets}
          filtersChecked={filtersChecked}
          onFacetClicked={onFacetClicked}
          checked={checked}
          handler={handler}
          selected={selected}
          setSelected={setSelected}
          openedFacetFields={openedFacetFields}
          toggleOpenedFacetFields={toggleOpenedFacetFields}
        />
      )}
    </>
  );
};

export default SearchFilters;
