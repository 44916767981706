import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { Document } from "@bloomreach/spa-sdk";

import "./rich-text.less";

interface RichTextProps extends BrProps {
  content?: string;
}

const RichText = ({ content, component, page }: RichTextProps) => {
  if (content) {
    return (
      <div
        className="rich-text-container container"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }

  if (!component) {
    return null;
  }

  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);
  if (document) {
    const { content: docContent } = document.getData();
    return (
      <div
        className="rich-text-container container"
        dangerouslySetInnerHTML={{
          __html: page.rewriteLinks(docContent.value)
        }}
      />
    );
  }
  return null;
};

export default RichText;
