/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { FC, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import intl from "react-intl-universal";
import queryString from "query-string";
import {
  loginCortexRegisteredAuthService,
  getConfig
} from "@zilker/store-components";
import { discoverOIDCParameters } from "@zilker/store-components/src/utils/AuthService";
import { useMainContext } from "../contexts/MainContext";
import { pushToMaintenace } from "../utils/helpers";
import { processLoggedInUser, isLoggedIn } from "../services/LoginService";

import "./LoginRedirectPage.less";

interface OidcParameters {
  clientId: string;
  scopes: string;
  authorizationEndpoint: string;
  endSessionEndpoint: string;
}

export const LoginRedirectPage: FC<RouteComponentProps> = props => {
  const { config } = getConfig();
  const accountSharedId = localStorage.getItem(
    `${config.cortexApi.scope}_AccountSharedId`
  );

  const mainContext = useMainContext();

  const {
    auth: { setTempSsoTokens, logout },
    modal: { setShowSsoModal },
    account: {
      accountDetails: { membership, isInitialData }
    },
    user: {
      userProfile: { ecommerceUser }
    },
    cart: { cartDataFromAuth }
  } = mainContext;

  const { history } = props;

  useEffect(() => {
    const locationData = window.location.search;
    const url = locationData;
    const params = queryString.parse(url);
    if (
      config.b2b.openId &&
      config.b2b.openId.enable &&
      params.code &&
      params.session_state
    ) {
      localStorage.setItem(
        `${config.cortexApi.scope}_openIdcCode`,
        params.code
      );
      localStorage.setItem(
        `${config.cortexApi.scope}_openIdcSessionState`,
        params.session_state
      );
      localStorage.removeItem("OidcSecret");
    }

    // generate unique, random values of 13 digits to enable BR click-tracking
    localStorage.setItem(
      "request_id",
      `${Math.floor(Math.random() * 10000000000000)}`
    );

    discoverOIDCParameters().then((oidcParameters: OidcParameters) => {
      const redirectUri =
        config.b2b.openId && config.b2b.openId.enable
          ? `${config.b2b.openId.callbackUrl}/loggedin`
          : config.b2b.keycloak.callbackUrl;

      const clientId = encodeURIComponent(
        config.b2b.openId && config.b2b.openId.enable
          ? oidcParameters.clientId
          : config.b2b.keycloak.client_id
      );
      loginCortexRegisteredAuthService(params.code, redirectUri)
        .then(res => res.json())
        .then(response => {
          if (response.messages && response.messages[0]) {
            const ssoError = {
              errorMsgId: response.messages[0]["debug-message"],
              admins:
                response.messages[0].data &&
                response.messages[0].data.adminEmails,
              firstName:
                response.messages[0].data &&
                response.messages[0].data.firstName,
              lastName:
                response.messages[0].data && response.messages[0].data.lastName,
              phoneNumber:
                response.messages[0].data &&
                response.messages[0].data.phoneNumber,
              email:
                response.messages[0].data && response.messages[0].data.email,
              companyAffiliation:
                response.messages[0].data &&
                response.messages[0].data.companyAffiliation
            };

            localStorage.setItem("sso_error", JSON.stringify(ssoError));

            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => loginCortexRegisteredAuthService => LoginRedirectPage.tsx"
              })
            );
            console.error(response.messages[0]["debug-message"]);
          }
          const tempTokens = {
            _oAuthRole: "REGISTERED",
            _oAuthScope: config.cortexApi.scope,
            _oAuthToken: `Bearer ${response["access-token"]}`,
            _oAuthUserName: clientId,
            _oAuthTokenTTL: response["expires-in"]
          };
          setTempSsoTokens(prevState => {
            return { ...prevState, ...tempTokens };
          });

          localStorage.setItem(
            `${config.cortexApi.scope}_oAuthToken`,
            `Bearer ${response["access-token"]}`
          );

          localStorage.setItem(
            `${config.cortexApi.scope}_oAuthTokenTTL`,
            tempTokens._oAuthTokenTTL
          );

          localStorage.setItem(
            `${config.cortexApi.scope}_oAuthRole`,
            tempTokens._oAuthRole
          );
          localStorage.setItem(
            `${config.cortexApi.scope}_oAuthScope`,
            tempTokens._oAuthScope
          );
          localStorage.setItem(
            `${config.cortexApi.scope}_oAuthUserName`,
            tempTokens._oAuthUserName
          );
          const pageRoute = localStorage.getItem("tempPageRoute");
          localStorage.setItem("pageRoute", pageRoute || "/");
          processLoggedInUser(history, mainContext);
          setShowSsoModal(true);
          localStorage.setItem("showSsoModal", "true");
        })
        .catch(e => {
          if (e.messages && e.messages[0]) {
            const ssoError = {
              errorMsgId: e.messages[0]["debug-message"],
              admins: e.messages[0].data && e.messages[0].data.adminEmails
            };

            localStorage.setItem("sso_error", JSON.stringify(ssoError));

            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => loginCortexRegisteredAuthService => LoginRedirectPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "loginCortexRegisteredAuthService => LoginRedirectPage.tsx"
            });
          }
          console.error(e);
        });
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      if (!isInitialData) {
        if (membership && membership.length > 0) {
          if (localStorage.pageRoute) {
            history.push(localStorage.pageRoute);
          } else {
            history.push("/");
          }
        } else if (!ecommerceUser && accountSharedId) {
          history.push("/");
        }
      } else if (
        isInitialData &&
        cartDataFromAuth &&
        cartDataFromAuth.length > 1
      ) {
        history.push("/");
      }
    }
  }, [membership, accountSharedId, cartDataFromAuth]);

  return (
    <div>
      <h3 className="loader-heading">{intl.get("please-wait-logging-in")}</h3>
      <div className="loader" />
    </div>
  );
};

export default withRouter(LoginRedirectPage);
