import React, { FC, useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import intl from "react-intl-universal";

import {
  addToCart,
  updateAhriSystemGroupItems
} from "@elasticpath/ref-store/src/services/EpServices";
import { getAvailability } from "@elasticpath/ref-store/src/services/connectGQLservices";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { cortexFetch, getConfig, productAdded } from "@zilker/store-components";
import {
  extractPhoneAndFax,
  handleCustomException,
  formatPickupOrDeliveryAvailabilityLabel,
  generateSpecificErrorMessage,
  pushToMaintenace,
  checkTokensExpired,
  checkResponse
} from "@elasticpath/ref-store/src/utils/helpers";

import "../../../app/src/theme/sharedClasses.less";
import "./AlternateBranchList.less";

interface BranchListProps {
  orderInfo?: {
    orderName: string;
    orderItemsLength: number;
  };
  product?: any;
  products?: any;
  qtyColumnHeader: string;
  history: any;
  branches: any;
  availaibilityDisplayedWithoutQuantity?: boolean;
  itemQty?: number;
  itemIndividualQty?: number;
  skusArray?: string[];
  productUri?: string;
  isAhri?: boolean;
  savedOrderCartUri?: string;
  isCartPage?: boolean;
  isSavedOrderDetails?: boolean;
}

const AlternateBranchList: FC<BranchListProps> = props => {
  const {
    product,
    orderInfo,
    qtyColumnHeader,
    branches,
    availaibilityDisplayedWithoutQuantity,
    itemQty,
    itemIndividualQty,
    skusArray,
    products,
    productUri,
    isAhri,
    savedOrderCartUri,
    isCartPage,
    isSavedOrderDetails
  } = props;

  const { config } = getConfig();
  const {
    alternateBranchesModalPageSize,
    showDistributionCenter,
    dnePolicyPath
  } = config;
  const history = useHistory();

  const [skip, setSkip] = useState<number>(0);
  const [filterByAvailability, setFilterByAvailability] = useState<boolean>(
    false
  );
  const [memberBranchInventory, setMemberBranchInventory] = useState<
    Array<{
      sku: string;
      branchName: string;
      branchNumber: number;
      quantityAvailable: number;
      distance?: number;
      inventory?: any[];
    }>
  >([]);
  const [dcAvailability, setDcAvailability] = useState(null);
  const [noAvailableBranches, setNoAvailableBranches] = useState<boolean>(
    false
  );
  const [inventoryError, setInventoryError] = useState<string>("");
  const [availabilityCheckDone, setAvailabilityCheckDone] = useState<boolean>(
    false
  );
  const [availabilityLoading, setAvailabilityLoading] = useState<boolean>(
    false
  );
  const [loadMoreIsActive, setLoadMoreIsActive] = useState<boolean>(false);
  const [branchSelected, setBranchSelected] = useState("");
  const [enteredZipCode, setEnteredZipCode] = useState<string>("");
  const [itemQtyEntered, setItemQtyEntered] = useState<number>(itemQty || 1);
  const [addCartButtonIds, setAddCartButtonIds] = useState([]);
  const [latLong, setLatLong] = useState<{
    latitude: number;
    longitude: number;
  }>(undefined);
  const [handleAddToCartButtonLabel, setHandleAddToCartButtonLabel] = useState<{
    messageIsShown: boolean;
    isErrorMessage: boolean;
    clickedBranchNumber: number;
    errorMessage: any;
  }>({
    messageIsShown: false,
    isErrorMessage: false,
    clickedBranchNumber: 0,
    errorMessage: ""
  });
  const [notAvailableProducts, setNotAvailableProducts] = useState([]);

  const context = useContext<{
    auth: any;
    user: any;
    branches: any;
    cart: any;
    account: any;
  }>(MainContext);

  const limit = alternateBranchesModalPageSize;

  const shouldDisableQtyColumn =
    window.location.pathname === "/checkout" ||
    window.location.pathname === "/mycart" ||
    window.location.pathname.includes("/savedOrders") ||
    (availaibilityDisplayedWithoutQuantity && !isAhri);
  const isCheckout = window.location.pathname === "/checkout";
  const ahriProducts =
    isAhri &&
    products.map(pr => ({
      sku: pr._item[0]._code[0].code,
      qtyEntered: pr.quantity,
      qtyAvailable: 1
    }));
  const productsRendered = isAhri ? ahriProducts : products;

  const onAvailabilityFilter = (event): void => {
    const { checked } = event.target;
    setFilterByAvailability(checked);
  };

  const handleAvailabilityError = error => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      auth: { logout }
    } = context;

    const {
      cortexApi: { scope }
    } = config;
    const { selectedBranch } = defaultCart;

    setAvailabilityCheckDone(true);
    const errorPath =
      "validateAvailability => getAvailability => AlternateBranchList.tsx";
    handleCustomException(error, logout, history, errorPath);
  };

  const handleZipcodeChange = event => {
    setEnteredZipCode(event.target.value);
  };

  const handleItemQtyChange = (event, branchNum) => {
    setItemQtyEntered(
      branchNum === branchSelected ? event.target.value : itemQty || 1
    );
  };

  const handleKeyDown = event => {
    const { keyCode } = event;
    const enterKeyCode = 13;

    if (keyCode === enterKeyCode) {
      validateZipCode();
    }
  };

  const handleDGAInventory = (
    alternateBranches,
    latitude?: number,
    longitude?: number,
    zipCode?: string
  ) => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      account: {
        accountDetails: { customerNumber }
      }
    } = context;
    const { selectedBranch } = defaultCart;

    const branchNumber = selectedBranch.code;
    const skus = availaibilityDisplayedWithoutQuantity && skusArray;
    const productSkus = product
      ? [product.sku]
      : products.map(item => item.sku || item.code);
    if (!loadMoreIsActive) {
      setAvailabilityLoading(true);
    }

    getAvailability(
      skus || productSkus,
      customerNumber,
      branchNumber,
      zipCode,
      zipCode ? null : latitude,
      zipCode ? null : longitude
    )
      .then(inventoryResponse => {
        if (
          inventoryResponse &&
          inventoryResponse.data &&
          inventoryResponse.data.data &&
          inventoryResponse.data.data.inventory
        ) {
          if (!inventoryResponse.data.data.inventory.entitledInventory) {
            console.error("Inventory error");
            setInventoryError(intl.get("inventory-error"));
            setAvailabilityCheckDone(true);
            setEnteredZipCode("");
            if (!loadMoreIsActive) {
              setAvailabilityLoading(false);
            } else {
              setLoadMoreIsActive(false);
            }
          } else {
            const data =
              inventoryResponse.data.data.inventory.entitledInventory;
            if (availaibilityDisplayedWithoutQuantity) {
              setMemberBranchInventory(prevState => {
                const updatedState = [...prevState];
                return updatedState.concat(data.branches);
              });
              setAvailabilityCheckDone(true);
              setInventoryError("");
              if (data.hubInventory) {
                setDcAvailability(data.hubInventory);
              }
              if (!loadMoreIsActive) {
                setAvailabilityLoading(false);
              } else {
                setLoadMoreIsActive(false);
              }
            } else {
              const branchInventory = data.branches.map(branch => {
                return {
                  sku: product.sku,
                  branchName: branch.branchName,
                  branchNumber: branch.branchNumber,
                  quantityAvailable: branch.inventory.length
                    ? branch.inventory[0].qtyAvailable
                    : 0,
                  distance: branch.distance,
                  ...(isAhri && { formattedAddress: branch.formattedAddress })
                };
              });

              setMemberBranchInventory(prevState => {
                const updatedState = [...prevState];
                return updatedState.concat(branchInventory);
              });

              setNoAvailableBranches(!alternateBranches);
              setAvailabilityCheckDone(true);
              setInventoryError("");
              if (data.hubInventory) {
                setDcAvailability(data.hubInventory);
              }
              if (!loadMoreIsActive) {
                setAvailabilityLoading(false);
              } else {
                setLoadMoreIsActive(false);
              }
            }
          }
        }
      })
      .catch(error => {
        console.error("Inventory error", error);
        setInventoryError(intl.get("inventory-error"));
        handleAvailabilityError(error);
        setEnteredZipCode("");
        if (!loadMoreIsActive) {
          setAvailabilityLoading(false);
        } else {
          setLoadMoreIsActive(false);
        }
      });
  };

  const handleDGABranches = alternateBranches => {
    if (enteredZipCode) {
      handleDGAInventory(
        alternateBranches,
        undefined,
        undefined,
        enteredZipCode
      );
    } else if (latLong) {
      handleDGAInventory(
        alternateBranches,
        latLong.latitude,
        latLong.longitude
      );
    } else if (!latLong && "geolocation" in navigator) {
      if (!loadMoreIsActive) {
        setAvailabilityLoading(true);
      }
      const onSuccess = position => {
        if (!loadMoreIsActive) {
          setAvailabilityLoading(false);
        }
        let currentLatitude;
        let currentLongitude;
        if (position && position.coords) {
          if (position.coords.latitude && position.coords.longitude) {
            currentLatitude = position.coords.latitude;
            currentLongitude = position.coords.longitude;
          }
        }
        if (currentLatitude && currentLongitude) {
          setLatLong({
            latitude: currentLatitude,
            longitude: currentLongitude
          });
        }
        handleDGAInventory(
          alternateBranches,
          currentLatitude,
          currentLongitude
        );
      };

      const onError = error => {
        setAvailabilityLoading(false);
        if (error && error.code) {
          // navigator permission denied
          if (error.code === 1) {
            if (enteredZipCode) {
              handleDGAInventory(
                alternateBranches,
                undefined,
                undefined,
                enteredZipCode
              );
            } else {
              setInventoryError(intl.get("enter-zipcode"));
            }
          }
          // navigator timed out
          else if (error.code === 3 || error.code === 2) {
            const skus = product
              ? product.sku
              : products.map(item => item.sku || item.code).join(", ");
            const zipCodeErrorMsg =
              availaibilityDisplayedWithoutQuantity || isAhri
                ? orderInfo.orderName
                : skus;
            setInventoryError(
              intl.get("zipcode-error-message", {
                SKU: zipCodeErrorMsg
              })
            );
          }
        }
      };

      const options = {
        enableHighAccuracy: true,
        maximumAge: 250,
        timeout: 2000
      };
      navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    }
  };

  const formatAvailability = branch => {
    let availability;
    if (availaibilityDisplayedWithoutQuantity) {
      const availableLabel = (
        <div className="label-availability">
          <span className="bold-text">{intl.get("available-label")}</span>
        </div>
      );
      const notAvailableLabel = (
        <div className="label-availability">
          <span className="bold-text">
            {intl.get("available-for-back-order-label")}
          </span>
        </div>
      );
      availability =
        branch &&
        branch.inventory &&
        branch.inventory.length &&
        (orderInfo && branch.inventory.length === orderInfo.orderItemsLength) &&
        branch.inventory.every(item => {
          const vendorSku = item.sku;
          const branchItem = productsRendered.find(
            ({ sku }) => sku === vendorSku
          );
          return item.qtyAvailable >= branchItem.qtyEntered;
        })
          ? availableLabel
          : notAvailableLabel;
      return availability;
    }
    if (memberBranchInventory || memberBranchInventory.length) {
      const foundBranch = memberBranchInventory.find(currentBranch => {
        return currentBranch.branchNumber === branch.branchNumber;
      });
      if (foundBranch) {
        const currentBranch = `${foundBranch.branchName}`;
        let dcAvailabilityQty;
        if (
          dcAvailability &&
          dcAvailability.inventory &&
          dcAvailability.inventory[0]
        ) {
          dcAvailabilityQty = dcAvailability.inventory[0].qtyAvailable;
        } else {
          dcAvailabilityQty = 0;
        }
        availability = formatPickupOrDeliveryAvailabilityLabel({
          branchAvailability: foundBranch.quantityAvailable,
          regionAvailability: 0,
          currentBranch,
          isSingleLinedLabel: false,
          isAltBranches: true,
          dcAvailability: dcAvailabilityQty
        });
      } else {
        return null;
      }
    }
    return availability;
  };

  /**
   * ## renderHomeBranchLabel
   *
   * @remarks Renders small label-like
   * span next to the branch name and number, if the
   * given branch is a home branch of the user.
   */

  const renderHomeBranchLabel = () => {
    return (
      <span className="home-branch-label">
        <i className="icon-home" />
        <span>{`${intl.get("home")} ${intl.get("branch")}`}</span>
      </span>
    );
  };

  const onAddToCart = async (event, currentBranchNumber) => {
    const {
      cart: {
        cartDetails: { defaultCart },
        getCartDetails
      },
      auth: { logout }
    } = context;

    const {
      addItemsToCart: {
        self: { uri }
      }
    } = defaultCart;

    const { id } = event.target;

    const { location } = history;
    const alternateBranchesString = "Alternate Branches";
    const categoryName = location.pathname.split("_")[1];

    let productCategory;
    switch (true) {
      case location.pathname.includes("/category"):
        productCategory = `${alternateBranchesString} ${categoryName}`;
        break;

      case location.pathname.includes("/search"):
        productCategory = `${alternateBranchesString} ${
          location.state ? location.state.searchType : "keyword"
        }`;
        break;

      case location.pathname.includes("/itemdetail"):
        productCategory = `${alternateBranchesString} PDP`;
        break;

      case location.pathname.includes("/partsFinder"):
        productCategory = `${alternateBranchesString} Parts Finder`;
        break;

      case location.pathname.includes("/savedOrders"):
        productCategory = `${alternateBranchesString} Saved Order Details`;
        break;

      default:
        productCategory = "";
    }

    setAddCartButtonIds(addCartButtonIds.concat(id));

    // items from Saved order page
    if (savedOrderCartUri) {
      return cortexFetch(savedOrderCartUri, {
        method: "post",
        body: JSON.stringify({
          "branch-number": currentBranchNumber,
          "shipping-method": "pickup"
        })
      })
        .then(res => {
          const onSuccess = data => {
            onAddToCartSuccess(id, currentBranchNumber, productCategory);
          };
          const onError = data => {
            if (!data.ok) {
              return data.json().then(json => {
                throw json;
              });
            }
            throw data;
          };
          return checkResponse(res, onSuccess, onError);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => updateAhriSystemGroupItems => AlternateBranchList.tsx"
              })
            );
          }
          setHandleAddToCartButtonLabel({
            messageIsShown: true,
            isErrorMessage: true,
            clickedBranchNumber: currentBranchNumber,
            errorMessage: generateSpecificErrorMessage(e)
          });
          setTimeout(() => {
            setHandleAddToCartButtonLabel({
              messageIsShown: false,
              isErrorMessage: false,
              clickedBranchNumber: 0,
              errorMessage: ""
            });
            setItemQtyEntered(itemQty || 1);
          }, 3000);
          setAddCartButtonIds(prevAddCartButtonIds =>
            prevAddCartButtonIds.filter(buttonId => buttonId !== id)
          );

          throw e;
        });
    }

    // regular items on PDP/PLP, Cart and Checkout page, or AHRI items on PDP/PLP
    if (!isAhri || (!isCartPage && !isCheckout)) {
      const items: Array<{
        code: string;
        quantity: number;
        "shipping-method": "pickup";
        "branch-number": string;
        "ahri-number"?: string;
      }> =
        products && products.length
          ? products.map(item => {
              return {
                code: item.sku || item.code,
                quantity:
                  currentBranchNumber === branchSelected
                    ? itemQtyEntered
                    : item.qtyEntered || item.quantity,
                "shipping-method": "pickup",
                "branch-number": currentBranchNumber,
                "ahri-number": isAhri ? orderInfo.orderName : null
              };
            })
          : [
              {
                code: product.sku,
                quantity:
                  currentBranchNumber === branchSelected
                    ? itemQtyEntered
                    : itemQty || 1,
                "shipping-method": "pickup",
                "branch-number": currentBranchNumber,
                "ahri-number": isAhri ? orderInfo.orderName : null
              }
            ];

      if (productUri) {
        return cortexFetch(productUri, {
          method: "put",
          body: JSON.stringify({
            "shipping-method": "pickup",
            "branch-number": currentBranchNumber,
            quantity:
              currentBranchNumber === branchSelected
                ? itemQtyEntered
                : itemQty || 1
          })
        })
          .then(() => {
            onAddToCartSuccess(id, currentBranchNumber, productCategory);
          })
          .catch(e => {
            if (checkTokensExpired(e)) {
              logout().catch(err =>
                pushToMaintenace(history, {
                  e: err,
                  errIn: "Logout => onAddToCart => AlternateBranchList.tsx"
                })
              );
            }
            setHandleAddToCartButtonLabel({
              messageIsShown: true,
              isErrorMessage: true,
              clickedBranchNumber: currentBranchNumber,
              errorMessage: generateSpecificErrorMessage(e)
            });
            setTimeout(() => {
              setHandleAddToCartButtonLabel({
                messageIsShown: false,
                isErrorMessage: false,
                clickedBranchNumber: 0,
                errorMessage: ""
              });
              setItemQtyEntered(itemQty || 1);
            }, 3000);
            setAddCartButtonIds(prevAddCartButtonIds =>
              prevAddCartButtonIds.filter(buttonId => buttonId !== id)
            );
          });
      }

      return addToCart(uri, { items })
        .then(res => {
          onAddToCartSuccess(id, currentBranchNumber, productCategory, items);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => onAddToCart => AlternateBranchList.tsx"
              })
            );
          }
          setHandleAddToCartButtonLabel({
            messageIsShown: true,
            isErrorMessage: true,
            clickedBranchNumber: currentBranchNumber,
            errorMessage: generateSpecificErrorMessage(e)
          });
          setTimeout(() => {
            setHandleAddToCartButtonLabel({
              messageIsShown: false,
              isErrorMessage: false,
              clickedBranchNumber: 0,
              errorMessage: ""
            });
            setItemQtyEntered(itemQty || 1);
          }, 3000);
          setAddCartButtonIds(prevAddCartButtonIds =>
            prevAddCartButtonIds.filter(buttonId => buttonId !== id)
          );
        });
    }

    // AHRI items on Cart and Checkout page
    if (isAhri && (isCartPage || isCheckout)) {
      const {
        cart: {
          cartDetails: {
            defaultCart: { cartId }
          }
        }
      } = context;
      const qtySet =
        currentBranchNumber === branchSelected ? itemQtyEntered : itemQty || 1;
      updateAhriSystemGroupItems(
        products,
        qtySet,
        cartId,
        "pickup",
        currentBranchNumber
      )
        .then(() => {
          onAddToCartSuccess(id, currentBranchNumber, productCategory);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => updateAhriSystemGroupItems => AlternateBranchList.tsx"
              })
            );
          }
          setHandleAddToCartButtonLabel({
            messageIsShown: true,
            isErrorMessage: true,
            clickedBranchNumber: currentBranchNumber,
            errorMessage: generateSpecificErrorMessage(e)
          });
          setTimeout(() => {
            setHandleAddToCartButtonLabel({
              messageIsShown: false,
              isErrorMessage: false,
              clickedBranchNumber: 0,
              errorMessage: ""
            });
            setItemQtyEntered(itemQty || 1);
          }, 3000);
          setAddCartButtonIds(prevAddCartButtonIds =>
            prevAddCartButtonIds.filter(buttonId => buttonId !== id)
          );
        });
    }
    return null;
  };

  const onAddToCartSuccess = async (
    id,
    currentBranchNumber,
    productCategory,
    items?: any
  ) => {
    const {
      cart: { getCartDetails }
    } = context;
    setAddCartButtonIds(prevAddCartButtonIds =>
      prevAddCartButtonIds.filter(buttonId => buttonId !== id)
    );
    setHandleAddToCartButtonLabel(() => {
      const updatedState = { ...handleAddToCartButtonLabel };
      updatedState.messageIsShown = true;
      updatedState.clickedBranchNumber = currentBranchNumber;
      return updatedState;
    });
    setTimeout(() => {
      setHandleAddToCartButtonLabel(() => {
        const updatedState = { ...handleAddToCartButtonLabel };
        updatedState.messageIsShown = false;
        updatedState.clickedBranchNumber = 0;

        return updatedState;
      });
      setItemQtyEntered(itemQty || 1);
    }, 3000);

    // Update Cart Count in Context.
    getCartDetails().then(() => {
      if (product) {
        const displayedQuantity =
          currentBranchNumber === branchSelected
            ? itemQtyEntered
            : itemQty || 1;
        const quantityUsed = product.quantity || displayedQuantity;
        productAdded(
          product.name,
          product.sku,
          product.price,
          product.brand,
          productCategory,
          quantityUsed
        );
      } else if (products) {
        products.forEach(prod => {
          const displayedQuantity =
            currentBranchNumber === branchSelected
              ? itemQtyEntered
              : itemQty || 1;
          const nameUsed = prod.displayName || prod.name;
          const quantityUsed = prod.quantity || displayedQuantity;
          const priceUsed = prod.priceNumber || prod.price;
          productAdded(
            nameUsed,
            prod.sku,
            priceUsed,
            prod.brand,
            prod.category,
            quantityUsed
          );
        });
      }
    });
  };

  const renderDistributionCenterRow = branch => {
    let inventoryLabel;
    const roundedDistance = convertDistanceToKm(branch.distance);

    if (
      branch &&
      branch.inventory &&
      branch.inventory[0] &&
      branch.inventory[0].qtyAvailable &&
      branch.inventory[0].qtyAvailable > 0
    ) {
      inventoryLabel = (
        <div className="label-availability">
          <span className="bold-text">
            {intl.get("in-stock-quantity", {
              itemQty: branch.inventory[0].qtyAvailable
            })}
          </span>
        </div>
      );
    } else {
      inventoryLabel = (
        <div className="label-availability">
          <span className="bold-text">
            {intl.get("available-for-back-order-label")} /{" "}
            {intl.get("place-order-at-branch")}
          </span>
        </div>
      );
    }
    return (
      <li
        className="alternate-branches-modal-list-item"
        key={`alternate-branch-${branch.branchName}`}
      >
        <div className="branch-item-product-quantity">
          <span className="branch-item-product-quantity-mobile">
            {`${qtyColumnHeader}: `}
          </span>
          <div className="branch-item-product-availability">
            {inventoryLabel}
          </div>
        </div>
        <div className="branch-item-details">
          <p>{branch.branchName}</p>
          <p>
            <span>{branch.city} </span>
            <span>{branch.state}</span>
          </p>
        </div>
        <div className="branch-item-qty" />
        <div className="branch-item-distance">
          <span className="branch-item-distance-mobile">
            {`${intl.get("distance")}: `}
          </span>
          {roundedDistance}
        </div>
        <div className="branch-item-pickup-button">
          <div className="item-button">
            <Link
              to={{
                pathname: dnePolicyPath,
                state: { prevUrl: window.location.href }
              }}
              className="dast-link"
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {intl.get("click-here-for-more-information")}
            </Link>
          </div>
        </div>
      </li>
    );
  };

  const convertDistanceToKm = distance => {
    const {
      user: { userProfile }
    } = context;

    const { isCanadianUser } = userProfile;

    let distanceRounded;
    // Convert distance to kilometers if user is from Canada
    // 1mile = 1.609344km
    if (!isCanadianUser) {
      distanceRounded =
        typeof distance === "number"
          ? `${distance.toFixed(2)} mi`
          : `${distance} mi`;
    } else {
      distanceRounded =
        typeof distance === "number"
          ? `${(distance * 1.609344).toFixed(2)} km`
          : `${distance} km`;
    }
    return distanceRounded;
  };

  /**
   * ## renderBranchItem
   *
   * @param branch BranchDetails
   *
   * @remarks Renders the single list item that displays the quantity for the
   * product in warehouses(branhces) and distance.
   *
   * @returns JSX.Element
   */

  const renderBranchItem = branch => {
    const {
      account: { accountDetails }
    } = context;

    const { homeBranch } = accountDetails;
    const inventoryAvailable = formatAvailability(branch);

    const {
      distance,
      quantityAvailable,
      inventory
    } = memberBranchInventory.find(
      branchToFind => branchToFind.branchNumber === branch.branchNumber
    );

    const qtyToUse =
      isAhri && window.location.pathname.includes("/savedOrders")
        ? itemIndividualQty
        : itemQtyEntered;

    let availableQuantity;

    if (!savedOrderCartUri || !isSavedOrderDetails) {
      availableQuantity =
        isAhri || !quantityAvailable
          ? orderInfo &&
            inventory.length === orderInfo.orderItemsLength &&
            inventory.every(item => item.qtyAvailable >= qtyToUse)
          : !!quantityAvailable;
    } else {
      const skuMap = new Map(
        products.map(savedProduct => [
          savedProduct.sku,
          savedProduct.qtyEntered
        ])
      );
      availableQuantity =
        inventory.length === orderInfo.orderItemsLength &&
        inventory.every(item => item.qtyAvailable >= skuMap.get(item.sku));
    }

    const shouldRenderItem =
      inventoryAvailable !== null && filterByAvailability
        ? availableQuantity
        : true;

    if (
      !availableQuantity &&
      filterByAvailability &&
      notAvailableProducts.every(
        notAvailableProduct => notAvailableProduct !== branch.branchNumber
      )
    ) {
      setNotAvailableProducts(prevState => [...prevState, branch.branchNumber]);
    }

    const totalItems =
      products &&
      products.reduce((accumulator, currentProduct) => {
        const currentQty = currentProduct.qtyEntered || currentProduct.quantity;
        return currentQty + accumulator;
      }, 0);
    const selectedItemsQty =
      products && products.length
        ? totalItems * itemQtyEntered
        : itemQtyEntered;
    const itemsQty = products ? totalItems : itemQty;
    const roundedDistance = convertDistanceToKm(distance);

    return shouldRenderItem ? (
      <li
        className="alternate-branches-modal-list-item"
        key={`alternate-branch-${branch.branchName}`}
      >
        <div className="branch-item-product-quantity">
          <span className="branch-item-product-quantity-mobile">
            {`${qtyColumnHeader}: `}
          </span>
          <div className="branch-item-product-availability">
            {inventoryAvailable}
          </div>
        </div>
        <div className="branch-item-details">
          <p>
            {branch.branchName}
            {branch.branchNumber === homeBranch ? renderHomeBranchLabel() : ""}
          </p>
          <p>{branch.formattedAddress}</p>

          <a
            className="show-on-mobile"
            href={`tel:${extractPhoneAndFax(branch).phone}`}
          >
            {`P: ${extractPhoneAndFax(branch).phone}`}
          </a>
          <span className="hide-on-mobile">
            {extractPhoneAndFax(branch).phone}
          </span>
          {branch.fax && <span>{` F: ${extractPhoneAndFax(branch).fax}`}</span>}
        </div>
        {!shouldDisableQtyColumn && (
          <div className="branch-item-qty">
            <input
              type="number"
              min="1"
              max="9999"
              aria-label={intl.get("enter-item-qty")}
              id={branch.branchNumber}
              value={
                branch.branchNumber === branchSelected
                  ? itemQtyEntered
                  : itemQty || 1
              }
              onChange={e => {
                setBranchSelected(e.target.id);
                return handleItemQtyChange(e, e.target.id);
              }}
            />
          </div>
        )}
        <div className="branch-item-distance">
          <span className="branch-item-distance-mobile">
            {`${intl.get("distance")}: `}
          </span>
          {roundedDistance}
        </div>
        <div className="branch-item-pickup-button">
          {!shouldDisableQtyColumn && (
            <div className="branch-item-qty-mobile">
              <span>{intl.get("quantity")}:</span>
              <input
                type="number"
                min="1"
                max="9999"
                aria-label={intl.get("enter-item-qty")}
                id={branch.branchNumber}
                value={
                  branch.branchNumber === branchSelected
                    ? itemQtyEntered
                    : itemQty || 1
                }
                onChange={e => {
                  setBranchSelected(e.target.id);
                  return handleItemQtyChange(e, e.target.id);
                }}
              />
            </div>
          )}
          <div className="item-button">
            {addCartButtonIds.length &&
            addCartButtonIds.includes(branch.branchName) ? (
              <div className="miniLoader" />
            ) : (
              <button
                tabIndex={-1}
                type="button"
                className="dast-btn dast-btn-primary"
                onClick={event => {
                  onAddToCart(event, branch.branchNumber);
                }}
                id={branch.branchName}
                disabled={
                  addCartButtonIds.length &&
                  !addCartButtonIds.includes(branch.branchName)
                }
              >
                {intl.get("pick-up-method")}
              </button>
            )}
          </div>
          {handleAddToCartButtonLabel.messageIsShown &&
            handleAddToCartButtonLabel.clickedBranchNumber ===
              branch.branchNumber && (
              <div className="pick-up-button-label">
                <span>
                  {handleAddToCartButtonLabel.isErrorMessage
                    ? handleAddToCartButtonLabel.errorMessage
                    : intl.get("item-was-added-to-your-cart", {
                        count:
                          branch.branchNumber === branchSelected
                            ? selectedItemsQty
                            : itemsQty
                      })}
                </span>
              </div>
            )}
        </div>
      </li>
    ) : null;
  };

  const onLoadMore = () => {
    setSkip(skip + alternateBranchesModalPageSize);
    setLoadMoreIsActive(true);
  };

  const branchesRendered = () => {
    const branchNumbersList = memberBranchInventory.map(
      branch => branch.branchNumber
    );
    const branchesInList = [];
    for (let i = 0; i < branchNumbersList.length; i++) {
      const formattedBranches = memberBranchInventory.map(branch => {
        return {
          formattedAddress: branches.find(
            br => br.branchNumber === branch.branchNumber
          ).formattedAddress,
          phone: branches.find(br => br.branchNumber === branch.branchNumber)
            .phone,
          fax: branches.find(br => br.branchNumber === branch.branchNumber).fax,
          ...branch
        };
      });
      const branchesFound =
        isAhri || isSavedOrderDetails ? formattedBranches : branches;
      const branchItem = branchesFound.find(
        branch => branch.branchNumber === branchNumbersList[i]
      );
      branchesInList.push(branchItem);
    }
    return branchesInList;
  };

  const renderLoadMoreAvailabilityMessage = (
    availableProductsNotFound: boolean,
    numberOfFetchedBranches: number,
    totalNumberOfBranches?: number
  ) => (
    <div className="no-available-items-label">
      <p>
        {availableProductsNotFound
          ? intl.get("looking-branches-without-availability", {
              fetchedBranchesAmount: numberOfFetchedBranches,
              totalBranchesAmount: totalNumberOfBranches
            })
          : intl.get("additional-branches-have-been-loaded", {
              additionalFetchedBranchesNumber: numberOfFetchedBranches - limit
            })}
      </p>
      <p>{intl.get("keep-loading-branches-or-uncheck")}</p>
    </div>
  );

  const renderBranchList = () => {
    const branchesListDGA = branchesRendered();
    const shouldRenderButton =
      branchesListDGA &&
      availabilityCheckDone &&
      branches.length !== branchesListDGA.length;
    return (
      <div className="alternate-branches-modal-body">
        <ul>
          {showDistributionCenter
            ? renderDistributionCenterRow(dcAvailability)
            : null}
          {branchesListDGA && availabilityCheckDone ? (
            <>
              {notAvailableProducts.length === branchesListDGA.length &&
              filterByAvailability
                ? renderLoadMoreAvailabilityMessage(
                    true,
                    branchesListDGA.length,
                    branches.length
                  )
                : branchesListDGA.map(branch => renderBranchItem(branch))}
            </>
          ) : (
            <>
              {!noAvailableBranches || !memberBranchInventory.length ? (
                <div className="loader-container">
                  <h3>
                    <span>{intl.get("checking-availability")}</span>
                  </h3>
                  <div className="loader" />
                </div>
              ) : (
                // noAvailableBranches will only be true if error happens in Motili site, for non-goodman branch.
                <div className="custom-error-availability">
                  {intl.get("third-party-branch-availability-error")}
                </div>
              )}
            </>
          )}
          {shouldRenderButton ? (
            <>
              {filterByAvailability &&
                branchesListDGA.length > limit &&
                notAvailableProducts.length !== branchesListDGA.length &&
                renderLoadMoreAvailabilityMessage(
                  false,
                  branchesListDGA.length
                )}
              <div className="button-container">
                <div className="center-container">
                  {loadMoreIsActive ? (
                    <div className="miniLoader" />
                  ) : (
                    <button
                      type="button"
                      className="dast-btn dast-btn-primary"
                      onClick={onLoadMore}
                      aria-label={intl.get("load-more")}
                    >
                      {intl.get("load-more")}
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </ul>
      </div>
    );
  };

  /**
   * ## validateAvailability
   *
   * @description Validates if item is available on current branch and in the region.
   */
  const validateAvailability = async () => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList }
    } = context;

    const hasProducts = availaibilityDisplayedWithoutQuantity
      ? productsRendered
      : product;
    if (defaultCart && branchesList && hasProducts) {
      handleDGABranches(branchesList);
    }
  };

  const submitZipCode = () => {
    const {
      branches: { branchesList }
    } = context;

    setMemberBranchInventory([]);
    handleDGAInventory(branchesList, 0, 0, enteredZipCode);
  };

  const validateZipCode = () => {
    const isValidZipCode = /^([A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d|\d{5}(?:[-\s]\d{4})?)$/.test(
      enteredZipCode
    );

    if (enteredZipCode && isValidZipCode) {
      submitZipCode();
    } else {
      setInventoryError(intl.get("invalid-zipcode-error"));
      setEnteredZipCode("");
    }
  };
  const renderModalContent = () => {
    const skus = product
      ? product.sku
      : products.map(item => item.sku || item.code).join(", ");
    const zipCodeErrorMsg =
      availaibilityDisplayedWithoutQuantity || isAhri
        ? orderInfo.orderName
        : skus;
    if (availabilityLoading) {
      return (
        <div className="loader-container">
          <h3>
            <span>{intl.get("checking-availability")}</span>
          </h3>
          <div className="loader" />
        </div>
      );
    }
    if (!inventoryError) {
      if (memberBranchInventory.length) {
        return (
          <>
            <div className="alternate-branches-modal-branches-head">
              <span>{qtyColumnHeader}</span>
              <span>
                {`${intl.get("branch")} ${intl.get("name")}/ ${intl.get(
                  "address"
                )}`}
              </span>
              {!shouldDisableQtyColumn && <span>{intl.get("quantity")}</span>}
              <span>{intl.get("distance")}</span>
              <span>{intl.get("add-to-cart")}</span>
            </div>
            {renderBranchList()}
          </>
        );
      }
      return (
        <h3 className="zipcode-error-label">
          {intl.get("zipcode-error-message", {
            SKU: zipCodeErrorMsg
          })}
        </h3>
      );
    }
    return null;
  };

  useEffect(() => {
    const {
      branches: { branchesList },
      cart: {
        cartDetails: { defaultCart }
      }
    } = context;
    const hasProducts = availaibilityDisplayedWithoutQuantity
      ? productsRendered
      : product;
    if (defaultCart && branchesList && hasProducts) {
      validateAvailability();
    } else {
      setAvailabilityCheckDone(true);
    }
  }, [skip]);

  return (
    <div className="alternate-branches-modal-content">
      <div className="alternate-branches-modal-header">
        <div className="alternate-branches-modal-title">
          <h4>{intl.get("alternate-branches")}</h4>
          {inventoryError && (
            <p>
              <i className="icon-info" />
              <span>{inventoryError}</span>
            </p>
          )}
        </div>
        <div className="alternate-branches-modal-details">
          {product && (
            <p>
              <span>{`${intl.get("product")}: `}</span>
              <span className="details__sku">{product.sku}</span>
            </p>
          )}
          {orderInfo && !isAhri && (
            <p>
              <span>{`${intl.get("order-name")}: `}</span>
              <span>{orderInfo.orderName}</span>
            </p>
          )}
          {orderInfo && isAhri && (
            <p>
              <span>
                {intl.get("ahri-system", {
                  number: orderInfo.orderName
                })}
              </span>
            </p>
          )}

          {product && (isCartPage || isCheckout) && (
            <p className="quantity-entered">
              <span>{`${intl.get("quantity-entered")}: `}</span>
              <span>{itemQty}</span>
            </p>
          )}
          {branches && (
            <div className="availability-label">
              <label id="availability-label" htmlFor="filterByAvailability">
                <input
                  name="filterByAvailability"
                  id="filterByAvailability"
                  type="checkbox"
                  checked={filterByAvailability}
                  onChange={onAvailabilityFilter}
                />
                <span>{intl.get("show-branches-with-stock")}</span>
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="zipcode-container">
        <span>{intl.get("enter-zipcode")}</span>
        <div className="zipcode-input-wrapper">
          <input
            type="text"
            aria-label="zipcode"
            value={enteredZipCode}
            onChange={handleZipcodeChange}
            onKeyDown={handleKeyDown}
          />
          <button
            type="button"
            className="dast-btn dast-btn-primary"
            onClick={validateZipCode}
          >
            {intl.get("submit")}
          </button>
        </div>
      </div>
      {renderModalContent()}
    </div>
  );
};

export default AlternateBranchList;
