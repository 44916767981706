import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { AlternateBranchList, getConfig } from "@zilker/store-components";
import {
  checkTokensExpired,
  pushToMaintenace,
  isSpecialAirPurifier
} from "../../../app/src/utils/helpers";
import { updateAhriSystemGroupItems } from "../../../app/src/services/EpServices";
import { cortexFetch } from "../utils/Cortex";
import { MainContext } from "../../../app/src/contexts/MainContext";

interface ProductItemProps {
  sku: string;
  description: string;
  price: string;
  quantity: number;
  buttonText: string;
  uri: string;
  productImage: any;
  displayName: any;
  setIsLoading?: any;
  isLoading?: boolean;
  groupId?: string;
  first?: boolean;
  groupedItems?: any;
  name?: string;
  brand?: string;
}
const ProductItem: React.FC<ProductItemProps> = props => {
  const {
    sku,
    description,
    price,
    quantity,
    buttonText,
    uri,
    productImage,
    displayName,
    setIsLoading,
    isLoading,
    groupId,
    first,
    groupedItems,
    brand
  } = props;

  const context = useContext<{
    cart: any;
    branches: any;
    auth: any;
    user: any;
    account: any;
  }>(MainContext);
  const {
    branches: { branchesList, airPurifierBranch },
    cart,
    auth,
    account: {
      accountDetails: { homeBranch }
    }
  } = context;
  const history = useHistory();

  const { branchNumber: airPurifierBranchNumber } = airPurifierBranch;

  const [openAlternateBranchModal, setOpenAlternateBranchModal] = useState<
    boolean
  >(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(
    false
  );

  const { config } = getConfig();

  const imgMissingHorizontal = config.missingImagePlaceholderUrl;

  const isDeliverySection = buttonText === intl.get("pick-up-instead");

  const openAlternateBranchesModal = () => {
    setOpenConfirmationModal(false);
    setOpenAlternateBranchModal(true);
  };

  const openUserConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };

  const closeAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(false);
  };

  const closeUserConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const updateShippingMethod = () => {
    const { getCartDetails } = cart;
    const { logout } = auth;

    setOpenConfirmationModal(false);
    setIsLoading(true);
    if (groupId !== "undefined") {
      const {
        cartDetails: {
          defaultCart: { cartId }
        }
      } = cart;
      updateAhriSystemGroupItems(
        groupedItems,
        quantity,
        cartId,
        "delivery",
        homeBranch
      )
        .then(() => {
          return getCartDetails();
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => updateShippingMethod => productItem.tsx"
              })
            );
          }
        });
    } else {
      cortexFetch(uri, {
        method: "put",
        body: JSON.stringify({
          "shipping-method": "delivery",
          "branch-number": isSpecialAirPurifier(sku)
            ? airPurifierBranchNumber
            : homeBranch,
          quantity
        })
      })
        .then(() => {
          return getCartDetails();
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => updateShippingMethod => productItem.tsx"
              })
            );
          }
        })
        .finally(() => setIsLoading(false));
    }
  };
  const renderAlternateBranchesModal = () => {
    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };
    const orderName = groupId && groupId.split("ahri-group-")[1];
    const skusArray =
      groupedItems && groupedItems.map(item => item._item[0]._code[0].code);
    return (
      <Modal
        open={openAlternateBranchModal}
        onClose={closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          {groupId !== "undefined" ? (
            <AlternateBranchList
              orderInfo={{
                orderName,
                orderItemsLength: groupedItems.length
              }}
              products={groupedItems}
              qtyColumnHeader={intl.get("stock-status")}
              history={history}
              branches={branchesList}
              availaibilityDisplayedWithoutQuantity
              itemQty={quantity}
              isAhri
              skusArray={skusArray}
            />
          ) : (
            <AlternateBranchList
              product={{
                name: displayName,
                sku,
                price,
                brand
              }}
              qtyColumnHeader={intl.get("stock-status")}
              history={history}
              branches={branchesList}
              itemQty={quantity}
              productUri={uri}
            />
          )}
        </div>
      </Modal>
    );
  };

  const promptUserForConfirmation = () => {
    const fulfillmentMethod = isDeliverySection ? "picked up" : "delivered";
    return (
      <Modal
        open={openConfirmationModal}
        onClose={closeUserConfirmationModal}
        classNames={{ modal: "confirmation-modal-content" }}
      >
        <div className="modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3>{intl.get("change-fulfillment-method-heading")}</h3>
            </div>
            <div className="modal-body">
              <div id="changeFulfillmentMethodModal">
                <div className="fulfillment-modal-content">
                  <p>
                    {intl.get("change-fulfillment-method-modal-confirmation", {
                      method: fulfillmentMethod
                    })}
                  </p>
                </div>
                <div className="change-fulfillment-buttons">
                  <button
                    type="button"
                    className="dast-btn dast-btn-primary"
                    onClick={
                      isDeliverySection
                        ? openAlternateBranchesModal
                        : updateShippingMethod
                    }
                  >
                    {intl.get("ok")}
                  </button>
                  <button
                    type="button"
                    className="dast-btn dast-btn-secondary"
                    onClick={closeUserConfirmationModal}
                  >
                    {intl.get("cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {isDeliverySection &&
        !openConfirmationModal &&
        renderAlternateBranchesModal()}
      {promptUserForConfirmation()}
      {first && groupId !== "undefined" ? (
        <li className="ahri-container">
          <div>
            {intl.get("ahri-system", {
              number: groupId.split("ahri-group-")[1]
            })}
          </div>
          <button
            type="button"
            className="dast-link"
            onClick={openUserConfirmationModal}
          >
            {isSpecialAirPurifier(sku) ? intl.get("delivery-only") : buttonText}
          </button>
        </li>
      ) : null}
      <li
        key={`_${Math.random()}-${sku}`}
        className={`${groupId !== "undefined" ? "ahri" : ""}`}
      >
        <div className="img-container">
          <img
            src={productImage || imgMissingHorizontal}
            alt={displayName || intl.get("none-available")}
            onError={e => {
              const element: any = e.target;
              element.src = imgMissingHorizontal;
            }}
          />
        </div>
        <div className="wrapper-div">
          <div>{description}</div>
          <div> {sku}</div>
        </div>
        <div>${price}</div>
        <div>{quantity}</div>
        {isLoading ? (
          <div className="miniLoader" />
        ) : groupId !== "undefined" ? null : (
          <button
            type="button"
            className={`dast-link ${
              isSpecialAirPurifier(sku) ? "disabled" : ""
            }`}
            onClick={openUserConfirmationModal}
            disabled={isSpecialAirPurifier(sku)}
          >
            {isSpecialAirPurifier(sku) ? intl.get("delivery-only") : buttonText}
          </button>
        )}
      </li>
    </>
  );
};

export default ProductItem;
