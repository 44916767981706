/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import intl from "react-intl-universal";

import CartLineItem from "../CartLineItem/cart.lineitem";
import { getConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { ContractItemDetails } from "../ContractItemsTable/ContractItem";
import {
  checkResponse,
  checkTokensExpired,
  generateSpecificErrorMessage,
  pushToMaintenace
} from "../../../app/src/utils/helpers";
import { updateAhriSystemGroupItems } from "../../../app/src/services/EpServices";
import { cortexFetch } from "../utils/Cortex";

import "./cart.main.less";

interface CartMainProps extends RouteComponentProps {
  empty: boolean;
  cartData: {
    [key: string]: any;
  };
  itemDetailLink?: string;
  groupedItems: any;
  history: any;
  setItemError: (...args: any[]) => any;
  inventoryError: string;
  isErrorMessage: boolean;
  notSelectedPickupBranches: any[];
  missingFulfillmentMethod: any[];
}

const CartMain: React.FunctionComponent<CartMainProps> = ({
  isErrorMessage,
  notSelectedPickupBranches,
  missingFulfillmentMethod,
  itemDetailLink,
  empty,
  groupedItems,
  history,
  setItemError,
  inventoryError
}) => {
  const [isChangeDisabled, setIsChangeDisabled] = useState<boolean>(false);
  // active groups represent pros groups that should be visible for mobile view
  // since for mobile we have expand option
  const [activeGroups, setActiveGroups] = useState<Array<string>>(
    Object.keys(groupedItems)
  );
  const { config } = getConfig();

  const context = useContext<{
    user: any;
    cart: any;
    contract: any;
    auth: any;
    account: any;
  }>(MainContext);

  const validateContractQuantityLimit = async (
    qtyEntered: number,
    item: any
  ) => {
    const {
      cart: {
        cartDetails: {
          defaultCart: { cartOrderDetailsForm }
        }
      },
      contract: { filterContracts }
    } = context;

    if (!item["contract-number"] || cartOrderDetailsForm.pricing === "y") {
      return true;
    }

    const [contract] = filterContracts({
      orderNumber: cartOrderDetailsForm["contract-number"]
    });
    const {
      detailLines
    }: { detailLines: Array<ContractItemDetails> } = contract;

    const contractItem = detailLines.find(
      detail => detail.sku === item._item[0]._code[0].code
    );
    return qtyEntered <= contractItem.quantity - contractItem.releasedQuantity;
  };

  const handleQuantityChange = async (
    item,
    selectedQuantity,
    toggleLoader,
    shippingMethod,
    branchNumber
  ) => {
    await toggleLoader();
    setIsChangeDisabled(true);

    const {
      cart: { setErrorCartPopupMessage }
    } = context;

    if (!selectedQuantity || selectedQuantity === item.quantity) {
      toggleLoader();
      setIsChangeDisabled(false);
      return;
    }

    const valid = validateContractQuantityLimit
      ? await validateContractQuantityLimit(selectedQuantity, item)
      : true;

    try {
      if (valid) {
        updateItemQuantity(
          item,
          selectedQuantity,
          toggleLoader,
          shippingMethod,
          branchNumber
        );
      } else {
        toggleLoader();
        setIsChangeDisabled(false);
        setErrorCartPopupMessage(intl.get("contract-quantity-limit-error"));
      }
    } catch (err) {
      toggleLoader();
      setIsChangeDisabled(false);
      setErrorCartPopupMessage(intl.get("custom-error-add-to-cart"));
    }
  };

  const updateItemQuantity = (
    item,
    selectedQuantity,
    toggleLoader,
    shippingMethod,
    branchNumber
  ) => {
    const {
      cart: { getCartDetails, setErrorCartPopupMessage },
      auth: { logout }
    } = context;

    cortexFetch(item.self.uri, {
      method: "put",
      body: JSON.stringify({
        quantity: selectedQuantity,
        "shipping-method": shippingMethod,
        "branch-number": branchNumber
      })
    })
      .then(res => {
        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        toggleLoader();
        setIsChangeDisabled(false);
      })
      .catch(e => {
        toggleLoader();
        setIsChangeDisabled(false);
        setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => updateItemQuantity => CartLineItem.tsx"
            })
          );
        }
      });
  };

  const handleRemoveBtnClicked = (item, toggleLoader) => {
    const {
      cart: { getCartDetails },
      auth: { logout }
    } = context;

    toggleLoader();
    setIsChangeDisabled(true);

    cortexFetch(item.self.uri, {
      method: "delete"
    })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setIsChangeDisabled(false);
      })
      .catch(e => {
        toggleLoader();
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleRemoveBtnClicked => CartLineItem.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "handleRemoveBtnClicked => CartLineItem.tsx"
          });
        }
      });
  };

  const handleGroupItemsUpdate = async (
    item,
    quantity,
    groupId,
    groupType,
    toggleUpdateLoader,
    toggleDeleteLoader,
    shippingMethod,
    branchNumber,
    isQuantityChange: boolean
  ) => {
    const {
      cart: {
        getCartDetails,
        cartDetails: {
          defaultCart: { cartId, items }
        }
      },
      auth: { logout },
      account: {
        accountDetails: { homeBranch }
      }
    } = context;
    const toggleLoader = quantity ? toggleUpdateLoader : toggleDeleteLoader;

    await toggleLoader();
    setIsChangeDisabled(true);

    if (isQuantityChange) {
      if (quantity === item.quantity) {
        toggleLoader();
        setIsChangeDisabled(false);
        return;
      }
    }

    const groupItems = items.filter(groupItem => {
      switch (groupType) {
        case "PROS":
          return (
            groupItem["pros-config-id"] === groupId.split("pros-group-")[1]
          );
        case "AHRI":
          return (
            `${groupItem["ahri-number"]}-${groupItem["shipping-method"]}-${groupItem["branch-number"]}` ===
            groupId.split("ahri-group-")[1]
          );
        default:
          return false;
      }
    });

    const branchNumberUsed = branchNumber || homeBranch;
    const shippingMethodUsed = shippingMethod || "pickup";

    updateAhriSystemGroupItems(
      groupItems,
      quantity,
      cartId,
      shippingMethodUsed,
      branchNumberUsed
    )
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        if (isQuantityChange) {
          toggleLoader();
        }
        setIsChangeDisabled(false);
      })
      .catch(e => {
        if (isQuantityChange) {
          toggleLoader();
        }
        setIsChangeDisabled(false);
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleGroupItemsUpdate => CartLineItem.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "handleGroupItemsUpdate => CartLineItem.tsx"
          });
        }
      });
  };

  const renderCartTableHeader = () => (
    <div className="row cart-lineitem-new cart-table-header">
      <div
        className={`col-12 ${config.calculatePrice ? "col-lg-4" : "col-lg-7"}`}
      >
        <div className="col-header d-none d-lg-block">
          {`${intl.get("product")}:`}
        </div>
      </div>
      {config.calculatePrice && (
        <div className="col-12 col-lg-2 d-none d-lg-block">
          <div className="col-header d-none d-lg-block justify-content-lg-center">
            {`${intl.get("price")}: `}
          </div>
        </div>
      )}
      <div className="col-6 col-lg-1">
        <div className="col-header d-none d-lg-block justify-content-lg-center">
          {`${intl.get("quantity-abbr")}: `}
        </div>
      </div>
      <div className="col-6 col-lg-3 vertically-aligned-mobile">
        <div className="col-header d-none d-lg-block justify-content-lg-center">
          {`${intl.get("fulfillment")}:`}
        </div>
      </div>
      <div
        className={`col-12 ${config.calculatePrice ? "col-lg" : "col-lg-1"}`}
      >
        <div className="col-header d-none d-lg-block">
          {config.calculatePrice && `${intl.get("ext-price")}: `}
        </div>
      </div>
    </div>
  );

  const toggleActiveGroup = prosId => {
    const group = activeGroups.find(activeGroup => activeGroup === prosId);
    const updatedGroups = group
      ? activeGroups.filter(activeGroup => activeGroup !== prosId)
      : [...activeGroups, prosId];
    setActiveGroups(updatedGroups);
  };

  if (empty) {
    return (
      <div className="cart-empty-container">
        <span className="cart-empty-message">
          {intl.get("shopping-cart-empty-message")}
        </span>
      </div>
    );
  }

  return (
    <>
      <div className="cart-main-inner table-responsive">
        {renderCartTableHeader()}
        {Object.keys(groupedItems)
          .reverse()
          .map(group =>
            groupedItems[group].map((item, itemIndex) => {
              let groupName = "";
              let ahriAvailability = null;
              if (group) {
                const isAHRI = group.includes("ahri");
                const isPros = group.includes("pros");
                if (isAHRI) {
                  groupName = "AHRI";
                  ahriAvailability = groupedItems[group].every(
                    groupItem =>
                      groupItem.branchAvailability &&
                      groupItem.branchAvailability > 0
                  );
                } else if (isPros) {
                  groupName = "PROS";
                }
              }

              return (
                <CartLineItem
                  isErrorMessage={isErrorMessage}
                  notSelectedPickupBranches={notSelectedPickupBranches}
                  missingFulfillmentMethod={missingFulfillmentMethod}
                  key={`${item._item[0]._code[0].code} ${group || ""}_${
                    item["shipping-method"]
                  }_${item["branch-number"]}_${item["contract-number"] || ""}`}
                  item={item}
                  itemDetailLink={itemDetailLink}
                  isChangeDisabled={isChangeDisabled}
                  first={itemIndex === 0}
                  groupId={group}
                  groupName={groupName}
                  handleGroupItemsUpdate={handleGroupItemsUpdate}
                  handleQuantityChange={handleQuantityChange}
                  handleRemoveBtnClicked={handleRemoveBtnClicked}
                  toggleActivePros={toggleActiveGroup}
                  activePros={activeGroups}
                  setItemError={setItemError}
                  inventoryError={inventoryError}
                  ahriAvailability={ahriAvailability}
                />
              );
            })
          )}
      </div>
    </>
  );
};

export default withRouter(CartMain);
