import React from "react";
import { Link } from "react-router-dom";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";

import "./banner.less";
import RichText from "../RichText/rich-text";

const Banner = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return <>No Banner to display</>;
  }

  const { content, link, image, title, linktitle } = document.getData();
  const imageSrc = page.getContent<ImageSet>(image);

  const className = "dast-btn dast-btn-secondary";

  return (
    <div className="container-fluid p-0">
      <div className="jumbotron has-edit-button">
        <div className="row">
          <div className="col-xl-6 col-12 p-0">
            <div className="banner-text">
              {title && <h1>{title}</h1>}
              <div className="banner-content">
                <RichText content={content.value} />
              </div>
              <div className="banner-link">
                {link && (
                  <Link to={link} className={className}>
                    {linktitle}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-12 p-0">
            {image && (
              <figure>
                <img src={imageSrc.getOriginal().getUrl()} alt={title} />
              </figure>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
