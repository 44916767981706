import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";
import { isLinkExternal } from "../../../../../app/src/utils/helpers";
import { MainContext } from "../../../../../app/src/contexts/MainContext";

import "./quick-services.less";

const QuickServices = ({ component, page }: BrProps) => {
  const context = useContext<{ auth: any }>(MainContext);
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  const {
    auth: { isLoggedIn }
  } = context;

  if (!document) {
    return <>No Quick Service to display</>;
  }

  const renderQuickServices = () => {
    const { cards } = document.getData();
    return cards.map(({ cellWidth, elements, id: cardId }, index) => (
      <div
        className="quick-services-card"
        style={{ flex: `${cellWidth} 0 0` }}
        id={cardId}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        {elements.map(
          ({
            title,
            image,
            link,
            id: elementId,
            isLinkForLoggedInUserOnly,
            isQuickOrder
          }) => {
            const [isExternal, url] = isLinkExternal(link);
            const modalValue = isQuickOrder;
            const imageSrc = page.getContent<ImageSet>(image);
            return !isExternal ? (
              <Link
                to={{
                  pathname:
                    !isLoggedIn && isLinkForLoggedInUserOnly ? "/login" : url,
                  state: { modal: modalValue, prevUrl: window.location.href }
                }}
                className="quick-services-element"
                id={elementId}
                key={`${title}`}
                onClick={() => window.scrollTo(0, 0)}
              >
                <img
                  src={imageSrc ? imageSrc.getOriginal().getUrl() : ""}
                  alt="Non available"
                />
                <h4>{title}</h4>
              </Link>
            ) : (
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="quick-services-element"
                id={elementId}
                key={`${title}`}
              >
                <img
                  src={imageSrc ? imageSrc.getOriginal().getUrl() : ""}
                  alt="Non available"
                />
                <h4>{title}</h4>
              </a>
            );
          }
        )}
      </div>
    ));
  };

  return (
    <div className="quick-services container">
      {document && renderQuickServices()}
    </div>
  );
};

export default QuickServices;
