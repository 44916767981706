import React from "react";
import "./carouselarrowbutton.less";

const CarouselArrowButton = props => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={` test arrow ${className}`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};
export default CarouselArrowButton;
