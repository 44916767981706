import React, { FC } from "react";

import "./bread.crumbs.component.less";
import { NavLink } from "react-router-dom";
import { decodeURIComponentSafely } from "@elasticpath/ref-store/src/utils/helpers";

interface urlParamInterface {
  param: string;
  name: string;
  state?: boolean;
  start?: number;
  sku?: string;
  facets?: any;
  itemsLoaded?: number;
  sortByAvailability?: boolean;
}

interface Props {
  breadCrumbsMap: urlParamInterface[];
}

const BreadCrumbs: FC<Props> = ({ breadCrumbsMap }) => {
  return <ul className="bread-crumbs">{createCrumbsList(breadCrumbsMap)}</ul>;
};

function createCrumbsList(breadCrumbsMap: urlParamInterface[]) {
  return breadCrumbsMap.map((urlParam: urlParamInterface, index: number) => {
    const key = urlParam.name + index;
    const isLast = index === breadCrumbsMap.length - 1;
    const {
      param,
      name,
      state,
      start,
      sku,
      facets,
      itemsLoaded,
      sortByAvailability
    } = urlParam;
    const breadCrumbUrl = param !== "home" ? `/${param}` : "";
    let breadCrumb: JSX.Element;

    if (param === "nocrumb") {
      breadCrumb = (
        <li className="no-crumb" key={key}>
          {decodeURIComponentSafely(name)}
        </li>
      );
    } else {
      breadCrumb = (
        <li key={key} className="dast-link bread-crumb-link">
          {state ? (
            <NavLink
              to={{
                pathname: `${encodeURI(breadCrumbUrl)}`,
                state: {
                  name,
                  start,
                  sku,
                  facets,
                  itemsLoaded,
                  sortByAvailability,
                  prevUrl: window.location.href
                }
              }}
              key={key}
            >
              {name ? name.replace(/%25/g, "%") : ""}
            </NavLink>
          ) : (
            <NavLink to={`${encodeURI(breadCrumbUrl)}`} key={key}>
              {name ? name.replace(/%25/g, "%") : ""}
            </NavLink>
          )}
        </li>
      );
    }
    return (
      <div className="bread-crumb-wrapper" key={`wrapper-${key}`}>
        {breadCrumb}
        {!isLast && (
          <li className="bread-crumb-divider" key={`divider-${key}`} />
        )}
      </div>
    );
  });
}

export default BreadCrumbs;
