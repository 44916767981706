import React, { FC, useContext } from "react";

import SearchResultsMenuItem from "./search.results.menu.item";
import { MainContext } from "../../../app/src/contexts/MainContext";
import "./search.results.menu.section.less";

interface SearchResultsMenuSectionProps {
  name: string;
  data: any;
  filtersChecked: any;
  onFacetClicked: (any) => any;
  toggleOpenedFacetFields: (facetName: string) => any;
  openedFacetFields?: any[];
  handleCheckboxChange?: (any) => any;
  selected?: string;
}

const SearchResultsMenuSection: FC<SearchResultsMenuSectionProps> = ({
  name,
  data,
  filtersChecked,
  onFacetClicked,
  openedFacetFields,
  toggleOpenedFacetFields,
  handleCheckboxChange,
  selected
}) => {
  const filterIsExpanded = openedFacetFields.some(field => field === name);
  const classNameSuffix = filterIsExpanded ? "opened" : "";
  const context = useContext<{
    cart: any;
    branches: any;
  }>(MainContext);
  const {
    cart: { cartDetails },
    branches: { findBranch }
  } = context;
  const { defaultCart } = cartDetails;
  const selectedBranch =
    defaultCart && findBranch(defaultCart.selectedBranch.code);

  const toggleSection = (): void => {
    toggleOpenedFacetFields(name);
  };

  const sections = Array.from(data);
  const sectionName = name.replace(/_/g, " ");
  return (
    <div className="search-results-menu-item">
      <div
        className={`search-results-menu-item-title ${classNameSuffix}`}
        onClick={toggleSection}
        onKeyDown={toggleSection}
        role="button"
        aria-hidden="true"
      >
        <div>
          <h4>{`${sectionName}`}</h4>
        </div>
        <i
          title={`Open ${name} Menu`}
          aria-label={`Open ${name} Menu`}
          className={filterIsExpanded ? "icon-chevron-up" : "icon-chevron-down"}
        />
      </div>
      <div className={`search-results-menu-item-fields ${classNameSuffix}`}>
        {sections.map(
          (item: { name?: string; type?: string; count?: number }) => {
            const key = `${item.name}-${item.type}`;
            const sectionItems = filtersChecked[item.type]
              ? Array.from(filtersChecked[item.type])
              : [];
            const itemChecked = sectionItems.includes(`"${item.name}"`);

            return (
              <SearchResultsMenuItem
                key={key}
                data={item}
                itemChecked={itemChecked}
                onFacetClicked={onFacetClicked}
                selectedBranch={selectedBranch}
                selected={selected}
                handleCheckboxChange={handleCheckboxChange}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default SearchResultsMenuSection;
