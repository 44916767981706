import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import * as LoginService from "@elasticpath/ref-store/src/services/LoginService";
import {
  formatAhriLink,
  formatWarrantyLinks,
  formatProductRegistrationLinks,
  formatHVACTools,
  authenticateUser
} from "../../../app/src/utils/helpers";

import "./appheaderCTA.less";

interface AppHeaderCTAProps {
  isMobile?: boolean;
  isMobileMin?: boolean;
  isDesktop?: boolean;
}

interface NavItem {
  name: string;
  path: string;
  internal: boolean;
  description?: string;
  isAhri?: boolean;
}

const AppHeaderCTA: React.FC<AppHeaderCTAProps> = props => {
  const { isMobile, isMobileMin, isDesktop } = props;
  const history = useHistory();
  const context = useContext<{
    cart: any;
    account: any;
    auth: any;
  }>(MainContext);

  const {
    account: {
      accountDetails: { membership, customerNumber }
    },
    cart: {
      cartDetails: { defaultCart }
    },
    auth: { isLoggedIn }
  } = context;

  const [resources, setResources] = useState([]);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const redirectUser = async () => {
    const currentLocationParams = window.location.search;
    const urlParams = new URLSearchParams(currentLocationParams);
    const accountTypeParam = urlParams.get("accountType");
    const invEmailParam = urlParams.get("invEmail");

    if (
      LoginService.isLoggedIn() &&
      customerNumber &&
      membership &&
      defaultCart
    ) {
      formatAhriLink(membership, customerNumber, defaultCart);
    } else if (!LoginService.isLoggedIn()) {
      authenticateUser(accountTypeParam, invEmailParam, history);
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const renderResourcesDropdown = (name: string, menu: Array<NavItem>) => {
    const currentWarranty = formatWarrantyLinks();
    const productRegistrationLinks = formatProductRegistrationLinks();

    return (
      <div>
        <button
          type="button"
          className="dast-btn-outlined-nav"
          data-name={name}
          data-fullstory-id="nav-resources"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <span>{name}</span>
        </button>
        <div
          className={`nav-dropdown-container dropdown ${
            openDropdown ? "show" : ""
          } tools-dropdown-list`}
        >
          <ul
            className="dropdown-menu nav-dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            {menu.map(
              menuItem =>
                menuItem &&
                menuItem.path && (
                  <li key={menuItem.name} className="tools-list-item">
                    {menuItem.internal ? (
                      <Link
                        to={{
                          pathname: menuItem.path,
                          state: { prevUrl: window.location.href }
                        }}
                        onClick={e => {
                          setOpenDropdown(!openDropdown);
                          e.stopPropagation();
                        }}
                        className="dropdown-item"
                        data-fullstory-id={`nav-${menuItem.name.replace(
                          /\s+/g,
                          "-"
                        )}`}
                      >
                        {" "}
                        <div>{menuItem.name}</div>
                      </Link>
                    ) : (
                      <a
                        href={
                          menuItem.name === "System Configurator"
                            ? "#"
                            : menuItem.path
                        }
                        className="dropdown-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-fullstory-id={`nav-${menuItem.name.replace(
                          /\s+/g,
                          "-"
                        )}`}
                        onClick={e => {
                          if (menuItem.isAhri) {
                            e.preventDefault();
                            formatAhriLink(
                              membership,
                              customerNumber,
                              defaultCart
                            );
                          }
                          setOpenDropdown(!openDropdown);
                          e.stopPropagation();
                        }}
                      >
                        <div>
                          {menuItem.isAhri && (
                            <span className="ahri-span">
                              **{intl.get("new").toUpperCase()}**
                            </span>
                          )}
                          {menuItem.name}
                        </div>
                      </a>
                    )}
                    {menuItem.description && (
                      <p className="item-description">{menuItem.description}</p>
                    )}
                  </li>
                )
            )}
            <li key="warranty-key" className="tools-list-item">
              <span className="dropdown-item regular-item">
                <button
                  type="button"
                  className="cat-name dropdown-item dropdown-toggle non-hover-button"
                  aria-expanded="true"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {intl.get("warranty")}
                </button>
              </span>
              <div className="nav-submenu-dropdown-container dropdown">
                <ul
                  className="dropdown-menu nav-dropdown-menu  nestedCategory1"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {currentWarranty.map(
                    warrantyItem =>
                      warrantyItem &&
                      warrantyItem.path && (
                        <li key={warrantyItem.name}>
                          {warrantyItem.internal ? (
                            <Link
                              className="subcat-item dropdown-item"
                              id={`header_navbar_sub_category_button_${warrantyItem.name}`}
                              data-fullstory-id={`nav-${warrantyItem.name.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              title={warrantyItem.name}
                              to={{
                                pathname: warrantyItem.path,
                                state: {
                                  navClick: true,
                                  name: warrantyItem.name,
                                  prevUrl: window.location.href
                                }
                              }}
                              onClick={e => {
                                setOpenDropdown(!openDropdown);
                                e.stopPropagation();
                              }}
                            >
                              <div aria-expanded="true">
                                {warrantyItem.name}
                              </div>
                            </Link>
                          ) : (
                            <a
                              href={warrantyItem.path}
                              className="subcat-item dropdown-item"
                              data-fullstory-id={`nav-${warrantyItem.name.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={e => {
                                setOpenDropdown(!openDropdown);
                                e.stopPropagation();
                              }}
                            >
                              <div aria-expanded="true">
                                {warrantyItem.name}
                              </div>
                            </a>
                          )}
                        </li>
                      )
                  )}
                </ul>
              </div>
            </li>
            <li key="prod-reg-key" className="tools-list-item">
              <span className="dropdown-item regular-item">
                <button
                  type="button"
                  className="cat-name dropdown-item dropdown-toggle non-hover-button"
                  aria-expanded="true"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  {intl.get("product-registration")}
                </button>
              </span>
              <div className="nav-submenu-dropdown-container dropdown">
                <ul
                  className="dropdown-menu nav-dropdown-menu  nestedCategory1"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {productRegistrationLinks.map(
                    productItem =>
                      productItem &&
                      productItem.path && (
                        <li key={productItem.name}>
                          <a
                            href={productItem.path}
                            className="subcat-item dropdown-item"
                            data-fullstory-id={`nav-${productItem.name.replace(
                              /\s+/g,
                              "-"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => {
                              setOpenDropdown(!openDropdown);
                              e.stopPropagation();
                            }}
                          >
                            <div aria-expanded="true">{productItem.name}</div>
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const currentResources = formatHVACTools();
    setResources(currentResources);
  }, []);

  return (
    <div className="header-cta-container" ref={wrapperRef}>
      <button
        type="button"
        className={`${
          isMobile ? "header-mobile-link" : "dast-btn-outlined-nav"
        }`}
        data-fullstory-id="nav-partsFinder-button"
        onClick={() => history.push("/partsFinder")}
      >
        {isMobileMin
          ? intl.get("find-a-part-sm-mobile")
          : intl.get("find-a-part")}
      </button>
      <button
        type="button"
        className={`${
          isMobile ? "header-mobile-link" : "dast-btn-outlined-nav"
        }`}
        onClick={redirectUser}
        data-fullstory-id="nav-systemBuilder"
      >
        {intl.get("build-a-system")}
      </button>
      {isDesktop && isLoggedIn && resources
        ? renderResourcesDropdown(intl.get("resources"), resources)
        : null}
    </div>
  );
};

export default AppHeaderCTA;
