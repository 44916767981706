/**
 * Refactored component from productdisplayitem.main.tsx. This component handles displaying the inventory
 * availability messaging.
 */
import React, { FC, useContext, useState } from "react";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { useHistory, Redirect } from "react-router-dom";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import {
  formatPickupOrDeliveryAvailabilityLabel,
  isSpecialAirPurifier,
  renderSpecialAirPurifierLabel,
  updateBranchNumber,
  generateSpecificErrorMessage
} from "../../../../app/src/utils/helpers";
import { productAdded } from "../../utils/Segment";
import AlternateBranchList from "../../AlternateBranchList/AlternateBranchList";

import "../../../../app/src/theme/sharedClasses.less";
import "./ProductAvailability.less";

interface ProductAvailabilityProps {
  productId: string;
  productData: any;
  itemPriceDetails: any;
  skuEntitled: boolean;
  validQuantity: boolean;
  inventoryAvailability: any[];
  itemQty: number;
  branchAvailability: any;
  regionAvailability: any;
  inventoryError: string;
  isCallButtonActive: boolean;
  isModalOpened: boolean;
  shippingMethod?: "pickup" | "delivery";
  openAlternateBranchesModal: () => void;
  closeAlternateBranchesModal: () => void;
  setShippingMethod?: (method: "pickup" | "delivery") => void;
  populateitemQuantity: any;
  dcAvailability?: number | null;
}

const ProductAvailability: FC<ProductAvailabilityProps> = ({
  productId,
  productData,
  itemPriceDetails,
  skuEntitled,
  validQuantity,
  inventoryAvailability,
  itemQty,
  branchAvailability,
  regionAvailability,
  inventoryError,
  isCallButtonActive,
  isModalOpened,
  openAlternateBranchesModal,
  closeAlternateBranchesModal,
  populateitemQuantity,
  dcAvailability
}) => {
  const history = useHistory();

  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
    account: any;
  }>(MainContext);
  const {
    cart: {
      cartDetails: { defaultCart },
      cartError
    },
    branches: { branchesList, findBranch },
    user: {
      userProfile: { ecommerceBuyer }
    },
    account: {
      accountDetails: { customerRoles }
    }
  } = context;
  const [itemQuantity, setItemQuantity] = useState<number>(1);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [
    clickedPickupOrDeliveryButton,
    setClickedPickupOrDeliveryButton
  ] = useState<string>("");

  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const activeBranch =
    defaultCart && findBranch(defaultCart.selectedBranch.code)
      ? findBranch(defaultCart.selectedBranch.code).branchName
      : "";

  const isPriceZero = itemPriceDetails && `${itemPriceDetails.total}` === "0";
  const priceUsed =
    itemPriceDetails && !isPriceZero ? itemPriceDetails.price : 0;

  const shouldBtnBeDisabled =
    (productData && !productData._addtocartform) ||
    (!itemPriceDetails || isPriceZero) ||
    !skuEntitled ||
    !validQuantity ||
    !ecommerceBuyer;

  const setTypeOfClickedButon = (buttonType: "pickup" | "delivery") =>
    setClickedPickupOrDeliveryButton(buttonType);

  const pickupBtnIsLoading =
    showLoader && clickedPickupOrDeliveryButton === "pickup";
  const deliveryBtnIsLoading =
    showLoader && clickedPickupOrDeliveryButton === "delivery";

  const name = productData._definition[0]["display-name"];
  const brand = productData._definition[0].details.find(
    info => info["display-name"] === "Brand"
  ).value;

  const addItemToCart = method => {
    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      },
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart, selectedBranch }
        },
        getCartDetails,
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage
      },
      account: {
        accountDetails: { homeBranch }
      }
    } = context;
    const items: [
      {
        code: string;
        quantity: number;
        "shipping-method": "pickup" | "delivery";
        "branch-number": string;
      }
    ] = [
      {
        code: productId,
        quantity: itemQuantity,
        "shipping-method": method,
        "branch-number": updateBranchNumber(
          method === "delivery",
          productId,
          airPurifierBranchNumber,
          homeBranch,
          selectedBranch.code
        )
      }
    ];

    setShowLoader(true);

    // Populate Cart with the item.
    addToCart(addItemsToCart.self.uri, { items })
      .then(() => {
        const id = productData._code[0].code;
        const category = productData._parentcategory[0].name;
        const price =
          !itemPriceDetails ||
          !itemPriceDetails.total ||
          itemPriceDetails.total === intl.get("pending")
            ? 0
            : itemPriceDetails.total;

        // sends information to Segment when user adds a product
        productAdded(name, id, price, brand, category, itemQuantity);
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setSuccesCartPopupMessage(itemQuantity);
        setShowLoader(false);
      })
      .catch(err => {
        console.error(err);
        setShowLoader(false);
        setErrorCartPopupMessage(generateSpecificErrorMessage(err));
      });
  };

  const handleAddToCartButtonClick = method => {
    if (
      branchAvailability === 0 &&
      regionAvailability > 0 &&
      method === "pickup"
    ) {
      return openAlternateBranchesModal();
    }

    return addItemToCart(method);
  };

  const handleQuantityChange = event => {
    if (event.target.value === "") {
      setItemQuantity(1);
      populateitemQuantity(1);
    } else {
      setItemQuantity(parseInt(event.target.value, 10));
      populateitemQuantity(parseInt(event.target.value, 10));
    }
  };

  const handleQuantityDecrement = () => {
    if (itemQuantity > 1) {
      const newItemQuantity = itemQuantity - 1;
      setItemQuantity(newItemQuantity);
      populateitemQuantity(newItemQuantity);
    }
  };

  const handleQuantityIncrement = () => {
    const newItemQuantity = itemQuantity + 1;
    setItemQuantity(newItemQuantity);
    populateitemQuantity(newItemQuantity);
  };

  const renderAlternateBranchesModal = () => {
    const productSkuCode = productId;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={isModalOpened}
        onClose={closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            product={{
              name,
              sku: productSkuCode,
              price: priceUsed,
              brand
            }}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branchesList}
            itemQty={itemQty}
          />
        </div>
      </Modal>
    );
  };

  const shouldRenderAlternateBranchesBtn =
    ecommerceBuyer && !isVirtualBranchUser;

  if (cartError) {
    return (
      <Redirect
        to={{
          pathname: "/maintenance",
          state: {
            error: {
              e: { message: cartError },
              errIn: "ProductAvailability.tsx"
            }
          }
        }}
      />
    );
  }

  return inventoryAvailability &&
    inventoryAvailability.length &&
    !inventoryError ? (
    <>
      {renderAlternateBranchesModal()}
      <div className="add-to-cart-container">
        <div className="form-group qty-selector">
          <div className="input-group-btn">
            <button
              type="button"
              aria-label="subtract one item from your cart"
              className="quantity-left-minus btn btn-number"
              data-type="minus"
              data-field=""
              onClick={handleQuantityDecrement}
            >
              <span className="icon-minus" />
            </button>
            <div className="quantity-col form-content form-content-quantity">
              <input
                id="product_display_quantity_field"
                aria-label="item quantity"
                className="product-display-item-quantity-select form-control form-control-quantity"
                type="number"
                step="1"
                min="1"
                max="9999"
                value={itemQuantity}
                onChange={handleQuantityChange}
              />
            </div>
            <button
              type="button"
              aria-label="add one item from your cart"
              className="quantity-right-plus btn btn-number"
              data-type="plus"
              data-field=""
              onClick={handleQuantityIncrement}
            >
              <span className="icon-plus" />
            </button>
          </div>
        </div>
      </div>
      {shouldRenderAlternateBranchesBtn && (
        <div className="shipping-method-buttons">
          <div className="shipping-method-button-and-label">
            {shouldBtnBeDisabled ? (
              <div className="label-availability">
                <span className="bold-text">
                  {!ecommerceBuyer
                    ? intl.get("contact-admin")
                    : intl.get("stocking-or-entitlement-issue")}
                </span>
                <span>
                  {!ecommerceBuyer
                    ? intl.get("to-place-online-orders")
                    : intl.get("call-branch-to-order")}
                </span>
              </div>
            ) : isSpecialAirPurifier(productId) ? (
              renderSpecialAirPurifierLabel(productId)
            ) : (
              formatPickupOrDeliveryAvailabilityLabel({
                branchAvailability,
                regionAvailability,
                currentBranch: activeBranch,
                isSingleLinedLabel: false,
                isVirtualBranchesUser: isVirtualBranchUser,
                callBranchToOrderButtonIsActive: isCallButtonActive,
                onLabelClick: openAlternateBranchesModal,
                dcAvailability
              })
            )}
            <div className="button-wrapper">
              {pickupBtnIsLoading ? (
                <div className="miniLoader" />
              ) : (
                <button
                  className={
                    !shouldBtnBeDisabled
                      ? "dast-btn dast-btn-primary"
                      : "dast-btn dast-btn-secondary"
                  }
                  type="button"
                  onClick={() => {
                    if (
                      !shouldBtnBeDisabled ||
                      !isSpecialAirPurifier(productId)
                    )
                      handleAddToCartButtonClick("pickup");
                    setTypeOfClickedButon("pickup");
                  }}
                  disabled={
                    shouldBtnBeDisabled ||
                    isSpecialAirPurifier(productId) ||
                    deliveryBtnIsLoading
                  }
                >
                  {intl.get("pick-up-method")}
                </button>
              )}
            </div>
          </div>

          <div className="shipping-method-button-and-label">
            {shouldBtnBeDisabled ? (
              <div className="label-availability">
                <span className="bold-text">
                  {!ecommerceBuyer
                    ? intl.get("contact-admin")
                    : intl.get("stocking-or-entitlement-issue")}
                </span>
                <span>
                  {!ecommerceBuyer
                    ? intl.get("to-place-online-orders")
                    : intl.get("call-branch-to-order")}
                </span>
              </div>
            ) : (
              formatPickupOrDeliveryAvailabilityLabel({
                branchAvailability,
                regionAvailability,
                currentBranch: activeBranch,
                isSingleLinedLabel: false,
                isVirtualBranchesUser: isVirtualBranchUser,
                callBranchToOrderButtonIsActive: isCallButtonActive,
                isDelivery: true
              })
            )}
            <div className="button-wrapper">
              {deliveryBtnIsLoading ? (
                <div className="miniLoader" />
              ) : (
                <button
                  className={
                    !shouldBtnBeDisabled || isSpecialAirPurifier(productId)
                      ? "dast-btn dast-btn-primary"
                      : "dast-btn dast-btn-secondary"
                  }
                  type="button"
                  onClick={() => {
                    if (
                      !shouldBtnBeDisabled ||
                      !isSpecialAirPurifier(productId)
                    )
                      handleAddToCartButtonClick("delivery");
                    setTypeOfClickedButon("delivery");
                  }}
                  disabled={shouldBtnBeDisabled || pickupBtnIsLoading}
                >
                  {intl.get("ship-to-me")}
                </button>
              )}{" "}
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="d-flex h3 align-items-center">
      {inventoryError ? (
        <span>{inventoryError}</span>
      ) : (
        <>
          <span className="label">
            {`${intl.get("checking-availability")}`}
          </span>
          <div className="miniLoader" />
        </>
      )}
    </div>
  );
};

export default ProductAvailability;
