import React from "react";
import { Link } from "react-router-dom";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import { isLinkExternal } from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig } from "../../../utils/ConfigProvider";

import "./call-out.less";

const CallOut = ({ component, page }: BrProps) => {
  const { config } = getConfig();

  const { document: documentRef } = component.getModels();
  const content = documentRef && page.getContent(documentRef);
  if (!content) {
    return null;
  }

  const {
    contentBackgroundColorAndButton,
    contentPosition,
    image,
    imageAltText,
    imageCropLocation,
    header,
    subheader,
    buttonText,
    urlNavigationLink
  } = content.getData();

  const imgMissingHorizontal = config.missingImagePlaceholderUrl;
  const callOutImage = image && page.getContent<ImageSet>(image);

  const contentPositionCaseless = contentPosition.toLowerCase();
  const styleInfo = contentBackgroundColorAndButton.split(" - ");
  const imageCropLocationCaseless = imageCropLocation
    ? imageCropLocation.toLowerCase()
    : null;
  const bgString = styleInfo[0];
  const bgStringBreakdown = bgString.split("#");
  const bgColor =
    bgStringBreakdown[0].replaceAll(" ", "").toLowerCase() || "black";
  const opacityString = styleInfo[1].replaceAll(" ", "");

  const [isExternal, url] = urlNavigationLink
    ? isLinkExternal(urlNavigationLink)
    : isLinkExternal("");

  let opacity = "";
  switch (opacityString) {
    case "20%":
      opacity = "twenty";
      break;
    case "40%":
      opacity = "forty";
      break;
    case "60%":
      opacity = "sixty";
      break;
    case "80%":
      opacity = "eighty";
      break;
    case "100%":
      opacity = "";
      break;
    default:
      opacity = "";
  }
  const buttonColorString = styleInfo[2].replaceAll(" ", "");

  let buttonColor = "";
  switch (buttonColorString) {
    case "bluebutton(primary)":
      buttonColor = "dast-btn-primary";
      break;
    case "blackbutton":
      buttonColor = "dast-btn-tertiary";
      break;
    case "outlinedbutton(nav)":
      buttonColor = "dast-btn-outlined-nav";
      break;
    default:
      buttonColor = "dast-btn-primary";
  }

  let imageAlignClass = "";
  switch (imageCropLocationCaseless) {
    case "left":
      imageAlignClass = "image-justify-left";
      break;
    case "center":
      imageAlignClass = "image-justify-center";
      break;
    case "right":
      imageAlignClass = "image-justify-right";
      break;
    default:
      imageAlignClass = "image-justify-center";
      break;
  }

  return (
    <div className="call-out-container container">
      <div
        className={`${bgColor}${opacity && `-${opacity}`} callout-content ${
          contentPositionCaseless === "right"
            ? `content-${contentPositionCaseless}`
            : ""
        }`}
      >
        <div className="text-container">
          <h2 className="title">{header}</h2>
          {subheader && <p className="subheader">{subheader}</p>}
        </div>
        <div className="button-container">
          <Link
            to={isExternal ? { pathname: url } : url}
            target={!isExternal ? "_self" : "_blank"}
            rel={isExternal ? "noopener noreferrer" : ""}
            className={`dast-btn ${buttonColor}`}
          >
            {buttonText}
          </Link>
        </div>
      </div>
      {image && (
        <div
          className={`call-out-image-wrapper ${
            contentPositionCaseless === "right"
              ? `image-${contentPositionCaseless}`
              : ""
          } ${imageAlignClass}`}
        >
          <img
            className="call-out-image"
            src={callOutImage.getOriginal().getUrl()}
            alt={imageAltText}
            onError={e => {
              const element: any = e.target;
              element.src = imgMissingHorizontal;
            }}
          />
        </div>
      )}
    </div>
  );
};
export default CallOut;
