import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import ArticlesCard from "../../../ArticlesCard/articles-card";

import "./articles.less";

const Articles = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent(documentRef);

  if (!document) {
    return null;
  }
  const { articlesCardList, articlesHeader } = document.getData();

  const setCardsStyle = () => {
    switch (articlesCardList.length) {
      case 0:
        return "";
      case 1:
        return "one-card-container";
      case 2:
        return "two-card-container";
      case 3:
        return "three-card-container";
      default:
        return "";
    }
  };
  const cardsStyle = setCardsStyle();

  const renderCards = () => {
    return (
      <div className={`articles-card-list ${cardsStyle}`}>
        {articlesCardList.map(card => {
          return (
            <ArticlesCard
              image={card.imageLink}
              header={card.header}
              subHeader={card.subHeader}
              buttonText={card.buttonText}
              urlLink={card.urlLink}
              key={`${card.id}-articles-card-${Math.random()}`}
              page={page}
              imageAltText={card.imageAltText}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="articles-container container">
      {articlesHeader && (
        <div className="articles-header">
          <h2>{articlesHeader}</h2>
        </div>
      )}
      {renderCards()}
    </div>
  );
};

export default Articles;
