import React, { useEffect, useState, useContext } from "react";
import { BrComponentContext, BrPageContext } from "@bloomreach/react-sdk";
import DOMPurify from "dompurify";
import "./cookie-acceptance-popup.less";

const CookieAcceptancePopup = () => {
  const component = useContext(BrComponentContext);
  const page = useContext(BrPageContext);

  const [showCookieBanner, setShowCookieBaner] = useState(true);

  useEffect(() => {
    const cookieName = "acceptcookie=true";
    const allCookies = document.cookie.split(";");
    if (allCookies.some(cookie => cookie.includes(cookieName))) {
      setShowCookieBaner(false);
    } else {
      setShowCookieBaner(true);
    }
  }, []);

  const docComponent = component.getComponent(
    "container",
    "cookie-acceptance-popup"
  );

  let contentHtml = "";

  if (docComponent) {
    const { document: documentRef } = docComponent.getModels();
    const content = documentRef && page.getContent(documentRef);
    const myContent = content ? content.getData().content : null;
    if (myContent) {
      contentHtml = page.rewriteLinks(myContent.value);
    } else {
      contentHtml = "";
    }
  }

  const setCookiesAccept = () => {
    const name = "acceptcookie=true";
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `${name}=${""}${expires}; path=/`;
    setShowCookieBaner(false);
  };

  const closeBanner = () => {
    setShowCookieBaner(false);
  };

  return (
    <>
      {document && contentHtml && showCookieBanner && (
        <div className="banner-container">
          <div className="container">
            <div
              className="rich-text-container"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(contentHtml)
              }}
            />
            <button
              onClick={setCookiesAccept}
              type="button"
              className="dast-btn dast-btn-primary"
            >
              Accept
            </button>
            <button
              onClick={closeBanner}
              type="button"
              className="dast-btn-close"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieAcceptancePopup;
