import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ImageSet, Page } from "@bloomreach/spa-sdk";

import "./promo-content-grid-card.less";
import { isLinkExternal } from "@elasticpath/ref-store/src/utils/helpers";

interface PromoContentGridCardInterface {
  header: string;
  subHeader: string;
  image: string;
  imageAltText: string;
  cardLink?: string;
  showRibbon?: boolean;
  ribbonText?: string;
  page?: Page;
}

const PromoContentGridCard: FC<PromoContentGridCardInterface> = ({
  header,
  subHeader,
  image,
  imageAltText,
  cardLink,
  showRibbon,
  ribbonText,
  page
}) => {
  const cardImage = page.getContent<ImageSet>(image);
  const cardImageUrl = cardImage.getOriginal().getUrl();
  const [isExternal, url] = isLinkExternal(cardLink);

  const gridCardContent = (
    <>
      <div className="grid-image-container">
        <img src={cardImageUrl} alt={imageAltText} />
        {showRibbon && <span className="ribbon-text">{ribbonText}</span>}
      </div>
      <h3 className="grid-card-header">{header}</h3>
      <p className="grid-card-subheader">{subHeader}</p>
    </>
  );

  return cardLink ? (
    <Link
      to={isExternal ? { pathname: url } : url}
      target={!isExternal ? "_self" : "_blank"}
      rel={isExternal ? "noopener noreferrer" : ""}
      className="promo-content-grid-card grid-card-hovered"
    >
      {gridCardContent}
    </Link>
  ) : (
    <div className="promo-content-grid-card">{gridCardContent}</div>
  );
};

export default PromoContentGridCard;
