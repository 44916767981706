import React, { FC, useContext } from "react";
import intl from "react-intl-universal";

import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { CatalogPriceResponse } from "../productdisplayitem.interfaces";

interface ProductPriceProps {
  priceDetails: any;
  entitlement: boolean;
}

const ProductPrice: FC<ProductPriceProps> = ({ priceDetails, entitlement }) => {
  const { config }: { config: IEpConfig } = getConfig();
  const context = useContext<{ auth: any; user: any }>(MainContext);
  const {
    auth: { isLoggedIn },
    user: {
      userProfile: { ecommerceBuyer }
    }
  } = context;
  const { calculatePrice } = config;
  const displayPrice = isLoggedIn && calculatePrice;
  const priceToShow =
    entitlement &&
    (priceDetails &&
      priceDetails.lineTotal &&
      priceDetails.lineTotal !== intl.get("pending")) &&
    ecommerceBuyer
      ? `$${priceDetails.lineTotal}`
      : intl.get("pending");
  return (
    displayPrice &&
    (priceDetails ? (
      <div className="product-details-page-price">{priceToShow}</div>
    ) : (
      <div className="product-details-page-miniLoader">
        <div className="miniLoader" />
      </div>
    ))
  );
};
export default ProductPrice;
