import React, { useState } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import NotificationBanner from "../NotificationBanner/notificationbanner";

const AppNotificationBanner = ({ component, page }: BrProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const { document: ref } = component.getModels();
  const content = ref && page.getContent(ref);
  if (!content) return null;
  const { message, staticdropdown, urllink, alignment } = content.getData();
  const closeContainerHandler = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <NotificationBanner
        isOpen={isOpen}
        closeContainerHandler={closeContainerHandler}
        message={message}
        typeOfBanner={staticdropdown}
        link={urllink}
        alignment={alignment}
      />
    </>
  );
};
export default AppNotificationBanner;
