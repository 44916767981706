import React, { FC, useContext } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import intl from "react-intl-universal";

import { MainContext } from "../../../app/src/contexts/MainContext";

import "./search.no.results.less";

interface SearchNoResultsProps {
  branchesFilter?: any;
}

const SearchNoResults: FC<SearchNoResultsProps> = ({ branchesFilter }) => {
  const context = useContext<{ navigation: any; branches: any }>(MainContext);
  const {
    branches: { branchesList },
    navigation: { navigation }
  } = context;

  const location = useLocation();
  const matchCategoryPath = matchPath(location.pathname, {
    path: "/category*",
    exact: false,
    strict: false
  });
  const renderNoResultsMessage = () => {
    let noResultsMessage;
    if (branchesFilter && branchesFilter.length === 1) {
      const selectedBranchNumber = branchesFilter[0].branchNumber;
      const selectedBranchName = branchesList.find(
        branch => branch.branchNumber === selectedBranchNumber
      ).branchName;
      noResultsMessage = `${intl.get("no-results-at")} ${selectedBranchName}`;
    } else if (branchesFilter && branchesFilter.length > 1) {
      noResultsMessage = intl.get("no-results-in-your-region");
    } else {
      noResultsMessage = intl.get("no-search-results");
    }
    return noResultsMessage;
  };

  /** We are rendering links for all subcategories of Equpment cateogry and link for Parts and Accessories category  */
  return (
    <div className="search-no-results">
      <h2 className="no-search-result-title" id="no_search_result">
        {renderNoResultsMessage()}
      </h2>
      <div className="suggestions">
        {!matchCategoryPath && (
          <div className="search-tips">
            <h2 className="search-tips-title">{intl.get("search-tips")}</h2>
            <ul className="search-tips-list">
              <li>{intl.get("search-tip-1")}</li>
              <li>{intl.get("search-tip-2")}</li>
              <li>{intl.get("search-tip-3")}</li>
            </ul>
          </div>
        )}
        <div className="category-suggestions">
          <h2 className="category-suggestions-title">
            {intl.get("browse-by-category")}
          </h2>
          {navigation && (
            <ul className="category-suggestions-list">
              {Object.entries(navigation).map(
                (category: [string, any]) =>
                  category[0] === "Equipment" &&
                  Object.entries(category[1]).map(
                    (subCategory: [string, any]) =>
                      subCategory[0] !== "name" &&
                      subCategory[0] !== "show" && (
                        <li key={subCategory[0]}>
                          <Link
                            to={{
                              pathname: `/categorylanding/${subCategory[1].name}`,
                              state: { name: subCategory[0] }
                            }}
                          >
                            {subCategory[0]}
                          </Link>
                        </li>
                      )
                  )
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchNoResults;
