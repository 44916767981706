import React, { FC, useContext } from "react";
import Modal from "react-responsive-modal";
import { withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import { renderStorefrontUrl } from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./appheaderpermissiondenied.main.less";

interface AppHeaderPermissionDeniedProps extends RouteComponentProps {
  openModal: boolean;
}

const AppHeaderPermissionDenied: FC<AppHeaderPermissionDeniedProps> = ({
  openModal
}) => {
  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    job: any;
    account: any;
  }>(MainContext);
  const {
    account: {
      accountDetails: { companyAffiliation }
    }
  } = context;
  const {
    auth: { logout }
  } = context;

  const { config } = getConfig();
  const { siteUrl } = config;

  const renderPermissionDeniedMsg = () => {
    const dealerSiteUrl = renderStorefrontUrl(companyAffiliation);
    return (
      <p>
        {intl.get("permission-denied-message-pt1")}
        {siteUrl}. {intl.get("permission-denied-message-pt2")}
        <a
          href={`https://${dealerSiteUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {dealerSiteUrl}
        </a>
        {intl.get("permission-denied-message-pt3")}
      </p>
    );
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={logout}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div className="modal-lg">
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">
                {intl.get("change-carts-permission-denied")}
              </h2>
            </div>

            <div className="modal-body">
              <div id="cart_selection_modal_form">
                <div className="carts-selection-region permission-denied-container">
                  {renderPermissionDeniedMsg()}
                </div>

                <div className="action-row">
                  <div className="form-input btn-container">
                    <button
                      onClick={logout}
                      className="dast-btn-primary dast-btn wide"
                      id="continue_with_cart_button"
                      data-cmd="continue"
                      type="submit"
                    >
                      {intl.get("change-carts-ok")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(AppHeaderPermissionDenied);
