/* eslint-disable prettier/prettier */
/* eslint-disable class-methods-use-this */
import { getConfig, cortexFetch } from "@zilker/store-components";
import { httpGet } from "./HttpService";
import { checkResponse } from "../utils/helpers";

const _retainedTokens = { EP: null, DK: null };

/* eslint-disable camelcase */
export interface PublicUserDetailsInterface {
  username?: string;
  password?: string;
  grant_type?: string;
  role?: string;
  scope?: string;
  code?: string;
  redirect_uri?: string;
  client_id?: string;
}

/**
 * @member {function}
 * @desc Generate POST form body.
 */
const _generateFormBody = userDetails => {
  const userFormBody = [];
  let userFormBodyString = "";
  Object.keys(userDetails).forEach(encodedKey => {
    const encodedValue = userDetails[encodedKey];
    userFormBody.push(`${encodedKey}=${encodedValue}`);
  });
  userFormBodyString = userFormBody.join("&");

  return userFormBodyString;
};

/**
 * @member {function}
 * @desc Checks if tokens for EP is in localStorage.
 */
export const checkEPTokenRetained = () => {
  return !!_retainedTokens.EP;
};

/**
 * @member {function}
 * @desc Checks if tokens for DK is in localStorage.
 */
export const checkDKTokenRetained = () => {
  return !!_retainedTokens.DK;
};

/**
 * @member {function}
 * @desc Request tokens for EP.
 */
export const getEPTokens = () => {
  if (checkEPTokenRetained()) {
    return _retainedTokens.EP;
  }

  return new Promise((resolve, reject) => {
    const publicUserDetails: PublicUserDetailsInterface = {
      username: "",
      password: "",
      grant_type: "password",
      role: "PUBLIC",
      scope: getConfig().config.cortexApi.scope
    };

    cortexFetch("/oauth2/tokens", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      },
      body: _generateFormBody(publicUserDetails)
    })
      .then(res => checkResponse(res))
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};

/**
 * @member {function}
 * @desc Request tokens for Daikin.
 */
export const getDKTokens = (
  credentials = { username: "", password: "" }
): Promise<any> => {
  const { username, password } = credentials;

  // if (checkDKTokenRetained()) {
  //   return Promise.resolve(_retainedTokens.DK);
  // }

  const endpoint = `${getConfig().config.DaikinApi.baseUri} ${
    getConfig().config.DaikinApi.path
  }?${getConfig().config.DaikinApi.query}`;

  return new Promise((resolve, reject) => {
    httpGet(endpoint)
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
};

/**
 * @member {function}
 * @desc Set tokens in class and browser storage for EP.
 */
export const setEPTokens = (tokens, registeredUserDetails?) => {
  if (!tokens) {
    return;
  }

  const { config } = getConfig();
  _retainedTokens.EP = tokens;

  localStorage.setItem(`${config.cortexApi.scope}_oAuthRole`, tokens.role);
  localStorage.setItem(`${config.cortexApi.scope}_oAuthScope`, tokens.scope);
  localStorage.setItem(
    `${config.cortexApi.scope}_oAuthToken`,
    `Bearer ${tokens.access_token}`
  );

  if (registeredUserDetails) {
    localStorage.setItem(
      `${config.cortexApi.scope}_oAuthUserName`,
      registeredUserDetails.username
    );
  }
};

/**
 * @member {function}
 * @param {object} tokens
 * @desc Sets token in class and browser storage for Daikin.
 */
export const setDKTokens = token => {
  _retainedTokens.DK = token;

  localStorage.setItem("DK_oAuthToken", `Bearer ${token}`);
};
