import React, { useEffect, useState, useLayoutEffect } from "react";
import { BrProps, BrManageContentButton } from "@bloomreach/react-sdk";
import { ImageSet, Document, Pagination } from "@bloomreach/spa-sdk";
import $ from "jquery";

import "./enhanced-list.less";
import EnhancedListCarouselTitle from "./enhanced-list.carousel-title";
import EnhancedListGrid from "./enhanced-list.grid";

const EnhancedList = ({ component, page }: BrProps) => {
  const { pageable } = component.getModels();
  const { displayMode } = component.getProperties();
  if (!pageable || pageable.items.length < 1) {
    return <>No items in Enhanced List</>;
  }

  const isSecondaryCarousel = displayMode === "carousel_titled_secondary";

  const renderCarouselType = () => {
    switch (displayMode) {
      case "carousel_titled":
        return <EnhancedListCarouselTitle component={component} page={page} />;
      case "carousel_titled_secondary":
        return <EnhancedListCarouselTitle component={component} page={page} />;
      case "grid":
        return <EnhancedListGrid component={component} page={page} />;
      default:
        return <span>Default List</span>;
    }
  };

  return (
    <div
      className={`enhanced-list-container${
        isSecondaryCarousel ? " secondary-carousel" : ""
      }`}
    >
      {renderCarouselType()}
    </div>
  );
};

export default EnhancedList;
