import React, { useState, useEffect } from "react";

// eslint-disable-next-line import/prefer-default-export
export const NavigationContext = () => {
  const [navigation, setNavigation] = useState(null);
  const [categoriesMap, setCategoriesMap] = useState(null);

  useEffect(() => {
    if (navigation) {
      getCategoriesMap();
    }
  }, [navigation]);

  function getCategoriesMap() {
    const categories = new Map();
    Object.values(navigation).forEach(category =>
      Object.entries(category).forEach(([key, value]) => {
        if (key !== "name" && key !== "show") {
          categories.set(key, value);
        }
      })
    );
    setCategoriesMap(categories);
  }

  return {
    navigation,
    setNavigation,
    categoriesMap
  };
};
