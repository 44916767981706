import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { AhriSelector } from "@goodman-cloud/daikin-common-components";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import {
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage,
  findProsBrand,
  updateBranchNumber
} from "@elasticpath/ref-store/src/utils/helpers";
import { productAdded, productViewed } from "@zilker/store-components";
import "./SystemBuilderDCS.less";

const SystemBuilderDCS: FC<{}> = () => {
  const context = useContext<{
    auth: any;
    cart: any;
    user: any;
    branches: any;
    account: any;
  }>(MainContext);
  const {
    auth: { isLoggedIn, logout },
    account: {
      accountDetails: { membership, customerNumber }
    }
  } = context;
  const { config }: { config: IEpConfig } = getConfig();
  const { connectServicesToken, environment } = config;

  const [branch, setBranch] = useState<string>("");
  const [jobNum, setJobNum] = useState<string>("");
  const [pickupButtonIsLoading, setPickupButtonIsLoading] = useState<boolean>(
    false
  );
  const [deliveryButtonIsLoading, setDeliveryButtonIsLoading] = useState<
    boolean
  >(false);

  const history = useHistory();

  const {
    cart: {
      cartDetails: { defaultCart }
    }
  } = context;

  useEffect(() => {
    if (defaultCart) {
      const { selectedBranch, jobNumber } = defaultCart;
      if (jobNum !== jobNumber) {
        const jobString = jobNumber === null ? "" : jobNumber;
        setJobNum(jobString);
      }
      if (branch !== selectedBranch.code) {
        setBranch(selectedBranch.code);
      }
    }
  }, [defaultCart]);

  const onAddToCart = (orderItems, shippingMethod) => {
    if (isLoggedIn) {
      const itemQuantity = orderItems.reduce(
        (total, current) => total + Number(current.quantity),
        0
      );
      const {
        cart: {
          cartDetails: {
            defaultCart: { addItemsToCart }
          },
          getCartDetails,
          setSuccesCartPopupMessage,
          setErrorCartPopupMessage
        }
      } = context;
      if (shippingMethod === "delivery") {
        setDeliveryButtonIsLoading(true);
      } else if (shippingMethod === "pickup") {
        setPickupButtonIsLoading(true);
      }

      addToCart(addItemsToCart.self.uri, { items: orderItems })
        .then(res => {
          orderItems.forEach(item => {
            // sends information to Segment when user views a product
            productViewed(item.name, item.id, item.price, "", "");
            // sends information to Segment for every product user adds
            productAdded(item.name, item.id, item.price, "", "", item.quantity);
          });
        })
        .then(() => {
          return getCartDetails();
        })
        .then(() => {
          setDeliveryButtonIsLoading(false);
          setPickupButtonIsLoading(false);
          setSuccesCartPopupMessage(itemQuantity);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => addToCart => OrderHistoryDetailsPage.tsx"
              })
            );
          }
          setDeliveryButtonIsLoading(false);
          setPickupButtonIsLoading(false);
          setErrorCartPopupMessage(generateSpecificErrorMessage(e));
        });
    }
  };

  const ahriAddToCartCallback = (e: any) => {
    if (e) {
      const {
        branches: {
          airPurifierBranch: { branchNumber: airPurifierBranchNumber }
        },
        account: {
          accountDetails: { homeBranch }
        }
      } = context;
      // Note: Cortex expects to receive the AHRI Number as ahri-number. Hence the updating below.
      const ahriNumber = e["ahri-number"];
      const shippingMethod = e["shipping-method"];
      const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

      onAddToCart(
        e.selectedProductSkus.map(systemProduct => ({
          ...systemProduct,
          "ahri-number": ahriNumber,
          "shipping-method": shippingMethod,
          quantity: 1,
          "branch-number": updateBranchNumber(
            shippingMethod === "delivery",
            ahriNumber,
            airPurifierBranchNumber,
            homeBranch,
            selectedBranch.code
          )
        })),
        shippingMethod
      );
    }
  };

  const updatedBrands = () => {
    const brands = membership.map(brand => {
      switch (brand.toLowerCase()) {
        case "dcp":
          return "Daikin";
        case "amanaadv":
          return "Amana";
        case "aplusplatinum":
        case "goodman":
        case "privatelabel":
          return "Goodman";
        default:
          return "";
      }
    });

    const goodmanFound = brands.find(
      brand => brand.toLowerCase() === "goodman"
    );
    if (!goodmanFound) {
      brands.push("Goodman");
    }
    const filteredBrands = brands;
    return filteredBrands;
  };

  const fetchBrand = () => {
    const { prosBrand } = findProsBrand(membership);
    return prosBrand;
  };

  return (
    <div className="container system-builder-container">
      {membership && membership.length && branch ? (
        <AhriSelector
          env={environment}
          apiToken={connectServicesToken}
          brand={fetchBrand()}
          branch={branch}
          customerNumber={customerNumber}
          addToCart={ahriAddToCartCallback}
          entitledBrands={updatedBrands()}
          jobNumber={jobNum}
          pickupButtonIsLoading={pickupButtonIsLoading}
          deliveryButtonIsLoading={deliveryButtonIsLoading}
        />
      ) : null}
    </div>
  );
};

export default SystemBuilderDCS;
