/**
 * Refactored component from productdisplayitem.main.tsx. This component handles loading the dynamic
 * PDP Components that load in below the fold. These components are driven by the ep.config.json files
 * and control the order that they are inserted onto the page.
 */
import React, { FC, Ref, useContext, useEffect, useState } from "react";
import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { RecommendedProductsWidget } from "@zilker/store-components";

import ProductRecommendationsDisplayMain from "../../ProductRecommendations/productrecommendations.main";
import ProductDetailTabsComponent from "../../ProductDetailTabsComponent/ProductDetailTabsComponent";
import { ItemDescription } from "../productdisplayitem.interfaces";
import {
  Metadata,
  Doc,
  ItemWidgetResponse
} from "../../../../models/BloomreachPathsAndRecommResponse";
import { getItemWidget } from "../../../../app/src/services/PathwaysAndRecommendationsService";

interface ProductLongDescriptionProps {
  productData: any;
  productDocuments: any;
  productId: string;
  titleRef: Ref<HTMLDivElement>;
  productDetailsTabsRef: Ref<HTMLDivElement>;
  location: any;
}

const PDPComponents: FC<ProductLongDescriptionProps> = ({
  productData,
  productDocuments,
  productId,
  titleRef,
  productDetailsTabsRef,
  location
}) => {
  const [recommendedProducts, setRecommendedProducts] = useState<Array<Doc>>(
    []
  );
  const [widgetMetaData, setWidgetMetaData] = useState<Metadata>(null);

  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
    account: any;
  }>(MainContext);
  const {
    auth: { isLoggedIn },
    account: {
      accountDetails: { customerNumber }
    },
    cart: {
      cartDetails: { defaultCart }
    }
  } = context;
  const hasUserProfileLoaded = !!(
    defaultCart &&
    defaultCart.selectedBranch &&
    customerNumber
  );
  const { config }: { config: IEpConfig } = getConfig();
  const { state } = location;

  const fetchCustomersAlsoPurchasedData = async sku => {
    const {
      branches: { branchesList },
      account: {
        accountDetails: { membership }
      }
    } = context;

    if (!sku) {
      setRecommendedProducts([]);
      setWidgetMetaData(null);
      return;
    }

    const branchIds =
      branchesList && branchesList.map(branch => `"${branch.branchNumber}"`);
    try {
      const url = state ? state.prevUrl : "";
      const type = "recommendedProductsPDP";
      const { data }: { data: ItemWidgetResponse } = await getItemWidget(
        sku,
        window.location.href,
        url,
        membership,
        type,
        branchIds
      );
      const {
        response: { docs },
        metadata
      } = data;
      setRecommendedProducts(docs);
      setWidgetMetaData(metadata);
    } catch (error) {
      console.error(error);
    }
  };

  const renderPDPLayout = (component: string) => {
    switch (component) {
      case "recommended-products":
        return (
          <ProductRecommendationsDisplayMain
            productDetails={generateProductDetails()}
            titleRef={titleRef}
            key="recommended-products"
            productDataDetails={productData._definition[0].details}
          />
        );
      case "tabs":
        return (
          <ProductDetailTabsComponent
            productData={productData}
            productDocuments={productDocuments}
            key="tabs"
            ref={productDetailsTabsRef}
          />
        );

      case "customers-also-purchased":
        return config.showRecommendedProducts &&
          recommendedProducts &&
          recommendedProducts.length ? (
          <RecommendedProductsWidget
            key="customers-also-purchased"
            products={recommendedProducts}
            metadata={widgetMetaData}
            isPDP
          />
        ) : null;

      default:
        return null;
    }
  };

  /**
   * ## generateProductDetails
   * @description Extracts title, brand and SKU from product data.
   */
  const generateProductDetails = () => {
    const result = {
      title: "",
      brand: "",
      sku: productId
    };

    if (productData) {
      const productDetails = productData._definition
        ? productData._definition[0].details
        : [];
      if (productDetails.length) {
        const productTitle = productData._definition
          ? productData._definition[0]["display-name"]
          : "";

        const productBrand = productDetails.find(
          (detail: ItemDescription) => detail.name === "brand"
        );

        if (productTitle && productBrand) {
          result.title = productTitle;
          result.brand = productBrand.value;
        }
      }
    }

    return result;
  };

  useEffect(() => {
    if (!recommendedProducts || !recommendedProducts.length) {
      fetchCustomersAlsoPurchasedData(productId);
    }
  }, [productId, recommendedProducts.length, state]);

  let result;
  if (isLoggedIn) {
    result = hasUserProfileLoaded ? (
      config.pdpComponentsOrder.map(component => renderPDPLayout(component))
    ) : (
      <div className="miniLoader" />
    );
  } else {
    result = config.pdpComponentsOrder.map(component =>
      renderPDPLayout(component)
    );
  }
  return <>{result}</>;
};

export default PDPComponents;
