import React, { FC, useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";
import {
  generateSpecificErrorMessage,
  InventoryAvailabilityInterface,
  updateBranchNumber,
  formatQuoteAvailability
} from "@elasticpath/ref-store/src/utils/helpers";
import { addToCart } from "@elasticpath/ref-store/src/services/EpServices";
import {
  EntitlementDetails,
  PriceDetails
} from "@elasticpath/ref-store/src/containers/PartsFinderPage";
import {
  priceCatalogGQL,
  getAvailability,
  checkEntitlementGQL
} from "@elasticpath/ref-store/src/services/connectGQLservices";
import {
  productAdded,
  productClicked,
  productViewed,
  getConfig
} from "@zilker/store-components";
import { SingleProduct } from "@elasticpath/ref-store/src/utils/searchUtils";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import MultipleItemsCarousel from "../MultipleItemsCarousel/MultipleItemsCarousel";
import {
  Doc,
  Metadata
} from "../../../models/BloomreachPathsAndRecommResponse";
import SearchResultsItem from "../SearchResultsPage/search.results.item";
import CarouselArrowButton from "../CarouselArrowbutton/carouselarrowbutton";
import "./RecommendedProductsWidget.less";

declare let BrTrk: any;
interface RecommendedProductsWidgetInterface {
  products: Doc[];
  metadata: Metadata;
  isPDP?: boolean;
}

const RecommendedProductsWidget: FC<RecommendedProductsWidgetInterface> = ({
  products,
  metadata,
  isPDP
}) => {
  const [productsWithDetails, setProductsWithDetails] = useState<
    Array<SingleProduct>
  >(products);
  const [prices, setPrices] = useState<Array<PriceDetails>>(null);
  const [inventory, setInventory] = useState<
    Array<InventoryAvailabilityInterface>
  >(null);
  const [entitlements, setEntitlements] = useState<Array<EntitlementDetails>>(
    null
  );
  const [addCartButtonIds, setAddCartButtonsIds] = useState<Array<string>>([]);
  const context = useContext<{
    user: any;
    cart: any;
    branches: any;
    account: any;
    auth: any;
  }>(MainContext);

  const {
    auth: { isLoggedIn }
  } = context;

  const Config = getConfig().config;
  useEffect(() => {
    if (metadata) {
      trackWidgetViewEvent();
    }
  }, [metadata]);

  useEffect(() => {
    if (
      products.map(({ pid }) => pid).join() !==
      productsWithDetails.map(({ pid }) => pid).join()
    ) {
      setProductsWithDetails(products);
      setPrices(null);
      setEntitlements(null);
      setInventory(null);
      trackWidgetViewEvent();
    }
  }, [products]);

  useEffect(() => {
    if (isLoggedIn) {
      if (inventory && entitlements && !prices) {
        fetchPrices();
      }
      if (!entitlements) fetchEntitlement();
    }
  }, [productsWithDetails]);

  useEffect(() => {
    if (isLoggedIn) {
      if (!inventory) fetchInventory();
    }
  }, [inventory]);

  useEffect(() => {
    if (prices) updateProductDetails(prices);
  }, [prices]);

  useEffect(() => {
    if (inventory) updateProductDetails(inventory, true);
  }, [inventory]);

  useEffect(() => {
    if (entitlements) updateProductDetails(entitlements);
  }, [entitlements]);

  const fetchPrices = async () => {
    const {
      account: {
        accountDetails: { customerNumber }
      },
      cart: {
        cartDetails: { defaultCart }
      }
    } = context;

    const { selectedBranch } = defaultCart;

    const priceRequestBody = {
      customerNumber,
      shipments: [
        {
          branchNumber: selectedBranch.code,
          customerPickup: false,
          items: products.map(product => ({
            sku: product.pid,
            quantity: 1
          }))
        }
      ],
      jobNumber: null
    };
    try {
      const { data } = await priceCatalogGQL(priceRequestBody);
      setPrices(data.data.quote.shipments[0].items);
    } catch (err) {
      console.error(err);
      setPrices([]);
    }
  };

  const fetchInventory = async () => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      account: {
        accountDetails: { customerNumber }
      }
    } = context;

    const skus = productsWithDetails.map(product => product.pid);
    const { selectedBranch } = defaultCart;

    try {
      const branchNumber = selectedBranch.code;

      const { data } = await getAvailability(
        skus,
        customerNumber,
        branchNumber
      );
      if (
        data &&
        data.data &&
        data.data.inventory &&
        data.data.inventory.entitledInventory
      ) {
        const availabilityData = data.data.inventory.entitledInventory;
        const availability: Array<
          InventoryAvailabilityInterface
        > = formatQuoteAvailability(
          availabilityData.branches,
          availabilityData.regionRollups,
          skus,
          availabilityData.hubInventory
        );
        setInventory(availability);
      }
    } catch (err) {
      console.error(err);
      setInventory([]);
    }
  };

  const fetchEntitlement = async () => {
    const {
      account: {
        accountDetails: { customerNumber }
      }
    } = context;
    const skus = productsWithDetails.map(product => product.pid);
    try {
      const { data } = await checkEntitlementGQL(customerNumber, skus);
      if (data && data.data && data.data.customer) {
        if (!data.data.customer.productEntitlements) {
          setEntitlements([]);
        } else {
          setEntitlements(data.data.customer.productEntitlements);
        }
      }
    } catch (err) {
      console.error(err);
      setEntitlements([]);
    }
  };

  const updateProductDetails = (details, isInventory = false) => {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = context;
    const { selectedBranch } = defaultCart;
    const checkInventory = detail =>
      isInventory ? detail.branchNumber === selectedBranch.code : true;
    const result = productsWithDetails.map(product => {
      const productDetails =
        details.find(
          detail => detail.sku === product.pid && checkInventory(detail)
        ) || {};
      return {
        ...product,
        productPrice: product.productPrice || productDetails.lineTotal || 0,
        entitled: product.entitled || productDetails.entitled || false,
        branchAvailability:
          product.branchAvailability || productDetails.branchAvailability || 0,
        regionAvailability:
          product.regionAvailability || productDetails.regionAvailability || 0,
        dcAvailability:
          product.dcQtyAvailable || productDetails.dcQtyAvailable || 0
      };
    });
    setProductsWithDetails(result);
  };

  const onAddToCart = (e, shippingMethod: "pickup" | "delivery", item) => {
    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      },
      cart: {
        cartDetails: { defaultCart }
      },
      account: {
        accountDetails: { homeBranch }
      }
    } = context;
    const { selectedBranch } = defaultCart;

    let activeBranch;
    if (defaultCart) {
      activeBranch = selectedBranch.code;
    }

    const code = e.target.id;
    const items = [
      {
        code,
        quantity: 1,
        "shipping-method": shippingMethod,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          code,
          airPurifierBranchNumber,
          homeBranch,
          activeBranch
        )
      }
    ];

    setAddCartButtonsIds(addCartButtonIds.concat(code));

    const {
      cart: {
        setSuccesCartPopupMessage,
        setErrorCartPopupMessage,
        getCartDetails,
        cartDetails: {
          defaultCart: { addItemsToCart }
        }
      }
    } = context;

    addToCart(addItemsToCart.self.uri, { items })
      .then(() => getCartDetails())
      .then(() => {
        setSuccesCartPopupMessage(1);
        setAddCartButtonsIds(prevState =>
          prevState.filter(buttonId => buttonId !== code)
        );
        trackWidgetAddToCartEvent(code);
        productViewed(
          item.title,
          item.pid,
          item.productPrice,
          item.brand,
          null
        );
        productAdded(
          item.title,
          item.pid,
          item.productPrice,
          item.brand,
          null,
          1
        );
      })
      .catch(err => {
        console.error(err);
        setAddCartButtonsIds(prevState =>
          prevState.filter(buttonId => buttonId !== code)
        );
        setErrorCartPopupMessage(generateSpecificErrorMessage(err));
      });
  };

  const trackWidgetViewEvent = () => {
    const {
      widget: { rid, id, type }
    } = metadata;
    const widgetWiewData = {
      wrid: rid,
      wid: id,
      wty: type
    };

    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-view",
        widgetWiewData,
        true
      );
  };

  const trackWidgetClickEvent = (item: SingleProduct) => {
    const {
      widget: { rid, id, type }
    } = metadata;
    const widgetWiewData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: item.pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-click",
        widgetWiewData,
        true
      );
  };

  const trackWidgetAddToCartEvent = (pid: string) => {
    const {
      widget: { rid, id, type }
    } = metadata;

    const widgetAtcData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: pid,
      sku: pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent("cart", "widget-add", widgetAtcData);
  };

  const customizedSetting = {
    arrows: productsWithDetails.length > 4,
    infinite: false,
    nextArrow: <CarouselArrowButton />,
    prevArrow: <CarouselArrowButton />,
    dots: false,
    scrollNumber: 1
  };

  return (
    <div
      className={`${
        isPDP ? "silder-container-product-recomendations" : ""
      } recommended-products-widget`}
    >
      <h1 className="dast-product-carousel-header">
        {intl.get("customers-also-purchased")}
      </h1>
      <div className="silder-container dast-product-carousel">
        {productsWithDetails.length && (
          <MultipleItemsCarousel
            items={productsWithDetails}
            numOfItems={productsWithDetails.length}
            carouselConfig={Config["cart-recommended-products-carousel"]}
            customizedSetting={customizedSetting}
            displayComponent={item => (
              <SearchResultsItem
                item={item}
                onAddToCart={onAddToCart}
                key={item.pid}
                clickedButtonLoading={addCartButtonIds.includes(item.pid)}
                onClick={clickedItem => {
                  trackWidgetClickEvent(clickedItem);
                  productClicked(
                    clickedItem.title,
                    clickedItem.pid,
                    clickedItem.productPrice,
                    clickedItem.brand,
                    null
                  );
                }}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendedProductsWidget;
