/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { Link } from "react-router-dom";
import { getConfig } from "../utils/ConfigProvider";

import "./productlistpagination.less";

let searchUrlVar = true;
let intl = { get: str => str };

interface ProductListPaginationProps {
  paginationDataProps: { [key: string]: any };
  titleString: string;
  isTop?: boolean;
  productListPaginationLinks?: { [key: string]: any };
}

interface ProductListPaginationState {
  paginationData: { [key: string]: any };
  paginationPreviousLink: string;
  paginationNextLink: string;
  searchUrl: boolean;
}

class ProductListPagination extends React.Component<
  ProductListPaginationProps,
  ProductListPaginationState
> {
  constructor(props) {
    super(props);
    const { paginationDataProps } = this.props;
    ({ intl } = getConfig());
    this.state = {
      paginationData: paginationDataProps,
      paginationPreviousLink: "",
      paginationNextLink: "",
      searchUrl: true
    };
  }

  componentDidMount() {
    let paginationPreviousLinkVar = "";
    let paginationNextLinkVar = "";
    searchUrlVar = true;
    const { paginationData } = this.state;
    if (paginationData.self.type.includes("navigation")) {
      searchUrlVar = false;
    }
    for (let i = 0; i < paginationData.links.length; i++) {
      if (paginationData.links[i].rel === "previous") {
        paginationPreviousLinkVar = paginationData.links[i].uri;
      }
      if (paginationData.links[i].rel === "next") {
        paginationNextLinkVar = paginationData.links[i].uri;
      }
    }
    this.setState({
      paginationPreviousLink: paginationPreviousLinkVar,
      paginationNextLink: paginationNextLinkVar,
      searchUrl: searchUrlVar
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { paginationPreviousLink, paginationNextLink } = prevState;
    let previousLink = "";
    let nextLink = "";
    for (let i = 0; i < nextProps.paginationDataProps.links.length; i++) {
      if (nextProps.paginationDataProps.links[i].rel === "previous") {
        previousLink = nextProps.paginationDataProps.links[i].uri;
      }
      if (nextProps.paginationDataProps.links[i].rel === "next") {
        nextLink = nextProps.paginationDataProps.links[i].uri;
      }
    }
    if (
      paginationPreviousLink !== previousLink &&
      paginationNextLink !== nextLink
    ) {
      return {
        paginationPreviousLink: previousLink,
        paginationNextLink: nextLink
      };
    }
    return null;
  }

  render() {
    const {
      paginationNextLink,
      paginationPreviousLink,
      searchUrl
    } = this.state;
    const {
      isTop,
      titleString,
      productListPaginationLinks,
      paginationDataProps: { pagination }
    } = this.props;
    const { productSearch, productCategory } = productListPaginationLinks;
    if (pagination) {
      const urlPrefix = searchUrl ? productSearch : productCategory;
      return (
        <div
          className="product-list-pagination-component"
          data-region="categoryPaginationRegion"
          style={{ display: "block" }}
        >
          {/* {isTop ? (
            <div className="total-results">
              <span className="total-results-value">
                {intl.get("viewing")}
                &nbsp;
                {paginationData.pagination["results-on-page"]}
              </span>
              &nbsp;
              <span className="results-displayed-value">
                {intl.get("of")}
                &nbsp;
                {paginationData.pagination.results}
                &nbsp;
                {intl.get("products")}
              </span>
            </div>
          ) : (
            ""
          )} */}
          {!isTop &&
          (paginationNextLink !== "" || paginationPreviousLink !== "") ? (
            <div className="pagination-navigation-container">
              {paginationPreviousLink !== "" ? (
                <Link
                  to={`${urlPrefix}/${titleString}${paginationPreviousLink}`}
                  className="btn-pagination prev"
                  role="button"
                >
                  <span className="icon" />
                  {intl.get("previous")}
                </Link>
              ) : (
                <div className="btn-pagination prev hide" />
              )}
              <span className="pagestate-summary">
                <label
                  htmlFor="pagination_curr_page_label"
                  className="pagination-label"
                >
                  {intl.get("page")}
                  &nbsp;
                </label>
                <span className="pagination-value pagination-curr-page-value">
                  {pagination.current}
                </span>
                <label
                  htmlFor="pagination_total_pages_label"
                  className="pagination-label"
                >
                  &nbsp;
                  {intl.get("of")}
                  &nbsp;
                </label>
                <span className="pagination-value pagination-total-pages-value">
                  {pagination.pages}
                </span>
              </span>
              {paginationNextLink !== "" ? (
                <Link
                  to={`${urlPrefix}/${titleString}${paginationNextLink}`}
                  className="btn-pagination next"
                  role="button"
                >
                  {intl.get("next")}
                  <span className="icon" />
                </Link>
              ) : (
                <div className="btn-pagination next hide" />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }

    return <div className="loader" />;
  }
}

export default ProductListPagination;
