import React, { useEffect, useState, useLayoutEffect } from "react";
import { BrProps, BrManageContentButton } from "@bloomreach/react-sdk";
import { ImageSet, Document, Pagination } from "@bloomreach/spa-sdk";
import $ from "jquery";

import "./generic-list.less";
import RichText from "../RichText/rich-text";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const GenericList = ({ component, page }: BrProps) => {
  const [width, height] = useWindowSize();
  const { pageable } = component.getModels();
  const params = component.getProperties();

  const contents = pageable.items.map(reference => {
    const document = page.getContent<Document>(reference);
    return document.getData();
  });

  if (!contents) {
    return <span>No content items</span>;
  }

  const mobileWidth = 768;
  const tabletWidth = 1024;

  const chunks = [];
  let chunkSize = 4; // Full size browser items per page
  if (width <= mobileWidth) {
    chunkSize = 1; // Mobile
  } else if (width <= tabletWidth) {
    chunkSize = 3; // Tablet
  }

  while (contents.length) {
    chunks.push(contents.splice(0, chunkSize));
  }

  const renderCarouselControls = () => {
    return (
      <>
        <a
          className="carousel-control-prev"
          href={`#carousel-title-${component.getId()}`}
          role="button"
          aria-label="previous slide"
          data-slide="prev"
        >
          <svg
            className="bi bi-chevron-left"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M13.354 3.646a.5.5 0 010 .708L7.707 10l5.647 5.646a.5.5 0 01-.708.708l-6-6a.5.5 0 010-.708l6-6a.5.5 0 01.708 0z"
              clipRule="evenodd"
            />
          </svg>
        </a>
        <a
          className="carousel-control-next"
          href={`#carousel-title-${component.getId()}`}
          role="button"
          aria-label="next slide"
          data-slide="next"
        >
          <svg
            className="bi bi-chevron-right"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M6.646 3.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L12.293 10 6.646 4.354a.5.5 0 010-.708z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </>
    );
  };

  const renderText = item => {
    if (item.content && item.content !== "") {
      return <RichText content={item.content} />;
    }
    return <></>;
  };

  const renderItems = () => {
    return chunks.map((items, index) => {
      return (
        <div
          className={`carousel-item ${index === 0 ? "active" : ""}`}
          key={items[0].id}
        >
          {items.map(item => {
            return (
              <div className="list-item" key={item.id}>
                <h3>{item.title}</h3>
                <div>{renderText(item)}</div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <>
      <div className="generic-list-container">
        <div className="container-fluid">
          <div className="container">
            <div
              id={`carousel-title-${component.getId()}`}
              className="carousel row"
            >
              <div className="carousel-inner">{renderItems()}</div>
              {params.showPagination && renderCarouselControls()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenericList;
