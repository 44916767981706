import React, { FC } from "react";
import { Link } from "react-router-dom";
import useAhriToolsLink from "../CustomHooks/useAhriToolsLink";
import { isLinkExternal } from "../../../app/src/utils/helpers";
import GridCardInterface from "./types/GridCardInterface";
import useWindowSize from "../CustomHooks/useWindowSize";

interface EcommGridCardProps {
  gridCard: GridCardInterface;
  isLoggedIn: boolean;
}

const EcommGridCard: FC<EcommGridCardProps> = ({ gridCard, isLoggedIn }) => {
  const [width, height] = useWindowSize();
  const {
    image,
    topTitle,
    mainTitle,
    bottomTitle,
    buttonText,
    type,
    cardWidth,
    backgroundColor,
    textColor,
    borderColor,
    description,
    backgroundImage: desktopBackgroundImage,
    isLinkForLoggedInUserOnly,
    useAhriToolLink,
    link,
    mobileBackgroundImage,
    buttonBackgroundColor,
    buttonBorderColor
  } = gridCard;
  const ahriToolLink = useAhriToolsLink();

  const getLink = () => {
    if (!isLoggedIn && isLinkForLoggedInUserOnly) return "/login";
    if (useAhriToolLink) return ahriToolLink;
    return link;
  };

  const [isExternal, url] = isLinkExternal(getLink());

  const backgroundImage =
    width <= 768
      ? mobileBackgroundImage || desktopBackgroundImage
      : desktopBackgroundImage;

  const style = {
    flex: `${cardWidth} 0 0`,
    color: textColor,
    border: borderColor ? `1px solid ${borderColor}` : "",
    backgroundColor,
    backgroundImage: `url(${backgroundImage})`
  };

  return (
    <Link
      to={
        // eslint-disable-next-line no-nested-ternary
        !isLoggedIn && isLinkForLoggedInUserOnly
          ? "/login"
          : isExternal
          ? { pathname: url }
          : url
      }
      target={!isExternal ? "_self" : "_blank"}
      rel={isExternal ? "noopener noreferrer" : ""}
      className={`grid-card ${type} ${backgroundImage ? "card-with-bkg" : ""}`}
      style={(() => {
        if (backgroundImage) {
          if (topTitle) {
            return {
              flex: `${cardWidth} 0 0`,
              color: textColor,
              background: `linear-gradient(to top, ${backgroundColor} 0%, ${backgroundColor} 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(to bottom, ${backgroundColor} 0%, ${backgroundColor} 10%, rgba(0, 0, 0, 0) 30%), url(${backgroundImage})`,
              backgroundRepeat: `no-repeat`,
              backgroundSize: `100% auto`,
              backgroundPosition: `center`
            };
          }
          return {
            flex: `${cardWidth} 0 0`,
            color: textColor,
            background: `linear-gradient(to top, ${backgroundColor} 0%, ${backgroundColor} 20%, rgba(0, 0, 0, 0) 50%), url(${backgroundImage})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `100% auto`,
            backgroundPosition: `left top`
          };
        }
        return style;
      })()}
    >
      {topTitle && <h2 className="grid-card-top-title">{topTitle}</h2>}
      {image && (
        <img
          className="grid-card-image 
            grid-card-image-center"
          src={image}
          alt={mainTitle}
        />
      )}

      {mainTitle && <h2 className="grid-card-title">{mainTitle}</h2>}
      {bottomTitle && <h2 className="grid-card-bottom-title">{bottomTitle}</h2>}
      {description && <p className="grid-card-description">{description}</p>}
      {buttonText && (
        <button
          type="button"
          className="grid-card-button"
          style={{
            backgroundColor: buttonBackgroundColor || "transparent",
            border: buttonBorderColor
              ? `1px solid ${buttonBorderColor}`
              : "none"
          }}
        >
          {buttonText}
        </button>
      )}
    </Link>
  );
};

export default EcommGridCard;
