import React, { FC } from "react";

import "./Campaign.less";

interface CampaignProps {
  content: string;
}

const Campaign: FC<CampaignProps> = ({ content }) => (
  <div
    className="campaign-container"
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

export default Campaign;
