/* eslint-disable camelcase */
import React, { FC, useState, useEffect, useContext, useRef } from "react";
import intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import {
  CategoryPLPBreadCrumbs,
  productListViewed,
  productAdded,
  productViewed,
  productListFiltered,
  productsSearched,
  page,
  logSearchTermResultPageThroughSegment,
  logNoSearchResults,
  logNoResultsAfterApplyingFilters,
  getConfig,
  Campaign,
  SearchResultsItems,
  SearchNoResults,
  SearchResultsAvailabilityCheckbox,
  SearchResultsScroppToTopButton,
  SearchFilters,
  useWindowSize
} from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import {
  EntitlementDetails,
  PriceDetails
} from "@elasticpath/ref-store/src/containers/PartsFinderPage";
import {
  SingleProduct,
  formatFacets,
  filterFacetFields,
  generateSearchParams,
  clearItemFromSessionStorage
} from "../utils/searchUtils";
import { MainContext } from "../contexts/MainContext";
import { brSearch } from "../services/SearchService";
import { getProductPrice } from "../utils/mappings/productDetails";
import {
  getAvailability,
  checkEntitlementGQL
} from "../services/connectGQLservices";

import {
  checkTokensExpired,
  handleCustomException,
  pushToMaintenace,
  generateSpecificErrorMessage,
  updateBranchNumber,
  formatQuoteAvailability
} from "../utils/helpers";
import { addToCart } from "../services/EpServices";
import "./SearchResultsPage.less";

const initialRows: number = 120;
interface SearchResultsPageProps extends RouteComponentProps, BrProps {
  match: any;
  history: any;
  searchType?: string;
}

interface SelectedBranch {
  code: string;
  vendor: string;
}

const SearchResultsPage: FC<SearchResultsPageProps> = ({
  match,
  history,
  searchType,
  location
}) => {
  // Value obtained from variables.less media query values
  const desktopMin = 1024;
  const urlRegex = new RegExp(/[,]/g);
  const showResultsBy = intl.get("show-results-by");
  const displayDefault = intl.get("in-my-region");
  const myCurrentBranch = intl.get("my-current-branch");
  const [width, height] = useWindowSize();
  const [pageTitle, setPageTitle] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchMethod, setSearchMethod] = useState<string>("");
  const [brSearchType, setBrSearchType] = useState<string>("");
  const [products, setProducts] = useState<SingleProduct[]>([]);
  const [productSkus, setProductSkus] = useState<string[]>([]);
  const [productPrices, setProductPrices] = useState<PriceDetails[]>([]);
  const [productEntitlements, setProductEntitlements] = useState<
    EntitlementDetails[]
  >(null);
  const [productAvailability, setProductAvailability] = useState(null);
  const [start, setStart] = useState<number>(0);
  const [numFound, setNumFound] = useState<number>(0);
  const [filtersChecked, setFiltersChecked] = useState<{
    [name: string]: any;
  }>({});
  const [branchesFilter, setBranchesFilter] = useState(null);
  const [facetFields, setFacetFields] = useState<{ [name: string]: any }>({});
  const [addCartButtonIds, setAddCartButtonIds] = useState([]);
  const [sortByAvailability, setSortByAvailability] = useState<boolean>(false);
  const [scrollToTop, setScrollToTop] = useState<boolean>(false);
  const [itemToScroll, setItemToScroll] = useState<string>("");
  const [inventoryError, setInventoryError] = useState<string>("");
  const [breadCrumbsMap, setBreadCrumbsMap] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [htmlData, setHtmlData] = useState("");
  const [openedFacetFields, setOpenedFacetFields] = useState<any[]>([
    showResultsBy
  ]);
  const [isLoadMoreButtonLoading, setIsLoadMoreButtonLoading] = useState<
    boolean
  >(false);
  const [selected, setSelected] = useState<string>(displayDefault);
  const [suggestedSearchTerm, setSuggestedSearchTerm] = useState<string[]>([]);

  const prevSearchTerm = useRef("");

  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    job: any;
    branches: any;
    navigation: any;
    account: any;
  }>(MainContext);
  const {
    cart: {
      cartDetails: { defaultCart },
      getCartDetails,
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage
    },
    job: { persistedJobNumber },
    auth: { isLoggedIn, logout },
    branches: { branchesList },
    navigation: { navigation },
    account: {
      accountDetails: { membership, customerNumber }
    }
  } = context;
  const selectedBranch: SelectedBranch | null = defaultCart
    ? defaultCart.selectedBranch
    : null;
  const jobNumber = defaultCart ? defaultCart.jobNumber : null;
  const clientId = defaultCart ? defaultCart.clientId : null;
  const { config } = getConfig();
  const { calculatePrice, showAvailableItemsFirst } = config;
  const { state, pathname } = location;
  const { itemsDisplayedPLP } = config;

  const prevProductSkusRef = useRef<any[]>([]);

  let activeBranch;
  if (defaultCart) {
    activeBranch = selectedBranch.code;
  }

  const handleAnalytics = () => {
    page();
    if (searchMethod === "category") {
      // sends informaion to Segment when user lands on PLP
      productListViewed(searchTerm);
    } else if (searchMethod === "keyword") {
      // sends informaion to Segment when user lands on Search Result Page
      productsSearched(searchTerm);
    }
  };

  const handleIsIAQProducts = () => {
    // PGL-364: Check whether the page title calculated in formatCategoryTitle is IAQ PRODUCTS
    let expectedPageTitle = "";
    if (pageTitle) {
      expectedPageTitle = pageTitle;
    } else if (match.params.keywords) {
      expectedPageTitle =
        searchMethod === "keyword" && state && state.searchTerm
          ? state.searchTerm
          : match.params.keywords;
    }
    return expectedPageTitle.toUpperCase() === "IAQ PRODUCTS";
  };

  const handleLogSearchTermResultPage = () => {
    const impressionProducts = products.map(item => {
      const priceDetails =
        productPrices &&
        productPrices.find(product => product.sku === item.pid);
      return {
        id: item.pid,
        name: item.title,
        brand: item.brand,
        price: priceDetails ? priceDetails.lineTotal : 0
      };
    });
    if (searchMethod === "keyword") {
      logSearchTermResultPageThroughSegment(
        "search",
        state ? state.searchTerm : searchTerm,
        impressionProducts
      );
    } else if (searchMethod === "category") {
      logSearchTermResultPageThroughSegment(
        "categoryitems",
        searchTerm,
        impressionProducts
      );
    }
  };

  const renderSuggestedTermsLinks = (term, index) => (
    <React.Fragment key={term}>
      <Link to={`/search/${term}`} className="dast-link">
        {term}
      </Link>
      {index < suggestedSearchTerm.length - 1 ? ", " : ""}
    </React.Fragment>
  );

  const fetchSearchResults = async (isSelectedBranch?: boolean) => {
    const {
      start: startValue,
      startParam,
      filtersChecked: filtersCheckedValue,
      sortByAvailabilityParam
    } = generateSearchParams(
      history,
      location,
      itemsDisplayedPLP,
      initialRows,
      showAvailableItemsFirst
    );

    const filtersParam = formatFacets(filtersCheckedValue);
    let prev = "";
    if (!(typeof state === "undefined")) {
      prev = state.prevUrl;
    }
    const prevUrl = prev;
    handleAnalytics();
    clearItemFromSessionStorage();
    const currentlySelectedBranch = isSelectedBranch
      ? [
          {
            branchNumber: selectedBranch.code
          }
        ]
      : null;
    try {
      const res: any = await brSearch(
        searchTerm,
        brSearchType,
        startParam,
        initialRows,
        filtersParam,
        "",
        window.location.href,
        prevUrl,
        isSelectedBranch ? currentlySelectedBranch : branchesList,
        membership
      );
      const {
        data: {
          response,
          facet_counts: { facet_fields },
          campaign,
          did_you_mean
        }
      } = res;
      const didYouMeanTerms = did_you_mean || [];
      setSuggestedSearchTerm(didYouMeanTerms);
      if (response) {
        setProducts(response.docs);
        const skus = response.docs.map((product: SingleProduct) => product.pid);
        setProductSkus(skus);
        setNumFound(response.numFound);
      }
      setFacetFields(filterFacetFields(facet_fields));
      setFiltersChecked(filtersCheckedValue || {});
      setStart(startValue);
      setSortByAvailability(sortByAvailabilityParam);
      if (!response.docs || response.docs.length === 0) {
        if (searchMethod === "keyword") {
          logNoSearchResults("search", state ? state.searchTerm : searchTerm);
        } else if (searchMethod === "category") {
          logNoSearchResults("categoryitems", searchTerm);
        }
      }
      if (campaign) {
        const {
          data: {
            campaign: { htmlText }
          }
        } = res;
        setHtmlData(htmlText);
      } else {
        setHtmlData("");
      }
      if (
        !(isLoggedIn && products.length && customerNumber && selectedBranch)
      ) {
        setIsLoading(false);
      }
    } catch (e) {
      if (checkTokensExpired()) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "logout => fetchSearchResults => SearchResultsPage.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "fetchSearchResults => SearchResultsPage.tsx"
        });
      }
    }
  };

  const fetchProductPrices = async () => {
    if (products.length) {
      if (isLoggedIn && selectedBranch && customerNumber) {
        const priceRequestBody = {
          customerNumber,
          shipments: [
            {
              branchNumber: selectedBranch.code,
              customerPickup: false,
              items: productSkus.map(sku => ({
                sku,
                quantity: 1
              }))
            }
          ],
          jobNumber
        };
        if (defaultCart.jobNumber || persistedJobNumber) {
          priceRequestBody.jobNumber =
            defaultCart.jobNumber || persistedJobNumber;
        }
        await getProductPrice(
          priceRequestBody,
          res => {
            if (
              res &&
              res.data &&
              res.data.quote &&
              Array.isArray(res.data.quote.shipments)
            ) {
              setProductPrices(res.data.quote.shipments[0].items);
            }
          },
          e => {
            const errorPath = "fetchProductPrices => SearchResultsPage.tsx";
            handleCustomException(e, logout, history, errorPath);
            setProductPrices([]);
          }
        );
        handleLogSearchTermResultPage();
      }
    }
  };

  const validateEntitlement = async () => {
    if (
      isLoggedIn &&
      config.entitlementCheck &&
      selectedBranch &&
      selectedBranch.vendor
    ) {
      const skus = products.map((product: SingleProduct) => product.pid);
      if (skus && skus.length) {
        try {
          const { data } = await checkEntitlementGQL(customerNumber, skus);
          if (data && data.data && data.data.customer) {
            setIsLoading(false);
            if (!data.data.customer.productEntitlements) {
              setProductEntitlements([]);
            } else {
              setProductEntitlements(data.data.customer.productEntitlements);
            }
          }
        } catch (e) {
          const errorPath =
            "validateEntitlement => checkEntitlementGQL => SearchResultsPage.tsx";
          handleCustomException(e, logout, history, errorPath);
          setProductEntitlements([]);
          setIsLoading(false);
        }
      }
    }
  };

  const fetchProductAvailability = async () => {
    if (isLoggedIn && selectedBranch && branchesList) {
      const skus = products.map((product: SingleProduct) => product.pid);
      try {
        const branchNumber = selectedBranch.code;

        const { data } = await getAvailability(
          skus,
          customerNumber,
          branchNumber
        );
        if (data && data.data && data.data.inventory) {
          if (!data.data.inventory.entitledInventory) {
            setInventoryError(intl.get("inventory-error"));
          } else {
            const availabilityData = data.data.inventory.entitledInventory;
            const availability = formatQuoteAvailability(
              availabilityData.branches,
              availabilityData.regionRollups,
              skus,
              availabilityData.hubInventory
            );
            setProductAvailability(availability);
            setInventoryError("");
          }
        }
      } catch (e) {
        const errorPath = "fetchProductAvailability => SearchResultsPage.tsx";
        handleCustomException(e, logout, history, errorPath);
        setProductAvailability([]);
        setInventoryError(intl.get("inventory-error"));
      }
    }
  };

  const onAddToCart = async (
    event,
    shippingMethod: "pickup" | "delivery",
    item?: SingleProduct
  ) => {
    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      },
      account: {
        accountDetails: { homeBranch }
      }
    } = context;
    const { id } = event.target;

    const items = [
      {
        code: id,
        quantity: 1,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          id,
          airPurifierBranchNumber,
          homeBranch,
          activeBranch
        ),
        "shipping-method": shippingMethod
      }
    ];
    const {
      addItemsToCart: {
        self: { uri }
      }
    } = defaultCart;
    setAddCartButtonIds(addCartButtonIds.concat(id));

    addToCart(uri, { items })
      .then(res => {
        // Update Cart Count in Context.
        return getCartDetails();
      })
      .then(() => {
        if (item) {
          const { title, pid, productPrice, brand } = item;
          const price =
            !productPrice || productPrice === intl.get("pending")
              ? 0
              : Number(productPrice);
          // sends information to Segment when user views a product
          productViewed(title, pid, price, brand, "");
          // sends information to Segment when user adds a product

          productAdded(title, pid, price, brand, searchMethod, 1);
        }
      })
      .then(() => {
        setSuccesCartPopupMessage(1);

        setAddCartButtonIds(prevAddCartButtonIds =>
          prevAddCartButtonIds.filter(buttonId => buttonId !== id)
        );
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => onAddToCart => SearchResultsPage.tsx"
            })
          );
        }
        setErrorCartPopupMessage(generateSpecificErrorMessage(e));

        setAddCartButtonIds(prevAddCartButtonIds =>
          prevAddCartButtonIds.filter(buttonId => buttonId !== id)
        );
      });
  };

  const toggleOpenedFacetFields = facetName => {
    if (openedFacetFields.some(field => field === facetName)) {
      const filteredFacetFields = openedFacetFields.filter(
        facetField => facetField !== facetName
      );
      setOpenedFacetFields(filteredFacetFields);
    } else {
      setOpenedFacetFields(prevState => [...prevState, facetName]);
    }
  };

  const onFacetChange = async (
    filter: string,
    checkedFilters: {
      [name: string]: any;
    },
    branchesIncluded: any
  ) => {
    const facetChangeStart = 0;
    const filters = [];
    Object.entries(checkedFilters).forEach(checkedFilter => {
      const type = checkedFilter[0];
      checkedFilter[1].forEach(value => {
        const f = {
          type,
          value
        };
        filters.push(f);
      });
    });
    // sends applied filters to Segment
    productListFiltered(searchTerm, filters);
    // fetch BR search results for selected facets
    let prev = "";
    if (!(typeof state === "undefined")) {
      prev = state.prevUrl;
    }
    const prevUrl = prev;
    const isIAQProducts = handleIsIAQProducts();
    const includeBranchesList = isLoggedIn && !isIAQProducts;
    handleAnalytics();
    clearItemFromSessionStorage();
    brSearch(
      searchTerm,
      brSearchType,
      facetChangeStart,
      initialRows,
      filter,
      "",
      window.location.href,
      prevUrl,
      includeBranchesList ? branchesIncluded || branchesFilter : null,
      membership
    )
      .then(async res => {
        const {
          data: {
            response,
            facet_counts: { facet_fields },
            campaign
          }
        } = res;
        const fields = filterFacetFields(facet_fields);
        if (response) {
          setProducts(response.docs);
          const skus = response.docs.map(
            (product: SingleProduct) => product.pid
          );
          if (!response.docs || response.docs.length === 0) {
            if (searchMethod === "keyword") {
              logNoResultsAfterApplyingFilters(
                "search",
                state ? state.searchTerm : searchTerm,
                filters
              );
            } else if (searchMethod === "category") {
              logNoResultsAfterApplyingFilters(
                "categoryitems",
                searchTerm,
                filters
              );
            }
          }
          setProductSkus(skus);
          setNumFound(response.numFound);
        }
        setFacetFields({ ...fields });
      })
      .catch(e =>
        pushToMaintenace(history, {
          e,
          errIn: "onFacetChange => SearchResultsPage.tsx"
        })
      );
  };

  const onFacetClicked = event => {
    const { checked, value, name } = event.target;
    const escValue = `"${value}"`;
    let branchesIncluded = [];
    const inMyRegion = intl.get("in-my-region");
    const displayAll = intl.get("all-including-products-not-in-region");

    if (!filtersChecked[`${name}`] && name !== showResultsBy) {
      filtersChecked[`${name}`] = new Set();
    }
    if (checked) {
      if (name !== showResultsBy) {
        filtersChecked[`${name}`].add(escValue);
        if (branchesFilter) {
          branchesIncluded = branchesFilter;
        }
      } else if (value === myCurrentBranch) {
        branchesIncluded = [
          {
            branchNumber: selectedBranch.code
          }
        ];
        setBranchesFilter([
          {
            branchNumber: selectedBranch.code
          }
        ]);
      } else if (value === inMyRegion) {
        branchesIncluded = branchesList;
        setBranchesFilter(branchesList);
      } else if (value === displayAll) {
        setBranchesFilter(null);
      }
    } else if (name !== showResultsBy) {
      filtersChecked[`${name}`].delete(escValue);
      if (branchesFilter) {
        branchesIncluded = branchesFilter;
      }
    } else {
      setBranchesFilter(null);
      branchesIncluded = null;
    }
    const filter = formatFacets(filtersChecked);
    onFacetChange(filter, filtersChecked, branchesIncluded);
  };

  const onAvailabilityCheckboxClick = event => {
    const { checked } = event.target;
    setSortByAvailability(checked);
  };

  const onLoadMore = async () => {
    const startIndex = start + 1;
    const numDisplayed = startIndex * itemsDisplayedPLP;
    const filters = filtersChecked && formatFacets(filtersChecked);
    setIsLoadMoreButtonLoading(true);
    if (numFound > products.length && numDisplayed >= products.length) {
      let prev = "";
      if (!(typeof state === "undefined")) {
        prev = state.prevUrl;
      }
      const prevUrl = prev;
      const isIAQProducts = handleIsIAQProducts();
      const includeBranchesList = isLoggedIn && !isIAQProducts;
      handleAnalytics();
      clearItemFromSessionStorage();
      brSearch(
        searchTerm,
        brSearchType,
        numDisplayed,
        itemsDisplayedPLP,
        filters,
        "",
        window.location.href,
        prevUrl,
        includeBranchesList ? branchesFilter : null,
        membership
      )
        .then(async res => {
          const {
            data: {
              response,
              facet_counts: { facet_fields },
              campaign
            }
          } = res;
          if (response) {
            const appendedProducts = products.concat(response.docs);
            setProducts(appendedProducts);
            const skus = response.docs.map(
              (product: SingleProduct) => product.pid
            );
            setProductSkus(skus);
            setNumFound(response.numFound);
          }
          setFacetFields(filterFacetFields(facet_fields));
          setStart(startIndex);
          setIsLoadMoreButtonLoading(false);
        })
        .catch(e => {
          setIsLoadMoreButtonLoading(false);

          if (checkTokensExpired()) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "logout => onLoadMore => SearchResultsPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "onLoadMore => SearchResultsPage.tsx"
            });
          }
        });
    } else {
      setStart(startIndex);
      setIsLoadMoreButtonLoading(false);
    }
  };

  // PGL-1795: Adding this so we can properly track when selectedBranch and jobNumber are changing. Cart context updates when we
  // click on the header, so selected branch is updating and firing useEffects off unnecessarily. This is used to cache values we
  // need for later comparison, since a typical usage of useRef would get rewritten in this specific use case.
  const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const scrollToLastViewedItem = () => {
    let scrollSku;
    if (history.action === "POP") {
      scrollSku = sessionStorage.getItem("sku");
    } else if (state && state.sku) {
      scrollSku = state.sku;
    }
    const element = document.getElementById(`${scrollSku}`);
    if (element) {
      element.scrollIntoView();
      setItemToScroll(scrollSku);
    }
  };

  const prevSelectedBranch = usePrevious(selectedBranch);
  const prevJob = usePrevious(jobNumber);

  useEffect(() => {
    setIsLoading(true);
    setProducts([]);
    const newSearchTerm = match.params.keywords
      ? match.params.keywords.replace(urlRegex, "")
      : "";
    setSearchTerm(newSearchTerm);
    const tempBrSearchType = searchType || "keyword";
    setBrSearchType(tempBrSearchType);
    setSortByAvailability(showAvailableItemsFirst);
    let tempSearchMethod =
      state && state.searchType ? state.searchType : tempBrSearchType;
    if (!tempSearchMethod && pathname.includes("search")) {
      tempSearchMethod = "keyword";
    }
    setSearchMethod(tempSearchMethod);
    window.addEventListener("scroll", () =>
      setScrollToTop(window.pageYOffset > 300)
    );
    window.addEventListener("beforeunload", clearItemFromSessionStorage);
    return () => {
      window.removeEventListener("scroll", () =>
        setScrollToTop(window.pageYOffset > 300)
      );
      window.removeEventListener("beforeunload", clearItemFromSessionStorage);
    };
  }, [match.params.keywords, history, searchType]);

  // Set Title
  useEffect(() => {
    if (navigation && match.params.keywords) {
      const tempTitle =
        searchMethod === "keyword" && state && state.searchTerm
          ? state.searchTerm
          : match.params.keywords;
      const tempBrSearchType = searchType || "keyword";
      const isCategoryPage =
        tempBrSearchType && tempBrSearchType === "category";
      if (!isCategoryPage) {
        setPageTitle(tempTitle);
        setBreadCrumbsMap([
          { param: "home", name: "Home" },
          {
            param: "nocrumb",
            name: intl.get("search-results-crumbs", {
              keywords: state ? state.searchTerm : match.params.keywords
            })
          }
        ]);
      }
    }
  }, [navigation, searchTerm]);

  // Fetch BR search results
  useEffect(() => {
    const isIAQProducts = handleIsIAQProducts();
    const includeBranchesList = isLoggedIn && !isIAQProducts;
    const hasMembership = membership.length !== 0;
    const hasMembershipOrIsGuest =
      (isLoggedIn && hasMembership) || (!isLoggedIn && !hasMembership);
    const branchesListNeeded = includeBranchesList === !!branchesList;
    const searchTermChanged = searchTerm !== prevSearchTerm.current;
    const performSearch =
      !!searchTerm &&
      hasMembershipOrIsGuest &&
      (branchesListNeeded || (searchTermChanged && isIAQProducts));
    if (performSearch) {
      setBranchesFilter(branchesList);
      fetchSearchResults();
      prevSearchTerm.current = searchTerm;
    }
  }, [searchTerm, branchesList, membership]);

  useEffect(() => {
    if (
      selectedBranch &&
      prevSelectedBranch !== null &&
      prevSelectedBranch !== undefined &&
      prevSelectedBranch.code !== selectedBranch.code &&
      selected === myCurrentBranch
    ) {
      setBranchesFilter([
        {
          branchNumber: selectedBranch.code
        }
      ]);
      fetchSearchResults(true);
    }
  }, [selectedBranch]);

  // Fetch product prices
  useEffect(() => {
    if (
      calculatePrice &&
      isLoggedIn &&
      products.length &&
      (prevProductSkusRef.current.length !== productSkus.length ||
        (prevProductSkusRef.current.length === productSkus.length &&
          prevProductSkusRef.current[prevProductSkusRef.current.length - 1] !==
            productSkus[productSkus.length - 1]) ||
        (prevSelectedBranch !== null &&
          prevSelectedBranch !== undefined &&
          prevSelectedBranch.code !== selectedBranch.code) ||
        (prevJob !== null && prevJob !== undefined && prevJob !== jobNumber)) &&
      selectedBranch &&
      customerNumber
    ) {
      fetchProductPrices();
    }
  }, [productSkus, selectedBranch, jobNumber, customerNumber]);

  // Update the previous product SKUs using useRef
  useEffect(() => {
    prevProductSkusRef.current = productSkus;
  }, [productSkus]);

  // Fetch product entitlements
  useEffect(() => {
    if (isLoggedIn && products.length && customerNumber && selectedBranch) {
      validateEntitlement();
    }
  }, [isLoggedIn, products, customerNumber, selectedBranch]);

  // Fetch product availability
  useEffect(() => {
    if (
      isLoggedIn &&
      products.length &&
      selectedBranch &&
      customerNumber &&
      branchesList
    ) {
      fetchProductAvailability();
    }
  }, [
    isLoggedIn,
    products,
    selectedBranch,
    clientId,
    customerNumber,
    branchesList
  ]);

  // Scroll to last viewed item
  useEffect(() => {
    if (products.length && productAvailability && !itemToScroll) {
      scrollToLastViewedItem();
    }
  }, [itemToScroll, products, productAvailability]);

  if (!searchTerm) return null;

  if (isLoading) {
    return <div className="loader" />;
  }

  return (
    <>
      <div className="search-results-page">
        <div className="container">
          {inventoryError && (
            <MessageContainer
              message={{
                type: "basic",
                debugMessages: inventoryError
              }}
              closeContainerHandler={null}
            />
          )}
          <CategoryPLPBreadCrumbs
            query={match.params.keywords}
            isCategoryPage={searchType && searchType === "category"}
            breadCrumbsMap={breadCrumbsMap}
            setBreadCrumbsMap={setBreadCrumbsMap}
            setPageTitle={setPageTitle}
          />
          <div className="search-results-wrapper">
            <div className="search-results-total-count">
              {`
                  ${intl.get("category-results-total-found", {
                    found: numFound
                  })} for "${pageTitle}"
                 `}
            </div>
            {suggestedSearchTerm.length ? (
              <div className="suggested-terms-section">
                {intl.get("did-you-mean")}
                {suggestedSearchTerm.map(renderSuggestedTermsLinks)}?
              </div>
            ) : null}
            <SearchFilters
              facets={facetFields}
              filtersChecked={filtersChecked}
              onFacetClicked={onFacetClicked}
              checked={sortByAvailability}
              handler={onAvailabilityCheckboxClick}
              selected={selected}
              setSelected={setSelected}
              openedFacetFields={openedFacetFields}
              toggleOpenedFacetFields={toggleOpenedFacetFields}
            />
            {!isLoading && !products.length && (
              <SearchNoResults branchesFilter={branchesFilter} />
            )}
          </div>
          {htmlData && width < desktopMin && <Campaign content={htmlData} />}
          <div className="search-results-wrapper">
            {htmlData && width >= desktopMin && <Campaign content={htmlData} />}
            {numFound > 0 && (
              <div className="search-results-top-banner">
                <div className="search-results-total-banner">
                  {width >= desktopMin && (
                    <>
                      {numFound ? (
                        <div className="search-total-results">
                          {intl.get("search-results-count", {
                            loaded:
                              (start + 1) * itemsDisplayedPLP >= numFound
                                ? products.length
                                : (start + 1) * itemsDisplayedPLP,
                            found: numFound
                          })}
                        </div>
                      ) : null}
                      {config.sortByAvailability && isLoggedIn ? (
                        <SearchResultsAvailabilityCheckbox
                          checked={sortByAvailability}
                          handler={onAvailabilityCheckboxClick}
                        />
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            )}
            <SearchResultsItems
              productEntitlements={productEntitlements}
              productAvailability={productAvailability}
              products={products}
              productPrices={productPrices}
              addCartButtonIds={addCartButtonIds}
              start={start}
              rows={itemsDisplayedPLP}
              filtersChecked={filtersChecked}
              sortByAvailability={sortByAvailability}
              numFound={numFound}
              onAddToCart={onAddToCart}
              onLoadMore={onLoadMore}
              isLoading={isLoadMoreButtonLoading}
            />
          </div>
          {scrollToTop && <SearchResultsScroppToTopButton />}
        </div>
      </div>
    </>
  );
};

export default withRouter(SearchResultsPage);
