/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import {
  checkTokensExpired,
  formatGeneralDGAAvailabilityLabel,
  isSpecialAirPurifier,
  pushToMaintenace,
  updateBranchNumber,
  formatPickupOrDeliveryAvailabilityLabel,
  formatPickupOrDeliveryAvailabilityLabelAhri
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";
import "./cart.lineitem.less";
import AlternateBranchList from "../AlternateBranchList/AlternateBranchList";
import { cortexFetch } from "../utils/Cortex";
import { ReactComponent as ErrorIcon } from "../../../app/src/images/icons/error-icon.svg";

let Config: IEpConfig | any = {};

interface CartLineItemProps extends RouteComponentProps {
  item: { [key: string]: any };
  itemDetailLink?: string;
  history: any;
  match: any;
  isChangeDisabled?: boolean;
  first?: boolean;
  groupId?: string;
  groupName?: string;
  isErrorMessage: boolean;
  notSelectedPickupBranches: any[];
  missingFulfillmentMethod?: any[];
  handleQuantityChange: (...args: any[]) => any;
  handleRemoveBtnClicked: (...args: any[]) => any;
  handleGroupItemsUpdate: (...args: any[]) => any;
  toggleActivePros?: (...args: any[]) => any;
  activePros?: any;
  setItemError?: (...args: any[]) => any;
  inventoryError?: string;
  ahriAvailability?: boolean | null;
}

interface CartLineItemState {
  selectedQuantity: any;
  deleteLoader: boolean;
  updateLoader: boolean;
  hasError: boolean;
  availabilityLoader: boolean;
  modalIsOpen: boolean;
  branchAvailability: number;
  regionAvailability: number;
  dcAvailability: number | null;
  shippingMethodIsLoading: boolean;
  imgMissingHorizontal: any;
}

class CartLineItem extends React.Component<
  CartLineItemProps,
  CartLineItemState
> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    const { item } = this.props;

    this.state = {
      selectedQuantity: item.quantity,
      deleteLoader: false,
      updateLoader: false,
      hasError: false,
      availabilityLoader: false,
      modalIsOpen: false,
      branchAvailability: 0,
      regionAvailability: 0,
      dcAvailability: 0,
      shippingMethodIsLoading: false,
      imgMissingHorizontal: Config.missingImagePlaceholderUrl
    };
    this.handleInputQuantityChange = this.handleInputQuantityChange.bind(this);
    this.toggleUpdateLoader = this.toggleUpdateLoader.bind(this);
    this.toggleDeleteLoader = this.toggleDeleteLoader.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
    this.renderPickupBranchLabel = this.renderPickupBranchLabel.bind(this);
    this.updateShippingMethod = this.updateShippingMethod.bind(this);
    this.openAlternateBranchesModal = this.openAlternateBranchesModal.bind(
      this
    );
    this.closeAlternateBranchesModal = this.closeAlternateBranchesModal.bind(
      this
    );
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props;

    if (prevProps.item.quantity !== item.quantity) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedQuantity: item.quantity
      });
    }
    if (prevProps.item.branchAvailability !== item.branchAvailability) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        branchAvailability: item.branchAvailability
      });
    }
    if (prevProps.item.regionAvailability !== item.regionAvailability) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        regionAvailability: item.regionAvailability
      });
    }

    if (prevProps.item.dcAvailability !== item.dcAvailability) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        dcAvailability: item.dcAvailability
      });
    }

    this.validateQuantity();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateQuantity() {
    const { selectedQuantity } = this.state;
    const { setItemError, item } = this.props;
    if (selectedQuantity > 1000 && !item.error) {
      setItemError({
        ...item,
        error: intl.get("large-quantity-error", { limit: 1000 })
      });
      this.setState({
        hasError: true
      });
    }
    if (selectedQuantity < 0 && !item.error) {
      setItemError({ ...item, error: intl.get("negative-quantity-error") });
      this.setState({
        hasError: true
      });
    }
    if (selectedQuantity <= 1000 && selectedQuantity > 0 && item.error) {
      setItemError({ ...item, error: "" });
      this.setState({
        hasError: false
      });
    }
  }

  handleInputQuantityChange(e) {
    const { value } = e.target;
    if (!value) {
      this.setState({
        selectedQuantity: ""
      });
    } else {
      this.setState({
        selectedQuantity: parseInt(value, 10),
        availabilityLoader: true
      });
    }
  }

  updateQuantity(groupItems: boolean) {
    const {
      item,
      groupId,
      groupName,
      handleQuantityChange,
      handleGroupItemsUpdate
    } = this.props;
    const { selectedQuantity, hasError } = this.state;
    if (!hasError) {
      if (!groupItems) {
        handleQuantityChange(
          item,
          selectedQuantity,
          this.toggleUpdateLoader,
          item["shipping-method"],
          item["branch-number"]
        );
        this.setState({
          availabilityLoader: false
        });
      } else {
        handleGroupItemsUpdate(
          item,
          selectedQuantity,
          groupId,
          groupName,
          this.toggleUpdateLoader,
          this.toggleDeleteLoader,
          item["shipping-method"],
          item["branch-number"],
          true
        );
        this.setState({
          availabilityLoader: false
        });
      }
    }
  }

  toggleUpdateLoader() {
    const { updateLoader } = this.state;
    if (this._isMounted) {
      this.setState({
        updateLoader: !updateLoader
      });
    }
  }

  toggleDeleteLoader() {
    const { deleteLoader } = this.state;
    if (this._isMounted) {
      this.setState({
        deleteLoader: !deleteLoader
      });
    }
  }

  renderContractNumber() {
    const { item } = this.props;
    if (item["contract-number"]) {
      return (
        <span className="contract-number">
          {`${intl.get("contract-number")}: ${item["contract-number"]}`}
        </span>
      );
    }
    return null;
  }

  renderUnitPrice() {
    const { item } = this.props;
    if (item._item && (item._price || item._item[0]._price)) {
      const itemPrice = item._price ? item._price : item._item[0]._price;
      const purchasePrice =
        item._price && itemPrice[0]["purchase-price"]
          ? itemPrice[0]["purchase-price"][0].display
          : intl.get("pending");
      return (
        <ul
          className={`price-container ${
            item["contract-number"] ? "contract-container" : ""
          }`}
        >
          <li className="cart-unit-purchase-price">{purchasePrice}</li>
          <li className="contract-number-container">
            {this.renderContractNumber()}
          </li>
        </ul>
      );
    }
    return (
      <ul className="price-container">
        <li className="cart-unit-purchase-price">{}</li>
        <li className="contract-number-container">
          {this.renderContractNumber()}
        </li>
      </ul>
    );
  }

  renderTotalPrice() {
    const { item } = this.props;
    let itemTotal =
      item._total && item._total[0].cost ? item._total[0].cost[0].display : "";
    if (!itemTotal && item._price) {
      itemTotal = item._price[0]["purchase-price"]
        ? item._price[0]["purchase-price"][0].display
        : "";
    }
    if (!itemTotal) {
      itemTotal = intl.get("pending");
    }
    return (
      <ul className="price-container">
        <li
          className="cart-total-list-price is-hidden"
          data-region="itemListPriceRegion"
        />
        <li className="cart-total-purchase-price">{itemTotal}</li>
      </ul>
    );
  }

  renderProsItemsHeader = () => {
    const {
      selectedQuantity,
      updateLoader,
      deleteLoader,
      availabilityLoader,
      branchAvailability,
      regionAvailability,
      dcAvailability,
      shippingMethodIsLoading
    } = this.state;

    const {
      handleGroupItemsUpdate,
      item,
      groupId,
      groupName,
      isChangeDisabled,
      inventoryError,
      toggleActivePros,
      activePros,
      isErrorMessage,
      notSelectedPickupBranches,
      missingFulfillmentMethod,
      ahriAvailability
    } = this.props;
    const isAHRI = groupId.includes("ahri");
    const ahriNumber = isAHRI
      ? groupId.split("ahri-group-")[1].split("-")[0]
      : item["ahri-number"];
    const isOutOfStock =
      branchAvailability === 0 &&
      regionAvailability === 0 &&
      dcAvailability === 0;
    return (
      <div className="row cart-lineitem-new pros-header">
        <div
          className={`col-6 ${Config.calculatePrice ? "col-lg-4" : "col-lg-7"}`}
        >
          <div className="col-header d-lg-block pros-items-heading">
            <h4>
              {ahriNumber
                ? intl.get("ahri-system", {
                    number: ahriNumber
                  })
                : intl.get("system-configuration")}
            </h4>
            <div className="d-flex">
              <span className="ahri-label">
                {intl.get("see-all-components")}
              </span>
              <button
                type="button"
                className="expand-pros-button"
                onClick={() => toggleActivePros(groupId)}
              >
                {activePros.includes(groupId) ? (
                  <i className="icon-chevron-up" />
                ) : (
                  <i className="icon-chevron-down" />
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="col-6 d-lg-none">
          <div className="col-header remove-button">
            {!deleteLoader ? (
              <button
                className="item-remove-btn d-lg-block"
                type="button"
                aria-label="remove item"
                onClick={() =>
                  handleGroupItemsUpdate(
                    item,
                    0,
                    groupId,
                    groupName,
                    this.toggleUpdateLoader,
                    this.toggleDeleteLoader,
                    item["shipping-method"],
                    item["branch-number"],
                    true
                  )
                }
                disabled={isChangeDisabled}
              >
                <span className="btn-text">
                  <i className="icon-close-x" />
                </span>
              </button>
            ) : (
              <div className="miniLoader d-lg-block" />
            )}
          </div>
        </div>

        {Config.calculatePrice && (
          <div className="col-12 col-lg-2 d-lg-block col-header" />
        )}
        <div className="col-4 col-lg-1 ahri-quantity">
          <div className="col-header d-lg-flex quantity-update justify-content-lg-end">
            <span className="input-group-btn" key="quantity-buttons">
              <div className="quantity-col form-content form-content-quantity">
                <input
                  className="product-display-item-quantity-select form-control form-control-quantity"
                  type="number"
                  step="1"
                  min="1"
                  max="9999"
                  value={selectedQuantity}
                  onChange={this.handleInputQuantityChange}
                  onBlur={() => this.updateQuantity(true)}
                  disabled={isChangeDisabled}
                />
              </div>
            </span>
          </div>
          {updateLoader && <div className="miniLoader" />}
        </div>
        <div className="col-12 col-lg-3 vertically-aligned-mobile col-header">
          {(!inventoryError && (updateLoader || availabilityLoader)) ||
          inventoryError ? (
            <div className="d-flex justify-content-start justify-content-lg-center">
              <span className="label">
                {inventoryError
                  ? intl.get("available-for-back-order-label")
                  : intl.get("checking-availability")}
              </span>
            </div>
          ) : (
            <div
              className={
                isErrorMessage &&
                missingFulfillmentMethod.some(
                  branch =>
                    branch.ahriNumber === groupId.split("ahri-group-")[1]
                )
                  ? "cart-shipping-methods pickup-error"
                  : "cart-shipping-methods"
              }
            >
              <div
                className={
                  isErrorMessage &&
                  notSelectedPickupBranches.some(
                    branch =>
                      branch.ahriNumber === groupId.split("ahri-group-")[1]
                  ) &&
                  !missingFulfillmentMethod.length &&
                  item["shipping-method"] === "pickup"
                    ? "cart-shipping-method pickup-error"
                    : "cart-shipping-method"
                }
              >
                <input
                  type="radio"
                  id={`pickup ${ahriNumber}_${item["shipping-method"]}_${item["branch-number"]}`}
                  name={`${ahriNumber}_${item["shipping-method"]}_${item["branch-number"]}`}
                  checked={
                    item["shipping-method"] === "pickup" ||
                    (item["shipping-method"] === "pickup" &&
                      !item["branch-number"] &&
                      !isOutOfStock)
                  }
                  onChange={() => {
                    if (item["shipping-method"] !== "pickup") {
                      this.updateShippingMethod(true, "pickup");
                    }
                  }}
                />
                <label
                  htmlFor={`pickup ${ahriNumber}_${item["shipping-method"]}_${item["branch-number"]}`}
                >
                  {formatPickupOrDeliveryAvailabilityLabelAhri({
                    currentBranch: this.renderPickupBranchLabel(item),
                    ahriAvailability,
                    onLabelClick: this.openAlternateBranchesModal,
                    buttonIsLoading:
                      shippingMethodIsLoading ||
                      updateLoader ||
                      availabilityLoader,
                    isCartPage: true
                  })}
                </label>
              </div>

              <div className="cart-shipping-method">
                <input
                  type="radio"
                  id={`delivery ${ahriNumber}_${item["shipping-method"]}_${item["branch-number"]}`}
                  name={`${ahriNumber}_${item["shipping-method"]}_${item["branch-number"]}`}
                  checked={
                    item["shipping-method"] === "delivery" ||
                    (item["shipping-method"] === "pickup" &&
                      !item["branch-number"] &&
                      isOutOfStock)
                  }
                  onChange={() => {
                    if (item["shipping-method"] !== "delivery") {
                      this.updateShippingMethod(true, "delivery");
                    }
                  }}
                />
                <label
                  htmlFor={`delivery ${ahriNumber}_${item["shipping-method"]}_${item["branch-number"]}`}
                >
                  {formatPickupOrDeliveryAvailabilityLabelAhri({
                    currentBranch: this.renderPickupBranchLabel(item),
                    ahriAvailability,
                    buttonIsLoading:
                      shippingMethodIsLoading ||
                      updateLoader ||
                      availabilityLoader,
                    isDelivery: true,
                    isCartPage: true
                  })}
                </label>
              </div>
            </div>
          )}
        </div>
        {Config.calculatePrice ? (
          <div className="col-12 col-lg col-header">
            <div className="col-12 col-lg">
              <div className="d-flex justify-content-end justify-content-lg-between">
                <div className="d-inline-block d-md-none mr-2">
                  {`${intl.get("ext-price")}: `}
                </div>
                {this.renderCloseButton()}
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 col-lg-1">{this.renderCloseButton()}</div>
        )}

        <div className="col-lg d-none d-lg-block">
          <div className="col-header remove-button">
            {!deleteLoader ? (
              <button
                className="item-remove-btn d-lg-block"
                type="button"
                aria-label="remove item"
                onClick={() =>
                  handleGroupItemsUpdate(
                    item,
                    0,
                    groupId,
                    groupName,
                    this.toggleUpdateLoader,
                    this.toggleDeleteLoader,
                    item["shipping-method"],
                    item["branch-number"],
                    true
                  )
                }
                disabled={isChangeDisabled}
              >
                <span className="btn-text">
                  <i className="icon-close-x" />
                </span>
              </button>
            ) : (
              <div className="miniLoader d-lg-block" />
            )}
          </div>
        </div>
      </div>
    );
  };

  renderCloseButton() {
    const {
      item,
      isChangeDisabled,
      groupId,
      handleRemoveBtnClicked
    } = this.props;
    const { deleteLoader } = this.state;
    const isProsUndefined = groupId === "undefined";

    return (
      isProsUndefined &&
      (!deleteLoader ? (
        <button
          className="item-remove-btn d-none d-lg-block"
          type="button"
          aria-label="remove item"
          onClick={() => handleRemoveBtnClicked(item, this.toggleDeleteLoader)}
          disabled={isChangeDisabled}
        >
          <span className="btn-text">
            <i className="icon-close-x" />
          </span>
        </button>
      ) : (
        <div className="miniLoader d-none d-lg-block" />
      ))
    );
  }

  openAlternateBranchesModal() {
    this.setState({
      modalIsOpen: true
    });
  }

  closeAlternateBranchesModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  renderAlternateBranchesModal(productId, productQuantity) {
    const { history, item, groupId } = this.props;
    const { modalIsOpen } = this.state;

    const {
      branches: { branchesList },
      cart: {
        cartDetails: {
          defaultCart: { items }
        }
      }
    } = this.context;

    const groupItems =
      items &&
      items
        .filter(groupItem => {
          const groupIdSplit = groupId.split("ahri-group-")[1];
          return (
            groupItem["ahri-number"] ===
            (groupIdSplit && groupIdSplit.split("-")[0])
          );
        })
        .map(groupItem => {
          const addedGroupItemInfo = {
            ...groupItem,
            name: groupItem._item[0]._definition[0]["display-name"],
            brand: groupItem._item[0]._definition[0].details.find(
              detail => detail.name === "brand"
            ).value,
            priceNumber: groupItem._price[0]["purchase-price"]
              ? groupItem._price[0]["purchase-price"][0].amount
              : 0,
            category: groupItem._item[0]._definition[0].details.find(
              detail => detail.name.toLowerCase() === "product_category"
            ).value
          };
          return addedGroupItemInfo;
        });

    const ahriNumber =
      groupId !== "undefined" &&
      groupItems &&
      groupItems[0] &&
      groupItems[0]["ahri-number"];
    const skusArray =
      groupItems && groupItems.map(sku => sku._item[0]._code[0].code);
    const productSkuCode = productId;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    const productName = item._item[0]._definition[0]["display-name"] || "";
    const productPrice = item._price[0]["purchase-price"]
      ? item._price[0]["purchase-price"][0].amount
      : 0;
    const productBrand = item._item[0]._definition[0].details.find(
      itemDetail => itemDetail.name === "brand"
    ).value;

    return (
      <Modal
        open={modalIsOpen}
        onClose={this.closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          {groupId !== "undefined" && groupItems && groupItems.length ? (
            <AlternateBranchList
              orderInfo={{
                orderName: ahriNumber,
                orderItemsLength: groupItems.length
              }}
              products={groupItems}
              qtyColumnHeader={intl.get("stock-status")}
              history={history}
              branches={branchesList}
              availaibilityDisplayedWithoutQuantity
              itemQty={productQuantity}
              isAhri
              skusArray={skusArray}
              isCartPage
            />
          ) : (
            <AlternateBranchList
              product={{
                name: productName,
                sku: productSkuCode,
                price: productPrice,
                brand: productBrand
              }}
              qtyColumnHeader={intl.get("stock-status")}
              history={history}
              branches={branchesList}
              itemQty={productQuantity}
              productUri={item.self.uri}
              isCartPage
            />
          )}
        </div>
      </Modal>
    );
  }

  renderPickupBranchLabel = item => {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { findBranch }
    } = this.context;

    let selectedBranchLabel;
    const branchDetails =
      item["branch-number"] && findBranch(item["branch-number"]);
    if (branchDetails) {
      selectedBranchLabel = branchDetails.branchName;
    }

    const activeBranchLabel = findBranch(defaultCart.selectedBranch.code)
      .branchName;

    const pickupBranchLabel =
      item["branch-number"] && item["shipping-method"] === "pickup"
        ? selectedBranchLabel
        : activeBranchLabel;
    return pickupBranchLabel;
  };

  updateShippingMethod = (
    isAhri: boolean,
    updatedShippingMethod: "pickup" | "delivery"
  ) => {
    const {
      auth: { logout },
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      },
      cart: {
        getCartDetails,
        cartDetails: {
          defaultCart: {
            selectedBranch: { code: activeBranchCode }
          }
        }
      },
      account: {
        accountDetails: { homeBranch }
      }
    } = this.context;
    const {
      history,
      handleGroupItemsUpdate,
      item,
      groupId,
      groupName
    } = this.props;
    const { selectedQuantity } = this.state;

    this.setState({ shippingMethodIsLoading: true });

    const itemCode = item._item[0]._code[0].code;

    if (isAhri) {
      return handleGroupItemsUpdate(
        item,
        selectedQuantity,
        groupId,
        groupName,
        this.toggleUpdateLoader,
        this.toggleDeleteLoader,
        updatedShippingMethod,
        updateBranchNumber(
          updatedShippingMethod === "delivery",
          itemCode,
          airPurifierBranchNumber,
          homeBranch,
          activeBranchCode
        ),
        false
      ).finally(() => this.setState({ shippingMethodIsLoading: false }));
    }

    return cortexFetch(item.self.uri, {
      method: "put",
      body: JSON.stringify({
        "shipping-method": updatedShippingMethod,
        "branch-number": updateBranchNumber(
          updatedShippingMethod === "delivery",
          itemCode,
          airPurifierBranchNumber,
          homeBranch,
          activeBranchCode
        ),
        quantity: selectedQuantity
      })
    })
      .then(() => {
        return getCartDetails();
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => updateShippingMethod => cart.lineitem.tsx"
            })
          );
        }
      })
      .finally(() => this.setState({ shippingMethodIsLoading: false }));
  };

  render() {
    const {
      item,
      itemDetailLink,
      isChangeDisabled,
      groupId,
      first,
      handleRemoveBtnClicked,
      activePros,
      inventoryError,
      isErrorMessage,
      notSelectedPickupBranches,
      missingFulfillmentMethod
    } = this.props;
    const {
      selectedQuantity,
      deleteLoader,
      updateLoader,
      availabilityLoader,
      branchAvailability,
      regionAvailability,
      dcAvailability,
      shippingMethodIsLoading,
      imgMissingHorizontal
    } = this.state;

    const isOutOfStock =
      branchAvailability === 0 &&
      regionAvailability === 0 &&
      dcAvailability === 0;
    let itemCodeString = "";
    let itemDisplayName = "";
    let itemThumbnailImage = null;
    let itemAvailability = "";
    let restrictedMaterialAttr = null;
    if (item._item) {
      itemCodeString = item._item[0]._code[0].code;
      itemDisplayName = item._item[0]._definition[0]["display-name"];
      itemThumbnailImage = item._item[0]._definition[0].details.find(
        detail => detail.name === "full_image"
      );
      restrictedMaterialAttr = item._item[0]._definition[0].details.find(
        detail => detail.name === "restricted_materials"
      );
    }
    if (item._code) {
      itemCodeString = item._code[0].code;
    }
    if (item._definition) {
      itemDisplayName = item._definition[0]["display-name"];
    }

    const { scope } = Config.cortexApi;

    if (scope !== "motili") {
      itemAvailability = formatGeneralDGAAvailabilityLabel(
        item.branchAvailability,
        selectedQuantity,
        item.regionAvailability
      );
    }

    const isLoadingAvailability =
      itemAvailability === intl.get("checking-availability");
    const isAhriGroup = groupId !== "undefined";

    return (
      <>
        {this.renderAlternateBranchesModal(itemCodeString, item.quantity)}
        {first && isAhriGroup ? this.renderProsItemsHeader() : null}

        <div
          id={`cart_lineitem_${itemCodeString}`}
          className={`row cart-lineitem-new ${
            isAhriGroup
              ? `pros-lineitem ${activePros.includes(groupId) ? "active" : ""}`
              : ""
          }`}
          style={isAhriGroup ? { backgroundColor: "#f0f0f0" } : {}}
        >
          <div
            className={`col-12 ${
              Config.calculatePrice ? "col-lg-4-custom" : "col-lg-7"
            }`}
          >
            <div className="d-flex product-details-image">
              <Link
                to={{
                  pathname: `${itemDetailLink}/${itemCodeString}`,
                  state: {
                    name: itemDisplayName,
                    prevUrl: window.location.href
                  }
                }}
              >
                <img
                  src={
                    itemThumbnailImage
                      ? itemThumbnailImage.value
                      : imgMissingHorizontal
                  }
                  onError={e => {
                    const element: any = e.target;
                    element.src = imgMissingHorizontal;
                  }}
                  alt={itemDisplayName || intl.get("none-available")}
                  className="cart-lineitem-thumbnail"
                />
              </Link>
              <div className="product-details">
                <Link
                  to={{
                    pathname: `${itemDetailLink}/${itemCodeString}`,
                    state: {
                      name: itemDisplayName,
                      prevUrl: window.location.href
                    }
                  }}
                >
                  {itemDisplayName}
                </Link>
                <ul className="options-container">
                  <li className="part-number-container">
                    <span>{`${intl.get("product")}: `}</span>
                    <span className="part-number">{itemCodeString}</span>
                  </li>
                </ul>
                {Config.calculatePrice && (
                  <div className="d-flex d-block d-lg-none">
                    {this.renderUnitPrice()}
                  </div>
                )}
                {restrictedMaterialAttr &&
                restrictedMaterialAttr.value === "Yes" ? (
                  <div className="certification-required-warning">
                    <ErrorIcon className="debug-msg-icon error" />
                    <span className="bold">
                      {intl.get("product-requires-certification")}
                    </span>
                  </div>
                ) : null}
                <p className="warning-text">{item.warrningMessage}</p>
                <p className="warning-text">{item.error}</p>
                {!deleteLoader ? (
                  groupId === "undefined" && (
                    <button
                      className="item-remove-btn d-block d-lg-none"
                      aria-label="remove item"
                      type="button"
                      onClick={() =>
                        handleRemoveBtnClicked(item, this.toggleDeleteLoader)
                      }
                      disabled={isChangeDisabled}
                    >
                      <span className="btn-text">
                        <i className="icon-close-x" />
                      </span>
                    </button>
                  )
                ) : (
                  <div className="miniLoader d-block d-lg-none" />
                )}
              </div>
            </div>
          </div>
          {Config.calculatePrice && (
            <div className="col-12 col-lg-2-custom d-none d-lg-block">
              <div className="d-flex justify-content-center">
                <div className="d-inline-block d-md-none mr-2">
                  {`${intl.get("price")}: `}
                </div>
                {this.renderUnitPrice()}
              </div>
            </div>
          )}
          <div className="col-6 col-lg-1-custom">
            <div className="d-flex quantity-container justify-content-center flex-column">
              {groupId === "undefined" && (
                <div className="quantity-col form-content justify-content-lg-center">
                  {selectedQuantity !== undefined
                    ? [
                        <span
                          className="input-group-btn"
                          key="quantity-buttons"
                        >
                          <div className="quantity-col form-content form-content-quantity">
                            <input
                              aria-label={`"quantity " + ${selectedQuantity}`}
                              className="product-display-item-quantity-select form-control form-control-quantity"
                              type="number"
                              step="1"
                              min="1"
                              max="9999"
                              value={selectedQuantity}
                              onChange={this.handleInputQuantityChange}
                              onBlur={() => this.updateQuantity(false)}
                              disabled={isChangeDisabled}
                            />
                          </div>
                        </span>
                      ]
                    : ""}
                  {updateLoader && <div className="miniLoader" />}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-3-custom vertically-aligned-mobile">
            {(!inventoryError &&
              (shippingMethodIsLoading ||
                isLoadingAvailability ||
                updateLoader ||
                availabilityLoader)) ||
            inventoryError ? (
              <div className="d-flex justify-content-start justify-content-lg-center">
                {groupId === "undefined" && (
                  <span className="label">
                    {inventoryError
                      ? intl.get("available-for-back-order-label")
                      : intl.get("checking-availability")}
                  </span>
                )}
              </div>
            ) : (
              groupId === "undefined" && (
                <div
                  className={
                    isErrorMessage &&
                    missingFulfillmentMethod.some(
                      branch =>
                        branch.sku ===
                        `${itemCodeString}_${item["shipping-method"]}_${item["branch-number"]}`
                    )
                      ? "cart-shipping-methods pickup-error"
                      : "cart-shipping-methods"
                  }
                >
                  <div
                    className={
                      isErrorMessage &&
                      notSelectedPickupBranches.some(
                        branch =>
                          branch.sku ===
                          `${itemCodeString}_${item["shipping-method"]}_${item["branch-number"]}`
                      ) &&
                      !missingFulfillmentMethod.length &&
                      item["shipping-method"] === "pickup"
                        ? "cart-shipping-method pickup-error"
                        : "cart-shipping-method"
                    }
                  >
                    <input
                      type="radio"
                      id={`pickup ${itemCodeString}_${
                        item["shipping-method"]
                      }_${item["branch-number"]}_${item["contract-number"] ||
                        ""}`}
                      name={`${itemCodeString}_${item["shipping-method"]}_${
                        item["branch-number"]
                      }_${item["contract-number"] || ""}`}
                      checked={
                        (item["shipping-method"] === "pickup" &&
                          !item["branch-number"] &&
                          !isOutOfStock) ||
                        (item["shipping-method"] === "pickup" &&
                          item["branch-number"])
                      }
                      disabled={isSpecialAirPurifier(itemCodeString)}
                      onChange={() => {
                        if (item["shipping-method"] !== "pickup") {
                          this.updateShippingMethod(false, "pickup");
                        }
                      }}
                    />{" "}
                    {isSpecialAirPurifier(itemCodeString) ? (
                      <span className="cart-air-purifier-message">
                        {intl.get("available-only-for-delivery", {
                          sku: itemCodeString
                        })}
                      </span>
                    ) : (
                      <>
                        <label
                          htmlFor={`pickup ${itemCodeString}_${item["shipping-method"]}_${item["branch-number"]}`}
                        >
                          {formatPickupOrDeliveryAvailabilityLabel({
                            branchAvailability,
                            regionAvailability,
                            currentBranch: this.renderPickupBranchLabel(item),
                            isSingleLinedLabel: false,
                            onLabelClick: this.openAlternateBranchesModal,
                            buttonIsLoading:
                              shippingMethodIsLoading ||
                              updateLoader ||
                              availabilityLoader,
                            isCartPage: true,
                            isAhri: false,
                            dcAvailability
                          })}
                        </label>
                      </>
                    )}
                  </div>

                  <div className="cart-shipping-method">
                    <input
                      type="radio"
                      id={`delivery ${itemCodeString}_${
                        item["shipping-method"]
                      }_${item["branch-number"]}_${item["contract-number"] ||
                        ""}`}
                      name={`${itemCodeString}_${item["shipping-method"]}_${
                        item["branch-number"]
                      }_${item["contract-number"] || ""}`}
                      checked={
                        item["shipping-method"] === "delivery" ||
                        (item["shipping-method"] === "pickup" &&
                          !item["branch-number"] &&
                          isOutOfStock)
                      }
                      onChange={() => {
                        if (item["shipping-method"] !== "delivery") {
                          this.updateShippingMethod(false, "delivery");
                        }
                      }}
                    />
                    <label
                      htmlFor={`delivery ${itemCodeString}_${item["shipping-method"]}_${item["branch-number"]}`}
                    >
                      {formatPickupOrDeliveryAvailabilityLabel({
                        branchAvailability,
                        regionAvailability,
                        currentBranch: this.renderPickupBranchLabel(item),
                        isSingleLinedLabel: false,
                        buttonIsLoading:
                          shippingMethodIsLoading ||
                          updateLoader ||
                          availabilityLoader,
                        isDelivery: true,
                        isCartPage: true,
                        isAhri: false
                      })}
                    </label>
                  </div>
                </div>
              )
            )}
          </div>
          {Config.calculatePrice ? (
            <div className="col-12 col-lg-custom">
              <div className="d-flex justify-content-end justify-content-lg-between">
                <div className="d-inline-block d-md-none mr-2">
                  {`${intl.get("ext-price")}: `}
                </div>
                {this.renderTotalPrice()}
                {this.renderCloseButton()}
              </div>
            </div>
          ) : (
            <div className="col-12 col-lg-1-custom">
              {this.renderCloseButton()}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(CartLineItem);
