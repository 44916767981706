/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import * as UserPrefs from "../utils/UserPrefs";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import canadaLogo from "../../../app/src/images/header-icons/CA_logo.png";
import usaLogo from "../../../app/src/images/header-icons/US_logo.png";
import headerLogo from "../../../app/src/images/site-images/Company-Logo-v1.png";

import "./appheaderlocale.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AppHeaderLocaleMainProps {
  isMobileView?: boolean;
  onCurrencyChange?: (...args: any[]) => any;
  onLocaleChange?: (...args: any[]) => any;
  userProfile: any;
  isLoggedIn: boolean;
}
interface AppHeaderLocaleMainState {
  selectedLocaleValue: any;
  selectedCurrencyValue: any;
}

class AppHeaderLocaleMain extends React.Component<
  AppHeaderLocaleMainProps,
  AppHeaderLocaleMainState
> {
  constructor(props) {
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);
    super(props);

    this.state = {
      selectedLocaleValue: UserPrefs.getSelectedLocaleValue(),
      selectedCurrencyValue: ""
    };
  }

  componentDidMount() {
    const { isLoggedIn } = this.props;
    if (!isLoggedIn) {
      this.setState({
        selectedCurrencyValue: UserPrefs.getSelectedCurrencyValue()
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userProfile: { preferedCurrency }
    } = this.props;
    const { selectedCurrencyValue } = this.state;
    if (
      (preferedCurrency && !selectedCurrencyValue) ||
      prevProps.userProfile.preferedCurrency !== preferedCurrency
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedCurrencyValue: UserPrefs.getSelectedCurrencyValue(
          preferedCurrency
        )
      });
    }
  }

  handleLocaleClick(newLocaleValue) {
    const { onLocaleChange } = this.props;
    this.setState({
      selectedLocaleValue: newLocaleValue
    });
    UserPrefs.setSelectedLocaleValue(newLocaleValue);
    onLocaleChange();
  }

  handleCurrencyClick(newCurrencyValue) {
    const { onCurrencyChange } = this.props;
    this.setState({
      selectedCurrencyValue: newCurrencyValue
    });
    UserPrefs.setSelectedCurrencyValue(newCurrencyValue);
    onCurrencyChange();
  }

  render() {
    const { selectedLocaleValue, selectedCurrencyValue } = this.state;
    const selectedLocale = Config.supportedLocales.filter(
      l => l.value === selectedLocaleValue
    )[0];
    const title = `${selectedCurrencyValue}/${selectedLocale.name}`;
    const { isMobileView } = this.props;
    const selectedCurrencyLogo =
      selectedCurrencyValue === "CAD" ? canadaLogo : usaLogo;
    return (
      <div
        className={`main-locale-container ${isMobileView ? "mobile-view" : ""}`}
      >
        <button
          id={`${isMobileView ? "mobile_" : ""}locale-dropdown-trigger`}
          type="button"
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            className="country-logo"
            alt="Header logo"
            src={selectedCurrencyLogo}
            onError={e => {
              const element: any = e.target;
              element.src = headerLogo;
            }}
          />
        </button>
        <div className="dropdown-menu dropdown-menu-right dropdown-margin-right">
          <div className="dropdown-header">{intl.get("language")}</div>
          <button
            type="button"
            disabled
            key="en-US"
            id={`${isMobileView ? "mobile_" : ""}locale-en-US`}
            className={`dropdown-item ${
              selectedLocaleValue === "en-US"
                ? "selected disabled"
                : "not-selected"
            }`}
          >
            {"English"} / {selectedCurrencyValue}
          </button>

          {/* <div className="dropdown-divider" /> */}

          {/* <div className="dropdown-header">{intl.get("currency")}</div>
          {Config.supportedCurrencies.map(currency => (
            <button
              type="button"
              key={currency.value}
              id={`${isMobileView ? "mobile_" : ""}currency-${currency.value}`}
              className={`dropdown-item locale-item ${
                currency.value === selectedCurrencyValue
                  ? "selected disabled"
                  : "not-selected"
              }`}
              onClick={() =>
                currency.value !== selectedCurrencyValue &&
                this.handleCurrencyClick(currency.value)
              }
            >
              {currency.name}
            </button>
          ))} */}
        </div>
      </div>
    );
  }
}

export default AppHeaderLocaleMain;
