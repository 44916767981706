/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BrProps } from "@bloomreach/react-sdk";
import {
  FunctionalProductDisplayItemMain,
  ProductDisplayItemMain,
  page
} from "@zilker/store-components";

interface ProductDetailPageProps extends RouteComponentProps, BrProps {
  history: any;
  match: any;
  location: any;
}
interface ProductDetailPageState {
  url: string;
}
class ProductDetailPage extends React.Component<
  ProductDetailPageProps,
  ProductDetailPageState
> {
  constructor(props) {
    super(props);
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.onReloadPage = this.onReloadPage.bind(this);
    this.handleAddToWishList = this.handleAddToWishList.bind(this);
    this.handleChangeProductFeature = this.handleChangeProductFeature.bind(
      this
    );
  }

  componentDidMount() {
    page();
  }

  handleAddToCart() {
    const { history } = this.props;
    history.push("/mycart");
  }

  onReloadPage() {
    const { history, location } = this.props;
    history.push(location.pathname);
  }

  handleAddToWishList() {
    const { history } = this.props;
    history.push("/wishlists");
  }

  handleChangeProductFeature(path) {
    const { history } = this.props;
    history.push(`/itemdetail/${path}`);
  }

  render() {
    const handleProductLink = window.location.href;
    const windowNavigator: any = window.navigator;
    const isInStandaloneMode = windowNavigator.standalone;
    const { history, location } = this.props;
    let rawProductId;
    if (location.pathname.includes("itemdetail")) {
      rawProductId = location.pathname.slice(12).trim();
    }
    return (
      <div className="product-display-page">
        <FunctionalProductDisplayItemMain
          productId={rawProductId}
          onChangeProductFeature={this.handleChangeProductFeature}
          onAddToCart={this.handleAddToCart}
          onAddToWishList={this.handleAddToWishList}
          productLink={handleProductLink}
          isInStandaloneMode={isInStandaloneMode}
          itemDetailLink="/itemdetail"
          onReloadPage={this.onReloadPage}
          history={history}
          location={location}
        />
      </div>
    );
  }
}

export default withRouter<ProductDetailPageProps, any>(ProductDetailPage);
