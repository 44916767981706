import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";
import RichText from "../RichText/rich-text";

// Blog list currently does not render correctly. The blog articles don't come back in the pagemodel response.
// Might be an issue with essentials after the upgrade (BR 14, Pagemodel API 1.0). The blog list container definition comes through but
// none of the attached content
const BlogList = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return <>No Blog List to display</>;
  }

  const { contents, link, image, title, linktitle } = document.getData();

  const renderItems = () => {
    return contents.map((content, index) => {
      return (
        <div className="row mb-3" key={content.id}>
          <div className="col-12">
            <h3>{content.title}</h3>
          </div>
          <div className="col-12">
            <RichText content={content.value} />
          </div>
        </div>
      );
    });
  };

  return <div className="container">{renderItems()}</div>;
};

export default BlogList;
