import React from "react";
import intl from "react-intl-universal";
import "./BranchChangeComponent.less";

interface BranchChangeProps {
  isLoading?: boolean;
  userSelectionConfirmed: (...args: any[]) => any;
}

const BranchChangeComponent: React.FC<BranchChangeProps> = props => {
  const { userSelectionConfirmed, isLoading } = props;
  const confirmUserSelection = () => {
    userSelectionConfirmed();
  };

  return (
    <div className="branch-changed-modal">
      <div className="modal-header">
        <h2 className="modal-title">{intl.get("change-branch")}</h2>
      </div>
      <ul>
        <li>{intl.get("branch-change-delivery-items-updated")}</li>
      </ul>
      <div className="confirm-btn">
        {isLoading ? (
          <div className="miniLoader" />
        ) : (
          <button
            aria-label={intl.get("confirm")}
            type="button"
            className="dast-btn dast-btn-primary"
            onClick={() => {
              confirmUserSelection();
            }}
          >
            {intl.get("confirm")}
          </button>
        )}
      </div>
    </div>
  );
};
export default BranchChangeComponent;
