import React, { FC, useEffect, useState, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { History } from "history";
import {
  SavedOrderDetailComponent,
  page,
  productAdded,
  productViewed,
  getConfig,
  AlternateBranchList
} from "@zilker/store-components";
import {
  getAvailability,
  getStandardOrderDetailsGQL,
  orderSummaryGQL,
  checkEntitlementGQL
} from "@elasticpath/ref-store/src/services/connectGQLservices";
import { brSearch } from "../services/SearchService";
import { EntitlementDetails } from "./PartsFinderPage";
import {
  checkTokensExpired,
  pushToMaintenace,
  generateSpecificErrorMessage,
  InventoryAvailabilityInterface,
  isSpecialAirPurifier,
  updateBranchNumber,
  formatQuoteAvailability,
  handleCustomException
} from "../utils/helpers";
import { MainContext } from "../contexts/MainContext";

import { addToCart } from "../services/EpServices";
import "./SavedOrderDetails.less";

interface StandardOrderDetailsProps {
  orderName: string;
  order?: any;
  history: History;
  logout: any;
}

interface CartDetailInterface {
  branchAvailability?: number;
  regionAvailabiliy?: number;
  sequenceNumber: number;
  sku: string;
  description: string;
  unitOfMeasure: string;
  quantity: number;
  price: number;
  brand: string;
  title: string;
  class: string;
}

interface OrderSummaryInterface {
  subTotal: any;
  total: any;
  tax: any;
}

const StandardOrderDetails: FC<StandardOrderDetailsProps> = ({
  orderName,
  order,
  history,
  logout
}) => {
  const [cartData, setCartData] = useState<CartDetailInterface[]>([]);
  const [orderSummary, setOrderSummary] = useState<OrderSummaryInterface>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPickupOrDelivery, setLoadingPickupOrDelivery] = useState<
    string
  >(null);
  const [qtyChangeDisabled, setQtyChangeDisabled] = useState<boolean>(false);
  const [removeItemDisabled, setRemoveItemDisabled] = useState<boolean>(false);
  const [standardOrder, setStandardOrder] = useState<any>(null);
  const [standardOrderDetails, setStandardOrderDetails] = useState<any>(null);
  const [orderPrices, setOrderPrices] = useState<any>(null);
  const [invalidSku, setInvalidSku] = useState<string>("");
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [branch, setBranch] = useState<{ code: number; vendor: string }>(null);
  const [inventoryError, setInventoryError] = useState<string>("");
  const [isProductBeingAdded, setIsProductBeingAdded] = useState({
    sku: "",
    shippingMethod: ""
  });
  const [entitlements, setEntitlements] = useState<EntitlementDetails[]>(null);

  const context = useContext<{
    cart: any;
    user: any;
    branches: any;
    account: any;
    auth: any;
  }>(MainContext);
  const {
    cart: {
      setSuccesCartPopupMessage,
      setErrorCartPopupMessage,
      getCartDetails,
      cartDetails: { defaultCart }
    },
    account: {
      accountDetails: { customerNumber, customerRoles, homeBranch }
    },
    branches: {
      branchesList,
      airPurifierBranch: { branchNumber: airPurifierBranchNumber }
    }
  } = context;
  const orderNumber = order && order.orderNumber;

  const location = useLocation();

  const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
  const selectedBranchNumber = selectedBranch && selectedBranch.code;
  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const { config } = getConfig();

  const hasSpecialAirPurifiers =
    cartData && Boolean(cartData.find(({ sku }) => isSpecialAirPurifier(sku)));

  const addCartToDefaultCart = (shippingMethod: "pickup" | "delivery") => {
    const { addItemsToCart } = defaultCart;

    const items = cartData.map(item => {
      return {
        code: item.sku,
        quantity: item.quantity,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          item.sku,
          airPurifierBranchNumber,
          homeBranch,
          selectedBranchNumber
        ),
        "shipping-method": shippingMethod
      };
    });
    return addToCart(addItemsToCart.self.uri, { items });
  };

  const addItemToDefaultCart = (
    foundItem: CartDetailInterface[],
    shippingMethod: "pickup" | "delivery"
  ) => {
    const items = foundItem.map(item => {
      const itemCode = item.sku;
      return {
        code: itemCode,
        quantity: item.quantity,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          itemCode,
          airPurifierBranchNumber,
          homeBranch,
          selectedBranchNumber
        ),
        "shipping-method": shippingMethod
      };
    });

    const { addItemsToCart } = defaultCart;
    return addToCart(addItemsToCart.self.uri, { items });
  };

  const handleAddToCart = (shippingMethod: "pickup" | "delivery") => {
    setLoadingPickupOrDelivery(shippingMethod);

    const analyticsItems = cartData
      .map(element => {
        const isEntitled = entitlements.find(prod => prod.sku === element.sku)
          .entitled;
        if (isEntitled) {
          return {
            id: element.sku,
            quantity: element.quantity,
            category: element.class,
            name: element.title,
            brand: element.brand,
            price: element.price
          };
        }
        return undefined;
      })
      .filter(item => item);

    const itemQuantity = analyticsItems.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);

    addCartToDefaultCart(shippingMethod)
      .then(() => {
        // sends information to Segment for every product user adds
        analyticsItems.forEach(item =>
          productAdded(
            item.name,
            item.id,
            item.price,
            item.brand,
            item.category,
            item.quantity
          )
        );
      })
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        setLoadingPickupOrDelivery("");
        setSuccesCartPopupMessage(itemQuantity);
      })
      .catch(e => {
        setLoadingPickupOrDelivery("");

        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddToCart => StandardOrderDetails.tsx"
            })
          );
        } else {
          setErrorCartPopupMessage(generateSpecificErrorMessage(e, orderName));
        }
      });
  };

  const handleAddSingleItemTypeToCart = (
    sku: string,
    shippingMethod: "pickup" | "delivery",
    groupId?: string,
    setSelectedQuantity?: any
  ) => {
    setIsProductBeingAdded({ sku, shippingMethod });

    const foundItem = cartData.filter(item => item.sku === sku);
    if (foundItem.length > 0) {
      const analyticsItems = foundItem.map(item => {
        return {
          id: item.sku,
          quantity: item.quantity,
          category: item.class,
          name: item.title,
          brand: item.brand,
          price: item.price
        };
      });

      addItemToDefaultCart(foundItem, shippingMethod)
        .then(() => {
          // sends information to Segment
          analyticsItems.forEach(analyticsItem => {
            productViewed(
              analyticsItem.name,
              analyticsItem.id,
              analyticsItem.price,
              analyticsItem.brand,
              analyticsItem.category
            );
            productAdded(
              analyticsItem.name,
              analyticsItem.id,
              analyticsItem.price,
              analyticsItem.brand,
              analyticsItem.category,
              analyticsItem.quantity
            );
          });
        })
        .then(() => {
          return getCartDetails();
        })
        .then(() => {
          setIsProductBeingAdded({ sku: "", shippingMethod: "" });
          setSuccesCartPopupMessage(foundItem[0].quantity);
          setSelectedQuantity(1);
        })
        .catch(e => {
          setIsProductBeingAdded({ sku: "", shippingMethod: "" });
          setSelectedQuantity(1);
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => handleAddSingleItemTypeToCart => StandardOrderDetails.tsx"
              })
            );
          } else {
            setErrorCartPopupMessage(
              generateSpecificErrorMessage(e, orderName)
            );
          }
        });
    }
  };

  const disableChangesFromItem = (val: boolean): void => {
    setQtyChangeDisabled(!!invalidSku || val);
    setRemoveItemDisabled(val);
  };

  const formatFqParam = details => {
    let fqParam = details.reduce((prevPartNumbers, currPart) => {
      return !prevPartNumbers
        ? `pid:("${currPart}"`
        : `${prevPartNumbers} OR "${currPart}"`;
    }, "");
    fqParam += ")";

    return fqParam;
  };

  const formatOrderWithDetails = (orderDetails, res) => {
    const finalOrderDetails = [];
    orderDetails.forEach(detail => {
      const finalDetail = res.find(item => item.pid === detail.sku);
      if (finalDetail) {
        finalOrderDetails.push(finalDetail);
      } else {
        finalOrderDetails.push({
          pid: detail.sku,
          description: detail.description,
          title: detail.description,
          brand: ""
        });
      }
    });
    return finalOrderDetails;
  };

  const formatPrices = async orderItems => {
    const { jobNumber } = defaultCart;
    const priceRequestBody = {
      customerNumber,
      shipments: [
        {
          branchNumber: selectedBranch.code,
          customerPickup: false,
          items: orderItems.map(part => ({
            sku: part.pid,
            quantity: 1
          }))
        }
      ],
      jobNumber
    };

    try {
      const { data } = await orderSummaryGQL(priceRequestBody);
      const { items }: { items } = data.data.quote.shipments[0];
      const { subTotal, total, tax } = data.data.quote;
      setOrderPrices(items);

      setInvalidSku("");
      setQtyChangeDisabled(false);
      setOrderSummary({ subTotal, total, tax });
    } catch (error) {
      const errorPath = "formatPrices => StandardOrderDetails.tsx";
      handleCustomException(error, logout, history, errorPath);
    }
  };

  const fetchOrderDetails = async details => {
    const {
      account: {
        accountDetails: { membership }
      }
    } = context;
    const orderDetailsSkus = details.map(det => det.sku);

    const fqParam = formatFqParam(orderDetailsSkus);

    const { state } = location;
    let prev = "";
    if (!(typeof state === "undefined")) {
      prev = location.state.prevUrl;
    }
    const prevUrl = prev;
    try {
      const orderItems = await brSearch(
        "",
        "keyword",
        0,
        105,
        "",
        fqParam,
        window.location.href,
        prevUrl,
        null,
        membership
      );
      const {
        response: { docs }
      } = orderItems.data;
      const finalOrder = formatOrderWithDetails(details, docs);
      setStandardOrderDetails(finalOrder);
      if (defaultCart) {
        formatPrices(finalOrder);
      }
    } catch (error) {
      if (checkTokensExpired(error)) {
        logout().catch(logoutErr =>
          pushToMaintenace(history, {
            e: logoutErr,
            errIn: "Logout => fetchOrderDetails => StandardOrderDetails.tsx"
          })
        );
      } else {
        console.error(error);
        pushToMaintenace(history, {
          e: error,
          errIn: "fetchOrderDetails => StandardOrderDetails.tsx"
        });
      }
    }
  };

  const getStandardOrderDetails = () => {
    return getStandardOrderDetailsGQL(customerNumber, orderNumber).then(
      standardOrderResponse => {
        let standardOrderRes = null;
        try {
          standardOrderRes =
            standardOrderResponse.data.data.customer.standardOrder;
          setStandardOrder(standardOrderRes);
          const { detail } = standardOrderRes;
          fetchOrderDetails(detail);
        } catch (err) {
          if (checkTokensExpired(err)) {
            logout().catch(logoutErr =>
              pushToMaintenace(history, {
                e: logoutErr,
                errIn:
                  "Logout => getStandardOrderDetails => StandardOrderDetails.tsx"
              })
            );
          } else {
            console.error(err);
            pushToMaintenace(history, {
              e: err,
              errIn: "getStandardOrderDetails => StandardOrderDetails.tsx"
            });
          }
        }
      }
    );
  };

  const fetchAvailability = async (orderItems, currentBranch) => {
    if (branchesList && orderItems) {
      const skusArr = orderItems.map(item => item.sku);
      getAvailability(skusArr, customerNumber, currentBranch.code)
        .then(res => {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.inventory &&
            res.data.data.inventory.entitledInventory
          ) {
            const availabilityData = res.data.data.inventory.entitledInventory;
            const inventoryAvailability: InventoryAvailabilityInterface[] = formatQuoteAvailability(
              availabilityData.branches,
              availabilityData.regionRollups,
              skusArr,
              availabilityData.hubInventory
            );
            setInventoryError("");
            if (inventoryAvailability) {
              const itemsWithAvailability = orderItems.map(item => {
                const inventoryItem = inventoryAvailability.find(
                  ia =>
                    ia.sku === item.sku &&
                    ia.branchNumber === currentBranch.code
                );
                return inventoryItem
                  ? {
                      ...item,
                      branchAvailability: inventoryItem.branchAvailability,
                      regionAvailability: inventoryItem.regionAvailability,
                      dcAvailability: inventoryItem.dcQtyAvailable,
                      quantity: 1
                    }
                  : {
                      ...item,
                      branchAvailability: 0,
                      regionAvailability: 0,
                      dcAvailability: 0,
                      quantity: 1
                    };
              });
              if (standardOrderDetails && orderPrices) {
                const mappedOrder = itemsWithAvailability.map(item => {
                  const detailItem = standardOrderDetails.find(
                    ord => ord.pid === item.sku
                  );
                  const itemWithPrice = orderPrices.find(
                    it => it.sku === item.sku
                  );

                  return {
                    ...item,
                    ...detailItem,
                    lineTotal: itemWithPrice.lineTotal,
                    price: itemWithPrice.price,
                    quantity: itemWithPrice.quantity
                  };
                });
                setCartData(mappedOrder);
                setLoading(false);
              }
            }
          }
        })
        .catch(e => {
          console.error("Inventory error", e);
          setInventoryError(intl.get("inventory-error"));
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => fetchAvailability => StandardOrderDetails.tsx"
              })
            );
          }
        });
    }
  };

  const populateSavedOrderData = () => {
    fetchAvailability(standardOrder.detail, selectedBranch);
    setBranch(selectedBranch);
  };

  useEffect(() => {
    if (orderNumber) {
      page();
      getStandardOrderDetails();
    }
  }, [orderNumber, selectedBranch]);

  useEffect(() => {
    if (
      (standardOrder && (cartData && !cartData.length)) ||
      (branch && branch.code !== selectedBranch.code)
    ) {
      populateSavedOrderData();
    }
  }, []);

  useEffect(() => {
    if (standardOrder) {
      populateSavedOrderData();
    }
  }, [orderPrices]);

  useEffect(() => {
    if (!invalidSku) setQtyChangeDisabled(false);
  }, [invalidSku]);

  useEffect(() => {
    if (branchesList && cartData && cartData.length && branch) {
      fetchAvailability(cartData, branch);
    }
  }, [branchesList]);

  const validateEntitlement = async () => {
    if (config.entitlementCheck && selectedBranch && selectedBranch.vendor) {
      const skus = cartData.map(item => item.sku);
      if (skus && skus.length) {
        try {
          const { data } = await checkEntitlementGQL(customerNumber, skus);
          if (data && data.data && data.data.customer) {
            if (!data.data.customer.productEntitlements) {
              setEntitlements([]);
            } else {
              setEntitlements(data.data.customer.productEntitlements);
            }
          }
        } catch (e) {
          console.error(e);
          setEntitlements([]);
        }
      }
    }
  };

  useEffect(() => {
    if (cartData && customerNumber && selectedBranch) {
      validateEntitlement();
    }
  }, [cartData, customerNumber, selectedBranch]);

  const checkEntitlement = item => {
    const entitlementForItem = entitlements.find(prod => prod.sku === item.sku);
    return entitlementForItem.entitled;
  };

  const groupCartProductsQuantityBySku = () => {
    if (cartData) {
      const uniqueSkus = Array.from(new Set(cartData.map(item => item.sku)));

      const uniqueProductsArray = [];
      uniqueSkus.forEach((uniqueSku, index) => {
        uniqueProductsArray.push({
          sku: uniqueSku,
          qtyEntered: 0,
          qtyAvailable: 0,
          name: "",
          brand: ""
        });

        cartData.forEach(item => {
          if (item.sku === uniqueSku) {
            uniqueProductsArray[index].qtyEntered += item.quantity;
            uniqueProductsArray[index].name = item.title;
            uniqueProductsArray[index].brand = item.brand;
          }
          if (item.branchAvailability) {
            uniqueProductsArray[index].qtyAvailable = item.branchAvailability;
          }
        });
      });
      return uniqueProductsArray;
    }
    return [];
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const skusArray = cartData && cartData.map(({ sku }) => sku);

  const renderAlternateBranchesModal = () => {
    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={isModalOpened}
        onClose={closeModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            orderInfo={{
              orderName,
              orderItemsLength: new Set(cartData.map(cartItem => cartItem.sku))
                .size
            }}
            products={groupCartProductsQuantityBySku()}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branchesList}
            availaibilityDisplayedWithoutQuantity
            skusArray={skusArray}
            isSavedOrderDetails
          />
        </div>
      </Modal>
    );
  };

  const isLoadingOrderForPickup = loadingPickupOrDelivery === "pickup";

  const isLoadingOrderForDelivery = loadingPickupOrDelivery === "delivery";

  const isLoadingOrderToCart =
    isLoadingOrderForPickup || isLoadingOrderForDelivery;

  const isDisabledAddOrderForPickup =
    hasSpecialAirPurifiers ||
    removeItemDisabled ||
    isLoadingOrderForDelivery ||
    (isProductBeingAdded && isProductBeingAdded.sku !== "");

  const isDisabledAddOrderForDelivery =
    removeItemDisabled ||
    isLoadingOrderForPickup ||
    (isProductBeingAdded && isProductBeingAdded.sku !== "");

  if (loading) return <div className="loader" />;

  return (
    <>
      <div className="saved-order-details content-box content-table">
        {standardOrder && cartData ? renderAlternateBranchesModal() : null}
        <h2 className="saved-order-title">
          <NavLink to="/myAccount/standardOrders" className="back-button">
            <span>{intl.get("back")}</span>
          </NavLink>
          <span className="title">{orderName}</span>
          {!isVirtualBranchUser && (
            <button
              type="button"
              className="availability-button"
              onClick={openModal}
            >
              <i className="icon-home" />
              {intl.get("check-order-availability")}
            </button>
          )}
        </h2>

        {cartData && cartData.length ? (
          <div className="cards-headers table-labels">
            <div className="card-header-product label">
              {intl.get("product")}
            </div>
            {config.calculatePrice && (
              <div className="card-header-price label">{intl.get("price")}</div>
            )}
            <div className="card-header-quantity label">
              {intl.get("quantity")}
            </div>
            <div className="card-header-availability label">
              {intl.get("availability")}
            </div>
            {config.calculatePrice && (
              <div className="card-header-ext-price label">
                {intl.get("ext-price")}
              </div>
            )}

            <div className="card-header-add-to-cart label">
              {intl.get("add-to-cart")}
            </div>
          </div>
        ) : (
          <span>{intl.get("there-are-no-standard-items-to-display")}</span>
        )}
        {cartData &&
        cartData.length &&
        entitlements &&
        standardOrderDetails &&
        orderPrices
          ? cartData.map((item, index) => (
              <SavedOrderDetailComponent
                cartDetail={item}
                key={`${item.sku}`}
                setIsChangeDisabled={disableChangesFromItem}
                qtyChangeDisabled={qtyChangeDisabled}
                removeItemDisabled={removeItemDisabled}
                refreshCartDetails={getStandardOrderDetails}
                hasError={item.sku === invalidSku}
                groupId="undefined"
                handleAddSingleItemTypeToCart={handleAddSingleItemTypeToCart}
                inventoryError={inventoryError}
                isProductBeingAdded={isProductBeingAdded}
                isLoadingOrderToCart={isLoadingOrderToCart}
                entitled={checkEntitlement(item)}
                isStandardOrder
                setOrderPrices={setOrderPrices}
                setOrderSummary={setOrderSummary}
                cartData={cartData}
                itemIsNotFromCatalog={standardOrderDetails.some(
                  standardOrderItem =>
                    item.sku === standardOrderItem.pid &&
                    !standardOrderItem.price
                )}
                loadingPickupOrDelivery={loadingPickupOrDelivery}
              />
            ))
          : null}
      </div>
      {orderSummary && cartData && cartData.length ? (
        <div className="saved-order-details-add-to-cart-box">
          {config.calculatePrice && (
            <div>
              <span className="saved-order-details-total-label">
                {intl.get("todays-subtotal").toUpperCase()}:
              </span>
              <span className="saved-order-details-total-value">
                ${orderSummary.subTotal}
              </span>
            </div>
          )}
          {config.calculatePrice && (
            <div className="saved-order-details-total">
              <span className="saved-order-details-total-label">
                {intl.get("estimated-total").toUpperCase()}:
              </span>
              <span className="saved-order-details-total-value">
                ${orderSummary.total}
              </span>
            </div>
          )}
          <div className="pickup-delivery-buttons">
            {!isLoadingOrderForPickup ? (
              <button
                className="dast-btn dast-btn-primary"
                aria-label={intl.get("add-all-as-pick-up")}
                type="button"
                disabled={isDisabledAddOrderForPickup}
                onClick={() => {
                  handleAddToCart("pickup");
                }}
              >
                {intl.get("add-all-as-pick-up")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
            {!isLoadingOrderForDelivery ? (
              <button
                className="dast-btn dast-btn-primary"
                aria-label={intl.get("add-all-as-delivery")}
                type="button"
                disabled={isDisabledAddOrderForDelivery}
                onClick={() => {
                  handleAddToCart("delivery");
                }}
              >
                {intl.get("add-all-as-delivery")}
              </button>
            ) : (
              <div className="miniLoader" />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default StandardOrderDetails;
