import React, { useState } from "react";
// eslint-disable-next-line import/prefer-default-export
export const JobContext = () => {
  const [persistedJobNumber, setPersistedJobNumber] = useState("");
  const [persistedJobName, setPersistedJobName] = useState("");

  return {
    persistedJobNumber,
    setPersistedJobNumber,
    persistedJobName,
    setPersistedJobName
  };
};
