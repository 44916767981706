import React, { FC, useContext } from "react";
import intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { SystemBuilderDCS } from "@zilker/store-components";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { RouteComponentProps } from "react-router";

import "./LeadTracking.less";

interface SystemBuilderProps extends RouteComponentProps, BrProps {
  history: any;
  location: any;
  match: any;
}

const SystemBuilder: FC<SystemBuilderProps> = ({
  match,
  history,
  location
}) => {
  return <SystemBuilderDCS />;
};

export default SystemBuilder;
