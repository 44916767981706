import React, { FC, useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { History } from "history";

import { getTaxonomy, getAssets } from "../services/connectServices";
import { checkTokensExpired, pushToMaintenace } from "../utils/helpers";
import { MainContext } from "../contexts/MainContext";

import "./DocumentsLibrary.less";

interface DocumentsLibraryProps extends RouteComponentProps {
  history: History;
}
interface Document {
  assetType: string;
  url: string;
}
interface Product {
  sku: string;
  brand: string;
  description: string;
  category: string;
  subCategory: string;
  family: string;
  familyDescription: string;
  documents?: Document[];
  error?: string;
}

interface Family {
  sku: string;
  products: Product[];
  description: string;
  category: string;
  subcategory: string;
}

interface SubCategory {
  category: string;
  subCategory: string;
  families: Family[];
}

interface Category {
  category: string;
  subCategories: SubCategory[];
}

interface Breadcrumbs {
  category: Category;
  subCategory: SubCategory;
  family: Family;
}

const DocumentsLibrary: FC<DocumentsLibraryProps> = ({ history }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [families, setFamilies] = useState<Family[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory>(
    null
  );
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs>({
    category: null,
    subCategory: null,
    family: null
  });

  const context = useContext<{ auth: any }>(MainContext);
  const {
    auth: { logout }
  } = context;

  useEffect(() => {
    fetchCategories();
  }, []);

  async function fetchCategories() {
    try {
      const { data } = await getTaxonomy();
      setCategories(data.result.categories);
      setBreadcrumbs({
        ...breadcrumbs,
        category: data.result.categories[0],
        subCategory: data.result.categories[0].subCategories[0]
      });
      setSelectedSubCategory(data.result.categories[0].subCategories[0]);
      setFamilies(data.result.categories[0].subCategories[0].families);
    } catch (e) {
      if (checkTokensExpired(e)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => fetchCategories => DocumentsLibrary.tsx"
          })
        );
      } else {
        pushToMaintenace(history, {
          e,
          errIn: "fetchCategories => DocumentsLibrary.tsx"
        });
      }
    }
  }

  const onSubCategoryClick = subCategory => {
    const parentCategory = categories.find(
      cat => cat.category === subCategory.category
    );
    setBreadcrumbs({
      category: parentCategory,
      subCategory,
      family: null
    });
    setSelectedSubCategory(subCategory);
    setFamilies(subCategory.families);
    setProducts([]);
  };

  const onFamilyClick = family => {
    setBreadcrumbs({
      ...breadcrumbs,
      family
    });
    setProducts(family.products);
  };

  const onProductClick = async product => {
    const { documents, sku } = product;
    try {
      if (!documents) {
        const { data } = await getAssets(sku);
        const updatedProducts = products.map(prod =>
          prod.sku === sku
            ? { ...prod, documents: data.result[0].assets, error: "" }
            : prod
        );
        setProducts(updatedProducts);
      }
    } catch (e) {
      const updatedProducts = products.map(prod =>
        prod.sku === sku
          ? {
              ...prod,
              error: intl.get("product-documents-error"),
              documents: null
            }
          : prod
      );
      setProducts(updatedProducts);
    }
  };

  const renderSidebar = () => {
    return (
      <div className="sidebar">
        <div className="sticky-sidebar">
          {categories.map(({ category, subCategories }, index) => (
            <div key={category} className="mb-1">
              <button
                className="category"
                type="button"
                data-toggle="collapse"
                data-target={`#${category.split(" ").join("")}`}
                aria-expanded={index === 0 ? "true" : "false"}
                aria-controls={category.split(" ").join("")}
              >
                {category}
              </button>

              <div
                id={category.split(" ").join("")}
                className={`collapse sub-categories ${
                  index === 0 ? "show" : ""
                }`}
              >
                {subCategories.map(subCategory => {
                  const { subCategory: subCategoryName } = subCategory;
                  return (
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#sidebar-mobile"
                      aria-expanded="false"
                      aria-controls="sidebar-mobile"
                      key={subCategoryName}
                      className={`sub-category ${
                        selectedSubCategory &&
                        selectedSubCategory.subCategory === subCategoryName
                          ? "active"
                          : ""
                      }`}
                      onClick={() => onSubCategoryClick(subCategory)}
                    >
                      {subCategoryName}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSidebarMobile = () => {
    return (
      <div className="col-12 d-lg-none">
        <h4 className="main-title ">
          {intl.get("documents-library")}
          <button
            type="button"
            className="collapse d-block d-lg-none"
            data-toggle="collapse"
            data-target="#sidebar-mobile"
            aria-expanded="false"
            aria-controls="sidebar-mobile"
          >
            <span className="icon glyphicon glyphicon-align-justify" />
          </button>
        </h4>
        <div id="sidebar-mobile" className="collapse sidebar-mobile">
          {renderSidebar()}
        </div>
      </div>
    );
  };

  const renderBreadcrumbs = () => {
    const { category, subCategory, family } = breadcrumbs;
    return (
      <ul className="bread-crumbs">
        {category && (
          <li key="category">
            <button type="button" className="label">
              {category.category}
            </button>
          </li>
        )}
        {subCategory && (
          <li key="subCategory">
            <button
              type="button"
              className="label"
              onClick={() => onSubCategoryClick(subCategory)}
            >
              {subCategory.subCategory}
            </button>
          </li>
        )}
        {family && (
          <li key="family">
            <button
              type="button"
              className="label"
              onClick={() => onFamilyClick(family)}
            >
              {`${family.sku} ${
                family.description ? `- ${family.description}` : ""
              }`}
            </button>
          </li>
        )}
      </ul>
    );
  };

  if (!categories || !categories.length) {
    return <div className="loader" />;
  }

  return (
    <div className="container documents-library-conatiner">
      <div className="content-box">
        <h4 className="d-none d-lg-block">{intl.get("documents-library")}</h4>
        <div className="row">
          <div className="d-none d-sm-none d-md-none d-lg-block col-lg-4">
            {renderSidebar()}
          </div>
          {renderSidebarMobile()}
          <div className="col-12 col-lg-8">
            <div className="content">
              {renderBreadcrumbs()}
              {!products.length ? (
                <div className="families">
                  <h1 className="content-title">{intl.get("families")}</h1>
                  <ul>
                    {families.map(family => {
                      const { sku, description } = family;
                      return (
                        <li key={sku}>
                          <button
                            type="button"
                            className="family"
                            onClick={() => onFamilyClick(family)}
                          >
                            {`${sku} ${description ? `- ${description}` : ""}`}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {products.length ? (
                <div className="products">
                  <h1 className="content-title">{intl.get("products")}</h1>
                  {products.map(product => {
                    const { sku, description, documents, error } = product;
                    return (
                      <div key={sku} className="card card-body">
                        <button
                          type="button"
                          data-toggle="collapse"
                          data-target={`#${sku}`}
                          aria-expanded="false"
                          aria-controls={sku}
                          onClick={() => onProductClick(product)}
                        >
                          {`${sku} - ${description}`}
                        </button>

                        <div id={sku} className="collapse documents">
                          <p className="error">{error}</p>
                          {documents
                            ? documents.map(({ assetType, url }) => (
                                <a
                                  className="document"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={url}
                                  key={url}
                                >
                                  {assetType.replace(/([A-Z])/g, " $1")}
                                </a>
                              ))
                            : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(DocumentsLibrary);
