/* eslint-disable camelcase */
import { History, Location } from "history";

export const formatFacets = (facets: { [name: string]: any }) => {
  let filter: string = "";
  const filtersCheckedKeys = Object.keys(facets);
  filtersCheckedKeys.map(flName => {
    const section = Array.from(facets[flName]);
    if (section.length) {
      filter += `${flName}:`;
      filter += section.length > 1 ? section.join("OR") : section.join("");
    }
    return null;
  });
  return filter;
};

export interface SingleProduct {
  branchAvailability?: number;
  brand: string;
  class?: string;
  description: string;
  entitled?: boolean;
  full_image?: string;
  pid: string;
  price: number;
  price_range?: Array<number>;
  productPrice?: number | string;
  regionAvailability?: number;
  sale_price?: number;
  sale_price_range?: Array<number>;
  thumb_image: string;
  title: string;
  url: string;
  variants?: Array<{ sku_swatch_images: string[]; sku_thumb_images: string[] }>;
  dcQtyAvailable?: any;
}

export const filterFacetFields = facets => {
  const filterItems = [];

  if (Object.keys(facets).length) {
    Object.keys(facets).forEach(facetName => {
      if (facets[facetName].length) {
        if (!filterItems[facetName]) {
          filterItems[facetName] = new Set();
        }
        facets[facetName].forEach(item => {
          filterItems[facetName].add({ ...item, type: facetName });
        });
      }
    });
  }

  return filterItems;
};

/**
 * This check is added to prevent exceeding the BRSM limit of 200 rows per request (will throw CORS error).
 * Check if limit is exceeded, and default to 'maxRows' (Math.floor(200 / rows) * rows) if it is.
 * Assume that user browsed items over 200. After the user returns from PDP, fetch last 'maxRows' items - maximum quantity before 200,
 * and on load more, fetch 'rows', starting from 'maxRows', and so on.
 * The location state keeps itemsLoaded value (also the session storage - when history action is equal to POP).
 * If items that are loaded are less than 'maxRows', fetch that portion of items. For example, if only 63 items are loaded,
 * start will be 0, rowsParam will be itemsLoaded. If batch is greater than maxRows, then rowsParam = maxRows and start is maxStart.
 * Logic on load more for start from index will be (maxStart+1)*rows = maxRows - which is correct.
 */
export const handleBrSearchLimit = (
  location: Location,
  rows: number,
  fromStorage: boolean = false
): {
  startParamValue: number;
  startValue: number;
} => {
  const { state } = location;
  const searchResultParams = JSON.parse(
    sessionStorage.getItem("searchResultParams")
  );

  const itemsLoaded = fromStorage
    ? searchResultParams.itemsLoaded
    : state && state.itemsLoaded;

  const startValue = fromStorage
    ? searchResultParams.start
    : state && state.start;

  const maxRows = Math.floor(200 / rows) * rows;
  const maxStart = maxRows / rows - 1;

  const start = startValue && itemsLoaded < maxRows ? startValue : maxStart; // start maps to maxRows
  const startParam = 0;

  return {
    startParamValue: startParam,
    startValue: start
  };
};

export const clearItemFromSessionStorage = () => {
  sessionStorage.removeItem("searchResultParams");
  sessionStorage.removeItem("sku");
};

/**
 * There are two paths - how the page parameters are loaded:
 *  1. history.action === "POP" - On initial load, on calling history.(go|goBack|goForward),
 *     and when navigating using the browser's forward/back buttons - parameters are pulled from the session storage
 *     (populated in productdisplayitem.main.tsx, from location state).
 *  2. By clicking breadcrumbs (on PDP) - parameters are read from the location state object
 *     (populated in bread.crumbs.component.tsx, from props that are passed, again from PDP).
 * In both cases, , the search.results.item.tsx passes parameters through location state to PDP component.
 */
export const generateSearchParams = (
  history: History,
  location: Location,
  rows: number,
  initialRows: number,
  showAvailableItemsFirst: boolean
) => {
  let start: number = 0;
  let startParam: number;
  let rowsParam: number;
  let filtersParam = {};
  let sortByAvailabilityParam: boolean = showAvailableItemsFirst;

  if (history.action === "POP") {
    startParam = 0;
    try {
      const searchResultParams = JSON.parse(
        sessionStorage.getItem("searchResultParams"),
        (key, value) => (value instanceof Array ? new Set(value) : value)
      );
      if (searchResultParams) {
        rowsParam = (searchResultParams.start + 1) * initialRows;
        filtersParam = searchResultParams.facets;
        // Check in session storage, if sort parameter is saved. Using Boolean function to keep boolean type - if JSON.parse returns null.
        sortByAvailabilityParam = Boolean(
          searchResultParams.sortByAvailability
        );

        if (rowsParam > 200) {
          const { startParamValue, startValue } = handleBrSearchLimit(
            location,
            rows,
            true
          );
          startParam = startParamValue;
          start = startValue;
        }
      } else {
        rowsParam = initialRows;
      }
    } catch (error) {
      // Prevent error on navigating between PLP and PDP.
      console.error(error);
    }
  } else {
    const { state } = location;
    startParam = state && state.start ? 0 : start;
    rowsParam =
      state && state.start ? (state.start + 1) * initialRows : initialRows;
    start = state && state.start ? state.start : start;
    filtersParam = state && state.facets ? state.facets : {};
    // Check in location state object, if sort parameter is saved:
    sortByAvailabilityParam =
      state && state.sortByAvailability !== undefined
        ? Boolean(state.sortByAvailability)
        : showAvailableItemsFirst;

    if (rowsParam > 200) {
      const { startParamValue, startValue } = handleBrSearchLimit(
        location,
        rows
      );
      startParam = startParamValue;
      start = startValue;
    }
    clearItemFromSessionStorage();
  }
  return {
    start,
    startParam,
    filtersChecked: filtersParam,
    sortByAvailabilityParam
  };
};
