import React, { useState } from "react";
import intl from "react-intl-universal";

import Notifications, {
  NotificationData,
  NotificationsError,
  NotificationsStatus
} from "../Notifications/Notifications";
import "./NotificationsContainer.less";

interface NotificationsContainerProps {
  userProfile: any;
  updateNotifications: (...args) => any;
}

const NotificationsContainer: React.FC<NotificationsContainerProps> = props => {
  const { userProfile, updateNotifications } = props;

  // Define state
  const [notificationsStatus, setNotificationsStatus] = useState<
    NotificationsStatus
  >(setInitialState().initialNotificationsState);
  const [notificationsData, setNotificationsData] = useState<NotificationData>(
    setInitialState().initialNotificationsData
  );
  const [notificationsError, setNotificationsError] = useState<
    NotificationsError
  >();

  // Functions

  function setInitialState(): {
    initialNotificationsState: NotificationsStatus;
    initialNotificationsData: NotificationData;
  } {
    const {
      email: defaultEmail,
      phone,
      optInSmsAlert,
      optInOrderStatusEmail,
      optInDeliveryStatusEmail,
      smsPhoneNumbers,
      orderStatusEmails,
      deliveryStatusEmails
    } = userProfile;

    const shouldReceiveSMSAlerts: boolean = optInSmsAlert === "true";
    const shouldReceiveOrderStatusEmails: boolean =
      optInOrderStatusEmail === "true";
    const shouldReceiveDeliveryStatusEmail: boolean =
      optInDeliveryStatusEmail === "true";

    const initialPhoneNumbers: Array<string> = smsPhoneNumbers
      ? smsPhoneNumbers.split("|")
      : [];
    const initialOrderStatusEmails: Array<string> = orderStatusEmails
      ? orderStatusEmails.split("|")
      : [defaultEmail];
    const initialDeliveryStatusEmail: string =
      deliveryStatusEmails || defaultEmail;

    const initialNotificationsState: NotificationsStatus = {
      smsChecked: shouldReceiveSMSAlerts,
      orderStatusChecked: shouldReceiveOrderStatusEmails,
      deliveryStatusChecked: shouldReceiveDeliveryStatusEmail
    };
    const initialNotificationsData: NotificationData = {
      phoneNumbers: initialPhoneNumbers,
      orderStatusEmails: initialOrderStatusEmails,
      deliveryStatusEmail: initialDeliveryStatusEmail
    };

    return { initialNotificationsState, initialNotificationsData };
  }

  function onSaveChanges(event): void {
    if (validateNotifications()) {
      updateNotifications(notificationsStatus, notificationsData);
    }
  }

  function updateNotificationsData(name: string, value: any) {
    setNotificationsData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setNotificationsError(null);
  }

  function updateNotificationsStatus(name: string) {
    setNotificationsStatus(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  }

  function validateNotifications(): boolean {
    const phoneRegex: RegExp = new RegExp(
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-|\s]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/
    );
    const emailRegex: RegExp = new RegExp(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i
    );

    const phoneNumbersWithError: Array<string> = [];
    const emailsWithError: Array<string> = [];

    const {
      phoneNumbers,
      orderStatusEmails,
      deliveryStatusEmail
    } = notificationsData;
    const {
      smsChecked,
      orderStatusChecked,
      deliveryStatusChecked
    } = notificationsStatus;

    if (smsChecked) {
      phoneNumbers.forEach((phoneNumber, index) => {
        if (!phoneRegex.test(phoneNumber)) {
          phoneNumbersWithError[index - 1] = intl.get("invalid-phone-number");
        }
      });
    }

    if (orderStatusChecked) {
      orderStatusEmails.forEach((email, index) => {
        if (!emailRegex.test(email)) {
          emailsWithError[index] = intl.get("invalid-email");
        }
      });
    }

    const emailWithError: string =
      deliveryStatusChecked && !emailRegex.test(deliveryStatusEmail)
        ? intl.get("invalid-email")
        : "";

    setNotificationsError({
      deliveryStatusEmail: emailWithError,
      phoneNumbers: phoneNumbersWithError,
      orderStatusEmails: emailsWithError
    });

    return (
      !phoneNumbersWithError.length &&
      !emailsWithError.length &&
      !emailWithError
    );
  }

  return (
    <div className="notifications-container content-box">
      <h4>{intl.get("notifications")}</h4>
      <Notifications
        notificationsStatus={notificationsStatus}
        notificationsData={notificationsData}
        updateNotificationsStatus={updateNotificationsStatus}
        updateNotificationsData={updateNotificationsData}
        notificationsError={notificationsError}
        userProfile={userProfile}
      />
      <button
        aria-label={intl.get("save")}
        type="button"
        className="dast-btn dast-btn-primary"
        onClick={onSaveChanges}
      >
        {intl.get("save")}
      </button>
    </div>
  );
};

export default NotificationsContainer;
