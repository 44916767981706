/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useState } from "react";
import intl from "react-intl-universal";

import { RegistrationSubmittedModal, Input } from "@zilker/store-components";
// import { sendRegistrationForm } from "../services/daikinServices";

import "../theme/sharedClasses.less";
import "./RegistrationPage.less";
import { isInvalidEmail } from "../utils/helpers";

// Class
class MessageBody {
  firstName: string;

  lastName: string;

  email: string;

  phone: string;

  customerNumber: string;

  platform: Platform;

  constructor({
    form,
    platform
  }: {
    form: RegistrationForm;
    platform: Platform;
  }) {
    this.firstName = form["first-name"];

    this.lastName = form["last-name"];

    this.email = form.email;

    this.phone = form["phone-number"];

    this.customerNumber = form["customer-number"];

    this.platform = platform;
  }
}

// Types

// Note: interface properties are named this way, for easier mapping with localization files.
export interface RegistrationForm {
  email: string;
  "first-name": string;
  "last-name": string;
  "customer-number": string;
  "phone-number": string;
}

enum Platform {
  ecommerce = "ecommerce",
  loyalty = "loyalty",
  proparts = "proparts"
}

export interface RegistrationMessageBody {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  customerNumber: string;
  platform: Platform;
}

// Component
const RegistrationPage: React.FC = () => {
  // Initial state objects
  const inputForm: RegistrationForm = {
    email: "",
    "first-name": "",
    "last-name": "",
    "customer-number": "",
    "phone-number": ""
  };

  const inputErrors: RegistrationForm = {
    email: "",
    "first-name": "",
    "last-name": "",
    "customer-number": "",
    "phone-number": ""
  };

  const registrationResponse = {
    message: "",
    error: ""
  };

  // Define state
  const [form, setForm] = useState(inputForm);
  const [formErrors, setFormErrors] = useState(inputErrors);
  const [modalVisible, setModalVisible] = useState(false);
  const [
    registrationResponseMessage,
    setRegistrationResponseMessage
  ] = useState(registrationResponse);

  // Functions
  /**
   * ## onInputChange
   * @param event
   *
   * @remarks This method is triggered on the input change.
   * The value of the input is written into the state component.
   * Errors are reset with empty strings.
   *
   * @returns void
   */
  function onInputChange(event): void {
    // use value from the input and set state
    const formInput = event.target;

    setForm(prevState => {
      const updatedState = { ...form };

      updatedState[formInput.name] = formInput.value.toString();
      return { ...prevState, ...updatedState };
    });

    // Clear form errors
    setFormErrors(inputErrors);
  }

  /**
   * ## validateForm
   *
   * @param formValues RegistrationForm
   *
   * @remarks This function iterates through form inputs,
   * and checks if there is any value for each field.
   * If the value is empty string,add error message
   * to errors object property.
   * If there are no errors in that object, the form is valid.
   *
   * @returns { errorsObj: RegistrationForm, isValid: boolean }
   */
  function validateForm(
    formValues: RegistrationForm
  ): { errorsObj: RegistrationForm; isValid: boolean } {
    let isValid = false;

    const errorsObj: RegistrationForm = {
      email: "",
      "first-name": "",
      "last-name": "",
      "customer-number": "",
      "phone-number": ""
    };

    Object.keys(formValues).forEach((formInputName: string) => {
      if (!formValues[formInputName].trim()) {
        errorsObj[formInputName] = intl.get(
          `${formInputName}-is-mandatory-field`
        );
      }
    });

    if (Object.values(errorsObj).every((elem: string) => elem === "")) {
      isValid = true;
    }

    if (
      formValues["phone-number"] &&
      !formValues["phone-number"].match(/^\d{10}$/g)
    ) {
      isValid = false;
      errorsObj["phone-number"] = intl.get(`phone-number-is-mandatory-field`);
    }

    if (isInvalidEmail(formValues.email)) {
      isValid = false;
      errorsObj.email = isInvalidEmail(formValues.email);
    }

    return { errorsObj, isValid };
  }

  /**
   * ## onModalClose
   *
   * @remarks Method to close the modal,
   * when "OK" or "X" button is clicked.
   *
   * @returns void
   */
  function onModalClose(): void {
    setModalVisible(false);
  }

  /**
   *## submitForm
   *
   *  @remarks This method is responsible for
   * form submitting.
   * These are the steps:
   * 1. form is validated
   * 2. if found, errors are set to state
   * 3. if valid, form values are sent to Daikin service.
   * 4. (opt) navigate to other page | display message | clear inputs
   *
   * @returns void
   */
  function submitForm(): void {
    const { errorsObj, isValid } = validateForm(form);

    if (!isValid) {
      // display error messages
      setFormErrors(errorsObj);
      return;
    }

    const platform: Platform = Platform.ecommerce;

    const messageBody = new MessageBody({ form, platform });

    /**
     * If use this, please uncomment the responding daikin service as well.
     */
    // sendRegistrationForm(messageBody)
    //   .then(({ data }) => {
    //     if (data.errors) {
    //       console.error("Error in registration response: ", data.errors);

    //       const responseMessage = {
    //         message: "",
    //         error: `${data.message}: ${JSON.stringify(data.errors)}`
    //       };

    //       setRegistrationResponseMessage(responseMessage);

    //       return;
    //     }

    //     const responseMessage = {
    //       message: data.messageId,
    //       error: ""
    //     };

    //     setRegistrationResponseMessage(responseMessage);

    //     setModalVisible(true);
    //   })
    //   .then(() => {
    //     setForm(inputForm);
    //     setFormErrors(inputErrors);
    //   })
    //   .catch(err => {
    //     console.error("Error submitting the registration: ", err);
    //   });
  }

  return (
    <div
      className="viewport ui-container static-ui-container"
      data-region="viewPortRegion"
      style={{ display: "block" }}
    >
      <div className="registration-form">
        <div className="registration-form-content">
          <h2>{intl.get("request-for-account")}</h2>

          {Object.keys(form).map((inputName: string) => {
            const inputType = inputName === "email" ? "email" : "text";

            return (
              <Input
                label={intl.get(`${inputName}`)}
                type={inputType}
                inputName={inputName}
                ariaLabel={intl.get(`${inputName}`)}
                inputHandler={onInputChange}
                value={form[inputName]}
                errors={formErrors}
                required
                key={`registration-page-input-${inputName}`}
              />
            );
          })}
          <button
            aria-label={intl.get("send")}
            className="dast-btn dast-btn-primary"
            type="button"
            onClick={submitForm}
          >
            {intl.get("send") ? intl.get("send").toUpperCase() : "SEND"}
          </button>
        </div>
      </div>
      {modalVisible && (
        <RegistrationSubmittedModal
          message={intl.get("registration-request-received")}
          onModalClose={onModalClose}
        />
      )}
    </div>
  );
};

export default RegistrationPage;
