import React, { FC, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { getConfig } from "@zilker/store-components";
import { useMainContext } from "../contexts/MainContext";
import * as LoginService from "../services/LoginService";
import { authenticateUser } from "../utils/helpers";

interface AHRIRedirectPageProps {
  history: any;
}

const AHRIRedirectPage: FC<AHRIRedirectPageProps> = ({ history }) => {
  const context = useMainContext();
  const { config } = getConfig();
  const {
    account: {
      accountDetails: { membership, customerNumber }
    },
    cart: {
      cartDetails: { defaultCart }
    },
    auth: { isLoggedIn }
  } = context;
  const currentLocationParams = window.location.search;
  const urlParams = new URLSearchParams(currentLocationParams);
  const accountTypeParam = urlParams.get("accountType");
  const invEmailParam = urlParams.get("invEmail");

  const formatAhriLink = () => {
    const { ahriURL, brXM } = config;
    const prosBrand = membership.join("|");
    const token =
      localStorage.getItem(`${config.cortexApi.scope}_oAuthToken`) || "";

    let tokenValue = token;
    if (token) {
      tokenValue = token.replace("Bearer ", "");
    }
    const { cartId, selectedBranch } = defaultCart;

    const ahriLink = `${ahriURL}?brand=${prosBrand}&customerNumber=${customerNumber}&branchNumber=${selectedBranch.code}&token=${tokenValue}&cartId=${cartId}&callbackURL=${brXM.origin}mycart&siteOrigin=${config.cortexApi.scope}`;
    window.location.href = ahriLink;
  };

  const redirectUser = async () => {
    if (
      LoginService.isLoggedIn() &&
      customerNumber &&
      membership &&
      defaultCart
    ) {
      formatAhriLink();
    } else if (!LoginService.isLoggedIn()) {
      authenticateUser(accountTypeParam, invEmailParam, history);
    }
  };

  useEffect(() => {
    redirectUser();
  }, [customerNumber, membership, defaultCart, isLoggedIn]);
  return <div className="loader" />;
};

export default withRouter(AHRIRedirectPage);
