/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import Modal from "react-responsive-modal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { handleSelectedCart } from "@elasticpath/ref-store/src/services/LoginService";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "../../../app/src/theme/sharedClasses.less";
import "./appmodalcartselect.main.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface AppModalCartSelectMainProps {
  handleModalClose: (...args: any[]) => any;
  handleCartModalCloseButton: (...args: any[]) => any;
  openModal: boolean;
  onAuthenticationRedirect?: (...args: any[]) => any;
  closeSsoLoader?: (...args: any[]) => any;
  history: any;
  auth: any;
}
interface AppModalCartSelectMainState {
  orgAuthServiceData: any;
  continueLoader: boolean;
  showModal: boolean;
}

class AppModalCartSelectMain extends React.Component<
  AppModalCartSelectMainProps,
  AppModalCartSelectMainState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = getConfig());
    this.state = {
      orgAuthServiceData: undefined,
      continueLoader: false,
      showModal: false
    };
    this.handleCartChange = this.handleCartChange.bind(this);
    this.handleCartConfirm = this.handleCartConfirm.bind(this);
  }

  componentDidMount() {
    const {
      context: {
        cart: { cartDataFromAuth, storeSelectedCart }
      }
    } = this;
    const { closeSsoLoader, handleModalClose } = this.props;
    if (cartDataFromAuth) {
      const updateStateObject = this.organizationDataStateObject(
        cartDataFromAuth
      );
      this.setState(updateStateObject, () => {
        if (!updateStateObject.showModal && cartDataFromAuth.length === 1) {
          this.setState({ continueLoader: true });
        }
      });
      storeSelectedCart(0);
    }
    closeSsoLoader();
    if (cartDataFromAuth && cartDataFromAuth.length === 1) {
      handleModalClose();
    }
  }

  /**
   * ## organizationDataStateObject
   *
   * @param
   *
   * @remarks This function creates an object containing fetched carts,
   * and an index of a currently selected cart (index is usefull to
   * mark the selected cart in the Shop For modal).
   *
   * @returns {orgAuthServiceData: any, selectedCart: string}
   */

  organizationDataStateObject(orgAuthServiceData) {
    const updateStateObject: {
      orgAuthServiceData: any;
      showModal: boolean;
    } = {
      orgAuthServiceData,
      showModal: orgAuthServiceData ? orgAuthServiceData.length !== 1 : true
    };

    return updateStateObject;
  }

  /**
   * ## isLoggingInProcess
   *
   * @param
   *
   * @remarks This function determens wether the sso login process is occuring
   * (The alternative to sso login process is ShopFor process).
   *
   * @returns boolean
   */

  isLoggingInProcess() {
    const {
      context: {
        auth: {
          tempSsoTokens: { _oAuthToken }
        }
      }
    } = this;

    return !!_oAuthToken;
  }

  handleCartChange(event) {
    const {
      cart: { storeSelectedCart }
    } = this.context;
    const selectedCart = event.target.value;
    storeSelectedCart(selectedCart);
  }

  handleCartConfirm() {
    const {
      history,
      handleCartModalCloseButton,
      handleModalClose
    } = this.props;
    const {
      cortexApi: { scope }
    } = Config;
    const {
      cart: { selectedCart }
    } = this.context;
    const { orgAuthServiceData } = this.state;

    // we used selectedCart = "0" for submission earlier
    if (
      (orgAuthServiceData && !orgAuthServiceData.length) ||
      !orgAuthServiceData
    ) {
      handleCartModalCloseButton();
    } else {
      const selectedCartData = orgAuthServiceData[selectedCart].customerNumber;
      const accountGuid = orgAuthServiceData[selectedCart].accountGuid;
      localStorage.setItem(`${scope}_AccountSharedId`, selectedCartData);
      localStorage.setItem(`${scope}_AccountGuid`, accountGuid);
      handleSelectedCart(
        this.context,
        selectedCart,
        orgAuthServiceData,
        handleModalClose
      );
      localStorage.setItem("hasLoggedIn", "true");
    }
  }

  renderCartOption() {
    const {
      cart: { selectedCart }
    } = this.context;
    const { orgAuthServiceData } = this.state;
    if (orgAuthServiceData && orgAuthServiceData.length) {
      return orgAuthServiceData.map((division, index) => {
        if (division) {
          const cartUsed =
            selectedCart || localStorage.getItem("selectedCart") || 0;
          const accountPendingApproval = division.status !== "ACTIVE";

          return (
            <div
              className={`radio ${accountPendingApproval ? "disabled" : ""}`}
              key={division.customerNumber}
            >
              {accountPendingApproval ? (
                <p className="error">{intl.get("please-contact-admin")}</p>
              ) : null}
              <label
                htmlFor={`cart-selection-option${index}`}
                className="custom-radio-button"
              >
                <input
                  id={`cart-selection-option${index}`}
                  type="radio"
                  value={index}
                  checked={
                    Number(cartUsed) === index && !accountPendingApproval
                  }
                  onChange={this.handleCartChange}
                  disabled={accountPendingApproval}
                />
                <span className="helping-el" />
                <span className="label-text">
                  {division.companyName} {`(${division.customerNumber})`}
                </span>
              </label>
            </div>
          );
        }
        return null;
      });
    }
    return (
      <div className="radio division-message">
        <label htmlFor="cart-selection-option">
          <span className="label-text">{intl.get("no-divisions-found")}</span>
        </label>
      </div>
    );
  }

  renderConfirmButton(selectedCartName) {
    const { orgAuthServiceData } = this.state;
    const { handleCartModalCloseButton } = this.props;

    if (!selectedCartName) {
      return (
        <button
          onClick={handleCartModalCloseButton}
          className="dast-btn dast-btn-primary wide"
          id="continue_with_cart_button"
          aria-label={intl.get("close")}
          data-cmd="close"
          type="button"
        >
          {intl.get("close")}
        </button>
      );
    }
    return (
      <button
        onClick={this.handleCartConfirm}
        className="dast-btn dast-btn-primary wide"
        id="continue_with_cart_button"
        aria-label={
          (orgAuthServiceData && !orgAuthServiceData.length) ||
          !orgAuthServiceData
            ? intl.get("change-carts-ok")
            : `${intl.get("continue-with")} ${selectedCartName}`
        }
        data-cmd="continue"
        type="submit"
      >
        {(orgAuthServiceData && !orgAuthServiceData.length) ||
        !orgAuthServiceData
          ? intl.get("change-carts-ok")
          : `${intl.get("continue-with")} ${selectedCartName}`}
      </button>
    );
  }

  render() {
    const {
      cart: { selectedCart }
    } = this.context;
    const { orgAuthServiceData, continueLoader, showModal } = this.state;
    const { openModal, handleCartModalCloseButton } = this.props;
    const cartUsed = selectedCart || localStorage.getItem("selectedCart") || 0;
    const approvedAccounts =
      orgAuthServiceData &&
      orgAuthServiceData.length &&
      orgAuthServiceData.filter(acc => acc.status === "ACTIVE");

    const selectedCartName =
      approvedAccounts && approvedAccounts.length && approvedAccounts[cartUsed]
        ? `${approvedAccounts[cartUsed].companyName} (${approvedAccounts[cartUsed].customerNumber})`
        : "";

    if (!orgAuthServiceData) {
      return null;
    }

    return !showModal ? null : (
      <Modal
        open={openModal && !!orgAuthServiceData}
        onClose={handleCartModalCloseButton}
        classNames={{ modal: "cart-selection-modal-content" }}
      >
        <div className="modal-lg">
          <div className="modal-content" id="simplemodal-container">
            <div className="modal-header">
              <h2 className="modal-title">
                {orgAuthServiceData && !orgAuthServiceData.length
                  ? intl.get("change-carts-permission-denied")
                  : intl.get("select-an-account")}
              </h2>
            </div>

            <div className="modal-body">
              <div id="cart_selection_modal_form">
                <div className="carts-selection-region">
                  {this.renderCartOption()}
                </div>
                <div className="action-row">
                  <div className="form-input btn-container">
                    {continueLoader ? (
                      <div className="miniLoader" />
                    ) : (
                      this.renderConfirmButton(selectedCartName)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AppModalCartSelectMain;
