import React, { useEffect } from "react";
import { BrProps, BrManageContentButton } from "@bloomreach/react-sdk";
import { ImageSet, Document, Pagination } from "@bloomreach/spa-sdk";
import $ from "jquery";
import { isLinkExternal } from "../../../../../app/src/utils/helpers";
import useBanners from "../../../CustomHooks/useBanners";

import "./carousel.less";
import RichText from "../RichText/rich-text";

const Carousel = ({ component, page }: BrProps) => {
  const { pageable } = component.getModels();

  useEffect(() => {
    $(".carousel-container .carousel").carousel();
  });

  const contents = pageable.items.map(reference => {
    const document = page.getContent<Document>(reference);
    return document.getData();
  });

  const banners = useBanners(contents);

  const {
    cycle,
    carouselWidth,
    carouselHeight,
    carouselBackgroundColor,
    interval,
    pause,
    showNavigation
  } = component.getProperties();

  const containerStyles = {} as any;
  if (carouselBackgroundColor) {
    containerStyles.backgroundColor = carouselBackgroundColor;
  }

  const renderBannerLink = (banner: any) => {
    const buttonStyle = {} as any;
    if (banner.buttonBGColor) {
      buttonStyle.backgroundColor = banner.buttonBGColor;
    }
    if (banner.url) {
      const [isExternal, url] = isLinkExternal(banner.url);
      if (isExternal) {
        return (
          <a
            href={url}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            className="dast-btn cta"
            style={buttonStyle}
          >
            {banner.linktitle ? banner.linktitle : "Read More"}
          </a>
        );
      }
      return (
        <a href={url} className="dast-btn cta" style={buttonStyle}>
          {banner.linktitle ? banner.linktitle : "Read More"}
        </a>
      );
    }
    return null;
  };

  const renderCarouselIndicator = () => {
    return banners.length > 1
      ? banners.map((banner, index) => {
          return (
            <li
              key={banner.id}
              data-target="#carouselMain"
              data-slide-to={index}
              className={`${index === 0 ? "active" : ""}`}
            />
          );
        })
      : null;
  };

  const renderContent = banner => {
    if (banner.content && banner.content.value !== "") {
      return <RichText content={banner.content.value} />;
    }
    return <></>;
  };

  const renderCarouselItems = () => {
    let hasBackground = false;

    return banners.map((banner, index) => {
      const carouselStyle = {} as any;
      if (banner.backgroundImage) {
        hasBackground = true;

        const backgroundImage = page.getContent<ImageSet>(
          banner.backgroundImage
        );

        carouselStyle.backgroundImage = `url(${backgroundImage
          .getOriginal()
          .getUrl()})`;
        carouselStyle.backgroundSize = "cover";
      }
      const imageSrc = page.getContent<ImageSet>(banner.image);
      return (
        <div
          className={`carousel-item ${index === 0 ? "active" : ""} ${
            hasBackground ? "carousel-item-background" : ""
          }`}
          key={banner.id}
          style={carouselStyle}
        >
          <div className="row marquee-width">
            <div
              className="col-12 col-lg-6 p-0 d-flex align-items-center1"
              style={{ zIndex: 1 }}
            >
              <div className="carousel-caption">
                {banner.title && <h3>{banner.title}</h3>}
                <div className="carousel-body">{renderContent(banner)}</div>
                <div className="carousel-link">{renderBannerLink(banner)}</div>
              </div>
            </div>
            <div className="col-12 col-lg-6 carousel-image p-0">
              {banner.image && (
                <img src={imageSrc.getOriginal().getUrl()} alt={banner.title} />
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="carousel-container d-flex justify-content-center"
      style={containerStyles}
    >
      <div
        id="carouselMain"
        className="carousel slide"
        data-ride={cycle ? "carousel" : false}
        data-pause={pause ? "hover" : false}
      >
        <div className="carousel-inner">{renderCarouselItems()}</div>
        {showNavigation ? (
          <ol className="carousel-indicators">{renderCarouselIndicator()}</ol>
        ) : null}
      </div>
    </div>
  );
};

export default Carousel;
