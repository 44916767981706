import React from "react";
import intl from "react-intl-universal";
import DOMPurify from "dompurify";
import { getConfig } from "@zilker/store-components";
import "./SiteLogos.less";

interface SiteLogosProps {
  logos: string[];
  content: any;
  headerLogo: boolean;
}

const SiteLogos: React.FC<SiteLogosProps> = props => {
  const { logos, content, headerLogo } = props;
  const { config } = getConfig();
  const {
    cortexApi: { scope }
  } = config;

  const singleLogoSite = logos && logos.length === 1;
  const dneHeaderLogo = headerLogo && scope === intl.get("usg");

  function createMarkup() {
    const sanitizedContent = DOMPurify.sanitize(content);
    const modifiedContent = sanitizedContent.replace(
      /<img((?![\w\s]*alt=)[\w\s\S]*?)>/g,
      '<img$1 alt="Supporting image for Footer" >'
    );
    return { __html: modifiedContent };
  }
  return (
    <div
      className={`footer-logo dast-paragraph ${
        singleLogoSite ? "single-footer-logo" : ""
      }`}
    >
      <div
        className={`logos-container ${
          singleLogoSite ? "single-logo-container" : ""
        }`}
      >
        {logos.map(
          (logo: any, index: number) =>
            logo && (
              <a href="/" key={logo}>
                <img
                  src={logo}
                  alt={`logo ${index + 1}`}
                  className={`footer-logo-image ${
                    singleLogoSite && index === 0 && !dneHeaderLogo
                      ? "single-logo"
                      : ""
                  }`}
                />
              </a>
            )
        )}
      </div>

      <div
        className="content-container"
        dangerouslySetInnerHTML={createMarkup()}
      />
    </div>
  );
};

export default SiteLogos;
