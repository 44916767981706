import React from "react";
import intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import { Input, ContractOrderTable } from "@zilker/store-components";
import { MainContext } from "../contexts/MainContext";

import "./ContractOrdersPage.less";

export interface ContractOrder {
  branchNo: string;
  branchShipAddress1: string;
  branchShipAddress2: string;
  branchShipAddress3: string;
  branchShipCity: string;
  branchShipState: string;
  branchShipZip: string;
  customerNumber: string;
  description: string;
  detailLines: any[];
  enteredBy: string;
  expiration: Date;
  firstRelease: Date;
  firstShipment: Date;
  jobNumber: string;
  jobName: string;
  lastRelease: Date;
  lastShipment: Date;
  orderNumber: string;
  orderPlaced: Date;
  otherChargesAmt: number;
  purchaseOrderNumber: string;
  pricing: string;
  promisedShip: Date;
  shipToAddress1: string;
  shipToAddress2: string;
  shipToAddress3: string;
  shipToCity: string;
  shipToState: string;
  shipToZip: string;
  status: string;
  subTotal: string;
  tax: string;
  total: string;
}

interface Filters {
  orderNumber?: string;
  purchaseOrderNumber?: string;
}

interface ContractOrderPageProps extends BrProps {
  history: any;
  match: any;
  auth: any;
}
interface ContractOrderPageState {
  contracts: Array<ContractOrder>;
  filters: Filters;
  contractsLoading: boolean;
}

class ContractOrderPage extends React.Component<
  ContractOrderPageProps,
  ContractOrderPageState
> {
  static contextType = MainContext;

  timeout = null;

  miliseconds = 250;

  constructor(props) {
    super(props);
    this.state = {
      contracts: null,
      filters: { orderNumber: "", purchaseOrderNumber: "" },
      contractsLoading: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.fetchContractOrders = this.fetchContractOrders.bind(this);
    this.filterContractOrders = this.filterContractOrders.bind(this);
  }

  componentDidMount() {
    this.fetchContractOrders();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      account: {
        accountDetails: { customerNumber }
      }
    } = this.context;
    const { contracts } = this.state;
    const { contracts: prevContracts } = prevState;
    if (customerNumber && !contracts && contracts !== prevContracts) {
      this.fetchContractOrders();
    }
  }

  filterContractOrders() {
    const { filters } = this.state;
    const {
      contract: { filterContracts }
    } = this.context;
    const filteredContracts = filterContracts(filters);
    this.setState({
      contracts: filteredContracts,
      contractsLoading: false
    });
  }

  async fetchContractOrders() {
    const {
      contract: { contractsList }
    } = this.context;
    if (contractsList && contractsList.length) {
      this.setState({
        contracts: contractsList || [],
        contractsLoading: false
      });
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState(
      prevState => ({
        ...prevState,
        contractsLoading: true,
        filters: { ...prevState.filters, [name]: value }
      }),
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(this.filterContractOrders, this.miliseconds);
      }
    );
  }

  renderContractsFilters() {
    const {
      filters: { orderNumber, purchaseOrderNumber }
    } = this.state;
    return (
      <div className="filters-container">
        <div className="filters-header">
          <span className="filters-title">{intl.get("filter")}</span>
        </div>
        <div className="filters">
          <Input
            inputName="orderNumber"
            ariaLabel="orderNumber"
            label=""
            placeholder={intl.get("contract-number")}
            type="text"
            inputHandler={this.handleInputChange}
            value={orderNumber}
          />
          <Input
            inputName="purchaseOrderNumber"
            ariaLabel="purchaseOrderNumber"
            label=""
            placeholder={intl.get("p-o-number")}
            type="text"
            inputHandler={this.handleInputChange}
            value={purchaseOrderNumber}
          />
        </div>
      </div>
    );
  }

  render() {
    const { contracts, contractsLoading } = this.state;
    const { history } = this.props;
    return (
      <div className="contract-orders-container container">
        {contracts ? (
          <div className="content-box content-table">
            <h4>{intl.get("contract-orders")}</h4>
            {this.renderContractsFilters()}
            <ContractOrderTable
              contracts={contracts}
              loading={contractsLoading}
              history={history}
            />
          </div>
        ) : (
          <div className="loader" />
        )}
      </div>
    );
  }
}

export default ContractOrderPage;
