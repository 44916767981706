import React from "react";
import { BrManageContentButton, BrProps } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import DOMPurify from "dompurify";

import "./simple-content.less";

const SimpleContent = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent(documentRef);

  if (!document) {
    return null;
  }

  const {
    title,
    content,
    introduction,
    author,
    image: imageRef,
    date
  } = document.getData();

  const image = imageRef && page.getContent<ImageSet>(imageRef);
  let contentHtml;
  if (content && content.value) {
    contentHtml = page.rewriteLinks(content.value);
  }

  return (
    <div className="container-fluid has-edit-button">
      <div className="container">
        <BrManageContentButton content={document} />
        <h2 className="blog-post-title">{title}</h2>
        <p className="blog-post-meta">
          {date && (
            <span className="blog-post-date">
              {new Date(date).toDateString()}
            </span>
          )}
          {author && (
            <span className="author">
              <a href="#pagination">{author}</a>
            </span>
          )}
        </p>
        {introduction && <p>{introduction}</p>}
        {image && (
          <figure>
            <img src={image.getOriginal().getUrl()} alt={title} />
          </figure>
        )}
        {contentHtml && (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(contentHtml)
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SimpleContent;
