/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import React, { FC, useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import _ from "lodash";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { getConfig } from "@zilker/store-components";
import {
  formatAhriLink,
  formatWarrantyLinks,
  formatProductRegistrationLinks,
  formatHVACTools
} from "../../../app/src/utils/helpers";
import { ReactComponent as PlusIcon } from "../../../app/src/images/icons/plus-icon.svg";
import { ReactComponent as MinusIcon } from "../../../app/src/images/icons/minus-icon.svg";

import "./AppHeaderNavigationBarMain.less";

interface AppHeaderNavigationBarMainProps {
  isOfflineCheck: (...args: any[]) => any;
  isOffline?: boolean;
  isMobileView: boolean;
  appHeaderNavigationLinks?: { [key: string]: any };
  cortexNavigations: any;
  toggleMobileMenu?: any;
}

interface NavItem {
  name: string;
  path: string;
  internal: boolean;
  description?: string;
  isAhri?: boolean;
}

const AppHeaderNavigationBarMain: FC<
  AppHeaderNavigationBarMainProps
> = props => {
  const {
    isOfflineCheck,
    isOffline,
    isMobileView,
    appHeaderNavigationLinks,
    cortexNavigations,
    toggleMobileMenu
  } = props;

  const { config } = getConfig();
  const context = useContext<{
    auth: { isLoggedIn };
    user: { userProfile };
    cart: {
      cartDetails: { defaultCart };
    };
    navigation: { setNavigation };
    account: { accountDetails };
  }>(MainContext);

  const {
    account: {
      accountDetails: { membership }
    }
  } = context;

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !isMobileView
        ) {
          closeMenu();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { partsFinderPageDisplay } = config;

  const [navigations, setNavigations] = useState<any>({});
  const [customerNumber, setCustomerNumber] = useState<string>(null);
  const [branchNumber, setBranchNumber] = useState<string>(null);
  const [isLoggedInUser, setIsLoggedInUser] = useState<boolean>(false);
  const [cartId, setCartId] = useState<string>(null);
  const [resources, setResources] = useState<any>([]);
  const [firstSubmenu, setFirstSubmenu] = useState<boolean>(false);
  const [menuIndex, setMenuIndex] = useState<number>(null);
  const [secondSubmenu, setSecondSubmenu] = useState<boolean>(false);
  const [secondSubmenuIndex, setSecondSubmenuIndex] = useState<number>(null);

  const prevCustomerNumber = useRef<string>();
  const prevIsLoggedInUser = useRef<boolean>();
  const prevBranchNumber = useRef<string>();
  const prevCartId = useRef<string>();

  const getDropDownNavigationState = currentNavigations => {
    const dropDownNavigation = {};

    currentNavigations.forEach(category => {
      const displayName = category["display-name"];
      const { name } = category;
      const show = false;
      let children;

      if (category._child) {
        children = getDropDownNavigationState(category._child);
      }

      if (!category.hidden) {
        dropDownNavigation[displayName] = {
          show,
          name,
          ...children
        };
      }
    });

    return dropDownNavigation;
  };

  const toggleFirstSubmenu = index => {
    if (index !== menuIndex && firstSubmenu) {
      setFirstSubmenu(true);
    } else {
      setFirstSubmenu(!firstSubmenu);
    }
    setMenuIndex(index);
    setSecondSubmenuIndex(null);
    setSecondSubmenu(false);
  };

  const toggleSecondSubmenu = index => {
    if (index === undefined) {
      return;
    }
    if (index !== secondSubmenuIndex && secondSubmenu) {
      setSecondSubmenu(true);
    } else {
      setSecondSubmenu(!secondSubmenu);
    }
    setSecondSubmenuIndex(index);
  };

  const closeMenu = () => {
    setFirstSubmenu(false);
    setSecondSubmenu(false);
    setMenuIndex(null);
    setSecondSubmenuIndex(null);
    if (isMobileView) {
      toggleMobileMenu();
    }
  };

  const renderSubCategoriesWithNoChildren = (
    subcategoryChildKeyName,
    nestedChildObj,
    path,
    categoryLevel
  ) => {
    const pathNavigation =
      categoryLevel === 0
        ? appHeaderNavigationLinks.categorieslanding
        : appHeaderNavigationLinks.categories;
    if (
      subcategoryChildKeyName !== "show" &&
      subcategoryChildKeyName !== "name"
    ) {
      return (
        <li key={`${path}`}>
          <Link
            className={`${
              categoryLevel === 0 ? "" : "subcat-item"
            } dropdown-item ${nestedChildObj.show ? "show" : ""}`}
            id={`header_navbar_sub_category_button_${nestedChildObj.name}`}
            data-fullstory-id={`nav-${subcategoryChildKeyName.replace(
              /\s+/g,
              "-"
            )}`}
            title={subcategoryChildKeyName}
            to={{
              pathname: pathNavigation + nestedChildObj.name,
              state: {
                navClick: true,
                name: subcategoryChildKeyName,
                prevUrl: window.location.href
              }
            }}
            onClick={e => {
              closeMenu();
              e.stopPropagation();
            }}
          >
            <div>{subcategoryChildKeyName}</div>
          </Link>
        </li>
      );
    }
    return null;
  };

  const renderSubCategories = (
    category,
    path,
    isLeftDropDownStyling,
    categoryLevel
  ) => {
    const childObj = _.get(navigations, path, "");
    const subCategoryChildArray = Object.keys(childObj);
    const subCategoryChildArraySliced = [...subCategoryChildArray].slice(2, 7);
    const categoriesRendered =
      categoryLevel !== 1 ? subCategoryChildArray : subCategoryChildArraySliced;

    return categoriesRendered.map((subcategoryChildKeyName, index) => {
      const nestedChildObj = childObj[subcategoryChildKeyName];
      const currentPath = `${path}.${subcategoryChildKeyName}`;
      let shouldRenderViewAll = false;
      if (Object.keys(nestedChildObj).length > 7) {
        shouldRenderViewAll = true;
      }

      if (
        subcategoryChildKeyName !== "show" &&
        subcategoryChildKeyName !== "name"
      ) {
        if (Object.keys(nestedChildObj).length > 2) {
          return renderSubCategoriesWithChildren(
            subcategoryChildKeyName,
            nestedChildObj,
            currentPath,
            isLeftDropDownStyling,
            categoryLevel,
            shouldRenderViewAll,
            index
          );
        }
        return renderSubCategoriesWithNoChildren(
          subcategoryChildKeyName,
          nestedChildObj,
          currentPath,
          categoryLevel
        );
      }
      return null;
    });
  };

  const renderSubCategoriesWithChildren = (
    subcategoryChildKeyName,
    nestedChildObj,
    path,
    isLeftDropDownStyling,
    categoryLevel,
    shouldRenderViewAll,
    index
  ) => {
    const currentCategoryLevel = categoryLevel + 1;
    return (
      <li
        className={`second-level-cat ${
          isLeftDropDownStyling ? "left-drop-down" : "right-drop-down"
        }`}
        key={`${path}`}
      >
        {isMobileView ? (
          <button
            type="button"
            className={`dropdown-item dropdown-toggle ${
              _.get(navigations, `${path}.show`, "") ? "rotateCaret" : ""
            }`}
            id={`navbarDropdownMenuLink_${nestedChildObj.name}`}
            data-fullstory-id={`nav-${subcategoryChildKeyName.replace(
              /\s+/g,
              "-"
            )}`}
            onClick={e => {
              e.stopPropagation();
              toggleSecondSubmenu(index);
            }}
          >
            {subcategoryChildKeyName}
            <PlusIcon
              className={`plus-icon ${
                secondSubmenu && index === secondSubmenuIndex ? "" : "show"
              }`}
            />{" "}
            <MinusIcon
              className={`minus-icon ${
                secondSubmenu && index === secondSubmenuIndex ? "show" : ""
              }`}
            />
          </button>
        ) : (
          <Link
            className={`cat-name dropdown-item dropdown-toggle ${
              _.get(navigations, `${path}.show`, "") ? "rotateCaret" : ""
            }`}
            to={{
              pathname: `/categorylanding/${nestedChildObj.name}`,
              state: {
                navClick: true,
                name: subcategoryChildKeyName,
                prevUrl: window.location.href
              }
            }}
            id={`navbarDropdownMenuLink_${nestedChildObj.name}`}
            data-fullstory-id={`nav-${subcategoryChildKeyName.replace(
              /\s+/g,
              "-"
            )}`}
            onClick={() => closeMenu()}
            aria-haspopup="true"
            aria-expanded="false"
          >
            {subcategoryChildKeyName}{" "}
          </Link>
        )}
        <div
          className={`nav-submenu-dropdown-container dropdown ${
            secondSubmenu && index === secondSubmenuIndex ? "show" : ""
          }`}
          data-index={index}
        >
          <ul
            className={`dropdown-menu secondary-dropdown nav-dropdown-menu ${
              nestedChildObj.show ? "show" : ""
            } nestedCategory${currentCategoryLevel}`}
            aria-labelledby={`"navbarDropdownMenuLink_" + ${nestedChildObj.name}`}
          >
            {renderSubCategories(
              subcategoryChildKeyName,
              path,
              !isLeftDropDownStyling,
              currentCategoryLevel
            )}
            {shouldRenderViewAll && (
              <li key={`${path}`}>
                <Link
                  className={`dropdown-item subcat-item dropdown-toggle ${
                    _.get(navigations, `${path}.show`, "") ? "rotateCaret" : ""
                  } view-all`}
                  to={{
                    pathname: `/categorylanding/${nestedChildObj.name}`,
                    state: {
                      navClick: true,
                      name: subcategoryChildKeyName,
                      prevUrl: window.location.href
                    }
                  }}
                  id={`navbarDropdownMenuLink_${nestedChildObj.name}`}
                  data-fullstory-id={`nav-${nestedChildObj.name.replace(
                    /\s+/g,
                    "-"
                  )}-view-all`}
                  onClick={e => {
                    closeMenu();
                    e.stopPropagation();
                  }}
                >
                  {intl.get("view-all")}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </li>
    );
  };

  const renderCategoriesWithNoChildren = (categoryKey, path) => {
    return (
      <li className="nav-item" key={`${path}`} data-name={categoryKey}>
        <Link
          className="nav-link"
          to={{
            pathname:
              appHeaderNavigationLinks.categories +
              navigations[categoryKey].name,
            state: {
              navClick: true,
              prevUrl: window.location.href
            }
          }}
          id="navbarMenuLink"
        >
          <div>{categoryKey}</div>
        </Link>
      </li>
    );
  };

  const renderCategoriesWithChildren = (
    category,
    path,
    isLeftDropDownStyling,
    categoryLevel,
    index
  ) => {
    return (
      <li className="nav-item" key={index} data-name={category}>
        {isMobileView ? (
          <button
            type="button"
            className={`nav-link ${
              _.get(navigations, `${path}.show`, "") ? "rotateCaret" : ""
            }`}
            id={`navbarDropdownMenuLink_${navigations[category].name}`}
            data-fullstory-id={`nav-${category.replace(/\s+/g, "-")}`}
            onClick={e => {
              e.stopPropagation();
              toggleFirstSubmenu(index);
            }}
          >
            {category}{" "}
            <PlusIcon
              className={`plus-icon ${
                firstSubmenu && index === menuIndex ? "" : "show"
              }`}
            />{" "}
            <MinusIcon
              className={`minus-icon ${
                firstSubmenu && index === menuIndex ? "show" : ""
              }`}
            />
          </button>
        ) : (
          <button
            type="button"
            className="nav-link"
            id={`navbarDropdownMenuLink_${navigations[category].name}`}
            data-fullstory-id={`nav-${category.replace(/\s+/g, "-")}`}
            onClick={e => {
              e.stopPropagation();
              toggleFirstSubmenu(index);
            }}
          >
            {category}{" "}
          </button>
        )}

        <div
          className={`nav-dropdown-container dropdown ${
            firstSubmenu && index === menuIndex ? "show" : ""
          }`}
        >
          <ul
            className={`dropdown-menu nav-dropdown-menu nestedCategory${categoryLevel}`}
            aria-labelledby={`"navbarDropdownMenuLink_" + ${navigations[category].name}`}
          >
            {renderSubCategories(
              category,
              path,
              isLeftDropDownStyling,
              categoryLevel
            )}
          </ul>
        </div>
      </li>
    );
  };

  const renderCategories = () => {
    const firstLevelKeys = Object.keys(navigations);

    return firstLevelKeys
      .filter(categoryKey => !!categoryKey)
      .map((category, index) => {
        const categoryObj = navigations[category];
        const path = category;
        if (Object.keys(categoryObj).length > 2) {
          const categoryLevel = 0;
          return renderCategoriesWithChildren(
            category,
            path,
            true,
            categoryLevel,
            index
          );
        }
        return renderCategoriesWithNoChildren(category, path);
      });
  };

  const renderMenuItemWithSubItems = (name: string, menu: Array<NavItem>) => {
    const currentWarranty = formatWarrantyLinks();
    const productRegistrationLinks = formatProductRegistrationLinks();
    const toolsIndex = Object.keys(navigations).length;
    const warrantyIndex = 0;
    const productRegIndex = 1;
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = context;
    return (
      <li
        className="nav-item resources"
        key={name}
        data-name={name}
        onClick={() => toggleFirstSubmenu(toolsIndex)}
        data-fullstory-id={`nav-${name.replace(/\s+/g, "-")}`}
      >
        <span className="nav-link">
          {name}
          <>
            <PlusIcon
              className={`plus-icon ${
                firstSubmenu && toolsIndex === menuIndex ? "" : "show"
              }`}
            />
            <MinusIcon
              className={`minus-icon ${
                firstSubmenu && toolsIndex === menuIndex ? "show" : ""
              }`}
            />
          </>
        </span>

        <div
          className={`nav-dropdown-container dropdown ${
            firstSubmenu && toolsIndex === menuIndex ? "show" : ""
          } tools-dropdown-list`}
        >
          <ul
            className="dropdown-menu nav-dropdown-menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            {menu.map(
              menuItem =>
                menuItem &&
                menuItem.path && (
                  <li key={menuItem.name} className="tools-list-item">
                    {menuItem.internal ? (
                      <Link
                        to={{
                          pathname: menuItem.path,
                          state: { prevUrl: window.location.href }
                        }}
                        onClick={e => {
                          closeMenu();
                          e.stopPropagation();
                        }}
                        className="dropdown-item"
                        data-fullstory-id={`nav-${menuItem.name.replace(
                          /\s+/g,
                          "-"
                        )}`}
                      >
                        {" "}
                        <div>{menuItem.name}</div>
                      </Link>
                    ) : (
                      <a
                        href={
                          menuItem.name === "System Configurator"
                            ? "#"
                            : menuItem.path
                        }
                        className="dropdown-item"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-fullstory-id={`nav-${menuItem.name.replace(
                          /\s+/g,
                          "-"
                        )}`}
                        onClick={e => {
                          if (menuItem.isAhri) {
                            e.preventDefault();
                            formatAhriLink(
                              membership,
                              customerNumber,
                              defaultCart
                            );
                          }
                          closeMenu();
                          e.stopPropagation();
                        }}
                      >
                        <div>
                          {menuItem.isAhri && (
                            <span className="ahri-span">
                              **{intl.get("new").toUpperCase()}**
                            </span>
                          )}
                          {menuItem.name}
                        </div>
                      </a>
                    )}
                    {menuItem.description && (
                      <p className="item-description">{menuItem.description}</p>
                    )}
                  </li>
                )
            )}
            <li key="warranty-key" className="tools-list-item">
              <span className="regular-item">
                <button
                  className="dropdown-item dropdown-toggle "
                  aria-expanded="true"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    toggleSecondSubmenu(warrantyIndex);
                  }}
                >
                  {intl.get("warranty")}
                  <PlusIcon
                    className={`plus-icon ${
                      secondSubmenu && warrantyIndex === secondSubmenuIndex
                        ? ""
                        : "show"
                    }`}
                  />
                  <MinusIcon
                    className={`minus-icon ${
                      secondSubmenu && warrantyIndex === secondSubmenuIndex
                        ? "show"
                        : ""
                    }`}
                  />
                </button>
              </span>
              <div
                className={`nav-submenu-dropdown-container dropdown ${
                  secondSubmenu && warrantyIndex === secondSubmenuIndex
                    ? "show"
                    : ""
                }`}
              >
                <ul
                  className="dropdown-menu secondary-dropdown nav-dropdown-menu  nestedCategory1"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {currentWarranty.map(
                    warrantyItem =>
                      warrantyItem &&
                      warrantyItem.path && (
                        <li key={warrantyItem.name}>
                          {warrantyItem.internal ? (
                            <Link
                              className="subcat-item dropdown-item"
                              id={`header_navbar_sub_category_button_${warrantyItem.name}`}
                              data-fullstory-id={`nav-${warrantyItem.name.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              title={warrantyItem.name}
                              to={{
                                pathname: warrantyItem.path,
                                state: {
                                  navClick: true,
                                  name: warrantyItem.name,
                                  prevUrl: window.location.href
                                }
                              }}
                              onClick={e => {
                                closeMenu();
                                e.stopPropagation();
                              }}
                            >
                              <div className="" aria-expanded="true">
                                {warrantyItem.name}
                              </div>
                            </Link>
                          ) : (
                            <a
                              href={warrantyItem.path}
                              className="subcat-item dropdown-item"
                              data-fullstory-id={`nav-${warrantyItem.name.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={e => {
                                closeMenu();
                                e.stopPropagation();
                              }}
                            >
                              <div className="" aria-expanded="true">
                                {warrantyItem.name}
                              </div>
                            </a>
                          )}
                        </li>
                      )
                  )}
                </ul>
              </div>
            </li>
            <li key="prod-reg-key" className="tools-list-item">
              <span className="regular-item">
                <button
                  className="dropdown-item dropdown-toggle "
                  aria-expanded="true"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    toggleSecondSubmenu(productRegIndex);
                  }}
                >
                  {intl.get("product-registration")}
                  <PlusIcon
                    className={`plus-icon ${
                      secondSubmenu && productRegIndex === secondSubmenuIndex
                        ? ""
                        : "show"
                    }`}
                  />
                  <MinusIcon
                    className={`minus-icon ${
                      secondSubmenu && productRegIndex === secondSubmenuIndex
                        ? "show"
                        : ""
                    }`}
                  />
                </button>
              </span>
              <div
                className={`nav-submenu-dropdown-container dropdown ${
                  secondSubmenu && productRegIndex === secondSubmenuIndex
                    ? "show"
                    : ""
                }`}
              >
                <ul
                  className="dropdown-menu secondary-dropdown nav-dropdown-menu  nestedCategory1"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {productRegistrationLinks.map(
                    productItem =>
                      productItem &&
                      productItem.path && (
                        <li key={productItem.name}>
                          <a
                            href={productItem.path}
                            className="subcat-item dropdown-item"
                            data-fullstory-id={`nav-${productItem.name.replace(
                              /\s+/g,
                              "-"
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => {
                              closeMenu();
                              e.stopPropagation();
                            }}
                          >
                            <div className="" aria-expanded="true">
                              {productItem.name}
                            </div>
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
    );
  };

  useEffect(() => {
    const {
      auth: { isLoggedIn },
      cart: {
        cartDetails: { defaultCart }
      },
      navigation: { setNavigation },
      account: { accountDetails }
    } = context;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const userCartId = defaultCart ? defaultCart.cartId : null;
    const currentCustomerNumber = accountDetails
      ? accountDetails.customerNumber
      : null;
    const userBranchNumber = selectedBranch
      ? selectedBranch.code
      : selectedBranch;

    const dropdownNavigations = getDropDownNavigationState(cortexNavigations);

    setNavigation(dropdownNavigations);
    setNavigations(dropdownNavigations);
    setCustomerNumber(currentCustomerNumber);
    setBranchNumber(userBranchNumber);
    setIsLoggedInUser(isLoggedIn);
    setCartId(userCartId);
  }, []);

  useEffect(() => {
    if (!navigator.onLine && !isOffline && isOffline !== undefined) {
      isOfflineCheck(true);
    } else if (navigator.onLine && isOffline) {
      isOfflineCheck(false);
    }
  }, [navigator.onLine, isOffline]);

  useEffect(() => {
    const {
      auth: { isLoggedIn },
      cart: {
        cartDetails: { defaultCart }
      },
      account: { accountDetails }
    } = context;

    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    let selectedBranchNumber = selectedBranch;
    if (selectedBranch) {
      selectedBranchNumber = selectedBranch.code;
    }
    const userCartId = defaultCart ? defaultCart.cartId : null;
    const currentCustomerNumber = accountDetails
      ? accountDetails.customerNumber
      : null;

    if (
      prevIsLoggedInUser !== isLoggedIn ||
      (prevCustomerNumber !== currentCustomerNumber && currentCustomerNumber) ||
      (prevBranchNumber !== selectedBranchNumber && selectedBranchNumber) ||
      (prevCartId !== userCartId && userCartId)
    ) {
      const currentTools = formatHVACTools();

      setBranchNumber(selectedBranch ? selectedBranch.code : selectedBranch);
      setCustomerNumber(currentCustomerNumber);
      setIsLoggedInUser(isLoggedIn);
      setCartId(userCartId);
      setResources(currentTools);
    }

    prevCustomerNumber.current = customerNumber;
    prevIsLoggedInUser.current = isLoggedInUser;
    prevBranchNumber.current = branchNumber;
    prevCartId.current = cartId;
  }, [isLoggedInUser, customerNumber, branchNumber, cartId]);

  return (
    <div
      className={`app-header-navigation-component ${
        isMobileView ? "mobile-view" : ""
      }`}
      ref={wrapperRef}
    >
      <nav className="navbar navbar-expand hover-menu">
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              {renderCategories()}
              {isMobileView && isLoggedInUser && resources
                ? renderMenuItemWithSubItems(intl.get("resources"), resources)
                : null}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AppHeaderNavigationBarMain;
