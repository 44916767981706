import intl from "react-intl-universal";
import { getConfig } from "@zilker/store-components";

interface OrderStatusListInterface {
  [key: string]: string;
}
const OrderStatusList = () => {
  const statusList: OrderStatusListInterface = {
    O: intl.get("open"),
    R: intl.get("reviewed"),
    P: intl.get("priced"),
    C: intl.get("changed"),
    I: intl.get("invoiced"),
    K: intl.get("reserved"),
    N: intl.get("pending")
  };

  const motiliStatusList: OrderStatusListInterface = {
    CANCEL_REQUESTED: intl.get("cancel-requested"),
    CANCELLED: intl.get("cancelled"),
    CLOSED: intl.get("closed"),
    COMPLETED: intl.get("completed"),
    HOLD: intl.get("hold"),
    ORDER_REQUESTED: intl.get("order-requested"),
    ORDERED_CONFIRM: intl.get("order-confirm"),
    ORDERED: intl.get("ordered"),
    SUBMITTED: intl.get("submitted")
  };

  const { config } = getConfig();
  const {
    cortexApi: { scope }
  } = config;

  if (scope === "motili") return motiliStatusList;

  return statusList;
};

export default OrderStatusList;
