import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";

import "./video.less";

const Video = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return <>No Video to display</>;
  }

  const { description, title, link } = document.getData();
  const { width, height } = component.getProperties();

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {link && (
            <iframe
              className={`video-iframe ${
                width === 0 && height === 0 ? "unsized" : ""
              }`}
              width={width > 0 ? width : ""}
              height={height > 0 ? height : ""}
              title={title}
              src={link}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Video;
