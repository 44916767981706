/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { Link } from "react-router-dom";
import intl from "react-intl-universal";
import { generateImageUrl } from "../../../app/src/utils/mappings/productDetails";
import { ReactComponent as ErrorIcon } from "../../../app/src/images/icons/error-icon.svg";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "./ordertable.lineitem.less";

interface OrderTableLineItemProps {
  item: {
    [key: string]: any;
  };
  itemDetailLink?: string;
  prosItem?: boolean;
}

const OrderTableLineItem: React.FunctionComponent<OrderTableLineItemProps> = (
  props: OrderTableLineItemProps
) => {
  const { item, itemDetailLink, prosItem } = props;
  const { code } = item._item[0]._code[0];
  const displayName = item._item[0]._definition[0]["display-name"];
  const { quantity } = item;
  const totalPrice = item._total[0].cost[0].display;
  const productImage = generateImageUrl(item._item[0]._definition[0].details);
  let restrictedMaterialAttr;

  if (item._item[0]._definition[0] && item._item[0]._definition[0].details) {
    restrictedMaterialAttr = item._item[0]._definition[0].details.find(
      detail => detail.name === "restricted_materials"
    );
  }

  const { config }: { config: IEpConfig } = getConfig();
  const { defaultChannel } = config.brXM;
  const motiliStyle = defaultChannel === "motili";

  const imgMissingHorizontal = config.missingImagePlaceholderUrl;

  return (
    <tr
      className="order-lineitem-row"
      style={prosItem ? { backgroundColor: "#f0f0f0" } : {}}
    >
      <td className="thumbnail-col">
        <img
          className="thumbnail"
          src={productImage}
          alt={displayName || intl.get("none-available")}
          onError={e => {
            const element: any = e.target;
            element.src = imgMissingHorizontal;
          }}
        />
      </td>
      <td className="info-col">
        <div className="title-col-wrapper">
          <div className="title-col">
            <Link
              to={{
                pathname: `${itemDetailLink}/${code}`,
                state: { name: displayName }
              }}
            >
              {displayName}
            </Link>
            <p>
              <label htmlFor="item-code">
                {`${intl.get("product")}`}: &nbsp;
              </label>
              <span className="item-code">{item._item[0]._code[0].code}</span>
            </p>
          </div>
          <div className="quantity-col">
            <p>
              <label htmlFor="item-quantity">
                {motiliStyle
                  ? `${intl.get("quantity")}:`
                  : `${intl.get("qty-ordered")}: `}
                &nbsp;
              </label>
              <span className="item-quantity">{quantity}</span>
            </p>
            {config.showBackOrderedQuantity && (
              <p>
                <label htmlFor="backordered-item-quantity">
                  {intl.get("qty-b-o")}: &nbsp;
                </label>
                <span className="backordered-item-quantity">
                  {item.backOrderedQty}
                </span>
              </p>
            )}
          </div>
          {config.calculatePrice && (
            <div className="price-col">
              <div>
                <span className="purchase-price">{totalPrice}</span>
              </div>
            </div>
          )}
        </div>
        {restrictedMaterialAttr &&
        restrictedMaterialAttr["display-value"] === "Yes" ? (
          <div className="certification-required-warning">
            <ErrorIcon className="debug-msg-icon error" />
            <span className="bold">
              {intl.get("product-requires-certification")}
            </span>
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export default OrderTableLineItem;
