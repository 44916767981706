/* eslint-disable no-nested-ternary */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import {
  AppFooterMain,
  AppHeaderMain,
  AppFooterLogo,
  FacebookChat,
  HeaderLogo,
  HeaderBannerTicker,
  CookieAcceptancePopup,
  SimpleContent,
  getSelectedLocaleValue,
  Banner,
  Carousel,
  Video,
  GenericList,
  EnhancedBanner,
  EnhancedList,
  setSelectedLocaleValue,
  BlogList,
  Facets,
  AppFooterHorizontal,
  Grid,
  ReleaseNote,
  GlobalCarousel,
  QuickServices,
  RichText,
  SearchComponent,
  CTA,
  QuickHitsHorizontal,
  PromoContentGrid,
  SearchHomePage,
  TrainingCards,
  CallToAction,
  AppNotificationBanner,
  ContentBanner,
  HomeWarrantyLookUp,
  WarrantyLookUp,
  discoverOIDCParameters,
  CallOut,
  Articles
} from "@zilker/store-components";
import uuidv4 from "uuid/v4";
import React, {
  PropsWithChildren,
  ReactChildren,
  useContext,
  useEffect
} from "react";
import intl from "react-intl-universal";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  withRouter,
  useLocation
} from "react-router-dom";
import sha256 from "crypto-js/sha256";
import { BrComponent, BrPage, BrProps } from "@bloomreach/react-sdk";
import { Page, TYPE_CONTAINER_BOX } from "@bloomreach/spa-sdk";
import axios from "axios";
import packageJson from "../package.json";
import { checkMemberType } from "./utils/helpers";
import "./App.less";
import AboutUsPage from "./containers/AboutUsPage";
import AccountPage from "./containers/AccountPage";
import AccountStatementPage from "./containers/AccountStatementPage";
import AddPaymentMethod from "./containers/AddPaymentMethod";
import CartPage from "./containers/CartPage";
import CategoryListPage from "./containers/CategoryListPage";
import ChangePasswordForm from "./containers/ChangePasswordPage";
import CheckoutAuthPage from "./containers/CheckoutAuthPage";
import CheckoutPageFunctional from "./containers/CheckoutPageFunctional";
import ContactUsPageSF from "./containers/ContactUsPageSF";
import HomePage from "./containers/HomePage";
import ErrorPage from "./containers/ErrorPage";
import OrderHistoryPage from "./containers/OrderHistoryPage";
import OrderReviewPage from "./containers/OrderReviewPage";
import ProductDetailPage from "./containers/ProductDetailPage";
import ProductsComparePage from "./containers/ProductsComparePage";
import ProfilePage from "./containers/ProfilePage";
import PurchaseReceiptPage from "./containers/PurchaseReceiptPage";
import RegistrationPage from "./containers/RegistrationPage";
import SearchResultsPage from "./containers/SearchResultsPage";
import ShippingReturnsPage from "./containers/ShippingReturns";
import TermsAndConditionsPage from "./containers/TermsAndConditionsPage";
import WishListsPage from "./containers/WishListsPage";
import { LoginRedirectPage } from "./containers/LoginRedirectPage";
import Config from "./ep.config.json";
import OrderHistoryDetails from "./containers/OrderHistoryDetails";
import BranchSettings from "./containers/BranchSettings";
import AccountMain from "./containers/b2b/AccountMain";
import * as LoginService from "./services/LoginService";
import { brSuggest } from "./services/SearchService";
import MainContextProvider, { MainContext } from "./contexts/MainContext";
import MatchUpPage from "./containers/MatchUpPage";
import SessionExpiredPage from "./containers/SessionExpiredPage";
import detectIEBrowser from "./validation/detectIEBrowser";
import PartsFinderPage from "./containers/PartsFinderPage";
import ContractOrdersPage from "./containers/ContractOrdersPage";
import SystemBuilder from "./containers/SystemBuilder";
import LoginLandingPage from "./containers/LoginLandingPage";
import ProsExitPage from "./containers/ProsExitPage";
import DocumentsLibrary from "./containers/DocumentsLibrary";
import ReleaseNotes from "./containers/ReleaseNotes";
import CategoryLandingPage from "./containers/CategoryLandingPage";
import RegistrationSuccessfulPage from "./containers/RegistrationSuccessfulPage";
import DownloadPage from "./containers/DownloadPage";
import LeadDashboardInvitationPage from "./containers/LeadDashboardInvitationPage";
import AHRIRedirectPage from "./containers/AHRIRedirectPage";

declare global {
  interface Window {
    fbAsyncInit: any;
    dataLayer: any;
  }
}

// eslint-disable-next-line
declare var FB: any;

function handleCurrencyChange() {
  window.location.reload();
}

function handleAuthRedirect(history) {
  if (localStorage.pageRoute) {
    localStorage.removeItem("pageRoute");
  } else {
    history.push("/");
  }
}

function handleLocaleChange() {
  window.location.reload();
}

function handleFbAsyncInit() {
  // eslint-disable-next-line
  window.fbAsyncInit = function() {
    // eslint-disable-next-line
    FB.init({
      appId: Config.facebook.applicationId,
      status: true,
      xfbml: true,
      version: "v2.10"
    });
  };
}

const locationData = window.location.search;
// @ts-ignore: Property 'standalone' does not exist on type 'Navigator'.
const isInStandaloneMode = window.navigator.standalone;
const appFooterLinks = {
  aboutus: "/aboutus",
  contactus: "/contactus",
  shippingreturns: "/shippingreturns",
  termsandconditions: "/termsandconditions",
  shareFacebook: "/",
  shareTwitter: "/",
  shareInstagram: "/"
};
const appHeaderLinks = {
  mainPage: "/",
  myCart: "/mycart"
};
const appHeaderLoginLinks = {
  profile: "/myAccount/profile",
  branchLocator: "/myAccount/branchSettings",
  myOrders: "/myAccount/orderHistory",
  wishlists: "/wishlists",
  myContracts: "/myAccount/contractOrders",
  myLeads: "/myAccount/leadTracking",
  myReports: "/myAccount/leadReporting"
};
const appHeaderNavigationLinks = {
  categories: "/category/",
  subCategories: "/category/",
  categorieslanding: "/categorylanding/",
  warrantyLookup: "/warrantyLookup"
};
const appHeaderTopLinks = {
  shippingreturns: "/shippingreturns",
  aboutus: "/aboutus",
  contactus: "/contactus"
};
const appModalLoginLinks = {
  registration: "/registration"
};

const BR_ORIGIN = new URL(Config.brXM.origin);
const BR_CONTEXT_PATH = Config.brXM.contextPath;
let BR_CHANNEL_PATH = Config.brXM.defaultChannel;

const LEADGEN_ADMIN = "LEADGEN_ADMIN";
const LEADGEN_DISTRIBUTOR_ADMIN = "LEADGEN_DISTRIBUTOR_ADMIN";
const VIEW_DISTRIBUTOR_REPORTS = "VIEW_DISTRIBUTOR_REPORTS";
const LEADGEN_VIEWONLY = "LEADGEN_VIEWONLY";
const VIEW_ALL_DEALER_REPORTS = "VIEW_ALL_DEALER_REPORTS";
const ECOMMERCE_USER = "ECOMMERCE";

// hostname and URL-path are used for detecting if site is viewed in CMS preview
// and for fetching Page Model for the viewed page
let previewLocale = "";
if (window.location.pathname.indexOf("_cmsinternal") > 0) {
  const paths = window.location.pathname.split("/");
  previewLocale = paths[paths.findIndex(e => e === "_cmsinternal") + 1];
} else {
  previewLocale = "NOTINTERNAL";
}
let locale = getSelectedLocaleValue(Config);
const channel = Config.brXM.channels.filter(l => l.language === locale);
const previewChannel = Config.brXM.channels.filter(
  l => l.value === previewLocale
);

if (previewChannel[0] && previewChannel[0].language) {
  locale = previewChannel[0].language;
  setSelectedLocaleValue(locale, Config);
}

if (previewChannel.length === 1) {
  BR_CHANNEL_PATH = previewChannel[0].value;
} else if (channel.length === 1) {
  BR_CHANNEL_PATH = channel[0].value;
}

interface BoxContainerProps {
  page: Page;
  children: ReactChildren;
}

const BoxContainer = ({ page, children }: BoxContainerProps) => {
  if (page.isPreview()) {
    return (
      <div className={page.isPreview() ? "hst-container" : undefined}>
        {React.Children.map(children, child => (
          <div className={page.isPreview() ? "hst-container-item" : undefined}>
            {child}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      {React.Children.map(children, child => (
        <>{child}</>
      ))}
    </>
  );
};

const mapping = {
  "Home Page": HomePage,
  "My Account Page": AccountPage,
  "About Us": AboutUsPage,
  "Branch Settings": BranchSettings,
  "Account Main": AccountMain,
  "Account Statement": AccountStatementPage,
  "Contact Us": ContactUsPageSF,
  "Cart Page": CartPage,
  "Change Password": ChangePasswordForm,
  Checkout: CheckoutPageFunctional,
  "Add A Payment": AddPaymentMethod,
  "My Cart": CartPage,
  "Order History": OrderHistoryPage,
  "Order Details": OrderHistoryDetails,
  "Order Review": OrderReviewPage,
  "Products Compare": ProductsComparePage,
  Profile: ProfilePage,
  "Purchase Reciept": PurchaseReceiptPage,
  Registration: RegistrationPage,
  Search: SearchResultsPage,
  "Sign In": CheckoutAuthPage,
  "Shipping Returns": ShippingReturnsPage,
  "Terms And Conditions": TermsAndConditionsPage,
  Wishlists: WishListsPage,
  Maintenance: ErrorPage,
  Category: CategoryListPage,
  "Category Landing": CategoryLandingPage,
  "Product Details": ProductDetailPage,
  "Header Logo": HeaderLogo,
  "Footer Logo": AppFooterLogo,
  "Header Banner Ticker": HeaderBannerTicker,
  "Cookie Acceptance Popup": CookieAcceptancePopup,
  "Call Out": CallOut,
  "Simple Content": SimpleContent,
  Banner,
  Carousel,
  Video,
  "Generic List": GenericList,
  "Enhanced Banner": EnhancedBanner,
  "Enhanced List": EnhancedList,
  "Blog List": BlogList,
  "Blog Posts by Author": BlogList,
  Facets,
  "Match up": MatchUpPage,
  "Session Expired": SessionExpiredPage,
  "Warranty Lookup": WarrantyLookUp,
  "Parts Finder": PartsFinderPage,
  "Contract Orders": ContractOrdersPage,
  Grid,
  "Release Note": ReleaseNote,
  "Release Notes": ReleaseNotes,
  "Global Carousel": GlobalCarousel,
  "Quick Services": QuickServices,
  "System Finder": SystemBuilder,
  "Documents Library": DocumentsLibrary,
  "Rich Text": RichText,
  "Search Component": SearchComponent,
  CTA,
  "Custom Search": SearchHomePage,
  "Quick Hits Horizontal": QuickHitsHorizontal,
  "Promo Content Grid": PromoContentGrid,
  "Training Cards": TrainingCards,
  "Call To Action": CallToAction,
  "Notification Banner": AppNotificationBanner,
  "Content Banner": ContentBanner,
  "Warranty Look Up": HomeWarrantyLookUp,
  "Registration Successful": RegistrationSuccessfulPage,
  "Download Page": DownloadPage,
  "Lead Dashboard Invitation Page": LeadDashboardInvitationPage,
  "AHRI Redirect Page": AHRIRedirectPage,
  Articles,
  [TYPE_CONTAINER_BOX]: BoxContainer
};

// We need a specific instance of axios so we don't clash with our existing uses of axios.
const brAxios = axios.create();
brAxios.defaults.withCredentials = true;

const createLink = (href, linkText, className) => (
  <Link className={className} to={href}>
    {linkText()}
  </Link>
);

const redirectToHomePage = (): any => {
  return () => <Redirect to="/" />;
};

const VersionContainer = props => {
  const { appVersion, componentsVersion } = props;
  return (
    <div className="version" style={{ display: "none" }}>
      <span>{`${intl.get("app-version")}: ${appVersion}`}</span>
      <span>{`${intl.get("components-version")}: ${componentsVersion}`}</span>
    </div>
  );
};

const CMS = props => {
  const { history } = props;

  const context = useContext<{ auth: any }>(MainContext);
  const {
    auth: { setLogin, setGuestLoggedIn }
  } = context;
  return (
    <>
      <AppHeaderMain
        onCurrencyChange={handleCurrencyChange}
        onLocaleChange={handleLocaleChange}
        onAuthRedirect={() => {
          setLogin(true);
          setGuestLoggedIn(false);
          handleAuthRedirect(history);
        }}
        locationSearchData={locationData}
        isInStandaloneMode={isInStandaloneMode}
        appHeaderLinks={appHeaderLinks}
        appHeaderLoginLinks={appHeaderLoginLinks}
        appHeaderNavigationLinks={appHeaderNavigationLinks}
        appHeaderTopLinks={appHeaderTopLinks}
        appModalLoginLinks={appModalLoginLinks}
        brSuggest={brSuggest}
      />
      <div className="main-body-container d-flex flex-column" role="main">
        <BrComponent path="main" />
      </div>
      {Config.footer === "default-footer" && (
        <AppFooterMain key="app-footer" appFooterLinks={appFooterLinks} />
      )}
      {Config.footer === "horizontal-footer" && (
        <BrComponent path="footer-menu">
          <AppFooterHorizontal />
        </BrComponent>
      )}
    </>
  );
};

const Root = props => {
  const { componentsData, history } = props;

  const authenticateUser = async () => {
    if (!LoginService.isLoggedIn()) {
      try {
        const oidcSecret = uuidv4();
        localStorage.setItem("OidcSecret", oidcSecret);
        const oidcParameters: OidcParameters = await discoverOIDCParameters();
        const oidcStateObject = {
          secret: oidcSecret,
          pathname: window.location.pathname
        };

        const oidcStateEncoded = btoa(JSON.stringify(oidcStateObject));
        const redirectUrl = `${Config.b2b.openId.callbackUrl}/loggedin`;
        const { brand } = Config.b2b.openId;
        const queryArray = [
          `scope=${encodeURIComponent(oidcParameters.scopes)}`,
          "response_type=code",
          `client_id=${encodeURIComponent(oidcParameters.clientId)}`,
          `redirect_uri=${encodeURIComponent(redirectUrl)}`,
          `state=${oidcStateEncoded}`
        ];
        if (brand) {
          queryArray.push(`brand=${brand}`);
        }
        const query = queryArray.join("&");
        const loginUrl = `${oidcParameters.authorizationEndpoint}?${query}`;
        window.location.href = loginUrl;
      } catch (e) {
        // return () => <Redirect to="/maintenance" />;
      }
    }
  };

  interface OidcParameters {
    clientId: string;
    scopes: string;
    authorizationEndpoint: string;
    endSessionEndpoint: string;
  }

  const context = useContext<{ auth: any; user: any; account: any }>(
    MainContext
  );
  const {
    auth: { guestTokenError, setEmail },
    user: { userProfile },
    account: { accountDetails }
  } = context;
  const {
    email,
    ecommerceUser,
    ecommerceBuyer,
    roles,
    street,
    city,
    region,
    country,
    postalCode,
    givenName,
    familyName,
    phone
  } = userProfile;
  const { membership, customerNumber, company } = accountDetails;

  const location = useLocation();
  const messageString = "message";
  const successString = "success";
  const codeString = "code";
  const currentPath = location.search ? location.search.toLowerCase() : false;
  if (
    currentPath &&
    currentPath.indexOf(messageString) >= 0 &&
    currentPath.indexOf(successString) >= 0 &&
    currentPath.indexOf(codeString) >= 0 &&
    location.pathname === "/"
  ) {
    history.push({
      pathname: "/registrationSuccessful",
      search: location.search
    });
  }

  useEffect(() => {
    const privatePaths = [
      "/systemBuilder",
      "/mycart",
      "/warrantyLookup",
      "/myAccount",
      "/checkout"
    ];

    const privateAccountEcommPaths = [
      "/creditInformation",
      "/statement",
      "/invoiceQuery",
      "/branchSettings",
      "/savedOrders",
      "/standardOrders",
      "/orderHistory",
      "/contractOrders"
    ];

    const isLocationPrivate = privatePaths.find(privatePathSegment =>
      location.pathname.includes(privatePathSegment)
    );
    const isLocationPrivateForEcommUsers = privateAccountEcommPaths.find(path =>
      location.pathname.includes(path)
    );
    if (
      !LoginService.isLoggedIn() &&
      localStorage.getItem("pageRoute") !== location.pathname
    ) {
      if (location.pathname !== "/maintenance") {
        localStorage.setItem("tempPageRoute", location.pathname);
      }
      if (isLocationPrivate) {
        authenticateUser();
      }
    }
    if (email && (!ecommerceUser && isLocationPrivateForEcommUsers)) {
      history.push("/");
    }
  }, [location, email]);

  useEffect(() => {
    if (email) {
      setEmail(email);
    }
  }, [email]);

  useEffect(() => {
    if (roles) {
      const leadGenDistributorRole = roles.find(
        role =>
          role === LEADGEN_DISTRIBUTOR_ADMIN ||
          role === VIEW_DISTRIBUTOR_REPORTS
      );

      const leadGenRoles = roles.find(
        role =>
          role === LEADGEN_ADMIN ||
          role === LEADGEN_VIEWONLY ||
          role === VIEW_ALL_DEALER_REPORTS
      );

      if (leadGenDistributorRole || leadGenRoles) {
        const daikinComfortPro = "dcp";
        const hasDcpMembership = checkMemberType(daikinComfortPro, membership);

        const isEcommUser =
          roles && roles.find(role => role === ECOMMERCE_USER);
        if (leadGenDistributorRole) {
          document.cookie = `leadgenusertype=DISTRIBUTOR; path=/`;
        } else if (leadGenRoles && isEcommUser && hasDcpMembership) {
          document.cookie = `leadgenusertype=DCP; path=/`;
        } else if (leadGenRoles && !isEcommUser) {
          document.cookie = `leadgenusertype=INDEPENDENT; path=/`;
        } else if (document.cookie.length) {
          const cookieList = document.cookie.split("; ");
          if (cookieList !== undefined) {
            const leadgenCookie = cookieList.find(cookie =>
              cookie.startsWith("leadgenusertype")
            );
            if (leadgenCookie) {
              document.cookie =
                "leadgenusertype=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }
          }
        }
      }
    }
  }, [roles]);

  useEffect(() => {
    // sends information about logged in user to Segment
    if (email && customerNumber && window && window.analytics) {
      const hashedEmail = sha256(email).toString();
      window.analytics.identify(hashedEmail, {
        companyAddress1: street,
        companyAddress2: "",
        companyCity: city,
        companyState: region,
        companyCountry: country,
        companyZip: postalCode,
        customerNumber,
        email,
        firstName: givenName,
        lastName: familyName,
        phone,
        loyaltyPrograms: membership
      });
      window.analytics.group(customerNumber, {
        address1: street,
        address2: "",
        city,
        state: region,
        country,
        zip: postalCode,
        customerNumber,
        companyName: company,
        email,
        phone,
        loyaltyPrograms: membership
      });
    }
  }, [email]);

  return [
    <VersionContainer
      key="version-container"
      componentsVersion={componentsData.version}
      appVersion={packageJson.version}
    />,
    <FacebookChat
      key="facebook-chat"
      config={Config.facebook}
      handleFbAsyncInit={handleFbAsyncInit}
    />,
    <div
      key="app-content"
      className={detectIEBrowser() ? "ie-app-content" : "app-content"}
    >
      {detectIEBrowser() ? (
        <>
          <Route path="/maintenance" component={CMS} />
          <Redirect
            to={{
              pathname: "/maintenance",
              state: {
                error: {
                  e: { message: "IE browser" },
                  errIn: "IE browser",
                  renderIEMessage: true
                }
              }
            }}
          />
        </>
      ) : guestTokenError ? (
        <>
          <Route path="/maintenance" component={CMS} />
          <Redirect to="/maintenance" />
        </>
      ) : (
        <Switch>
          <Route
            path="/myAccount/:subPage?/:detail?/:zoom(.*)?"
            component={CMS}
          />
          <PrivateRoute
            path="/checkout/:kinpayPrid?"
            component={CMS}
            hasPermissions="/checkout/:kinpayPrid?"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/search/:keywords"
            component={CMS}
            hasPermissions="/search/:keywords"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/itemdetail/:productId"
            component={CMS}
            hasPermissions="/itemdetail/:productId"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/category/:keywords"
            component={CMS}
            hasPermissions="/category/:keywords"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/categorylanding/:categoryName"
            component={CMS}
            hasPermissions="/categorylanding/:categoryName"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/mycart"
            component={CMS}
            hasPermissions="/mycart"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/warrantyLookup"
            component={CMS}
            hasPermissions="/warrantyLookup"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/partsFinder"
            component={
              Config.partsFinderPageDisplay ? CMS : redirectToHomePage()
            }
            hasPermissions="/partsFinder"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/systemBuilder"
            component={CMS}
            hasPermissions="/systemBuilder"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/documentsLibrary"
            component={
              Config.documentsLibraryPageDisplay ? CMS : redirectToHomePage()
            }
            hasPermissions="/documentsLibrary"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <PrivateRoute
            path="/releaseNotes"
            component={CMS}
            hasPermissions="/releaseNotes"
            email={email}
            ecommerceUser={ecommerceUser}
            ecommerceBuyer={ecommerceBuyer}
            isLoggedIn={LoginService.isLoggedIn()}
          />
          <Route path="/" component={CMS} exact />
          <Route path="/(.*)" component={CMS} />
          <Redirect to="/" />
        </Switch>
      )}
    </div>
  ];
};

function PrivateRoute({
  component: Component,
  hasPermissions,
  email,
  ecommerceUser,
  ecommerceBuyer,
  ...rest
}) {
  const privateEcommPaths = [
    "/partsFinder",
    "/warrantyLookup",
    "/documentsLibrary",
    "releaseNotes",
    "/categorylanding",
    "/category",
    "/itemdetail",
    "/search",
    "/creditInformation",
    "/statement",
    "/invoiceQuery",
    "/branchSettings",
    "/savedOrders",
    "/standardOrders",
    "/orderHistory",
    "/contractOrders"
  ];

  const privateEcommBuyerPaths = ["/mycart", "/checkout", "/systemBuilder"];

  const isLocationPrivateForEcommUsers = privateEcommPaths.find(path =>
    hasPermissions.includes(path)
  );
  const isLocationPrivateForEcommBuyerUsers = privateEcommBuyerPaths.find(
    path => hasPermissions.includes(path)
  );
  if (
    email &&
    ((!ecommerceUser &&
      (isLocationPrivateForEcommUsers ||
        isLocationPrivateForEcommBuyerUsers)) ||
      (ecommerceUser && !ecommerceBuyer && isLocationPrivateForEcommBuyerUsers))
  ) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )}
      />
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
}

const BrPageWrapper = props => {
  const location = useLocation();
  // DGE-4202 Encoding double quotes and special characters to work around a bug in brXM.
  // The cloud version of brXM rejects urls that contain double quotes,
  // both url-encoded and not due to a bug in ingress, as well as urls that contain
  // backslashes, forwardslashes, backtick, caret, greater than and less than signs. The below logic can be
  // removed once the issue is fixed on the brXM side.
  const pathDoubleQuoteEscaped = location.pathname
    .replace(/%22/g, "%26quot%3B")
    .replace(/%3C/g, "%26lt%3B")
    .replace(/%3E/g, "%26gt%3B")
    .replace(/%2F/g, "%26sol%3B")
    .replace(/%5C/g, "%26bsol%3B")
    .replace(/%60/g, "%26grave%3B")
    .replace(/%5E/g, "%26caret%3B");

  const searchDoubleQuoteEscaped = location.search.replace(
    /%22/g,
    "%26quot%3B"
  );

  const brPageConfig = {
    httpClient: brAxios,
    endpoint: `${Config.brXM.siteOrigin}/site/${BR_CHANNEL_PATH}/resourceapi`,
    origin: Config.brXM.cmsOrigin,
    apiVersion: "1.0",
    path: `${pathDoubleQuoteEscaped}${searchDoubleQuoteEscaped}`
    // debug: true
  };
  return (
    <BrPage configuration={brPageConfig} mapping={mapping}>
      <MainContextProvider>
        <App {...props} />
      </MainContextProvider>
    </BrPage>
  );
};

const App = withRouter<any, any>(Root);

const AppWithRouter = props => {
  const { componentsData, history } = props;
  return (
    <Router>
      <Switch>
        <Route
          path="/loggedin"
          exact
          render={rprops => (
            <MainContextProvider>
              <LoginRedirectPage {...rprops} />
            </MainContextProvider>
          )}
        />
        <Route
          path="/login"
          exact
          render={rprops => (
            <MainContextProvider>
              <LoginLandingPage {...rprops} />
            </MainContextProvider>
          )}
        />
        <Route
          path="/registrationSuccessful"
          exact
          render={rprops => (
            <MainContextProvider>
              <RegistrationSuccessfulPage {...rprops} />
            </MainContextProvider>
          )}
        />
        <Route
          path="/leadInvitationAccept"
          exact
          render={rprops => (
            <MainContextProvider>
              <LeadDashboardInvitationPage {...rprops} />
            </MainContextProvider>
          )}
        />
        <Route
          path="/prosexit/:url"
          render={rprops => (
            <MainContextProvider>
              <ProsExitPage {...rprops} />
            </MainContextProvider>
          )}
        />
        <Route
          path="/download"
          render={rprops => {
            if (Config.daikinConnectDealerAppLinks.enabled) {
              return (
                <MainContextProvider>
                  <DownloadPage {...rprops} />
                </MainContextProvider>
              );
            }
            return (
              <MainContextProvider>{redirectToHomePage()}</MainContextProvider>
            );
          }}
        />
        <Route
          path="/ahriSystemConfigurator"
          exact
          render={rprops => (
            <MainContextProvider>
              <AHRIRedirectPage {...rprops} />
            </MainContextProvider>
          )}
        />
        <Route
          path="/"
          exact={false}
          render={passedProps => (
            <BrPageWrapper {...passedProps} componentsData={componentsData} />
          )}
        />
      </Switch>
    </Router>
  );
};
export default AppWithRouter;
