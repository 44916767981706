interface UserRolesInterface {
  [key: string]: string;
}

const UserRoles = () => {
  const userRoles: UserRolesInterface = {
    viewCredit: "ECOMM_VIEW_CREDIT",
    viewStatements: "ECOMM_VIEW_STATEMENTS",
    ecommerceBuyer: "ECOMMERCE_BUYER",
    leadDashboardAdmin: "LEADGEN_ADMIN",
    leadDistributerAdmin: "LEADGEN_DISTRIBUTOR_ADMIN",
    leadDashboardViewOnly: "LEADGEN_VIEWONLY"
  };

  return userRoles;
};

export default UserRoles;
