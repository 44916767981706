import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import PromoContentGridCard from "../../../PromoContentGridCard/promo-content-grid-card";

import "./promo-content-grid.less";

const PromoContentGrid = ({ component, page }: BrProps) => {
  if (!component || !page) {
    return null;
  }
  const { document: documentRef } = component.getModels();
  const content = documentRef && page.getContent(documentRef);

  let promoContentGridCards;

  if (content) {
    ({ promoContentGridCards } = content.getData());
  }

  const cardsData = promoContentGridCards || [];

  const setCardsStyle = () => {
    switch (cardsData.length) {
      case 2:
        return "two-grid-cards";
      case 3:
        return "three-grid-cards";
      case 4:
        return "four-grid-cards";
      default:
        return "";
    }
  };
  const cardsStyle = setCardsStyle();

  return (
    <div className={`container promo-content-grid ${cardsStyle}`}>
      {cardsData.map(card => (
        <PromoContentGridCard
          key={card.header}
          header={card.header}
          subHeader={card.subHeader}
          image={card.image}
          imageAltText={card.imageAltText}
          cardLink={card.cardLink}
          showRibbon={card.showRibbon}
          ribbonText={card.ribbonText}
          page={page}
        />
      ))}
    </div>
  );
};

export default PromoContentGrid;
