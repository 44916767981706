import React, { useState, useEffect, useContext } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet } from "@bloomreach/spa-sdk";
import Slider from "react-slick";

import { MainContext } from "../../../../../app/src/contexts/MainContext";
import PromotionsGridCard from "../../../GridCards/promotions.grid.card";
import EcommGridCard from "../../../GridCards/ecomm.grid.card";
import MainBannerGridCard from "../../../GridCards/mainbanner.grid.card";
import BusinessOpsGridCard from "../../../GridCards/businessops.grid.card";
import ProductTechOpsGridCard from "../../../GridCards/producttechops.gird.card";

import "./grid.less";

enum GridTypes {
  MAIN_BANNER = "main-banner",
  PROMOTION = "promotion",
  E_COMM_TOOLS = "e-comm-tools",
  PRODUCT_TECH_OPS = "product-tech-ops",
  BUSINESS_OPS = "business-ops"
}

const Grid = ({ component, page }: BrProps) => {
  const [grid, setGrid] = useState(null);

  const context = useContext<{ auth: any }>(MainContext);
  const {
    auth: { isLoggedIn }
  } = context;

  useEffect(() => {
    const { document: documentRef } = component.getModels();
    const document = documentRef && page.getContent<Document>(documentRef);
    setGrid(document);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const renderGridCard = gridCard => {
    const {
      image,
      id,
      type,
      backgroundImage,
      mobileBackgroundImage,
      link
    } = gridCard;
    const cardDesktopBackgroundImage = page.getContent<ImageSet>(
      backgroundImage
    );
    const cardMobileBackgroundImage = page.getContent<ImageSet>(
      mobileBackgroundImage
    );
    const cardImage = page.getContent<ImageSet>(image);
    const props = {
      gridCard: {
        ...gridCard,
        backgroundImage: cardDesktopBackgroundImage
          ? cardDesktopBackgroundImage.getOriginal().getUrl()
          : null,
        mobileBackgroundImage: cardMobileBackgroundImage
          ? cardMobileBackgroundImage.getOriginal().getUrl()
          : null,
        image: image ? cardImage.getOriginal().getUrl() : null
      },
      isLoggedIn,
      id
    };

    switch (type) {
      case GridTypes.MAIN_BANNER:
        return <MainBannerGridCard {...props} key={link} />;
      case GridTypes.PROMOTION:
        return <PromotionsGridCard {...props} key={link} />;
      case GridTypes.E_COMM_TOOLS:
        return <EcommGridCard {...props} key={link} />;
      case GridTypes.PRODUCT_TECH_OPS:
        return <ProductTechOpsGridCard {...props} key={link} />;
      case GridTypes.BUSINESS_OPS:
        return <BusinessOpsGridCard {...props} key={link} />;
      default:
        return null;
    }
  };

  const renderGridRow = () => {
    const {
      backgroundColor,
      backgroundImage,
      title,
      id,
      gridCards,
      titleColor,
      titleBottomBorderColor,
      gridCarousel
    } = grid.getData();

    const backgroundImageSrc = page.getContent<ImageSet>(backgroundImage);

    return (
      <div
        className={`grid-row-widget grid-row container ${
          backgroundColor && backgroundImage && titleBottomBorderColor
            ? "grid-row-product-tech-ops"
            : ""
        }`}
        style={(() => {
          if (backgroundColor && backgroundImage && titleBottomBorderColor) {
            return {
              background: `linear-gradient(${backgroundColor}, ${backgroundColor}), url(${backgroundImageSrc
                .getOriginal()
                .getUrl()})`
            };
          }
          return {
            backgroundColor,
            backgroundImage: `url(${
              backgroundImageSrc
                ? backgroundImageSrc.getOriginal().getUrl()
                : ""
            })`
          };
        })()}
        id={id}
      >
        {title && (
          <h2
            className="grid-row-title"
            style={{
              color: titleColor,
              borderBottom: `3px solid ${titleBottomBorderColor}`
            }}
          >
            {title}
          </h2>
        )}

        <div className="grid-cards-container">
          {gridCarousel && (
            <Slider
              slidesToShow={1}
              dots
              arrows={false}
              autoplay
              dotsClass="grid-carousel-pagination"
              draggable
              pauseOnDotsHover
              pauseOnFocus
              pauseOnHover
              autoplaySpeed={
                gridCarousel.autoplaySpeed
                  ? gridCarousel.autoplaySpeed * 1000
                  : 3000
              }
              className={`grid-carousel flex-${gridCarousel.carouselWidth}`}
            >
              {gridCarousel.gridCards.map(gridCard => renderGridCard(gridCard))}
            </Slider>
          )}
          {gridCards.map(gridCard => renderGridCard(gridCard))}
        </div>
      </div>
    );
  };

  return <>{grid && renderGridRow()}</>;
};

export default Grid;
