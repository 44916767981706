import React, { Component } from "react";
import * as intl from "react-intl-universal";
import { RouteComponentProps, withRouter } from "react-router";
import {
  cortexFetch,
  getConfig,
  Messagecontainer
} from "@zilker/store-components";
import base32 from "@elasticpath/ref-store/src/utils/base32/base32";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace,
  findProsBrand
} from "@elasticpath/ref-store/src/utils/helpers";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./SystemBuilderPros.less";

export interface IFrameMessage {
  message: IFrameMessageType;
  redirectUrl: string;
}

export enum IFrameMessageType {
  CLOSE_IFRAME = "CLOSE_IFRAME"
}

interface SystemBuilderProps extends RouteComponentProps {
  history: any;
}
interface SystemBuilderState {
  prosURL: string;
  prosServiceInvoked: boolean;
  device: string;
  currentBranch: string;
}
class SystemBuilder extends Component<SystemBuilderProps, SystemBuilderState> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {
      prosURL: "",
      prosServiceInvoked: false,
      device: "",
      currentBranch: ""
    };

    this.updateDevice = this.updateDevice.bind(this);
    this.redirectFromPros = this.redirectFromPros.bind(this);
  }

  componentDidMount() {
    // window.addEventListener("resize", this.updateDevice);
    window.addEventListener("message", this.redirectFromPros);
    this.fetchProsURL();
  }

  componentDidUpdate(prevProps, prevState) {
    const { prosURL, prosServiceInvoked, device, currentBranch } = this.state;
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    if (!prosURL && !prosServiceInvoked) {
      this.fetchProsURL();
    }

    if (device && device !== prevState.device) {
      this.fetchProsURL();
    }

    if (
      defaultCart &&
      currentBranch &&
      defaultCart.selectedBranch.code !== currentBranch
    ) {
      this.fetchProsURL();
    }
  }

  componentWillUnmount() {
    // window.addEventListener("resize", this.updateDevice);
    window.removeEventListener("message", this.redirectFromPros);
  }

  updateDevice() {
    this.setState({
      device: window.innerWidth > 1092 ? "desktop" : "touch"
    });
  }

  fetchProsURL() {
    const {
      config: {
        cortexApi: { scope }
      }
    } = getConfig();
    const {
      user: {
        userProfile: { email, preferredLocale }
      },
      cart: {
        cartDetails: { defaultCart }
      },
      auth: { logout },
      account: {
        accountDetails: { membership, customerNumber }
      }
    } = this.context;
    const { prosBrand } = findProsBrand(membership);
    const token = localStorage
      .getItem(`${scope}_oAuthToken`)
      .slice(6)
      .trim();
    const { history } = this.props;
    if (defaultCart && email && customerNumber && membership) {
      const { selectedBranch, cartId } = defaultCart;
      const body = {
        branch: selectedBranch.code,
        brand: prosBrand.toUpperCase(),
        "customer-number": customerNumber,
        email,
        language: preferredLocale,
        "shopping-cart-guid": base32.decode(cartId),
        "device-type": window.innerWidth > 1092 ? "desktop" : "touch",
        token
      };
      this.setState({
        prosURL: "",
        prosServiceInvoked: true,
        currentBranch: selectedBranch.code
      });
      cortexFetch(
        `/prosconfigui/${scope}/form?followlocation&format=standardlinks,zoom.nodatalinks&`,
        {
          method: "POST",
          body: JSON.stringify(body)
        }
      )
        .then(res => checkResponse(res))
        .then(res => {
          const { url } = res;
          this.setState({ prosURL: url });
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "logout => fetchProsURL => SystemBuilder.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "fetchProsURL => SystemBuilder.tsx"
            });
          }
        });
    }
  }

  redirectFromPros(event) {
    const { history } = this.props;
    const {
      data: { message, redirectUrl }
    } = event;
    const {
      cart: { getCartDetails },
      auth: { logout }
    } = this.context;
    if (message === IFrameMessageType.CLOSE_IFRAME) {
      getCartDetails()
        .then(() => {
          history.push(redirectUrl);
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "logout => redirectFromPros => SystemBuilder.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "redirectFromPros => SystemBuilder.tsx"
            });
          }
        });
    }
  }

  render() {
    const {
      user: {
        userProfile: { ecommerceUser }
      },
      account: {
        accountDetails: { jobsArray }
      }
    } = this.context;
    const { prosURL } = this.state;

    const hasJobPrice = JSON.stringify(jobsArray) !== "[]";
    return prosURL ? (
      <div className="system-builder-container container">
        {ecommerceUser ? (
          <>
            {hasJobPrice ? (
              <Messagecontainer
                message={{
                  type: "needinfo",
                  debugMessages: intl.get("job-pricing-message")
                }}
                closeContainerHandler={null}
                stayOpen
                overrideDefaultStickyBanner
              />
            ) : (
              ""
            )}
            <h1 className="section-title d-flex override-h1-as-h4">
              <span>{intl.get("system-builder")}</span>
            </h1>
            <iframe src={prosURL} title="PROS" />
          </>
        ) : (
          <h3>{intl.get("system-builder-error-message")}</h3>
        )}
      </div>
    ) : (
      <div className="loader-container">
        <h1>
          <span>{intl.get("loading-system-builder")}</span>
        </h1>
        <div className="loader" />
      </div>
    );
  }
}

export default SystemBuilder;
