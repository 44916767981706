import intl from "react-intl-universal";
import isEmpty from "./isEmpty";
import Config from "../ep.config.json";

const phoneRegex = new RegExp(
  /^[(]{0,1}[0-9]{3}[)]{0,1}[-|\s]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/
);
const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i);

export function validateNotifications(notificationsData, notificationsStatus) {
  const phoneNumbersWithError = [];
  const emailsWithError = [];

  const {
    phoneNumbers,
    orderStatusEmails,
    deliveryStatusEmail
  } = notificationsData;
  const {
    smsChecked,
    orderStatusChecked,
    deliveryStatusChecked
  } = notificationsStatus;

  if (smsChecked) {
    phoneNumbers.forEach((phoneNumber, index) => {
      if (!phoneRegex.test(phoneNumber)) {
        phoneNumbersWithError[index - 1] = intl.get("invalid-phone-number");
      }
    });
  }

  if (orderStatusChecked) {
    orderStatusEmails.forEach((email, index) => {
      if (!emailRegex.test(email)) {
        emailsWithError[index] = intl.get("invalid-email");
      }
    });
  }

  const emailWithError =
    deliveryStatusChecked && !emailRegex.test(deliveryStatusEmail)
      ? intl.get("invalid-email")
      : "";

  const errors = {
    deliveryStatusEmail: emailWithError,
    phoneNumbers: phoneNumbersWithError,
    orderStatusEmails: emailsWithError
  };

  const valid =
    !phoneNumbersWithError.length && !emailsWithError.length && !emailWithError;

  return {
    errors,
    isValid: valid
  };
}

function validateDateRange(dateString) {
  const maxDate = new Date();
  const minDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);

  const dateFileds = dateString.split("-");
  const dateEntered = new Date();
  dateEntered.setFullYear(dateFileds[0]);
  dateEntered.setMonth(dateFileds[1] - 1);
  dateEntered.setDate(dateFileds[2]);

  return (
    dateEntered.getTime() >= minDate.getTime() &&
    dateEntered.getTime() < maxDate.getTime()
  );
}

export function validateGeneralOrderInformation(
  orderInformation,
  poNumberRequired,
  phoneNumberRequired
) {
  let valid = true;
  const errors = {};
  // See DGE-3145. Alternate email input is removed.
  // const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i);

  // if (
  //   orderInformation["alternate-email-address"] &&
  //   orderInformation["alternate-email-address"] !== ""
  // ) {
  //   valid = emailRegex.test(orderInformation["alternate-email-address"]);
  //   if (!valid) {
  //     errors["alternate-email-address"] = intl.get("alternate-email-invalid");
  //   }
  // }

  if (!orderInformation.date.trim()) {
    errors.date = intl.get("mandatory-field-message", {
      key: intl.get("date-needed")
    });
    valid = false;
  }

  const dateRegex = new RegExp(/^\d{4}-\d{2}-\d{2}$/);

  if (orderInformation.date.trim() && !dateRegex.test(orderInformation.date)) {
    errors.date = intl.get("date-format-error");
    valid = false;
  }

  if (
    dateRegex.test(orderInformation.date) &&
    !validateDateRange(orderInformation.date)
  ) {
    errors.date = intl.get("date-range-error");
    valid = false;
  }

  if (
    (Config.POAlwaysRequired || poNumberRequired === "true") &&
    !orderInformation["po-number"].trim()
  ) {
    errors["po-number"] = intl.get("mandatory-field-message", {
      key: intl.get("p-o-number")
    });
    valid = false;
  }

  if (
    orderInformation["po-number"] &&
    orderInformation["po-number"].trim() &&
    orderInformation["po-number"].length > 22
  ) {
    errors["po-number"] = intl.get("po-number-maximum-size");
    valid = false;
  }

  if (phoneNumberRequired && !phoneRegex.test(orderInformation.phone)) {
    errors.phone = intl.get("invalid-phone-number");
    valid = false;
  }

  if (
    phoneNumberRequired &&
    (!orderInformation.phone || !orderInformation.phone.trim())
  ) {
    errors.phone = intl.get("mandatory-field-message", {
      key: intl.get("phone-number")
    });
    valid = false;
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}

export function validateRequiredMethod(method, methodName) {
  return {
    errors: method
      ? ""
      : intl.get("mandatory-field-message", { key: intl.get(methodName) }),
    isValid: !!method
  };
}
