import React from "react";
import "./notificationbanner.less";
import "../../../app/src/theme/variables.less";
import { Link } from "react-router-dom";
import { isLinkExternal } from "@elasticpath/ref-store/src/utils/helpers";
import { ReactComponent as CloseIcon } from "../../../app/src/images/icons/new-close-icon.svg";
import { ReactComponent as RightArrow } from "../../../app/src/images/icons/right-arrow.svg";

interface NotificationBannerProps {
  isOpen: boolean;
  message: string;
  typeOfBanner: string;
  link: string;
  alignment: string;
  closeContainerHandler: (...args: any[]) => any;
}
const NotificationBanner: React.SFC<NotificationBannerProps> = ({
  isOpen,
  message,
  typeOfBanner,
  closeContainerHandler,
  link,
  alignment
}) => {
  const [isExternal, url] = isLinkExternal(link);

  const generateDefaultBannerContent = () => (
    <div
      className={`message-container ${
        alignment === "Center" ? "message-on-center" : ""
      }`}
    >
      {message}
    </div>
  );

  const generateCloseableBannerContent = () => (
    <>
      {generateDefaultBannerContent()}
      <button
        type="button"
        className="close-button"
        onClick={closeContainerHandler}
      >
        <CloseIcon className="close-icon" />
      </button>
    </>
  );

  const generateActionBannerContent = () => (
    <>
      {generateDefaultBannerContent()}
      <div>
        <RightArrow className="right-arrow-icon" />
      </div>
    </>
  );

  const generateBannerContent = () => {
    switch (typeOfBanner) {
      case "Closeable":
        return (
          <div className="container main-banner-container">
            {generateCloseableBannerContent()}
          </div>
        );
      case "Action":
        return (
          <Link
            to={isExternal ? { pathname: url } : url}
            target={!isExternal ? "_self" : "_blank"}
            rel={!isExternal ? "noopener noreferrer" : ""}
          >
            <div className="container main-banner-container">
              {generateActionBannerContent()}
            </div>
          </Link>
        );
      default:
        return (
          <div className="container main-banner-container">
            {generateDefaultBannerContent()}
          </div>
        );
    }
  };

  return (
    <div className="notification-banner-widget">
      <div className={isOpen ? "showing" : "closing"}>
        {generateBannerContent()}
      </div>
    </div>
  );
};
export default NotificationBanner;
