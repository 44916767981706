import React, { FC, useContext, useEffect, useState } from "react";
import intl from "react-intl-universal";
import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { DaikinLeadGenReport } from "@goodman-cloud/daikin-common-components";

import "./LeadTracking.less";

interface LeadReportingProps {}

enum UserRole {
  VIEW_DEALER_REPORTS = "VIEW_DEALER_REPORTS",
  VIEW_ALL_REPORTS = "VIEW_ALL_REPORTS",
  VIEW_DISTRIBUTOR_REPORTS = "VIEW_DISTRIBUTOR_REPORTS"
}

const LeadReporting: FC<LeadReportingProps> = () => {
  const context = useContext<{ user: any }>(MainContext);
  const {
    user: {
      userProfile: {
        hasLeadGenRoles,
        hasLeadGenDistributorRoles,
        leadGenDealerEmail,
        roles
      }
    }
  } = context;

  const { config }: { config: IEpConfig } = getConfig();
  const { connectServicesToken, environment } = config;
  const userHasPermissions = hasLeadGenRoles || hasLeadGenDistributorRoles;

  const [reportTypeToUse, setReportTypeToUse] = useState<string>(undefined);
  const [isDaikinManager, setIsDaikinManager] = useState<boolean>(false);

  useEffect(() => {
    if (roles) {
      const userRoleType = roles.find(
        role =>
          role.toUpperCase() === "VIEW_DEALER_REPORTS" ||
          role.toUpperCase() === "VIEW_ALL_REPORTS" ||
          role.toUpperCase() === "VIEW_DISTRIBUTOR_REPORTS"
      );
      setReportTypeToUse(userRoleType);
      setIsDaikinManager(userRoleType === "VIEW_ALL_REPORTS");
    }
  }, [roles]);

  return (userHasPermissions || isDaikinManager) && reportTypeToUse ? (
    <div className="lead-reporting-table">
      <DaikinLeadGenReport
        bearerToken={connectServicesToken}
        email={leadGenDealerEmail}
        env={environment}
        pageCount={10}
        title="Leads Report"
        type={UserRole[reportTypeToUse]}
      />
    </div>
  ) : (
    <>
      <div className="content-box">
        <h2>{intl.get("lead-reporting")}</h2>
        <div>{intl.get("lead-tracking-no-permissions")}</div>
      </div>
    </>
  );
};

export default LeadReporting;
