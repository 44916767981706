/**
 * Refactored component from productdisplayitem.main.tsx. This component handles retrieving the data needed
 * for add to cart and the display of the inventory messaging.
 */
import React, { FC, useContext, useState, useEffect } from "react";

import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";

import { useHistory } from "react-router-dom";

import {
  convertUnitOfMeasure,
  formatQuoteAvailability,
  formatAhriLink
} from "@elasticpath/ref-store/src/utils/helpers";
import { getAvailability } from "@elasticpath/ref-store/src/services/connectGQLservices";

import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";

import ProductAvailability from "./ProductAvailability";
import { CatalogPriceResponse } from "../productdisplayitem.interfaces";
import AppHeaderLoginMain from "../../AppHeaderLogin/appheaderlogin.main";

interface ProductAddToCartSectionProps {
  productId: string;
  productData: any;
  priceData: CatalogPriceResponse;
  entitlement: boolean;
}

const ProductAddToCartSection: FC<ProductAddToCartSectionProps> = ({
  productId,
  productData,
  priceData,
  entitlement
}) => {
  const [inventoryAvailability, setInventoryAvailability] = useState(null);
  const [inventoryError, setInventoryError] = useState<string>("");
  const [validQuantity, setValidQuantity] = useState<boolean>(true);
  const [itemQty, setItemQty] = useState<number>(1);
  const [branchAvailability, setBranchAvailability] = useState(null);
  const [regionAvailability, setRegionAvailability] = useState(null);
  const [dcAvailability, setDcAvailability] = useState(null);
  const [unitOfMeasure, setUnitOfMeasure] = useState<number>(1);
  const [openAlternateBranchModal, setOpenAlternateBranchModal] = useState<
    boolean
  >(false);

  const history = useHistory();
  const { config }: { config: IEpConfig } = getConfig();

  const DEFAULT_UOM: number = 1.0;

  const context = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
    account: any;
  }>(MainContext);
  const {
    branches: { branchesList },
    user: {
      userProfile: { ecommerceBuyer }
    },
    auth: { isLoggedIn },
    cart: {
      cartDetails: { defaultCart }
    },
    account: {
      accountDetails: { customerNumber, membership }
    }
  } = context;
  const { systemBuilderPageDisplay } = config;

  const currentBranchNumber =
    defaultCart && defaultCart.selectedBranch
      ? defaultCart.selectedBranch.code
      : undefined;

  const appHeaderLoginLinks = {
    profile: "/myAccount/profile",
    wishlists: "/wishlists"
  };

  const appModalLoginLinks = {
    registration: "/registration"
  };

  const openAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(true);
  };

  const closeAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(false);
  };

  // Refresh if the product Id, branch list is still loading, or current selected branch changes.
  useEffect(() => {
    if (isLoggedIn && branchesList) {
      handleDGAInventory(currentBranchNumber);
    }
  }, [productId, branchesList, currentBranchNumber]);

  const formatUnitOfMeasure = items => {
    const UOM = items.find(item => item.unitOfMeasure);
    if (UOM) {
      return Number(UOM.unitOfMeasure);
    }
    return DEFAULT_UOM;
  };

  const handleDGAInventory = branchToCheck => {
    getAvailability([productId], customerNumber, branchToCheck)
      .then(res => {
        if (res && res.data && res.data.data && res.data.data.inventory) {
          if (!res.data.data.inventory.entitledInventory) {
            console.error("Inventory error");
            setInventoryError(intl.get("inventory-error"));
          } else {
            const data = res.data.data.inventory.entitledInventory;
            const availability = formatQuoteAvailability(
              data.branches,
              data.regionRollups,
              [productId],
              data.hubInventory
            );
            const UOM = formatUnitOfMeasure(availability);
            setUnitOfMeasure(UOM);
            const convertedUnitOfMeasure = convertUnitOfMeasure(UOM, itemQty);

            const itemAvailability = availability.find(
              item => item.branchNumber === branchToCheck
            );
            setBranchAvailability(itemAvailability.branchAvailability);
            setRegionAvailability(itemAvailability.regionAvailability);
            setDcAvailability(itemAvailability.dcQtyAvailable);

            setInventoryAvailability(availability);
            setValidQuantity(convertedUnitOfMeasure === 0);
            setInventoryError("");
          }
        }
      })
      .catch(error => {
        handleAvailabilityError(error);
      });
  };

  /**
   * ## populateitemQuantity
   * @param quantity number
   *
   * @description Sets selected item quantity in the state.
   */
  const populateitemQuantity = (quantity: number) => {
    const convertedUnitOfMeasure =
      unitOfMeasure && convertUnitOfMeasure(unitOfMeasure, quantity);

    setValidQuantity(convertedUnitOfMeasure === 0);
    setItemQty(quantity);
  };

  const handleAvailabilityError = error => {
    console.error("Inventory error", error);
    setInventoryError(intl.get("inventory-error"));
  };
  const isPriceZero = priceData && `${priceData.total}` === "0";
  const isCallButtonActive =
    (config.calculatePrice && isPriceZero) ||
    !entitlement ||
    (config.checkAvailability &&
      !branchAvailability &&
      !regionAvailability &&
      !validQuantity);

  const renderAuthenticatedAddtoCartHTML = () => {
    return (
      <>
        <ProductAvailability
          productId={productId}
          productData={productData}
          itemPriceDetails={priceData}
          skuEntitled={entitlement}
          validQuantity={validQuantity}
          inventoryAvailability={inventoryAvailability}
          itemQty={itemQty}
          branchAvailability={branchAvailability}
          regionAvailability={regionAvailability}
          inventoryError={inventoryError}
          isCallButtonActive={isCallButtonActive}
          isModalOpened={openAlternateBranchModal}
          openAlternateBranchesModal={openAlternateBranchesModal}
          closeAlternateBranchesModal={closeAlternateBranchesModal}
          populateitemQuantity={populateitemQuantity}
          dcAvailability={dcAvailability}
        />

        {systemBuilderPageDisplay && ecommerceBuyer && (
          <p className="system-builder-message">
            {intl.get("need-complete-system")}
            <button
              type="button"
              className="system-builder-link"
              onClick={() =>
                formatAhriLink(membership, customerNumber, defaultCart)
              }
            >
              {intl.get("system-configurator")}
            </button>
          </p>
        )}
        {!validQuantity && (
          <p className="invalid-quantity-message">
            {intl.get("unit-of-measure-error-message", {
              product: intl.get("product"),
              unitOfMeasure
            })}
          </p>
        )}
      </>
    );
  };

  const renderUnAuthenticatedAddToCartHTML = () => {
    return (
      <div className="atc-placeholder-logged-out">
        {intl.get("anonymous-browse-product-cta1")}
        &nbsp;
        <AppHeaderLoginMain
          history={history}
          permission
          appHeaderLoginLinks={appHeaderLoginLinks}
          appModalLoginLinks={appModalLoginLinks}
          isLoggedIn={false}
        />
        &nbsp;
        {intl.get("anonymous-browse-product-cta2")}
      </div>
    );
  };

  return (
    <>
      {isLoggedIn
        ? renderAuthenticatedAddtoCartHTML()
        : renderUnAuthenticatedAddToCartHTML()}
    </>
  );
};

export default ProductAddToCartSection;
