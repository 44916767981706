/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useContext } from "react";
import { pushToMaintenace } from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig } from "../utils/ConfigProvider";
import ShippingOptionContainer from "../ShippingOption/shippingoption.container";
import AddressContainer from "../AddressContainer/address.container";
import OrderDetailsComponent from "../OrderDetailsComponent/OrderDetailsComponent";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./purchasedetails.main.less";

let intl = { get: str => str };

interface PurchaseDetailsMainProps {
  data: {
    [key: string]: any;
  };
  onReorderAllProducts?: (...args: any[]) => any;
  itemDetailLink?: string;
  onMoveToCart?: (...args: any[]) => any;
  onConfiguratorAddToCart?: (...args: any[]) => any;
  history: any;
  auth: any;
  user: any;
}

const PurchaseDetailsMain: React.FunctionComponent<PurchaseDetailsMainProps> = (
  props: PurchaseDetailsMainProps
) => {
  const { data, history, user } = props;
  const { order, poNumber } = data;
  const { userProfile } = user;
  const context = useContext<{ order: any }>(MainContext);
  const {
    order: { backOrderedQuantity }
  } = context;
  ({ intl } = getConfig());
  const { config } = getConfig();
  const [billingAddress] = data._billingaddress;
  const { name, address } = billingAddress;

  const formatDate = milliseconds => {
    const months = [
      intl.get("january"),
      intl.get("february"),
      intl.get("march"),
      intl.get("april"),
      intl.get("may"),
      intl.get("june"),
      intl.get("july"),
      intl.get("august"),
      intl.get("september"),
      intl.get("october"),
      intl.get("november"),
      intl.get("december")
    ];
    const date = new Date(milliseconds);
    const dateFormatted = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
    return dateFormatted;
  };

  const orderPurchaseDate = formatDate(data["purchase-date"].value);
  let orderTotal = null;
  let otherCharges = null;
  if (order && order[0]._total && order[0]._total[0].cost) {
    orderTotal = order[0]._total[0].cost[0].display;
  }
  const shipments = data._shipments;
  if (
    order &&
    order[0]["_other-charges"] &&
    order[0]["_other-charges"][0].other
  ) {
    otherCharges = order[0]["_other-charges"][0].other.display;
  }

  let orderTaxTotal = null;
  let orderTaxGst = null;

  try {
    if (order && order[0]._tax && order[0]._tax[0].cost) {
      const taxCosts = order[0]._tax[0].cost || [];

      orderTaxTotal = taxCosts.find(costItem => costItem.title === "TAX");
      if (userProfile.isCanadianUser) {
        orderTaxGst = taxCosts.find(costItem => costItem.title === "GST");
      }
    }
  } catch (error) {
    pushToMaintenace(history, { e: error, errIn: "PurchaseDetailsMain.tsx" });
  }

  const renderShippingOption = () => {
    const { branch } = data;

    if (shipments) {
      const [option] = data._shipments[0]._element[0]._shippingoption;
      return (
        <div className="purchase-option">
          <h3>{intl.get("fulfillment-method")}</h3>
          <ShippingOptionContainer option={option} />
          <span>{intl.get("branch")}:</span>
          {branch && <span>{branch.branchName}</span>}
          {branch && <span>{branch.formattedAddress}</span>}
          {branch && <a href={`mailto:${branch.email}`}>{branch.email}</a>}
        </div>
      );
    }
    return null;
  };

  const renderPaymentMethod = () => {
    let postedPayments;
    try {
      postedPayments =
        data.order[0]._paymentinstrumentselector[0]._chosen[0]._description[0]
          .name;
    } catch {
      postedPayments = "";
    }

    return (
      <td data-el-value="paymentMethod">
        {postedPayments && intl.get(postedPayments)}
      </td>
    );
  };

  const renderPromotions = (promoCodes: string | null): any => {
    if (promoCodes) {
      return (
        <tr>
          <td>{intl.get("applied-promotion-codes")}:</td>
          <td data-el-value="appliedPromotions">{promoCodes}</td>
        </tr>
      );
    }

    return null;
  };

  return (
    <div data-region="purchaseInformationRegion" className="container">
      <div className="purchase-information-container">
        <h2 id="purchaseInformationMessage">
          {intl.get("order-processed-message")}
        </h2>

        <div data-region="purchaseSummaryRegion" className="purchase-summary">
          <div>
            <h4>{intl.get("summary")}</h4>

            <OrderDetailsComponent
              job={data.job}
              contractNumber={data.contractNumber}
            />
            <table className="table table-borderless">
              <tbody>
                <tr className="order-information-row">
                  <td>{intl.get("order-purchase-date")}:</td>
                  <td data-el-value="purchaseDate">{orderPurchaseDate}</td>
                  <td>{intl.get("billing-address")}:</td>
                  <td data-el-value="billingAddress">
                    <AddressContainer name={name} address={address} />
                  </td>
                </tr>
                <tr className="order-information-row">
                  <td>{intl.get("purchase-order")}:</td>
                  <td data-el-value="purchaseOrder">{poNumber}</td>
                  <td>{intl.get("payment-method")}:</td>
                  {renderPaymentMethod()}
                </tr>
                {config.enablePromotion && renderPromotions(data.promoCodes)}
                {config.calculatePrice && orderTaxTotal ? (
                  <tr data-el-container="taxTotal">
                    <td>
                      {userProfile.isCanadianUser
                        ? intl.get("canada-pst-tax")
                        : intl.get("tax")}
                      :
                    </td>
                    <td data-el-value="taxTotal">{orderTaxTotal.display}</td>
                  </tr>
                ) : null}
                {config.calculatePrice &&
                userProfile.isCanadianUser &&
                orderTaxGst ? (
                  <tr data-el-container="taxGst">
                    <td>{intl.get("gst-tax")}:</td>
                    <td data-el-value="taxGst">{orderTaxGst.display}</td>
                  </tr>
                ) : null}
                {config.calculatePrice &&
                userProfile.isCanadianUser &&
                otherCharges ? (
                  <tr data-el-container="otherCharges">
                    <td>{intl.get("other-charges")}:</td>
                    <td data-el-value="otherCharges">{otherCharges}</td>
                  </tr>
                ) : null}
                {config.calculatePrice && (
                  <tr data-el-container="orderTotal">
                    <td>{intl.get("total")}:</td>
                    <td data-el-value="orderTotal" colSpan={3}>
                      {orderTotal}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="warning-messages">
              {backOrderedQuantity && backOrderedQuantity.length
                ? backOrderedQuantity.some(item => item.quantity > 0) && (
                    <p>*{intl.get("backordered-items-warning")}</p>
                  )
                : null}
              {config.calculatePrice ? (
                <p className="price-disclaimer">
                  *
                  {intl.get(
                    "commodity-prices-may-vary-at-final-pickup-or-shipment"
                  )}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseDetailsMain;
