/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import intl from "react-intl-universal";
import { getConfig } from "@zilker/store-components";

function AboutUsPage() {
  const { config } = getConfig();
  const { defaultChannel } = config.brXM;
  const motiliTitle = defaultChannel === "motili";
  return (
    <div
      className="container-fluid"
      data-region="viewPortRegion"
      style={{ display: "block" }}
    >
      <div>
        <div data-region="appMain" style={{ display: "block" }}>
          <div>
            <div className="static-container container">
              <div className="static-container-inner">
                <div
                  className="static-title-container"
                  style={{ display: "block" }}
                >
                  <div>
                    <h1
                      className={`view-title ${
                        motiliTitle ? "staticPageTitle" : ""
                      }`}
                    >
                      {intl.get("about-us")}
                    </h1>
                  </div>
                </div>
                <div
                  className="static-main-container"
                  style={{ display: "block" }}
                >
                  <div className="static-container">
                    <span className="static-message">
                      {intl.get("about-us-content")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsPage;
