import React, { FC, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getConfig } from "@zilker/store-components";

const DownloadPage: FC<RouteComponentProps> = () => {
  const { config } = getConfig();
  const {
    daikinConnectDealerAppLinks: { ios, android }
  } = config;

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(ua)) {
      return "iOS";
    }
    return "Other";
  };

  const isAndroid = getMobileOS() === "Android";
  const isIOS = getMobileOS() === "iOS";
  useEffect(() => {
    if (isAndroid) {
      window.location.href = android;
    } else if (isIOS) {
      window.location.href = ios;
    } else {
      window.location.href = "/";
    }
  }, []);

  return <div className="download-page" />;
};

export default withRouter(DownloadPage);
