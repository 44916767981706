/* eslint-disable class-methods-use-this */
import React from "react";
import intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import { History } from "history";
import {
  OrderItemDetails,
  page,
  productAdded,
  getConfig
} from "@zilker/store-components";
import MessageContainer from "@zilker/store-components/src/MessageContainer/messagecontainer";
import {
  noOp,
  checkTokensExpired,
  simpleToLocaleDate,
  pushToMaintenace,
  isoToSimpleDate,
  openInvoiceReport,
  formatNullString,
  generateSpecificErrorMessage,
  isSpecialAirPurifier,
  updateBranchNumber
} from "../utils/helpers";
import {
  getOrderHistoryDetailsGQL,
  getInvoiceReportGQL
} from "../services/connectGQLservices";
import { MainContext } from "../contexts/MainContext";
import MethodOfShipment from "../utils/mappings/methodOfShipment";
import OrderStatusList from "../utils/mappings/statusList";
import { addToCart } from "../services/EpServices";

import "./OrderHistoryDetails.less";

interface OrderDetailsPayment {
  amount: string;
  authNumber: string;
  cardType: string;
  invoiceNumber: string;
  message: string;
  paymentMethod: string;
  prid: string;
  referenceNumber: string;
  resultCode: string;
  status: string;
  transactionType: string;
  type: string;
}

enum PaymentType {
  ACCOUNT = "ACCOUNT",
  CASH = "CASH",
  CARD = "CREDITCARD"
}

interface MotiliOrderDetailsData {
  orderedOn: string;
  estFulfillmentDate: string;
  actualFulfillmentDate: string;
  lineItems: Array<MotiliOrderLineItem>;
  contractorId: string;
  extReferenceNumber1: string;
  extPoNumber: string;
  fulfillmentTypeId: string;
  fulfillmentTo: string;
  trackingNumber: string;
  trackingUrl: string;
  trackingCompany: string;
  eCommOrderStatus: string;
  eCommPickedStatus: string;
  fulfillmentToStdAddress: string;
  fulfillmentToStdDeliveryLine?: string;
  fulfillmentToStdLastLine?: string;
  fulfillmentToStdCity?: string;
  fulfillmentToStdCountry?: string;
  fulfillmentToStdPostalCode?: string;
  vendorDistributionCenter: MotiliBranchDetails;
  statusId: string;
}

interface MotiliBranchDetails {
  rawAddress: string;
  vendorBranchNumber: string;
  vendorBranchContactEmail: string;
}

interface MotiliOrderLineItem {
  quantity: string | number;
  display: string;
  details: string;
}

interface OrderDetailsData {
  branchNumber: string;
  deliveryBranchAddress: string;
  deliveryBranchEmail: string;
  shipFromBranchNumber: string;
  orderPlacedDate: string;
  promisedShipDate: string;
  shipDate: string;
  details: Array<any>;
  customerNumber: string;
  orderNumber: string;
  purchaseOrderNumber: string;
  orderMethodOfShipment: string;
  orderByName: string;
  trackingNo: string;
  ordersShippingAddress1: string;
  ordersShippingAddress2: string;
  shippingCity: string;
  shippingState: string;
  mainShippingZipCode: string;
  shipCode: string;
  shipCodeDesc: string;
  subTotalAmount: string;
  otherChargesTotalAmt: any;
  orderTaxAmount: string;
  gstTaxAmount: string;
  totalInvoiceAmount: string;
  invoiceDate?: string;
  backOrderStatus?: string;
  orderStatusLookup: {
    ecommDescription: string;
  };
  pickStatusLookup: {
    ecommDescription: string;
  };
  shipmentStatusLookup: {
    ecommDescription: string;
  };
  submittedOrder?: {
    paymentType: PaymentType;
    payment: OrderDetailsPayment;
  };
}

interface OrderLineItem {
  quantityOrdered: string | number;
  sku: string;
  netPrice: string;
  linePrice: string;
  quantityShipped: string;
  quantityBackOrdered: string;
  description: string;
}

interface OrderHistoryDetailsPageProps extends BrProps {
  history: History;
  match: any;
  auth: any;
  user: any;
  branches: any;
  pageConfig: PageConfigProps;
}

interface PageConfigProps {
  title: string;
  topButtons: boolean;
  showSerialNumber: boolean;
  showInvoiceDate: boolean;
}

interface ResultMessage {
  debugMessages: string;
  id: string;
  type: string;
}

interface OrderHistoryDetailsPageState {
  loadingPickupOrDelivery: string;
  isProductBeingAdded: { sku: string; shippingMethod: string };
  isInvoiceBeingOpened: boolean;
  purchaseDetails: any;
  resultMessage: ResultMessage;
  noInvoiceError: string;
}

const emptyMessage: ResultMessage = {
  debugMessages: "",
  id: "",
  type: ""
};

let Config;

class OrderHistoryDetailsPage extends React.Component<
  OrderHistoryDetailsPageProps,
  OrderHistoryDetailsPageState
> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      loadingPickupOrDelivery: "",
      isProductBeingAdded: { sku: "", shippingMethod: "" },
      isInvoiceBeingOpened: false,
      purchaseDetails: undefined,
      resultMessage: emptyMessage,
      noInvoiceError: ""
    };
    this.fetchOrderDetails = this.fetchOrderDetails.bind(this);
    this.renderOrderItems = this.renderOrderItems.bind(this);
    this.onAddToCart = this.onAddToCart.bind(this);
    this.closeMessageContainer = this.closeMessageContainer.bind(this);
    this.onPrintInvoiceClicked = this.onPrintInvoiceClicked.bind(this);
    this.renderOrderDetailsButtons = this.renderOrderDetailsButtons.bind(this);
    this.renderPaymentCardDetails = this.renderPaymentCardDetails.bind(this);
    this.isShipmentPickupOrDelivery = this.isShipmentPickupOrDelivery.bind(
      this
    );
  }

  componentDidMount() {
    const {
      match,
      branches: { branchesList }
    } = this.props;
    const params = match.url.split("/");
    const orderNumber = params[params.length - 1];
    this._isMounted = true;
    if (branchesList) {
      this.fetchOrderDetails(orderNumber);
    }

    page();
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      branches: { branchesList }
    } = this.props;
    const params = match.url.split("/");
    const orderNumber = params[params.length - 1];
    if (prevProps.branches.branchesList !== branchesList) {
      this.fetchOrderDetails(orderNumber);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  formatOrderDetails(
    detailsData: MotiliOrderDetailsData | OrderDetailsData
  ): OrderDetailsData {
    const {
      cortexApi: { scope }
    } = Config;

    if (scope === "motili") {
      const motiliData = detailsData as MotiliOrderDetailsData;
      const statusList = OrderStatusList();

      const branchData: MotiliBranchDetails | {} =
        "vendorDistributionCenter" in motiliData
          ? motiliData.vendorDistributionCenter
          : {};

      const motiliOrderData = {
        branchNumber:
          "vendorBranchNumber" in branchData
            ? branchData.vendorBranchNumber
            : "",
        deliveryBranchAddress:
          "rawAddress" in branchData ? branchData.rawAddress : "",
        deliveryBranchEmail:
          "vendorBranchContactEmail" in branchData
            ? branchData.vendorBranchContactEmail
            : "",
        shipFromBranchNumber: undefined,
        orderPlacedDate: isoToSimpleDate(motiliData.orderedOn),
        promisedShipDate: isoToSimpleDate(motiliData.estFulfillmentDate),
        shipDate: isoToSimpleDate(motiliData.actualFulfillmentDate),
        details: motiliData.lineItems.map(
          (item: MotiliOrderLineItem): OrderLineItem => ({
            quantityOrdered: item.quantity,
            sku: item.display,
            netPrice: undefined,
            linePrice: undefined,
            quantityShipped: undefined,
            quantityBackOrdered: undefined,
            description: item.details
          })
        ),
        customerNumber: motiliData.contractorId,
        orderNumber: motiliData.extReferenceNumber1,
        purchaseOrderNumber: motiliData.extPoNumber,
        orderMethodOfShipment: motiliData.fulfillmentTypeId,
        orderByName: motiliData.fulfillmentTo,
        trackingNo:
          motiliData.trackingNumber ||
          motiliData.trackingUrl ||
          motiliData.trackingCompany,
        orderStatusLookup: {
          ecommDescription: statusList[motiliData.statusId]
        },
        pickStatusLookup: { ecommDescription: motiliData.eCommPickedStatus },
        // If motiliData.fulfillmentToStdAddress exists, it will include the full address. Otherwise, will use separate properties.
        ordersShippingAddress1:
          motiliData.fulfillmentToStdAddress ||
          motiliData.fulfillmentToStdDeliveryLine,
        ordersShippingAddress2: motiliData.fulfillmentToStdAddress
          ? undefined
          : motiliData.fulfillmentToStdLastLine,
        shippingCity: motiliData.fulfillmentToStdAddress
          ? undefined
          : motiliData.fulfillmentToStdCity,
        shippingState: motiliData.fulfillmentToStdCountry,
        mainShippingZipCode: motiliData.fulfillmentToStdAddress
          ? undefined
          : motiliData.fulfillmentToStdPostalCode,
        shipCode: undefined,
        shipCodeDesc: undefined,
        shipmentStatusLookup: undefined,
        subTotalAmount: undefined,
        otherChargesTotalAmt: undefined,
        orderTaxAmount: undefined,
        gstTaxAmount: undefined,
        totalInvoiceAmount: undefined
      };

      return motiliOrderData;
    }

    return detailsData as OrderDetailsData;
  }

  fetchOrderDetails(orderNumber) {
    const {
      auth: { logout },
      branches: { findBranch, airPurifierBranch },
      history
    } = this.props;
    const {
      account: {
        accountDetails: { customerNumber }
      }
    } = this.context;

    getOrderHistoryDetailsGQL(customerNumber, [orderNumber])
      .then(res => {
        const order: OrderDetailsData | {} =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.customer &&
          res.data.data.customer.orders
            ? this.formatOrderDetails(res.data.data.customer.orders[0])
            : {};

        const purchaseDetails: any = { ...order };
        // PGL-364: fix delivery branch info bug
        let deliveryBranch; // initialized as undefined
        if ("branchNumber" in purchaseDetails) {
          deliveryBranch =
            purchaseDetails.branchNumber === "300"
              ? airPurifierBranch
              : findBranch(purchaseDetails.branchNumber);
        }
        if (deliveryBranch) {
          purchaseDetails.deliveryBranchAddress =
            deliveryBranch.formattedAddress;
          purchaseDetails.deliveryBranchEmail = deliveryBranch.email;
        }

        const createdAtDateLocale: string =
          "orderPlacedDate" in order
            ? simpleToLocaleDate(order.orderPlacedDate)
            : "";
        const promisedShipDateLocale: string =
          "promisedShipDate" in order
            ? simpleToLocaleDate(order.promisedShipDate)
            : "";
        const shipDateLocale: string =
          "shipDate" in order ? simpleToLocaleDate(order.shipDate) : "";
        const invoiceDate: string =
          "invoiceDate" in order ? simpleToLocaleDate(order.invoiceDate) : "";

        purchaseDetails.createdAt = createdAtDateLocale;
        purchaseDetails.promisedShipDate = promisedShipDateLocale;
        purchaseDetails.shipDate = shipDateLocale;
        purchaseDetails.invoiceDate = invoiceDate;

        if (this._isMounted) {
          this.setState({ purchaseDetails });
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getOrderHistory => OrderHistoryDetailsPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "getOrderHistory => OrderHistoryDetailsPage.tsx"
          });
        }
      });
  }

  renderOrderItems(orderItems) {
    const { loadingPickupOrDelivery, isProductBeingAdded } = this.state;
    const {
      pageConfig: { showSerialNumber }
    } = this.props;

    return (
      orderItems &&
      orderItems.map((orderItem: any, i) => {
        const key = orderItem.sku + i;
        return (
          <OrderItemDetails
            key={key}
            details={orderItem}
            noOp={noOp}
            isProductBeingAdded={isProductBeingAdded}
            loadingPickupOrDelivery={loadingPickupOrDelivery}
            onAddToCart={this.onAddToCart}
            showSerialNumber={showSerialNumber}
          />
        );
      })
    );
  }

  formatBranchNumber(shippingMethod, skuNumber) {
    const orderFulfillmentMetod = this.isShipmentPickupOrDelivery();
    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber },
        branchesList
      },
      cart: {
        cartDetails: {
          defaultCart: { selectedBranch }
        }
      }
    } = this.context;
    const deliveryBranchNumber = isSpecialAirPurifier(skuNumber)
      ? airPurifierBranchNumber
      : selectedBranch && selectedBranch.code;

    if (shippingMethod === orderFulfillmentMetod) {
      if (shippingMethod === "pickup") {
        const {
          purchaseDetails: { branchNumber: orderBranchNumber }
        } = this.state;
        const selectedOrderBranch = branchesList.find(
          branch => branch.branchNumber === orderBranchNumber
        );
        const returnedBranch = selectedOrderBranch
          ? orderBranchNumber
          : selectedBranch && selectedBranch.code;
        return returnedBranch;
      }
      if (shippingMethod === "delivery") {
        return deliveryBranchNumber;
      }
    } else if (orderFulfillmentMetod === "pickup") {
      if (shippingMethod === "delivery") {
        return deliveryBranchNumber;
      }
    } else if (orderFulfillmentMetod === "delivery") {
      if (shippingMethod === "pickup") {
        return selectedBranch && selectedBranch.code;
      }
    }
    return null;
  }

  onAddToCart(shippingMethod: "pickup" | "delivery", skuNumber?: string) {
    const {
      auth: { logout },
      history
    } = this.props;

    const {
      purchaseDetails: { details }
    } = this.state;

    const {
      cart: {
        cartDetails: {
          defaultCart: { addItemsToCart }
        },
        getCartDetails,
        setSuccesCartPopupMessage
      }
    } = this.context;

    let orderProducts;

    if (this._isMounted) {
      if (skuNumber) {
        this.setState({
          isProductBeingAdded: { sku: skuNumber, shippingMethod }
        });

        orderProducts = details
          .filter(item => item.sku === skuNumber)
          .map((item: any) => {
            const formatedBranchNumber = this.formatBranchNumber(
              shippingMethod,
              skuNumber
            );
            return {
              code: item.sku,
              quantity: item.quantityOrdered,
              price: item.netPrice,
              name: item.description,
              "branch-number": formatedBranchNumber,
              "shipping-method": shippingMethod
            };
          });
      } else {
        this.setState({
          loadingPickupOrDelivery: shippingMethod
        });
        orderProducts = details
          .filter(item => item.sku !== "")
          .map((item: any) => {
            const formatedBranchNumber = this.formatBranchNumber(
              shippingMethod,
              item.sku
            );
            return {
              code: item.sku,
              quantity: item.quantityOrdered,
              price: item.netPrice,
              name: item.description,
              "branch-number": formatedBranchNumber,
              "shipping-method": shippingMethod
            };
          });
      }

      const itemQuantity = orderProducts.reduce(
        (total, current) => total + Number(current.quantity),
        0
      );

      addToCart(addItemsToCart.self.uri, { items: orderProducts })
        .then(res => {
          orderProducts.forEach(item => {
            // sends information to Segment for every product user adds
            productAdded(
              item.name,
              item.id,
              item.price,
              "",
              "",
              Number(item.quantity)
            );
          });
        })
        .then(() => {
          return getCartDetails();
        })
        .then(() => {
          setSuccesCartPopupMessage(itemQuantity);
          if (skuNumber) {
            this.setState({
              isProductBeingAdded: { sku: "", shippingMethod: "" }
            });
          } else {
            this.setState({
              loadingPickupOrDelivery: ""
            });
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => addToCart => OrderHistoryDetailsPage.tsx"
              })
            );
          } else if (this._isMounted) {
            this.setState({
              resultMessage: {
                debugMessages:
                  generateSpecificErrorMessage(e) ||
                  intl.get("custom-error-order-history-details"),
                id:
                  generateSpecificErrorMessage(e) ||
                  intl.get("custom-error-order-history-details"),
                type: "error"
              }
            });
            if (skuNumber) {
              this.setState({
                isProductBeingAdded: { sku: "", shippingMethod: "" }
              });
            } else {
              this.setState({
                loadingPickupOrDelivery: ""
              });
            }
          }
        });
    }
  }

  closeMessageContainer() {
    if (this._isMounted) {
      this.setState({
        resultMessage: emptyMessage
      });
    }
  }

  onPrintInvoiceClicked() {
    const {
      purchaseDetails: { orderNumber }
    } = this.state;
    const {
      auth: { logout },
      history
    } = this.props;
    const {
      account: {
        accountDetails: { customerNumber }
      }
    } = this.context;
    // const orderNumber = "S540187";

    this.setState({ noInvoiceError: "", isInvoiceBeingOpened: true });

    getInvoiceReportGQL(customerNumber, orderNumber)
      .then(invoice => {
        this.setState({ isInvoiceBeingOpened: false });
        if (invoice.data.errors) {
          this.setState({
            noInvoiceError: intl.get("invoice-error")
          });
        } else {
          openInvoiceReport(invoice, orderNumber);
        }
      })
      .catch(e => {
        if (e.response && e.response.status === 400) {
          this.setState({
            noInvoiceError: intl.get("invoice-error"),
            isInvoiceBeingOpened: false
          });
        } else if (checkTokensExpired(e)) {
          this._isMounted = false;
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => getInvoiceReport => OrderHistoryDetailsPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "getInvoiceReport => OrderHistoryDetailsPage.tsx"
          });
        }
      });
  }

  renderOrderDetailsButtons(
    loadingPickupOrDelivery: string,
    noInvoiceError: string,
    topButtons: boolean = false,
    isEcommerceBuyer: boolean
  ) {
    const {
      purchaseDetails: { details, orderStatusLookup },
      isProductBeingAdded,
      isInvoiceBeingOpened
    } = this.state;

    const hasSpecialAirPurifiers =
      details && Boolean(details.find(item => isSpecialAirPurifier(item.sku)));

    const isLoadingOrderForPickup = loadingPickupOrDelivery === "pickup";

    const isLoadingOrderForDelivery = loadingPickupOrDelivery === "delivery";

    const isDisabledAddOrderForPickup =
      hasSpecialAirPurifiers ||
      !isEcommerceBuyer ||
      isLoadingOrderForDelivery ||
      (isProductBeingAdded && isProductBeingAdded.sku !== "");

    const isDisabledAddOrderForDelivery =
      isLoadingOrderForPickup ||
      !isEcommerceBuyer ||
      (isProductBeingAdded && isProductBeingAdded.sku !== "");

    const shouldShowInvoiceButton =
      Config.showPrintInvoice &&
      orderStatusLookup.ecommDescription === intl.get("invoiced") &&
      !isInvoiceBeingOpened;
    let invoiceContent = null;

    if (shouldShowInvoiceButton) {
      invoiceContent = (
        <button
          type="button"
          className="dast-btn dast-btn-secondary"
          onClick={this.onPrintInvoiceClicked}
          aria-label={intl.get("print-invoice")}
        >
          {intl.get("print-invoice").toUpperCase()}
        </button>
      );
    } else if (isInvoiceBeingOpened) {
      invoiceContent = <div className="miniLoader" />;
    }

    const buttons = (
      <>
        <div
          className={`order-details-buttons ${topButtons ? "top-buttons" : ""}`}
        >
          <div className="print-invoice-wrapper">{invoiceContent}</div>
          <div className="pickup-delivery-buttons-container">
            <div className="pickup-delivery-buttons add-order">
              {!isLoadingOrderForPickup ? (
                <button
                  className="dast-btn dast-btn-primary"
                  aria-label={intl.get("add-all-as-pick-up")}
                  type="button"
                  disabled={isDisabledAddOrderForPickup}
                  onClick={() => {
                    this.onAddToCart("pickup", details.sku);
                  }}
                >
                  {isEcommerceBuyer
                    ? intl.get("add-all-as-pick-up")
                    : intl.get("contact-admin")}
                </button>
              ) : (
                <div className="miniLoader" />
              )}
              {!isLoadingOrderForDelivery ? (
                <button
                  className="dast-btn dast-btn-primary"
                  aria-label={intl.get("add-all-as-delivery")}
                  type="button"
                  disabled={isDisabledAddOrderForDelivery}
                  onClick={() => {
                    this.onAddToCart("delivery", details.sku);
                  }}
                >
                  {isEcommerceBuyer
                    ? intl.get("add-all-as-delivery")
                    : intl.get("contact-admin")}
                </button>
              ) : (
                <div className="miniLoader" />
              )}
            </div>
          </div>
          <p className="invoice-error">{noInvoiceError}</p>
        </div>
      </>
    );

    return buttons;
  }

  renderPaymentCardDetails(): any {
    const {
      purchaseDetails: { submittedOrder }
    } = this.state;
    const { payment } = submittedOrder;

    let paymentCardDetailsElement = null;
    if (payment) {
      try {
        const { authNumber, cardType, referenceNumber } = payment;
        paymentCardDetailsElement = (
          <div>
            <span className="label">{intl.get("auth-number")}:</span>
            <p>
              <span>{formatNullString(authNumber)}</span>
            </p>
            <span className="label">{intl.get("payment-card-type")}:</span>
            <p>
              <span>{formatNullString(cardType)}</span>
            </p>
            <span className="label">{intl.get("reference-number")}:</span>
            <p>
              <span>{formatNullString(referenceNumber)}</span>
            </p>
          </div>
        );
      } catch (error) {
        console.error(
          "renderPaymentCardDetails => OrderHistoryDetais.tsx ",
          error
        );
      }
    }

    return paymentCardDetailsElement;
  }

  isShipmentPickupOrDelivery() {
    const { purchaseDetails } = this.state;

    const methodOfShipment = MethodOfShipment()[
      purchaseDetails.orderMethodOfShipment
    ];

    return methodOfShipment === intl.get("pick-up-method") ||
      methodOfShipment === intl.get("pickup") ||
      methodOfShipment === intl.get("direct")
      ? "pickup"
      : "delivery";
  }

  render() {
    const {
      purchaseDetails,
      resultMessage,
      loadingPickupOrDelivery,
      noInvoiceError
    } = this.state;

    const {
      user: { userProfile }
    } = this.context;
    const { ecommerceBuyer } = userProfile;

    const {
      pageConfig: { title, topButtons, showInvoiceDate }
    } = this.props;

    if (purchaseDetails) {
      const paymentType =
        purchaseDetails.submittedOrder.paymentType === PaymentType.CARD
          ? intl.get("credit-card")
          : purchaseDetails.submittedOrder.paymentType;

      return (
        <div className="order-details-container">
          <MessageContainer
            message={resultMessage}
            closeContainerHandler={this.closeMessageContainer}
          />
          {topButtons
            ? this.renderOrderDetailsButtons(
                loadingPickupOrDelivery,
                noInvoiceError,
                topButtons,
                ecommerceBuyer
              )
            : null}
          <div className="container main-order-details">
            <div className="order-and-dates">
              <div className="order-info content-box">
                <h4>{title}</h4>
                <div>
                  <div>
                    <p>
                      <span className="label">
                        {intl.get("customer-number")}:
                      </span>
                      <span>{purchaseDetails.customerNumber}</span>
                    </p>
                    <p>
                      <span className="label">{intl.get("order-number")}:</span>
                      <span>{purchaseDetails.orderNumber}</span>
                    </p>
                    <p>
                      <span className="label">
                        {intl.get("purchase-order")}:
                      </span>
                      <span>{purchaseDetails.purchaseOrderNumber}</span>
                    </p>
                    <p>
                      <span className="label">{intl.get("ship-method")}:</span>
                      <span>
                        {purchaseDetails.shipmentStatusLookup.ecommDescription}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="label">
                        {intl.get("customer-name")}:
                      </span>
                      <span>{purchaseDetails.orderByName}</span>
                    </p>
                    <p>
                      <span className="label">
                        {intl.get("shipping-information")}:
                      </span>
                      <span>
                        {purchaseDetails.shipCode &&
                        purchaseDetails.shipCodeDesc
                          ? `${purchaseDetails.shipCode} ${purchaseDetails.shipCodeDesc}`
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className="label">{intl.get("tracking")}:</span>
                      <span>
                        {purchaseDetails.trackingNo
                          ? purchaseDetails.trackingNo
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className="label">{intl.get("ship-via")}:</span>
                      <span>{purchaseDetails.shipfromBranchNumber}</span>
                    </p>
                    <p>
                      <span className="label">{intl.get("status")}:</span>
                      <span>
                        {purchaseDetails.orderStatusLookup.ecommDescription}
                        {purchaseDetails.orderStatusLookup.ecommDescription &&
                        purchaseDetails.backOrderStatus === "OUT OF STOCK"
                          ? "/"
                          : ""}
                        {purchaseDetails.backOrderStatus === "OUT OF STOCK"
                          ? `${intl.get("back-ordered")}`
                          : ""}
                      </span>
                    </p>
                    <p>
                      <span className="label">{intl.get("pick-status")}:</span>
                      <span>
                        {purchaseDetails.pickStatusLookup.ecommDescription ||
                          null}
                      </span>
                    </p>
                    {Config.orderHistoryShowJob && (
                      <p>
                        <span className="label">{intl.get("job")}:</span>
                        <span>
                          {`${purchaseDetails.jobNumber ||
                            ""} ${purchaseDetails.jobName || ""}`}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="dates-payment-wrapper">
                <div className="dates content-box">
                  <h4>{intl.get("date")}</h4>
                  <div>
                    <span className="label">{intl.get("date-ordered")}:</span>
                    <p>
                      <span>
                        {purchaseDetails.createdAt
                          ? purchaseDetails.createdAt
                          : null}
                      </span>
                    </p>
                    <span className="label">{intl.get("date-promised")}:</span>
                    <p>
                      <span>
                        {purchaseDetails.promisedShipDate
                          ? purchaseDetails.promisedShipDate
                          : null}
                      </span>
                    </p>
                    <span className="label">{intl.get("date-shipped")}:</span>
                    <p>
                      <span>
                        {purchaseDetails.shipDate
                          ? purchaseDetails.shipDate
                          : null}
                      </span>
                    </p>
                    {showInvoiceDate && (
                      <span className="label">
                        {intl.get("date-invoiced")}:
                      </span>
                    )}
                    <p>
                      <span>
                        {showInvoiceDate && purchaseDetails.invoiceDate}
                      </span>
                    </p>
                  </div>
                </div>
                {Config.showOrderHistoryDetailsPayment && (
                  <div className="payment content-box">
                    <h4>{intl.get("payment")}</h4>
                    <div>
                      <span className="label">{intl.get("payment-type")}:</span>
                      <p>
                        <span>{paymentType || ""}</span>
                      </p>

                      {purchaseDetails.submittedOrder.paymentType ===
                        PaymentType.CARD && this.renderPaymentCardDetails()}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="addresses-and-totals">
              <div className="addresses content-box">
                <h4>{intl.get("addresses")}</h4>
                <div>
                  {this.isShipmentPickupOrDelivery() === "delivery" ? (
                    <div className="shipping">
                      <p className="label">{intl.get("ship-to")}:</p>
                      <p>
                        <span>{purchaseDetails.ordersShippingAddress1}</span>
                      </p>
                      <p>
                        <span>{purchaseDetails.ordersShippingAddress2}</span>{" "}
                        {purchaseDetails.shippingCity}{" "}
                        {purchaseDetails.shippingState}{" "}
                        {purchaseDetails.mainShippingZipCode}
                      </p>
                    </div>
                  ) : (
                    <div className="branch">
                      <p className="label">{intl.get("branch")}:</p>#
                      {purchaseDetails.branchNumber}
                      <p>{purchaseDetails.deliveryBranchAddress}</p>
                      <a href={`mailto:${purchaseDetails.deliveryBranchEmail}`}>
                        {purchaseDetails.deliveryBranchEmail}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              {Config.calculatePrice && (
                <div className="totals content-box">
                  <h4>{intl.get("total")}</h4>
                  <div className="totals-table">
                    <div className="totals-row">
                      <span className="label">{intl.get("sub-total")}:</span>
                      <p className="value">
                        {ecommerceBuyer
                          ? `$${purchaseDetails.subTotalAmount}`
                          : intl.get("pending")}
                      </p>
                    </div>
                    <div className="totals-row">
                      <span className="label">
                        {intl.get("delivery-and-handling")}:
                      </span>
                      <p className="value">
                        {ecommerceBuyer
                          ? `$${purchaseDetails.otherChargesTotalAmt || ""}`
                          : intl.get("pending")}
                      </p>
                    </div>
                    <div className="totals-row">
                      <span className="label">
                        {userProfile.isCanadianUser
                          ? intl.get("canada-pst-tax")
                          : intl.get("tax")}
                        :
                      </span>
                      <p className="value">
                        {ecommerceBuyer
                          ? `$${purchaseDetails.orderTaxAmount}`
                          : intl.get("pending")}
                      </p>
                    </div>
                    {userProfile.isCanadianUser ? (
                      <div className="totals-row">
                        <span className="label">
                          {intl.get("gst-tax").toUpperCase()}:
                        </span>
                        <p className="value">
                          {ecommerceBuyer
                            ? `$${purchaseDetails.gstTaxAmount}`
                            : intl.get("pending")}
                        </p>
                      </div>
                    ) : null}
                    <div className="totals-row">
                      <span className="label">{intl.get("total")}:</span>
                      <p className="value">
                        {ecommerceBuyer
                          ? `$${purchaseDetails.totalInvoiceAmount || ""}`
                          : intl.get("pending")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="items-main content-box content-table">
              <h4>{intl.get("items")}</h4>
              <div className="items table-labels">
                <div>
                  <p className="label">{intl.get("quantity-abbr")}</p>
                </div>
                <div>
                  <p className="label">{intl.get("product")}#</p>
                </div>
                <div>
                  <p className="label">{intl.get("description")}</p>
                </div>
                {Config.calculatePrice && (
                  <div>
                    <p className="label">{intl.get("price")}</p>
                  </div>
                )}
                {Config.calculatePrice && (
                  <div>
                    <p className="label">{intl.get("extended-price")}</p>
                  </div>
                )}
                {Config.orderHistoryQTYShipped && (
                  <div>
                    <p className="label">{intl.get("qty-shipped")}</p>
                  </div>
                )}
                {Config.showBackOrderedQuantity && (
                  <div>
                    <p className="label">{intl.get("qty-b-o")}</p>
                  </div>
                )}
                <div>
                  <p className="label">{intl.get("action")}</p>
                </div>
              </div>
              {this.renderOrderItems(purchaseDetails.details)}
            </div>
            {!topButtons
              ? this.renderOrderDetailsButtons(
                  loadingPickupOrDelivery,
                  noInvoiceError,
                  false,
                  ecommerceBuyer
                )
              : null}
          </div>
        </div>
      );
    }
    return (
      <>
        <div style={{ width: "100%" }}>
          <div className="loader" />
        </div>
      </>
    );
  }
}

export default OrderHistoryDetailsPage;
