/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import { cortexFetch, getConfig, WishListMain } from "@zilker/store-components";
import React from "react";
import intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { zoomWishList } from "@zilker/store-components/src/static/zoom";
/* eslint-disable-next-line import/no-useless-path-segments */
import { MainContext } from "../contexts/MainContext";
import "./WishListsPage.less";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "../utils/helpers";

interface WishListsPageProps extends RouteComponentProps, BrProps {
  history: any;
  location: any;
}

interface WishListsPageState {
  wishListData: any;
  isLoading: boolean;
  invalidPermission: boolean;
}

class WishListsPage extends React.Component<
  WishListsPageProps,
  WishListsPageState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {
      wishListData: undefined,
      isLoading: false,
      invalidPermission: false
    };
    this.handleItemConfiguratorAddToCart = this.handleItemConfiguratorAddToCart.bind(
      this
    );
    this.handleItemMoveToCart = this.handleItemMoveToCart.bind(this);
    this.handleItemRemove = this.handleItemRemove.bind(this);
  }

  componentDidMount() {
    this.fetchwishListData();
  }

  handleQuantityChange() {
    this.setState({ isLoading: true });
    this.fetchwishListData();
  }

  fetchwishListData() {
    const { history } = this.props;
    const {
      context: {
        auth: { isLoggedIn, logout }
      }
    } = this;
    if (isLoggedIn) {
      cortexFetch(`/?zoom=${zoomWishList.sort().join()}`)
        .then(res => checkResponse(res))
        .then(res => {
          if (!res._defaultwishlist) {
            this.setState({
              invalidPermission: true
            });
          } else {
            this.setState({
              wishListData: res._defaultwishlist[0],
              isLoading: false
            });
          }
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => fetchwishListData => WishListPage.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "fetchwishListData => WishListPage.tsx"
            });
          }
        });
    }
  }

  checkPermissions() {
    const { invalidPermission, wishListData, isLoading } = this.state;
    const { config } = getConfig();
    if (config.b2b.enable && invalidPermission) {
      return (
        <div className="message-permission">
          <h2>{intl.get("permission-message")}</h2>
        </div>
      );
    }
    return (
      (!wishListData || isLoading) && (
        <div
          data-region="mainWishListRegion"
          className="wish-list-main-container"
          style={{ display: "block" }}
        >
          <div className="loader" />
        </div>
      )
    );
  }

  handleItemConfiguratorAddToCart() {
    const { history } = this.props;
    history.push("/mycart");
  }

  handleItemMoveToCart() {
    const { history } = this.props;
    history.push("/mycart");
  }

  handleItemRemove() {
    const { history, location } = this.props;
    history.push(location.pathname);
  }

  render() {
    const { wishListData, isLoading } = this.state;
    const itemDetailLink = "/itemdetail";

    const { auth } = this.context;
    if (!auth.isLoggedIn) return <Redirect to={{ pathname: "/" }} />;

    return (
      <div className="wish-list-container container">
        <div className="wish-list-container-inner">
          <div
            data-region="wishListTitleRegion"
            className="wish-list-title-container"
            style={{ display: "block" }}
          >
            <div>
              {wishListData && !isLoading && (
                <h1 className="view-title">{intl.get("wishlists")}</h1>
              )}
              {(!wishListData || isLoading) && (
                <h1 className="view-title">{intl.get("wishlists")}</h1>
              )}
            </div>
          </div>
          {wishListData && !isLoading && (
            <div
              data-region="mainWishListRegion"
              className="wish-list-main-container"
              style={{ display: "block" }}
            >
              <WishListMain
                empty={!wishListData._lineitems[0]._element}
                wishListData={wishListData}
                handleQuantityChange={() => {
                  this.handleQuantityChange();
                }}
                onItemConfiguratorAddToCart={
                  this.handleItemConfiguratorAddToCart
                }
                onItemMoveToCart={this.handleItemMoveToCart}
                onItemRemove={this.handleItemRemove}
                itemDetailLink={itemDetailLink}
              />
            </div>
          )}
          <div>{this.checkPermissions()}</div>
        </div>
      </div>
    );
  }
}

export default withRouter<WishListsPageProps, any>(WishListsPage);
