import React from "react";
import "./Input.less";

interface InputProps {
  label: string;
  type: string;
  inputName: string;
  ariaLabel: string;
  inputHandler(any?): any;
  onBlurHandler?(any?): any;
  keyDownHandler?(any?): any;
  value: any;
  errors?: { [key: string]: any };
  required?: boolean;
  placeholder?: string;
  title?: string;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  type,
  inputName,
  inputHandler,
  keyDownHandler,
  ariaLabel,
  value,
  errors,
  required,
  placeholder,
  title,
  disabled,
  onBlurHandler
}) => {
  return (
    <div className="input-component">
      {label && (
        <p className="label" title={title}>
          {required && <span className="star">*</span>}
          {label}
        </p>
      )}
      <input
        type={type}
        name={inputName}
        onChange={inputHandler}
        onKeyDown={keyDownHandler}
        aria-label={ariaLabel}
        value={value}
        title={disabled ? title : ""}
        required={required}
        disabled={disabled}
        className={`${
          errors && errors[inputName] ? "validation-error-border" : null
        } ${disabled ? "disabled" : null}`}
        placeholder={placeholder}
        onBlur={onBlurHandler}
      />
      {errors && errors[inputName] ? (
        <p className="validation-error"> {errors[inputName]}</p>
      ) : null}
    </div>
  );
};

export default Input;
