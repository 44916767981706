import React, { FC, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ProsExitPageProps extends RouteComponentProps {
  match: any;
}

export interface IFrameMessage {
  message: IFrameMessageType;
  redirectUrl: string;
}

export enum IFrameMessageType {
  CLOSE_IFRAME = "CLOSE_IFRAME"
}

const ProsExitPage: FC<ProsExitPageProps> = ({ match }) => {
  useEffect(() => {
    closeIframe();
  }, []);

  function closeIframe() {
    const {
      params: { url }
    } = match;
    const message: IFrameMessage = {
      message: IFrameMessageType.CLOSE_IFRAME,
      redirectUrl: url
    };
    const parentUrl = window.parent.location.href;
    window.parent.postMessage(message, parentUrl);
  }

  return <div className="loader" />;
};

export default withRouter(ProsExitPage);
