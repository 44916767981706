import { httpGetNoToken } from "./HttpService";
import Config from "../ep.config.json";
import { getBRCookie, generateMembershipQuery } from "../utils/helpers";

const { scope } = Config.cortexApi;

function generateLocaleDomain() {
  let result = "";
  const selectedLocale = localStorage.getItem(`${scope}_locale`);
  if (selectedLocale) {
    const domain = selectedLocale.split("-")[0];
    if (domain.toLowerCase() !== "en") {
      result = `_${domain}`;
    }
  }

  return result;
}

function generateBranchQuery(branches: any[]) {
  return "&efq=".concat(
    encodeURIComponent(
      "branches_available".concat(
        ":(",
        branches
          .map(branch => {
            let branchString = branch.branchNumber.toString();
            const { memberBranchNumbers } = branch;
            if (branch.branchType === "VIR" && memberBranchNumbers) {
              branchString = memberBranchNumbers.join('" OR "');
            }
            return "".concat('"', branchString, '"');
          })
          .join(" OR "),
        ")"
      )
    )
  );
}

function generateServiceUrl(type: string, url: string, refUrl: string) {
  const {
    baseUri,
    accountId,
    authKey,
    domainKey,
    requestId,
    requestType
  } = Config.bloomreachSearch.config[type];

  const brUID2 = getBRCookie(); // cookie parameter _br_uid_2

  const localeDomain = generateLocaleDomain();

  return `${baseUri}account_id=${accountId}&auth_key=${authKey}&domain_key=${domainKey}${localeDomain}&request_id=${requestId}&${brUID2}&url=${url}&ref_url=${refUrl}&request_type=${requestType}&view_id=${scope}`;
}

export async function brSearch(
  qParam: string,
  searchType: string,
  start: number = 0,
  rows: number = 5,
  filter: string = "",
  fqParam: string = "",
  urlParam: string,
  urlRef: string = "",
  branches?: any[],
  membership?: any[],
  disableSpellCorrect?: boolean
) {
  const type = "search";
  const userType = generateMembershipQuery(membership);
  const urlUsed = urlParam.concat(userType);
  const url = generateServiceUrl(type, urlUsed, urlRef);
  const {
    fieldList,
    filterByBranchAvailability
  } = Config.bloomreachSearch.config.search;
  const q = qParam ? `q=${qParam}` : "";
  const fq = fqParam ? `fq=${fqParam}` : "";
  const fl = `fl=${fieldList.split(",").join("%2C")}`;

  const submitEfq: boolean = branches && filterByBranchAvailability;
  const efq =
    submitEfq && branches && branches.length
      ? generateBranchQuery(branches)
      : "";
  const spellCorrect = disableSpellCorrect ? "&query.spellcorrect=off" : "";

  let searchUrl = `${url}&search_type=${searchType}&${q}${fq}&${fl}&rows=${rows}&start=${start}${efq}${spellCorrect}`;

  if (filter) {
    searchUrl += `&fq=${filter}`;
  }

  try {
    return await httpGetNoToken(searchUrl);
  } catch (error) {
    return error;
  }
}

export async function brSuggest(
  keyword: string,
  urlParam: string,
  urlRef: string = ""
) {
  const type = "suggest";
  const url = generateServiceUrl(type, urlParam, urlRef);
  const q = `q=${keyword}`;
  const searchUrl = `${url}&${q}`;

  try {
    return await httpGetNoToken(searchUrl);
  } catch (error) {
    return error;
  }
}

export async function brRelatedProducts(
  titleKeyword: string,
  brandKeyword: string,
  productSku: string,
  urlParam: string,
  urlRef: string = "",
  branches?: any[]
) {
  const { fieldList, rows, start } = Config.bloomreachSearch.config.mlt;
  const type = "mlt";
  const url = generateServiceUrl(type, urlParam, urlRef);
  const sku = `pid=${productSku}`;
  const title = `title=${titleKeyword}`;
  const brand = `brand=${brandKeyword}`;
  const fl = `fl=${fieldList.split(",").join("%2C")}`;

  const submitEfq: boolean = branches && true;
  // const efq = submitEfq ? generateBranchQuery(branches) : "";

  // const mltUrl = `${url}&rows=${rows}&start=${start}&${fl}&${sku}&${title}&${brand}${efq}`;
  const mltUrl = `${url}&rows=${rows}&start=${start}&${fl}&${sku}&${title}&${brand}`;

  try {
    return await httpGetNoToken(mltUrl);
  } catch (error) {
    return error;
  }
}
