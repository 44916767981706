/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import intl from "react-intl-universal";
import { AddressContainer } from "@zilker/store-components";
import {
  pushToMaintenace,
  isSpecialAirPurifier
} from "../../../app/src/utils/helpers";
import OrderTableLineItem from "../OrderTableLineItem/ordertable.lineitem";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./ordertable.main.less";

interface OrderTableMainProps {
  data: {
    [key: string]: any;
  };
  itemDetailLink?: string;
  mincronOrderNums?: any;
  itemAvailability: any;
}

const OrderTableMain: React.FunctionComponent<OrderTableMainProps> = (
  props: OrderTableMainProps
) => {
  const { data, itemDetailLink, mincronOrderNums } = props;
  const context = useContext<{
    order: any;
    branches: any;
    cart: any;
    user: any;
  }>(MainContext);
  const {
    branches,
    cart: {
      cartDetails: { defaultCart }
    }
  } = context;
  const { airPurifierBranch } = branches;
  const {
    branchNumber: airPurifierBranchNumber,
    branchName: airPurifierBranchName
  } = airPurifierBranch;
  const history = useHistory();

  const [pickupItems, setPickupItems] = useState([]);
  const [deliveryItems, setDeliveryItems] = useState([]);
  const [airPurifiers, setAirPurifiers] = useState([]);
  const [branchesNums, setBranchesNums] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [mincronPickupOrder, setMincronPickupOrder] = useState([]);
  const [mincronDeliveryOrder, setMincronDeliveryOrder] = useState([]);

  const renderPickupBranchDetails = branch => {
    const { findBranch } = branches;
    const foundBranch = branch && findBranch(branch);
    const fulfillmentBranchDetails = foundBranch;
    const deliveries = data._order[0]._deliveries;
    if (deliveries) {
      return (
        <div className="order-option-container">
          {fulfillmentBranchDetails && (
            <span>{fulfillmentBranchDetails.branchName.toUpperCase()}</span>
          )}
          {fulfillmentBranchDetails && (
            <span>{fulfillmentBranchDetails.formattedAddress}</span>
          )}
          {fulfillmentBranchDetails && (
            <a href={`mailto:${fulfillmentBranchDetails.email}`}>
              {fulfillmentBranchDetails.email}
            </a>
          )}
        </div>
      );
    }
    return null;
  };

  const renderShippingAddress = () => {
    const deliveries = data._order[0]._deliveries;
    if (deliveries) {
      let shippingAddress = null;
      try {
        [
          shippingAddress
        ] = data._order[0]._deliveries[0]._element[0]._destinationinfo[0]._destination;
      } catch (e) {
        console.error(e);
        pushToMaintenace(history, {
          e,
          errIn: "renderShippingAddress => ordertable.main.tsx"
        });
      }

      const { name, address } = shippingAddress;
      return (
        <div className="order-option-container">
          <AddressContainer name={name} address={address} />
        </div>
      );
    }
    return null;
  };

  const renderOrderDetailsSection = (shippingMethod, branch) => {
    if (orderDetails && orderDetails.length) {
      if (shippingMethod === "pickup") {
        const orderByBranch = orderDetails.find(
          branchOrder =>
            branchOrder.customerPickup && branchOrder.branchNumber === branch
        );
        return orderByBranch;
      }
      if (branch === airPurifierBranchNumber) {
        const orderForAirPurifiers = orderDetails.find(
          deliveryOrder =>
            deliveryOrder.customerPickup === false &&
            deliveryOrder.branchNumber === airPurifierBranchNumber
        );
        return orderForAirPurifiers;
      }
      const orderForDelivery = orderDetails.find(
        deliveryOrder => deliveryOrder.customerPickup === false
      );
      return orderForDelivery;
    }
    return null;
  };

  const renderFulfillmentSection = (
    items,
    fulfillmentType,
    fulfillmentMsg,
    orderType,
    branch,
    mincronOrder?
  ) => {
    const { itemAvailability } = props;
    let pickupItemArray;
    let mincronOrderId;
    if (branch) {
      pickupItemArray = items.filter(item => item["branch-number"] === branch);
    }

    if (mincronOrder) {
      const mincronOrderError = mincronOrder.successOrError === "ERROR";
      if (mincronOrderError) {
        console.error(
          intl.get("order-log-failure-message", {
            status: "ERROR"
          })
        );
        mincronOrderId = intl.get("order-log-failure-id");
      } else {
        mincronOrderId = mincronOrder.orderNumber;
      }
    }

    const findBranch = allItems => {
      const foundItem = allItems.find(
        item => item["shipping-method"].toLowerCase() !== "pickup"
      );
      if (!foundItem) {
        return null;
      }
      const branchToUse = foundItem["branch-number"];
      const { branchesList } = branches;
      const foundBranch = branchesList.find(
        branchInList => branchInList.branchNumber === branchToUse
      );
      return branchToUse === airPurifierBranchNumber
        ? airPurifierBranchName
        : foundBranch && foundBranch.branchName;
    };

    const mappedItems = branch ? pickupItemArray : items;
    if (items && items.length && itemAvailability) {
      return (
        <>
          <tr className="addresses-info">
            <td>
              <div className="order-label">{fulfillmentType}</div>
            </td>
            {!fulfillmentType.toLowerCase().includes("pickup") && (
              <td>
                {intl.get("delivery-branch-label")}: {findBranch(items)}
              </td>
            )}
            <td className="fulfillment-info">
              <div>{fulfillmentMsg}</div>
              {data && data._order[0]._deliveries && branch
                ? renderPickupBranchDetails(branch)
                : renderShippingAddress()}
            </td>
          </tr>
          {mincronOrderNums ? (
            <tr className="mincron-order-id">
              <td>
                <p>
                  <label htmlFor="order-number" className="mincron-order-label">
                    {intl.get("order-number")}:
                  </label>
                  <span className="order-number">{mincronOrderId}</span>
                </p>
              </td>
            </tr>
          ) : null}
          {mappedItems.map(fulfillmentItem => {
            // if item is NOT found then no inventory available and entire quantity is backordered
            const itemFound = itemAvailability.find(
              item =>
                item.sku === fulfillmentItem._item[0]._code[0].code &&
                item.branchNumber === fulfillmentItem["branch-number"]
            );
            let backOrderedItem;
            let quantityBackordered;
            // pickup items
            if (branch) {
              backOrderedItem =
                itemFound &&
                itemFound.branchAvailability < fulfillmentItem.quantity;
              quantityBackordered =
                backOrderedItem &&
                fulfillmentItem.quantity - itemFound.branchAvailability;
            }
            // delivery items,
            else {
              // check if delivery item is being picked up from other branches, as pick up will be prioritized
              const skuPickedUp = pickupItems
                .filter(
                  pickupItem =>
                    pickupItem._item[0]._code[0].code ===
                    fulfillmentItem._item[0]._code[0].code
                )
                .map(pickedUpItem => pickedUpItem.quantity)
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                );
              // if region availability is already being picked up in same order, then item quantity is completely backordered
              if (
                skuPickedUp &&
                itemFound &&
                itemFound.regionAvailability <= skuPickedUp
              ) {
                quantityBackordered = fulfillmentItem.quantity;
              }
              // if region availability is more than pick up item (same sku) but not enough to fulfill delivery then remaining quantity is backordered
              else if (
                skuPickedUp &&
                itemFound &&
                itemFound.regionAvailability > skuPickedUp
              ) {
                quantityBackordered =
                  itemFound.regionAvailability - skuPickedUp <
                    fulfillmentItem.quantity &&
                  fulfillmentItem.quantity -
                    (itemFound.regionAvailability - skuPickedUp);
              }
              // if sku is not being picked up, then just checking quantity against region availability
              else if (
                !skuPickedUp &&
                itemFound &&
                itemFound.regionAvailability < fulfillmentItem.quantity
              ) {
                quantityBackordered =
                  fulfillmentItem.quantity - itemFound.regionAvailability;
              }
            }
            if (!quantityBackordered) {
              quantityBackordered = 0;
            }

            return (
              <OrderTableLineItem
                key={`${fulfillmentItem._item[0]._code[0].code}_${fulfillmentType}`}
                item={{
                  ...fulfillmentItem,
                  backOrderedQty: itemFound
                    ? quantityBackordered
                    : fulfillmentItem.quantity
                }}
                itemDetailLink={itemDetailLink}
              />
            );
          })}
          {orderType && (
            <tr className="subtotal-row">
              <td className="subtotal-section">
                <div>
                  <p>
                    <label htmlFor="item-code">
                      {intl.get("todays-subtotal")}:{" "}
                    </label>
                    <span className="item-code">${orderType.subTotal}</span>
                  </p>
                  <p>
                    <label htmlFor="item-code">{intl.get("tax")}: </label>
                    <span className="item-code">${orderType.tax}</span>
                  </p>
                  <p>
                    <label htmlFor="item-code">
                      {intl.get("order-total")}:{" "}
                    </label>
                    <span className="item-code">${orderType.total}</span>
                  </p>
                </div>
              </td>
            </tr>
          )}
        </>
      );
    }
    return null;
  };

  const generateMincronOrderIds = () => {
    if (mincronOrderNums && mincronOrderNums.length) {
      const mincronPickup = mincronOrderNums.filter(
        mincronOrd => mincronOrd.customerWillCall
      );
      const mincronDelivery = mincronOrderNums.filter(
        mincronOrd => mincronOrd.customerWillCall === false
      );

      setMincronPickupOrder(mincronPickup);
      setMincronDeliveryOrder(mincronDelivery);
    }
  };

  useEffect(() => {
    const {
      cart: { getCartDetails }
    } = context;

    if (data && data._lineitems && data._lineitems.length) {
      generateMincronOrderIds();
      const pickupItemsArr = data._lineitems[0]._element.filter(
        item => item["shipping-method"] === "pickup"
      );

      const branchNumbers = pickupItemsArr.map(item => item["branch-number"]);
      const filteredBranches = branchNumbers.filter(
        (value, index, array) => array.indexOf(value) === index
      );

      setBranchesNums(filteredBranches);

      const deliveryItemsArr = data._lineitems[0]._element.filter(item => {
        if (
          !isSpecialAirPurifier(item._item[0]._code[0].code) &&
          item["shipping-method"] === "delivery"
        ) {
          return true;
        }
        return false;
      });

      const specialAirPurifierItemsArr = data._lineitems[0]._element.filter(
        item => {
          if (isSpecialAirPurifier(item._item[0]._code[0].code)) {
            return true;
          }
          return false;
        }
      );
      const deliveryItemsFromSameBranch = deliveryItemsArr.find(
        item =>
          item["branch-number"] === airPurifierBranchNumber &&
          !isSpecialAirPurifier(item._item[0]._code[0].code)
      );
      const combinedDeliveryArr =
        deliveryItemsFromSameBranch &&
        deliveryItemsArr.concat(specialAirPurifierItemsArr);

      const order = defaultCart ? defaultCart.order : null;

      if (
        order &&
        order[0] &&
        order[0]._cartorderdetails &&
        order[0]._cartorderdetails[0] &&
        order[0]._cartorderdetails[0]._cartorderdetailsform &&
        order[0]._cartorderdetails[0]._cartorderdetailsform.length
      ) {
        const shippingPrices =
          order[0]._cartorderdetails[0]._cartorderdetailsform[0][
            "shipping-prices"
          ];
        setOrderDetails(JSON.parse(shippingPrices));

        getCartDetails();
      }
      setPickupItems(pickupItemsArr);
      if (combinedDeliveryArr) {
        setDeliveryItems(combinedDeliveryArr);
      } else {
        setDeliveryItems(deliveryItemsArr);
        setAirPurifiers(specialAirPurifierItemsArr);
      }
    }
  }, []);

  useEffect(() => {
    if (data && data._lineitems && data._lineitems.length) {
      generateMincronOrderIds();
    }
  }, [mincronOrderNums]);

  if (data && data._lineitems && data._lineitems.length && defaultCart) {
    const { selectedBranch } = defaultCart;
    const deliveryOrder = renderOrderDetailsSection("delivery", null);
    const airPurifierOrder = renderOrderDetailsSection(
      "delivery",
      airPurifierBranchNumber
    );
    let mincronDeliveryOrderStatusId;
    if (mincronDeliveryOrder.length) {
      if (mincronDeliveryOrder.length === 1) {
        const [deliveryStatusOrder] = mincronDeliveryOrder;
        mincronDeliveryOrderStatusId = deliveryStatusOrder;
      } else
        mincronDeliveryOrderStatusId = mincronDeliveryOrder.find(
          order => order.branchNumber !== airPurifierBranchNumber
        );
    } else
      mincronDeliveryOrderStatusId = {
        orderNumber: "Pending",
        successOrError: "Pending"
      };

    const mincronDeliveryAirPurifierOrderStatusId = mincronDeliveryOrder.length
      ? mincronDeliveryOrder.find(
          order => order.branchNumber === airPurifierBranchNumber
        )
      : { orderNumber: "Pending", successOrError: "Pending" };
    return (
      <div className="order-main-inner table-responsive">
        <table className="table table-borderless order-table">
          {pickupItems && pickupItems.length ? (
            <>
              <thead>
                <tr>
                  <td>
                    <h3>{intl.get("pick-up-items")}</h3>
                  </td>
                </tr>
              </thead>
              {branchesNums.length &&
                branchesNums.map((branch, index) => {
                  const pickupOrder = renderOrderDetailsSection(
                    "pickup",
                    branch
                  );
                  const pickupFulfillment = `${intl.get("pickup-order-#")}
                  ${index + 1}`;
                  const mincronOrderStatusId = mincronPickupOrder.length
                    ? mincronPickupOrder.find(
                        order => order.branchNumber === branch
                      )
                    : { orderNumber: "Pending", successOrError: "Pending" };
                  return (
                    <tbody key={Math.random()}>
                      {renderFulfillmentSection(
                        pickupItems,
                        pickupFulfillment,
                        intl.get("to-be-picked-up"),
                        pickupOrder,
                        branch,
                        mincronOrderStatusId
                      )}
                    </tbody>
                  );
                })}
            </>
          ) : null}

          {(deliveryItems && deliveryItems.length) ||
          (airPurifiers && airPurifiers.length) ? (
            <>
              <thead>
                <tr>
                  <td>
                    <h3>{intl.get("delivery-items")}</h3>
                  </td>
                </tr>
              </thead>
              <tbody>
                {deliveryItems && deliveryItems.length
                  ? renderFulfillmentSection(
                      deliveryItems,
                      intl.get("delivery-order"),
                      intl.get("to-be-delivered"),
                      deliveryOrder,
                      null,
                      mincronDeliveryOrderStatusId
                    )
                  : null}
                {airPurifiers && airPurifiers.length
                  ? renderFulfillmentSection(
                      airPurifiers,
                      intl.get("other-delivery-orders"),
                      intl.get("to-be-delivered"),
                      airPurifierOrder,
                      null,
                      mincronDeliveryAirPurifierOrderStatusId
                    )
                  : null}
              </tbody>
            </>
          ) : null}
        </table>
      </div>
    );
  }
  return null;
};

export default OrderTableMain;
