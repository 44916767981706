/* eslint-disable class-methods-use-this, no-nested-ternary */

import React, { createRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import {
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { getAssets } from "@elasticpath/ref-store/src/services/connectServices";
import { BreadCrumbsComponent } from "@zilker/store-components";
import ProductRecommendationsDisplayMain from "../ProductRecommendations/productrecommendations.main";
import IndiRecommendationsDisplayMain from "../IndiRecommendations/indirecommendations.main";
import BundleConstituentsDisplayMain from "../BundleConstituents/bundleconstituents.main";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { generateImageUrl } from "../../../app/src/utils/mappings/productDetails";
import AuthProductDetailsComponent from "../AuthProductDetailsComponent/AuthProductDetailsComponent";
import ProductDetailTabsComponent from "../ProductDetailTabsComponent/ProductDetailTabsComponent";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { cortexFetchItemLookupForm, itemLookup } from "../utils/CortexLookup";
import { DebugMessage } from "../MessageContainer/messagecontainer";
import { productViewed } from "../utils/Segment";
import { ReactComponent as WarningIcon } from "../../../app/src/images/icons/warning.svg";

// Types
import {
  ProductDisplayItemMainProps,
  ProductDisplayItemMainState,
  ItemDescription
} from "./productdisplayitem.interfaces";

import "./productdisplayitem.main.less";
import AppHeaderLoginMain from "../AppHeaderLogin/appheaderlogin.main";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

class ProductDisplayItemMain extends React.Component<
  ProductDisplayItemMainProps,
  ProductDisplayItemMainState
> {
  _isMounted = false;

  titleRef = createRef<HTMLDivElement>();

  static contextType = MainContext;

  private productDetailsTabsRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    const epConfig = getConfig();
    Config = epConfig.config;

    ({ intl } = epConfig);

    this.state = {
      productData: undefined,
      arFileExists: false,
      crumbs: [],
      itemPriceDetails: null,
      responseMessage: { type: "", debugMessages: "" },
      prop65required: false,
      productDocuments: [],
      imgMissingHorizontal: Config.missingImagePlaceholderUrl
    };

    this.productDetailsTabsRef = React.createRef();

    this.renderProductImage = this.renderProductImage.bind(this);
    this.urlExists = this.urlExists.bind(this);
    this.extractProductDetails = this.extractProductDetails.bind(this);
    this.handleException = this.handleException.bind(this);
    this.generateProductDetails = this.generateProductDetails.bind(this);
    this.fetchProductData = this.fetchProductData.bind(this);
    this.populatePrice = this.populatePrice.bind(this);
    this.handlePriceCatalogException = this.handlePriceCatalogException.bind(
      this
    );
    this.onDocumentsLinkClick = this.onDocumentsLinkClick.bind(this);
    this.fetchProductDocuments = this.fetchProductDocuments.bind(this);
  }

  componentDidMount() {
    const {
      productId,
      location: { state }
    } = this.props;

    this._isMounted = true;

    this.fetchProductData(productId);
    if (Config.showDocumentsTab)
      this.fetchProductDocuments(encodeURIComponent(productId));

    sessionStorage.setItem("sku", productId);

    // Save data to session storage if we navigated from PLP/Search result page
    // so we can go back to the last search results
    if (state && state.searchResultParams) {
      sessionStorage.setItem(
        "searchResultParams",
        JSON.stringify(state.searchResultParams, (key, value) =>
          value instanceof Set ? Array.from(value) : value
        )
      );
    }

    // Save data to session storage if we navigated from Parts Finder page
    // so we can go back to the last search results
    if (state && state.partsFinderSearchParams) {
      sessionStorage.setItem(
        "partsFinderSearchParams",
        JSON.stringify(state.partsFinderSearchParams)
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { productId } = this.props;

    if (prevProps.productId !== productId) {
      this.fetchProductData(productId);
      if (Config.showDocumentsTab)
        this.fetchProductDocuments(encodeURIComponent(productId));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  capitalizeWord(word) {
    return [...word]
      .map((letter, i) => {
        if (i === 0) return letter.toUpperCase();
        return letter;
      })
      .join("");
  }

  /**
   * ## fetchProductData
   * @param productId string
   *
   * @description Fetches data from the EP and sets the breadcrumbs.
   * If the device supports augmented reality (AR), set the flag in the state.
   */
  fetchProductData(productId: string) {
    const { history, location } = this.props;
    const {
      context: {
        auth: { isLoggedIn }
      }
    } = this;

    const { pathname } = history.location;

    if (productId && pathname.includes("itemdetail")) {
      cortexFetchItemLookupForm()
        .then(() => itemLookup(productId, false))
        .then((res: any) => {
          if (
            Config.arKit.enable &&
            document.createElement("a").relList.supports("ar")
          ) {
            const crumbs = [
              {
                param: "",
                name: res._definition[0].details[0]["display-value"]
              },
              {
                param: "",
                name: res._definition[0].details[1]["display-value"]
              },
              {
                param: "",
                name: res._definition[0].details[3]["display-value"]
              }
            ];
            this.urlExists(
              Config.arKit.skuArImagesUrl.replace("%sku%", productId),
              exists => {
                if (this._isMounted) {
                  this.setState({
                    productData: res,
                    arFileExists: exists,
                    crumbs
                  });
                }
              }
            );
          } else if (
            this._isMounted &&
            res._definition &&
            res._definition[0].details.length > 0 &&
            res._parentcategory &&
            res._parentcategory[0]
          ) {
            const { scope } = Config.cortexApi;
            const scopeUpper = this.capitalizeWord(scope);
            // NOTE: regex to remove special characters from category name,
            // since it's being used to create category (crumbs) url
            const categoryNameRegex = new RegExp(/[\s-/]/g);

            let categoryNameUrl = "";
            let categoryName = "";
            let name = "";
            let id = "";
            let brand = "";
            let prop65required;

            try {
              categoryName = res._parentcategory[0].name;
              categoryNameUrl = `${scopeUpper}${categoryName.replace(
                categoryNameRegex,
                ""
              )}`;
              name = res._definition[0]["display-name"];
              id = res._code[0].code;
              brand = res._definition[0].details.find(
                detail => detail.name === "brand"
              ).value;

              prop65required = res._definition[0].details.find(
                detail => detail.name.toLowerCase() === "prop_65_label_required"
              );

              if (!isLoggedIn) {
                // sends information to Segment when user views on a product
                productViewed(name, id, null, brand, categoryName);
              }
            } catch (error) {
              this.handleException(error);
            }

            const crumbs = [
              { param: "home", name: "Home" },
              {
                param: `category/${categoryNameUrl}`,
                name: categoryName,
                state: true,
                start:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.start,
                sku: id,
                facets:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.facets,
                itemsLoaded:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.itemsLoaded,
                sortByAvailability:
                  location.state &&
                  location.state.searchResultParams &&
                  location.state.searchResultParams.sortByAvailability
              },
              {
                param: "nocrumb",
                name
              }
            ];

            if (this._isMounted) {
              this.setState({
                productData: res,
                crumbs,
                prop65required:
                  prop65required && prop65required.value === "TRUE"
              });
            }
          } else if (this._isMounted) {
            this.setState({
              productData: res,
              crumbs: []
            });
          }
        })
        .catch(err => {
          this.handleException(err);
        });
    }
  }

  /**
   * ## fetchProductDocuments
   * @param productId string
   *
   * @description Fetches products documents from DCS
   */
  async fetchProductDocuments(productId: string) {
    try {
      const { data } = await getAssets(productId);

      const documents =
        data && data.result && data.result.length
          ? data.result[0].assets.map(asset => ({
              ...asset,
              assetType: asset.assetType.replace(/([A-Z])/g, " $1")
            }))
          : null;

      this.setState({
        productDocuments: documents
      });
    } catch (e) {
      console.error(e);
      this.setState({ productDocuments: null });
    }
  }

  /**
   * ## urlExists
   * @param url string
   * @param onSuccess (...args) => any
   *
   * @description Requests the headers that are returned if the specified resource
   * would be requested with an HTTP GET method.
   */
  urlExists(url: string, onSuccess: (...args) => any) {
    fetch(url, {
      method: "HEAD"
    })
      .then(res => {
        onSuccess((res as Response).ok);
      })
      .catch(err => {
        this.handleException(err);
      });
  }

  /**
   * ## handleException
   *
   * @description Logs the error message if it exists. Sets the generic error message
   * to component's state. Redirects the user to /maintenance page.
   */
  handleException(e: any = undefined): void {
    const { history } = this.props;
    const {
      context: {
        auth: { logout }
      }
    } = this;

    if (checkTokensExpired(e)) {
      this._isMounted = false;
      logout().catch(err =>
        pushToMaintenace(history, {
          e: err,
          errIn: "Logout => handleException => productdisplayidem.main.tsx"
        })
      );
    } else if (this._isMounted) {
      this.setState({
        responseMessage: {
          debugMessages: intl.get("service-error"),
          type: "error"
        }
      });
      pushToMaintenace(history, {
        e,
        errIn: "handleException => productdisplayidem.tsx"
      });
    }
  }

  /**
   * ## extractProductDetails
   * @param productData any
   * @description Extracts image URL, description and title from product data from EP.
   */
  extractProductDetails(
    productData: any
  ): {
    productImage: string;
    productDescriptionValue: string;
    productTitle: string;
  } {
    const productTitle = productData._definition[0]["display-name"];
    const productDescription = productData._definition[0].details
      ? productData._definition[0].details.find(
          (item: ItemDescription) =>
            item["display-name"] === "Summary" ||
            item["display-name"] === "Description"
        )
      : "";
    const productDescriptionValue = productDescription["display-value"] || "";

    const productImage = generateImageUrl(productData._definition[0].details);

    return {
      productImage,
      productDescriptionValue,
      productTitle
    };
  }

  /**
   * ## populatePrice
   *
   * @param data any - response payload
   *
   * @description Method that takes the product pricing details and
   * populates it to the component's state based on the successful response status code.
   */
  populatePrice(data: any): void {
    const responseMessage: DebugMessage = {
      debugMessages: intl.get("pdp-price-success"),
      type: "basic"
    };
    const {
      items: [itemPriceDetails]
    } = data;

    const { productData } = this.state;

    const categoryName = productData._parentcategory[0].name;
    const name = productData._definition[0]["display-name"];
    const id = productData._code[0].code;
    const brand = productData._definition[0].details.find(
      detail => detail.name === "brand"
    ).value;
    const price = itemPriceDetails.total;

    // sends information to Segment when user views a product
    productViewed(name, id, price, brand, categoryName);

    if (this._isMounted) {
      this.setState({
        itemPriceDetails,
        responseMessage
      });
    }
  }

  /**
   * ## renderLongDescription
   * @description Renders product description below the Product SKU.
   */
  renderLongDescription() {
    const { productData, prop65required } = this.state;
    if (productData._definition && productData._definition[0].details) {
      const desc = productData._definition[0].details.find(
        (item: ItemDescription) => item.name === "long_description"
      );

      return (
        <>
          <h4 className="override-h2-as-h4">
            {`${intl.get("product-description")}`}
          </h4>
          <span className="itemdetail-attribute-value-col">
            {desc && desc["display-value"]}
          </span>
          {prop65required && (
            <div className="prop-65-warning">
              <WarningIcon className="prop-65-warning-icon" />
              <span>{`${intl.get("prop-65-warning")}. `}</span>
              <a
                href="https://partnerlinkmarketing.goodmanmfg.com/goodman/service-technical-tools"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.get("prop-65-learn-more")}
              </a>
            </div>
          )}
        </>
      );
    }
    return null;
  }

  /**
   * ## renderProductImage
   * @description Renders image with condition for ARKIT.
   */
  // eslint-disable-next-line consistent-return
  renderProductImage() {
    const { productData, arFileExists, imgMissingHorizontal } = this.state;

    const imageUrl = productData._definition
      ? generateImageUrl(productData._definition[0].details, "full_image")
      : imgMissingHorizontal;

    const imageAlt = productData._definition
      ? productData._definition[0]["display-name"]
      : "";

    if (arFileExists) {
      return (
        <a href={imageUrl} rel="ar">
          <img
            src={imageUrl}
            onError={e => {
              const element: any = e.target;
              element.src = imgMissingHorizontal;
            }}
            alt={imageAlt || intl.get("none-available")}
            className="itemdetail-main-img"
          />
        </a>
      );
    }
    return (
      <div>
        <img
          src={imageUrl}
          onError={e => {
            const element: any = e.target;
            element.src = imgMissingHorizontal;
          }}
          alt={imageAlt || intl.get("none-available")}
          className="itemdetail-main-img"
        />
      </div>
    );
  }

  /**
   * ## generateProductDetails
   * @description Extracts title, brand and SKU from product data.
   */
  generateProductDetails(): { title: string; brand: string; sku: string } {
    const { productData } = this.state;
    const { productId } = this.props;
    const result = {
      title: "",
      brand: "",
      sku: productId
    };
    if (productData) {
      const productDetails = productData._definition
        ? productData._definition[0].details
        : [];
      if (productDetails.length) {
        const productTitle = productData._definition
          ? productData._definition[0]["display-name"]
          : "";

        const productBrand = productDetails.find(
          (detail: ItemDescription) => detail.name === "brand"
        );

        if (productTitle && productBrand) {
          result.title = productTitle;
          result.brand = productBrand.value;
        }
      }
    }

    return result;
  }

  handlePriceCatalogException() {
    if (this._isMounted) {
      this.setState(prevState => {
        return {
          ...prevState,
          itemPriceDetails: {
            ...prevState.itemPriceDetails,
            total: intl.get("pending")
          }
        };
      });
    }
  }

  onDocumentsLinkClick() {
    this.productDetailsTabsRef.current.scrollIntoView({ behavior: "smooth" });
  }

  renderPDPLayout(component) {
    const { productData, productDocuments } = this.state;

    switch (component) {
      case "recommended-products":
        return (
          <ProductRecommendationsDisplayMain
            productDetails={this.generateProductDetails()}
            titleRef={this.titleRef}
            key="recommended-products"
            productDataDetails={productData._definition[0].details}
          />
        );
      case "tabs":
        return (
          <ProductDetailTabsComponent
            productData={productData}
            productDocuments={productDocuments}
            key="tabs"
            ref={this.productDetailsTabsRef}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      productData,
      crumbs,
      responseMessage,
      itemPriceDetails,
      productDocuments
    } = this.state;

    const {
      itemDetailLink,
      history,
      location: { state, pathname }
    } = this.props;

    const {
      auth: { isLoggedIn },
      account: {
        accountDetails: { customerNumber }
      },
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    const {
      pageMetadata: {
        title: pageTitle,
        description: metaDescription,
        canonicalURL
      }
    } = Config;

    const appHeaderLoginLinks = {
      profile: "/myAccount/profile",
      wishlists: "/wishlists"
    };

    const appModalLoginLinks = {
      registration: "/registration"
    };

    if (productData && productData._definition && productData._code) {
      // Set the language-specific configuration for indi integration
      Config.indi.productReview.title = intl.get("indi-product-review-title");
      Config.indi.productReview.description = intl.get(
        "indi-product-review-description"
      );
      Config.indi.productReview.submit_button_text = intl.get(
        "indi-product-review-submit-button-text"
      );

      const productSku =
        productData &&
        productData._code &&
        productData._code.length &&
        productData._code[0].code;

      const hasUserProfileLoaded = !!(
        defaultCart &&
        defaultCart.selectedBranch &&
        customerNumber
      );

      let desc = null;

      if (productData._definition[0].details) {
        desc = productData._definition[0].details.find(
          (item: ItemDescription) => item.name === "long_description"
        );
      }

      return (
        <HelmetProvider>
          <div className="itemdetail-component container" ref={this.titleRef}>
            <Helmet>
              <title>{(state && state.name) || pageTitle}</title>
              <meta
                name="description"
                content={
                  (desc && desc["display-value"]) ||
                  (state && state.name) ||
                  metaDescription
                }
              />
              <link rel="canonical" href={`${canonicalURL}${pathname}`} />
            </Helmet>
            <div>
              <BreadCrumbsComponent breadCrumbsMap={crumbs} />
              <div className="itemdetail-assets">
                <div
                  data-region="itemDetailAssetRegion"
                  style={{ display: "block" }}
                >
                  <div className="itemdetail-asset-container">
                    {this.renderProductImage()}
                  </div>
                </div>
              </div>

              <div className="itemdetail-details">
                <div
                  data-region="itemDetailTitleRegion"
                  style={{ display: "block" }}
                >
                  <div>
                    <h1
                      className="itemdetail-title"
                      id={`category_item_title_${productSku}`}
                    >
                      {productData._definition &&
                        productData._definition[0]["display-name"]}
                    </h1>
                    <div
                      className="itemdetail-title-sku"
                      id={`category_item_sku_${productSku}`}
                    >
                      <span className="label">{intl.get("product")} #:</span>
                      <span className="product-sku">{productSku}</span>
                    </div>
                  </div>
                </div>

                {isLoggedIn &&
                  Config.calculatePrice &&
                  (itemPriceDetails ? (
                    <div className="product-details-page-price">
                      {itemPriceDetails.total &&
                      itemPriceDetails.total !== intl.get("pending")
                        ? `$${itemPriceDetails.total}`
                        : intl.get("pending")}
                    </div>
                  ) : (
                    <div className="product-details-page-miniLoader">
                      <div className="miniLoader" />
                    </div>
                  ))}

                {productDocuments && productDocuments.length ? (
                  <button
                    className="documents-tab-link"
                    type="button"
                    onClick={this.onDocumentsLinkClick}
                  >
                    {intl.get("documents-link-text")}
                  </button>
                ) : null}

                <div
                  data-region="itemDetailAvailabilityRegion"
                  style={{ display: "block" }}
                >
                  <ul className="itemdetail-availability-container">
                    <li>{this.renderLongDescription()}</li>
                  </ul>
                </div>
                <div
                  className="itemdetail-addtocart"
                  data-region="itemDetailAddToCartRegion"
                  style={{ display: "block" }}
                >
                  <div>
                    {isLoggedIn ? (
                      hasUserProfileLoaded ? (
                        <AuthProductDetailsComponent
                          encodedProductSku={encodeURIComponent(productSku)}
                          productSku={productSku}
                          productData={productData}
                          itemPriceDetails={itemPriceDetails}
                          handleGenericException={this.handleException}
                          populatePrice={this.populatePrice}
                          history={history}
                          handlePriceCatalogException={
                            this.handlePriceCatalogException
                          }
                        />
                      ) : (
                        <div className="miniLoader" />
                      )
                    ) : (
                      <div className="atc-placeholder-logged-out">
                        {intl.get("anonymous-browse-product-cta1")}
                        &nbsp;
                        <AppHeaderLoginMain
                          history={history}
                          permission
                          appHeaderLoginLinks={appHeaderLoginLinks}
                          appModalLoginLinks={appModalLoginLinks}
                          isLoggedIn={false}
                        />
                        {intl.get("anonymous-browse-product-cta2")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/** TODO: Do we want to have this component here? */}
            <BundleConstituentsDisplayMain
              productData={productData}
              itemDetailLink={itemDetailLink}
            />
            {isLoggedIn ? (
              hasUserProfileLoaded ? (
                Config.pdpComponentsOrder.map(component =>
                  this.renderPDPLayout(component)
                )
              ) : (
                <div className="miniLoader" />
              )
            ) : (
              Config.pdpComponentsOrder.map(component =>
                this.renderPDPLayout(component)
              )
            )}

            {/** TODO: Do we want to have this component here? */}
            <IndiRecommendationsDisplayMain
              render={["carousel", "product"]}
              configuration={Config.indi}
              keywords={productData._code[0].code}
            />
          </div>
        </HelmetProvider>
      );
    }

    return <div className="loader" />;
  }
}

export default ProductDisplayItemMain;
