import React from "react";
import { Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import {
  getAvailability,
  checkEntitlementGQL
} from "@elasticpath/ref-store/src/services/connectGQLservices";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import {
  processHttpResponse,
  handleCustomException,
  generateSpecificErrorMessage,
  updateBranchNumber,
  formatQuoteAvailability
} from "../../../app/src/utils/helpers";
import { addToCart } from "../../../app/src/services/EpServices";
import { getSimilarProductsWidget } from "../../../app/src/services/PathwaysAndRecommendationsService";
import { getProductPrice } from "../../../app/src/utils/mappings/productDetails";
import SearchResultsItem from "../SearchResultsPage/search.results.item";
import { SingleProduct } from "../../../app/src/utils/searchUtils";
import { MainContext } from "../../../app/src/contexts/MainContext";
import MultipleItemsCarousel from "../MultipleItemsCarousel/MultipleItemsCarousel";
import { productAdded, productClicked } from "../utils/Segment";

import "./productrecommendations.main.less";
import { Metadata } from "../../../models/BloomreachPathsAndRecommResponse";
import CarouselArrowButton from "../CarouselArrowbutton/carouselarrowbutton";

// Types

interface RelatedProduct extends SingleProduct {}

interface RelatedProductsResponse {
  numFound: number;
  start: number;
  docs: Array<RelatedProduct>;
}
interface ProductRecommendationsDisplayMainProps extends RouteComponentProps {
  productDetails: { title: string; brand: string; sku: string };
  titleRef?: any;
  history: any;
  productDataDetails: any[];
}

interface ProductRecommendationsDisplayMainState {
  items: Array<RelatedProduct>;
  message: {
    debugMessages: string;
    id: string;
    type: string;
  };
  redirect: boolean;
  productPrices: Array<any>;
  productEntitlements: Array<any>;
  addCartButtonIds: number[];
  productAvailability: any;
  displaySlider: boolean;
  avoidUpdate: boolean;
  metadata: Metadata;
}

let Config: IEpConfig;
declare let BrTrk: any;

/**
 * ## class ProductRecommendationsDisplayMain
 *
 * @description This component is responsible for rendering the "Related products"
 * carousel - component <MultipleItemsCarousel/>.
 */
class ProductRecommendationsDisplayMain extends React.Component<
  ProductRecommendationsDisplayMainProps,
  ProductRecommendationsDisplayMainState
> {
  _isMounted = false;

  static contextType = MainContext;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      items: null,
      message: null,
      redirect: false,
      productPrices: null,
      productEntitlements: null,
      addCartButtonIds: [],
      productAvailability: null,
      displaySlider: false,
      avoidUpdate: false,
      metadata: null
    };

    this.fetchRelatedProducts = this.fetchRelatedProducts.bind(this);
    this.handleError = this.handleError.bind(this);
    this.onAddToCart = this.onAddToCart.bind(this);
    this.fetchProductPrices = this.fetchProductPrices.bind(this);
    this.validateEntitlement = this.validateEntitlement.bind(this);
    this.validateAvailbility = this.validateAvailbility.bind(this);
    this.addPriceAndEntitlementAndAvailability = this.addPriceAndEntitlementAndAvailability.bind(
      this
    );
    this.handleAddToCartError = this.handleAddToCartError.bind(this);
  }

  componentDidMount() {
    const {
      productDetails: { sku }
    } = this.props;
    const {
      branches: { branchesInContext }
    } = this.context;

    this._isMounted = true;
    if (branchesInContext) {
      this.fetchRelatedProducts(sku);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ avoidUpdate: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      productDetails: { sku }
    } = this.props;
    const {
      branches: { branchesInContext }
    } = this.context;
    const { displaySlider, avoidUpdate } = this.state;

    const {
      productDetails: { sku: prevSku }
    } = prevProps;
    if (
      (sku && sku !== "undefined" && sku !== prevSku) ||
      (branchesInContext && !displaySlider && !avoidUpdate)
    ) {
      this.fetchRelatedProducts(sku);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ avoidUpdate: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * ## onAddToCart
   *
   * @param event { target: { id: any }
   *
   * @description Calls Cortex API and processes the response-
   * sets the response message in the component's state.
   */
  onAddToCart(
    event: { target: { id: any } },
    shippingMethod: "pickup" | "delivery",
    item?: any
  ) {
    const { id: pid } = event.target;
    const { addCartButtonIds } = this.state;
    const {
      branches: {
        airPurifierBranch: { branchNumber: airPurifierBranchNumber }
      },
      cart: {
        cartDetails: { defaultCart }
      },
      account: {
        accountDetails: { homeBranch }
      }
    } = this.context;
    const { addItemsToCart, selectedBranch } = defaultCart;

    let activeBranch;
    if (defaultCart) {
      activeBranch = selectedBranch.code;
    }

    const items = [
      {
        code: pid,
        quantity: 1,
        "shipping-method": shippingMethod,
        "branch-number": updateBranchNumber(
          shippingMethod === "delivery",
          pid,
          airPurifierBranchNumber,
          homeBranch,
          activeBranch
        )
      }
    ];

    if (this._isMounted) {
      this.setState({
        addCartButtonIds: addCartButtonIds.concat(pid)
      });
    }

    addToCart(addItemsToCart.self.uri, { items })
      .then(res => {
        processHttpResponse(
          res,
          () => {
            // Update Cart Count in Context.
            const { cart } = this.context;

            cart
              .getCartDetails()
              .then(() => {
                cart.setSuccesCartPopupMessage(1);
              })
              .catch(err => this.handleAddToCartError(pid, err));

            if (this._isMounted) {
              this.setState(prevState => {
                const { addCartButtonIds: buttonIds } = prevState;
                return {
                  message: {
                    debugMessages: intl.get("reorder-success"),
                    id: "",
                    type: "needinfo"
                  },
                  addCartButtonIds: buttonIds.filter(buttonId => {
                    return buttonId !== pid;
                  })
                };
              });
            }
          },
          err => this.handleAddToCartError(pid, err)
        );
      })
      .then(() => {
        if (item) {
          // sends information to Segment when user adds a product
          productAdded(
            item.title,
            item.pid,
            !item.productPrice || item.productPrice === intl.get("pending")
              ? 0
              : item.productPrice,
            item.brand,
            null,
            1
          );
          this.trackWidgetAddToCartEvent(pid);
        }
      })
      .catch(error => this.handleAddToCartError(pid, error));
  }

  /**
   * ## fetchRelatedProducts
   *
   * @param sku string
   *
   * @description Calls BR API and fetches products for "Related products" section.
   * Accepts query parameters for product title and brand.
   */
  fetchRelatedProducts(sku: string): void {
    const { location, productDataDetails } = this.props;
    const { state } = location;
    const {
      account: {
        accountDetails: { membership }
      }
    } = this.context;
    let prev = "";
    if (!(typeof state === "undefined")) {
      if (
        Object.prototype.hasOwnProperty.call(state, "partsFinderSearchParams")
      ) {
        prev = state.partsFinderSearchParams.prevUrl;
      } else if (
        Object.prototype.hasOwnProperty.call(state, "searchResultParams")
      ) {
        prev = state.searchResultParams.prevUrl;
      } else {
        prev = state.prevUrl;
      }
    }
    const productClass = productDataDetails.find(
      detail => detail.name === "class"
    ).value;
    let tonnage = "";

    const productClassesArray = [
      "Split Air Conditioner",
      "Split Heat Pump",
      "Coil",
      "Air Handler"
    ];
    const isClassFiltered = productClassesArray.includes(productClass);

    if (isClassFiltered) {
      tonnage = productDataDetails.find(detail => detail.name === "tonnage")
        .value;
    }
    const prevUrl = prev;

    getSimilarProductsWidget(
      sku,
      window.location.href,
      productClass,
      prevUrl,
      membership,
      tonnage
    ).then(similarProductsResponse => {
      this.processRelatedProducts(similarProductsResponse);
    });
  }

  /**
   * ## processRelatedProducts
   *
   * @param productsResponse RelatedProductsResponse
   * @description Get the list of prices for each product.
   */

  processRelatedProducts(productsResponse): void {
    const {
      branches: { branchesInContext },
      auth: { guestLoggedIn }
    } = this.context;
    const { displaySlider } = this.state;
    const onSuccess = (data: {
      response: RelatedProductsResponse;
      metadata: Metadata;
    }) => {
      const {
        response: { docs },
        metadata
      } = data;

      const items = docs.map(singleProduct => {
        if (singleProduct.full_image) {
          return {
            ...singleProduct,
            thumb_image: singleProduct.full_image
          };
        }
        return singleProduct;
      });

      if (this._isMounted) {
        let newDisplaySliderVal = displaySlider;
        if ((branchesInContext && !displaySlider) || guestLoggedIn) {
          newDisplaySliderVal = true;
        }
        this.setState({ items, displaySlider: newDisplaySliderVal });
      }
      if (Config.calculatePrice && docs.length !== 0)
        this.fetchProductPrices(docs);
      this.validateEntitlement(docs);
      this.validateAvailbility(docs);
      this.setState({
        metadata
      });
      this.trackWidgetViewEvent();
    };
    processHttpResponse(productsResponse, onSuccess, this.handleError);
  }

  /**
   * ## fetchProductPrices
   *
   * @param products Array<RelatedProduct>
   * @description Get the list of prices for each product.
   */
  fetchProductPrices(products: RelatedProduct[]) {
    const { history } = this.props;
    const { auth, cart, job, account } = this.context;
    const { logout } = auth;
    const {
      accountDetails: { customerNumber }
    } = account;

    const {
      cartDetails: { defaultCart }
    } = cart;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const jobNumber = defaultCart ? defaultCart.jobNumber : null;

    if (auth.isLoggedIn && selectedBranch && customerNumber) {
      const priceRequestBody = {
        customerNumber,
        shipments: [
          {
            branchNumber: selectedBranch.code,
            customerPickup: false,
            items: products.map(product => ({
              sku: product.pid,
              quantity: 1
            }))
          }
        ],
        jobNumber
      };
      if (defaultCart.jobNumber || job.persistedJobNumber) {
        priceRequestBody.jobNumber =
          defaultCart.jobNumber || job.persistedJobNumber;
      }

      getProductPrice(
        priceRequestBody,
        res => {
          if (this._isMounted) {
            this.setState({
              productPrices: res.data.quote.shipments[0].items
            });
          }
        },
        e => {
          const errorPath =
            "fetchProductPrices => productrecommendations.main.tsx";

          handleCustomException(e, logout, history, errorPath);

          if (this._isMounted) {
            this.setState({
              productPrices: null
            });
          }
        }
      );
    }
  }

  /**
   * ## validateEntitlement
   * @param docs Array<any>
   * @description Fetches the entitlement for each SKU for the logged user.
   */
  async validateEntitlement(docs) {
    const { history } = this.props;

    const {
      auth: { isLoggedIn, logout },
      cart: {
        cartDetails: { defaultCart }
      },
      account: {
        accountDetails: { customerNumber }
      }
    } = this.context;

    if (isLoggedIn && defaultCart && Config.entitlementCheck) {
      const skus = docs.map(product => product.pid);
      try {
        const { data } = await checkEntitlementGQL(customerNumber, skus);
        if (
          this._isMounted &&
          data &&
          data.data &&
          data.data.customer &&
          data.data.customer.productEntitlements
        ) {
          this.setState({
            productEntitlements: data.data.customer.productEntitlements
          });
        }
      } catch (error) {
        const errorPath =
          "validateEntitlement => checkEntitlementGQL => productrecommendations.main.tsx";
        handleCustomException(error, logout, history, errorPath);
      }
    }
  }

  /**
   * ## validateAvailability
   * @param docs Array<any>
   * @description Fetches the availability for each SKU for the logged user.
   */
  async validateAvailbility(docs) {
    const { history } = this.props;

    const {
      auth: { isLoggedIn, logout },
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList },
      account: {
        accountDetails: { customerNumber }
      }
    } = this.context;

    if (isLoggedIn && defaultCart && branchesList) {
      const { selectedBranch } = defaultCart;
      const skus = docs.map(product => product.pid);

      try {
        const branchNumber = selectedBranch.code;

        const { data } = await getAvailability(
          skus,
          customerNumber,
          branchNumber
        );
        if (
          data &&
          data.data &&
          data.data.inventory &&
          data.data.inventory.entitledInventory
        ) {
          const availabilityData = data.data.inventory.entitledInventory;
          const availability = formatQuoteAvailability(
            availabilityData.branches,
            availabilityData.regionRollups,
            skus,
            availabilityData.hubInventory
          );
          if (this._isMounted) {
            this.setState({
              productAvailability: availability
            });
          }
        }
      } catch (error) {
        const errorPath =
          "validateAvailability => getAvailability => productrecommendations.main.tsx";
        handleCustomException(error, logout, history, errorPath);
      }
    }
  }

  /**
   * ## addPriceAndEntitlement
   * @description If prices and entitlements exist,
   * add them to items. If not, set an empty string so the child
   * component will handle that.
   */
  addPriceAndEntitlementAndAvailability() {
    const {
      items,
      productEntitlements,
      productPrices,
      productAvailability
    } = this.state;
    const { productDataDetails } = this.props;
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    const productClass = productDataDetails.find(
      detail => detail.name === "class"
    ).value;

    const productClassesArray = [
      "Split Air Conditioner",
      "Split Heat Pump",
      "Coil",
      "Air Handler"
    ];
    const isClassFiltered = productClassesArray.includes(productClass);

    const {
      productsDisplayed
    } = Config.bloomreachWidget.config.similarProducts;

    const displayedItems = isClassFiltered
      ? items
      : items.slice(0, Number(productsDisplayed));

    return displayedItems.map(item => {
      let priceDetails;
      let itemWithDetails;

      try {
        priceDetails = productPrices.find(product => product.sku === item.pid);
        const productPrice = priceDetails ? priceDetails.lineTotal : "";
        itemWithDetails = { ...item, productPrice };
      } catch (error) {
        itemWithDetails = { ...item, productPrice: intl.get("pending") };
      }

      const entitlementDetails =
        productEntitlements &&
        productEntitlements.find(p => p.sku === item.pid);

      const entitled = entitlementDetails
        ? entitlementDetails.entitled
        : !Config.entitlementCheck;

      itemWithDetails.entitled = entitled;

      let inventoryItem;
      if (defaultCart) {
        const { selectedBranch } = defaultCart;

        inventoryItem =
          productAvailability &&
          productAvailability.find(
            p => p.sku === item.pid && p.branchNumber === selectedBranch.code
          );
      }

      itemWithDetails.branchAvailability = inventoryItem
        ? inventoryItem.branchAvailability
        : 0;
      itemWithDetails.regionAvailability = inventoryItem
        ? inventoryItem.regionAvailability
        : 0;
      itemWithDetails.dcAvailability = inventoryItem
        ? inventoryItem.dcQtyAvailable
        : null;
      return itemWithDetails;
    });
  }

  /**
   * ## handleError
   *
   * @param error string - message
   *
   * @description Sets the error message in the state and redirects to
   * maintenance page.
   */
  handleError(error?: string) {
    const errorMessage = error || intl.get("service-error");
    if (this._isMounted) {
      this.setState({
        message: {
          debugMessages: errorMessage,
          id: "",
          type: "error"
        },
        redirect: true
      });
    }
  }

  handleAddToCartError(pid: number, err: any = undefined): void {
    if (this._isMounted) {
      const {
        cart: { setErrorCartPopupMessage }
      } = this.context;
      setErrorCartPopupMessage(generateSpecificErrorMessage(err));
      this.setState(prevState => {
        const { addCartButtonIds: buttonIds } = prevState;
        return {
          addCartButtonIds: buttonIds.filter(buttonId => {
            return buttonId !== pid;
          })
        };
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  onSearchResultItemClick(item) {
    // sends information to Segment when user clicks on a product
    productClicked(
      item.title,
      item.pid,
      !item.productPrice || item.productPrice === intl.get("pending")
        ? 0
        : item.productPrice,
      item.brand,
      null
    );
    this.trackWidgetClickEvent(item);
  }

  /**
   * ## renderCarousel
   *
   * @description Renders carousel component for the related products on PDP.
   * If a product has any of the following classes:
   * "Split Air Conditioner", "Split Heat Pump", "Coil" and "Air Handler",
   * a filter is applied: only products that are entitled and available for purchase
   * are rendered in carousel
   */
  renderCarousel() {
    const { items, addCartButtonIds, displaySlider } = this.state;

    const { titleRef = undefined, productDataDetails } = this.props;

    const {
      auth: { guestLoggedIn }
    } = this.context;

    const {
      productsDisplayed
    } = Config.bloomreachWidget.config.similarProducts;
    const productClass = productDataDetails.find(
      detail => detail.name === "class"
    ).value;
    const productClassesArray = [
      "Split Air Conditioner",
      "Split Heat Pump",
      "Coil",
      "Air Handler"
    ];
    const isClassFiltered = productClassesArray.includes(productClass);

    const itemsWithDetails = this.addPriceAndEntitlementAndAvailability();
    const filteredItemsWithDetails = itemsWithDetails.filter(el => {
      const isPriceZero = el && `${el.productPrice}` === "0";
      if (guestLoggedIn) {
        return (
          !Config.checkAvailability ||
          el.branchAvailability ||
          el.regionAvailability
        );
      }
      return (
        Config.calculatePrice &&
        !isPriceZero &&
        el.entitled &&
        (!Config.checkAvailability ||
          el.branchAvailability ||
          el.regionAvailability)
      );
    });

    const carouselItems =
      filteredItemsWithDetails &&
      filteredItemsWithDetails.slice(0, Number(productsDisplayed));
    const itemsLength = items && items.slice(0, Number(productsDisplayed));
    const customizedSetting = {
      arrows: items.length > 4,
      infinite: false,
      nextArrow: <CarouselArrowButton />,
      prevArrow: <CarouselArrowButton />,
      dots: false,
      scrollNumber: 1
    };
    return displaySlider ? (
      <div className="silder-container dast-product-carousel">
        <MultipleItemsCarousel
          items={
            isClassFiltered
              ? carouselItems
              : this.addPriceAndEntitlementAndAvailability()
          }
          numOfItems={
            isClassFiltered ? carouselItems.length : itemsLength.length
          }
          carouselConfig={Config["mlt-carousel"]}
          customizedSetting={customizedSetting}
          displayComponent={item => (
            <SearchResultsItem
              item={item}
              onAddToCart={this.onAddToCart}
              key={item.pid}
              titleRef={titleRef}
              clickedButtonLoading={addCartButtonIds.includes(item.pid)}
              onClick={clickedItem => this.onSearchResultItemClick(clickedItem)}
            />
          )}
        />
      </div>
    ) : (
      <div className="loader-container">
        <div className="loader" />
      </div>
    );
  }

  trackWidgetViewEvent = () => {
    const {
      metadata: {
        widget: { rid, id, type }
      }
    } = this.state;
    const widgetViewData = {
      wrid: rid,
      wid: id,
      wty: type
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-view",
        widgetViewData,
        true
      );
  };

  trackWidgetClickEvent = (item: SingleProduct) => {
    const {
      metadata: {
        widget: { rid, id, type }
      }
    } = this.state;
    const widgetClickData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: item.pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent(
        "widget",
        "widget-click",
        widgetClickData,
        true
      );
  };

  trackWidgetAddToCartEvent = (pid: string) => {
    const {
      metadata: {
        widget: { rid, id, type }
      }
    } = this.state;
    const widgetAtcData = {
      wrid: rid,
      wid: id,
      wty: type,
      item_id: pid,
      sku: pid
    };
    if (typeof BrTrk !== "undefined")
      BrTrk.getTracker().logEvent("cart", "widget-add", widgetAtcData);
  };

  render() {
    const { items, redirect, message } = this.state;

    return items && items.length ? (
      <div>
        <div className="silder-container-product-recomendations">
          <h2 className="dast-product-carousel-header">
            {intl.get("product-recommendations")}
          </h2>
          {this.renderCarousel()}
          {redirect && message.debugMessages && (
            <Redirect
              to={{
                pathname: "/maintenance",
                state: {
                  error: {
                    e: { message: message.debugMessages },
                    errIn: "ProductRecommendationsMain"
                  }
                }
              }}
            />
          )}
        </div>
      </div>
    ) : null;
  }
}

export default withRouter(ProductRecommendationsDisplayMain);
