/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import intl from "react-intl-universal";
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  generateSpecificErrorMessage
} from "@elasticpath/ref-store/src/utils/helpers";
import { zoom } from "@zilker/store-components";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./cart.create.less";
import { productAdded } from "../utils/Segment";

let Config: IEpConfig | any = {};

interface CartCreateProps {
  handleModalClose: (...args: any[]) => any;
  openModal: boolean;
  updateCartModal?: boolean;
  history: any;
  auth: any;
}
interface CartCreateState {
  cartElements: any;
  cartName: string;
  showAddNewCartForm: boolean;
  showLoader: boolean;
  createCartForm: any;
  errorMessage: string;
  cartList: any;
  isOrderBeingAdded: any;
}

class CartCreate extends React.Component<CartCreateProps, CartCreateState> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    this.state = {
      cartElements: [],
      cartName: "",
      showAddNewCartForm: false,
      showLoader: false,
      createCartForm: [],
      errorMessage: "",
      cartList: null,
      isOrderBeingAdded: {
        orderName: "",
        shippingMethod: ""
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddNewCart = this.handleAddNewCart.bind(this);
    this.clearCartNameField = this.clearCartNameField.bind(this);
    this.handleShowCartForm = this.handleShowCartForm.bind(this);
    this.handleHideCartForm = this.handleHideCartForm.bind(this);
    this.handleHideLoader = this.handleHideLoader.bind(this);
    this.handleAddToDefaultCart = this.handleAddToDefaultCart.bind(this);
  }

  componentDidMount() {
    const {
      cart: { getCartList }
    } = this.context;
    const {
      history,
      auth: { logout }
    } = this.props;

    this._isMounted = true;
    this.setState({ showLoader: true });

    // First get list of all carts whithout prices and then call EP again to fetch prices
    getCartList()
      .then(res => {
        if (!res) {
          this.hideLoader();
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => componentDidMount => CartCreate.tsx"
            })
          );
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { cartList: prevCartList } = prevState;
    const {
      cart: { cartList }
    } = this.context;

    if (JSON.stringify(prevCartList) !== JSON.stringify(cartList)) {
      this.populateCartList(cartList);
    }
  }

  hideLoader() {
    this.setState({
      showLoader: false
    });
  }

  populateCartList(cartList: any): void {
    if (this._isMounted) {
      this.setState({ cartList, showLoader: false }, () => {
        this.generateCartData(-1);
      });
    }
  }

  generateCartData(itemIndex: number): void {
    const { cartElements, cartList } = this.state;

    const extCartElements = cartList._element.map((obj, index) => ({
      ...obj,
      editMode:
        cartElements[index] && index !== itemIndex
          ? cartElements[index].editMode
          : false,
      cartName: obj._descriptor[0].name || "",
      showLoader: false,
      removeCartLoading: false
    }));
    const index = cartList._element.findIndex(
      el => el._descriptor[0].default === "true"
    );
    this.setState({
      cartElements: extCartElements,
      showAddNewCartForm: false,
      showLoader: false,
      cartName: ""
    });
    if (itemIndex >= 0) {
      this.handleHideLoader(extCartElements, index);
    }
    if (cartList._createcartform) {
      this.setState({ createCartForm: cartList._createcartform });
    }
    if (itemIndex >= 0) {
      this.handleHideLoader(extCartElements, index);
    }
  }

  generateCartsTotals(totals) {
    const { cartElements } = this.state;
    const cartsWithTotal = cartElements.map((cart, index) => {
      const cartTotal = totals[index];
      const total = cartTotal._total[0].cost
        ? cartTotal._total[0].cost[0].display
        : intl.get("pending");
      return {
        ...cart,
        total
      };
    });
    this.setState({
      cartElements: cartsWithTotal
    });
  }

  fetchCartsTotals() {
    return cortexFetch(`/?zoom=${zoom.zoomCartsTotal.sort().join()}`)
      .then(res => checkResponse(res))
      .then(res => {
        if (res && res._carts && res._carts[0]) {
          this.generateCartsTotals(res._carts[0]._element);
        }
      })
      .catch(err => {
        const { cartElements } = this.state;
        const cartsWithPrice = cartElements.map(cart => ({
          ...cart,
          total: intl.get("pending")
        }));
        this.setState({
          cartElements: cartsWithPrice
        });
      });
  }

  handleHideLoader(cartList, index) {
    const elements = [...cartList];
    elements[index] = { ...elements[index] };
    elements[index].editMode = false;
    this.setState({
      cartElements: elements,
      isOrderBeingAdded: { orderName: "", shippingMethod: "" }
    });
  }

  createNewCart() {
    const { cartName } = this.state;
    const {
      history,
      auth: { logout }
    } = this.props;
    const {
      cart: { getCartList }
    } = this.context;
    this.setState({ showLoader: true });
    cortexFetch(
      `/carts/${Config.cortexApi.scope}/form?followlocation&format=standardlinks,zoom.nodatalinks`,
      {
        method: "post",
        body: JSON.stringify({ descriptor: { name: cartName.trim() } })
      }
    )
      .then(res => checkResponse(res))
      .then(() => getCartList())
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => createNewCart => CartCreate.tsx"
            })
          );
        } else if (this._isMounted) {
          this.setState({
            errorMessage: intl.get("custom-error-create-cart(order)"),
            showLoader: false
          });
        }
      });
  }

  handleChange(event) {
    this.setState({ cartName: event.target.value, errorMessage: "" });
  }

  handleAddNewCart() {
    const { cartName, cartElements } = this.state;
    const duplicate = cartElements.find(order => {
      if (!order._descriptor[0].name) {
        return order._descriptor[0].name === cartName;
      }
      return (
        order._descriptor[0].name
          .toUpperCase()
          .trim()
          .split(" ")
          .join("") ===
        cartName
          .toUpperCase()
          .trim()
          .split(" ")
          .join("")
      );
    });

    if (duplicate) {
      this.setState({
        errorMessage:
          intl.get("create-order-error-1") +
          cartName +
          intl.get("create-order-error-2")
      });
      return;
    }
    this.setState({ showAddNewCartForm: false });
    this.createNewCart();
  }

  clearCartNameField() {
    this.setState({ cartName: "", errorMessage: "" });
  }

  handleShowCartForm() {
    this.setState({ showAddNewCartForm: true });
  }

  handleHideCartForm() {
    this.setState({
      showAddNewCartForm: false,
      errorMessage: "",
      cartName: ""
    });
  }

  handleAddToDefaultCart(index: number, shippingMethod: "pickup" | "delivery") {
    const { cartElements } = this.state;
    const {
      cart: {
        getCartDetails,
        setSuccesCartPopupMessage,
        cartDetails: { defaultCart }
      },
      auth: { logout }
    } = this.context;
    const { handleModalClose, history } = this.props;

    this.setState({
      isOrderBeingAdded: {
        orderName: cartElements[index].cartName,
        shippingMethod
      }
    });
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const selectedBranchNumber = selectedBranch && selectedBranch.code;
    let addCartToDefaultCartLink;
    let itemsCount;

    try {
      addCartToDefaultCartLink = cartElements[index].links.find(
        link => link.rel === "addcarttodefaultcartform"
      ).uri;

      itemsCount = cartElements[index]["total-quantity"];
    } catch (e) {
      if (this._isMounted) {
        this.setState({
          isOrderBeingAdded: {
            orderName: "",
            shippingMethod: ""
          },
          errorMessage: intl.get("custom-error-add-to-cart")
        });
      }
    }

    // TODO DGE-3116
    // How to send items to Segment:
    // const orderItems = cartElem[index]._lineitems[0]._element.map(element => {
    //   return {
    //     id: element._item[0]._code[0].code,
    //     quantity: element.quantity,
    //     category: element._item[0]._definition[0].details.find(
    //       detail => detail.name.toLowerCase() === "product_category"
    //     ).value,
    //     name: element._item[0]._definition[0]["display-name"],
    //     brand: element._item[0]._definition[0].details.find(
    //       detail => detail.name === "brand"
    //     ).value,
    //     price: element._price[0]["list-price"][0].amount
    //   };
    // });

    cortexFetch(addCartToDefaultCartLink, {
      method: "post",
      body: JSON.stringify({
        "shipping-method": shippingMethod,
        "branch-number": selectedBranchNumber
      })
    })
      .then(res => {
        // sends information to Segment for every product user adds
        // orderItems.forEach(item => {
        //   productAdded(
        //     item.name,
        //     item.id,
        //     item.price,
        //     item.brand,
        //     item.category,
        //     item.quantity
        //   );
        // });
        const onSuccess = data => data;
        const onError = data => {
          if (!data.ok) {
            return data.json().then(json => {
              throw json;
            });
          }
          throw data;
        };
        return checkResponse(res, onSuccess, onError);
      })
      .then(() => getCartDetails())
      .then(() => {
        if (this._isMounted) {
          setSuccesCartPopupMessage(itemsCount);
          this.setState({
            isOrderBeingAdded: {
              orderName: "",
              shippingMethod: ""
            },
            errorMessage: intl.get("custom-error-add-to-cart")
          });
        }
        handleModalClose();
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleAddToDefaultCart => CartCreate.tsx"
            })
          );
        } else if (this._isMounted) {
          this.setState({
            isOrderBeingAdded: {
              orderName: "",
              shippingMethod: ""
            },
            errorMessage: intl.get("custom-error-add-to-cart")
          });
        }
      });
  }

  renderAddNewCartForm() {
    const { cartName, showLoader } = this.state;
    return (
      <div className="carts-list-item">
        {showLoader && (
          <div className="loader-wrapper">
            <div className="miniLoader" />
          </div>
        )}
        <div className="edit-mode">
          <h3>{intl.get("create-new-order")}</h3>
          <div className="edit-mode-form">
            <div className="cart-edit-field-wrap">
              <label htmlFor="cart_edit">Name</label>
              <input
                type="text"
                value={cartName}
                id="cart_edit"
                className="cart-edit-field"
                onChange={this.handleChange}
              />
              {cartName.length > 0 && (
                <span
                  role="presentation"
                  className="clear-field-btn"
                  onClick={this.clearCartNameField}
                />
              )}
            </div>
            <div className="btn-container">
              <button
                type="button"
                aria-label={intl.get("cancel")}
                className="dast-btn dast-btn-secondary"
                onClick={this.handleHideCartForm}
              >
                {intl.get("cancel")}
              </button>
              <button
                type="button"
                aria-label={intl.get("save")}
                className="dast-btn dast-btn-primary"
                onClick={this.handleAddNewCart}
                disabled={!cartName.trim().length}
              >
                {intl.get("save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCarts() {
    const { cartElements, isOrderBeingAdded } = this.state;
    if (cartElements.length) {
      return cartElements
        .filter(cart => !cart._descriptor[0].default)
        .map((cart, index) => {
          const cartName = cart._descriptor[0].name;
          const buttonsDisabled =
            isOrderBeingAdded &&
            isOrderBeingAdded.orderName !== "" &&
            isOrderBeingAdded.orderName !== cartName;
          const isPickupButtonLoading =
            isOrderBeingAdded &&
            isOrderBeingAdded.orderName === cartName &&
            isOrderBeingAdded.shippingMethod === "pickup";
          const isDeliveryButtonLoading =
            isOrderBeingAdded &&
            isOrderBeingAdded.orderName === cartName &&
            isOrderBeingAdded.shippingMethod === "delivery";

          const isDisabledAddOrderForPickup =
            isDeliveryButtonLoading || buttonsDisabled;
          const isDisabledAddOrderForDelivery =
            isPickupButtonLoading || buttonsDisabled;

          return (
            <li
              className={`carts-list-item ${
                cart.editMode || cart.deleteMode ? "edit-mode-state" : ""
              }`}
              // eslint-disable-next-line react/no-array-index-key
              key={`cartItem_${cart._descriptor[0].name}_${index}`}
              role="presentation"
            >
              <h4 className="cart-info">{cart._descriptor[0].name}</h4>
              <p className="cart-info cart-quantity">
                {cart["total-quantity"]} {intl.get("items")}
              </p>
              <div className="cart-info action-btn">
                {!isPickupButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("pick-up-method")}
                    type="button"
                    disabled={
                      isDisabledAddOrderForPickup || !cart["total-quantity"]
                    }
                    onClick={() => this.handleAddToDefaultCart(index, "pickup")}
                  >
                    {intl.get("pick-up-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
                {!isDeliveryButtonLoading ? (
                  <button
                    className="dast-btn dast-btn-primary"
                    aria-label={intl.get("delivery-method")}
                    type="button"
                    disabled={
                      !cart["total-quantity"] || isDisabledAddOrderForDelivery
                    }
                    onClick={() =>
                      this.handleAddToDefaultCart(index, "delivery")
                    }
                  >
                    {intl.get("delivery-method")}
                  </button>
                ) : (
                  <div className="miniLoader" />
                )}
              </div>
            </li>
          );
        });
    }

    return null;
  }

  render() {
    const {
      cartElements,
      showAddNewCartForm,
      createCartForm,
      errorMessage,
      showLoader
    } = this.state;
    const { openModal, handleModalClose } = this.props;
    const {
      cart: { cartListError, cartList }
    } = this.context;

    return (
      <Modal open={openModal} onClose={handleModalClose}>
        <div className="modal-lg cart-create-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("save-order")}</h2>
            </div>
            <div className="modal-body">
              {createCartForm.length >= 0 && !showLoader && (
                <div className="create-cart-btn-wrap">
                  {showAddNewCartForm ? (
                    this.renderAddNewCartForm()
                  ) : (
                    <button
                      type="button"
                      aria-label={intl.get("create-new-cart")}
                      className="dast-btn dast-btn-primary create-cart-btn"
                      onClick={this.handleShowCartForm}
                    >
                      {intl.get("create-new-order")}
                    </button>
                  )}
                  <span className="cart-create-error-message">
                    {errorMessage}
                  </span>
                </div>
              )}
              <div className="carts-list-wrap">
                <h3>
                  {intl.get("your-orders")}
                  {cartElements && cartElements.length > 1
                    ? `(${cartElements.length})`
                    : ""}
                </h3>
                <ul className="carts-list">{this.renderCarts()}</ul>
              </div>
              {!cartList && !createCartForm && cartListError && (
                <span className="cart-create-error-message">
                  {intl.get("custom-error-create-cart(order)")}
                </span>
              )}
              {!showAddNewCartForm && showLoader && !cartListError && (
                <div className="loader-wrapper">
                  <div className="miniLoader" />
                </div>
              )}
            </div>
            {!cartList && !showLoader && !cartListError && (
              <div className="no-saved-order-message">
                {intl.get("there-are-no-saved-orders-to-display")}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default CartCreate;
