import React, { FC } from "react";
import intl from "react-intl-universal";

import "./search.dropdown.component.less";
import { logAutoSuggestTermThroughSegment } from "../utils/Segment";

interface SearchDropdownPropsInterface {
  suggestions: Array<string>;
  suggestionIndex: number | undefined;
  arrowSelection: boolean;
  inputValue: string;
  goToSearchPage: (string?) => any;
  errorMessage: string;
  recentSearches?: Array<string>;
}

const SearchDropdown: FC<SearchDropdownPropsInterface> = ({
  suggestions,
  suggestionIndex,
  inputValue,
  arrowSelection,
  goToSearchPage,
  errorMessage,
  recentSearches = []
}) => {
  const handleOnClick = (term: string) => {
    logAutoSuggestTermThroughSegment(
      "headersearch autosuggest",
      term,
      inputValue
    );
    goToSearchPage(term);
  };

  const renderRecentSearches = () => (
    <div
      className={`recent-searches${
        suggestions.length ? " showing-suggestions" : ""
      }`}
    >
      <p className="recent-searches-title">{intl.get("recent-searches")}</p>
      {recentSearches.map((recentSearch: string, index: number) => {
        const key = `${recentSearch}${index}`;
        return (
          <div
            className={`suggestion-link${
              arrowSelection && index === suggestionIndex
                ? " active-suggestion"
                : ""
            }`}
            key={key}
            onClick={() => handleOnClick(recentSearch)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            {recentSearch}
          </div>
        );
      })}
    </div>
  );

  const renderSuggestions = () => (
    <div className="suggestions">
      {suggestions.map((suggestion: string, index: number) => {
        const key = `${suggestion}${index}`;
        return (
          <div
            className={`suggestion-link${
              arrowSelection &&
              index + recentSearches.length === suggestionIndex
                ? " active-suggestion"
                : ""
            }`}
            key={key}
            onClick={() => {
              handleOnClick(suggestion);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            {suggestion}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="search-dropdown">
      {errorMessage ? (
        <div className="error">{errorMessage}</div>
      ) : (
        <>
          {recentSearches.length ? renderRecentSearches() : null}
          {renderSuggestions()}
        </>
      )}
    </div>
  );
};

export default SearchDropdown;
