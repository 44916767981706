/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";

import "./generalorderinformation.main.less";
import { Input } from "@zilker/store-components";
import Notifications from "../Notifications/Notifications";
import { MainContext } from "../../../app/src/contexts/MainContext";

import { getConfig, IEpConfig } from "../utils/ConfigProvider";

interface GOIProps {
  poNumberRequired: boolean;
  handleNotificationsStatusChange: (...args: any[]) => any;
  handleNotificationsDataChange: (...args: any[]) => any;
  notificationsErrors: any;
}

const GeneralOrderInformation: React.FC<GOIProps> = props => {
  const {
    poNumberRequired,
    handleNotificationsStatusChange,
    handleNotificationsDataChange,
    notificationsErrors
  } = props;

  const context = useContext<{ user: any; cart: any }>(MainContext);
  const {
    user: { userProfile },
    cart: {
      orderInformation,
      notificationsStatus,
      orderInformationErrors,
      notificationsData
    }
  } = context;

  const { email, phone } = userProfile;

  const { config }: { config: IEpConfig } = getConfig();
  const {
    showUserNotifications,
    displayPriceOnPickedTickets,
    showPhoneNumberPopUp
  } = config;

  const displayPhoneNumber =
    showPhoneNumberPopUp &&
    email &&
    (!phone ||
      phone === "9999999999" ||
      phone === "999-999-9999" ||
      orderInformation.phone);

  const [formErrors, setFormErrors] = useState(orderInformationErrors);
  const maxDate = new Date();
  const minDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 10);

  useEffect(() => {
    setFormErrors(prevState => ({
      ...prevState,
      ...orderInformationErrors
    }));
  }, [orderInformationErrors]);

  const handleOrderInformationChange = (name, value) => {
    const { cart } = context;
    const { setOrderInformationData, setOrderInfoErrors } = cart;
    setOrderInformationData(prevInformation => ({
      ...prevInformation,
      [name]: value
    }));
    setOrderInfoErrors(prevErrors => ({
      ...prevErrors,
      [name]: ""
    }));
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    handleOrderInformationChange(name, value);
  };

  const handleCheckboxClick = e => {
    const name = e.target.id;
    const value = !orderInformation[e.target.id];
    handleOrderInformationChange(name, value);
  };

  return (
    <div className="general-order-information-container">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <div className="input-component">
              <p className="label">
                <span className="star">*</span>
                {intl.get("date-needed")}
              </p>
              <input
                type="date"
                name="date"
                placeholder="yyyy-mm-dd"
                onChange={handleInputChange}
                value={orderInformation.date}
                min={minDate.toJSON().split("T")[0]}
                max={maxDate.toJSON().split("T")[0]}
                className={
                  formErrors && formErrors.date
                    ? "validation-error-border"
                    : null
                }
                required
              />
              <span className="date-time-error">{formErrors.date}</span>
            </div>
          </div>
        </div>
        {/* // See DGE-3145. Alternate email input is removed.
        <div className="col-12 col-md-4"> */}
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <Input
              label={intl.get("purchase-order")}
              type="text"
              inputName="po-number"
              ariaLabel={intl.get("purchase-order")}
              inputHandler={handleInputChange}
              value={orderInformation["po-number"]}
              errors={formErrors}
              required={poNumberRequired}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <div className="input-component">
              <p className="label">{intl.get("order-comments")}: </p>
              <textarea
                id="order-comments"
                name="order-comments"
                value={orderInformation["order-comments"]}
                onChange={handleInputChange}
                maxLength={500}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex flex-column">
            <div className="input-component">
              <p className="label">{intl.get("delivery-instructions")}</p>
              <textarea
                id="shipping-instructions"
                name="shipping-instructions"
                value={orderInformation["shipping-instructions"]}
                onChange={handleInputChange}
                maxLength={240}
              />
            </div>
          </div>
        </div>
        {displayPriceOnPickedTickets && (
          <div className="col-12">
            <label
              className="price-pick-tickets-label"
              htmlFor="price-pick-tickets"
              id="price-pick-tickets-label"
            >
              <input type="checkbox" name="price-pick-tickets" id="checkbox" />
              <span
                className={`price-pick-tickets-checkbox ${
                  orderInformation["show-pick-tickets-price"]
                    ? "show-checkmark"
                    : "hide-checkmark"
                }`}
                onClick={handleCheckboxClick}
                onKeyDown={handleCheckboxClick}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="price-pick-tickets-label"
                id="show-pick-tickets-price"
              />
              {intl.get("price-pick-tickets")}
            </label>
          </div>
        )}
        {showUserNotifications && (
          <div className="col-12">
            <Notifications
              notificationsStatus={notificationsStatus}
              notificationsError={notificationsErrors}
              updateNotificationsStatus={handleNotificationsStatusChange}
              updateNotificationsData={handleNotificationsDataChange}
              userProfile={userProfile}
              notificationsData={notificationsData}
            />
          </div>
        )}
        {displayPhoneNumber && (
          <div className="col-12 col-md-6">
            <div className="d-flex flex-column">
              <Input
                label={intl.get("phone-number")}
                type="text"
                inputName="phone"
                ariaLabel={intl.get("phone-number")}
                inputHandler={handleInputChange}
                value={orderInformation.phone}
                errors={formErrors}
                required
              />
              <p className="error">
                {intl.get("empty-phone-number-message-2")}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralOrderInformation;
