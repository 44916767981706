/**
 * @desc Array of zoom parameters to pass to Cortex.
 * @see ./components/ProductDisplayItem/productdisplayitem.main.tsx
 */
const zoomArray = [
  "availability",
  "addtocartform",
  "addtowishlistform",
  "price",
  "rate",
  "definition",
  "definition:assets:element",
  "definition:options:element",
  "definition:options:element:value",
  "definition:options:element:selector:choice",
  "definition:options:element:selector:chosen",
  "definition:options:element:selector:choice:description",
  "definition:options:element:selector:chosen:description",
  "definition:options:element:selector:choice:selector",
  "definition:options:element:selector:chosen:selector",
  "definition:options:element:selector:choice:selectaction",
  "definition:options:element:selector:chosen:selectaction",
  "definition:components",
  "definition:components:element",
  "definition:components:element:code",
  "definition:components:element:standaloneitem",
  "definition:components:element:standaloneitem:code",
  "definition:components:element:standaloneitem:definition",
  "definition:components:element:standaloneitem:availability",
  "recommendations",
  "recommendations:crosssell",
  "recommendations:recommendation",
  "recommendations:replacement",
  "recommendations:upsell",
  "recommendations:warranty",
  "recommendations:crosssell:element:code",
  "recommendations:recommendation:element:code",
  "recommendations:replacement:element:code",
  "recommendations:upsell:element:code",
  "recommendations:warranty:element:code",
  "recommendations:crosssell:element:definition",
  "recommendations:recommendation:element:definition",
  "recommendations:replacement:element:definition",
  "recommendations:upsell:element:definition",
  "recommendations:warranty:element:definition",
  "recommendations:crosssell:element:price",
  "recommendations:recommendation:element:price",
  "recommendations:replacement:element:price",
  "recommendations:upsell:element:price",
  "recommendations:warranty:element:price",
  "recommendations:crosssell:element:availability",
  "recommendations:recommendation:element:availability",
  "recommendations:replacement:element:availability",
  "recommendations:upsell:element:availability",
  "recommendations:warranty:element:availability",
  "code"
];

const zoomAccountProfiles = [
  "element",
  "element:associates:self",
  "element:admins:element:associatedetails",
  "element:status"
];

const zoomAccountProfile = [
  "associates:self",
  "admins:element:associatedetails",
  "status"
];

const zoomUserAssociates = [
  "associates:element",
  "associates:element:associatedetails"
];

const zoomUserProfile = [
  "defaultprofile",
  "defaultprofile:addresses",
  "defaultprofile:addresses:addressform",
  "defaultprofile:addresses:shippingaddresses:default",
  "defaultprofile:addresses:shippingaddresses:element",
  "defaultprofile:emails",
  "defaultprofile:emails:element",
  "defaultprofile:emails:element:list",
  "defaultprofile:emails:element:profile",
  "defaultprofile:emails:emailform",
  "defaultprofile:emails:profile",
  "defaultprofile:addresses:element",
  "defaultprofile:addresses:billingaddresses:default",
  "defaultprofile:paymentmethods",
  "defaultprofile:paymentmethods:paymenttokenform",
  "defaultprofile:paymentmethods:element",
  "data-policies:element",
  "data-policies:element:datapolicyconsentform",
  "defaultprofile:accountusers:element"
];

/**
 * @desc Array of zoom parameters to pass to Cortex (multiple cart).
 * @see ./app/src/containers/OrderHistoryDetails.tsx
 */
const zoomFetchCart = [
  "defaultcart",
  "defaultcart:additemstocartform",
  "carts",
  "carts:element"
];

// Array of zoom parameters to pass to Cortex
const zoomArrayProfile = [
  "defaultprofile",
  "defaultprofile:emails",
  "defaultprofile:emails:element",
  "defaultprofile:emails:element:list",
  "defaultprofile:emails:element:profile",
  "defaultprofile:emails:emailform",
  "defaultprofile:emails:profile"
];

const zoomFetchCartOrderDetailsForm = [
  "defaultcart:order:cartorderdetails:cartorderdetailsform"
];

const zoomFetchOrder = [
  // zooms for checkout summary
  "defaultcart",
  "defaultcart:total",
  "defaultcart:discount",
  "defaultcart:order",
  "defaultcart:order:tax",
  "defaultcart:order:total",
  "defaultcart:order:paymenttypeinfo",
  "defaultcart:order:paymenttypeinfo:selector",
  "defaultcart:order:paymenttypeinfo:selector:choice",
  "defaultcart:order:paymenttypeinfo:selector:choice:description",
  "defaultcart:order:paymenttypeinfo:selector:chosen",
  "defaultcart:order:paymenttypeinfo:selector:chosen:description",
  "defaultcart:appliedpromotions:element",
  "defaultcart:order:couponinfo:coupon",
  "defaultcart:order:couponinfo:couponform",
  "defaultcart:order:other-charges",
  // zooms for billing address
  "defaultcart:order:billingaddressinfo:billingaddress",
  "defaultcart:order:billingaddressinfo:selector:choice",
  "defaultcart:order:billingaddressinfo:selector:choice:description",
  // zooms for shipping address
  "defaultcart:order:deliveries:element:destinationinfo:destination",
  "defaultcart:order:deliveries:element:destinationinfo:selector:choice",
  "defaultcart:order:deliveries:element:destinationinfo:selector:choice:description",
  // zooms for shipping options
  "defaultcart:order:deliveries:element:shippingoptioninfo:shippingoption",
  "defaultcart:order:deliveries:element:shippingoptioninfo:selector:choice",
  "defaultcart:order:deliveries:element:shippingoptioninfo:selector:choice:description",
  // zooms for payment methods
  "defaultcart:order:paymentmethodinfo:paymentmethod",
  "defaultcart:order:paymentmethodinfo:selector:choice",
  "defaultcart:order:paymentmethodinfo:selector:choice:description"
];

const zoomArrayPaymentType = [
  "choice",
  "choice:description",
  "chosen",
  "chosen:description"
];

const fetchCartData = [
  "carts",
  "carts:createcartform",
  "carts:element",
  "carts:element:addcarttodefaultcartform",
  "carts:element:additemstocartform",
  "carts:element:appliedpromotions:element",
  "carts:element:descriptor",
  "carts:element:discount",
  "carts:element:lineitems",
  "carts:element:lineitems:element",
  "carts:element:lineitems:element:appliedpromotions",
  "carts:element:lineitems:element:appliedpromotions:element",
  "carts:element:lineitems:element:availability",
  "carts:element:lineitems:element:dependentlineitems:element:item:definition",
  "carts:element:lineitems:element:dependentoptions:element:definition",
  "carts:element:lineitems:element:item",
  "carts:element:lineitems:element:item:code",
  "carts:element:lineitems:element:item:price",
  "carts:element:lineitems:element:item:definition",
  "carts:element:lineitems:element:item:definition:details",
  "carts:element:lineitems:element:item:definition:item",
  "carts:element:lineitems:element:item:definition:options:element",
  "carts:element:lineitems:element:item:definition:options:element:selector:choice",
  "carts:element:lineitems:element:item:definition:options:element:selector:chosen",
  "carts:element:lineitems:element:item:definition:options:element:value",
  "carts:element:lineitems:element:price",
  "carts:element:lineitems:element:total",
  "carts:element:order:couponinfo:coupon",
  "carts:element:order:couponinfo:couponform",
  "carts:element:order:total",
  "carts:element:order:tax",
  "carts:element:order:cartorderdetails:cartorderdetailsform",
  "carts:element:order:cartorderdetails:cartorderdetailswarehouses:selector:chosen:description",
  "carts:element:total",
  "carts:element:order:other-charges"
];

const zoomSavedOrders = ["carts", "carts:element", "carts:element:descriptor"];

const zoomSavedOrdersDetails = [
  "lineitems:element",
  "lineitems:element:item:definition",
  "lineitems:element:item:code",
  "lineitems:element:item:price",
  "order:total",
  "order:tax",
  "total"
];

const zoomOrderReview = [
  // zooms for checkout summary
  "defaultcart",
  "defaultcart:total",
  "defaultcart:discount",
  "defaultcart:order",
  "defaultcart:order:tax",
  "defaultcart:order:total",
  "defaultcart:order:other-charges",
  "defaultcart:appliedpromotions:element",
  "defaultcart:order:couponinfo:coupon",
  "defaultcart:order:couponinfo:couponform",
  // zoom for billing address
  "defaultcart:order:billingaddressinfo:billingaddress",
  // zoom for shipping address
  "defaultcart:order:deliveries:element:destinationinfo:destination",
  // zoom for shipping option
  "defaultcart:order:deliveries:element:shippingoptioninfo:shippingoption",
  // zoom for payment method
  "defaultcart:order:paymentmethodinfo:paymentmethod",
  "defaultcart:order:postedpayments",
  "defaultcart:order:paymentinstrumentselector:chosen:description",
  // zooms for table items
  "defaultcart:lineitems:element",
  "defaultcart:lineitems:element:total",
  "defaultcart:lineitems:element:item",
  "defaultcart:lineitems:element:item:code",
  "defaultcart:lineitems:element:price",
  "defaultcart:lineitems:element:item:price",
  "defaultcart:lineitems:element:item:definition",
  "defaultcart:lineitems:element:item:definition:options:element",
  "defaultcart:lineitems:element:item:definition:options:element:value",
  "defaultcart:lineitems:element:dependentlineitems",
  "defaultcart:lineitems:element:dependentlineitems:element",
  "defaultcart:lineitems:element:dependentlineitems:element:item:addtocartform",
  "defaultcart:lineitems:element:dependentlineitems:element:item:availability",
  "defaultcart:lineitems:element:dependentlineitems:element:item:definition",
  "defaultcart:lineitems:element:dependentlineitems:element:item:code",
  // zoom for purchaseform
  "defaultcart:order:purchaseform"
];

const zoomPurchase = [
  "paymentmeans:element",
  "postedpayments:element",
  "shipments:element:destination",
  "shipments:element:shippingoption",
  "billingaddress",
  "discount",
  "appliedpromotions:element",
  "lineitems:element",
  "lineitems:element:options:element",
  "lineitems:element:options:element:value",
  "lineitems:element:components",
  "lineitems:element:components:element",
  "lineitems:element:components:element:item:addtocartform",
  "lineitems:element:components:element:item:availability",
  "lineitems:element:components:element:item:definition",
  "lineitems:element:components:element:item:code",
  "lineitems:element:dependentlineitems",
  "lineitems:element:dependentlineitems:element",
  "lineitems:element:dependentlineitems:element:item:addtocartform",
  "lineitems:element:dependentlineitems:element:item:availability",
  "lineitems:element:dependentlineitems:element:item:definition",
  "lineitems:element:dependentlineitems:element:item:code"
];

const zoomResetPassword = ["passwordresetform"];

const zoomWishList = [
  "defaultwishlist",
  "defaultwishlist:lineitems",
  "defaultwishlist:lineitems:element",
  "defaultwishlist:lineitems:element:item:price",
  "defaultwishlist:lineitems:element:item:availability",
  "defaultwishlist:lineitems:element:list",
  "defaultwishlist:lineitems:element:list:element",
  "defaultwishlist:lineitems:element:item",
  "defaultwishlist:lineitems:element:item:code",
  "defaultwishlist:lineitems:element:item:definition",
  "defaultwishlist:lineitems:element:item:definition:options:element",
  "defaultwishlist:lineitems:element:item:definition:options:element:value",
  "defaultwishlist:lineitems:element:item:definition:options:element:selector:choice",
  "defaultwishlist:lineitems:element:item:definition:options:element:selector:chosen",
  "defaultwishlist:lineitems:element:item:definition:options:element:selector:choice:description",
  "defaultwishlist:lineitems:element:item:definition:options:element:selector:chosen:description",
  "defaultwishlist:lineitems:element:movetocartform"
];

const zoomAddessForm = [
  "element",
  "element:regions",
  "element:regions:element",
  "countries:element",
  "countries:element:regions",
  "countries:element:regions:element"
];

const zoomNavigation = [
  "navigations:element",
  "navigations:element:child",
  "navigations:element:child:child",
  "navigations:element:child:child:child",
  "navigations:element:child:child:child:child",
  "navigations:element:child:child:child:child:child",
  "navigations:element:child:child:child:child:child:child",
  "navigations:element:child:child:child:child:child:child:child",
  "navigations:element:child:child:child:child:child:child:child:child"
];

const zoomCategoryItems = [
  "chosen",
  "chosen:description",
  "offersearchresult",
  "offersearchresult:element",
  "offersearchresult:element:availability",
  "offersearchresult:element:definition",
  "offersearchresult:element:price",
  "offersearchresult:element:rate",
  "offersearchresult:element:code",
  "offersearchresult:element:pricerange",
  "offersearchresult:element:items",
  "offersearchresult:element:items:element",
  "offersearchresult:element:items:element:availability",
  "offersearchresult:element:items:element:definition",
  "offersearchresult:element:items:element:price",
  "offersearchresult:element:items:element:rate",
  "offersearchresult:element:items:element:code",
  "offersearchresult:facets",
  "offersearchresult:facets:element",
  "offersearchresult:facets:element:facetselector",
  "offersearchresult:facets:element:facetselector:choice:description",
  "offersearchresult:facets:element:facetselector:choice:selector",
  "offersearchresult:facets:element:facetselector:choice:selectaction",
  "offersearchresult:facets:element:facetselector:chosen:description",
  "offersearchresult:facets:element:facetselector:chosen:selector",
  "offersearchresult:facets:element:facetselector:chosen:selectaction",
  "offersearchresult:sortattributes",
  "offersearchresult:sortattributes:choice",
  "offersearchresult:sortattributes:choice:description",
  "offersearchresult:sortattributes:choice:selectaction",
  "offersearchresult:sortattributes:chosen",
  "offersearchresult:sortattributes:chosen:description",
  "offersearchresult:sortattributes:chosen:selectaction"
];

const zoomAppModalBundleConfiguration = [
  "dependentoptions",
  "dependentoptions:element",
  "dependentoptions:element:price",
  "dependentoptions:element:addtocartform",
  "dependentoptions:element:availability",
  "dependentoptions:element:definition",
  "dependentoptions:element:definition:options:element",
  "dependentoptions:element:definition:options:element:value",
  "dependentoptions:element:code",
  "dependentlineitems",
  "dependentlineitems:element",
  "dependentlineitems:element:item:price",
  "dependentlineitems:element:item:availability",
  "dependentlineitems:element:item:definition",
  "dependentlineitems:element:item:definition:options:element",
  "dependentlineitems:element:item:definition:options:element:value",
  "dependentlineitems:element:item:code",
  "dependentlineitems:element:dependentoptions",
  "dependentlineitems:element:dependentoptions:element",
  "dependentlineitems:element:dependentlineitems",
  "dependentlineitems:element:dependentlineitems:element:item:definition"
];

const zoomAccount = [
  "accountmetadata",
  "selfsignupinfo",
  "statusinfo",
  "statusinfo:status",
  "subaccounts",
  "subaccounts:element",
  "subaccounts:element:accountmetadata",
  "subaccounts:element:subaccounts",
  "subaccounts:element:subaccounts:element",
  "subaccounts:element:statusinfo",
  "subaccounts:element:statusinfo:status",
  "subaccounts:element:associateroleassignments",
  "subaccounts:element:associateroleassignments:element",
  "subaccounts:element:associateroleassignments:element",
  "subaccounts:element:associateroleassignments:element:associate",
  "subaccounts:element:associateroleassignments:element:associate:primaryemail",
  "subaccounts:element:associateroleassignments:element:roleinfo",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:chosen",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:chosen:description",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:chosen:selectaction",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:chosen:selector",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:choice",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:choice:description",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:choice:selectaction",
  "subaccounts:element:associateroleassignments:element:roleinfo:selector:choice:selector",
  "subaccounts:element:associateroleassignments:element:roleinfo:roles",
  "subaccounts:element:associateroleassignments:element:roleinfo:roles:element",
  "subaccounts:element:associateroleassignments:associateform",
  "subaccounts:element:associateroleassignments:associateform:addassociateaction",
  "subaccounts:element:subaccounts:accountform",
  "subaccounts:element:subaccounts:element:subaccounts:element",
  "subaccounts:element:subaccounts:element:statusinfo",
  "subaccounts:element:subaccounts:element:statusinfo:status",
  "subaccounts:accountform",
  "associateroleassignments",
  "associateroleassignments:element",
  "associateroleassignments:element:associate",
  "associateroleassignments:element:associate:primaryemail",
  "associateroleassignments:element:roleinfo",
  "associateroleassignments:element:roleinfo:selector",
  "associateroleassignments:element:roleinfo:selector:chosen",
  "associateroleassignments:element:roleinfo:selector:chosen:description",
  "associateroleassignments:element:roleinfo:selector:chosen:selectaction",
  "associateroleassignments:element:roleinfo:selector:chosen:selector",
  "associateroleassignments:element:roleinfo:selector:choice",
  "associateroleassignments:element:roleinfo:selector:choice:description",
  "associateroleassignments:element:roleinfo:selector:choice:selectaction",
  "associateroleassignments:element:roleinfo:selector:choice:selector",
  "associateroleassignments:element:roleinfo:roles",
  "associateroleassignments:element:roleinfo:roles:element",
  "associateroleassignments:associateform",
  "associateroleassignments:associateform:addassociateaction"
];

const zoomDashboardAccounts = [
  "accounts",
  "accounts:element",
  "accounts:element:accountmetadata",
  "accounts:element:selfsignupinfo",
  "accounts:element:statusinfo",
  "accounts:element:statusinfo:status",
  "accounts:element:subaccounts",
  "accounts:element:subaccounts:accountform",
  "accounts:element:subaccounts:parentaccount",
  "accounts:element:associateroleassignments",
  "accounts:element:associateroleassignments:element",
  "accounts:element:associateroleassignments:element:associateroleassignments",
  "accounts:element:associateroleassignments:element:roleinfo",
  "accounts:element:associateroleassignments:element:roleinfo:roles",
  "accounts:element:associateroleassignments:element:roleinfo:roles:element",
  "accounts:element:associateroleassignments:element:roleinfo:selector",
  "accounts:element:associateroleassignments:element:associate",
  "accounts:element:associateroleassignments:element:associate:primaryemail"
];

const zoomAccountAssociates = [
  "accounts",
  "accounts:element",
  "accounts:element:attributes",
  "accounts:element:selfsignupinfo",
  "accounts:element:statusinfo",
  "accounts:element:statusinfo:status",
  "accounts:element:associateroleassignments",
  "accounts:element:associateroleassignments:element",
  "accounts:element:associateroleassignments:element:associate",
  "accounts:element:associateroleassignments:element:associate:primaryemail",
  "accounts:element:associateroleassignments:element:roleinfo",
  "accounts:element:associateroleassignments:element:roleinfo:selector",
  "accounts:element:associateroleassignments:element:roleinfo:selector:chosen",
  "accounts:element:associateroleassignments:element:roleinfo:selector:chosen:description",
  "accounts:element:associateroleassignments:element:roleinfo:selector:chosen:selectaction",
  "accounts:element:associateroleassignments:element:roleinfo:selector:chosen:selector",
  "accounts:element:associateroleassignments:element:roleinfo:selector:choice",
  "accounts:element:associateroleassignments:element:roleinfo:selector:choice:description",
  "accounts:element:associateroleassignments:element:roleinfo:selector:choice:selectaction",
  "accounts:element:associateroleassignments:element:roleinfo:selector:choice:selector",
  "accounts:element:associateroleassignments:element:roleinfo:roles",
  "accounts:element:associateroleassignments:element:roleinfo:roles:element",
  "accounts:element:associateroleassignments:associateform",
  "accounts:element:associateroleassignments:associateform:addassociateaction",
  "myprofile:primaryemail"
];

// Array of zoom parameters to pass to Cortex for navigationForm
const navigationFormZoomArray = [
  "items",
  "items:element",
  "items:element:code",
  "items:element:definition",
  "offers",
  "offers:element",
  "offers:element:code",
  "offers:element:definition",
  "offers:element:items",
  "offers:element:items:element",
  "offers:element:items:element:definition",
  "offers:element:items:element:code",
  "element",
  "element:definition",
  "element:code",
  "element:items",
  "element:items:element",
  "element:items:element:definition",
  "element:items:element:code",
  "facets",
  "facets:element",
  "facets:element:facetselector",
  "facets:element:facetselector:choice:description",
  "facets:element:facetselector:choice:selector",
  "facets:element:facetselector:choice:selectaction",
  "facets:element:facetselector:chosen:description",
  "facets:element:facetselector:chosen:selector",
  "facets:element:facetselector:chosen:selectaction",
  "offers:facets",
  "offers:facets:element",
  "offers:facets:element:facetselector",
  "offers:facets:element:facetselector:choice:description",
  "offers:facets:element:facetselector:choice:selector",
  "offers:facets:element:facetselector:choice:selectaction",
  "offers:facets:element:facetselector:chosen:description",
  "offers:facets:element:facetselector:chosen:selector",
  "offers:facets:element:facetselector:chosen:selectaction",
  "parent"
];

// Array of zoom parameters to pass to Cortex for itemDetails
const itemFormZoomArray = [
  "availability",
  "addtocartform",
  "addtowishlistform",
  "price",
  "rate",
  "definition",
  "definition:assets:element",
  "definition:options:element",
  "definition:options:element:value",
  "definition:options:element:selector:choice",
  "definition:options:element:selector:chosen",
  "definition:options:element:selector:choice:description",
  "definition:options:element:selector:chosen:description",
  "definition:options:element:selector:choice:selector",
  "definition:options:element:selector:chosen:selector",
  "definition:options:element:selector:choice:selectaction",
  "definition:options:element:selector:chosen:selectaction",
  "definition:components",
  "definition:components:element",
  "definition:components:element:code",
  "definition:components:element:standaloneitem",
  "definition:components:element:standaloneitem:code",
  "definition:components:element:standaloneitem:definition",
  "definition:components:element:standaloneitem:availability",
  "recommendations",
  "recommendations:crosssell",
  "recommendations:recommendation",
  "recommendations:replacement",
  "recommendations:upsell",
  "recommendations:warranty",
  "recommendations:crosssell:element:code",
  "recommendations:recommendation:element:code",
  "recommendations:replacement:element:code",
  "recommendations:upsell:element:code",
  "recommendations:warranty:element:code",
  "recommendations:crosssell:element:definition",
  "recommendations:recommendation:element:definition",
  "recommendations:replacement:element:definition",
  "recommendations:upsell:element:definition",
  "recommendations:warranty:element:definition",
  "recommendations:crosssell:element:price",
  "recommendations:recommendation:element:price",
  "recommendations:replacement:element:price",
  "recommendations:upsell:element:price",
  "recommendations:warranty:element:price",
  "recommendations:crosssell:element:availability",
  "recommendations:recommendation:element:availability",
  "recommendations:replacement:element:availability",
  "recommendations:upsell:element:availability",
  "recommendations:warranty:element:availability",
  "code",
  "parentcategory"
];

// Array of zoom parameters to pass to Cortex for purchaseDetails
const purchaseFormZoomArray = [
  "paymentmeans:element",
  "postedpayments:element",
  "shipments:element:destination",
  "shipments:element:shippingoption",
  "billingaddress",
  "discount",
  "appliedpromotions:element",
  "lineitems:element",
  "lineitems:element:item:code",
  "lineitems:element:item:availability",
  "lineitems:element:item:definition",
  "lineitems:element:item:addtocartform",
  "lineitems:element:item:definition:assets:element",
  "lineitems:element:item:price",
  "lineitems:element:item:rate",
  "lineitems:element:options:element",
  "lineitems:element:options:element:value",
  "lineitems:element:components",
  "lineitems:element:components:element",
  "lineitems:element:components:element:item:addtocartform",
  "lineitems:element:components:element:item:availability",
  "lineitems:element:components:element:item:definition",
  "lineitems:element:components:element:item:code"
];

// Array of zoom parameters to pass to Cortex for searchDetails
const searchFormZoomArray = [
  "element",
  "element:availability",
  "element:definition",
  "element:definition:assets:element",
  "element:price",
  "element:rate",
  "element:code",
  "element",
  "element:availability",
  "element:definition",
  "element:definition:assets:element",
  "element:pricerange",
  "element:items",
  "element:items:element",
  "element:items:element:availability",
  "element:items:element:definition",
  "element:items:element:definition:assets:element",
  "element:items:element:price",
  "element:items:element:rate",
  "element:items:element:code",
  "facets",
  "facets:element",
  "facets:element:facetselector",
  "facets:element:facetselector:choice:description",
  "facets:element:facetselector:choice:selector",
  "facets:element:facetselector:choice:selectaction",
  "facets:element:facetselector:chosen:description",
  "facets:element:facetselector:chosen:selector",
  "facets:element:facetselector:chosen:selectaction",
  "sortattributes",
  "sortattributes:choice",
  "sortattributes:choice:description",
  "sortattributes:choice:selectaction",
  "sortattributes:choice:selector",
  "sortattributes:chosen",
  "sortattributes:chosen:description",
  "sortattributes:chosen:selectaction",
  "sortattributes:chosen:selector",
  "sortattributes:offersearchresult"
];

// Array of zoom parameters to pass to Cortex for batches
const batchFormZoomArray = [
  "element",
  "element:availability",
  "element:addtocartform",
  "element:addtowishlistform",
  "element:price",
  "element:rate",
  "element:definition",
  "element:definition:assets:element",
  "element:definition:options:element",
  "element:definition:options:element:value",
  "element:definition:options:element:selector:choice",
  "element:definition:options:element:selector:chosen",
  "element:definition:options:element:selector:choice:description",
  "element:definition:options:element:selector:chosen:description",
  "element:definition:options:element:selector:choice:selector",
  "element:definition:options:element:selector:chosen:selector",
  "element:definition:options:element:selector:choice:selectaction",
  "element:definition:options:element:selector:chosen:selectaction",
  "element:definition:components",
  "element:definition:components:element",
  "element:definition:components:element:code",
  "element:definition:components:element:standaloneitem",
  "element:definition:components:element:standaloneitem:code",
  "element:definition:components:element:standaloneitem:definition",
  "element:definition:components:element:standaloneitem:availability",
  "element:recommendations",
  "element:recommendations:crosssell",
  "element:recommendations:recommendation",
  "element:recommendations:replacement",
  "element:recommendations:upsell",
  "element:recommendations:warranty",
  "element:recommendations:crosssell:element:code",
  "element:recommendations:recommendation:element:code",
  "element:recommendations:replacement:element:code",
  "element:recommendations:upsell:element:code",
  "element:recommendations:warranty:element:code",
  "element:recommendations:crosssell:element:definition",
  "element:recommendations:recommendation:element:definition",
  "element:recommendations:replacement:element:definition",
  "element:recommendations:upsell:element:definition",
  "element:recommendations:warranty:element:definition",
  "element:recommendations:crosssell:element:price",
  "element:recommendations:recommendation:element:price",
  "element:recommendations:replacement:element:price",
  "element:recommendations:upsell:element:price",
  "element:recommendations:warranty:element:price",
  "element:recommendations:crosssell:element:availability",
  "element:recommendations:recommendation:element:availability",
  "element:recommendations:replacement:element:availability",
  "element:recommendations:upsell:element:availability",
  "element:recommendations:warranty:element:availability",
  "element:code"
];

/**
 * ************** Cart logic zoom arrays **************
 */

/**
 * @desc Array of zoom parameters to pass to Cortex (multiple carts without details).
 * This is used to retrieve the list of all carts - without details, just with cart name and a link to create cart.
 * Used in CartContext.js in function getcartList().
 * @see \react\app\src\contexts\CartContext.js
 * @see \react\app\src\containers\SavedOrders.tsx
 * @see \react\components\src\SavedOrderCard\saved.order.card.tsx
 * @see \react\components\src\CartCreate\cart.create.tsx
 */
const zoomCartList = [
  "carts",
  "carts:createcartform",
  "carts:element",
  "carts:element:descriptor"
];

/**
 * @description Array of zoom parameters to pass to Cortex (all carts total)
 * This is used to retrieve total for all carts
 * @see \react\components\src\CartCreate\cart.create.tsx
 */
const zoomCartsTotal = ["carts:element:total"];

/**
 * @desc Array of zoom parameters to pass to Cortex (default cart with details).
 * This is used to retrieve the default cart information- with all the details about the cart, order items,
 * quantity, prices, total, promotions, etc.
 * Used in CartContext.js in function getCartDetails - that is called in multiple pages.
 * @see \react\app\src\contexts\CartContext.js
 */
const zoomFetchDefaultCartData = [
  "defaultcart",
  "defaultcart:addcarttodefaultcartform",
  "defaultcart:additemstocartform",
  "defaultcart:appliedpromotions:element",
  "defaultcart:descriptor",
  "defaultcart:discount",
  "defaultcart:lineitems",
  "defaultcart:lineitems:element",
  "defaultcart:lineitems:element:appliedpromotions",
  "defaultcart:lineitems:element:appliedpromotions:element",
  "defaultcart:lineitems:element:availability",
  "defaultcart:lineitems:element:dependentlineitems:element:item:definition",
  "defaultcart:lineitems:element:dependentoptions:element:definition",
  "defaultcart:lineitems:element:item",
  "defaultcart:lineitems:element:item:code",
  "defaultcart:lineitems:element:item:price",
  "defaultcart:lineitems:element:item:definition",
  "defaultcart:lineitems:element:item:definition:details",
  "defaultcart:lineitems:element:item:definition:item",
  "defaultcart:lineitems:element:item:definition:options:element",
  "defaultcart:lineitems:element:item:definition:options:element:selector:choice",
  "defaultcart:lineitems:element:item:definition:options:element:selector:chosen",
  "defaultcart:lineitems:element:item:definition:options:element:value",
  "defaultcart:lineitems:element:price",
  "defaultcart:lineitems:element:total",
  "defaultcart:order:couponinfo:coupon",
  "defaultcart:order:couponinfo:couponform",
  "defaultcart:order:total",
  "defaultcart:order:tax",
  "defaultcart:order:cartorderdetails:cartorderdetailsform",
  "defaultcart:order:cartorderdetails:cartorderdetailswarehouses:selector:chosen:description",
  "defaultcart:total",
  "defaultcart:order:other-charges"
];

/**
 * @desc Array of zoom parameters to pass to Cortex (details for single saved order).
 * This is used to retrieve the details for a saved order - order name, total, order items- with price and quantity.
 * @see \react\app\src\containers\SavedOrderDetails.tsx
 */
const zoomFetchSavedOrder = [
  "addcarttodefaultcartform",
  "descriptor",
  "lineitems",
  "lineitems:element",
  "lineitems:element:item",
  "lineitems:element:total",
  "lineitems:element:price",
  "lineitems:element:item:definition",
  "lineitems:element:item:definition:details",
  "lineitems:element:item:code",
  "lineitems:element:item:price",
  "order:total",
  "total",
  "order:tax"
];

/**
 * @desc Array of zoom parameters to pass to Cortex (details for category landing).
 * Used to retrieve relevant details for bookmarks on the category landing breadcrumbs.
 * @see \react\app\src\containers\.tsx
 */
const navigationCategoryLandingZoomArray = ["details", "parent", "child"];

/**
 * @desc Array of zoom parameters to pass to Cortex (details for category PLP breadcrumbs).
 * Used to retrieve relevant details for bookmarks on the category PLP breadcrumbs.
 * @see \react\app\src\containers\SearchResultsPage.tsx
 */
const navigationCategoryPLPZoomArray = ["details", "parent", "parent:parent"];

export {
  zoomArray,
  zoomAccountProfile,
  zoomUserAssociates,
  zoomCartList,
  zoomUserProfile,
  zoomFetchCart,
  zoomArrayProfile,
  zoomFetchOrder,
  zoomArrayPaymentType,
  fetchCartData,
  zoomSavedOrders,
  zoomSavedOrdersDetails,
  zoomOrderReview,
  zoomPurchase,
  zoomResetPassword,
  zoomWishList,
  zoomAddessForm,
  zoomNavigation,
  zoomCategoryItems,
  zoomAppModalBundleConfiguration,
  zoomAccount,
  zoomDashboardAccounts,
  zoomAccountAssociates,
  navigationFormZoomArray,
  navigationCategoryLandingZoomArray,
  navigationCategoryPLPZoomArray,
  itemFormZoomArray,
  purchaseFormZoomArray,
  searchFormZoomArray,
  batchFormZoomArray,
  zoomFetchDefaultCartData,
  zoomFetchSavedOrder,
  zoomCartsTotal,
  zoomAccountProfiles,
  zoomFetchCartOrderDetailsForm
};
