import { useState, useEffect } from "react";
import { getConfig } from "@zilker/store-components";
import { fetchAccount } from "../services/EpServices";
import * as LoginService from "../services/LoginService";

const initialUserAccount = {
  uri: "",
  isBuyerAdmin: "",
  homeBranch: "",
  homeBranchVendor: "",
  addAssociateUri: "",
  customerNumber: "",
  customerRoles: "",
  company: "",
  poNumberRequired: "",
  chargeCreditCode: "",
  creditCardAllowed: "",
  cashAllowed: "",
  creditLineAllowed: "",
  isEligibleForLoyalty: "",
  jobsArray: "",
  jobNameRequired: "",
  jobNumberRequired: "",
  showPriceOnPickedTicket: "",
  dealerNumber: null,
  membership: [],
  companyAffiliation: "",
  hasError: "",
  loaded: false,
  isInitialData: true,
  admins: null
};
// eslint-disable-next-line import/prefer-default-export
export const AccountContext = () => {
  const [accountDetails, setAccountDetails] = useState(initialUserAccount);
  const checkLogin = LoginService.isLoggedIn();
  const { config } = getConfig();

  const accountSharedId = localStorage.getItem(
    `${config.cortexApi.scope}_AccountSharedId`
  );
  const accountGuid = localStorage.getItem(
    `${config.cortexApi.scope}_AccountGuid`
  );

  useEffect(() => {
    if (checkLogin && accountSharedId) {
      getUserAccount();
    }
  }, [checkLogin, accountSharedId]);

  function getUserAccount() {
    fetchUserAccount(setAccountDetails, accountGuid);
  }

  function removeUserAccount() {
    setAccountDetails(initialUserAccount);
  }

  return {
    accountDetails,
    getUserAccount,
    removeUserAccount
  };
};

function fetchUserAccount(setAccountDetails, accountGuid) {
  fetchAccount(accountGuid)
    .then(res => {
      if (res) {
        const accountPayload = res;
        if (accountPayload["job-number-name-array"]) {
          accountPayload.jobsArray = JSON.parse(
            accountPayload["job-number-name-array"],
            (key, value) => (value === "[]" ? JSON.parse(value) : value)
          );
        }

        if (accountPayload.membership) {
          accountPayload.membership = JSON.parse(accountPayload.membership);
          document.cookie = `loyaltyprogram=${encodeURIComponent(
            accountPayload.membership.toString()
          )}; path=/`;
        }

        if (accountPayload._associates && accountPayload._associates[0]) {
          if (
            accountPayload._associates[0]._self &&
            accountPayload._associates[0]._self[0] &&
            accountPayload._associates[0]._self[0].role
          ) {
            accountPayload.isBuyerAdmin =
              accountPayload._associates[0]._self[0].role === "BUYER_ADMIN";
          }
        }

        if (
          accountPayload._admins &&
          accountPayload._admins[0] &&
          accountPayload._admins[0]._element
        ) {
          accountPayload.admins = accountPayload._admins[0]._element;
        }
        if (accountPayload.self && accountPayload.self.uri) {
          const accountPayloadUri = accountPayload.self.uri;
          const queryStringIndex = accountPayloadUri.indexOf("?");
          const accountUri =
            queryStringIndex === -1
              ? accountPayloadUri
              : accountPayloadUri.substring(0, queryStringIndex);
          accountPayload.addAssociateUri = accountUri;
        }

        const userAccount = {
          uri: accountPayload.addAssociateUri,
          isBuyerAdmin: accountPayload.isBuyerAdmin,
          homeBranch: accountPayload["account-home-branch"],
          homeBranchVendor:
            accountPayload["account-home-branch-vendor"] || "GOODMAN",
          addAssociateUri: accountPayload.addAssociateUri,
          customerNumber: accountPayload["customer-number"],
          customerRoles: accountPayload["customer-roles"],
          company: accountPayload["account-business-name"],
          poNumberRequired: accountPayload["po-number-required"],
          chargeCreditCode: accountPayload["charge-credit-code"],
          creditCardAllowed: accountPayload["credit-card-allowed"],
          cashAllowed: accountPayload["cash-allowed"],
          creditLineAllowed: accountPayload["credit-line-allowed"],
          isEligibleForLoyalty: accountPayload["eligible-for-loyalty"],
          jobsArray: accountPayload.jobsArray,
          jobNameRequired: accountPayload["job-name-required"] === "true",
          jobNumberRequired: accountPayload["job-number-required"] === "true",
          showPriceOnPickedTicket:
            accountPayload["show-price-on-picked-ticket"] === "true",
          dealerNumber: accountPayload["dealer-number"],
          membership: accountPayload.membership
            ? accountPayload.membership
            : [],
          companyAffiliation: accountPayload["company-affiliation"],
          loaded: true,
          isInitialData: false,
          admins: accountPayload.admins
        };
        setAccountDetails(userAccount);
      } else if (!res) {
        // TODO: implement logic for no account users
        setAccountDetails({ ...initialUserAccount, loaded: true });
      }
    })
    .catch(error => {
      const accountDetails = initialUserAccount;
      accountDetails.hasError = error;

      setAccountDetails(accountDetails);
    });
}
