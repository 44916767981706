import React, { FC, useEffect } from "react";
import uuidv4 from "uuid/v4";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getConfig } from "@zilker/store-components";
import { discoverOIDCParameters } from "@zilker/store-components/src/utils/AuthService";
import { pushToMaintenace } from "../utils/helpers";
import * as LoginService from "../services/LoginService";

interface OidcParameters {
  clientId: string;
  scopes: string;
  authorizationEndpoint: string;
  endSessionEndpoint: string;
}

const LoginLandingPage: FC<RouteComponentProps> = ({ history }) => {
  const { config } = getConfig();

  useEffect(() => {
    if (config.b2b.openId && config.b2b.openId.enable) {
      login();
    }
  }, []);

  async function login() {
    if (!LoginService.isLoggedIn()) {
      try {
        const oidcSecret = uuidv4();
        localStorage.setItem("OidcSecret", oidcSecret);
        const oidcParameters: OidcParameters = await discoverOIDCParameters();

        const oidcStateObject = {
          secret: oidcSecret,
          pathname: window.location.pathname
        };

        const oidcStateEncoded = btoa(JSON.stringify(oidcStateObject));
        const redirectUrl = `${config.b2b.openId.callbackUrl}/loggedin`;
        const { brand } = config.b2b.openId;

        const queryArray = [
          `scope=${encodeURIComponent(oidcParameters.scopes)}`,
          "response_type=code",
          `client_id=${encodeURIComponent(oidcParameters.clientId)}`,
          `redirect_uri=${encodeURIComponent(redirectUrl)}`,
          `state=${oidcStateEncoded}`
        ];
        if (brand) {
          queryArray.push(`brand=${brand}`);
        }
        const query = queryArray.join("&");
        const loginUrl = `${oidcParameters.authorizationEndpoint}?${query}`;

        window.location.href = loginUrl;
      } catch (e) {
        pushToMaintenace(history, {
          e,
          errIn: "login => LoginLandingPage.tsx"
        });
      }
    } else {
      history.push("/");
    }
  }

  return <div className="loader" />;
};

export default withRouter(LoginLandingPage);
