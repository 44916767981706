/**
 * Refactored component from productdisplayitem.main.tsx. This component handles displaying the product image
 * on the PDP.
 */
import React, { FC } from "react";
import intl from "react-intl-universal";

import { generateImageUrl } from "../../../../app/src/utils/mappings/productDetails";
import { getConfig } from "../../utils/ConfigProvider";

interface ProductImageProps {
  productData: any;
}

const ProductImage: FC<ProductImageProps> = ({ productData }) => {
  const { config } = getConfig();

  const imgMissingHorizontal = config.missingImagePlaceholderUrl;

  const imageUrl = productData._definition
    ? generateImageUrl(productData._definition[0].details, "full_image")
    : "";

  const imageAlt = productData._definition
    ? productData._definition[0]["display-name"]
    : "";

  return (
    <div>
      <img
        src={imageUrl}
        onError={e => {
          const element: any = e.target;
          element.src = imgMissingHorizontal;
        }}
        alt={imageAlt || intl.get("none-available")}
        className="itemdetail-main-img"
      />
    </div>
  );
};

export default ProductImage;
