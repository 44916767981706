/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";

import "./profileaddresses.main.less";
import AddressItem, { AddressDetails } from "../AddressItem/AddressItem";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface ProfileAddressesMainProps {
  addresses: {
    [key: string]: any;
  };
  onChange: () => void;
  onAddNewAddress: () => void;
  onEditAddress: (address: string) => void;
  setUserMessage: (message: string) => void;
  history: any;
  auth: any;
  newAddressUri?: string;
}

class ProfileAddressesMain extends React.Component<
  ProfileAddressesMainProps,
  {}
> {
  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);

    this.handleDelete = this.handleDelete.bind(this);
    this.renderAddresses = this.renderAddresses.bind(this);
  }

  handleDelete(link: string, address: AddressDetails): void {
    const {
      history,
      auth: { logout },
      onChange,
      setUserMessage
    } = this.props;

    cortexFetch(link, {
      method: "delete"
    })
      .then(res => {
        const onSuccess = data => data;
        const onError = errorData => errorData.json();
        return checkResponse(res, onSuccess, onError);
      })
      // eslint-disable-next-line consistent-return
      .then(dataJson => {
        if (dataJson.messages) {
          const { id, type } = dataJson.messages[0];

          if (id === "address.assignedtocart" && type === "error") {
            // The associated order cannot be deleted.
            const debugMessage = this.formatUserMessage(
              dataJson.messages[0]["debug-message"],
              address
            );

            return setUserMessage(debugMessage);
          }

          if (type === "error") {
            return setUserMessage(intl.get("custom-error-delete-address"));
          }
        }

        if (dataJson.status === 204) {
          return onChange();
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => handleDelete => ProfileAddressesMain.tsx"
            })
          );
        } else {
          setUserMessage(intl.get("custom-error-delete-address"));
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  formatUserMessage(debugMessage: string, address: AddressDetails): string {
    const fullAddress: string = `${address["street-address"]} ${address.locality} ${address.region}, ${address["country-name"]} ${address["postal-code"]}`;

    const messageParts: Array<string> = debugMessage.split(" ");

    messageParts.splice(1, 1, fullAddress);

    const userMessage: string = messageParts.join(" ").replace("!", ".");

    return userMessage;
  }

  renderAddresses(): any {
    const { addresses, onEditAddress, newAddressUri } = this.props;

    const addressBookHeader = (
      <div className="address-book-header">
        <div className="address-book-header-before">
          <span>{`${intl.get("address")}: `}</span>
        </div>
        <div className="address-book-header-wrapper">
          <span>{`${intl.get("job-name")}: `}</span>
          <span>{`${intl.get("job-number")}: `}</span>
        </div>
        <div className="address-book-header-after" />
      </div>
    );

    if (
      addresses._shippingaddresses &&
      addresses._shippingaddresses[0] &&
      addresses._shippingaddresses[0]._element
    ) {
      const renderedAddresses = addresses._shippingaddresses[0]._element;
      return (
        <>
          {addressBookHeader}
          <ul>
            {renderedAddresses.map((addressElement, index) => {
              const { name, address, self, type, messages } = addressElement;
              const jobNumber: string = addressElement["job-number"] || "";
              const jobName: string = addressElement["job-name"] || "";

              /**
               * Check the latest address that has been edited or added and validate if it has warning message in the
               * messages array. Display warning below the address if the EP returned warning message for the invalid address.
               * If the address has been edited and warning disappeared, don;t show the message below the address.
               */
              const displayWarningMessage: boolean = Boolean(
                newAddressUri && self.uri === newAddressUri && messages.length
              );

              return (
                // eslint-disable-next-line react/no-array-index-key
                <li className="table-item" key={`address-item-${index}`}>
                  <AddressItem
                    addressItemDetails={{
                      name,
                      address,
                      uri: self.uri,
                      type,
                      jobNumber,
                      jobName
                    }}
                    onDelete={this.handleDelete}
                    onEdit={onEditAddress}
                    displayWarningMessage={displayWarningMessage}
                  />
                </li>
              );
            })}
          </ul>
        </>
      );
    }
    return (
      <div>
        <p>{intl.get("no-saved-address-message")}.</p>
      </div>
    );
  }

  render() {
    const { addresses, onAddNewAddress } = this.props;
    const isDisabled: boolean = !addresses._addressform;
    if (addresses) {
      return (
        <div
          className="profile-addresses-container content-box content-table"
          data-region="profileAddressesRegion"
        >
          <h4>{intl.get("delivery-address")}</h4>
          <div>
            {this.renderAddresses()}
            <button
              className="dast-btn dast-btn-primary add-address-button"
              type="button"
              aria-label={intl.get("add-address")}
              disabled={isDisabled}
              onClick={onAddNewAddress}
            >
              {intl.get("add-address")}
            </button>
          </div>
        </div>
      );
    }
    return <div className="loader" />;
  }
}

export default ProfileAddressesMain;
