import React, { FC, useContext } from "react";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { MainContext } from "../../../app/src/contexts/MainContext";

const AppModalWarning: FC = () => {
  const context = useContext<{ modal: any }>(MainContext);
  const {
    modal: { warningModal, setWarningModal }
  } = context;
  const { openModal, modalMessage } = warningModal;
  function closeModal() {
    sessionStorage.setItem("notified-branch", "true");
    setWarningModal({
      openModal: false,
      modalMessage: ""
    });
  }
  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      classNames={{ modal: "cart-selection-modal-content" }}
    >
      <div className="modal-lg">
        <div className="modal-content" id="simplemodal-container">
          <div className="modal-header">
            <h2 className="modal-title">{intl.get("info")}</h2>
          </div>

          <div className="modal-body">
            <div id="cart_selection_modal_form">
              <div className="carts-selection-region">{modalMessage}</div>
              <div className="action-row">
                <div className="form-input btn-container">
                  <button
                    onClick={closeModal}
                    className="dast-btn dast-btn-primary wide"
                    id="continue_with_cart_button"
                    data-cmd="continue"
                    type="submit"
                  >
                    {intl.get("continue")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppModalWarning;
