import React, { useState, FC } from "react";
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import { checkMemberType } from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { useMainContext } from "../../../app/src/contexts/MainContext";
import UserRoles from "../../../app/src/utils/mappings/userRoles";

import "./account.menu.component.less";

const AccountMenuComponent: FC<RouteComponentProps> = ({ location }) => {
  const [open, setOpen] = useState<boolean>(false);
  const context = useMainContext();
  const [classNameSuffix, setClassNameSuffix] = useState<string>("");

  const toggleMenu = (): void => {
    if (window.innerWidth <= 768) {
      setOpen(!open);
      if (open) setClassNameSuffix("");
      if (!open) setClassNameSuffix(" account-menu-open");
    }
  };

  const {
    account: {
      accountDetails: { isBuyerAdmin, membership }
    },
    user: {
      userProfile: {
        onlineBillPayLink,
        roles,
        hasLeadGenRoles,
        hasLeadGenDistributorRoles,
        ecommerceUser,
        ecommerceBuyer,
        leadGenDealer
      }
    },
    contract: { contractsList }
  } = context;

  const { config }: { config: IEpConfig } = getConfig();
  const {
    statementPageDisplay,
    creditInformationPageDisplay,
    showManageUsers,
    showOnlineBillPay,
    invoicePageDisplay,
    contractOrderPageDisplay,
    orderHistoryCSVExport,
    showStandardOrders
  } = config;

  const viewCreditRole = roles.includes(UserRoles().viewCredit);
  const viewStatementsRole = roles.includes(UserRoles().viewStatements);

  const isLeadGenAdmin =
    roles.includes(UserRoles().leadDashboardAdmin) ||
    roles.includes(UserRoles().leadDistributerAdmin);

  const isValidDaikinManagerLeadGen = roles.includes("VIEW_ALL_REPORTS");

  const userHasLeads =
    leadGenDealer !== null &&
    leadGenDealer !== "null" &&
    leadGenDealer !== undefined;

  const isAcceptableBuyer = isBuyerAdmin || isLeadGenAdmin;

  const daikinComfortPro = intl.get("dcp");
  const hasDcpMembership = checkMemberType(
    daikinComfortPro.toLowerCase(),
    membership
  );

  const isValidCODLeadGen = hasLeadGenRoles && userHasLeads && hasDcpMembership;
  const isValidINDLeadGen =
    !ecommerceUser && !ecommerceBuyer && hasLeadGenRoles && userHasLeads;
  const isValidDistributorLeadGen = hasLeadGenDistributorRoles;

  const displayLeadMenuItem = () => {
    if (
      isValidCODLeadGen ||
      isValidINDLeadGen ||
      isValidDistributorLeadGen ||
      isValidDaikinManagerLeadGen
    ) {
      return true;
    }

    return false;
  };

  const hasReportsRole =
    roles.includes("VIEW_DEALER_REPORTS") ||
    roles.includes("VIEW_DISTRIBUTOR_REPORTS") ||
    roles.includes("VIEW_ALL_REPORTS");

  return (
    <div className="account-menu content-box">
      <div
        className="account-menu-title"
        onClick={toggleMenu}
        onKeyDown={toggleMenu}
        role="presentation"
      >
        <h1>{`${intl.get("my-account")}`}</h1>
        <i
          title="Account Menu"
          aria-label="Open Account Menu"
          className={open ? "icon-minus" : "icon-plus"}
        />
      </div>
      <div className={`account-menu-content${classNameSuffix}`}>
        <div className="content-segment">
          <ul>
            <li>
              <NavLink
                to="/myAccount/profile"
                activeClassName="active-side-menu"
                onClick={toggleMenu}
              >
                {`${intl.get("account-settings")}`}
              </NavLink>
            </li>
            {ecommerceUser && (
              <>
                {creditInformationPageDisplay && viewCreditRole && (
                  <li>
                    <NavLink
                      to="/myAccount/creditInformation"
                      activeClassName="active-side-menu"
                      onClick={toggleMenu}
                    >
                      {`${intl.get("credit-information")}`}
                    </NavLink>
                  </li>
                )}
                {statementPageDisplay && viewStatementsRole && (
                  <li>
                    <NavLink
                      to="/myAccount/statement"
                      activeClassName="active-side-menu"
                      onClick={toggleMenu}
                    >
                      {`${intl.get("statement")}`}
                    </NavLink>
                  </li>
                )}
                {invoicePageDisplay && (
                  <li>
                    <NavLink
                      to="/myAccount/invoiceQuery"
                      activeClassName="active-side-menu"
                      onClick={toggleMenu}
                      className={
                        location.pathname.startsWith(
                          "/myAccount/invoiceDetails"
                        )
                          ? "active-side-menu"
                          : ""
                      }
                    >
                      {`${intl.get("invoice-inquiry")}`}
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/myAccount/branchSettings"
                    activeClassName="active-side-menu"
                    onClick={toggleMenu}
                  >
                    {`${intl.get("branch-settings")}`}
                  </NavLink>
                </li>
              </>
            )}
            {showManageUsers && isAcceptableBuyer && (
              <li>
                <NavLink
                  to="/myAccount/manageUsers"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                >
                  {`${intl.get("manage-users")}`}
                </NavLink>
              </li>
            )}
            {showOnlineBillPay && ecommerceUser && (
              <li>
                <a
                  href={onlineBillPayLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.get("online-billing-website")}
                </a>
              </li>
            )}
          </ul>
        </div>
        {ecommerceBuyer && (
          <div className="content-segment">
            <div className="account-menu-title">
              <h1 className="my-orders-title">{`${intl.get("my-orders")}`}</h1>
            </div>
            <ul>
              <li>
                <NavLink
                  to="/myAccount/savedOrders"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                >
                  {`${intl.get("my-saved-orders")}`}
                </NavLink>
              </li>
              {showStandardOrders && (
                <li>
                  <NavLink
                    to="/myAccount/standardOrders"
                    activeClassName="active-side-menu"
                    onClick={toggleMenu}
                  >
                    {`${intl.get("branch-standard-orders")}`}
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  to="/myAccount/orderHistory"
                  activeClassName="active-side-menu"
                  onClick={toggleMenu}
                  className={
                    location.pathname.startsWith("/myAccount/orderDetails")
                      ? "active-side-menu"
                      : ""
                  }
                >
                  {intl.get("order-history")}
                  {orderHistoryCSVExport && isBuyerAdmin && (
                    <span>{intl.get("new-download-feature")}</span>
                  )}
                </NavLink>
              </li>
              {contractOrderPageDisplay &&
              contractsList &&
              contractsList.length ? (
                <li>
                  <NavLink
                    to="/myAccount/contractOrders"
                    activeClassName="active-side-menu"
                    onClick={toggleMenu}
                    className={
                      location.pathname.startsWith("/myAccount/contractDetails")
                        ? "active-side-menu"
                        : ""
                    }
                  >
                    {`${intl.get("contract-orders")}`}
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        )}
        {displayLeadMenuItem() && (
          <div className="content-segment">
            <div className="account-menu-title">
              <h1 className="my-orders-title">My Leads</h1>
            </div>
            <ul>
              {hasLeadGenRoles && (
                <li>
                  <NavLink
                    to="/myAccount/leadTracking"
                    activeClassName="active-side-menu"
                    onClick={toggleMenu}
                  >
                    Lead Tracking
                  </NavLink>
                </li>
              )}
              {hasReportsRole && (
                <li>
                  <NavLink
                    to="/myAccount/leadReporting"
                    activeClassName="active-side-menu"
                    onClick={toggleMenu}
                  >
                    Lead Reporting
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(AccountMenuComponent);
