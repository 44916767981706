/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import { getSelectedLocaleValue, init } from "@zilker/store-components";
import React from "react";
import ReactDOM from "react-dom";
import intl from "react-intl-universal";
import App from "./App";
import epConfig from "./ep.config.json";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./theme/index.less";
import "./theme/reset.less";
import "./theme/style.less";

const locales = {};
epConfig.supportedLocales.forEach(locale => {
  let scope = "goodman";
  if (epConfig.brXM.defaultChannel && epConfig.brXM.defaultChannel.length > 0) {
    scope = epConfig.brXM.defaultChannel;
  }
  // load store level file first, if not found load the root level default file
  try {
    locales[
      locale.value
    ] = require(`./localization/${scope}/${locale.value}.json`);
  } catch (err) {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    locales[locale.value] = require(`./localization/${locale.value}.json`);
  }
});

// localisation init
intl
  .init({
    currentLocale: getSelectedLocaleValue(epConfig),
    locales
  })
  .then(() => {
    init({
      config: epConfig,
      DaikinApi: {},
      intl
    }).then(componentsData => {
      ReactDOM.render(
        <App componentsData={componentsData} />,
        document.getElementById("root")
      );
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/service-worker.js", { scope: "/" })
          // eslint-disable-next-line no-console
          .then(() => console.log("Service Worker registered successfully."))
          // eslint-disable-next-line no-console
          .catch(error =>
            console.error("Service Worker registration failed:", error)
          );
      }
    });
  });
