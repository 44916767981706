import React, { useState } from "react";
import * as intl from "react-intl-universal";
import { extractPhoneAndFax } from "@elasticpath/ref-store/src/utils/helpers";
import Modal from "react-responsive-modal";

import SingleBranch from "../SingleBranch/SingleBranch";
import { BranchDetails } from "../DefaultBranchInfoCard/DefaultBranchInfoCard";
import BranchChangeComponent from "../BranchChange/BranchChangeComponent";

import "./BranchListComponent.less";

interface BranchListComponentProps {
  branches: Array<BranchDetails>;
  homeBranch: BranchDetails;
  changeBranch?: (...args: any[]) => any;
}

const BranchListComponent: React.FC<BranchListComponentProps> = ({
  branches,
  homeBranch,
  changeBranch
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseConfirmModal = (): void => {
    setLoading(false);
    setOpenConfirmModal(false);
  };

  const handleOpenConfirmModal = e => {
    const branchNumber: string = e.target.name;
    setSelectedBranch(branchNumber);
    setOpenConfirmModal(true);
  };

  const confirmUserSelection = (): void => {
    setLoading(true);
    changeBranch(selectedBranch);
    setOpenConfirmModal(false);
  };

  const renderBranchChangeModal = () => {
    return (
      <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
        <BranchChangeComponent userSelectionConfirmed={confirmUserSelection} />
      </Modal>
    );
  };

  return (
    <div className="branch container">
      {renderBranchChangeModal()}
      {branches.map(branch => {
        return (
          <SingleBranch
            key={branch.branchNumber}
            branchName={branch.branchName}
            branchNumber={branch.branchNumber}
            address={branch.formattedAddress}
            phone={extractPhoneAndFax(branch).phone}
            fax={extractPhoneAndFax(branch).fax}
            email={branch.email}
            userHomeBranch={homeBranch}
            openModal={handleOpenConfirmModal}
          />
        );
      })}
    </div>
  );
};

export default BranchListComponent;
