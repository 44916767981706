import React, { FC } from "react";
import intl from "react-intl-universal";

import "./Popup.less";

const Popup: FC = () => {
  return (
    <div className="popup-container">
      {intl.get("customer-account-number-popup")}
    </div>
  );
};

export default Popup;
