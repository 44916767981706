import React, { useContext } from "react";
import { AuthContext } from "./AuthContext";
import { ProfileContext } from "./ProfileContext";
import { CartContext } from "./CartContext";
import { AccountContext } from "./AccountContext";

import { ModalContext } from "./ModalContext";
import { OrderContext } from "./OrderContext";
import { BranchContext } from "./BranchContext";
import { JobContext } from "./JobContext";
import { ClientContext } from "./ClientContext";
import { NavigationContext } from "./NavigationContext";
import { ContractContext } from "./ContractContext";

export const MainContext = React.createContext();

const MainContextProvider = ({ children }) => {
  const authContext = AuthContext();
  const profileContext = ProfileContext();
  const accountContext = AccountContext();
  const cartContext = CartContext();
  const modalContext = ModalContext();
  const orderContext = OrderContext();
  const branchContext = BranchContext();
  const jobContext = JobContext();
  const clientContext = ClientContext();
  const navigationContext = NavigationContext();
  const contractContext = ContractContext();

  const contextValue = {
    auth: authContext,
    account: accountContext,
    user: profileContext,
    cart: cartContext,
    modal: modalContext,
    order: orderContext,
    branches: branchContext,
    job: jobContext,
    client: clientContext,
    navigation: navigationContext,
    contract: contractContext
  };
  return (
    <MainContext.Provider value={contextValue}>{children}</MainContext.Provider>
  );
};

export default MainContextProvider;

export const useMainContext = () => useContext(MainContext);
