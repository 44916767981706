import React from "react";
import { Link } from "react-router-dom";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";

import RichText from "../RichText/rich-text";
import { isLinkExternal } from "../../../../../app/src/utils/helpers";

import "./cta.less";

const CTA = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return <>No CTA to display</>;
  }

  const { content, buttonText, link, image } = document.getData();
  const imageSrc = page.getContent<ImageSet>(image);

  const [isExternal, url] = isLinkExternal(link);

  return (
    <Link
      to={{ pathname: url }}
      target={isExternal ? "_blank" : "_self"}
      rel={isExternal ? "noopener noreferrer" : ""}
      className="cta container"
    >
      <div className="image-container">
        <img
          src={imageSrc ? imageSrc.getOriginal().getUrl() : ""}
          alt="Non Available"
        />
      </div>
      <div className="content-container">
        <RichText content={content.value} />
      </div>
      <div className="button-container">
        <button className="dast-btn dast-btn-primary" type="button">
          {buttonText}
        </button>
      </div>
    </Link>
  );
};

export default CTA;
