import React, { FC, useState } from "react";
import "./home-page-card.main.less";

// DAST-282: This is a wrapper class, so children in HomePageCardProps could be anything
interface HomePageCardProps {
  children: any;
  justifyContent?: string;
  disableHover?: boolean;
}

const HomePageCard: FC<HomePageCardProps> = ({
  children,
  justifyContent,
  disableHover
}) => {
  let dependentStyle = {};
  if (justifyContent && justifyContent !== "center") {
    dependentStyle = { justifyContent };
  }

  return (
    <div
      className={`home-page-card${disableHover ? " no-hover-styles" : ""}`}
      style={dependentStyle}
    >
      {children}
    </div>
  );
};

export default HomePageCard;
