/**
 * @description
 * The purpose of this function is to surpass the limitations of the TS and to
 * use DOM event properties to look up in the select node for the selected option.
 * Also, we check if the physically clicked node is option or select element.
 *
 * Mozilla and Chrome have different values for the event target.
 */

// eslint-disable-next-line consistent-return
function getClickedOption(event) {
  if (event.nativeEvent.originalTarget) {
    // This property is specific for Mozilla
    const { target } = event.nativeEvent;
    const { nodeName } = event.nativeEvent.target;

    let selectedLimit;

    if (nodeName === "SELECT") {
      const selectedOption = [...target.children].find(
        opt => opt.selected === true
      );

      selectedLimit = selectedOption.value;
    } else if (nodeName === "OPTION") {
      selectedLimit = target.value;
    }

    return { selectedLimit, target: nodeName };
  }
  // Chrome

  const { target } = event.nativeEvent;

  let nodeName = "SELECT";

  if (!event.clientX && !event.client) {
    // clicked on option node
    nodeName = "OPTION";
  }

  const selectedOption = [...target.children].find(
    opt => opt.selected === true
  );

  return { selectedLimit: selectedOption.value, target: nodeName };
}

export default getClickedOption;
