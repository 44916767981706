/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import { getConfig } from "./ConfigProvider";

export function getSelectedLocaleValue(epConfig?) {
  const config = epConfig || getConfig().config;
  const storedLocaleValue = localStorage.getItem(
    `${config.cortexApi.scope}_locale`
  );

  return config.supportedLocales.filter(l => l.value === storedLocaleValue)
    .length > 0
    ? storedLocaleValue
    : config.defaultLocaleValue;
}

export function setSelectedLocaleValue(newLocale, epConfig?) {
  const supportedLocales = epConfig
    ? epConfig.supportedLocales
    : getConfig().config.supportedLocales;

  const scope = epConfig
    ? epConfig.cortexApi.scope
    : getConfig().config.cortexApi.scope;

  if (supportedLocales.filter(l => l.value === newLocale).length === 0) {
    throw new Error(
      `Locale ${newLocale} is not in the list of supported locales.`
    );
  }

  localStorage.setItem(`${scope}_locale`, newLocale);
}

export function getSelectedCurrencyValue(usersCurrency = undefined) {
  // const storedCurrencyValue = localStorage.getItem(
  //   `${getConfig().config.cortexApi.scope}_currency`
  // );

  return getConfig().config.supportedCurrencies.filter(
    c => c.value === usersCurrency
  ).length > 0
    ? usersCurrency
    : getConfig().config.defaultCurrencyValue;
}

export function setSelectedCurrencyValue(newCurrency) {
  if (
    getConfig().config.supportedCurrencies.filter(c => c.value === newCurrency)
      .length === 0
  ) {
    throw new Error(
      `Currency ${newCurrency} is not in the list of supported currencies.`
    );
  }

  localStorage.setItem(
    `${getConfig().config.cortexApi.scope}_currency`,
    newCurrency
  );
}
