/* eslint-disable camelcase */
import React, { FC, useState, useContext, useEffect } from "react";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import {
  formatPickupOrDeliveryAvailabilityLabel,
  InventoryAvailabilityInterface
} from "../../../app/src/utils/helpers";
import { MainContext } from "../../../app/src/contexts/MainContext";
import {
  PartDetails,
  PartsFinderSearchParams
} from "../../../app/src/containers/PartsFinderPage";
import AlternateBranchList from "../AlternateBranchList/AlternateBranchList";
import { getConfig } from "../utils/ConfigProvider";

import "../../../app/src/theme/sharedClasses.less";
import "./PartsFinderTableItem.less";

interface PartsFinderTableItemProps {
  part: PartDetails;
  price: string;
  entitled: boolean;
  handlePartChecked: (...any) => any;
  updateSelectedQuantity: (...any) => any;
  isMobile: boolean;
  history: any;
  searchParams: PartsFinderSearchParams;
  availability: Array<InventoryAvailabilityInterface>;
  handleAddProductToCart: (...any) => any;
  isProductBeingAdded: { sku: string; shippingMethod: string };
  isLoadingSelectedItemsToCart: boolean;
}

const PartsFinderTableItem: FC<PartsFinderTableItemProps> = ({
  part,
  price,
  entitled,
  handlePartChecked,
  updateSelectedQuantity,
  isMobile,
  history,
  searchParams,
  availability,
  handleAddProductToCart,
  isProductBeingAdded,
  isLoadingSelectedItemsToCart
}) => {
  const [quantity, setQuantity] = useState<string>("1");
  const [checked, setChecked] = useState<boolean>();
  const [openAlternateBranchModal, setOpenAlternateBranchModal] = useState<
    boolean
  >(false);
  const context = useContext<{
    cart: any;
    auth: any;
    user: any;
    branches: any;
  }>(MainContext);
  const { cart, auth, user, branches } = context;
  const {
    cartDetails: { defaultCart }
  } = cart;
  const { isLoggedIn } = auth;
  const {
    userProfile: { ecommerceBuyer }
  } = user;
  const { branchesList, findBranch } = branches;
  const { config } = getConfig();

  useEffect(() => {
    if (checked !== undefined) {
      handlePartChecked(part, quantity, price, checked);
    }
  }, [checked]);

  useEffect(() => {
    if (checked) {
      updateSelectedQuantity(part, quantity);
    }
  }, [quantity]);

  const onCheckboxClick = () => {
    setChecked(!checked);
  };

  const handleQuantityChange = e => {
    const { value } = e.target;
    setQuantity(value);
  };

  const checkQuantity = e => {
    const { value } = e.target;
    if (Number(value) <= 0) {
      setQuantity("1");
    }
  };

  const onAddToCart = (shippingMethod: "pickup" | "delivery") => {
    const { selectedBranch } = defaultCart;

    const partAvailability =
      availability &&
      availability.length &&
      availability.find(
        singlePartAvailabilityDetails =>
          part.pid === singlePartAvailabilityDetails.sku &&
          selectedBranch.code === singlePartAvailabilityDetails.branchNumber
      );
    if (
      partAvailability.regionAvailability &&
      !partAvailability.branchAvailability &&
      shippingMethod === "pickup"
    ) {
      openAlternateBranchesModal();
    } else {
      handleAddProductToCart(shippingMethod, part, quantity);
    }
  };

  const openAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(true);
  };

  const closeAlternateBranchesModal = () => {
    setOpenAlternateBranchModal(false);
  };

  const renderAlternateBranchesModal = () => {
    const productName = part.description;
    const productSkuCode = part.pid;
    const productBrand = part.brand;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={openAlternateBranchModal}
        onClose={closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            product={{
              name: productName,
              sku: productSkuCode,
              price,
              brand: productBrand
            }}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branchesList}
            itemQty={+quantity}
          />
        </div>
      </Modal>
    );
  };

  const buttonsDisabled =
    isProductBeingAdded &&
    isProductBeingAdded.sku.length !== 0 &&
    isProductBeingAdded.sku !== part.pid;
  const isPickupButtonLoading =
    isProductBeingAdded &&
    isProductBeingAdded.sku === part.pid &&
    isProductBeingAdded.shippingMethod === "pickup";
  const isDeliveryButtonLoading =
    isProductBeingAdded &&
    isProductBeingAdded.sku === part.pid &&
    isProductBeingAdded.shippingMethod === "delivery";

  const formatAvailability = () => {
    let activeBranch = "";
    let inventoryItem;
    if (defaultCart) {
      const { selectedBranch } = defaultCart;
      if (isLoggedIn) {
        activeBranch = findBranch(selectedBranch.code).branchName;
      }

      inventoryItem = (defaultCart &&
        availability &&
        availability.length &&
        availability.find(
          item =>
            item.sku === part.pid && item.branchNumber === selectedBranch.code
        )) || {
        branchAvailability: 0,
        regionAvailability: 0,
        dcAvailability: 0
      };
    }
    return formatPickupOrDeliveryAvailabilityLabel({
      branchAvailability: inventoryItem && inventoryItem.branchAvailability,
      regionAvailability: inventoryItem && inventoryItem.regionAvailability,
      currentBranch: activeBranch,
      isSingleLinedLabel: false,
      onLabelClick: openAlternateBranchesModal,
      buttonIsLoading:
        isLoadingSelectedItemsToCart ||
        isPickupButtonLoading ||
        isDeliveryButtonLoading ||
        buttonsDisabled,
      dcAvailability: inventoryItem && inventoryItem.dcQtyAvailable
    });
  };

  const contactAdminLabel = (
    <div className="label-availability">
      <span>{intl.get("contact-admin-to-purchase")}</span>
    </div>
  );

  const availabilityLabel = part.exists ? formatAvailability() : "";
  const partAvailabilityLabel = !ecommerceBuyer
    ? contactAdminLabel
    : availabilityLabel;

  const isPartInvalid =
    !part.exists ||
    !entitled ||
    !price ||
    price === "0" ||
    (config.checkAvailability &&
      partAvailabilityLabel === intl.get("call-for-availability"));

  const addToCartPickupBtnLabel = !ecommerceBuyer
    ? intl.get("contact-admin")
    : intl.get("pick-up");
  const addToCartDeliveryBtnLabel = !ecommerceBuyer
    ? intl.get("contact-admin")
    : intl.get("ship");

  const itemPriceLabel = price ? `$${price}` : "";
  const itemPrice = ecommerceBuyer ? itemPriceLabel : intl.get("pending");

  const disablePartForGuest = !price && !partAvailabilityLabel;

  const isDisabledAddPartForPickup =
    isPartInvalid ||
    !ecommerceBuyer ||
    isDeliveryButtonLoading ||
    buttonsDisabled ||
    isLoadingSelectedItemsToCart;

  const isDisabledAddPartForDelivery =
    isPartInvalid ||
    !ecommerceBuyer ||
    isPickupButtonLoading ||
    buttonsDisabled ||
    isLoadingSelectedItemsToCart;

  const renderForDesktop = () => {
    return (
      <div
        className={`part-table-row ${
          disablePartForGuest || (isPartInvalid && isLoggedIn) ? "disabled" : ""
        } ${!ecommerceBuyer ? "restricted-access-row" : ""}`}
      >
        {renderAlternateBranchesModal()}
        {isLoggedIn && (
          <div className="checkbox">
            <label
              className="part-checkbox-label"
              htmlFor="part-checkbox"
              id="part-checkbox-label"
            >
              <input type="checkbox" id="checkbox" />
              <span
                className={`part-checkbox ${
                  checked ? "show-checkmark" : "hide-checkmark"
                }`}
                onClick={onCheckboxClick}
                onKeyDown={onCheckboxClick}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="part-checkbox-label"
              />
            </label>
          </div>
        )}
        <div className="sku">
          <Link
            id={part.pid}
            to={{
              pathname: `/itemdetail/${part.pid}`,
              state: { partsFinderSearchParams: searchParams }
            }}
          >
            {part.pid}
          </Link>
        </div>
        <div className="image">
          <img
            src={part.full_image || config.missingImagePlaceholderUrl}
            alt={part.title || intl.get("none-available")}
            onError={e => {
              const element: any = e.target;
              element.src = config.missingImagePlaceholderUrl;
            }}
          />
        </div>
        <div className="description">{part.description}</div>
        {isLoggedIn && (
          <div className="quantity">
            {part.exists && (
              <span className="input-group-btn">
                <div className="quantity-col form-content form-content-quantity">
                  <input
                    className="form-control-quantity"
                    type="number"
                    min="1"
                    max="9999"
                    value={quantity}
                    onChange={handleQuantityChange}
                    onBlur={checkQuantity}
                  />
                </div>
              </span>
            )}
          </div>
        )}
        {isLoggedIn && partAvailabilityLabel}
        {isLoggedIn && <div className="price">{itemPrice}</div>}
        {isLoggedIn && (
          <div
            className={`add-to-cart-button ${
              isPartInvalid && isLoggedIn ? "disabled" : ""
            }`}
          >
            {!isPickupButtonLoading ? (
              <div className="pickup-button-wrapper">
                <button
                  type="button"
                  className="dast-btn dast-btn-primary"
                  onClick={() => {
                    onAddToCart("pickup");
                  }}
                  disabled={isDisabledAddPartForPickup}
                >
                  {addToCartPickupBtnLabel}
                </button>
              </div>
            ) : (
              <div className="miniLoader pickup-loader" />
            )}

            {!isDeliveryButtonLoading ? (
              <div className="delivery-button-wrapper">
                <button
                  type="button"
                  className="dast-btn dast-btn-primary"
                  onClick={() => {
                    onAddToCart("delivery");
                  }}
                  disabled={isDisabledAddPartForDelivery}
                >
                  {addToCartDeliveryBtnLabel}
                </button>
              </div>
            ) : (
              <div className="miniLoader delivery-loader" />
            )}
          </div>
        )}
      </div>
    );
  };

  const renderForMobile = () => {
    return (
      <div
        className={`mobile-part-table-row ${
          isPartInvalid && isLoggedIn ? "disabled" : ""
        }`}
      >
        {renderAlternateBranchesModal()}
        {isLoggedIn && (
          <div className="checkbox">
            <label
              className="part-checkbox-label"
              htmlFor="part-checkbox"
              id="part-checkbox-label"
            >
              <input type="checkbox" id="checkbox" />
              <span
                className={`part-checkbox ${
                  checked ? "show-checkmark" : "hide-checkmark"
                }`}
                onClick={onCheckboxClick}
                onKeyDown={onCheckboxClick}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="part-checkbox-label"
              />
            </label>
          </div>
        )}
        <div className="part-info">
          <div className="part-image">
            <img
              src={part.full_image || config.missingImagePlaceholderUrl}
              alt={part.title || intl.get("none-available")}
              onError={e => {
                const element: any = e.target;
                element.src = config.missingImagePlaceholderUrl;
              }}
            />
          </div>
          <div className="part-details">
            <p className="sku">
              <Link
                id={part.pid}
                to={{
                  pathname: `/itemdetail/${part.pid}`,
                  state: searchParams
                }}
              >
                {part.pid}
              </Link>
            </p>
            <p>{part.description}</p>
            {isLoggedIn && partAvailabilityLabel}
            {isLoggedIn && (
              <div className="price-and-quantity">
                <p className="price">{itemPrice}</p>
                {part.exists && (
                  <div className="quantity">
                    <span className="input-group-btn">
                      <div className="quantity-col form-content form-content-quantity">
                        <input
                          className="form-control-quantity"
                          type="number"
                          min="1"
                          max="9999"
                          value={quantity}
                          onChange={handleQuantityChange}
                          onBlur={checkQuantity}
                        />
                      </div>
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isLoggedIn && (
          <div className="add-to-cart-button ">
            {!isPickupButtonLoading ? (
              <div className="pickup-delivery-button-wrapper">
                <button
                  type="button"
                  className="dast-btn dast-btn-primary"
                  onClick={() => {
                    onAddToCart("pickup");
                  }}
                  disabled={isDisabledAddPartForPickup}
                >
                  {addToCartPickupBtnLabel}
                </button>
              </div>
            ) : (
              <div className="miniLoader" />
            )}
            {!isDeliveryButtonLoading ? (
              <div className="pickup-delivery-button-wrapper">
                <button
                  type="button"
                  className="dast-btn dast-btn-primary delivery-button"
                  onClick={() => {
                    onAddToCart("delivery");
                  }}
                  disabled={isDisabledAddPartForDelivery}
                >
                  {addToCartDeliveryBtnLabel}
                </button>
              </div>
            ) : (
              <div className="miniLoader" />
            )}
          </div>
        )}
      </div>
    );
  };

  return <>{!isMobile ? renderForDesktop() : renderForMobile()}</>;
};

export default PartsFinderTableItem;
