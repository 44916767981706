import React, { FC } from "react";

import "./info.card.component.less";

interface Props {
  name: string;
  value: string;
}

const InfoCardComponent: FC<Props> = props => {
  const { name, value } = props;
  return (
    <div className="info-card">
      <p className="label">{name}</p>
      <p className="value">{value}</p>
    </div>
  );
};

export default InfoCardComponent;
