import React, { useEffect, useState } from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { isLinkExternal } from "../../../../../app/src/utils/helpers";
import useWindowSize from "../../../CustomHooks/useWindowSize";

import "./global-carousel.less";

interface CarouselCard {
  image: any;
  mobileImage: any;
  buttonText: string;
  buttonLink: string;
  title: string;
  alignment: string;
  content: string;
  backgroundImage: string;
}

interface MultipleItemsCarouselProps extends BrProps {
  configuration: any;
  pageModel: any;
  preview: any;
}

const GlobalCarousel: React.FC<MultipleItemsCarouselProps> = ({
  component,
  page
}) => {
  const [width] = useWindowSize();
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);
  const mobileBreakpoint = 768;
  const [renderCarousel, setRenderCarousel] = useState(false);

  useEffect(() => {
    setRenderCarousel(true);
  }, []);

  if (!document) {
    return <>No Global Carousel to display</>;
  }

  const { carouselCards, rightCard } = document.getData();

  const globalCarouselCards = carouselCards.map(card => {
    const imageSrc = page.getContent<ImageSet>(card.image);
    const mobileImageSrc = page.getContent<ImageSet>(card.mobileImage);
    if (width < mobileBreakpoint) {
      if (mobileImageSrc) {
        // eslint-disable-next-line no-param-reassign
        card.backgroundImage = mobileImageSrc.getOriginal().getUrl();
      } else if (imageSrc) {
        // eslint-disable-next-line no-param-reassign
        card.backgroundImage = imageSrc.getOriginal().getUrl();
      } else {
        // eslint-disable-next-line no-param-reassign
        card.backgroundImage = null;
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      card.backgroundImage = imageSrc ? imageSrc.getOriginal().getUrl() : null;
    }
    return card;
  });

  const rcImageSrc = page.getContent<ImageSet>(rightCard.image);
  const rcMobileImageSrc = page.getContent<ImageSet>(rightCard.mobileImage);
  if (width < mobileBreakpoint) {
    if (rcMobileImageSrc) {
      rightCard.backgroundImage = rcMobileImageSrc.getOriginal().getUrl();
    } else if (rcImageSrc) {
      rightCard.backgroundImage = rcImageSrc.getOriginal().getUrl();
    } else {
      rightCard.backgroundImage = null;
    }
  } else {
    rightCard.backgroundImage = rcImageSrc
      ? rcImageSrc.getOriginal().getUrl()
      : null;
  }

  const renderCardContent = (card: CarouselCard, index: number) => {
    const backgroundImageStyle = {
      backgroundImage: card.backgroundImage
        ? `url(${card.backgroundImage})`
        : ""
    };
    return (
      <div
        className={`carousel-card-wrapper ${
          width < mobileBreakpoint ? "carousel-mobile-wrapper" : ""
        }`}
      >
        {width >= mobileBreakpoint ? (
          <div
            className="carousel-background-image"
            style={backgroundImageStyle}
          >
            {card.title && <div className="carousel-overlay" />}
            <div
              className={`carousel-card-content ${
                card.alignment === "Left" || card.alignment === "Right"
                  ? `alignment-${card.alignment}`
                  : "alignment-center"
              }`}
            >
              {card.title && <h2>{card.title}</h2>}
              {card.content && <p>{card.content}</p>}
              {card.buttonText && (
                <button
                  className={`carousel-card-button dast-btn dast-btn-primary ${
                    card.title && card.content ? "" : "no-content-btn"
                  }`}
                  type="button"
                >
                  {card.buttonText}
                </button>
              )}
            </div>
          </div>
        ) : (
          <>
            {card.title && <div className="carousel-overlay" />}
            <div className="carousel-mobile-content">
              {card.title && <h2>{card.title}</h2>}
              {card.content && <p>{card.content}</p>}
              {card.buttonText && (
                <button
                  className={`carousel-card-button dast-btn dast-btn-primary ${
                    card.title && card.content ? "" : "no-content-btn"
                  }`}
                  type="button"
                >
                  {card.buttonText}
                </button>
              )}
            </div>
            {card.backgroundImage && (
              <div className="mobile-image-wrapper">
                <img alt={card.title} src={card.backgroundImage} />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderCard = (card: CarouselCard, index: number) => {
    const [isExternal, url] = isLinkExternal(card.buttonLink);
    return (
      <div key={`left-carousel-card-${index}`}>
        {card.buttonLink ? (
          <Link
            to={isExternal ? { pathname: url } : url}
            target={isExternal ? "_blank" : "_self"}
            rel={isExternal ? "noopener noreferrer" : ""}
            aria-label={`carousel-link-${index}`}
          >
            {renderCardContent(card, index)}
          </Link>
        ) : (
          <div>{renderCardContent(card, index)}</div>
        )}
      </div>
    );
  };

  return (
    <div className="global-carousel-widget">
      <div
        className={`global-carousel-container container ${
          width < 768 ? "mobile-carousel" : ""
        }`}
      >
        {renderCarousel && (
          <>
            {globalCarouselCards.length > 1 ? (
              <Slider
                className="global-carousel"
                slidesToShow={1}
                dots
                arrows={false}
                dotsClass="global-carousel-pagination"
                draggable
                pauseOnDotsHover
                pauseOnFocus
                pauseOnHover
                autoplay
                autoplaySpeed={3000}
              >
                {globalCarouselCards.map((carouselCard, index) =>
                  renderCard(carouselCard, index)
                )}
              </Slider>
            ) : (
              <>
                {globalCarouselCards.map((carouselCard, index) =>
                  renderCard(carouselCard, index)
                )}
              </>
            )}

            {renderCard(rightCard, 0)}
          </>
        )}
      </div>
    </div>
  );
};

export default GlobalCarousel;
