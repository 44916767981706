import React, { useState } from "react";
import intl from "react-intl-universal";
import { getMotiliBranches } from "../services/daikinServices";
import { checkTokensExpired } from "../utils/helpers";
import { getBranchesGQL } from "../services/connectGQLservices";

// eslint-disable-next-line import/prefer-default-export
export const BranchContext = () => {
  const [branchesList, setBranchesList] = useState();
  const [branchError, setBranchError] = useState(false);
  const [branchesErrorCode, setBranchesErrorCode] = useState("");
  const [branchesErrorMessage, setBranchesErrorMessage] = useState("");
  const [branchesInContext, setBranchesInContext] = useState(false);
  const airPurifierBranch = {
    divisionCode: "CEN",
    divisionName: "CENTRAL DIVISION",
    regionCode: "GMW",
    regionName: "GOODMAN MIDWEST",
    branchNumber: "300",
    branchName: "ST. LOUIS BRANCH #300",
    addressee: "DAIKIN COMFORT TECHNOLOGY #300",
    address1: "1430 SOUTH 3RD STREET",
    otherContact: "P:314-241-5922 F:314-241-0364",
    city: "St. Louis",
    state: "MO",
    zip: "63104",
    branchType: "ACT",
    address: "1430 South 3rd Street",
    country: "US",
    formattedAddress: "1430 S 3rd St, St. Louis, MO 63104, USA",
    geocoded: true,
    location: {
      type: "Point",
      coordinates: [-90.19560419999999, 38.6106996]
    },
    latitude: 38.6106996,
    longitude: -90.19560419999999,
    email: "BR300@daikincomfort.com",
    timezone: "America/Chicago",
    phone: "314-241-5922",
    fax: "314-241-0364",
    tags: ["ECOMMERCE"]
  };

  /**
   * ## formatMotiliBranches
   *
   * @param branches
   *
   * @description Function that maps through list of entitled branches for Motili site and
   * formats branches to match the response for other sites
   */
  const formatMotiliBranches = branches => {
    return branches.map(branch => {
      return {
        ...branch,
        branchNumber: branch.number,
        branchName: `${branch.name} #${branch.number}`,
        regionName: branch.region,
        formattedAddress: `${branch.address.address}, ${branch.address.city}, ${branch.address.region} ${branch.address.zip}`,
        distributor: branch.vendorName.toUpperCase(),
        address: branch.address.address,
        city: branch.address.city,
        state: branch.address.region,
        zip: branch.address.zip,
        email:
          branch.vendorName.toUpperCase() === "GOODMAN"
            ? `BR${branch.number}@daikincomfort.com`
            : ""
      };
    });
  };

  /**
   * ## findHomeBranch
   *
   * @param homeBranch
   * @param branches
   *
   * @description Function that checks whether a given branch number is an entitled branch
   */

  const findHomeBranch = (homeBranch, branches) => {
    const homeBranchNotInBranchesErrorCode = intl.get(
      "home-branch-not-in-branches-list-error-code"
    );
    const homeBranchNotInBranchesErrorMsg = intl.get("unentitled-home-branch");
    if (homeBranch) {
      const foundHomeBranch = branches.find(branch => {
        return branch.branchNumber === homeBranch;
      });
      if (!foundHomeBranch) {
        setBranchesErrorCode(homeBranchNotInBranchesErrorCode);
        setBranchesErrorMessage(homeBranchNotInBranchesErrorMsg);
      }
    }
  };

  /**
   * ## fetchBranches
   *
   * @param options
   *
   * @description Function that calls DCS service for entitled branches, for Goodman, Daikin and Amana sites
   */
  const fetchBranches = (homeBranch, customerNumber) => {
    if (customerNumber) {
      getBranchesGQL(customerNumber)
        .then(branchesResponse => {
          if (
            branchesResponse.data &&
            branchesResponse.data.data &&
            branchesResponse.data.data.customer &&
            branchesResponse.data.data.customer.branchEntitlements
          ) {
            const branches =
              branchesResponse.data.data.customer.branchEntitlements;

            const noEntitledBranchesErrorCode = intl.get(
              "no-entitled-branches-error-code"
            );
            const noEntitledBranchesErrorMsg = intl.get(
              "unentitled-home-branch"
            );

            if (!branches.length) {
              setBranchesErrorCode(noEntitledBranchesErrorCode);
              setBranchesErrorMessage(noEntitledBranchesErrorMsg);
            } else {
              setBranchesList(branches);
              setBranchesInContext(true);
              findHomeBranch(homeBranch, branches);
            }
          }
        })
        .catch(err => {
          const entitledBranchesTimeout = intl.get("entitled-branches-timeout");
          // If tokens are expired delegate session expired handling to
          // appheader.main fetchNavigationData
          if (!checkTokensExpired(err)) {
            if (
              err.response &&
              err.response.status >= 500 &&
              err.response.status < 600
            ) {
              setBranchesErrorCode(entitledBranchesTimeout);
              setBranchesErrorMessage(err.message);
            } else {
              setBranchError(err.message);
            }
          }
        });
    }
  };

  /**
   * ## fetchMotiliBranches
   *
   * @param motiliCompanyId
   *
   * @description Function that calls DCS service for entitled branches, for Motili site
   */
  const fetchMotiliBranches = motiliCompanyId => {
    getMotiliBranches(motiliCompanyId)
      .then(branchesResponse => {
        const branches = formatMotiliBranches(branchesResponse.data);
        const noEntitledBranchesErrorCode = intl.get(
          "no-entitled-branches-error-code"
        );
        const noEntitledBranchesErrorMsg = intl.get("unentitled-home-branch");
        if (!branches.length) {
          setBranchesErrorCode(noEntitledBranchesErrorCode);
          setBranchesErrorMessage(noEntitledBranchesErrorMsg);
        } else {
          setBranchesList(branches);
          setBranchesInContext(true);
        }
      })
      .catch(err => {
        const entitledBranchesTimeout = intl.get("entitled-branches-timeout");
        // If tokens are expired delegate session expired handling to
        // appheader.main fetchNavigationData
        if (!checkTokensExpired(err)) {
          if (
            err.response &&
            err.response.status >= 500 &&
            err.response.status < 600
          ) {
            setBranchesErrorCode(entitledBranchesTimeout);
            setBranchesErrorMessage(err.message);
          } else {
            setBranchError(err.message);
          }
        }
      });
  };

  /**
   * ## findBranch
   *
   * @param branchNumber
   *
   * @description Function that checks whether a given branch number is an entitled branch
   */

  const findBranch = branchNumber => {
    if (branchesList) {
      return branchesList.find(branch => {
        return branch.branchNumber === branchNumber;
      });
    }
    return undefined;
  };

  return {
    branchesList,
    fetchBranches,
    findBranch,
    branchError,
    branchesErrorCode,
    branchesErrorMessage,
    fetchMotiliBranches,
    branchesInContext,
    setBranchesInContext,
    findHomeBranch,
    airPurifierBranch
  };
};
