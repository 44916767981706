import React, { FC } from "react";
import { Link } from "react-router-dom";

import { isLinkExternal } from "../../../app/src/utils/helpers";
import useWindowSize from "../CustomHooks/useWindowSize";
import GridCardInterface from "./types/GridCardInterface";

interface PromotionsGridCardProps {
  gridCard: GridCardInterface;
  isLoggedIn: boolean;
}

const PromotionsGridCard: FC<PromotionsGridCardProps> = ({
  gridCard,
  isLoggedIn
}) => {
  const [width, height] = useWindowSize();
  const {
    image,
    topTitle,
    mainTitle,
    bottomTitle,
    id: cardId,
    type,
    cardWidth,
    backgroundColor,
    textColor,
    borderColor,
    description,
    backgroundImage: desktopBackgroundImage,
    isLinkForLoggedInUserOnly,
    link,
    mobileBackgroundImage
  } = gridCard;

  const getLink = () => {
    if (!isLoggedIn && isLinkForLoggedInUserOnly) return "/login";
    return link;
  };

  const [isExternal, url] = isLinkExternal(getLink());

  const backgroundImage =
    width <= 768
      ? mobileBackgroundImage || desktopBackgroundImage
      : desktopBackgroundImage;

  const style = {
    flex: `${cardWidth} 0 0`,
    color: textColor,
    border: borderColor ? `1px solid ${borderColor}` : "",
    backgroundColor,
    backgroundImage: `url(${backgroundImage})`
  };

  return (
    <Link
      to={{
        pathname: url,
        state: { prevUrl: window.location.href }
      }}
      target={!isExternal ? "_self" : "_blank"}
      rel={isExternal ? "noopener noreferrer" : ""}
      className={`grid-card ${type} ${
        backgroundImage ? "card-with-bkg" : ""
      } grid-card-link`}
      id={cardId}
      key={cardId}
      style={style}
    >
      {topTitle && <h2 className="grid-card-top-title">{topTitle}</h2>}
      {image && <img className="grid-card-image" src={image} alt={mainTitle} />}

      {mainTitle && <h2 className="grid-card-title">{mainTitle}</h2>}
      {bottomTitle && <h2 className="grid-card-bottom-title">{bottomTitle}</h2>}
      {description && <p className="grid-card-description">{description}</p>}
    </Link>
  );
};

export default PromotionsGridCard;
