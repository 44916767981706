import React, { FC, useState, useEffect, useContext } from "react";
import intl from "react-intl-universal";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  checkResponse,
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { cortexFetch } from "../utils/Cortex";

import "./ClientDropdownComponent.less";

const ClientDopdownComponent: FC<RouteComponentProps> = ({
  history,
  match,
  location
}) => {
  const context = useContext<{ auth: any; user: any; cart: any; client: any }>(
    MainContext
  );
  const {
    auth: { loguout },
    user: {
      userProfile: { clientList }
    },
    cart: {
      cartDetails: { defaultCart },
      getCartDetails
    },
    client: { persistedClient, setPersistedClient }
  } = context;
  const initialClient =
    defaultCart.clientId && defaultCart.clientName
      ? { id: defaultCart.clientId, name: defaultCart.name }
      : clientList[0];
  const [selectedClient, setSelectedClient] = useState(initialClient);

  const { url } = match;
  const { pathname } = location;

  // When user completes order, we will automatically associate next order with
  // previosily selected client, that we persisted in the context
  useEffect(() => {
    if (persistedClient.id && !defaultCart.clientId) {
      setSelectedClient({ ...persistedClient });
    }
  }, [persistedClient.id, defaultCart.clientId]);

  useEffect(() => {
    if (
      defaultCart.clientId &&
      defaultCart.clientName &&
      defaultCart.clientId !== selectedClient.id
    ) {
      setSelectedClient({
        id: defaultCart.clientId,
        name: defaultCart.clientName
      });
    }
  }, [defaultCart.clientId, defaultCart.clientName]);

  useEffect(() => {
    if (defaultCart.clientId !== selectedClient.id) {
      updateClientOnCurrentOrder();
    }
  }, [selectedClient]);

  const updateClientOnCurrentOrder = () => {
    const link = defaultCart.cartOrderDetailsForm.links[0].uri;

    const body = {
      ...defaultCart.cartOrderDetailsForm,
      "update-mode": "CLIENT",
      "client-id": selectedClient.id,
      "client-name": selectedClient.name
    };

    cortexFetch(link, {
      method: "post",
      body: JSON.stringify(body)
    })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        getCartDetails();
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          loguout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn:
                "Logout => updateClientOnCurrentOrder => ClientDropdownComponent.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "updateClientOnCurrentOrder => ClientDropdownComponent.tsx"
          });
        }
      });
  };

  const onClientSelect = e => {
    const newSelectedClient = clientList.find(
      client => client.id === e.target.value
    );
    setSelectedClient(newSelectedClient);
  };

  return (
    <div className="client-dropdown">
      <span>{`${intl.get("client")}: `}</span>
      <select
        name="client-select"
        onChange={onClientSelect}
        value={selectedClient.id}
        disabled={url.includes("purchaseReceipt") || pathname === "/order"}
      >
        {clientList.map(client => (
          <option className="client-option" key={client.id} value={client.id}>
            {`${client.name} ${client.id}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default withRouter(ClientDopdownComponent);
