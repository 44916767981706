/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { withRouter, Redirect } from "react-router";
import {
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { login } from "../utils/AuthService";
import { cortexFetch } from "../utils/Cortex";
import { MainContext } from "../../../app/src/contexts/MainContext";
import "./checkout.summarylist.less";

let Config: IEpConfig | any = {};
let intl = { get: str => str };

interface CheckoutSummaryListProps {
  data: {
    [key: string]: any;
  };
  giftCards?: any[];
  onChange?: (...args: any[]) => any;
  history: any;
  auth: any;
}

interface CheckoutSummaryListState {
  defaultCart: any;
}
class CheckoutSummaryList extends React.Component<
  CheckoutSummaryListProps,
  CheckoutSummaryListState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    ({ intl } = epConfig);

    this.state = {
      defaultCart: undefined
    };
  }

  componentDidMount() {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;

    this.setState({ defaultCart });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const oldOtherChargesDisplay = this.getPropsSafely(
      () => prevState.defaultCart.otherCharges.display
    );
    const otherChargesDisplay = this.getPropsSafely(
      // eslint-disable-next-line react/destructuring-assignment
      () => defaultCart.otherCharges.display
    );
    if (otherChargesDisplay && oldOtherChargesDisplay !== otherChargesDisplay) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ defaultCart });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getPropsSafely(fn) {
    try {
      return fn();
    } catch (e) {
      return undefined;
    }
  }

  deletePromotionCode(link) {
    const {
      history,
      auth: { logout },
      onChange
    } = this.props;

    login().then(() => {
      cortexFetch(link, {
        method: "delete"
      })
        .then(() => {
          onChange();
        })
        .catch(e => {
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn:
                  "Logout => fetchAccountStatement => CheckoutSummaryList.tsx"
              })
            );
          } else {
            pushToMaintenace(history, {
              e,
              errIn: "fetchAccountStatement => CheckoutSummaryList.tsx"
            });
          }
        });
    });
  }

  // renderCoupons() {
  //   const { data } = this.props;
  //   if (
  //     data._order &&
  //     data._order[0] &&
  //     data._order[0]._couponinfo[0]._coupon
  //   ) {
  //     return (
  //       <li className="cart-coupons" data-region="cartAppliedPromotionsRegion">
  //         <label
  //           htmlFor="cart-applied-promotions"
  //           className="cart-summary-label-col"
  //         >
  //           {intl.get("applied-coupons")}
  //           :&nbsp;
  //         </label>
  //         <br />
  //         {data._order[0]._couponinfo[0]._coupon.map(coupon => (
  //           <div className="promotions-table" key={coupon.code}>
  //             <div className="row">
  //               <div className="col-6 promotion-display">{coupon.code}</div>
  //               <div className="col-6 promotion-remove">
  //                 <button
  //                   type="button"
  //                   className="cart-remove-promotion"
  //                   onClick={() => {
  //                     this.deletePromotionCode(coupon.self.uri);
  //                   }}
  //                   data-actionlink=""
  //                 >
  //                   {intl.get("remove")}
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </li>
  //     );
  //   }
  //   return null;
  // }

  // renderPromotions() {
  //   const { data } = this.props;
  //   if (data._appliedpromotions) {
  //     return (
  //       <li
  //         className="cart-applied-promotions"
  //         data-region="cartAppliedPromotionsRegion"
  //       >
  //         <label
  //           htmlFor="cart-applied-promotions"
  //           className="cart-summary-label-col"
  //         >
  //           {intl.get("applied-promotions")}
  //           :&nbsp;
  //         </label>
  //         <br />
  //         {data._appliedpromotions[0]._element.map(promotion => (
  //           <span
  //             className="cart-summary-value-col cart-applied-promotions"
  //             key={promotion.name}
  //           >
  //             {promotion["display-name"]
  //               ? promotion["display-name"]
  //               : promotion.name}
  //           </span>
  //         ))}
  //       </li>
  //     );
  //   }
  //   return null;
  // }

  renderDiscount() {
    const { data } = this.props;
    if (data._discount) {
      return (
        <li className="cart-discount">
          <label
            htmlFor="cart-summary-value-col"
            className="cart-summary-label-col"
          >
            {intl.get("todays-discount")}
            :&nbsp;
          </label>
          <span className="cart-summary-value-col">
            {data._discount[0].discount[0].display}
          </span>
        </li>
      );
    }
    return null;
  }

  renderShipping() {
    const { data } = this.props;
    if (
      data._order &&
      data._order[0]._deliveries &&
      data._order[0]._deliveries[0]._element[0]._shippingoptioninfo
    ) {
      const shippingOption = data._order[0]._deliveries[0]._element[0]
        ._shippingoptioninfo[0]._shippingoption
        ? data._order[0]._deliveries[0]._element[0]._shippingoptioninfo[0]
            ._shippingoption[0].cost[0].display
        : "";
      return (
        <li className="checkout-shipping">
          <div data-region="checkoutShippingTotalRegion">
            <div className="checkout-shipping-total">
              <label
                className="cart-summary-label-col"
                htmlFor="checkout-shipping-total"
              >
                {intl.get("todays-shipping-cost")}
                :&nbsp;
              </label>
              <span>{shippingOption}</span>
            </div>
          </div>
        </li>
      );
    }
    return null;
  }

  renderTax() {
    const { data } = this.props;
    if (
      data._order &&
      data._order[0]._tax &&
      data._order[0]._tax[0].cost.length
    ) {
      return (
        <li className="checkout-tax">
          <div
            data-region="checkoutTaxTotalRegion"
            style={{ display: "block" }}
          >
            <div className="checkout-tax-total">
              <label
                className="cart-summary-label-col"
                htmlFor="checkout-tax-total"
              >
                {intl.get("todays-taxes")}
                :&nbsp;
              </label>
              <span>{data._order[0]._tax[0].total.display}</span>
            </div>
          </div>
          <div
            data-region="checkoutTaxBreakDownRegion"
            style={{ display: "block" }}
          >
            <ul className="checkout-tax-list">
              {data._order[0]._tax[0].cost.map(tax => (
                <li className="checkout-tax" key={tax.title}>
                  <label
                    className="cart-summary-label-col"
                    htmlFor="checkout-tax"
                  >
                    {tax.title}
                    :&nbsp;
                  </label>
                  <span>{tax.display}</span>
                </li>
              ))}
            </ul>
          </div>
        </li>
      );
    }
    return null;
  }

  renderCheckoutTotal() {
    const { data, giftCards } = this.props;
    let giftCardsAmount = 0;
    if (Array.isArray(giftCards)) {
      giftCardsAmount = giftCards
        .map(el => el.balance)
        .reduce((total, amount) => total + amount, 0);
    }
    if (data._order && data._order[0]._total) {
      return (
        <li className="checkout-total">
          <label className="cart-summary-label-col" htmlFor="checkout-total">
            {intl.get("todays-total")}
            :&nbsp;
          </label>
          <span data-el-value="checkout.total">
            {giftCardsAmount > 0
              ? `$${Math.max(
                  0,
                  data._order[0]._total[0].cost[0].amount - giftCardsAmount
                ).toFixed(2)}`
              : data._order[0]._total[0].cost[0].display}
          </span>
        </li>
      );
    }
    return null;
  }

  renderChosenGiftCardsTotal() {
    const { data, giftCards } = this.props;
    let giftCardsAmount = 0;
    if (Array.isArray(giftCards)) {
      giftCardsAmount = giftCards
        .map(el => el.balance)
        .reduce((total, amount) => total + amount, 0);
    }
    if (giftCardsAmount > 0) {
      return (
        <li className="gift-card">
          <label className="cart-summary-label-col" htmlFor="gift-card-total">
            {intl.get("gift-card")}
            :&nbsp;
          </label>
          <span data-el-value="gift-card.total">
            ${giftCardsAmount}
            &nbsp; - &nbsp;
            {data._order[0]._total[0].cost[0].display}
          </span>
        </li>
      );
    }
    return null;
  }

  render() {
    const { data, giftCards } = this.props;
    const { defaultCart } = this.state;

    const {
      user: { userProfile }
    } = this.context;

    let shippingOption = null;

    let orderTaxTotal = null;
    let orderTaxGst = null;

    let giftCardsAmount = 0;
    if (Array.isArray(giftCards)) {
      giftCardsAmount = giftCards
        .map(el => el.balance)
        .reduce((total, amount) => total + amount, 0);
    }

    let subtotal;
    let orderCost;
    let todaysTotal;
    let totalQuantity;
    let otherCharges;
    try {
      if (
        data._order &&
        data._order[0]._tax &&
        data._order[0]._tax[0].cost &&
        data._order[0]._tax[0].cost.length
      ) {
        orderTaxTotal = data._order[0]._tax[0].cost.find(
          costItem => costItem.title === "TAX"
        );
        if (userProfile.isCanadianUser) {
          orderTaxGst = data._order[0]._tax[0].cost.find(
            costItem => costItem.title === "GST"
          );
        }
      }
      if (
        data._order &&
        data._order[0]._deliveries &&
        data._order[0]._deliveries[0]._element[0]._shippingoptioninfo
      ) {
        shippingOption = data._order[0]._deliveries[0]._element[0]
          ._shippingoptioninfo[0]._shippingoption
          ? data._order[0]._deliveries[0]._element[0]._shippingoptioninfo[0]
              ._shippingoption[0].cost[0].display
          : null;
      }
      subtotal = data.total
        ? data.total.display
        : data._total[0].cost[0].display;
      orderCost = data.orderCost
        ? data.orderCost
        : data._order[0]._total[0].cost[0];
      todaysTotal =
        giftCardsAmount > 0
          ? `$${Math.max(0, orderCost.amount - giftCardsAmount).toFixed(2)}`
          : orderCost.display;
      totalQuantity = data.totalQuantity
        ? data.totalQuantity
        : data["total-quantity"];
      otherCharges =
        data._order &&
        data._order[0]["_other-charges"] &&
        data._order[0]["_other-charges"][0] &&
        data._order[0]["_other-charges"][0].other
          ? data._order[0]["_other-charges"][0].other.display
          : "$0.00";
    } catch (e) {
      return (
        <Redirect
          to={{
            pathname: "/maintenance",
            state: { error: { e, errIn: "CheckoutSummuryList.tsx" } }
          }}
        />
      );
    }

    return (
      <>
        <div className="checkout-summary-list-container d-flex flex-column">
          <div className="d-flex align-items-center">
            <div className="flex-fill summary-label">
              {intl.get("total-quantity")}:
            </div>
            <div>{totalQuantity}</div>
          </div>

          {/* {data.appliedPromotions && (
            <div className="d-flex align-items-center">
              <div className="flex-fill summary-label">
                {intl.get("applied-promotions")}:
              </div>
              <div>
                {data._appliedpromotions[0]._element.map(promotion => (
                  <p>
                    {promotion["display-name"]
                      ? promotion["display-name"]
                      : promotion.name}
                  </p>
                ))}
              </div>
            </div>
          )} */}

          {Config.calculatePrice && (
            <div className="d-flex align-items-center">
              <div className="flex-fill summary-label">
                {intl.get("todays-subtotal")}:
              </div>
              <div>{subtotal}</div>
            </div>
          )}

          {Config.calculatePrice && orderTaxTotal && (
            <div className="d-flex">
              <div className="flex-fill summary-label">
                {userProfile.isCanadianUser
                  ? intl.get("canada-pst-tax").toUpperCase()
                  : intl.get("tax")}
              </div>
              <div>{orderTaxTotal.display}</div>
            </div>
          )}
          {Config.calculatePrice && userProfile.isCanadianUser && orderTaxGst && (
            <div className="d-flex">
              <div className="flex-fill summary-label">
                {intl.get("gst-tax").toUpperCase()}
              </div>
              <div>{orderTaxGst.display}</div>
            </div>
          )}
          {Config.calculatePrice && userProfile.isCanadianUser && orderTaxGst && (
            <div className="d-flex">
              <div className="flex-fill summary-label">
                {intl.get("other-charges").toUpperCase()}
              </div>
              <div>{otherCharges}</div>
            </div>
          )}
          {Config.calculatePrice && data._order && data._order[0]._total && (
            <div className="d-flex align-items-center summary-total-container">
              <div className="flex-fill summary-label">
                {intl.get("todays-total")}:
              </div>
              <div className="summary-total">{todaysTotal}</div>
            </div>
          )}
        </div>

        {/* <ul className="cart-summary-list">
          <li className="cart-total-quantity">
            <label
              htmlFor="cart-summary-value-col"
              className="cart-summary-label-col"
            >
              {intl.get("total-quantity")}
              :&nbsp;
            </label>
            <span className="cart-summary-value-col">
              {data["total-quantity"]}
            </span>
          </li>
          {this.renderPromotions()}
          {this.renderCoupons()}
          <li className="cart-subtotal">
            <label
              htmlFor="cart-summary-value-col"
              className="cart-summary-label-col"
            >
              {intl.get("todays-subtotal")}
              :&nbsp;
            </label>
            <span className="cart-summary-value-col">
              {data._total[0].cost[0].display}
            </span>
          </li>
          {this.renderDiscount()}
          {this.renderShipping()}
          {this.renderTax()}
          {this.renderChosenGiftCardsTotal()}
          {this.renderCheckoutTotal()}
        </ul> */}
      </>
    );
  }
}

export default CheckoutSummaryList;
