import React, { useContext, useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import {
  checkTokensExpired,
  pushToMaintenace
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { changeBranchAndVendorOnCurrentOrder } from "../../../app/src/services/EpServices";

import "./BranchDropdownComponent.less";
import BranchChangeComponent from "../BranchChange/BranchChangeComponent";

interface BranchDropdownProps extends RouteComponentProps {
  history: any;
  location: any;
  match: any;
  selectedBranch: string;
  isMobile?: boolean;
  isCheckout?: boolean;
  hideBranchLabel?: boolean;
  shouldRenderSpan?: boolean;
}

const BranchDropdownComponent: React.FC<BranchDropdownProps> = ({
  history,
  location,
  match,
  selectedBranch,
  isMobile,
  isCheckout,
  hideBranchLabel,
  shouldRenderSpan
}) => {
  const [selectedBranchNumber, setSelectedBranchNumber] = useState(
    selectedBranch
  );
  const [tempBranchNumber, setTempBranchNumber] = useState<string>("");
  const [updateLoader, setUpdateLoader] = useState(false);
  const [openChangeBranchModal, setOpenChangeBranchModal] = useState(false);
  const [confirmButtonIsLoading, setConfirmButtonIsLoading] = useState(false);

  const mainContext = useContext<{
    auth: any;
    user: any;
    cart: any;
    branches: any;
    account: any;
  }>(MainContext);

  const {
    account: {
      accountDetails: { customerRoles }
    },
    cart: {
      cartDetails: { defaultCart },
      getCartDetails
    },
    auth: { logout },
    branches: { branchesList, findBranch }
  } = mainContext;
  const { cartOrderDetailsForm, items } = defaultCart;

  const isCartAssociatedToContract =
    items &&
    items.length &&
    cartOrderDetailsForm["contract-number"] &&
    cartOrderDetailsForm.pricing.toUpperCase() === "N";

  const BRANCHES_VIRTUAL = intl.get("virtual-branches");
  const isVirtualBranchUser =
    customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

  const { url } = match;
  const { pathname } = location;
  const { config }: { config: IEpConfig } = getConfig();

  useEffect(() => {
    if (
      config.branchDropDownLocation === "header" &&
      branchesList &&
      branchesList.length &&
      selectedBranchNumber !== defaultCart.selectedBranch.code
    ) {
      changeBranch();
    }
  }, [selectedBranchNumber]);

  useEffect(() => {
    setSelectedBranchNumber(defaultCart.selectedBranch.code);
  }, [defaultCart.selectedBranch.code]);

  const changeBranch = () => {
    const branch = findBranch(selectedBranchNumber);
    const fetchLink = cartOrderDetailsForm.links[0].uri;
    setConfirmButtonIsLoading(true);

    changeBranchAndVendorOnCurrentOrder(branch, fetchLink)
      .then(() => {
        sessionStorage.setItem("notified-branch", "true");
        return getCartDetails();
      })
      .then(() => {
        setUpdateLoader(false);
        setConfirmButtonIsLoading(false);
        setOpenChangeBranchModal(false);
      })
      .catch((e: any) => {
        setConfirmButtonIsLoading(false);
        setOpenChangeBranchModal(false);

        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => changeBranch => BranchDropdownComponent.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "changeBranch => BranchDropdownComponent.tsx"
          });
        }
      });
  };

  const closeChangeBranchModal = () => {
    setOpenChangeBranchModal(false);
  };

  const confirmUserSelection = (): void => {
    setSelectedBranchNumber(tempBranchNumber);
  };

  const renderBranchChangeModal = () => {
    return (
      <Modal open={openChangeBranchModal} onClose={closeChangeBranchModal}>
        <BranchChangeComponent
          userSelectionConfirmed={confirmUserSelection}
          isLoading={confirmButtonIsLoading}
        />
      </Modal>
    );
  };

  const renderBranchLabel = () => {
    if (isMobile) {
      return <>{intl.get("branch")}</>;
    }
    if (isCheckout) {
      return <>{intl.get("delivery-branch-label")}: </>;
    }
    return <>{intl.get("current-branch")}: </>;
  };

  const onBranchSelection = e => {
    const { value: branchNumber } = e.target;
    setTempBranchNumber(branchNumber);
    setOpenChangeBranchModal(true);
  };
  if (branchesList && branchesList.length && !isVirtualBranchUser) {
    const selectedBranchName = branchesList.find(
      branch => branch.branchNumber === selectedBranchNumber
    );

    return (
      <div className="branch-dropdown">
        {!hideBranchLabel && (
          <label htmlFor="branch-dropdown">{renderBranchLabel()}</label>
        )}
        {shouldRenderSpan ? (
          <span className="branch-dropdown-span">
            {selectedBranchName ? selectedBranchName.branchName : ""}
          </span>
        ) : (
          <select
            name="branch-dropdown"
            id="branch-dropdown"
            onChange={onBranchSelection}
            value={selectedBranchNumber}
            disabled={
              url.includes("purchaseReceipt") ||
              pathname === "/order" ||
              isCartAssociatedToContract
            }
            aria-label={intl.get("select-branch")}
          >
            {branchesList.map(branch => (
              <option
                className="branch-dropdown-option"
                key={branch.branchNumber}
                value={branch.branchNumber}
              >
                {branch.branchName}
              </option>
            ))}
          </select>
        )}
        {config.branchDropDownLocation === "plpLeft" &&
          !isMobile &&
          (!updateLoader ? (
            <button
              type="button"
              className="update-btn dast-btn dast-btn-primary"
              onClick={changeBranch}
            >
              {intl.get("update")}
            </button>
          ) : (
            <div className="miniLoader" />
          ))}
        {renderBranchChangeModal()}
      </div>
    );
  }
  if (branchesList && branchesList.length && isVirtualBranchUser) {
    return (
      <div className="branch-dropdown">
        <span>
          {`${intl.get("home-branch")}: #${branchesList[0].branchNumber}`}
        </span>
      </div>
    );
  }

  return null;
};
export default withRouter(BranchDropdownComponent);
