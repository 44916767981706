import { useState } from "react";
import { formatNumberValue } from "../utils/helpers";

// eslint-disable-next-line import/prefer-default-export
export const OrderContext = () => {
  const [orderData, setOrderData] = useState(null);
  const [purchaseData, setPurchaseData] = useState(null);
  const [branch, setBranch] = useState(null);
  const [job, setJob] = useState(null);
  const [poNumber, setPoNumber] = useState(null);
  const [promoCodes, setPromoCodes] = useState(null);
  const [backOrderedQuantity, setBackOrderedQuantity] = useState([]);
  const [contractNumber, setContractNumber] = useState("");

  return {
    orderData,
    setOrderData,
    purchaseData,
    setPurchaseData,
    branch,
    setBranch,
    job,
    setJob,
    poNumber,
    setPoNumber,
    promoCodes,
    setPromoCodes,
    backOrderedQuantity,
    setBackOrderedQuantity,
    contractNumber,
    setContractNumber
  };
};
