import * as React from "react";
import intl from "react-intl-universal";

import Input from "../common/InputComponent/Input";

import "./Notifications.less";

export interface NotificationsStatus {
  smsChecked: boolean;
  orderStatusChecked: boolean;
  deliveryStatusChecked: boolean;
}

export interface NotificationData {
  phoneNumbers: Array<string>;
  orderStatusEmails: Array<string>;
  deliveryStatusEmail: string;
}

export interface NotificationsError {
  deliveryStatusEmail: string;
  phoneNumbers: Array<string>;
  orderStatusEmails: Array<string>;
}

interface NotificationsProps {
  notificationsStatus: NotificationsStatus;
  notificationsData: NotificationData;
  updateNotificationsStatus: (...args) => any;
  updateNotificationsData: (...args) => any;
  notificationsError: NotificationsError;
  userProfile: any;
  history?: any;
}

const Notifications: React.FC<NotificationsProps> = ({
  notificationsStatus,
  notificationsData,
  updateNotificationsStatus,
  updateNotificationsData,
  notificationsError,
  userProfile
}) => {
  const { phone } = userProfile;
  const defaultPhoneNumber = phone;
  const smsPhoneList =
    notificationsData &&
    removeDefaultPhoneNumber(
      defaultPhoneNumber,
      notificationsData.phoneNumbers
    );
  const doesDataIncludeDefault =
    notificationsData &&
    notificationsData.phoneNumbers.indexOf(defaultPhoneNumber) > -1;
  const userHasPhoneNumbers = smsPhoneList && smsPhoneList.length;
  const noDefaultPhoneNumUserIndex = userHasPhoneNumbers
    ? smsPhoneList.length - 1
    : 0;
  const defaultPhoneNumUserIndex = noDefaultPhoneNumUserIndex + 1;
  const plusButtonIndex = defaultPhoneNumber
    ? defaultPhoneNumUserIndex
    : noDefaultPhoneNumUserIndex;

  function handleCheckboxClick(event): void {
    const { id } = event.target;
    updateNotificationsStatus(id);
  }

  function handleAddPhoneNumber(): void {
    const phoneNumbers = [...notificationsData.phoneNumbers];
    phoneNumbers.push("");
    updateNotificationsData(Object.keys({ phoneNumbers })[0], phoneNumbers);
  }

  function handleRemovePhoneNumber(): void {
    if (notificationsData.phoneNumbers.length >= 1) {
      const phoneNumbers = [...notificationsData.phoneNumbers];
      phoneNumbers.pop();
      updateNotificationsData(Object.keys({ phoneNumbers })[0], phoneNumbers);
    }
  }

  function handlePhoneNumberChange(e: any, index: number): void {
    const { value } = e.target;
    const indexToUse = doesDataIncludeDefault ? index + 1 : index;
    const phoneNumbers = [...notificationsData.phoneNumbers];
    phoneNumbers[indexToUse] = value;
    updateNotificationsData(Object.keys({ phoneNumbers })[0], phoneNumbers);
  }

  function handleAddEmail(): void {
    const orderStatusEmails = [...notificationsData.orderStatusEmails];
    orderStatusEmails.push("");
    updateNotificationsData(
      Object.keys({ orderStatusEmails })[0],
      orderStatusEmails
    );
  }

  function handleRemoveEmail(): void {
    if (notificationsData.orderStatusEmails.length > 1) {
      const orderStatusEmails = [...notificationsData.orderStatusEmails];
      orderStatusEmails.pop();
      updateNotificationsData(
        Object.keys({ orderStatusEmails })[0],
        orderStatusEmails
      );
    }
  }

  function handleEmailChange(e: any, index: number): void {
    const { value } = e.target;
    const orderStatusEmails = [...notificationsData.orderStatusEmails];
    orderStatusEmails[index] = value;
    updateNotificationsData(
      Object.keys({ orderStatusEmails })[0],
      orderStatusEmails
    );
  }

  function removeDefaultPhoneNumber(phoneNumber, smsPhoneNumbers) {
    if (notificationsData && notificationsData.phoneNumbers) {
      if (smsPhoneNumbers) {
        if (phoneNumber === "" || !phoneNumber) {
          return smsPhoneNumbers;
        }
        const smsPhoneListToReturn = [...smsPhoneNumbers];
        const phoneIndex = smsPhoneListToReturn.indexOf(phoneNumber);
        if (phoneIndex !== -1) {
          smsPhoneListToReturn.splice(phoneIndex, 1);
        }
        return smsPhoneListToReturn;
      }
    }
    return null;
  }

  function showIcons(index) {
    return (
      (defaultPhoneNumber && index + 1 === plusButtonIndex) ||
      (!defaultPhoneNumber && index === plusButtonIndex)
    );
  }

  function renderSmsPhoneList() {
    return (
      <>
        {defaultPhoneNumber && (
          <div className="input-container">
            <Input
              inputName="phoneNumber"
              label=""
              type="text"
              ariaLabel="phoneNumber"
              disabled
              title={intl.get("account-phone-number-tooltip")}
              inputHandler={e => handlePhoneNumberChange(e, 0)}
              value={defaultPhoneNumber}
              errors={null}
            />
            {!smsPhoneList.length && (
              <button
                type="button"
                className="plus-button"
                onClick={handleAddPhoneNumber}
                disabled={!defaultPhoneNumber}
                aria-label={intl.get("additional-input-phone")}
              >
                <span className="glyphicon glyphicon-plus" />
              </button>
            )}
          </div>
        )}
        {smsPhoneList.map((phoneNumber, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="input-container" key={index}>
            <Input
              inputName="phoneNumber"
              label=""
              type="text"
              ariaLabel="phoneNumber"
              inputHandler={e => handlePhoneNumberChange(e, index)}
              value={phoneNumber}
              placeholder="999-999-9999"
              errors={
                notificationsError && notificationsError.phoneNumbers[index]
                  ? {
                      phoneNumber: notificationsError.phoneNumbers[index]
                    }
                  : null
              }
            />
            {showIcons(index) && (
              <>
                <button
                  type="button"
                  className="plus-button"
                  onClick={handleAddPhoneNumber}
                  disabled={!phoneNumber}
                  aria-label={intl.get("additional-input-phone")}
                >
                  <span className="glyphicon glyphicon-plus" />
                </button>
                <button
                  type="button"
                  className="minus-button"
                  onClick={handleRemovePhoneNumber}
                >
                  <span className="glyphicon glyphicon-minus" />
                </button>
              </>
            )}
          </div>
        ))}
      </>
    );
  }

  function renderEmptyInput() {
    let phoneNum;
    return (
      <div className="input-container">
        <Input
          inputName="phoneNumber"
          label=""
          type="text"
          ariaLabel="phoneNumber"
          inputHandler={e => handlePhoneNumberChange(e, 0)}
          value={phoneNum}
          placeholder="999-999-9999"
          errors={null}
        />
        <button
          type="button"
          className="plus-button"
          onClick={handleAddPhoneNumber}
          disabled={!phoneNum}
          aria-label={intl.get("additional-input-phone")}
        >
          <span className="glyphicon glyphicon-plus" />
        </button>
      </div>
    );
  }

  return notificationsData ? (
    <div className="notifications-content">
      <div className="sms-notifications-content">
        <span className="label">{intl.get("sms")}</span>
        <label className="notifications-label" htmlFor="sms" id="sms-label">
          <input type="checkbox" name="sms" id="checkbox" />
          <span
            className={`notifications-checkbox ${
              notificationsStatus && notificationsStatus.smsChecked
                ? "show-checkmark"
                : "hide-checkmark"
            }`}
            onClick={handleCheckboxClick}
            onKeyDown={handleCheckboxClick}
            role="checkbox"
            aria-checked="false"
            tabIndex={0}
            aria-labelledby="sms-label"
            id="smsChecked"
          />
          {intl.get("receive-sms-alerts")}
        </label>

        {notificationsStatus && notificationsStatus.smsChecked && (
          <div>
            <span>{intl.get("phone-numbers")}</span>
            {renderSmsPhoneList()}
            {!notificationsData.phoneNumbers.length &&
              !defaultPhoneNumber &&
              renderEmptyInput()}
          </div>
        )}
      </div>
      <div className="email-notifications-content">
        <span className="label">{intl.get("email")}</span>
        <label
          className="notifications-label"
          htmlFor="order-status"
          id="order-status-label"
        >
          <input type="checkbox" name="order-status" id="checkbox" />
          <span
            className={`notifications-checkbox ${
              notificationsStatus && notificationsStatus.orderStatusChecked
                ? "show-checkmark"
                : "hide-checkmark"
            }`}
            onClick={handleCheckboxClick}
            onKeyDown={handleCheckboxClick}
            role="checkbox"
            aria-checked="false"
            tabIndex={0}
            aria-labelledby="order-status-label"
            id="orderStatusChecked"
          />
          {intl.get("receive-order-status")}
        </label>
        <p className="order-status-email-alert-message">
          {intl.get("order-status-email-alert-message")}
        </p>
        {notificationsStatus && notificationsStatus.orderStatusChecked && (
          <div>
            <span>{intl.get("emails")}</span>
            {notificationsData.orderStatusEmails.map((email, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="input-container" key={index}>
                <Input
                  inputName="orderStatusEmail"
                  label=""
                  type="email"
                  ariaLabel="orderStatusEmail"
                  inputHandler={e => handleEmailChange(e, index)}
                  value={email}
                  errors={
                    notificationsError &&
                    notificationsError.orderStatusEmails[index]
                      ? {
                          orderStatusEmail:
                            notificationsError.orderStatusEmails[index]
                        }
                      : null
                  }
                />
                {index === notificationsData.orderStatusEmails.length - 1 && (
                  <button
                    type="button"
                    className="plus-button"
                    onClick={handleAddEmail}
                    disabled={!email}
                    aria-label={intl.get("additional-input-email")}
                  >
                    <span className="glyphicon glyphicon-plus" />
                  </button>
                )}
                {index !== 0 &&
                  index === notificationsData.orderStatusEmails.length - 1 && (
                    <button
                      type="button"
                      className="minus-button"
                      onClick={handleRemoveEmail}
                    >
                      <span className="glyphicon glyphicon-minus" />
                    </button>
                  )}
              </div>
            ))}
          </div>
        )}
        <label
          className="notifications-label"
          htmlFor="delivery-status"
          id="delivery-status-label"
        >
          <input type="checkbox" name="delivery-status" id="checkbox" />
          <span
            className={`notifications-checkbox ${
              notificationsStatus && notificationsStatus.deliveryStatusChecked
                ? "show-checkmark"
                : "hide-checkmark"
            }`}
            onClick={handleCheckboxClick}
            onKeyDown={handleCheckboxClick}
            role="checkbox"
            aria-checked="false"
            tabIndex={0}
            aria-labelledby="delivery-status-label"
            id="deliveryStatusChecked"
          />
          {intl.get("receive-delivery-receipt")}
        </label>
        {notificationsStatus && notificationsStatus.deliveryStatusChecked && (
          <div>
            <span>{intl.get("email")}</span>
            <div className="input-container">
              <Input
                inputName="deliveryStatusEmail"
                label=""
                type="email"
                ariaLabel="deliveryStatusEmail"
                inputHandler={e =>
                  updateNotificationsData(e.target.name, e.target.value)
                }
                value={notificationsData.deliveryStatusEmail}
                errors={
                  notificationsError && notificationsError.deliveryStatusEmail
                    ? {
                        deliveryStatusEmail:
                          notificationsError.deliveryStatusEmail
                      }
                    : null
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default Notifications;
