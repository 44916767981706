import React, { FC } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import GridCardInterface from "./types/GridCardInterface";
import { isLinkExternal } from "../../../app/src/utils/helpers";
import useWindowSize from "../CustomHooks/useWindowSize";

interface MainBanerGridCardProps {
  gridCard: GridCardInterface;
  isLoggedIn: boolean;
}

const MainBannerGridCard: FC<MainBanerGridCardProps> = ({
  gridCard,
  isLoggedIn
}) => {
  const [width, height] = useWindowSize();
  const {
    id,
    image,
    topTitle,
    mainTitle,
    bottomTitle,
    buttonText,
    type,
    cardWidth,
    backgroundColor,
    textColor,
    borderColor,
    backgroundImage: desktopBackgroundImage,
    isLinkForLoggedInUserOnly,
    link,
    html,
    mobileBackgroundImage,
    buttonBackgroundColor,
    buttonBorderColor
  } = gridCard;

  const getLink = () => {
    if (!isLoggedIn && isLinkForLoggedInUserOnly) return "/login";
    return link;
  };
  const [isExternal, url] = isLinkExternal(getLink());

  const backgroundImage =
    width <= 768
      ? mobileBackgroundImage || desktopBackgroundImage
      : desktopBackgroundImage;

  const style = {
    flex: `${cardWidth} 0 0`,
    color: textColor,
    border: borderColor ? `1px solid ${borderColor}` : "",
    backgroundColor,
    backgroundImage: `url(${backgroundImage})`
  };

  return (
    <Link
      to={{
        pathname: url,
        state: { prevUrl: window.location.href }
      }}
      target={isExternal ? "_blank" : "_self"}
      rel={isExternal ? "noopener noreferrer" : ""}
      className={`grid-card ${type} ${backgroundImage ? "card-with-bkg" : ""}`}
      id={id}
      key={id}
      style={style}
    >
      {topTitle && <h2 className="grid-card-top-title">{topTitle}</h2>}
      {image && <img className="grid-card-image" src={image} alt={mainTitle} />}

      {mainTitle && <h2 className="grid-card-title">{mainTitle}</h2>}
      {bottomTitle && <h2 className="grid-card-bottom-title">{bottomTitle}</h2>}
      {html && (
        <div
          className="grid-card-description"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html.value) }}
        />
      )}
      {buttonText && (
        <button
          type="button"
          className="grid-card-button"
          style={{
            border: `1px solid ${buttonBorderColor || textColor}`,
            color: textColor,
            backgroundColor: buttonBackgroundColor || "transparent"
          }}
        >
          {buttonText}
        </button>
      )}
    </Link>
  );
};

export default MainBannerGridCard;
