import React, { FC, useContext } from "react";
import intl from "react-intl-universal";
import {
  getConfig,
  IEpConfig
} from "@zilker/store-components/src/utils/ConfigProvider";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { DaikinLeadGenTable } from "@goodman-cloud/daikin-common-components";

import "./LeadTracking.less";

interface LeadTrackingProps {}

const LeadTracking: FC<LeadTrackingProps> = () => {
  const context = useContext<{ user: any }>(MainContext);
  const {
    user: {
      userProfile: { leadGenDealerEmail, hasLeadGenRoles }
    }
  } = context;
  const { config }: { config: IEpConfig } = getConfig();
  const { connectServicesToken, environment } = config;
  const userHasPermissions = hasLeadGenRoles;

  return userHasPermissions ? (
    <>
      <DaikinLeadGenTable
        bearerToken={connectServicesToken}
        email={leadGenDealerEmail}
        env={environment}
        title={intl.get("lead-tracking")}
      />
    </>
  ) : (
    <>
      <div className="content-box">
        <h2>{intl.get("lead-tracking")}</h2>
        <div>{intl.get("lead-tracking-no-permissions")}</div>
      </div>
    </>
  );
};

export default LeadTracking;
