/* eslint-disable react/no-unused-state */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import ProductListItemMain from "../ProductListItem/productlistitem.main";

import { getConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";

import "./productlist.main.less";

let intl = { get: str => str };

interface ProductListMainProps {
  productData: any;
  productPrices?: any;
  showCompareButton?: boolean;
  productListLinks?: {
    [key: string]: any;
  };
}
interface ProductListMainState {
  isCompare: boolean;
  compareLink: string;
  compareList: any;
}
class ProductListMain extends React.Component<
  ProductListMainProps,
  ProductListMainState
> {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    ({ intl } = getConfig());

    this.state = {
      isCompare: false,
      compareLink: "",
      compareList: []
    };
    this.handleCompareToggle = this.handleCompareToggle.bind(this);
  }

  handleCompare(event) {
    this.setState({
      isCompare: !event
    });
  }

  handleCompareToggle(event) {
    const { compareList } = this.state;
    const { productListLinks } = this.props;
    const elementCode = event.target.name;
    const index = compareList.indexOf(elementCode);
    if (index === -1 && compareList.length > 2) return;
    if (index !== -1) {
      this.setState({
        compareList: compareList
          .slice(0, index)
          .concat(compareList.slice(index + 1)),
        compareLink: `${productListLinks.productsCompare}/${encodeURIComponent(
          compareList
            .slice(0, index)
            .concat(compareList.slice(index + 1))
            .join()
        )}`
      });
    } else {
      this.setState({
        compareList: [...compareList, elementCode],
        compareLink: `${productListLinks.productsCompare}/${encodeURIComponent(
          [...compareList, elementCode].join()
        )}`
      });
    }
  }

  checkComparison(product) {
    const { compareList, isCompare } = this.state;
    const isChecked =
      compareList.indexOf(product._items[0]._element[0]._code[0].code) !== -1;
    if (product._definition && isCompare) {
      return (
        <div className="compare-checkbox">
          <label htmlFor={product._code[0].code}>
            <input
              id={product._code[0].code}
              checked={isChecked}
              name={product._items[0]._element[0]._code[0].code}
              disabled={compareList.length > 2 && !isChecked}
              type="checkbox"
              onChange={this.handleCompareToggle}
            />
            <span className="helping-el" />
            <span className="label-text">
              &nbsp;
              {intl.get("compare")}
            </span>
          </label>
        </div>
      );
    }
    return null;
  }

  renderProducts() {
    const {
      showCompareButton,
      productListLinks,
      productPrices,
      productData: categoryModel
    } = this.props;
    return categoryModel._element.map(product => {
      let price;
      if (productPrices) {
        price = productPrices.find(p => p.sku === product._code[0].code);
      }
      if (product.self.type === "offers.offer") {
        return (
          <li
            key={`_${Math.random()
              .toString(36)
              .substr(2, 9)}`}
            className="category-item-container"
          >
            <ProductListItemMain
              offerData={product}
              productPrice={price || ""}
              itemDetailLink={productListLinks.itemDetail}
            />

            {showCompareButton ? this.checkComparison(product) : ""}
          </li>
        );
      }
      if (product._code) {
        return (
          <li
            key={`_${Math.random()
              .toString(36)
              .substr(2, 9)}`}
            className="category-item-container"
          >
            <ProductListItemMain
              productElement={product}
              productPrice={price || ""}
              itemDetailLink={productListLinks.itemDetail}
            />

            {this.checkComparison(product)}
          </li>
        );
      }
      return null;
    });
  }

  render() {
    const { productData: categoryModel } = this.props;
    if (categoryModel._element && categoryModel._element.length > 0) {
      return (
        <div
          className="product-list-container"
          data-region="categoryBrowseRegion"
        >
          <ul
            className="category-items-listing equalize"
            id="category_items_listing"
          >
            {this.renderProducts()}
          </ul>
        </div>
      );
    }

    return <div className="loader" />;
  }
}

export default ProductListMain;
