import React from "react";
import { Link, useLocation } from "react-router-dom";
import intl from "react-intl-universal";
import { getConfig } from "@zilker/store-components";
import AppHeaderLoginMain from "../AppHeaderLogin/appheaderlogin.main";
import AppHeaderLocaleMain from "../AppHeaderLocale/appheaderlocale.main";
import ClientDropdownComponent from "../ClientDropdownComponent/ClientDropdownComponent";
import JobDropdownComponent from "../JobDropdownComponent/JobDropdownComponent";
import BranchDropdownComponent from "../BranchDropdownComponent/BranchDropdownComponent";
import CartPopUp from "../CartPopUp/cartpopup";
import AppHeaderCTA from "../AppHeaderCTA/appheaderCTA";
import { ReactComponent as HelpIcon } from "../../../app/src/images/icons/help-icon.svg";
import { ReactComponent as CartIconHeader } from "../../../app/src/images/icons/cart-icon-header.svg";
import { ReactComponent as QuickOrderIcon } from "../../../app/src/images/icons/quick-order-icon.svg";

import "./appheadertopnav.main.less";

interface AppHeaderTopNavMainProps {
  isMobileView?: boolean;
  onCurrencyChange?: (...args: any[]) => any;
  onLocaleChange: (...args: any[]) => any;
  appHeaderTopLinks: {
    [key: string]: any;
  };
  isLoggedIn: boolean;
  userProfile: any;
  cart: any;
  homeBranchDetails: any;
  permission?: boolean;
  onAuthRedirect?: (...args: any[]) => any;
  locationSearchData?: string;
  appHeaderLoginLinks?: {
    [key: string]: any;
  };
  appModalLoginLinks?: {
    [key: string]: any;
  };
  history?: any;
  shouldDisplayJobDropdown: boolean;
  cartData: any;
  openModal: (...args: any[]) => any;
  appHeaderLinks: {
    [key: string]: any;
  };
  scrollClass: boolean;
  isMobile: boolean;
  isMobileMin?: boolean;
  accountDetails?: any;
}

const AppHeaderTopNavMain: React.FC<AppHeaderTopNavMainProps> = props => {
  const {
    isMobileView,
    onCurrencyChange,
    onLocaleChange,
    isLoggedIn,
    userProfile,
    cart,
    onAuthRedirect,
    locationSearchData,
    appHeaderLoginLinks,
    appModalLoginLinks,
    history,
    shouldDisplayJobDropdown,
    cartData,
    openModal,
    appHeaderLinks,
    scrollClass,
    isMobile,
    isMobileMin,
    accountDetails
  } = props;

  const { config } = getConfig();
  const location = useLocation();
  const { state } = location;

  const { clientList, ecommerceUser, ecommerceBuyer } = userProfile;
  const {
    cartDetails: { defaultCart, totalCount },
    currentCount
  } = cart;
  const { homeBranch } = accountDetails;
  const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

  const availability = Boolean(cartData);
  const shouldRenderCartBtn = isLoggedIn && ecommerceBuyer;
  const shouldRenderQuickorderBtn =
    config.enableBulkCart &&
    availability &&
    cartData &&
    cartData.addItemsToCart &&
    ecommerceBuyer;
  const shouldDisableQuickOrder =
    window.location.pathname === "/checkout" ||
    window.location.pathname === "/order";

  return (
    <div
      key="AppHeaderTop"
      className={`top-header container-fluid ${
        isMobileView ? "mobile-view" : ""
      } ${isLoggedIn ? "" : "sign-in-btn"}`}
    >
      {isLoggedIn ? (
        <div className="top-container container job-branch-row">
          <div className="user-information">
            {ecommerceUser && (
              <>
                <h6 className="user-account-num">{accountDetails.company}</h6>
                <h6 className="user-account-num">
                  {`${intl.get("account-number")}: `}
                  <span>{accountDetails.customerNumber} - </span>
                </h6>
              </>
            )}
            <div className="auth-icon-container">
              <div className="auth-username">
                {`${userProfile.givenName} ${userProfile.familyName}`}
              </div>
            </div>
          </div>
          <div className="user-information">
            {homeBranch && selectedBranch && ecommerceUser ? (
              <div className="dropdown-container">
                {shouldDisplayJobDropdown ? <JobDropdownComponent /> : null}
                {config.branchDropDownLocation === "header" ? (
                  <BranchDropdownComponent
                    selectedBranch={selectedBranch.code}
                  />
                ) : null}
                {config.showClientInformation &&
                clientList &&
                clientList.length &&
                defaultCart ? (
                  <ClientDropdownComponent />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {isMobile ? (
        <AppHeaderCTA isMobile={isMobile} isMobileMin={isMobileMin} />
      ) : null}
      <div className="icons-container-wrapper">
        {userProfile && (
          <div className="icon-container">
            <AppHeaderLocaleMain
              onCurrencyChange={onCurrencyChange}
              onLocaleChange={onLocaleChange}
              userProfile={userProfile}
              isLoggedIn={isLoggedIn}
            />
          </div>
        )}
        <div className="login-container">
          <AppHeaderLoginMain
            isMobileView={false}
            permission={availability}
            onAuthRedirect={onAuthRedirect}
            locationSearchData={locationSearchData}
            appHeaderLoginLinks={appHeaderLoginLinks}
            appModalLoginLinks={appModalLoginLinks}
            isLoggedIn={isLoggedIn}
            history={history}
            isAppHeaderMain={false}
            isHeader
          />
        </div>
        {isLoggedIn && (
          <div className="icon-container help-icon-container">
            <a
              href={config.SFHelpCenter}
              target="_blank"
              rel="noopener noreferrer"
              className="help-center"
              aria-label="Help center"
            >
              <HelpIcon className="help-icon" />
            </a>
          </div>
        )}
        {shouldRenderQuickorderBtn && isLoggedIn && (
          <div className="icon-container">
            <button
              className="bulk-button"
              type="button"
              aria-label="Quick order"
              onClick={openModal}
              disabled={shouldDisableQuickOrder}
            >
              <QuickOrderIcon className="quick-order-icon" />
            </button>
          </div>
        )}
        {shouldRenderCartBtn && (
          <div className="cart-link-wrapper">
            <div
              className={`cart-link-container multi-cart-dropdown dropdown ${
                currentCount.show ? "show" : ""
              }`}
            >
              <Link
                className={`cart-link ${
                  currentCount.show ? "modal-arrow" : ""
                }`}
                to={{
                  pathname: appHeaderLinks.myCart,
                  state: {
                    prevUrl: window.location.href,
                    error: state && state.error ? state.error : undefined
                  }
                }}
              >
                <div className="cart-total-quantity"> {totalCount} </div>
                <CartIconHeader className="cart-icon" />
                {intl.get("shopping-cart-nav")}
              </Link>
              <div
                className={`${
                  scrollClass ? "multi-cart-container-scroll" : ""
                } multi-cart-container dropdown-menu dropdown-menu-right ${
                  currentCount.show ? "show" : ""
                }`}
              >
                <CartPopUp appHeaderLinks={appHeaderLinks} cartData={cart} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeaderTopNavMain;
